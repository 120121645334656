import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Form, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { useParams, useNavigate } from "react-router-dom";

import { FieldsForm, PhotoForm } from "../";
import {
	addMarketplaceOffer,
	updateMarketplaceOffer,
	resetMarketplaceOffer,
	selectMarketplaceOffer,
} from "store";
import { TStore } from "models";
import { Loading } from "components";
import { parseContentWithMentions } from "utils";
import styles from "./styles.module.scss";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.marketplaceOffer,
	(state: TStore) => state.app.currentCommunityId,
	(state: TStore) => state.app.currentGroupId,
	(marketplaceOffer, currentCommunityId, currentGroupId) => ({
		marketplaceOffer,
		currentCommunityId,
		currentGroupId,
	}),
);

interface IMainForm {
	handleSwitchScreen: () => void;
}

const MainForm: React.FunctionComponent<IMainForm> = ({
	handleSwitchScreen,
}) => {
	const { section, postId }: { section?: string; postId?: string } =
		useParams();
	const [loading, setLoading] = useState(section === "edit" ? true : false);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { marketplaceOffer, currentCommunityId, currentGroupId } =
		useSelector(stateSelector);

	const handleFormFinish = useCallback(
		(formName: any, info: any) => {
			const { photoForm } = info.forms;

			if (formName === "fieldsForm") {
				setLoading(true);
				const images = photoForm.getFieldValue("postImages") || [];

				const imagesToBeRemoved =
					photoForm.getFieldValue("imagesToBeRemoved") || [];
				const imagesObj =
					images &&
					images.fileList &&
					images.fileList
						.filter((item: any) => item.originFileObj)
						.map((item: any) => item.originFileObj);

				try {
					if (!marketplaceOffer.editData) {
						const { source } = parseContentWithMentions({
							content: info.values.description,
							typeOfConversion: "fromInputToSystem",
						});
						const isGroupsCheckboxGroupEmpty =
							!info.values.groupsCheckboxGroup ||
							info.values.groupsCheckboxGroup.length === 0;

						dispatch(
							addMarketplaceOffer({
								data: {
									...info.values,
									groupsCheckboxGroup: currentGroupId
										? [currentGroupId]
										: info.values.groupsCheckboxGroup,
									description: source,
									postImages: imagesObj,
									all_groups: currentGroupId
										? false
										: isGroupsCheckboxGroupEmpty,
								},
								erroCallback: () => {
									navigate(-1);
								},
								successCallback: () => {
									navigate(-1);
								},
							}),
						);
					} else {
						// update offer
						const { source } = parseContentWithMentions({
							content: info.values.description,
							typeOfConversion: "fromInputToSystem",
						});
						const isGroupsCheckboxGroupEmpty =
							!info.values.groupsCheckboxGroup ||
							info.values.groupsCheckboxGroup.length === 0;

						dispatch(
							updateMarketplaceOffer({
								data: {
									...info.values,
									description: source,
									postImages: imagesObj,
									imagesToBeRemoved,
									id: marketplaceOffer?.editData
										?.community_post.id,
									all_groups: isGroupsCheckboxGroupEmpty,
								},
								errorCallback: () => {
									navigate(-1);
								},
								successCallback: () => {
									navigate(-1);
								},
							}),
						);
					}
				} catch (error) {
					console.error({ error });
					navigate(-1);
				}
			}
		},
		[currentGroupId, dispatch, marketplaceOffer.editData, navigate],
	);

	useEffect(() => {
		if (
			!marketplaceOffer.editData &&
			loading &&
			currentCommunityId &&
			section === "edit" &&
			postId
		) {
			dispatch(
				selectMarketplaceOffer({
					marketplaceOfferId: parseInt(postId),
					successCallback: () => {
						setLoading(false);
					},
					erroCallback: () => {
						setLoading(false);
					},
				}),
			);
		}
	}, [
		dispatch,
		section,
		postId,
		currentCommunityId,
		marketplaceOffer.editData,
		loading,
	]);

	// reset the offer store
	useEffect(() => {
		return () => {
			dispatch(resetMarketplaceOffer());
		};
	}, [dispatch]);

	if (loading) return <Loading />;

	return (
		<>
			<div className={styles.callToActionBtnContainer}>
				<Button onClick={handleSwitchScreen}>{"Cancel"}</Button>
			</div>
			<Form.Provider onFormFinish={handleFormFinish}>
				<Row gutter={32} className={styles.formColWrapper}>
					<Col className={styles.fieldsFormWrapper} span={14}>
						<FieldsForm
							marketplaceOffer={
								marketplaceOffer && marketplaceOffer.editData
							}
						/>
					</Col>
					<Col className={styles.photoFormWrapper} span={10}>
						<PhotoForm
							images={
								marketplaceOffer?.editData?.community_post
									?.images
							}
						/>
					</Col>
				</Row>
			</Form.Provider>
		</>
	);
};

export default MainForm;
