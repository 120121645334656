import { FunctionComponent, memo, useCallback, useMemo } from "react";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import classNames from "classnames";

import { EJoinCommunityOnboardingSteps, TStore } from "models";
import styles from "./styles.module.scss";
import { ReactComponent as CheckedCheckbox } from "assets/images/checked-checkbox.svg";
import { useResponsiveQuery } from "utils";

interface IOnboardingBarProgressBar {
	props: unknown;
}

const stateSelectorHandle = createSelector(
	(state: TStore) => state.communities.selectedCommunityToJoinInfo,
	(selectedCommunityToJoinInfo) => ({
		selectedCommunityToJoinInfo,
	}),
);

const OnboardingBarProgressBar: FunctionComponent<IOnboardingBarProgressBar> =
	({ props }) => {
		const stateSelector = useCallback(stateSelectorHandle, []);
		const { selectedCommunityToJoinInfo } = useSelector(stateSelector);
		const { isMobile } = useResponsiveQuery();

		const progressBarStepsKeys: null | Array<
			keyof typeof EJoinCommunityOnboardingSteps
		> = useMemo(() => {
			if (!selectedCommunityToJoinInfo) return null;
			return Object.keys(
				selectedCommunityToJoinInfo.onboardingProgressBarSteps,
			).filter((item) => {
				const prgressStepKey =
					item as unknown as EJoinCommunityOnboardingSteps;
				const progressStep =
					selectedCommunityToJoinInfo.onboardingProgressBarSteps[
						prgressStepKey
					];
				return progressStep.isVisible;
			}) as unknown as Array<keyof typeof EJoinCommunityOnboardingSteps>;
		}, [selectedCommunityToJoinInfo]);

		return (
			<>
				{!selectedCommunityToJoinInfo.onboardingProgressBarSteps ? null : (
					<>
						<div
							className={classNames(styles.flexRow, {
								[styles.scrollableWrapper]: isMobile,
							})}
						>
							{isMobile && (
								<div
									style={{ display: "flex" }}
									className={
										styles.absoluteLeftPositionedWhiteShaddow
									}
								/>
							)}
							{progressBarStepsKeys?.map((item, index) => {
								const progressStep =
									selectedCommunityToJoinInfo
										.onboardingProgressBarSteps[item];
								return (
									<div
										className={classNames(
											styles.centerFlexItem,
											{
												[styles.textHeaderBackgroundColor]:
													progressStep.isCurrent,
											},
										)}
										key={item}
									>
										{progressStep.checked ? (
											<div className={styles.iconWrapper}>
												<CheckedCheckbox />
											</div>
										) : (
											<div
												className={classNames(
													styles.boxNumber,
													{
														[styles.backgroundBoxHeaderBackgroundColor]:
															progressStep.isCurrent,
														[styles.backgroundBoxGreyColor]:
															progressStep.isDisabled,
													},
												)}
											>
												{index + 1}
											</div>
										)}
										<div style={{ whiteSpace: "nowrap" }} className={styles.text}>
											{item}
										</div>
										{index <
										progressBarStepsKeys.length - 1 ? (
											<div
												className={styles.dashedLine}
											/>
										) : null}
									</div>
								);
							})}
							{isMobile && (
								<div
									style={{ display: "flex" }}
									className={
										styles.absoluteRightPositionedWhiteShaddow
									}
								/>
							)}
						</div>
					</>
				)}
			</>
		);
	};

export default memo(OnboardingBarProgressBar);
