import React, { memo, useCallback } from "react";
import { Col, Row } from "antd";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";

import { TStore } from "models";
import { getMarketplaceOffers } from "store";
import { Loading } from "components";
import { CardView } from "..";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.app.currentGroupId,
	(state: TStore) => state.marketplaceOffers?.pins,
	(state: TStore) => state.marketplaceOffers?.meta,
	(state: TStore) => state.marketplaceOffers.loading,
	(currentGroupId, pins, meta, loading) => {
		return {
			currentGroupId,
			pins,
			meta,
			loading,
		};
	},
);

interface IFilterByTags {}

const ListItems: React.FunctionComponent<IFilterByTags> = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const stateSelector = useCallback(stateSelectorHandle, []);
	const { pins, meta, loading } = useSelector(stateSelector);

	const handleSelectedOffer = useCallback(
		(marketplaceOfferId: number) => () => {
			navigate(`/marketplace/edit/${marketplaceOfferId}`);
		},
		[navigate],
	);

	const handleLoadMore = useCallback(() => {
		if (loading) {
			return;
		}
		dispatch(getMarketplaceOffers());
	}, [dispatch, loading]);

	return (
		<InfiniteScroll
			pageStart={1}
			loadMore={handleLoadMore}
			hasMore={meta?.pagination?.has_next_page}
			useWindow={false}
			loader={
				<div
					key={0}
					style={{
						padding: "12px 8px",
						flexGrow: 1,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Loading />
				</div>
			}
			getScrollParent={() => document.getElementById("listSectionId")}
		>
			{pins.length === 0 && (
				<div className={"emptyPlaceholder"}>{"No posts, yet."}</div>
			)}
			<Row
				gutter={[18, 32]}
				style={{
					margin: !pins || !pins.length ? 0 : undefined,
				}}
			>
				{pins.map((item) => (
					<Col
						key={`marketplace_${item.id}`}
						xs={24}
						sm={12}
						xl={8}
						className={styles.cardWrapper}
					>
						<CardView
							marketplaceOffer={item}
							onClick={handleSelectedOffer}
						/>
					</Col>
				))}
			</Row>
		</InfiniteScroll>
	);
};

export default memo(ListItems);
