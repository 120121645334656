import { IImage, ILinkedCommunityDocuments, IMetaData } from "models";
import {
	IMarketplaceOffersLoadingSetAction,
	IMarketplaceOffersGetAction,
	IMarketplaceOffersSetAction,
	IMarketplaceOffersSetTagsAction,
	IMarketplaceOffersSetFilterTagsAction,
	IMarketplaceOffersRemoveFilterTagAction,
	IMarketplaceOffersSetQueryAction,
	IMarketplaceOffersSetInStoreAction,
} from "./actions";

// ENUMS
export enum EMarketplaceOffersActionTypes {
	marketplaceOffersLoadingSet = "marketplaceOffers/set/loading",
	getMarketplaceOffers = "marketplaceOffers/get",
	setMarketplaceOffers = "marketplaceOffers/set",
	setMarketplaceOfferInStore = "marketplaceOffers/set/marketplaceOffer/in/store",
	setMarketplaceOffersTags = "marketplaceOffers/set/tags",
	setFilterTagsMarketplaceOffers = "marketplaceOffers/set/filter/tag",
	removeFilterTagMarketplaceOffers = "marketplaceOffers/remove/filter/tag",
	setMarketplaceOffersQuery = "marketplaceOffers/set/query",
}

// INTERFACES

export enum EMarketplaceOfferGoodType {
	Share = "Share",
	Sell = "Sell",
	Buy = "Buy",
}

export interface ISaveMarketplaceOfferPayload {
	audienceCheckAll: boolean;
	audienceCheckboxGroup: string[];
	currency: string;
	description: string;
	expiryDate: Date;
	goodType: string;
	location: string;
	maxPrice: string;
	minPrice: string;
	postcode: string;
	postcodeLat: number;
	postcodeLng: number;
	tagsCheckboxGroup: string[];
	title: string;
	website: string;
	all_groups: boolean;
	postImages?: File[];
	groupsCheckAll?: boolean;
	groupsCheckboxGroup?: any;
	groupsCheckNone?: boolean;
	audienceCheckNone?: boolean;
	id?: number;
	imagesToBeRemoved?: File[];
}

export interface IMarketplaceOfferRawData {
	all_groups: boolean;
	comment_btn_label: string;
	community_id: number | null;
	community_post_id: number;
	description: string;
	good: {
		id: number;
		price: string; // the price, if any, can have this structure "min-value - max-value" or "0" or "any number". The price "0" is for the marketplace posts that are a giveaway.
		type: EMarketplaceOfferGoodType;
		postcode: string | null;
		currency: string;
	};
	group_objects: any;
	groups: string[];
	id: number;
	images: IImage[];
	is_approved: boolean;
	is_visible: boolean;
	liked: boolean;
	likes_count: number;
	member_type: string;
	membership_id: number;
	mentions: string[];
	pin_type: number;
	post_approved: boolean;
	recipient_id: number;
	sticky: boolean;
	tags: string[];
	timestamp: string;
	title: string;
	user: {
		id: number;
		full_name: string;
		picture: string;
		location: string;
		postcode: string;
	};
	user_pin: boolean;
	video_link: string | null;
	video_title: string | null;
	video_uid: string | null;
	vimeo_thumbnail_url: string | null;
	vimeo_uri: string | null;
	website: string | null;
	linked_community_documents: ILinkedCommunityDocuments[];
}

export interface IMarketplaceOffersState {
	loading: boolean;
	pins: IMarketplaceOfferRawData[];
	meta: IMetaData;
	tags?: {
		id: number;
		name: string;
		is_selected: boolean;
	}[];
	selectedTags?: string[];
	query?: string;
}

export interface IOffersLoadingPayload {
	loading: boolean;
}

// TYPES
export type TMarketplaceOffersActions =
	| IMarketplaceOffersSetAction
	| IMarketplaceOffersLoadingSetAction
	| IMarketplaceOffersGetAction
	| IMarketplaceOffersSetTagsAction
	| IMarketplaceOffersSetFilterTagsAction
	| IMarketplaceOffersRemoveFilterTagAction
	| IMarketplaceOffersSetInStoreAction
	| IMarketplaceOffersSetQueryAction;
