import { call, put, all, takeEvery, select } from "redux-saga/effects";

import {
	IMarketplaceOffersGetAction,
	EMarketplaceOffersActionTypes,
	TStore,
} from "models";
import {
	api,
	setMarketplaceOffersLoading,
	setMarketplaceOffers,
	setMarketplaceOffersTags,
} from "store";
import { rollbar } from "utils";

const getCommunityId = (state: TStore) => state.app.currentCommunityId;
const getGroupId = (state: TStore) => state.app.currentGroupId;
const getSelectedTags = (state: TStore) => state.marketplaceOffers.selectedTags;
const getQuery = (state: TStore) => state.marketplaceOffers.query;
const getMarketplaceOffersPins = (state: TStore) =>
	state.marketplaceOffers.pins;
const getMarketplaceOffersMeta = (state: TStore) =>
	state.marketplaceOffers.meta;

function* getMarketplaceOffersHandle({ payload }: IMarketplaceOffersGetAction) {
	yield put(setMarketplaceOffersLoading(true));
	try {
		let page: number;
		const communityId = yield select(getCommunityId);
		const groupId = yield select(getGroupId);
		const selectedTags = yield select(getSelectedTags);
		const q = yield select(getQuery);
		const marketplaceOffersData = yield select(getMarketplaceOffersPins);
		const marketplaceOffersMeta = yield select(getMarketplaceOffersMeta);

		if (
			payload?.reset ||
			marketplaceOffersData?.length === 0 ||
			!marketplaceOffersData
		) {
			page = 1;
		} else if (marketplaceOffersMeta?.pagination?.has_next_page) {
			page =
				parseInt(marketplaceOffersMeta?.pagination?.current_page) + 1;
		} else {
			return;
		}

		const { data } = yield call(api.marketplaceOffers.get, {
			...payload,
			communityId,
			tag_clicked: !!selectedTags.length,
			q: !!selectedTags.length ? selectedTags[0] : q,
			groupId,
			isGoods: true,
			page,
		});

		const { data: tags } = yield call(api.marketplaceOffers.getTags, {
			community_id: communityId,
			is_goods: true,
		});

		if (!data) {
			yield put(setMarketplaceOffersLoading(false));
		} else {
			yield put(setMarketplaceOffers({ data, reset: payload?.reset }));
			yield put(setMarketplaceOffersTags(tags));
			yield put(setMarketplaceOffersLoading(false));
		}
	} catch (error) {
		rollbar.error(error)
		console.error({ error });
		yield put(setMarketplaceOffersLoading(false));
	}
}

export default function* rootSaga() {
	yield all([
		takeEvery(
			EMarketplaceOffersActionTypes.getMarketplaceOffers,
			getMarketplaceOffersHandle,
		),
	]);
}
