import React, { memo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Menu } from "antd";
import { google, outlook, office365, yahoo, ics } from "calendar-link";

import { ReactComponent as CheckmarkIcon } from "assets/images/icons/common_icons/checkmark_icon.svg";
import { ReactComponent as Horn } from "assets/images/paceholder.svg";
import styles from "./styles.module.scss";
import { getEvent } from "store";
import { rollbar } from "utils";

interface IFeaturedEventBox {
	day: string;
	date: string;
	avatar: string;
	tag?: string;
	display?: boolean;
	event: {
		id: number;
		title: string;
		description: string;
		location: string;
		starts_at: Date;
		ends_at: Date;
		event_tag_list: string[];
	};
}

const FeaturedEventBox: React.FunctionComponent<IFeaturedEventBox> = ({
	day,
	date,
	avatar,
	tag,
	event,
	display = true,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { title, description, location, starts_at, ends_at, event_tag_list } =
		event;
	let duration: number =
		moment.duration(moment(ends_at).diff(moment(starts_at))).asHours() < 24
			? moment.duration(moment(ends_at).diff(moment(starts_at))).asHours()
			: moment.duration(moment(ends_at).diff(moment(starts_at))).asDays();
	let durationUnit: "day" | "hour" =
		moment.duration(moment(ends_at).diff(moment(starts_at))).asHours() < 24
			? "hour"
			: "day";
	let eventCalendar: any = {
		title,
		description,
		location,
		start: moment(starts_at).format("YYYY-MM-DDTHH:mm:ss"),
		end: moment(ends_at).format("YYYY-MM-DDTHH:mm:ss"),
		duration: [Math.round(duration), durationUnit],
	};

	const handleGoToEvent = useCallback(() => {
		dispatch(getEvent({ id: event.id }));
		navigate(`/calendar/events/${event.id}`);
	}, [dispatch, event, navigate]);
	const days = date.substr(0, 2);
	const month = date.substr(2);

	const formattedDate = `${days} ${month}`;

	const handleClick = (item: any) => {
		try {
			const path = calendarItems.find((i) => i.key === item.key)?.path;
			if (path?.startsWith("BEGIN")) {
				const blob = new Blob([path], {
					type: "text/calendar;charset=utf-8",
				});
				const link = document.createElement("a");
				link.href = window.URL.createObjectURL(blob);
				link.setAttribute("download", `CalendarEvent.ics`);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			} else {
				window.open(path, "_blank");
			}
		} catch (error) {
			rollbar.error(error);
		}
	};

	let calendarItems = eventCalendar
		? [
				{ key: "Google", path: google(eventCalendar) },
				{
					key: "Apple",
					path: decodeURIComponent(
						ics(eventCalendar).split("charset=utf8,")[1],
					),
				},
				{ key: "Yahoo!", path: yahoo(eventCalendar) },

				{
					key: "Outlook - Web",
					path: outlook(eventCalendar),
				},
				{
					key: "Outlook - Desktop",
					path: decodeURIComponent(
						ics(eventCalendar).split("charset=utf8,")[1],
					),
				},
				{
					key: "Office365",
					path: office365(eventCalendar),
				},
		  ]
		: [];

	const dateFormater = (date: Date) => {
		const newDate = new Date(date);

		const formattedDate = newDate
			.toLocaleString("en-US", {
				month: "short",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				hour12: false,
			})
			.replace(",", "");
		return formattedDate;
	};

	return (
		<div
			className={styles.container}
			style={{ backgroundColor: "#ffffff" }}
		>
			<div className={styles.dateBox} onClick={handleGoToEvent}>
				<div
					style={{
						display: "flex",
						justifyContent: "space-around",
					}}
				>
					<div>
						<Horn className={styles.placeholderSvg} fill="red" />
					</div>
					<div>
						<Horn className={styles.placeholderSvg} />
					</div>
				</div>
				<span className={styles.day}>{day}</span>
				<span className={styles.date}>{formattedDate}</span>
			</div>
			<div className={styles.featuredEventBox}>
				<div className={styles.topLine}>
					<div className={styles.calendar} onClick={handleGoToEvent}>
						<p>{title}</p>
					</div>
				</div>
				<div className={styles.infoBox} onClick={handleGoToEvent}>
					<div className={styles.timePlace}>
						<p>
							{`${dateFormater(event.starts_at)} - ${dateFormater(
								event.ends_at,
							)}`}
						</p>
						{event.location}
					</div>
					<div className={styles.tagSection}>
						{event_tag_list.map((tag, index: number) => {
							return <span key={`key_${index}`}>{tag}</span>;
						})}
					</div>
				</div>
				{display && (
					<div className={styles.addToCalendarBtn}>
						<Menu
							onClick={handleClick}
							selectedKeys={[]}
							mode="horizontal"
							style={{ backgroundColor: "transparent" }}
						>
							<Menu.SubMenu
								key="SubMenu"
								icon={<CheckmarkIcon />}
								title="Add to Calendar"
							>
								{calendarItems.map((item) => (
									<Menu.Item key={item.key}>
										{item.key}
									</Menu.Item>
								))}
							</Menu.SubMenu>
						</Menu>
					</div>
				)}
			</div>
		</div>
	);
};

export default memo(FeaturedEventBox);
