import React, { memo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { createSelector } from "reselect";

import { getDoc } from "store";
import { IDoc, TStore } from "models";
import Loading from "components/Loading";
import { MediaBox } from "components";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.document?.data,
	(documentData) => {
		return {
			documentData,
		};
	},
);

interface IDocNotificationDetails {
	docId?: number;
	forceCommunityId?: number;
	folderId?: number | null;
	docData?: IDoc;
	type?: "resources";
}

const DocNotificationDetails: React.FC<IDocNotificationDetails> = ({
	docId,
	forceCommunityId,
	folderId,
	docData,
	type,
}) => {
	const [loading, setLoading] = useState(docData ? false : true);
	const dispatch = useDispatch();
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { documentData } = useSelector(stateSelector);
	const documentItem = docData ? docData : documentData;

	useEffect(() => {
		if (!docData && docId && forceCommunityId) {
			dispatch(
				getDoc({
					id: docId,
					forceCommunityId,
					folderId: folderId ? folderId : null,
					callbackOnSuccess: () => setLoading(false),
					callbackOnError: () => setLoading(false),
				}),
			);
		}
	}, [dispatch, docData, docId, folderId, forceCommunityId]);

	return loading ? (
		<Loading />
	) : documentItem ? (
		<div>
			<MediaBox {...documentItem} type={type || "documents"} />
		</div>
	) : null;
};

export default memo(DocNotificationDetails);
