import React, { memo } from "react";
import { Layout } from "antd";
import { CodeSandboxOutlined } from "@ant-design/icons";

import { colors } from "utils";

export default memo(
	({ fontSize, style }: { fontSize?: number; style?: object }) => (
		<Layout
			style={{
				alignItems: "center",
				justifyContent: "center",
				alignSelf: "stretch",
				background: "transparent",
				...(style || {}),
			}}
		>
			<CodeSandboxOutlined
				style={{ fontSize: fontSize || 48, color: colors.grey5 }}
				spin
			/>
		</Layout>
	),
);
