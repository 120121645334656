import React, { memo, ReactNode } from "react";

import styles from "./styles.module.scss";
import { FooterLayout, OnboardingLayoutHeader } from "layouts/components";
import OnboardingProgressBar from "layouts/onboardingProgressBar";
import { Divider } from "antd";

import { CustomButton } from "components";
import { isItAWhiteLabel } from "utils";
import { useLocation } from "react-router-dom";

interface IOnboardingLayout {
	children: ReactNode;
	fullWidthContainerForChildren?: boolean;
	headerTitle?: string;
	headerSubtitle?: string;
	footerLayout?: boolean;
	handleBack?: () => void;
	handleNext?: () => void;
	nextButton?: boolean;
}

const OnboardingLayout: React.FunctionComponent<IOnboardingLayout> = ({
	headerTitle = "Welcome to Ugenie",
	headerSubtitle = ``,
	children,
	fullWidthContainerForChildren = false,
	footerLayout = true,
	handleBack,
	handleNext,
	nextButton = true,
}) => {
	const location = useLocation();
	return (
		<section
			className={styles.onboardingLayout}
			style={fullWidthContainerForChildren ? { alignItems: "unset" } : {}}
		>
			<OnboardingLayoutHeader />
			<Divider style={{ margin: "0px" }} />
			<OnboardingProgressBar props={""} />
			<div
				className={styles.container}
				style={
					fullWidthContainerForChildren ? { maxWidth: "unset" } : {}
				}
			>
				<section className={styles.header}>
					<h1 className={styles.title}>{headerTitle}</h1>

					<h3 className={styles.subtitle}>{headerSubtitle}</h3>
				</section>
				{children}
			</div>
			{footerLayout && <FooterLayout />}

			{location.pathname.includes("/resume-payment-process") &&
			isItAWhiteLabel()
				? null
				: handleBack &&
				  handleNext && (
						<div className={styles.topLevelContainer}>
							<div className={styles.btnContainer}>
								<CustomButton
									onClick={handleBack}
									buttonText={"Back"}
									varient="outline"
								/>
								{nextButton && (
									<CustomButton
										onClick={handleNext}
										buttonText="Next"
										varient="solid"
									/>
								)}
							</div>
						</div>
				  )}
		</section>
	);
};

export default memo(OnboardingLayout);
