import {
	EMessagesActionTypes,
	EAppActionTypes,
	IConversationData,
	IConversationMeta,
	IMessageThread,
} from "models";

export const setMessagesLoading = (payload: boolean) => ({
	type: EMessagesActionTypes.messagesLoadingSet,
	payload,
});

export const setMessages = (payload: any) => ({
	type: EMessagesActionTypes.setMessages,
	payload,
});

export const getMessages = (payload: { page: number }) => ({
	type: EMessagesActionTypes.getMessages,
	payload,
});

export const getConversations = (payload?: {
	skipLoading?: boolean;
	reset?: boolean;
}) => ({
	type: EMessagesActionTypes.getConversations,
	payload,
});

export const getConversationsSilently = () => ({
	type: EMessagesActionTypes.getConversationsSilently,
});

export const updateThreadsSilently = (payload: any) => ({
	type: EMessagesActionTypes.updateThreadsSilently,
	payload,
});

export const updateMessagesThreads = (communityId: number, data: any) => ({
	type: EMessagesActionTypes.updateMessagesThreads,
	payload: {
		communityId,
		data,
	},
});

export const setConversationLoading = (payload: {
	conversationId: number;
	loading: boolean;
}) => ({
	type: EMessagesActionTypes.conversationLoadingSet,
	payload,
});

export const sendMessageAction = (payload: {
	postId: number;
	content: string;
	recipientId: number;
	conversationId: number;
}) => ({
	type: EMessagesActionTypes.sendMessage,
	payload,
});

export const addMessageToThread = ({
	communityId,
	conversationId,
	message,
	recipientId,
}: {
	communityId: number;
	conversationId: number;
	message: string;
	recipientId: number;
}) => ({
	type: EMessagesActionTypes.addMessageToThread,
	payload: {
		communityId,
		conversationId,
		message,
		recipientId,
	},
});

export const updateMessageCounter = (payload: number) => ({
	type: EAppActionTypes.updateMessageCounter,
	payload,
});

export const fetchConversation = (payload: {
	conversationId: number;
	communityPostId: number;
	recipientId: number;
	page?: number;
	shouldOpen?: boolean;
}) => ({
	type: EMessagesActionTypes.fetchConversation,
	payload,
});

export const updateConversation = ({
	data,
	meta,
}: {
	data: IConversationData[] | null;
	meta: IConversationMeta | null;
}) => ({
	type: EMessagesActionTypes.updateConversation,
	payload: {
		data,
		meta,
	},
});

// export const initiatePrivateChat = (payload: { member: IMember }) => ({
// 	type: EMessagesActionTypes.initiatePrivateChat,
// 	payload,
// });

export const createPinnedConversation = (payload: IMessageThread | null) => ({
	type: EMessagesActionTypes.createPinnedConversation,
	payload,
});

export const setSelectedThread = (payload: IMessageThread | null) => ({
	type: EMessagesActionTypes.setSelectdThread,
	payload,
});
export const setConversationErrorMessage = (payload: { error: string }) => ({
	type: EMessagesActionTypes.setConversationErrorMessage,
	payload,
});

// export const updatePinnedConversation = (payload: IMessageThread | null) => ({
// 	type: EMessagesActionTypes.updatePinnedConversation,
// 	payload,
// });
