import { EPostActionTypes, IAddUpdatePost, IPostState } from "models";

export const setPostLoading = (payload: boolean) => ({
	type: EPostActionTypes.postLoadingSet,
	payload,
});

export const postStoreAdd = (payload: any) => ({
	type: EPostActionTypes.postStoreAdd,
	payload,
});

export const postUpdateStore = (payload: any) => ({
	type: EPostActionTypes.postUpdateStore,
	payload,
});

export const addPost = (payload: IAddUpdatePost) => ({
	type: EPostActionTypes.addPost,
	payload,
});

export const sendCommentForPost = (payload: {
	postId: number;
	content: string;
}) => ({
	type: EPostActionTypes.sendCommentForPost,
	payload,
});

export const getEditPostAction = (payload: {
	id: number;
	callbackOnSuccess?: (post: IPostState["data"]) => void;
	callbackOnError?: () => void;
}) => ({
	type: EPostActionTypes.postEditGet,
	payload,
});

export const deletePostAction = (payload: {
	postId: number;
	location: string;
}) => ({
	type: EPostActionTypes.postDelete,
	payload,
});

export const setPost = (payload: any) => ({
	type: EPostActionTypes.postSet,
	payload,
});

export const resetPost = () => ({
	type: EPostActionTypes.postReset,
});

export const deletePostComment = (payload: {
	postId: number;
	commentId: number;
}) => ({
	type: EPostActionTypes.deletePostComment,
	payload,
});

export const deleteLocalPostComment = (payload: {
	postId: number;
	commentId: number;
}) => ({
	type: EPostActionTypes.deleteLocalPostComment,
	payload,
});

export const editLocalPostComment = (payload: {
	postId: number;
	commentId: number;
	content: string;
}) => ({
	type: EPostActionTypes.editLocalPostComment,
	payload,
});

export const editPostComment = (payload: {
	postId: number;
	commentId: number;
	content: string;
}) => ({
	type: EPostActionTypes.editPostComment,
	payload,
});

export const getPostRawData = (payload: {
	id: number;
	callbackOnSuccess: () => void;
	callbackOnError: () => void;
	forceCommunityId?: number;
}) => ({
	type: EPostActionTypes.postRawDataGet,
	payload,
});
