import { all, call, put, select, takeEvery } from "redux-saga/effects";

import { EMediaItemActionTypes, TStore } from "models";
import { api } from "store";
import {
	fetchMediaItemAction,
	mediaItemLoadingSetAction,
	mediaItemSetAction,
	setDocsPathAction,
	setResourcesPathAction,
	setVideosPathAction,
} from "store/actions";
import { rollbar } from "utils";

const getCommunityId = (state: TStore) => state.app.currentCommunityId;

function* fetchItemHandle({
	payload,
}: ReturnType<typeof fetchMediaItemAction>) {
	const { type, mediaItemId } = payload;
	let folderId = 0;
	switch (type) {
		case "documents": {
			folderId = yield select(
				(state: TStore) => state.documents?.currentFolder,
			);
			break;
		}
		case "resources": {
			folderId = yield select(
				(state: TStore) => state.resources?.currentFolder,
			);
			break;
		}
		default:
			folderId = 0;
	}

	try {
		yield put(mediaItemLoadingSetAction(true));
		const communityId: number = yield select(getCommunityId);

		//@ts-ignore
		const { data } = yield call(api.docs[type + "One"], {
			communityId,
			id: mediaItemId,
			folderId: folderId ?? 0,
		});
		switch (type) {
			case "resources":
				if (data.resources && data.resources[0]) {
					yield put(mediaItemSetAction(data.resources[0]));
				}
				break;
			case "documents":
				if (data.documents && data.documents[0]) {
					yield put(mediaItemSetAction(data.documents[0]));
				}
				break;
		}
		yield put(mediaItemLoadingSetAction(false));
	} catch (error) {
		rollbar.error(error)
		console.error({ error });
		yield put(mediaItemLoadingSetAction(true));
	}
}

const pathByType: any = {
	videos: setVideosPathAction,
	resources: setResourcesPathAction,
	documents: setDocsPathAction,
};

function* setMediaPathHandle({ payload }: any) {
	try {
		const { type, ...rest } = payload;
		yield put(pathByType[type](rest));
	} catch (error) {
		rollbar.error(error)

	}
}

export default function* rootSaga() {
	yield all([
		takeEvery(EMediaItemActionTypes.fetchItem, fetchItemHandle),
		takeEvery(EMediaItemActionTypes.setPath, setMediaPathHandle),
	]);
}
