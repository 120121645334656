import { ECommunityAccessStatus, IPost } from "models";
import { ICommunityAppeareance } from "models/communities";
import {
	IPostsLoadingSetAction,
	IPostsGetAction,
	IPostsSetAction,
	ISetFilterTagsPostsAction,
	IRemoveFilterTagPostsAction,
	IRemoveFilterTagAction,
	ISetFilterTagAction,
	ISetPostsQueryAction,
	IPostsGetPayload,
	ISetPostsShouldRefreshAction,
} from "./actions";

// ENUMS
export enum EPostsActionTypes {
	postsLoadingSet = "posts/set/loading",
	getPosts = "posts/get",
	setPosts = "posts/set",
	setFilterTagsPosts = "posts/set/filter/tag/posts", //used in sagas
	removeFilterTagPosts = "posts/remove/filter/tag/posts", //used in sagas
	setFilterTagsMiddleware = "posts/set/filter/tag", // used in reducer
	removeFilterTagMiddleware = "posts/remove/filter/tag", //used in reducer
	setQueryAndFilter = "posts/query/setAndFilter",
	setPostsQuery = "posts/set/query", //used in reducer
	getPostsSilently = "POSTS/GET/SILENTLY",
	setShouldRefresh = "POSTS/SHOULD_REFRESH/SET",
}

// INTERFACES
export interface ITags {
	id: number;
	name: string;
	is_selected: boolean;
}

export interface IGetPosts {
	communityId: string;
}

export interface ILinkedCommunityDocuments {
	id: number;
	original_filename: string;
	title: string;
	folder_id: null | number;
	document_section_name: string;
	folder_tree: any;
	mentions: string[];
}

export interface IPostsMeta {
	appearance?: ICommunityAppeareance;
	pagination: {
		current_page: number;
		per_page: number;
		total_pages: number;
		total_objects: number;
		has_next_page: boolean;
	};
	notification_counter?: number;
	privacy: string;
	scope_group: any; //TODO: review this property and add an specific type
	access_status: ECommunityAccessStatus;
	is_approved: boolean;
}

export interface IPostsState {
	loading: boolean;
	data: IPost[];
	tags?: ITags[];
	selectedTags?: string[];
	query?: string;
	shouldRefresh?: Boolean;
	meta: IPostsMeta;
}

export interface IPostsLoadingPayload {
	loading: boolean;
}

// TYPES
export type TPostsActions =
	| IPostsSetAction
	| IPostsLoadingSetAction
	| IPostsGetAction
	| ISetFilterTagsPostsAction
	| IRemoveFilterTagPostsAction
	| IRemoveFilterTagAction
	| ISetFilterTagAction
	| IPostsGetPayload
	| ISetPostsQueryAction
	| ISetPostsShouldRefreshAction;
