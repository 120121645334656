import React, { memo, useCallback, useRef, useState } from "react";
import { Menu, Dropdown } from "antd";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";

import styles from "./styles.module.scss";
import { TStore } from "models";
import { List } from "./components";
import { useDispatch } from "react-redux";
import { getNotificationsAction } from "store/actions/notifications";
import { ReactComponent as Notification } from "assets/images/notificationIcon.svg";
const stateSelectorHandle = createSelector(
	(state: TStore) => state.notifications.unreadNotificationsCounter,
	(unreadNotificationsCounter) => ({
		unreadNotificationsCounter,
	}),
);

const NotificationsMenu = () => {
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { unreadNotificationsCounter } = useSelector(stateSelector);
	const current = useRef<any>({});
	const dispatch = useDispatch();

	const [open, setOpen] = useState(false);

	const handleOpenChange = (flag: boolean) => {
		if (flag) {
			dispatch(getNotificationsAction({ reset: true }));
		}

		setOpen(flag);
	};

	const notificationsMenu = (
		<Menu>
			<List setOpen={setOpen} />
		</Menu>
	);

	if (!current.current) return null;
	return (
		<Dropdown
			className={styles.userIcon}
			overlay={notificationsMenu}
			placement="bottomRight"
			overlayStyle={{ width: 380 }}
			onOpenChange={handleOpenChange}
			open={open}
		>
			<div className={styles.imgContainer}>
				{unreadNotificationsCounter &&
				unreadNotificationsCounter > 0 ? (
					<div className={styles.redBullet}>
						{unreadNotificationsCounter > 9
							? "9+"
							: unreadNotificationsCounter}
					</div>
				) : null}
				<Notification />
			</div>
		</Dropdown>
	);
};

export default memo(NotificationsMenu);
