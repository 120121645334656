import {
	all,
	takeEvery,
	call,
	select,
	put,
	takeLatest,
} from "redux-saga/effects";

import {
	EMessagesActionTypes,
	IMessagesGetConversationsAction,
	TStore,
	IMessageThread,
	IUser,
} from "models";
import {
	api,
	setMessagesLoading,
	updateMessagesThreads,
	setConversationLoading,
	addMessageToThread,
	updateMessageCounter,
	createPinnedConversation,
	setSelectedThread,
	getConversations,
	updateThreadsSilently,
	sendMessageAction,
	getConversationsSilently,
	fetchConversation,
	updateConversation,
	setConversationErrorMessage,
} from "store";
import { rollbar } from "utils";

const getCurrentCommunityId = (state: TStore) => state.app.currentCommunityId;
const getCurrentUser = (state: TStore) => state.auth.credentials?.user;
const getPinnedConversation = (state: TStore) =>
	state.messages.pinnedConversation;
const getThreadsPagination = (communityId: number) => (state: TStore) => {
	return state.messages.threads?.[communityId]?.meta?.pagination;
};
const getConversation = () => (state: TStore) => state.messages.conversation;

function* sendMessageHandle({ payload }: ReturnType<typeof sendMessageAction>) {
	try {
		const { postId, recipientId, content, conversationId } = payload;

		const user: IUser = yield select(getCurrentUser);
		const communityId: number = yield select(getCurrentCommunityId);
		if (!user || !communityId) {
			throw new Error("Missing parameters.");
		}

		const pinnedConversation: IMessageThread | null = yield select(
			getPinnedConversation,
		);
		const { data } = yield call(api.messages.sendMessage, {
			content,
			postId,
			recipientId,
			communityId,
			userId: user.id,
			conversationId,
		});

		if (!payload.conversationId) {
			yield put(setMessagesLoading(true));
			const { data: threads } = yield call(
				api.messages.getConversations,
				{
					// ...payload,
					page: 1, // TODO: page parameter is not sent in payload. I need to check this one.
					communityId,
					userId: user.id,
				},
			);
			yield put(
				updateMessageCounter(
					threads.posts.reduce(
						(acc: number, cur: any) => acc + cur.message_counter,
						0,
					),
				),
			);

			const newThread = threads.posts.filter(
				(item: IMessageThread) => item.id === data.conversation.id,
			);

			yield put(
				setSelectedThread(newThread.length === 1 ? newThread[0] : null),
			);

			if (
				conversationId === 0 ||
				pinnedConversation?.id === newThread[0].id
			) {
				// update the pinned conversation content
				yield put(
					createPinnedConversation(
						newThread.length === 1 ? { ...newThread[0] } : null,
					),
				);
			}
			yield put(updateMessagesThreads(communityId, threads));
		}
		yield put(
			addMessageToThread({
				communityId,
				conversationId: payload.conversationId || payload.postId,
				message: data.message,
				recipientId,
			}),
		);
	} catch (error) {
		rollbar.error(error)
		console.error({ error });
	}
}

function* getConversationsHandle({ payload }: IMessagesGetConversationsAction) {
	if (!payload || !payload.skipLoading) yield put(setMessagesLoading(true));
	try {
		const communityId = yield select(getCurrentCommunityId);
		const pagination = yield select(getThreadsPagination(communityId));
		const user = yield select(getCurrentUser);
		if (communityId && user) {
			const skip =
				payload &&
				payload.reset &&
				pagination &&
				parseInt(pagination.current_page) === 1;
			if (!skip) {
				const { data } = yield call(api.messages.getConversations, {
					page:
						(payload && payload.reset) || !pagination
							? 1
							: parseInt(pagination.current_page) + 1,
					communityId,
					userId: user.id,
				});
				yield put(
					updateMessageCounter(
						data.posts.reduce(
							(acc: number, cur: any) =>
								acc + cur.message_counter,
							0,
						),
					),
				);
				yield put(updateMessagesThreads(communityId, data));
			}
		}
		yield put(setMessagesLoading(false));
	} catch (error) {
		rollbar.error(error)
		console.error({ error });
		yield put(setMessagesLoading(false));
	}
}

function* getConversationsSilentlyHandle() {
	try {
		const communityId = yield select(getCurrentCommunityId);
		const pagination = yield select(getThreadsPagination(communityId));
		const user = yield select(getCurrentUser);

		if (!pagination) yield put(getConversations());
		else {
			let total = 0;
			let orderedThreads: number[] = [];
			let updates: any = {};
			for (
				let page = 1;
				page <= parseInt(pagination.current_page);
				page++
			) {
				const { data } = yield call(api.messages.getConversations, {
					page,
					communityId,
					userId: user.id,
				});
				total += data.posts.reduce(
					(acc: number, cur: any) => acc + cur.message_counter,
					0,
				);
				orderedThreads = [
					...orderedThreads,
					...data.posts.map((item: IMessageThread) => item.id),
				];
				updates = {
					...updates,
					...data.posts.reduce(
						(acc: { [key: number]: any }, cur: IMessageThread) => {
							acc[cur.id] = {
								...cur,
							};

							return acc;
						},
						{},
					),
				};
			}
			yield put(updateMessageCounter(total));
			yield put(
				updateThreadsSilently({
					communityId,
					orderedThreads,
					updates,
				}),
			);
		}
	} catch (error) {
		rollbar.error(error)
		console.error({ error });
	}
}

function* fetchConversationHandle({
	payload,
}: ReturnType<typeof fetchConversation>) {
	const { conversationId, page } = payload;

	try {
		const communityId: number = yield select(getCurrentCommunityId);
		const user: IUser = yield select(getCurrentUser);
		const conversation = yield select(getConversation());

		if (!communityId || !user) {
			throw new Error("Missing parameters!");
		}

		const parsedPage = page
			? page
			: conversation?.meta?.pagination?.current_page
				? conversation?.meta?.pagination?.current_page + 1
				: 1;

		const {
			data: { mailbox_badge },
		} = yield call(api.messages.openConversation, {
			...payload,
			communityId,
			userId: user.id,
			page: parsedPage,
		});
		yield put(updateMessageCounter(mailbox_badge));

		const { data } = yield call(api.messages.getConversation, {
			...payload,
			communityId,
			userId: user.id,
			page: parsedPage,
		});

		if (!conversation.data) {
			yield put(
				setConversationLoading({
					conversationId,
					loading: true,
				}),
			);

			yield put(getConversationsSilently());
			yield put(
				updateConversation({
					meta: data.meta,
					data: data.conversation.reduce(
						(
							acc: { [key: number]: object },
							cur: { [key: string]: any },
						) => {
							acc[cur.id] = cur;

							return acc;
						},
						{},
					),
				}),
			);
		} else {
			const existingMessages: string[] = Object.keys(conversation.data);

			yield put(
				updateConversation({
					data: data.conversation.reduce(
						(
							acc: { [key: number]: object },
							cur: { [key: string]: any },
						) => {
							acc[cur.id] = cur;

							return acc;
						},
						{},
					),
					meta: data.meta,
				}),
			);
			//   if (payload.shouldOpen) {
			if (
				data.conversation.filter(
					(item: IMessageThread) =>
						existingMessages.indexOf(item.id.toString()) === -1,
				).length!!
			) {
				yield put(getConversationsSilently());
			}
		}
		yield put(
			setConversationErrorMessage({
				error: "",
			}),
		);
	} catch (error: any) {
		rollbar.error(error)
		if (
			error?.response?.data?.errors ===
			"Conversations with this user are disabled"
		) {
			yield put(
				setConversationErrorMessage({
					error: `${error.response.data.errors}.`,
				}),
			);
			// for the situations when the user select a thread with a blocked user
			// the getConversationsSilently needs to be triggered to update the thread's messages count.
			yield put(getConversationsSilently());
		}
		console.error({ error });
	}
}

export default function* rootSaga() {
	yield all([
		takeEvery(EMessagesActionTypes.sendMessage, sendMessageHandle),
		takeEvery(
			EMessagesActionTypes.getConversations,
			getConversationsHandle,
		),
		takeEvery(
			EMessagesActionTypes.getConversationsSilently,
			getConversationsSilentlyHandle,
		),

		takeLatest(
			EMessagesActionTypes.fetchConversation,
			fetchConversationHandle,
		),
	]);
}
