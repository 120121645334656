import { EResourceActionTypes, IDoc, IMetaData, IVideo } from "models";

export const getResource = (payload: {
	id: number;
	callbackOnSuccess: () => void;
	callbackOnError: () => void;
	folderId: number | null;
	forceCommunityId?: number;
}) => ({
	type: EResourceActionTypes.getResource,
	payload,
});

export const getResourceForProgressbar = (payload: {
	id: number;
	callbackOnSuccess?: () => void;
	callbackOnError?: () => void;
	folderId?: number | null;
	forceCommunityId?: number;
}) => ({
	type: EResourceActionTypes.getResourceForProgressbar,
	payload,
});

export const setResource = (payload: {
	data: IVideo | IDoc;
	meta: IMetaData;
}) => ({
	type: EResourceActionTypes.setResource,
	payload,
});

export const setResourceUploadProgressAction = (
	payload: number | undefined,
) => ({
	type: EResourceActionTypes.setResourceUploadProgress,
	payload,
});

export const setResourceIdAction = (payload: number | undefined) => ({
	type: EResourceActionTypes.setResourceId,
	payload,
});

export const setResourceSuccessfullyUploadedAction = (
	payload: boolean | undefined,
) => ({
	type: EResourceActionTypes.setResourceSuccessfullyUploaded,
	payload,
});

export const setRerourceCreatedIntervalAction = (
	payload: number | undefined,
) => ({
	type: EResourceActionTypes.setInterval,
	payload,
});
