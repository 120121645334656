import {
    call,
    put,
    all,
    takeEvery,
    select,
    takeLatest,
} from "redux-saga/effects";
import {
    EPostsActionTypes,
    EPostActionTypes,
    IPostSendCommentPayload,
    TStore,
} from "models";
import {
    api,
    setPostsLoading,
    setPosts,
    setPostLoading,
    notify,
    setFilterTagsPosts,
    postStoreAdd,
    getPosts,
    setPost,
    setQueryPostsAction,
    appendLocalPostComments,
    setPostsShouldReload,
    addPost,
    history,
} from "store";
import { hexToRgbA, rollbar, isItAWhiteLabel } from "utils";

const getCurrentCommunityId = (state: TStore) => state.app.currentCommunityId;
const getGroupId = (state: TStore) => state.app.currentGroupId;
const getPostsFromState = (state: TStore) => state.posts;
const getCommunityTags = (state: any) => state.community.mainTags.tags;

function* sendCommentForPostHandle({ payload }: IPostSendCommentPayload) {
    try {
        const communityId = yield select(getCurrentCommunityId);
        const { data }: any = yield call(api.posts.comment, {
            ...payload,
            communityId,
        });
        yield put(
            appendLocalPostComments(payload.postId, {
                comments: [data.comment],
            }),
        );
    } catch (error) {
        rollbar.error(
            error
        )
        console.error({ error });
    }
}

function* getPostsSilentlyHandle() {
    try {
        let communityId = yield select(getCurrentCommunityId);
        const groupId = yield select(getGroupId);
        const posts = yield select(getPostsFromState);
        const { data } = yield call(api.posts.getHomePosts, {
            communityId,
            groupId,
            query:
                posts.selectedTags && posts.selectedTags.length > 0
                    ? posts.selectedTags[0]
                    : posts.query,
            tagClicked:
                posts.selectedTags && posts.selectedTags.length > 0
                    ? true
                    : false,
        });

        if (
            (data?.posts[0] && !posts.data[0]) ||
            data?.posts[0].id !== posts.data[0].id
        ) {
            yield put(setPostsShouldReload(true));
        } else {
            yield put(setPostsShouldReload(false));
        }
    } catch (error) {
        rollbar.error(
            error
        )
        console.error({ error });
    }
}

function* getPostsHandle({ payload }: ReturnType<typeof getPosts>) {
    const {
        communityId: payloadCommunityId,
        page,
        resetPosts = false,
        onError,
        onSuccess,
    } = payload;
    let currentCommunityId = yield select(getCurrentCommunityId);
    yield put(setPostsLoading(true));
    try {
        if (!payloadCommunityId && !currentCommunityId) {
            throw new Error("Missing parameters.");
        }
        const communityId = payloadCommunityId ?? currentCommunityId;

        const groupId = yield select(getGroupId);
        const posts = yield select(getPostsFromState);
        const { data } = yield call(api.posts.getHomePosts, {
            communityId,
            groupId,
            query:
                posts.selectedTags && posts.selectedTags.length > 0
                    ? posts.selectedTags[0]
                    : posts.query,
            tagClicked:
                posts.selectedTags && posts.selectedTags.length > 0
                    ? true
                    : false,
            page,
        });

        if (!data) {
            yield put(setPostsLoading(false));
        } else {
            yield put(
                setPosts({
                    data,
                    resetPosts: resetPosts,
                }),
            );
            const {
                post_header_background,
                announcement_header_background,
                header_background,
                primary_color,
                secondary_color,
            } = data.meta.appearance;

            document.documentElement.style.setProperty(
                "--headerBackground",
                header_background,
            );
            document.documentElement.style.setProperty(
                "--headerBackgroundRgb",
                hexToRgbA(header_background),
            );

            document.documentElement.style.setProperty(
                "--announcementHeaderBackground",
                announcement_header_background,
            );
            document.documentElement.style.setProperty(
                "--announcementHeaderBackgroundRgb",
                hexToRgbA(announcement_header_background),
            );
            document.documentElement.style.setProperty(
                "--postHeaderBackground",
                post_header_background,
            );
            document.documentElement.style.setProperty(
                "--postHeaderBackgroundRgb",
                hexToRgbA(post_header_background),
            );
            document.documentElement.style.setProperty(
                "--primaryColor",
                primary_color,
            );
            document.documentElement.style.setProperty(
                "--primaryColorRgb",
                hexToRgbA(primary_color),
            );
            document.documentElement.style.setProperty(
                "--secondaryColor",
                secondary_color,
            );
            document.documentElement.style.setProperty(
                "--secondaryColorRgb",
                hexToRgbA(secondary_color),
            );
            yield put(setPostsLoading(false));
        }
        onSuccess?.();
    } catch (error) {
        rollbar.error(
            error
        )
        if (
            error.response &&
            error.response.data &&
            error.response.data &&
            error.response.data.meta
        ) {
            if (
                Object.keys(error.response.data.meta).indexOf("is_approved") !==
                -1 &&
                !error.response.data.meta.is_approved
            ) {
                if (!isItAWhiteLabel()) {
                    yield history.push("/communities");
                } else {
                    yield history.push("/joining/init");
                }
            }
        }
        console.error({ error });
        yield put(setPostsLoading(false));
        onError?.();
    }
}

function* postAddHandle({ payload }: ReturnType<typeof addPost>) {
    const image = payload.community_post.image;
    yield put(setPostLoading(true));
    try {
        let { data } = yield call(
            payload.communityPostId ? api.posts.update : api.posts.add,
            payload,
        );
        if (!data) {
            yield put(setPostLoading(false));
            yield call(
                notify,
                "error",
                "Error!",
                "The post could not be saved!",
            );
        } else {
            if (image) {
                // save the image
                yield call(api.posts.addImage, {
                    community_id: payload.community_id,
                    community_post_id: data.community_post.id,
                    image,
                });
                const { data: postData } = yield call(api.posts.getPostEdit, {
                    community_id: payload.community_id,
                    community_post_id: data.community_post.id,
                });
                data.community_post.images = postData.community_post.images;
            }
            // add the returned post in posts store
            if (payload.communityPostId) {
                yield put(setPost({}));
                yield put(getPosts({ resetPosts: true }));
                yield call(
                    notify,
                    "success",
                    "Success!",
                    "Post updated successfully!",
                );
            } else {
                // if(data.meta)
                if (data.meta.needs_approval) {
                    yield call(
                        notify,
                        "success",
                        "Success!",
                        data?.meta?.message as string,
                    );
                }

                else {
                    yield put(postStoreAdd(data));
                    yield call(
                        notify,
                        "success",
                        "Success!",
                        "Post created successfully!",
                    );
                }
            }
            yield put(setPostLoading(false));
        }
    } catch (error) {
        rollbar.error(
            error
        )
        console.error({ error });
        yield put(setPostLoading(false));
    }
}

function* handleSetFilterTags({ payload }: any) {
    const tags: {
        id: number;
        name: string;
        is_selected: boolean;
    }[] = yield select(getCommunityTags);
    const parsedTags = tags
        .filter((item) =>
            payload.tag.find((element: string) => element === item.name),
        )
        .map((item) => item.name);

    yield put(setFilterTagsPosts({ parsedTags }));
    return {
        selectedTags: parsedTags,
    };
}

function* handleSetQueryAndFilterTags({ payload }: any) {
    try {
        yield put(setQueryPostsAction({ query: payload }));
        yield put(getPosts({ resetPosts: true }));
    } catch (error) {
        rollbar.error(
            error
        )
        console.error({ error });
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(
            EPostActionTypes.sendCommentForPost,
            sendCommentForPostHandle,
        ),
        takeLatest(EPostsActionTypes.getPosts, getPostsHandle),
        takeEvery(EPostsActionTypes.getPostsSilently, getPostsSilentlyHandle),
        takeEvery(
            EPostsActionTypes.setFilterTagsMiddleware,
            handleSetFilterTags,
        ),
        takeEvery(
            EPostsActionTypes.setQueryAndFilter,
            handleSetQueryAndFilterTags,
        ),
        takeEvery(EPostActionTypes.addPost, postAddHandle),
    ]);
}
