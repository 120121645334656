import {
	IMarketplaceOffersState,
	TMarketplaceOffersActions,
	EMarketplaceOffersActionTypes,
} from "models";

const initial: IMarketplaceOffersState = {
	loading: false,
	pins: [],
	tags: [],
	selectedTags: [],
	query: "",
	meta: {
		pagination: {
			current_page: "1",
			per_page: 10,
			total_pages: 0,
			total_objects: 0,
			has_next_page: false,
		},
	},
};

export function marketplaceOffersReducer(
	state = initial,
	action: TMarketplaceOffersActions,
): IMarketplaceOffersState {
	switch (action.type) {
		case EMarketplaceOffersActionTypes.marketplaceOffersLoadingSet: {
			return {
				...state,
				loading: action.payload,
			};
		}
		case EMarketplaceOffersActionTypes.setMarketplaceOffers: {
			const { data, reset } = action.payload;
			const pins = reset
				? data.pins
				: state?.pins
				? [...state.pins].concat(data.pins)
				: data.pins;

			return {
				...state,
				pins,
				meta: data.meta,
			};
		}
		case EMarketplaceOffersActionTypes.setMarketplaceOfferInStore: {
			const { data, newMarketplaceOffer } = action.payload;
			if (data) {
				if (newMarketplaceOffer) {
					const newPinsData = [...state.pins];
					newPinsData.unshift(data);
					return {
						...state,
						pins: newPinsData,
					};
				} else {
					const marketplacePostIndex = state.pins.findIndex(
						(item) => item.id === data.id,
					);

					const newPins = [...state.pins];

					newPins[marketplacePostIndex] = {
						...newPins[marketplacePostIndex],
						...data,
					};

					return {
						...state,
						pins: newPins,
					};
				}
			} else {
				return {
					...state,
				};
			}
		}
		case EMarketplaceOffersActionTypes.setMarketplaceOffersTags: {
			return {
				...state,
				tags: action.payload.tags,
			};
		}
		case EMarketplaceOffersActionTypes.setMarketplaceOffersQuery: {
			return {
				...state,
				query: action.payload.query,
			};
		}
		case EMarketplaceOffersActionTypes.setFilterTagsMarketplaceOffers: {
			return {
				...state,
				selectedTags: action.payload.tags,
			};
		}

		case EMarketplaceOffersActionTypes.removeFilterTagMarketplaceOffers: {
			const selectedTags = state.selectedTags
				? [...state.selectedTags]
				: [];

			const parsedTags = selectedTags.filter(
				(item) => item !== action.payload.tagId,
			);

			return {
				...state,
				selectedTags: parsedTags,
			};
		}

		default:
			return state;
	}
}
