import {
	IEditProfileState,
	EProfileActionTypes,
	TProfileActions,
} from "models";

const initialEditProfile: IEditProfileState = {
	loading: false,
	data: {
		user: {
			id: "0",
			first_name: "",
			last_name: "",
			location: "",
			about_me: "",
			email: "",
			name: "",
			member_directory_opt_in: false,
			industry: "",
			profession: "",
		},
		posts: {
			pins: [],
			meta: {
				pagination: {
					current_page: 0,
					per_page: 0,
					total_pages: 0,
					total_objects: 0,
					has_next_page: false,
				},
			},
		},
	},
};

// EDIT
export function editProfileReducer(
	state = initialEditProfile,
	action: TProfileActions,
): IEditProfileState {
	switch (action.type) {
		case EProfileActionTypes.editProfileLoadingSet: {
			return {
				...state,
				loading: action.payload,
			};
		}
		case EProfileActionTypes.setEditProfile: {
			return {
				...state,
				data: {
					...state.data,
					user: action.payload.user,
				},
			};
		}
		case EProfileActionTypes.setEditProfilePosts: {
			return {
				...state,
				data: {
					...state.data,
					posts: {
						...state.data.posts,
						pins:
							parseInt(
								action.payload.data.meta.pagination
									.current_page,
							) === 1
								? action.payload.data.pins
								: state.data.posts.pins.concat(
										action.payload.data.pins,
								  ),
						meta: {
							...state.data.posts.meta,
							pagination: {
								...action.payload.data.meta.pagination,
							},
						},
					},
				},
			};
		}
		default:
			return state;
	}
}
