import React, { useState, useCallback } from "react";
import {
	PaymentElement,
	useElements,
	useStripe,
} from "@stripe/react-stripe-js";
import { createSelector } from "reselect";
import { Button } from "antd";

import { ECommunityType, IMemberPlanPriceOptions, TStore } from "models";
import { useSelector } from "react-redux";
import { notify } from "store";
import styles from "./styles.module.scss";
import { Loading } from "components";
import { StripePaymentElementChangeEvent } from "@stripe/stripe-js";
import { useSelectCommunityByProcess } from "utils";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.payments.memberSubscription,
	(state: TStore) => state.auth?.credentials?.user,
	(state: TStore) => state.communities?.selectedCommunityToJoinInfo,
	(
		paymentsMemberSubscription,
		authenticatedUser,
		selectedCommunityToJoinInfo,
	) => ({
		paymentsMemberSubscription,
		authenticatedUser,
		selectedCommunityToJoinInfo,
	}),
);

interface ISetupForm {
	typeOfCommunity: ECommunityType;
	memberPlanPrice: IMemberPlanPriceOptions | null;
}

const SetupForm: React.FunctionComponent<ISetupForm> = ({
	typeOfCommunity,
	memberPlanPrice,
}) => {
	const stripe = useStripe();
	const elements = useElements();
	const [message, setMessage] = useState<null | string>(null);
	const [theFormIsReady, setTheFormIsReady] = useState(false);
	const [isThePaymentElementCompleted, setIsThePaymentElementCompleted] =
		useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { paymentsMemberSubscription, authenticatedUser } =
		useSelector(stateSelector);
	const { resultedCommunity } = useSelectCommunityByProcess({
		typeOfCommunity,
	});

	const handleSubmit = useCallback(
		async (event: any) => {
			// We don't want to let default form submission happen here,
			// which would refresh the page.
			event.preventDefault();

			if (
				!stripe ||
				!elements ||
				!paymentsMemberSubscription?.setup_intent_client_secret ||
				!paymentsMemberSubscription?.payload?.subscription_id ||
				!resultedCommunity ||
				!memberPlanPrice?.stripe_id ||
				!authenticatedUser
			) {
				// Stripe.js has not yet loaded.
				// Make sure to disable form submission until Stripe.js has loaded.
				notify("error", "Something went wrong.", "Payment form error.");
				return;
			}
			setIsLoading(true);
			stripe.elements({
				clientSecret:
					paymentsMemberSubscription.setup_intent_client_secret,
			});

			const { error } = await stripe.confirmSetup({
				//`Elements` instance that was used to create the Payment Element
				elements,
				confirmParams: {
					return_url: `${window.location.origin}/processing-setup-intent-payment?stripe_plan_price_id=${memberPlanPrice?.stripe_id}&current_subscription_id=${paymentsMemberSubscription.payload.subscription_id}&community_id=${resultedCommunity.id}&type_of_community=${typeOfCommunity}&subscription_access_status=${paymentsMemberSubscription.payload?.subscription_status}`,
				},
			});

			if (
				error.type === "card_error" ||
				error.type === "validation_error"
			) {
				// This point will only be reached if there is an immediate error when
				// confirming the payment. Show error to your customer (e.g., payment
				// details incomplete)
				console.error(error);
				setMessage(error.message ?? null);
				setIsLoading(false);
			} else {
				// Your customer will be redirected to your `return_url`. For some payment
				// methods like iDEAL, your customer will be redirected to an intermediate
				// site first to authorize the payment, then redirected to the `return_url`.
				setMessage("An unexpected error occured.");
				setIsLoading(false);
			}
		},
		[
			authenticatedUser,
			elements,
			paymentsMemberSubscription,
			resultedCommunity,
			memberPlanPrice,
			stripe,
			typeOfCommunity,
		],
	);

	const handleOnChange = (event: StripePaymentElementChangeEvent) => {
		setIsThePaymentElementCompleted(event.complete);
	};

	return (
		<form className={styles.container} onSubmit={handleSubmit}>
			<PaymentElement
				onChange={handleOnChange}
				onReady={() => setTheFormIsReady(true)}
			/>
			{theFormIsReady ? (
				<>
					<Button
						htmlType="submit"
						disabled={
							isLoading ||
							!stripe ||
							!elements ||
							!isThePaymentElementCompleted
						}
						className={styles.submitBtn}
						loading={isLoading}
					>
						{"Add card"}
					</Button>
					{/* Show error message to your customers */}
					{message && (
						<div className={styles.errorMsg}>{message}</div>
					)}
				</>
			) : (
				<Loading />
			)}
		</form>
	);
};

export default SetupForm;
