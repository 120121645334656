import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import { useDispatch } from "react-redux";

import styles from "./styles.module.scss";
import { joinCommunity, notify } from "store";
import { ISelectedCommunityToJoin } from "models";
import { ReactComponent as X } from "assets/images/x.svg";

interface IMemberPlanNotAvailableModal {
	selectedCommunityToJoinInfo: ISelectedCommunityToJoin;
}

const MemberPlanNotAvailableModal = (props: IMemberPlanNotAvailableModal) => {
	const { selectedCommunityToJoinInfo } = props;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id, selected_member_plan } = selectedCommunityToJoinInfo;

	const goToCommunityPlans = useCallback(() => {
		if (!id) {
			notify("error", "An error occurred. Please try again later.");
			return;
		}
		dispatch(joinCommunity({ id: id }));
	}, [dispatch, id]);

	const goToCommunitiesList = useCallback(() => {
		navigate(-1);
	}, [navigate]);

	const modalInfo = {
		title: "",
		content: "",
	};

	modalInfo.title = selected_member_plan?.available
		? "Payment system updated."
		: "The member plan has been discontinued";

	modalInfo.content = selected_member_plan?.available
		? "Our payment system has been updated to support more currencies. Please confirm your currency preference."
		: "The previously selected member plan is not available anymore. Please select a different one.";

	return (
		<Modal
			title={modalInfo.title}
			visible={true}
			onOk={goToCommunityPlans}
			okType={"danger"}
			centered
			closable={false}
			cancelText={"Cancel"}
			cancelButtonProps={{ id: "btn-cancel-delete-account" }}
			okButtonProps={{ id: "btn-ok-leave-group" }}
			okText={"Ok"}
			onCancel={goToCommunitiesList}
			className={"modalContainer"}
			getContainer={() =>
				(document.getElementById("content") as HTMLElement)
					? (document.getElementById("content") as HTMLElement)
					: (document.getElementById("root") as HTMLElement)
			}
		>
			<div className={styles.modalContent}>{modalInfo.content}</div>
			<div onClick={goToCommunitiesList} className={"closeModal"}>
				<X fill="#FFFFFF" width={13} height={13} />
			</div>
		</Modal>
	);
};

export default MemberPlanNotAvailableModal;
