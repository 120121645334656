import { useState } from "react";

export const sessionStorageKeys = {
	ugenieHubLink: "ugenieHubLink",
};

export const useSessionStorage = ({
	key,
	initialValue = "",
}: {
	key: string;
	initialValue?: any;
}) => {
	const [storedValue, setStoredValue] = useState(() => {
		try {
			const item = window.sessionStorage.getItem(key);
			return item ? JSON.parse(item) : initialValue;
		} catch (err) {
			console.error(err);
			return initialValue;
		}
	});

	const setValue = (value: any) => {
		try {
			const valueToStore =
				value instanceof Function ? value(storedValue) : value;
			setStoredValue(valueToStore);
			window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
		} catch (err) {
			console.error(err);
		}
	};

	const deleteValue = (key: string) => {
		try {
			window.sessionStorage.removeItem(key);
			// reset the value.
			setStoredValue(null);
		} catch (err) {
			console.error(err);
		}
	};

	return { storedValue, setValue, deleteValue };
};
