import { ENotificationTypes } from "models";
import { memo, useMemo, ReactElement } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import { appChangeCommunity } from "store";
import { isItAWhiteLabel } from "utils";
import {
	CommunityEventNotificationDetails,
	DocNotificationDetails,
	MarketplaceOfferNotificationDetails,
	OfferNotificationDetails,
	PostNotificationDetails,
	ResourceNotificationDetails,
	VideoNotificationDetails,
} from "./components";

const NotificationDetails = memo(() => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location: {
		state: {
			notificationType: ENotificationTypes;
			objectId: number;
			communityId: number;
			folderId?: number;
			notifiableType?: string;
			body?: string;
		};
	} = useLocation();
	const {
		notificationType,
		objectId,
		communityId,
		folderId,
		notifiableType,
		body,
	} = location.state || {};

	const Component: ReactElement | null = useMemo(() => {
		if (notificationType && objectId && communityId) {
			switch (notificationType) {
				case ENotificationTypes.new_community_post:
				case ENotificationTypes.community_post_approved:
				case ENotificationTypes.new_community_post_access:
				case ENotificationTypes.new_community_post_mention: {
					return (
						<PostNotificationDetails
							postId={objectId}
							forceCommunityId={communityId}
						/>
					);
				}
				case ENotificationTypes.comment:
				case ENotificationTypes.comment_thread:
				case ENotificationTypes.comment_mention:
				case ENotificationTypes.comment_thread_mention:
					return (
						<PostNotificationDetails
							postId={objectId}
							forceCommunityId={communityId}
						/>
					);
				case ENotificationTypes.video_approved:
				case ENotificationTypes.new_community_video:
				case ENotificationTypes.new_community_video_access:
				case ENotificationTypes.new_community_video_mention: {
					return (
						<VideoNotificationDetails
							videoId={objectId}
							forceCommunityId={communityId}
						/>
					);
				}
				case ENotificationTypes.new_community_document:
				case ENotificationTypes.new_community_document_access:
				case ENotificationTypes.new_community_document_mention:
				case ENotificationTypes.document_approved:
					return (
						<DocNotificationDetails
							docId={objectId}
							forceCommunityId={communityId}
							folderId={folderId}
						/>
					);
				case ENotificationTypes.new_offer:
					return (
						<OfferNotificationDetails
							offerId={objectId}
							forceCommunityId={communityId}
						/>
					);
				case ENotificationTypes.new_community_good:
				case ENotificationTypes.community_good_approved:
				case ENotificationTypes.new_community_good_mention:
					return (
						<MarketplaceOfferNotificationDetails
							marketplaceId={objectId}
							forceCommunityId={communityId}
						/>
					);
				case ENotificationTypes.new_community_event:
					return (
						<CommunityEventNotificationDetails
							communityEventId={objectId}
							forceCommunityId={communityId}
						/>
					);
				case ENotificationTypes.tags_updated:
				case ENotificationTypes.profile_updated:
					navigate("/profile");
					return null;
				case ENotificationTypes.membership_type_updated:
					navigate("/profile");
					return null;
				case ENotificationTypes.new_resource:
				case ENotificationTypes.new_resource_access:
				case ENotificationTypes.resource_approved:
				case ENotificationTypes.new_community_resource_mention:
					return (
						<ResourceNotificationDetails
							resourceId={objectId}
							forceCommunityId={communityId}
							folderId={folderId ? folderId : null}
						/>
					);
				case ENotificationTypes.approved_membership: {
					// change the current community with the one the user was accepted into
					// and redirect the user in feed page.
					dispatch(
						appChangeCommunity({
							communityId,
						}),
					);
					return null;
				}
				case ENotificationTypes.new_community_invite: {
					navigate(
						isItAWhiteLabel() ? "/joining/init" : "/communities",
					);
					return null;
				}

				case ENotificationTypes.available_vimeo:
					if (notifiableType === "video") {
						return (
							<VideoNotificationDetails
								videoId={objectId}
								forceCommunityId={communityId}
								// folderId={folderId ? folderId : null}
							/>
						);
					} else if (notifiableType === "resource") {
						return (
							<ResourceNotificationDetails
								resourceId={objectId}
								forceCommunityId={communityId}
								folderId={folderId ? folderId : null}
							/>
						);
					} else {
						return (
							<DocNotificationDetails
								docId={objectId}
								forceCommunityId={communityId}
								folderId={folderId ? folderId : null}
							/>
						);
					}

				default:
					return <div>{body ? body : "New notification!"}</div>;
			}
		} else {
			return null;
		}
	}, [
		notificationType,
		objectId,
		communityId,
		folderId,
		body,
		notifiableType,
		dispatch,
		navigate,
	]);

	return Component;
});

export default NotificationDetails;
