import { IPost } from "models/post";
import {
	IMembersSetAction,
	IMembersGetAction,
	IMembersSetLoadingAction,
	IMembersSetFilterTagsAction,
	IMembersUpdateBanStatusMemberAction,
} from "./actions";

// ENUMS
export enum EMembersActionTypes {
	membersLoadingSet = "members/set/loading",
	getMembers = "members/get",
	setMembers = "members/set",
	setFilterTagsMembers = "member/set/filter/tags/members", // used in sagas
	updateTheBanStatusForMember = "members/update/ban/status/member",
}

// INTERFACES
// export interface IGetMembers {
//   payload: {
//     communityId: number;
//     q?: string;
//   };
// }

// export interface IGetMembersPayload extends IGetMembers {
//   type: any;
// }

export interface IMember {
	email: string;
	full_name: string;
	id: number;
	membership_id: number;
	membership_type: string;
	picture: string;
	location: string;
	distance: string;
	blocked: boolean;
	about_me: string;
	blocked_by: boolean;
	name?: string;
	posts?: { pins: IPost[] };
	industry: string | null;
	profession: string | null;
}

export interface IMembersState {
	loading: boolean;
	tags: string[];
	selectedTags: string[];
	users?: IMember[];
}

export interface IMembersLoadingPayload {
	loading: boolean;
}

// TYPES
export type TMembersActions =
	| IMembersSetAction
	| IMembersGetAction
	| IMembersSetLoadingAction
	| IMembersSetFilterTagsAction
	| IMembersUpdateBanStatusMemberAction;
