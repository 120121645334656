import { IOfferState, EOfferActionTypes, TOfferActions } from "models";

const initial: IOfferState = {
	loading: false,
	currentId: undefined,
	data: undefined,
};

export function offerReducer(
	state = initial,
	action: TOfferActions,
): IOfferState {
	switch (action.type) {
		case EOfferActionTypes.offerLoadingSet: {
			return {
				...state,
				loading: action.payload,
			};
		}
		case EOfferActionTypes.setOffer: {
			return {
				...state,
				data: action.payload.data,
			};
		}

		default:
			return state;
	}
}
