import { EPostsActionTypes } from "models";

export const setPostsLoading = (payload: boolean) => ({
	type: EPostsActionTypes.postsLoadingSet,
	payload,
});

export const getPosts = (payload: {
	resetPosts?: boolean;
	page?: number;
	communityId?: number;
	onSuccess?: () => void;
	onError?: () => void;
}) => ({
	type: EPostsActionTypes.getPosts,
	payload,
});

export const setPosts = (payload: any) => ({
	type: EPostsActionTypes.setPosts,
	payload,
});

export const setFilterTagsPosts = (payload: any) => ({
	type: EPostsActionTypes.setFilterTagsPosts,
	payload,
});

export const removeFilterTagPosts = (payload: any) => ({
	type: EPostsActionTypes.removeFilterTagPosts,
	payload,
});

export const setPostsQuery = (payload: any) => ({
	type: EPostsActionTypes.setPostsQuery,
	payload,
});

export const removeFilterTagMiddleware = (payload: any) => ({
	type: EPostsActionTypes.removeFilterTagMiddleware,
	payload,
});

export const setFilterTagsMiddleware = (payload: any) => ({
	type: EPostsActionTypes.setFilterTagsMiddleware,
	payload,
});

export const setPostsQueryAndFilterAction = (payload: any) => ({
	type: EPostsActionTypes.setQueryAndFilter,
	payload,
});

export const setQueryPostsAction = (payload: any) => ({
	type: EPostsActionTypes.setPostsQuery,
	payload,
});

export const getFeedSilently = () => ({
	type: EPostsActionTypes.getPostsSilently,
});

export const setPostsShouldReload = (payload: Boolean) => ({
	type: EPostsActionTypes.setShouldRefresh,
	payload,
});
