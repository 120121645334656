import {
	ENotificationsActionTypes,
	INotification,
	ISetNotificationsPayload,
} from "models/notifications";

export const setNotificationLoading =(payload:{loading:boolean,id:number}) => ({
	type: ENotificationsActionTypes.setNotificationLoading,
	payload,
})

export const getNotificationsSettingsAction = (payload: {
	callbackOnSuccess: () => void;
	callbackOnError: () => void;
}) => ({
	type: ENotificationsActionTypes.getNotificationsSettings,
	payload,
});

export const setNotificationsSettingsAction = (payload: {
	membership: { [key: string]: any };
}) => ({
	type: ENotificationsActionTypes.setNotificationsSettings,
	payload,
});
export const changeSettingStatusAction = (payload: { name: string }) => ({
	type: ENotificationsActionTypes.statusChange,
	payload,
});
export const getNotificationsAction = (payload?: {
	callbackOnSuccess?: () => void;
	callbackOnError?: () => void;
	reset?: boolean;
}) => ({
	type: ENotificationsActionTypes.getNotifications,
	payload,
});
export const setNotificationsAction = (payload: ISetNotificationsPayload) => ({
	type: ENotificationsActionTypes.setNotifications,
	payload,
});
export const openNotificationAction = (payload: {
	id: number;
	forceCommunityId?: number;
}) => ({
	type: ENotificationsActionTypes.openNotification,
	payload,
});
export const updateNotification = (payload: {
	notification: INotification;
}) => ({
	type: ENotificationsActionTypes.updateNotification,
	payload,
});
export const markAllAsReadSagaAction = (payload: {
	onSuccessCallback: () => void;
	onErrorCallback: () => void;
}) => ({
	type: ENotificationsActionTypes.markAllAsRead,
	payload,
});
export const getUnreadNotificationsCounterSagaAction = (payload?: {
	communityId?: number;
	onSuccessCallback?: () => void;
	onErrorCallback?: () => void;
}) => ({
	type: ENotificationsActionTypes.getUnreadNotificationsCounter,
	payload,
});
export const setUnreadNotificationsCounterAction = (payload: {
	unreadNotificationsCounter: number;
}) => ({
	type: ENotificationsActionTypes.setUnreadNotificationsCounter,
	payload,
});
export const setAllNotificationsAsReadAction = (payload: {
	notifications: INotification[]
}) => ({
	type: ENotificationsActionTypes.setAllNotificationsAsRead,
	payload,
});
