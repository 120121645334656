import { IOffer } from "models";
import { EGroupPrivacy } from "models/groups";
import {
	IOffersLoadingSetAction,
	IOffersGetAction,
	IOffersSetAction,
	IOffersFilterTagsSetAction,
	IOffersFilterTagsSetInStoreAction,
	ISetOffersQueryAction,
	IOffersIncrementRedeemByEmailCounterAction,
	IOffersIncrementRedeemByLinkCounterAction,
	IOffersIncrementWebsiteViewsAction,
} from "./actions";

// ENUMS
export enum EOffersActionTypes {
	offersLoadingSet = "offers/set/loading",
	getOffers = "offers/get",
	setOffers = "offers/set",
	setFilterTags = "offers/filter/tags/set",
	setFilterTagsInStore = "offers/filter/tags/set/inStore",
	setQueryAndFilter = "offers/query/setAndFilter",
	setOffersQuery = "offers/query/set",
	incrementRedeemByEmailCounter = "offers/increment/redeem/email/counter",
	incrementRedeemByLinkCounter = "offers/increment/redeem/link/counter",
	incrementWebsiteViews = "offers/increment/website/views",
}

// INTERFACES
export interface IOffersState {
	loading: boolean;
	data?: IOffer[];
	tags?: string[];
	group?: {
		id: number;
		name: string;
		description: string;
		privacy: EGroupPrivacy;
	};
	selectedTags: string[];
	query: string;
	meta: {
		pagination: {
			current_page: number;
			per_page: number;
			total_pages: number;
			total_objects: number;
			has_next_page: boolean;
		};
	};
}

// TYPES
export type TOffersActions =
	| IOffersSetAction
	| IOffersLoadingSetAction
	| IOffersGetAction
	| IOffersFilterTagsSetAction
	| IOffersFilterTagsSetInStoreAction
	| ISetOffersQueryAction
	| IOffersIncrementRedeemByEmailCounterAction
	| IOffersIncrementRedeemByLinkCounterAction
	| IOffersIncrementWebsiteViewsAction;
