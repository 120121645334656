
import {   Form, FormItemProps } from "antd";
import styles from './styles.module.scss'
const FormItem = Form.Item;


const FormInput =(props:FormItemProps) => {
    return (
        <div className={styles.hay}>
        <FormItem {...props}/>
        </div>
    )
}
export default FormInput