import { call, put, all, takeEvery, takeLatest } from "redux-saga/effects";

import {
	ECommunityActionTypes,
	ECommunityType,
	ICommunityGetAction,
	ICommunityMemberPlan,
} from "models";
import {
	api,
	setCommunityLoading,
	setCommunity,
	setCommunityTags,
	setMembers,
	setDeepLinkedSignUp,
	getGroups,
	getCommunityMemberPlans,
	setSelectedCommunityToJoinMemberPlans,
	setCommunityMemberPlansAction,
} from "store";
import { rollbar } from "utils";

function* getCommunityHandle({ payload }: ICommunityGetAction) {
	try {
		const { communityId, memberDirectoryOptIn } = payload;
		yield put(setCommunityLoading(true));
		const { data } = yield call(api.community.get, {
			community_id: communityId,
		});
		const { data: tags } = yield call(api.community.getTags, {
			community_id: communityId,
		});
		let members = null;
		if (memberDirectoryOptIn) {
			const { data } = yield call(api.community.getMembers, {
				community_id: communityId,
			});
			members = data;
		}

		if (!data) {
			yield put(setCommunityLoading(false));
		} else {
			yield put(setCommunity(data));
			yield put(setCommunityTags(tags));
			yield put(getGroups({ communityId }));
			if (members) {
				yield put(setMembers(members));
			}
			yield put(setCommunityLoading(false));
		}
	} catch (error) {
		rollbar.error(error)
		console.error({ error });
		yield put(setCommunityLoading(false));
	}
}

function* getBySlugHandle({ payload }: any) {
	try {
		yield put(setCommunityLoading(true));
		const { data } = yield call(api.communities.get, { slug: payload });

		if (data && data.communities && data.communities[0]) {
			yield put(setDeepLinkedSignUp(data.communities[0]));
		} else {
			yield put(setDeepLinkedSignUp(data));
		}
		yield put(setCommunityLoading(false));
	} catch (error) {
		rollbar.error(error)
		console.error({ error });
		yield put(setCommunityLoading(false));
	}
}

function* getMemberPlansHandle({
	payload,
}: ReturnType<typeof getCommunityMemberPlans>) {
	const {
		successCallback,
		errorCallback,
		communityId,
		typeOfCommunity,
		invited,
	} = payload;
	try {
		if (!communityId) {
			throw new Error("Missing parameters.");
		}
		let data: { plans: ICommunityMemberPlan[]; meta: any };
		const { data: memberPlans } = yield call(api.community.getMemberPlans, {
			communityId,
			invited,
		});

		if (memberPlans?.plans?.length === 0 && invited) {
			const { data: memberPlans } = yield call(
				api.community.getMemberPlans,
				{
					communityId,
					invited: false,
				},
			);
			data = memberPlans;
		} else {
			data = memberPlans;
		}

		if (typeOfCommunity === ECommunityType.join) {
			yield put(
				setSelectedCommunityToJoinMemberPlans({ plans: data.plans }),
			);
		} else if (typeOfCommunity === ECommunityType.current) {
			yield put(setCommunityMemberPlansAction({ plans: data.plans }));
		}
		if (successCallback) {
			successCallback();
		}
	} catch (error) {
		rollbar.error(error)
		if (errorCallback) {
			errorCallback();
		}
		console.error({ error });
	}
	yield put({ type: ECommunityActionTypes.getMembersPlansCompleted });
}

export default function* rootSaga() {
	yield all([
		takeEvery(ECommunityActionTypes.getCommunity, getCommunityHandle),
		takeEvery(ECommunityActionTypes.getBySlug, getBySlugHandle),
		// takeEvery(ECommunityActionTypes.saveCommunityPost, saveCommunityPostHandle),
		takeLatest(ECommunityActionTypes.getMembersPlans, getMemberPlansHandle),
	]);
}
