import React, { memo, useCallback } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import { TStore } from "models";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.auth.credentials,
	(credentials) => ({ user: credentials }),
);

interface IBordingRoute {
	children: any;
}

const BoardingRoute: React.FunctionComponent<IBordingRoute> = ({
	children,
}) => {
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { user } = useSelector(stateSelector);
	const location = useLocation();

	return !user?.user?.location ? (
		children
	) : (
		<>
			<Navigate
				to={{
					pathname: "/dashboard",
				}}
				state={{ from: location }}
			/>
		</>
	);
};

export default memo(BoardingRoute);
