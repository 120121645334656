import { call, put, all, takeEvery, select } from "redux-saga/effects";

import {
	EVideosActionTypes,
	TStore,
	IVideosIncrementVideoViewsAction,
} from "models";
import {
	api,
	setVideosLoading,
	setVideos,
	setVideosFilterTagsInStoreAction,
	setVideosQueryAction,
	getVideos,
	incrementVideoViewsAction,
	setVideosOrderAction,
	updateVideosCurrentFolderAction,
	deleteVediosAction,
} from "store";
import { rollbar } from "utils";

const getCommunityId = (state: TStore) => state.app.currentCommunityId;
const getGroupId = (state: TStore) => state.app.currentGroupId;
const getCommunityTags = (state: TStore) => state.community.mainTags.tags;
const getSelectedTags = (state: TStore) => state.videos.selectedTags;
const getQuery = (state: TStore) => state.videos.query;
const getRedirectQuery = (state: TStore) => state.videos.redirectQuery;
const getVideosData = (state: TStore) => state.videos.data;
const getCurrentFolder = (state: TStore) => state.videos.currentFolder;
const getVideosOrder = (state: TStore) => ({
	order: state.videos.order,
	order_dir: state.videos.order_dir,
});
const getVideosMeta = (state: TStore) => state.videos.meta;
const getCurrentCommunityId = (state: TStore) => state.app.currentCommunityId;
function* getVideosHandle({ payload }: ReturnType<typeof getVideos>) {

	try {
		yield put(setVideosLoading(true));
		const communityId = yield select(getCommunityId);
		const groupId = yield select(getGroupId);
		const selectedTags = yield select(getSelectedTags);
		const q = yield select(getQuery);
		const rQ = yield select(getRedirectQuery);
		const videosData = yield select(getVideosData);
		const meta = yield select(getVideosMeta);
		const order = yield select(getVideosOrder);
		const currentFolder = yield select(getCurrentFolder);
		let page = parseInt(meta?.pagination?.current_page);
		if (payload?.reset || videosData?.length === 0 || !videosData) {
			page = 1;
		} else {
			page++;
		}

		const { data } = yield call(api.videos.get, {
			communityId,
			groupId,
			tag_clicked: !!selectedTags.length,
			q: !!selectedTags.length ? selectedTags[0] : q,
			page,
			videoId: rQ,
			folder_id: currentFolder,
			...order,
		});
		if (!data) {
			yield put(setVideosLoading(false));
		} else {
			yield put(setVideos({ data, reset: payload?.reset }));
			yield put(setVideosLoading(false));
		}
	} catch (error) {
		rollbar.error(
			error
		)
		console.error({ error });
		yield put(setVideosLoading(false));
	}
}

function* setFilterTagsHandle({ payload }: any) {
	try {
		const tags: {
			id: number;
			name: string;
			is_selected: boolean;
		}[] = yield select(getCommunityTags);

		const parsedTags = tags
			.filter((item) => {
				return payload.tag.find(
					(element: string) =>
						element.toLowerCase().trim() ===
						item.name.toLowerCase().trim(),
				);
			})
			.map((item) => item.name);

		yield put(setVideosFilterTagsInStoreAction({ parsedTags }));
		return {
			selectedTags: parsedTags,
		};
	}
	catch (error) {
		rollbar.error(
			error
		)
	}
}

function* handleSetQueryAndFilterTags({ payload }: any) {
	try {
		yield put(setVideosQueryAction({ query: payload }));
		yield put(getVideos({ reset: true }));
	} catch (error) {
		rollbar.error(
			error
		)
		console.error({ error });
	}
}

function* setVideosCurrentFolderHandle({ payload }: any) {
	try {
		yield put(updateVideosCurrentFolderAction(payload));
		yield put(getVideos({ reset: true }));
	} catch (error) {
		rollbar.error(
			error
		)
		console.error({ error });
	}
}

function* incrementVideoViewsHandle({
	payload,
}: IVideosIncrementVideoViewsAction) {
	try {
		yield put(incrementVideoViewsAction(payload));
	} catch (error) {
		rollbar.error(
			error
		)
		console.error({ error });
	}
}

function* updateVideosOrderHandle({ payload }: any) {
	try {
		yield put(setVideosOrderAction(payload));
		yield put(getVideos({ reset: true }));
	} catch (error) {
		rollbar.error(
			error
		)
		console.error({ error });
	}
}

function* videosDeleteHandle({ payload }: ReturnType<typeof deleteVediosAction>) {
	const { videoId } = payload;
	try {
		const currentCommunityId = yield select(getCurrentCommunityId);
		if (!videoId || !currentCommunityId) {
			throw new Error("Missing parameters!");
		}
		yield call(api.videos.deleteVideo, {
			community_id: currentCommunityId,
			videoId: videoId,
		})
		yield put(getVideos({ reset: true }))
	} catch (error) {
		rollbar.error(
			error
		)
		console.error({ error });
	}
}

export default function* rootSaga() {
	yield all([
		takeEvery(EVideosActionTypes.getVideos, getVideosHandle),
		takeEvery(
			EVideosActionTypes.setQueryAndFilter,
			handleSetQueryAndFilterTags,
		),
		takeEvery(
			EVideosActionTypes.setVideosCurrentFolder,
			setVideosCurrentFolderHandle,
		),
		takeEvery(EVideosActionTypes.setFilterTags, setFilterTagsHandle),
		takeEvery(
			EVideosActionTypes.incrementVideoViews,
			incrementVideoViewsHandle,
		),
		takeEvery(
			EVideosActionTypes.updateVideosOrder,
			updateVideosOrderHandle,
		),
		takeEvery(
			EVideosActionTypes.deleteVideos,
			videosDeleteHandle,
		),
	]);
}
