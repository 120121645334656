import React, { memo, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal } from "antd";
import classNames from "classnames";

import commonStyles from "../../../components/styles.module.scss";
import { IPaymentMethod } from "models";
import { Loading } from "components";
import {
	deletePaymentMethodActionSaga,
	notify,
	setDefaultPaymentMethodSagaAction,
} from "store";
import { ReactComponent as VisaCardIcon } from "assets/images/visaCard.svg";
import { ReactComponent as MasterCardIcon } from "assets/images/masterCard.svg";
import { ReactComponent as X } from "assets/images/x.svg";
import localStyles from "./styles.module.scss";

const styles = { ...commonStyles, ...localStyles };

interface IPaymentMethodCard {
	paymentMethod: IPaymentMethod;
	defaultPaymentMethodId: string | null;
}

const PaymentMethodCard = (props: IPaymentMethodCard) => {
	const { paymentMethod, defaultPaymentMethodId = null } = props;
	const dispatch = useDispatch();
	const [deletePaymentMethodLoading, setDeletePaymentMethodLoading] =
		useState(false);
	const [
		makeDefaultAPaymentMethodLoading,
		setMakeDefaultAPaymentMethodLoading,
	] = useState(false);
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
	const [
		isMakeDefaultAPaymentMethodVisible,
		setIsMakeDefaultAPaymentMethodVisible,
	] = useState(false);

	const toggleDeletePaymentMethodModal = useCallback(() => {
		// TODO: Continue when the default payment method is available.
		if (false) {
			notify(
				"error",
				"Default Payment Method",
				"The default payment method cannot be deleted. Add a different default payment method.",
			);
		} else {
			setIsDeleteModalVisible(!isDeleteModalVisible);
		}
	}, [isDeleteModalVisible]);

	const toggleMakeDefaultAPaymentMethodModal = useCallback(() => {
		setIsMakeDefaultAPaymentMethodVisible(
			!isMakeDefaultAPaymentMethodVisible,
		);
	}, [isMakeDefaultAPaymentMethodVisible]);

	const deletePaymentMethod = useCallback(() => {
		toggleDeletePaymentMethodModal();
		setDeletePaymentMethodLoading(true);
		dispatch(
			deletePaymentMethodActionSaga({
				paymentMethodId: paymentMethod.data.id,
				onSuccessCallback: () => {
					setDeletePaymentMethodLoading(false);
				},
				onErrorCallback: () => {
					setDeletePaymentMethodLoading(false);
				},
			}),
		);
	}, [dispatch, paymentMethod.data.id, toggleDeletePaymentMethodModal]);

	const handleMakeDefaultPaymentMethod = useCallback(() => {
		if (paymentMethod?.data?.id) {
			setMakeDefaultAPaymentMethodLoading(true);
			dispatch(
				setDefaultPaymentMethodSagaAction({
					onErrorCallback: () => {
						setIsMakeDefaultAPaymentMethodVisible(false);
						setMakeDefaultAPaymentMethodLoading(false);
					},
					onSuccessCallback: () => {
						setIsMakeDefaultAPaymentMethodVisible(false);
						setMakeDefaultAPaymentMethodLoading(false);
					},
					paymentMethodId: paymentMethod.data.id,
				}),
			);
		} else {
			throw new Error("Missing parameters!");
		}
	}, [dispatch, paymentMethod?.data?.id]);

	return (
		<div>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
				}}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
					}}
				>
					<div>
						{paymentMethod.data.card.brand.toLowerCase().trim() ===
						"visa" ? (
							<VisaCardIcon width={34} height={20} />
						) : (
							<MasterCardIcon width={34} height={38} />
						)}
					</div>
					<div style={{ marginLeft: 10 }}>
						{paymentMethod.data.card.last4}
					</div>
				</div>
				<div className={styles.flexRow}>
					{defaultPaymentMethodId !== paymentMethod.data.id ? (
						<div
							onClick={toggleMakeDefaultAPaymentMethodModal}
							className={classNames(
								styles.boldAndBlackText,
								styles.rightMargin10,
							)}
						>
							<div>{"Make default"}</div>
						</div>
					) : (
						<div
							className={classNames(
								styles.greyAndBlackText,
								styles.rightMargin10,
							)}
						>
							{"Default"}
						</div>
					)}
					{!deletePaymentMethodLoading ? (
						<Button
							onClick={toggleDeletePaymentMethodModal}
							type="link"
							size={"middle"}
							danger
							style={{ padding: 0 }}
							disabled={
								defaultPaymentMethodId === paymentMethod.data.id
							}
							title={
								defaultPaymentMethodId === paymentMethod.data.id
									? "The default payment method cannot be deleted."
									: ""
							}
						>
							Delete
						</Button>
					) : (
						<Loading />
					)}
				</div>
			</div>
			<Modal
				title={"Payment Methods"}
				visible={isDeleteModalVisible}
				onOk={deletePaymentMethod}
				okType={"danger"}
				centered
				closable={false}
				cancelText={"Cancel"}
				cancelButtonProps={{
					id: "btn-cancel-delete-account",
				}}
				okButtonProps={{ id: "btn-ok-leave-group" }}
				okText={"Yes, I am sure"}
				onCancel={toggleDeletePaymentMethodModal}
				className={"modalContainer"}
				getContainer={() =>
					document.getElementById(
						"subscription-management-layout",
					) as HTMLElement
				}
				maskClosable={false}
			>
				<div className={styles.modalContent}>
					{"Are you sure you want to remove current payment method?"}
				</div>
				<div
					onClick={toggleDeletePaymentMethodModal}
					className={"closeModal"}
				>
					<X fill="#FFFFFF" width={13} height={13} />
				</div>
			</Modal>
			<Modal
				title={"Make Default"}
				visible={isMakeDefaultAPaymentMethodVisible}
				onOk={handleMakeDefaultPaymentMethod}
				okType={"danger"}
				centered
				closable={false}
				cancelText={"Cancel"}
				cancelButtonProps={{
					id: "btn-cancel-delete-account",
					disabled: makeDefaultAPaymentMethodLoading,
				}}
				okButtonProps={{ id: "btn-ok-leave-group" }}
				okText={"Yes, I am sure"}
				onCancel={toggleMakeDefaultAPaymentMethodModal}
				className={"modalContainer"}
				getContainer={() =>
					document.getElementById(
						"subscription-management-layout",
					) as HTMLElement
				}
				maskClosable={false}
				confirmLoading={makeDefaultAPaymentMethodLoading}
			>
				<div className={styles.modalContent}>
					{
						"Are you sure you want to make this payment method as your default payment method?"
					}
				</div>
				<div
					onClick={toggleMakeDefaultAPaymentMethodModal}
					className={"closeModal"}
				>
					<X fill="#FFFFFF" width={13} height={13} />
				</div>
			</Modal>
		</div>
	);
};

export default memo(PaymentMethodCard);
