import { memo, useCallback, useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { Avatar, Col, Row } from "antd";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import { Loading } from "components";
import {
	getNotificationsAction,
	openNotificationAction,
} from "store/actions/notifications";
import emptyNotifications from "assets/images/icons/common_icons/empty_notifications.svg";
import settings from "assets/images/icons/common_icons/settings.svg";
import { ENotificationTypes, INotification, TStore } from "models";
import styles from "./styles.module.scss";
import { GetImg } from "utils";
import { MarkAllAsRead } from "..";
import { appSetGroup, notify } from "store";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.notifications,
	(state: TStore) => state.app.currentCommunityId,
	(notifications, currentCommunityId) => {
		return {
			notifications,
			currentCommunityId,
		};
	},
);

const List = memo(
	({
		setOpen,
	}: {
		setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	}) => {
		// const [loading, setLoading] = useState<boolean>(false);
		const [notificationLoading, setNotificationLoading] =
			useState<boolean>(false);
		const [greenBullet, setGreenBullet] = useState<boolean>(false);
		const stateSelector = useCallback(stateSelectorHandle, []);
		const { notifications, currentCommunityId } =
			useSelector(stateSelector);

		const dispatch = useDispatch();
		const navigate = useNavigate();

		const handleLoadMore = useCallback(() => {
			dispatch(getNotificationsAction({}));
		}, [dispatch]);
		const handleClickOnNotif = useCallback(
			(item: INotification) => {
				if (item) {
					const {
						object_id,
						community_id,
						commentable_id,
						type,
						folder_id,
						notifiable_type,
						id,
						body,
						group_id,
						read,
					} = item;

					const types = [
						"membership_updated",
						"membership_type_updated",
						"member_blocked",
						"member_unblocked",
						"approved_membership",
						"group_join_request_approved",
						"group_join_request_rejected",
						"unfollowed",
						"invite_token_used",
						"subscription_status_changed",
						"trial_ending",
						"tags_updated",
						"profile_updated",
					];

					if (group_id) {
						dispatch(appSetGroup({ currentGroupId: group_id }));
						navigate("/dashboard");
					}
					const state = {
						notificationType: type,
						objectId: [
							ENotificationTypes.comment,
							ENotificationTypes.comment_thread,
							ENotificationTypes.comment_mention,
							ENotificationTypes.comment_thread_mention,
						].includes(type)
							? commentable_id
								? commentable_id
								: 0
							: object_id,
						communityId: community_id,
						folderId: folder_id,
						notifiableType: notifiable_type,
						body,
					};

					!types.includes(type) &&
						navigate("/notification/details", {
							state,
						});

					dispatch(
						openNotificationAction({
							id,
							forceCommunityId: community_id,
						}),
					);

					if (types.includes(type) && !read) {
						setNotificationLoading(true);
						const loader = setTimeout(() => {
							setNotificationLoading(false);
							setGreenBullet(true);

							const greenBullets = setTimeout(() => {
								setGreenBullet(false);
							}, 1500);
							return () => clearTimeout(greenBullets);
						}, 500);
						return () => {
							clearTimeout(loader);
						};
					}
				}
			},
			[navigate, dispatch],
		);

		useEffect(() => {
			if (!currentCommunityId || notifications?.data?.length > 0) return;
			// The initial fetching of the notification
			// setLoading(true)
			dispatch(
				getNotificationsAction({
					reset: true,
					callbackOnSuccess: () => {
						// setLoading(false);
					},
					callbackOnError: () => {
						// setLoading(false);
					},
				}),
			);

			return () => {};
		}, [currentCommunityId, dispatch, notifications?.data?.length]);
		// setTimeout(() => {
		// 	setLoading(false)
		// },3000)

		// useEffect(() => {
		// 	if(notifications.data.length)
		// 	{setLoading(false)}
		// },[notifications.data.length])

		const showExpiredToast = () => {
			notify("error", "This post is expired");
		};

		return (
			<>
				<div className={styles.flexSpaceBetweenContainer}>
					<div
						style={{
							fontSize: 16,
							fontFamily: "Avenir Heavy, sans-serif",
						}}
					>
						Notifications
					</div>
					<div className={styles.flexRow}>
						<MarkAllAsRead
							notitficationCount={
								notifications.unreadNotificationsCounter
							}
						/>

						<div
							onClick={() => {
								navigate("/profile/manage-notifications");
								setOpen(false);
							}}
							style={{ cursor: "pointer" }}
						>
							<img
								style={{
									width: 27,
								}}
								src={settings}
								alt=""
							/>
						</div>
					</div>
				</div>
				{notifications.loading ? (
					<Loading />
				) : notifications.data.length > 0 ? (
					<div
						id={"notifications-dropdown-menu-list"}
						className={styles.notificationWrapper}
					>
						<InfiniteScroll
							pageStart={0}
							initialLoad={false}
							loadMore={handleLoadMore}
							hasMore={
								!notifications.loading &&
								notifications.meta?.pagination?.has_next_page
							}
							loader={
								<div
									key={0}
									style={{
										position: "relative",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
										height: 82,
									}}
								>
									<div>
										<Loading />
									</div>
								</div>
							}
							useWindow={false}
						>
							{notifications?.data.map(
								(item: INotification, index) => {
									return (
										<Row
											className={styles.rowContainer}
											key={item?.id}
											style={{
												borderBottom:
													index ===
													notifications.data!.length -
														1
														? "note"
														: "1px solid #cdcdcd",
												backgroundColor: !item?.read
													? "#ececec"
													: "#fff",
											}}
										>
											<Col
												className={styles.colContainer}
											>
												<div
													className={
														styles.notifCardContainer
													}
													onClick={() => {
														if (
															item?.exp_date ===
																null ||
															new Date(
																item?.exp_date,
															).getTime() >
																Date.now()
														) {
															handleClickOnNotif(
																item,
															);
														} else {
															showExpiredToast();
														}
													}}
												>
													{!item?.read && (
														<div
															className={
																styles.redBulletContainer
															}
														>
															<div
																className={
																	styles.redBullet
																}
															/>
														</div>
													)}
													{notificationLoading && (
														<Loading />
													)}
													{greenBullet && (
														<div
															className={
																styles.greenBulletContainer
															}
														>
															<div
																className={
																	styles.greenBullet
																}
															/>
														</div>
													)}
													<div
														className={
															styles.imgContainer
														}
													>
														<Avatar
															size={42}
															src={
																<GetImg
																	endpoint={
																		item?.image
																	}
																/>
															}
														/>
													</div>
													<div
														className={
															styles.bodyContainer
														}
													>
														<div>{item?.body}</div>
														<div
															className={
																styles.smallTextHeaderBackgroundColor
															}
														>
															{moment(
																item?.date,
															).format(
																"DD MMMM  YYYY, h:mm A",
															)}
														</div>
													</div>
												</div>
											</Col>
										</Row>
									);
								},
							)}
						</InfiniteScroll>
					</div>
				) : (
					<div>
						<div
							style={{
								width: "100%",
								height: 450,
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "column",
							}}
						>
							<img
								style={{ width: 35, marginBottom: 20 }}
								src={emptyNotifications}
								alt=""
							/>
							<p style={{ textAlign: "center" }}>
								Select tags in your profile to get notified
								about the latest content matching those tags.
							</p>
						</div>
					</div>
				)}
			</>
		);
	},
);

export default List;
