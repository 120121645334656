import React, { memo, useCallback, useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import classNames from "classnames";

import styles from "./styles.module.scss";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import { addANewPaymentMethodAction } from "store";
import { StripeCardElementOptions } from "@stripe/stripe-js";
import { createSelector } from "reselect";
import { TStore } from "models";
import { useSelector } from "react-redux";
import { rollbar } from "utils";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.posts.meta.appearance?.primary_color,
	
	(
	primary_color
	) => ({
	primary_color
	}),
);

interface IStripeCardElement {
	onCancelButton: () => void;
}

const StripeCardElement: React.FunctionComponent<IStripeCardElement> = ({
	onCancelButton,
}) => {
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const elements = useElements();
	const stripe = useStripe();
	const [stripeValidationError, setStripeValidationError] = useState<
		string | null
	>(null);
	const stateSelector = useCallback(stateSelectorHandle, []);

	const {
		primary_color
	} = useSelector(stateSelector);
	const CARD_OPTIONS: StripeCardElementOptions = {
		hidePostalCode: true,
		iconStyle: "solid",
		style: {
			base: {
				iconColor: `${primary_color}`,
				color: `${primary_color}`,
				fontWeight: 500,
				fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
				fontSize: "16px",
				fontSmoothing: "antialiased",
				":-webkit-autofill": {
					color: `${primary_color}`,
				},
				"::placeholder": {
					color: `${primary_color}`
				},
			},
			invalid: {
				iconColor: "#ffc7ee",
				color: "#ffc7ee",
			},
		},
	};
	
	const handleCancel = useCallback(() => {
		onCancelButton();
	}, [onCancelButton]);

	const handleSaveCard = useCallback(async () => {
		setLoading(true);
		// create a payment method
		if (!elements || !stripe) {
			// Stripe.js has not loaded yet. Make sure to disable
			// form submission until Stripe.js has loaded.
			setLoading(false);
			return;
		} else if (elements) {
			// Get a reference to a mounted CardElement. Elements knows how
			// to find your CardElement because there can only ever be one of
			// each type of element.
			const cardElement = elements.getElement("card");

			try {
				// Use your card Element with other Stripe.js APIs
				const {
					error,
					paymentMethod,
				} = await stripe.createPaymentMethod({
					type: "card",
					card: cardElement!,
				});

				if (paymentMethod) {
					dispatch(
						addANewPaymentMethodAction({
							paymentMethodId: paymentMethod.id,
							onSuccessCallback: () => {
								setLoading(false);
								handleCancel();
							},
							onErrorCallback: (errors) => {
								if (errors && errors[0][0] === "data") {
									setStripeValidationError(errors[0][1]);
								}
								setLoading(false);
							},
						}),
					);
				} else if (error) {
					setLoading(false);
					setStripeValidationError(
						error?.message ? error.message : null,
					);
					console.error(error);
				}
			} catch (error) {
				setLoading(false);
				console.error(error);
				rollbar.error(error)
			}
		}
	}, [dispatch, elements, handleCancel, stripe]);

	return (
		<div className={styles.container}>
			<div>
				<CardElement
					className={classNames(
						styles.stripeElement,
						styles.stripeElementWebkitAutofill,
					)}
					options={CARD_OPTIONS}
				/>
				{
					<div className={styles.errorText}>
						{stripeValidationError}
					</div>
				}
			</div>
			<div>
				<Button
					className={styles.mainBtn}
					onClick={handleSaveCard}
					loading={loading}
				>
					{"Save card"}
				</Button>
				<Button
					className={classNames(styles.mainBtn, styles.secondaryBtn)}
					onClick={handleCancel}
					disabled={loading}
				>
					{"Cancel"}
				</Button>
			</div>
		</div>
	);
};

export default memo(StripeCardElement);
