import {
	IPostsState,
	EPostsActionTypes,
	TPostsActions,
	EPostActionTypes,
	TPostActions,
	ECommunityAccessStatus,
} from "models";

const initial: IPostsState = {
	loading: false,
	data: [],
	selectedTags: [],
	query: "",
	shouldRefresh: false,
	meta: {
		appearance: undefined,
		pagination: {
			current_page: 1,
			per_page: 10,
			total_pages: 0,
			total_objects: 0,
			has_next_page: false,
		},
		privacy: "",
		scope_group: null,
		is_approved: false,
		access_status: ECommunityAccessStatus.closed_community,
	},
};

export function postsReducer(
	state = initial,
	action: TPostsActions | TPostActions,
): IPostsState {
	switch (action.type) {
		case EPostsActionTypes.postsLoadingSet: {
			return {
				...state,
				loading: action.payload,
			};
		}
		case EPostsActionTypes.setPosts: {
			if (action.payload.resetPosts) {
				//reset the state
				return {
					...state,
					data: action.payload.data.posts,
					meta: action.payload.data.meta,
					shouldRefresh: false,
				};
			} else {
				return {
					...state,
					data: state.data.concat(action.payload.data.posts),
					meta: action.payload.data.meta,
				};
			}
		}
		case EPostActionTypes.postStoreAdd: {
			return {
				...state,
				data: [action.payload.community_post].concat(state.data),
			};
		}
		case EPostActionTypes.postUpdateStore: {
			return {
				...state,
				data: [action.payload.data.community_post].concat(
					state.data.filter(
						(p) => p.id !== action.payload.communityPostId,
					),
				),
			};
		}
		case EPostsActionTypes.setPostsQuery: {
			return {
				...state,
				query: action.payload.query,
				selectedTags: [],
			};
		}
		case EPostsActionTypes.setFilterTagsPosts: {
			const query = !action.payload.parsedTags.length ? "" : state.query;
			return {
				...state,
				selectedTags: action.payload.parsedTags,
				query,
			};
		}
		case EPostsActionTypes.setShouldRefresh: {
			return {
				...state,
				shouldRefresh: action.payload,
			};
		}
		case EPostsActionTypes.removeFilterTagPosts: {
			const selectedTags = state.selectedTags
				? [...state.selectedTags]
				: [];

			const parsedTags = selectedTags.filter(
				(item) => item !== action.payload.tagId,
			);

			return {
				...state,
				selectedTags: parsedTags,
			};
		}
		default:
			return state;
	}
}
