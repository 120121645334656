import React, { useState, useEffect, useCallback, useMemo } from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import styles from "./styles.module.scss";
import { Loading, SetupForm, StripeWrapper } from "components";
import { useDispatch, useSelector } from "react-redux";
import { notify, paymentsCreateMemberSubscription } from "store";
import { createSelector } from "reselect";
import { ECommunityType, ESubscriptionStatus, TStore } from "models";
import {
	isItAWhiteLabel,
	useMemberPlanPrice,
	usePeriodOfPayment,
	useSelectCommunityByProcess,
} from "utils";
import { PaymentMethodsList } from "..";
import { StripeCheckoutForm } from "./ components";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.payments.memberSubscription,
	(state: TStore) => state.auth?.credentials?.user,
	(state: TStore) => state.subscription,
	(state: TStore) => state.communities?.selectedCommunityToJoinInfo,
	(
		paymentsMemberSubscription,
		authenticatedUser,
		subscription,
		selectedCommunityToJoinInfo,
	) => ({
		paymentsMemberSubscription,
		authenticatedUser,
		subscription,
		selectedCommunityToJoinInfo,
	}),
);

interface ICheckouttForm {
	typeOfCommunity: ECommunityType;
}

const CheckoutForm: React.FunctionComponent<ICheckouttForm> = ({
	typeOfCommunity,
}) => {
	const [methodOfPayment] = useState<
		"new card" | "existing payment method"
	>("new card");

	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [initiateTheSubscription, setInitiateTheSubscription] =
		useState(false);

	const stateSelector = useCallback(stateSelectorHandle, []);
	const { paymentsMemberSubscription, authenticatedUser, subscription } =
		useSelector(stateSelector);
	const { resultedCommunity } = useSelectCommunityByProcess({
		typeOfCommunity,
	});

	const memberPlanPrice = useMemberPlanPrice({ typeOfCommunity });

	const periodOfPayment = usePeriodOfPayment({
		interval: resultedCommunity?.selected_member_plan?.period?.interval,
		intervalCount:
			resultedCommunity?.selected_member_plan?.period?.interval_count,
		priceTag: memberPlanPrice?.price_tag,
	});

	const periodOfTrial: string | null = useMemo(() => {
		if (resultedCommunity?.selected_member_plan?.trial_period) {
			if (
				resultedCommunity.selected_member_plan.trial_period
					?.interval === "DAY"
			) {
				const noOfDays =
					resultedCommunity.selected_member_plan.trial_period
						?.interval_count;
				if (noOfDays > 1) {
					return `${noOfDays} days`;
				} else if (noOfDays === 1) {
					return `1 day`;
				} else {
					return null;
				}
			} else if (
				resultedCommunity.selected_member_plan.trial_period
					?.interval === "MONTH"
			) {
				const noOfMOnths =
					resultedCommunity.selected_member_plan.trial_period
						?.interval_count;
				if (noOfMOnths > 1) {
					return `${noOfMOnths} months`;
				} else if (noOfMOnths === 1) {
					return `1 month`;
				} else {
					return null;
				}
			} else {
				return null;
			}
		} else {
			return null;
		}
	}, [resultedCommunity?.selected_member_plan?.trial_period]);

	const handleInitiateSubscriptionSuccess = useCallback(
		(paymentsMemberSubscriptionData) => {
			if (
				resultedCommunity?.id &&
				paymentsMemberSubscriptionData &&
				loading
			) {
				if (typeOfCommunity === ECommunityType.join) {
					if (
						(paymentsMemberSubscriptionData.payload
							?.subscription_status ===
							ESubscriptionStatus.trialing &&
							paymentsMemberSubscriptionData.setup_intent_client_secret !==
								null &&
							paymentsMemberSubscriptionData.payload
								?.client_secret === null) ||
						((paymentsMemberSubscriptionData.payload
							?.subscription_status ===
							ESubscriptionStatus.inactive ||
							paymentsMemberSubscriptionData.payload
								?.subscription_status ===
								ESubscriptionStatus.incomplete) &&
							paymentsMemberSubscriptionData?.payload
								?.client_secret !== null)
					) {
						setLoading(false);
					} else {
						notify(
							"error",
							"An error occurred. Please try again later.",
						);
						if (isItAWhiteLabel()) {
							navigate("/joining/init", {
								state: {
									error: true,
								},
							});
						} else {
							navigate("/communities");
						}
					}
				} else if (
					typeOfCommunity === ECommunityType.current &&
					(paymentsMemberSubscriptionData.payload?.client_secret !==
						null ||
						paymentsMemberSubscriptionData.setup_intent_client_secret !==
							null)
				) {
					if (subscription?.paymentMethods?.length === 0) {
						setLoading(false);
					} else {
						notify(
							"error",
							"An error occurred. Please try again later.",
						);
						if (isItAWhiteLabel()) {
							navigate("/joining/init", {
								state: {
									error: true,
								},
							});
						} else {
							navigate("/communities");
						}
					}
				}
			}
		},
		[
			navigate,
			loading,
			subscription?.paymentMethods?.length,
			typeOfCommunity,
			resultedCommunity,
		],
	);

	console.log(
		resultedCommunity?.id,
		memberPlanPrice?.stripe_id,
		!initiateTheSubscription,
	);

	useEffect(() => {
		if (
			resultedCommunity?.id &&
			memberPlanPrice?.stripe_id &&
			!initiateTheSubscription
		) {
			setInitiateTheSubscription(true);
			if (
				typeOfCommunity === ECommunityType.join ||
				subscription?.currentMemberPlan?.free
			) {
				// * Create a subscription
				dispatch(
					paymentsCreateMemberSubscription({
						stripePlanPriceId: memberPlanPrice.stripe_id,
						communityId: resultedCommunity.id,
						onSuccessCallback: (
							paymentsMemberSubscriptionData: any,
						) => {
							handleInitiateSubscriptionSuccess(
								paymentsMemberSubscriptionData,
							);
						},
						onErrorCallback: () => {
							notify(
								"error",
								"An error occurred. Please try again later.",
							);
							if (isItAWhiteLabel()) {
								navigate("/joining/init", {
									state: {
										error: true,
									},
								});
							} else {
								navigate("/communities");
							}
						},
					}),
				);
			} else {
				handleInitiateSubscriptionSuccess(paymentsMemberSubscription);
			}
		}
	}, [
		dispatch,
		navigate,
		initiateTheSubscription,
		resultedCommunity?.id,
		resultedCommunity?.selected_member_plan?.trial_period,
		subscription?.paymentMethods?.length,
		typeOfCommunity,
		handleInitiateSubscriptionSuccess,
		paymentsMemberSubscription,
		resultedCommunity,
		memberPlanPrice,
		subscription?.currentMemberPlan?.free,
	]);

	return loading ? (
		<Loading />
	) : resultedCommunity && authenticatedUser ? (
		<div className={styles.container}>
			<div className={styles.flexColumnDirection}>
				<div className={classNames(styles.flexRowDirection)}>
					<div className={classNames(styles.flex1, styles.heavyText)}>
						{"Community:"}
					</div>
					<div className={classNames(styles.flex2, styles.heavyText)}>
						{resultedCommunity.name}
					</div>
				</div>
				<div className={classNames(styles.flexRowDirection)}>
					<div className={classNames(styles.flex1, styles.heavyText)}>
						{"Member Plan:"}
					</div>
					<div className={classNames(styles.flex2, styles.heavyText)}>
						{typeOfCommunity === ECommunityType.join
							? resultedCommunity.selected_member_plan
									?.display_name ?? "-"
							: subscription?.changeSubscriptionSelectedMemberPlan
									?.display_name ?? "-"}
					</div>
				</div>
				<div className={classNames(styles.flexRowDirection)}>
					<div className={classNames(styles.flex1, styles.heavyText)}>
						{"Email:"}
					</div>
					<div className={classNames(styles.flex2, styles.heavyText)}>
						{authenticatedUser.email}
					</div>
				</div>
			
				<div className={classNames(styles.flexRowDirection)}>
					<div className={classNames(styles.flex1, styles.heavyText)}>
						{"Period:"}
					</div>
					<div className={classNames(styles.flex2, styles.heavyText)}>
						{periodOfPayment ? periodOfPayment : "-"}
					</div>
				</div>
				<div className={classNames(styles.flexRowDirection, styles.amountRow)}>
					<div className={classNames(styles.priceFlex1)}>
						{"Amount"}
					</div>
					<div className={classNames(styles.flex2)}>
						{memberPlanPrice?.price_tag ?? "-"}
					</div>
				</div>
				{periodOfTrial && (
					<div className={classNames(styles.flexRowDirection)}>
						<div
							className={classNames(
								styles.flex1,
								styles.heavyText,
							)}
						>
							{"Trial days:"}
						</div>
						<div className={classNames(styles.flex2)}>
							{periodOfTrial}
						</div>
					</div>
				)}
			</div>
			{paymentsMemberSubscription?.payload?.client_secret ? (
				<>
					{
						// <div
						// 	className={classNames(
						// 		styles.flexRowSpaceBetween,
						// 		styles.selectSection,
						// 	)}
						// >
						// 	{
						// 		<>
						// 			{
						// 				<Button
						// 					onClick={() =>
						// 						setMethodOfPayment(
						// 							"existing payment method",
						// 						)
						// 					}
						// 					className={classNames(
						// 						styles.mainBtn,
						// 						{
						// 							[styles.activeButton]:
						// 								methodOfPayment ===
						// 								"existing payment method",
						// 						},
						// 					)}
						// 				>
						// 					{
						// 						"Select an existing payment method."
						// 					}
						// 				</Button>
						// 			}
						// 			{
						// 				<Button
						// 					style={{ padding: "0px 28px" }}
						// 					onClick={() =>
						// 						setMethodOfPayment("new card")
						// 					}
						// 					className={classNames(
						// 						styles.mainBtn,
						// 						{
						// 							[styles.activeButton]:
						// 								methodOfPayment ===
						// 								"new card",
						// 						},
						// 					)}
						// 				>
						// 					{"New card"}
						// 				</Button>
						// 			}
						// 		</>
						// 	}
						// </div>
					}
					{methodOfPayment === "new card" ? (
						<div className={styles.cardContainer}>
							<StripeWrapper
								stripeLinkedAccountId={
									paymentsMemberSubscription?.payload
										?.stripe_linked_account_id!
								}
							>
								<StripeCheckoutForm
									typeOfCommunity={typeOfCommunity}
									price={memberPlanPrice?.price_tag}
								/>
							</StripeWrapper>
						</div>
					) : (
						<StripeWrapper
							stripeLinkedAccountId={
								paymentsMemberSubscription?.payload
									?.stripe_linked_account_id!
							}
						>
							<PaymentMethodsList
								paymentMethods={
									paymentsMemberSubscription?.payload
										.payment_methods
								}
								typeOfCommunity={typeOfCommunity}
							/>
						</StripeWrapper>
					)}
				</>
			) : paymentsMemberSubscription?.setup_intent_client_secret ? (
				<>
					<StripeWrapper
						stripeLinkedAccountId={
							paymentsMemberSubscription?.payload
								?.stripe_linked_account_id!
						}
						setupIntentClientSecret={
							paymentsMemberSubscription.setup_intent_client_secret
						}
					>
						<SetupForm
							typeOfCommunity={typeOfCommunity}
							memberPlanPrice={memberPlanPrice}
						/>
					</StripeWrapper>
				</>
			) : (
				<div>{"An error occured! Please try again later."}</div>
			)}
		</div>
	) : (
		<></>
	);
};

export default CheckoutForm;
