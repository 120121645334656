import React, { ReactElement, useMemo, useState } from "react";
import { Modal } from "antd";

import CustomButton from "../Button"

import { ReactComponent as SadFaceIcon } from "assets/images/sad-face.svg";


import styles from "./styles.module.scss";



interface IConfirmationModal {
    trigger: ReactElement
    onConfirm: () => void
    action?: "leave" | "decline"
    communityName?:string
}

const getActionOptions = (action?: "leave" | "decline" ,communityName?:string)=>{
  switch (action) {
    case "leave":
      return {msg:<>Leave the <span>{communityName}</span> community?</>,acceptLabel:"Yes, leave",cancelLabel: "No, I'll stay"}
  
    case "decline":
      return {msg:<>Decline invitation from <span>{communityName}</span>?</>,acceptLabel:"Yes, decline"}
  
    default:
      return {msg:<>Leave from <span>{communityName}</span> community?</>,acceptLabel:"Yes, leave"}

  }
}

const ConfirmationModal: React.FunctionComponent<IConfirmationModal> = ({
    trigger,
    onConfirm,
    action,
    communityName
}) => {

    const [open, setOpen] = useState(false);

    const showModal = () => {
      setOpen(true);
    };
  
    const hideModal = () => {
      setOpen(false);
    };

    const actionOptions = useMemo(()=> getActionOptions(action,communityName),[action,communityName])


	return (
        <>
          <div onClick={showModal}>
              {trigger}
          </div>
          <Modal
            open={open}
            title={false}
            closable={false}
            footer={false}
            className={styles.confirmationModal}
          >
            <div className={styles.confirmationModalContainer}> 
              <SadFaceIcon height={42} width={42} />

              <div className={styles.messageContainer}>
                {actionOptions.msg}
              </div>

              <div style={{display:"flex",gap:"16px"}}>
                <CustomButton varient="outline" onClick={hideModal}>{ actionOptions.cancelLabel ?? "No, cancel"}</CustomButton>
                <CustomButton varient="denger" onClick={onConfirm}>{actionOptions.acceptLabel}</CustomButton>
              </div>
            </div>
          </Modal>
      </>
	);
};

export default ConfirmationModal;
