import React, { memo, useCallback } from "react";
import { Navigate, useMatch, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import { TStore } from "models";
import { sessionStorageKeys, useSessionStorage, isItAWhiteLabel } from "utils";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.auth.credentials,
	(credentials) => ({ user: credentials }),
);

interface IGuesRoute {
	children: any; //TODO: fix the any
}

const GuestRoute: React.FunctionComponent<IGuesRoute> = ({ children }) => {
	const { setValue: setUgenieHubLink, storedValue: ugenieHubLink } =
		useSessionStorage({ key: sessionStorageKeys.ugenieHubLink });
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { user } = useSelector(stateSelector);
	const location = useLocation();
	// when the user is using a deep link he/she is redirected to the communities page
	const defaultPath =
		user && ugenieHubLink
			? `${!isItAWhiteLabel() ? "/communities" : "/joining/init"}`
			: "/dashboard";

	let matchDeepLink: any = useMatch({
		path: "/hubs/:token/sign-up",
	});

	if (
		matchDeepLink?.isExact &&
		matchDeepLink?.params?.token &&
		!ugenieHubLink
	) {
		// set the hub's token in session storage
		setUgenieHubLink(matchDeepLink.params.token);
	}

	return !user ? (
		children
	) : (
		<Navigate
			to={{
				pathname: defaultPath,
			}}
			state={{ from: location }}
		/>
	);
};

export default memo(GuestRoute);
