import { IDocState, EDocActionTypes, TDocActions } from "models";

const initial: IDocState = {
	loading: false,
	currentId: 0,
	data: undefined,
};

export function docReducer(state = initial, action: TDocActions): IDocState {
	switch (action.type) {
		case EDocActionTypes.docLoadingSet: {
			return {
				...state,
				loading: action.payload,
			};
		}
		case EDocActionTypes.setDoc: {
			return {
				...state,
				data: action.payload.data,
			};
		}

		default:
			return state;
	}
}
