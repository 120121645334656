import React, { memo, useEffect } from "react";
import { useDispatch } from "react-redux";

import { getConversationsSilently, getFeedSilently } from "store";

const MainLayout: React.FunctionComponent<any> = ({ children }) => {
	const dispatch = useDispatch();

	useEffect(() => {
		const interval = setInterval(() => {
			dispatch(getFeedSilently());
		}, 5 * 60000);

		return () => clearInterval(interval);
	}, [dispatch]);

	useEffect(() => {
		const interval = setInterval(() => {
			dispatch(getConversationsSilently());
		}, 20000);

		return () => clearInterval(interval);
	}, [dispatch]);

	return <>{children}</>;
};

export default memo(MainLayout);
