import { memo, useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { Button } from "antd";
import { Navigate, useNavigate } from "react-router";

import {
	appChangeCommunity,
	getSelectedCommunityToJoinAccessStatus,
	paymentsCreateMemberSubscription,
	resumePaymentProcessAction,
} from "store";
import { ECommunityAccessStatus, ECommunityType, TStore } from "models";
import { Loading } from "components";
import { CheckoutForm } from "..";
import styles from "./styles.module.scss";
import { OnboardingLayout } from "layouts";
import { MemberPlanNotAvailableModal } from "./components";
import { getMemberPlanPriceOptionByCurrency, isItAWhiteLabel } from "utils";
import FormWrapper from "components/FormWrapper";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.communities.selectedCommunityToJoinInfo,
	(selectedCommunityToJoinInfo) => ({
		selectedCommunityToJoinInfo,
	}),
);

const NotApproved: React.FunctionComponent<unknown> = () => {
	const navigate = useNavigate();

	const backToCommunitiesScreen = () => {
		navigate("/communities");
	};

	return (
		<div className={styles.notApprovedContainer}>
			<div className={styles.heavyTitle}>
				{"You need to be approved!"}
			</div>
			<div className={styles.roundButton}>
				<Button onClick={backToCommunitiesScreen} style={{}}>
					{"Back to communities"}
				</Button>
			</div>
		</div>
	);
};

interface IPendingStatus {
	resumePaymentProcess?: boolean;
	communityId?: number;
}

const PendingStatus: React.FunctionComponent<IPendingStatus> = ({
	resumePaymentProcess,
	communityId,
}) => {
	const defaultScreen = !isItAWhiteLabel() ? "/communities" : "/joining/init";
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { selectedCommunityToJoinInfo } = useSelector(stateSelector);
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const [isTheSelectedMemberPlanAvailable, setIsThePaymentElementCompleted] =
		useState(true);
	const navigate = useNavigate();
	const getResumePaymentProcessRef = useRef(false);
	const getCommunityAccessStatusRef = useRef(false);
	const back = useCallback(() => {
		if (!selectedCommunityToJoinInfo) {
			console.error("Missing data!");
			return;
		}
		if (selectedCommunityToJoinInfo.uses_stripe) {
			navigate(-1);
		} else {
			navigate(-1);
		}
	}, [navigate, selectedCommunityToJoinInfo]);
	const componentToBeRendered = useCallback(() => {
		if (loading) {
			return <Loading />;
		} else if (!selectedCommunityToJoinInfo) {
			return <Navigate to={defaultScreen} />;
		} else if (!isTheSelectedMemberPlanAvailable) {
			return (
				<MemberPlanNotAvailableModal
					selectedCommunityToJoinInfo={selectedCommunityToJoinInfo}
				/>
			);
		} else {
			const { id, selected_member_plan, selectedCurrency, uses_stripe } =
				selectedCommunityToJoinInfo;
			switch (selectedCommunityToJoinInfo.access_status) {
				case ECommunityAccessStatus.closed_community: {
					return <Navigate to={defaultScreen} />;
				}
				case ECommunityAccessStatus.subscription_pending: {
					return <NotApproved />;
				}
				case ECommunityAccessStatus.subscription_inactive: {
					// This case can be executed in two situations:
					// 1. on uHubs when the user is adding the payment info (the user is resuming the payment process)
					// 2. on white labels when the user has a free plan and it is approved to join a closed community
					let memberPlanPrice: any = null;
					if (!resumePaymentProcess) {
						if (!selectedCurrency) {
							if (selected_member_plan?.price?.currency) {
								memberPlanPrice = selected_member_plan?.price;
							} else {
								return (
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											justifyContent: "center",
											alignItems: "center",
											flex: 1,
										}}
									>
										{"Something went wrong."}
									</div>
								);
							}
						} else {
							memberPlanPrice =
								getMemberPlanPriceOptionByCurrency(
									selectedCurrency,
									selected_member_plan?.price_options,
								);
						}
					} else {
						memberPlanPrice = selected_member_plan?.price;
					}

					if (id && selected_member_plan?.free) {
						if (uses_stripe && memberPlanPrice?.stripe_id) {
							dispatch(
								paymentsCreateMemberSubscription({
									stripePlanPriceId:
										memberPlanPrice.stripe_id,
									communityId: selectedCommunityToJoinInfo.id,
									onSuccessCallback: () => {
										dispatch(
											appChangeCommunity({
												communityId:
													selectedCommunityToJoinInfo.id,
											}),
										);
										setLoading(false);
									},
									onErrorCallback: () => {
										if (isItAWhiteLabel()) {
											navigate(defaultScreen, {
												state: {
													error: true,
												},
											});
										} else {
											navigate(defaultScreen);
										}
										setLoading(false);
									},
								}),
							);
						} else {
							navigate(defaultScreen);
						}

						return <Loading />;
					} else if (id && memberPlanPrice?.stripe_id) {
						return (
							<>
								<OnboardingLayout
									footerLayout={false}
									fullWidthContainerForChildren={true}
									headerTitle={"Checkout"}
									headerSubtitle={
										"You have chosen to pay this subscription with the following details:"
									}
									handleBack={back}
									// handleNext={() => console.log('next')}
									nextButton={false}
								>
									<FormWrapper maxWidth="552px">
										<CheckoutForm
											typeOfCommunity={
												ECommunityType.join
											}
										/>
									</FormWrapper>
								</OnboardingLayout>
								{/* <div style={{width:'100%',background:'white',display:'flex',justifyContent:'center'}}>
			<div className={styles.btnContainer}>
			<CustomButton
				onClick={back}
				buttonText={"Back"}
				style={{width:'170px'}}
				varient="outline"
			>
				<LeftOutlined
					className={styles.onboardingSecondartBtnIcon}
				/>
				
			</CustomButton>
			<CustomButton
				// onClick={goToGroups}
				buttonText="Next"
				style={{width:'170px'}}
				varient="solid"
			>
				
				<RightOutlined />
			</CustomButton>
		</div>
			</div> */}
							</>
						);
					} else {
						return (
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
									flex: 1,
								}}
							>
								{"Error. There is no subscription available."}
							</div>
						);
					}
				}
				case ECommunityAccessStatus.granted: {
					dispatch(
						appChangeCommunity({
							communityId: selectedCommunityToJoinInfo.id,
							onSuccessCallback: () => {
								navigate("/dashboard");
							},
						}),
					);
					return <Loading />;
				}
				default: {
					// For the communities that are not connected to stripe.
					// This condition might be a bit unclear but note that all the communities that are not connected to stripe
					// and has the 'privacy' parameter as 'open' must be visible to the user after the user joined into them
					if (
						selectedCommunityToJoinInfo.access_status === null &&
						!selectedCommunityToJoinInfo?.uses_stripe &&
						selectedCommunityToJoinInfo?.privacy === "open"
					) {
						dispatch(
							appChangeCommunity({
								communityId: selectedCommunityToJoinInfo.id,
								onSuccessCallback: () => {
									navigate("/dashboard");
								},
							}),
						);
						return <Loading />;
					} else {
						return <Navigate to={defaultScreen} />;
					}
				}
			}
		}
	}, [
		loading,
		selectedCommunityToJoinInfo,
		isTheSelectedMemberPlanAvailable,
		resumePaymentProcess,
		dispatch,
		navigate,
		back,
		defaultScreen,
	]);

	useEffect(() => {
		if (
			!loading ||
			!resumePaymentProcess ||
			!communityId ||
			getResumePaymentProcessRef.current
		) {
			return;
		}
		dispatch(
			resumePaymentProcessAction({
				communityId,
				onSuccessCallback: () => {
					setLoading(false);
				},
				onErrorCallback: (props) => {
					if (props?.isTheSelectedMemberPlanAvailable) {
						setLoading(false);
					} else {
						setIsThePaymentElementCompleted(false);
						setLoading(false);
					}
				},
			}),
		);
		getResumePaymentProcessRef.current = true;
	}, [communityId, dispatch, loading, resumePaymentProcess]);

	useEffect(() => {
		if (
			!loading ||
			!selectedCommunityToJoinInfo?.id ||
			getCommunityAccessStatusRef.current
		) {
			return;
		}
		dispatch(
			getSelectedCommunityToJoinAccessStatus({
				communityId: selectedCommunityToJoinInfo.id,
				successCallback: () => {
					setLoading(false);
				},
				errorCallback: () => {
					setLoading(false);
				},
			}),
		);
		getCommunityAccessStatusRef.current = true;
	}, [
		dispatch,
		navigate,
		loading,
		resumePaymentProcess,
		selectedCommunityToJoinInfo.id,
	]);

	return loading ? (
		<div
			style={{
				display: "flex",
				flex: 1,
				flexDirection: "column",
				justifyContent: "center",
			}}
		>
			<Loading />
		</div>
	) : (
		componentToBeRendered()
	);
};

export default memo(PendingStatus);
