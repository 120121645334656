import { memo } from "react";

import { InvitesEmptyState, Loading } from "components";
import { ETypeOfCommunities, ICommunity } from "models";
import CommunityBox from "components/CommunityBox";
import styles from "./styles.module.scss";

interface IInvitesListProps {
	invites: ICommunity[];
	isLoading: boolean;
}

const InvitesList = ({ invites, isLoading }: IInvitesListProps) => {
	return (
		<div>
			{isLoading ? (
				<Loading />
			) : invites?.length ? (
				<div className={styles.infiniteScroll}>
					{invites.map((item) => (
						<CommunityBox
							key={"community-" + item.id}
							data={item as ICommunity}
							sourceOfCommunitiesDataList={ETypeOfCommunities.Invites}
						/>
					))}
				</div>
			) : (
				<InvitesEmptyState />
			)}
		</div>
	);
};

export default memo(InvitesList);
