import { useMemo, useCallback } from "react";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";

import { ECommunityType, ISelectedCommunityToJoin, TStore } from "models";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.payments.memberSubscription,
	(state: TStore) => state.auth?.credentials?.user,
	(state: TStore) => state.communities.selectedCommunityToJoinInfo,
	(state: TStore) => state.community,
	(state: TStore) => state.subscription,
	(
		memberSubscription,
		authenticatedUser,
		selectedCommunityToJoinInfo,
		community,
		subscription,
	) => ({
		memberSubscription,
		authenticatedUser,
		selectedCommunityToJoinInfo,
		community,
		subscription,
	}),
);

export const useSelectCommunityByProcess = ({
	typeOfCommunity,
}: {
	typeOfCommunity: ECommunityType;
}): { resultedCommunity: ISelectedCommunityToJoin | null } => {
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { selectedCommunityToJoinInfo, community, subscription } =
		useSelector(stateSelector);

	const resultedCommunity = useMemo(() => {
		if (
			typeOfCommunity === ECommunityType.join &&
			selectedCommunityToJoinInfo
		) {
			return selectedCommunityToJoinInfo;
		} else if (
			typeOfCommunity === ECommunityType.current &&
			community?.data &&
			subscription?.changeSubscriptionSelectedMemberPlan
		) {
			return {
				...community.data,
				selected_member_plan:
					subscription.changeSubscriptionSelectedMemberPlan,
			} as ISelectedCommunityToJoin;
		} else return null;
	}, [community, selectedCommunityToJoinInfo, typeOfCommunity, subscription]);
	return { resultedCommunity };
};
