import { EGroupPrivacy } from "models";
import {
	EResourceActionTypes,
	IResourceState,
	TResourceActions,
} from "models/resource";

const initial: IResourceState = {
	loading: false,
	path: [],
	data: undefined,
	currentFolder: undefined,
	order: "latest",
	order_dir: "desc",
	meta: {
		pagination: {
			current_page: 0,
			per_page: 0,
			total_pages: 0,
			total_objects: 0,
			has_next_page: true,
		},
		scope_group: {
			id: 0,
			name: "",
			description: "",
			privacy: EGroupPrivacy.closed,
		},
		folders: null,
	},
	uploadProgress: undefined,
	resourceId: undefined,
	resourceSuccessfullyUploaded: undefined,
	createdInterval: undefined,
};

export function resourceReducer(
	state = initial,
	action: TResourceActions,
): IResourceState {
	switch (action.type) {
		case EResourceActionTypes.setResource: {
			const { data, meta } = action.payload;
			return {
				...state,
				data,
				meta,
			};
		}
		case EResourceActionTypes.setResourceUploadProgress: {
			return {
				...state,
				uploadProgress: action.payload,
			};
		}
		case EResourceActionTypes.setResourceId: {
			return {
				...state,
				resourceId: action.payload,
			};
		}
		case EResourceActionTypes.setResourceSuccessfullyUploaded: {
			return {
				...state,
				resourceSuccessfullyUploaded: action.payload,
			};
		}
		case EResourceActionTypes.setInterval: {
			return {
				...state,
				createdInterval: action.payload,
			};
		}
		default:
			return state;
	}
}
