import {
	IDoc,
	IMediaItemLoadingSetAction,
	IMediaItemSetAction,
	IVideo,
} from "models";

// ENUMS
export enum EMediaItemActionTypes {
	update = "media/item/update",
	fetchItem = "media/item/fetch",
	setLoading = "media/item/loading/set",
	setPath = "media/path/set",
}

export interface IMediaItemState {
	loading: boolean;
	data?: (IVideo | IDoc) | null;
}

// TYPES
export type TMediaItemActions =
	| IMediaItemSetAction
	| IMediaItemLoadingSetAction;
