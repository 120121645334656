import { Radio } from "antd"
import { RadioButtonProps } from "antd/lib/radio/radioButton"
import styles from './styles.module.scss'

interface ICustomRadioButtonProps {
    children:Node | String
    
}

const CustomRadioButton = ({children,...props}:ICustomRadioButtonProps & RadioButtonProps) => {
    return(
        <div className={styles.customRadioButton}>
        <Radio {...props}>
            {children}
        </Radio>
        </div>
    )
}
export default CustomRadioButton