import { memo } from "react";

import { MainLayout } from "layouts";
import { HelmetPageTitle } from "components";
import { DetailedView } from "./components";

const MarketplaceView = () => {
	return (
		<>
			<HelmetPageTitle screenTitle={"Marketplace"} />
			<MainLayout>
				<DetailedView />
			</MainLayout>
		</>
	);
};

export default memo(MarketplaceView);
