import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";

import { ReactComponent as X } from "assets/images/x.svg";
import styles from "./styles.module.scss";

const LogoutMenuItem = () => {
	const navigate = useNavigate();
	const [confirmModalVisible, setConfirmModalVisible] = useState(false);

	const handleSignOut = () => {
		navigate("/auth/sign-out");
	};

	const toggleConfirmLogoutModal = useCallback(() => {
		setConfirmModalVisible(!confirmModalVisible);
	}, [confirmModalVisible]);

	return (
		<>
			<div onClick={toggleConfirmLogoutModal} className={styles.userIcon}>
				{"Logout"}
			</div>
			<Modal
				title={"Are you sure you want to logout?"}
				visible={confirmModalVisible}
				onOk={handleSignOut}
				okType={"danger"}
				centered
				closable={false}
				cancelText={"Cancel"}
				cancelButtonProps={{ id: "btn-cancel-delete-account" }}
				okButtonProps={{ id: "btn-ok-leave-group" }}
				okText={"Yes, I am sure"}
				onCancel={toggleConfirmLogoutModal}
				className={"modalContainer"}
				getContainer={() =>
					document.getElementById("content") as HTMLElement ?
					document.getElementById("content") as HTMLElement:
					document.getElementById("root") as HTMLElement
				}
			>
				<div
					onClick={toggleConfirmLogoutModal}
					className={"closeModal"}
				>
					<X fill="#FFFFFF" width={13} height={13} />
				</div>
			</Modal>
		</>
	);
};

export default LogoutMenuItem;
