import { call, put, all, takeEvery, select, take } from "redux-saga/effects";

import {
    EAuthActionTypes,
    IAuthSignInActionPayload,
    IAuthSignUpActionPayload,
    TStore,
    EAppActionTypes,
    ICommunityInvitation,
    ECommunitiesActionTypes,
    ESubscriptionActionTypes,
} from "models";
import {
    api,
    notify,
    setAuthenticationLoading,
    setUser,
    appInit,
    clearToken,
    resetStore,
    resetStoreSuccess,
    setAppLoading,
    getCommunitiesJoined,
    setInvite,
    getEditProfile,
    storeLogout,
    authenticateByToken,
    getUserCurrentSubscriptionAction,
    joinCommunity,
    getInvitationDataByCommunityLink,
    history,
} from "store";
import { updateToken } from "../..";
import { getInvite, getSubscriptionManagementActionSaga } from "store/actions";
import { isItAWhiteLabel } from "utils";
import { whiteLabelConfig } from "config";
import { rollbar } from "utils";

const getOneSignalId = (state: TStore) => state.oneSignal.id;
const getUser = (state: TStore) =>
    state.auth.credentials && state.auth.credentials.user;
const getUserId = (state: TStore) => state.auth.credentials?.user.id;
const getCommunityInvitation = (state: TStore) =>
    state.auth.communityInvitation;
const whitelist = [
    "/auth/sign-up",
    "/auth/sign-in",
    "/invites/",
    "/hubs/",
    "/auth/forgot-password",
    "/reset-password",
    "/auth/token/",
];

function* authByTokenHandle({
    payload,
}: ReturnType<typeof authenticateByToken>) {
    try {
        const { data } = yield call(api.auth.authenticateByToken, payload);
        if (!data.user.access_token || !data.user) {
            yield call(
                notify,
                "error",
                "Authentication Failed",
                "Invalid credentials... please try again",
            );
            yield put(setAppLoading(false));
        } else {
            yield call(
                notify,
                "success",
                "Authentication Success",
                "Welcome back",
            );
            updateToken(data.user.access_token);
            yield put(getCommunitiesJoined());

            yield put(
                setUser({
                    credentials: data,
                }),
            );
            let communityId: number = 0;
            let communityData: Record<string, any> | null = null;
            if (data.user) {
                communityId =
                    data.user.last_visited_community_id ||
                    data.user.joined_community_id ||
                    data.user.last_previewed_community_id;
                if (communityId) {
                    const { data } = yield call(api.community.home, {
                        community_id: communityId,
                    });
                    communityData = data;
                }

                if (
                    communityData &&
                    (communityData?.meta?.privacy === "open" ||
                        communityData?.meta?.is_approved)
                ) {
                    yield put(
                        getEditProfile({ communityId, userId: data.user.id }),
                    );
                    yield put(
                        getUserCurrentSubscriptionAction({
                            communityId,
                            userId: data.user.id,
                        }),
                    );
                    yield put(
                        appInit({
                            userId: data.user.id,
                            communityId: communityId,
                            token: data.user.access_token,
                        }),
                    );
                    yield put(
                        getSubscriptionManagementActionSaga({
                            communityId,
                        }),
                    );
                    yield take(
                        ESubscriptionActionTypes.COMPLETE_GET_SUBSCRIPTION_MANAGEMENT,
                    );
                }
            } else {
                throw Error("Error: The request could not be performed.");
            }
        }
    } catch (error) {
        rollbar.error(error)
        console.error({ error });
        yield call(
            notify,
            "error",
            "Authentication Failed",
            "Invalid credentials... please try again",
        );
        yield put(setAppLoading(false));
    }
}

function* loginHandle({ payload }: IAuthSignInActionPayload) {
    yield put(setAppLoading(true));
    let response;
    try {
        const oneSignalId = yield select(getOneSignalId);
        response = yield call(api.auth.login, {
            ...payload,
            player_id: oneSignalId,
        });
        if (!response.data.user.access_token || !response.data.user) {
            yield call(
                notify,
                "error",
                "Authentication Failed",
                "Invalid credentials... please try again",
            );
            yield put(setAppLoading(false));
        } else {
            yield call(
                notify,
                "success",
                "Authentication Success",
                "Welcome back",
            );
            updateToken(response.data.user.access_token);
            yield put(getCommunitiesJoined());

            yield put(
                setUser({
                    credentials: response.data,
                }),
            );
            let communityId: number = 0;
            if (response.data.user) {
                communityId =
                    response.data.user.last_visited_community_id ||
                    response.data.user.joined_community_id ||
                    response.data.user.last_previewed_community_id;
            }

            if (communityId) {
                const { data: communityData } = yield call(api.community.home, {
                    community_id: communityId,
                });
                if (
                    communityData.meta.privacy === "open" ||
                    communityData.meta.is_approved
                ) {
                    // TODO: move setUser in a getUser function (saga function)
                    const { data: editProfileData } = yield call(
                        api.profile.getEditProfile,
                        {
                            user_id: response.data.user.id,
                            community_id: communityId,
                        },
                    );
                    const userData = {
                        user: {
                            ...editProfileData.user,
                            ...response.data.user,
                            community_id: communityId,
                            joined_community_id: communityId,
                        },
                    };

                    yield put(
                        setUser({
                            credentials: userData,
                        }),
                    );
                    yield put(
                        getUserCurrentSubscriptionAction({
                            communityId,
                            userId: response.data.user.id,
                        }),
                    );
                    yield put(
                        getEditProfile({
                            communityId,
                            userId: response.data.user.id,
                        }),
                    );
                    yield put(
                        appInit({
                            userId: response.data.user.id,
                            communityId: communityId,
                            token: response.data.user.access_token,
                        }),
                    );
                    yield put(
                        getSubscriptionManagementActionSaga({
                            communityId,
                        }),
                    );
                    yield take(
                        ESubscriptionActionTypes.COMPLETE_GET_SUBSCRIPTION_MANAGEMENT,
                    );
                } else {
                    yield put(
                        appInit({
                            userId: response.data.user.id,
                            communityId: "",
                            token: response.data.user.access_token,
                        }),
                    );
                    if (!isItAWhiteLabel()) {
                        yield history.push("/communities");
                    } else {
                        yield put(
                            joinCommunity({
                                id: whiteLabelConfig?.community?.id!,
                            }),
                        );
                        yield take(
                            ECommunitiesActionTypes.joinCommunityCompleted,
                        );
                    }
                }
                yield put(setAppLoading(false));
            } else {
                throw Error("Error: The request could not be performed.");
            }
        }
    } catch (error) {
        rollbar.error(error)
        console.error({ error });
        if (error?.response?.data?.meta) {
            if (
                Object.keys(error.response.data.meta).indexOf("is_approved") !==
                -1 &&
                !error.response.data.meta.is_approved
            ) {
                updateToken(response.data.user.access_token);

                yield put(
                    setUser({
                        credentials: response.data,
                    }),
                );
                yield put(
                    appInit({
                        userId: response.data.user.id,
                        communityId: null,
                        token: response.data.user.access_token,
                    }),
                );
                if (!isItAWhiteLabel()) {
                    yield history.push("/communities");
                } else {
                    yield put(
                        joinCommunity({
                            id: whiteLabelConfig?.community?.id!,
                        }),
                    );
                    yield take(ECommunitiesActionTypes.joinCommunityCompleted);
                }
                yield put(setAppLoading(false));
            }
        } else {
            let errors = [];
            if (error?.response?.data?.errors) {
                errors = error.response.data.errors.reduce(
                    (acc: string[], cur: { errors: string[] }) => {
                        acc = acc.concat(
                            cur.errors.map((item: string) =>
                                item.toLowerCase(),
                            ),
                        );

                        return acc;
                    },
                    [],
                );
            }
            if (errors.indexOf("no active communities." as never) !== -1) {
                yield call(
                    notify,
                    "success",
                    "Authentication Success",
                    "Welcome back",
                );
                updateToken(error.response.data.user.access_token);

                yield put(
                    setUser({
                        credentials: error.response.data,
                    }),
                );
                yield put(
                    appInit({
                        userId: error.response.data.user.id,
                        communityId: null,
                        token: error.response.data.user.access_token,
                    }),
                );
                if (!error.response.data.user)
                    yield history.push("/auth/sign-in");
                if (error.response.data.user) {
                    if (!isItAWhiteLabel()) {
                        yield history.push("/communities");
                    } else {
                        yield put(
                            joinCommunity({
                                id: whiteLabelConfig?.community?.id!,
                            }),
                        );
                        yield take(
                            ECommunitiesActionTypes.joinCommunityCompleted,
                        );
                    }
                }

                yield put(setAppLoading(false));
            } else {
                console.error({ error });
                yield call(
                    notify,
                    "error",
                    "Authentication Failed",
                    "Invalid credentials... please try again",
                );
                yield put(setAppLoading(false));
            }
        }
    }
}

function* singOutHandle({ payload }: any) {
    try {
        yield put(resetStore(payload));
        if (payload && payload.pathname) {
            const found = whitelist.reduce(
                (acc: boolean, cur: string) =>
                    acc || payload.pathname.indexOf(cur) === 0,
                false,
            );
            if (!found) yield history.push("/auth/sign-in");
        } else {
            yield history.push("/auth/sign-in");
        }
        clearToken();
    } catch (error) {
        rollbar.error(error)
        console.error({ error });
    }
}

function* signUpHandle({ payload }: IAuthSignUpActionPayload) {
    yield put(setAuthenticationLoading(true));
    yield put(setAppLoading(true));
    try {
        const oneSignalId = yield select(getOneSignalId);
        const communityInvitation: ICommunityInvitation = yield select(
            getCommunityInvitation,
        );
        const userDetails = {
            user: {
                ...payload,
            },
            player_id: oneSignalId,
        };
        const { data } = yield call(api.auth.signUp, userDetails);

        if (!data.user.access_token || !data.user) {
            yield put(setAuthenticationLoading(false));
            yield put(setAppLoading(false));
            throw new Error("The user couldn't be created!");
        }

        updateToken(data.user.access_token);
        yield put(
            setUser({
                credentials: {
                    user: data.user,
                },
            }),
        );
        yield put(
            appInit({
                userId: data.user.id,
                communityId: "",
                token: data.user.access_token,
            }),
        );

        if (isItAWhiteLabel()) {
            yield put(
                joinCommunity({
                    id: whiteLabelConfig?.community?.id!,
                }),
            );
            yield take(ECommunitiesActionTypes.joinCommunityCompleted);
        } else if (communityInvitation) {
            yield put(joinCommunity({ id: communityInvitation.community.id }));
            yield take(ECommunitiesActionTypes.joinCommunityCompleted);
        } else {
            yield history.push("/communities/all");
        }
        yield put(setAuthenticationLoading(false));
        yield put(setAppLoading(false));
    } catch (error) {
        rollbar.error(error)
        console.error({ error });
        yield call(
            notify,
            "error",
            "Registration Failed",
            "An account with this email address already exists",
        );
        yield put(setAuthenticationLoading(false));
        yield put(setAppLoading(false));
    }
}

function* resetStoreHandle({ payload }: any) {
    try {
        yield put(setAppLoading(true));
        const user = yield select(getUser);
        if (user && user.id) yield call(api.auth.logout, { userId: user.id });
        clearToken();
        yield put(resetStoreSuccess());
        if (
            process.env.REACT_APP_WHITELABEL_LOGIN_PAGE &&
            process.env.REACT_APP_SSO
        ) {
            window.location.assign(
                process.env.REACT_APP_WHITELABEL_LOGIN_PAGE,
            );
            return
        }
        if (payload && payload.pathname) {
            const found = whitelist.reduce(
                (acc: boolean, cur: string) =>
                    acc || payload.pathname.indexOf(cur) === 0,
                false,
            );
            if (!found) yield history.push("/auth/sign-in");
        } else {
            yield history.push("/auth/sign-in");
        }
        yield put(setAppLoading(false));
    } catch (error) {
        rollbar.error(error)
        console.error({ error });
        clearToken();
        yield put(resetStoreSuccess());
        yield history.push("/auth/sign-in");
        yield put(setAppLoading(false));
    }
}

function* getInviteHandle({ payload }: ReturnType<typeof getInvite>) {
    const { token } = payload;
    try {
        const { data } = yield call(api.auth.getInvite, token);
        yield put(setInvite(data));
        yield history.push("/auth/sign-up");
    } catch (error) {
        rollbar.error(error)
        console.error({ error });
        yield history.push("/auth/sign-up");
    }
}

function* getInvitationByCommunityLinkHandle({
    payload,
}: ReturnType<typeof getInvitationDataByCommunityLink>) {
    const { token } = payload;
    try {
        if (!token) throw new Error("Missing parameters!");
        const { data } = yield call(api.auth.communityPreviews, payload);
        yield put(
            setInvite({
                community: data,
            }),
        );
        yield history.push("/auth/sign-up");
    } catch (error) {
        rollbar.error(error)
        console.error({ error });
        yield history.push("/auth/sign-up");
    }
}

function* deleteAccountHandle() {
    try {
        yield put(setAuthenticationLoading(true));
        const user = yield select(getUser);
        const { data } = yield call(api.auth.deleteAccount, user.id);
        if (data?.user) {
            yield put(storeLogout());
        }
        yield put(setAuthenticationLoading(false));
        yield call(notify, "success", "User account successfully deleted!");
    } catch (error) {
        yield put(setAuthenticationLoading(false));
        yield call(notify, "error", "Failed to delete!");
    }
}

function* getUserCurrentSubscription({
    payload,
}: ReturnType<typeof getUserCurrentSubscriptionAction>) {
    const { communityId, userId } = payload;
    const globalUserId: number = yield select(getUserId);
    const loggedUserId = userId ? userId : globalUserId;
    try {
        if (!loggedUserId && !communityId) {
            throw new Error("Invalid parameters!");
        }
        // get and set the current subscription
        const { data: userProfileData } = yield call(
            api.profile.getUserProfile,
            {
                user_id: loggedUserId,
                community_id: communityId,
            },
        );

        const { data: editProfileData } = yield call(
            api.profile.getEditProfile,
            {
                user_id: loggedUserId,
                community_id: communityId,
            },
        );
        const { data: profileAccountSettingsData } = yield call(
            api.profile.getProfileAccountSettings,
            {
                user_id: loggedUserId,
                community_id: communityId,
            },
        );

        const userData = {
            user: {
                ...editProfileData.user,
                ...userProfileData.user,
                member_directory_opt_in:
                    profileAccountSettingsData.user.member_directory_opt_in,
                community_id: communityId,
                joined_community_id: communityId,
                currentPlanId: userProfileData.user.current_plan_id,
                currenSubscription: userProfileData.user.current_subscription,
            },
        };

        yield put(
            setUser({
                credentials: userData,
            }),
        );
        yield put(getSubscriptionManagementActionSaga({ communityId }));
        yield take(
            ESubscriptionActionTypes.COMPLETE_GET_SUBSCRIPTION_MANAGEMENT,
        );
    } catch (error) {
        rollbar.error(error)
        console.error(error);
    }

    yield put({ type: EAuthActionTypes.getUserCurrentSubscriptionCompleted });
}

export default function* rootSaga() {
    yield all([
        takeEvery(EAuthActionTypes.authByToken, authByTokenHandle),
        takeEvery(EAuthActionTypes.authInit, loginHandle),
        takeEvery(EAuthActionTypes.authSignOut, singOutHandle),
        takeEvery(EAuthActionTypes.authSignUp, signUpHandle),
        takeEvery(EAppActionTypes.resetStore, resetStoreHandle),
        takeEvery(EAuthActionTypes.getInvite, getInviteHandle),
        takeEvery(EAuthActionTypes.deleteAccount, deleteAccountHandle),
        takeEvery(
            EAuthActionTypes.getUserCurrentSubscription,
            getUserCurrentSubscription,
        ),
        takeEvery(
            EAuthActionTypes.getInvitationDataByCommunityLink,
            getInvitationByCommunityLinkHandle,
        ),
    ]);
}
