import { axiosInstance } from "..";

export const payments = {
	createMemberSubscribe: (payload: {
		communityId: number;
		stripe_plan_price_id: string;
	}) => {
		const { communityId, stripe_plan_price_id } = payload;
		return axiosInstance().post(
			`/communities/${communityId}/member_subscriptions`,
			{
				subscription: {
					stripe_plan_price_id: stripe_plan_price_id,
				},
			},
		);
	},
	changeMemberSubscription: (payload: {
		communityId: number;
		stripe_plan_price_id: string;
		subscriptionId: number;
	}) => {
		const { communityId, stripe_plan_price_id, subscriptionId } = payload;
		return axiosInstance().patch(
			`/communities/${communityId}/member_subscriptions/${subscriptionId}`,
			{
				subscription: {
					stripe_plan_price_id: stripe_plan_price_id,
				},
			},
		);
	},
	subscriptionPending: (payload: {
		communityId: number;
		subscriptionId: number;
	}) => {
		const { communityId, subscriptionId } = payload;
		return axiosInstance().post(
			`/communities/${communityId}/member_subscriptions/${subscriptionId}/pending`,
			{},
		);
	},
};
