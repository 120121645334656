import {
	IViewCountsAddPost,
	IViewCountsAddVideo,
	IViewCountsAddDoc,
	IViewCountsAddOffer,
} from "./actions";

// ENUMS
export enum EViewCountsTypes {
	postViewCountsIncrement = "viewCounts/posts/increment",
	addPost = "viewCounts/posts/add",
	videoViewCountsIncrement = "viewCounts/videos/increment",
	addVideo = "viewCounts/videos/add",
	docViewCountsIncrement = "viewCounts/docs/increment",
	addDoc = "viewCounts/docs/add",
	offerViewCountsIncrement = "viewCounts/offers/increment",
	addOffer = "viewCounts/offers/add",
}

// INTERFACES
export interface IViewCountsState {
	posts: number[];
	videos: number[];
	docs: number[];
	offers: number[];
}

// TYPES
export type TViewCountsActions =
	| IViewCountsAddPost
	| IViewCountsAddVideo
	| IViewCountsAddDoc
	| IViewCountsAddOffer;
