import React, { memo, useRef, useCallback, useState } from "react";
import { useEffect } from "react";

import styles from "./styles.module.scss";

interface IPostImage {
	postImage: string;
}

const PostImage: React.FunctionComponent<IPostImage> = ({ postImage }) => {
	const [imageSize, setImageSize] = useState<null | {
		width: number;
		height: number;
	}>(null);
	const imageContainerRef = useRef<HTMLDivElement>(null);
	const [showTheBackgroundImage, setShowTheBackgroundImage] = useState<
		null | boolean
	>(null);
	const [originalImageStyle, setOriginalImageStyle] = useState({});

	const handleOnLoadImage = useCallback(
		(event: React.SyntheticEvent<HTMLImageElement, Event>) => {
			setImageSize({
				width: event.currentTarget.width,
				height: event.currentTarget.height,
			});
		},
		[],
	);

	useEffect(() => {
		if (
			imageSize &&
			imageSize.width > 0 &&
			imageSize.height > 0 &&
			imageContainerRef.current &&
			showTheBackgroundImage === null
		) {
			if (imageSize.width + 100 < imageContainerRef.current.clientWidth) {
				setShowTheBackgroundImage(true);
				setOriginalImageStyle({ height: imageSize.height, zIndex: 2 });
			} else {
				setOriginalImageStyle({ width: "100%" });
			}
		}
	}, [imageSize, showTheBackgroundImage, postImage]);

	return (
		<div className={styles.postImg} ref={imageContainerRef}>
			{showTheBackgroundImage && imageSize && (
				<img
					style={{
						width: "100%",
						height: imageSize.height,
					}}
					className={styles.blurFilterImg}
					src={postImage}
					alt=""
				/>
			)}
			<img
				style={{ ...originalImageStyle }}
				src={postImage}
				alt=""
				onLoad={(event) => handleOnLoadImage(event)}
			/>
		</div>
	);
};

export default memo(PostImage);
