import React, { memo, useCallback } from "react";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";

import { FeedPosts } from "components";
import { IMember, TStore } from "models";
import { getMemberPosts } from "store";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.member.member.posts.pins,
	(state: TStore) => state.member.member.posts.meta,
	(state: TStore) => state.member.loading,
	(state: TStore) => state.app.currentCommunityId,
	(memberPosts, memberPostsMeta, memberPostsLoading, currentCommunityId) => ({
		memberPosts,
		memberPostsMeta,
		memberPostsLoading,
		currentCommunityId,
	}),
);

interface IPostData {
	member: Partial<IMember>;
}

const PostsData: React.FunctionComponent<IPostData> = ({ member }) => {
	const dispatch = useDispatch();
	const stateSelector = useCallback(stateSelectorHandle, []);
	const {
		memberPosts,
		memberPostsMeta,
		memberPostsLoading,
		currentCommunityId,
	} = useSelector(stateSelector);

	const handleLoadMore = useCallback(
		(page: number) => {
			if (currentCommunityId && member?.id) {
				dispatch(
					getMemberPosts({
						memberId: member.id,
						communityId: currentCommunityId,
						page,
					}),
				);
			}
		},
		[currentCommunityId, dispatch, member?.id],
	);

	return (
		<FeedPosts
			posts={memberPosts}
			loading={memberPostsLoading}
			hasMore={
				!memberPostsLoading && memberPostsMeta.pagination.has_next_page
			}
			loadMore={handleLoadMore}
			getScrollParent={() => document.getElementById("fullProfileBoxId")}
			meta={memberPostsMeta}
		/>
	);
};

export default memo(PostsData);
