import { IEventState, EEventActionTypes, TEventActions } from "models";

const initial: IEventState = {
	loading: false,
	currentId: 0,
	data: undefined,
};

export function eventReducer(
	state = initial,
	action: TEventActions,
): IEventState {
	switch (action.type) {
		case EEventActionTypes.eventLoadingSet: {
			return {
				...state,
				loading: action.payload,
			};
		}
		case EEventActionTypes.setEvent: {
			return {
				...state,
				data: action.payload.data,
				currentId: action.payload.data.id, // for backward compatibitlly
			};
		}

		default:
			return state;
	}
}
