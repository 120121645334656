import { ActiveCommunitiesList } from "..";

const ActiveCommunities = () => {
	return (
		<div
			style={{
				width: "100%",
				display: "flex",
				flex: 1,
				flexDirection: "column",
			}}
		>
			{/* {<InvitesCommunitiesList />} */}
			{<ActiveCommunitiesList />}
		</div>
	);
};

export default ActiveCommunities;
