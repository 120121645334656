import { call, put, all, takeEvery, select } from "redux-saga/effects";

import { EMembersActionTypes, TStore } from "models";
import { api, setMembers, getMembers, setMembersLoading } from "store";
import { IMember } from "../../../models/members/types";
import { rollbar } from "utils";

const getCurrentCommunityId = (state: TStore) => state.app.currentCommunityId;
const getCurrentGroupId = (state: TStore) => state.app.currentGroupId;
const getMembersSelectedTags = (state: TStore) => state.members.selectedTags;

function* getMembersHandle({ payload }: ReturnType<typeof getMembers>) {
	const {
		q = "",
		onSuccessCallback,
		onErrorCallback,
		autosuggest,
	} = payload || {};

	try {
		yield put(setMembersLoading(true));
		const currentCommunityId = yield select(getCurrentCommunityId);
		const currentGroupId = yield select(getCurrentGroupId);
		const selectedTags = yield select(getMembersSelectedTags);
		if (!currentCommunityId) {
			throw new Error("Missing parameters.");
		}
		const { data } = yield call(api.members.get, {
			communityId: currentCommunityId,
			groupId: currentGroupId,
			q,
			autosuggest,
			tags: selectedTags?.length > 0 ? selectedTags[0] : undefined, // there is an endpoint limitation and only one tag can be used
		});
		let res: IMember[] = [];
		if (payload?.currentMember) {
			const parseMembers = data?.users?.find(
				(member: IMember) =>
					member.id === parseFloat(payload?.currentMember as string),
			);
			res = [parseMembers].concat(
				data.users?.filter(
					(member: IMember) =>
						member.id !==
						parseFloat(payload?.currentMember as string),
				),
			);
		}

		yield put(
			setMembers({
				tags: data.tags,
				users: res.length > 0 ? res : data?.users,
			}),
		);
		// TODO: remove setMembersLoading. Is redundant.
		yield put(setMembersLoading(false));
		if (onSuccessCallback) {
			onSuccessCallback(res.length > 0 ? res : data?.users);
		}
	} catch (error) {
		rollbar.error(error)
		console.error(error);
		if (onErrorCallback) {
			onErrorCallback();
		}
		yield put(setMembersLoading(false));
	}
}

export default function* rootSaga() {
	yield all([takeEvery(EMembersActionTypes.getMembers, getMembersHandle)]);
}
