// eslint-disable-next-line @typescript-eslint/no-unused-vars

export interface IWhiteLabelConfig {
	community?: {
		id: number;
		slug?: string;
		showGroups: boolean;
		profileLabels?: {
			marketplace?: string;
			skills?: string;
			communityTags?: string;
		};
		whiteLabel?: string;
		onboarding?: {
			labels: {
				communityTags?: string;
				userSkills?: string;
				marketplace?: string;
			};
		};
		contactEmail?: string;
		tagLine?: string;
		hideNotifications?: boolean;
		disableRegister?: boolean;
		hideTerms?: boolean;
		excludeNotifications?: [string];
	};
	localStoragePrefix: string;

	accountSettings: {
		showEditProfile?: boolean; // UGENIE TRUE
		tags?: {
			community?: boolean; // UGENIE FALSE
			marketplace?: boolean; // UGENIE TRUE
			skills?: boolean; // UGENIE FALSE
		};
	};

	hideNewMessageIcon: boolean;
	hideChangeCommunityIcon?: boolean;
	hideNotificationsSettingsIcon?: boolean;
	forceOptIn?: boolean;
	// only on ttc should exist
	colors: {
		accent?: string;
		messageAccent?: string;
		activeBottomTab?: string;
		header?: string;
		text?: string;
		landingPageStart?: string;
		landingPageEnd?: string;
		labelColor?: string;
		defaultColor: string; // used as a fallback in case we dont have any postsMeta color.
	};
}

export const whiteLabelConfig: IWhiteLabelConfig | null = {
	community: {
		id: parseInt(process.env.REACT_APP_WHITELABEL_COMMUNITY_ID ?? "", 10),
		slug: process.env.REACT_APP_WHITELABEL_COMMUNITY_SLUG,
		showGroups: true,
		onboarding: {
			labels: {
				communityTags:
					"What community updates would you like to receive?",
				userSkills: "Skills",
			},
		},
		whiteLabel: "The Menopause Resilience Club™",
		contactEmail: "adelle@executivemidlife.coach",
		profileLabels: {
			communityTags: "Interests Tags",
			skills: "Skills Tags",
			marketplace: "Marketplace Tags",
		},
	},
	localStoragePrefix: "u-ccu-",
	accountSettings: {
		showEditProfile: true,
		tags: {
			community: true,
			marketplace: true,
			skills: true,
		},
	},
	hideChangeCommunityIcon: false,
	hideNewMessageIcon: false,
	hideNotificationsSettingsIcon: false,
	forceOptIn: false,
	colors: {
		landingPageEnd: "#FFFFFF",
		landingPageStart: "#FFFFFF",
		labelColor: "#186977",
		defaultColor: "#00BDD9", // #009ABA hard color, #00BDD9 light color
	},
};
