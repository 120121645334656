import { axiosInstance } from "..";
import { IMembersGetPayload } from "models";

export const members = {
	get: (payload: IMembersGetPayload) => {
		const { groupId, q, autosuggest, tags } = payload;
		return axiosInstance().get(
			`/communities/${payload.communityId}/users`,
			{
				params: {
					q: q,
					group_id: groupId,
					autosuggest,
					"tags[]": tags,
				},
			},
		);
	},
};
