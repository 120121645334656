import {
	call,
	put,
	all,
	takeEvery,
	select,
	takeLatest,
} from "redux-saga/effects";

import { EGroupsActionTypes, TStore } from "models";
import {
	api,
	getCommunity,
	getGroups,
	joinGroup,
	leaveGroup,
	setGroups,
	updateJoinedGroup,
} from "store";
import { rollbar } from "utils";

function* getGroupsHandle({ payload }: ReturnType<typeof getGroups>) {
	const { communityId, errorCallback, successCallback } =
		{ ...payload } || {};
	const currentCommunityId: number = yield select(getCurrentCommunityId);
	try {
		if (!currentCommunityId && !communityId) {
			throw new Error("Missing parameters!");
		}
		const { data } = yield call(api.groups.get, {
			currentCommunityId: communityId ?? currentCommunityId,
			page: 1,
		});
		if (data) {
			yield put(setGroups(data));
			if (successCallback) {
				successCallback(data.groups);
			}
		}
	} catch (error) {
		rollbar.error(error)
		if (errorCallback) {
			errorCallback();
		}
		console.error({ error });
	}
}

const getGroupsPagination = (state: TStore) => state.groups.meta?.pagination;
const getCurrentCommunityId = (state: TStore) => state.app.currentCommunityId;
function* getMoreGroupsHandle({ payload }: any) {
	try {
		const pagination = yield select(getGroupsPagination);
		const currentCommunityId: number = yield select(getCurrentCommunityId);
		const { data } = yield call(api.groups.getMore, {
			currentCommunityId:
				currentCommunityId || (payload && payload.currentCommunityId),
			page: parseInt(pagination.current_page) + 1,
		});
		yield put(setGroups(data));
	} catch (error) {
		rollbar.error(error)
		console.error({ error });
	}
}

function* joinGroupsHandle({ payload }: ReturnType<typeof joinGroup>) {
	const { communityId, groupId } = payload;
	try {
		if (!communityId || !groupId) throw new Error("Missing parameters!");
		const { data } = yield call(api.groups.join, payload);
		if (!data) throw new Error("Missing data!");
		const {
			membership = { id: "", membership_id: "" },
			request = { membership_id: "", id: "" },
		} = data;

		//**
		/* if the user has been approved into a new group		
		/* update the community's main tags data 
		/* we are doing this update because in the app we are using the groups within the community's global store.
		/* we are not using the groups' global data store which can include paginating data.
		 */
		if (membership.id) {
			yield put(getCommunity({ communityId }));
		}

		yield put(
			updateJoinedGroup({
				typeOfAction: "join",
				group_id: groupId,
				membership_id:
					membership.id || membership.membership_id || null,
				join_request_status:
					(request.membership_id && "pending") ||
					(membership.id && "approved") ||
					"none",
				request_id: request.id || null,
				is_joined: !!membership.id || !!membership.membership_id,
			}),
		);
	} catch (error) {
		rollbar.error(error)
		console.error(error);
	}
}

function* leaveGroupHandle({ payload }: ReturnType<typeof leaveGroup>) {
	const { groupId, privacy, communityId } = payload;
	try {
		const { data } = yield call(api.groups.leave, payload);

		//**
		/* if the user has leaved into a new group,		
		/* update the community's main tags data
		/* we are doing this update because in the app we are using the groups within the community's global store.
		/* we are not using the groups' global data store which can include paginating data.
		 */
		yield put(getCommunity({ communityId }));

		yield put(
			updateJoinedGroup({
				typeOfAction: "leave",
				group_id: groupId,
				membership_id: (data.membership && data.membership.id) || null,
				is_joined: false,
				join_request_status: "none",
				privacy,
				request_id: null,
			}),
		);
	} catch (error) {
		rollbar.error(error)
		console.error({ error });
	}
}
function* cancelJoinGroupRequestHandle({ payload }: any) {
	//   yield put(setGroupsLoading(true));
	try {
		const { data } = yield call(api.groups.cancelJoinRequest, payload);
		yield put(
			updateJoinedGroup({
				typeOfAction: "cancel request to join",
				group_id: payload.group_id,
				membership_id: (data.membership && data.membership.id) || null,
				request_id: null,
				is_joined: false,
				join_request_status: "none",
			}),
		);
		// yield put(setGroupsLoading(false));
	} catch (error) {
		rollbar.error(error)
		console.error({ error });
		// yield put(setGroupsLoading(false));
	}
}

export default function* rootSaga() {
	yield all([
		takeLatest(EGroupsActionTypes.getGroups, getGroupsHandle),
		takeLatest(EGroupsActionTypes.getMoreGroups, getMoreGroupsHandle),
		takeEvery(EGroupsActionTypes.joinGroup, joinGroupsHandle),
		takeEvery(EGroupsActionTypes.leaveGroup, leaveGroupHandle),
		takeEvery(
			EGroupsActionTypes.cancelJoinGroupRequest,
			cancelJoinGroupRequestHandle,
		),
	]);
}
