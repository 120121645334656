import {
	IDocsState,
	EDocsActionTypes,
	TDocsActions,
	EGroupPrivacy,
} from "models";

const initial: IDocsState = {
	loading: false,
	filterDocsLoading: false,
	path: [],
	data: undefined,
	selectedTags: [],
	query: "",
	redirectQuery: "",
	currentFolder: 0,
	order: "latest",
	order_dir: "desc",
	meta: {
		pagination: {
			current_page: 0,
			per_page: 0,
			total_pages: 0,
			total_objects: 0,
			has_next_page: true,
		},
		scope_group: {
			id: 0,
			name: "",
			description: "",
			privacy: EGroupPrivacy.closed,
		},
		folders: null,
	},
};

export function docsReducer(state = initial, action: TDocsActions): IDocsState {
	switch (action.type) {
		case EDocsActionTypes.docsLoadingSet: {
			return {
				...state,
				loading: action.payload,
			};
		}
		case EDocsActionTypes.filterDocsLoadingSet: {
			return {
				...state,
				filterDocsLoading: action.payload,
			};
		}
		case EDocsActionTypes.setDocs: {
			const { data, reset } = action.payload;
			const documents = reset
				? data.documents
				: state?.data
					? [...state.data].concat(data.documents)
					: data.documents;

			return {
				...state,
				data: documents,
				meta: data.meta,
			};
		}
		case EDocsActionTypes.setFilterTagsInStore: {
			const query = !action.payload.parsedTags.length ? "" : state.query;
			return {
				...state,
				selectedTags: action.payload.parsedTags,
				query,
			};
		}
		case EDocsActionTypes.setDocsQuery: {
			return {
				...state,
				query: action.payload.query,
				selectedTags: [],
			};
		}
		case EDocsActionTypes.incrementDocViews: {
			return {
				...state,
			};
		}
		case EDocsActionTypes.updateDocsCurrentFolder: {
			return {
				...state,
				currentFolder: action.payload,
			};
		}
		case EDocsActionTypes.setRedirectQuery: {

			return {
				...state,
				redirectQuery: action.payload.redirectQuery,
				selectedTags: [],
			};
		}
		case EDocsActionTypes.setDocsOrder: {
			return {
				...state,
				order: action.payload[0],
				order_dir: action.payload[1],
			};
		}
		case EDocsActionTypes.setPath: {
			const { index, path, item } = action.payload;

			let data: any = [];
			if (path) {
				data = path;
			} else if (index) {
				data = [...state.path].filter((_, i: number) => i === 0);
			} else if (item) {
				data = [...state.path].concat([item]);
			}

			return {
				...state,
				path: data,
			};
		}
		default:
			return state;
	}
}
