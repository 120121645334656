import React, { memo, useCallback, useState, useEffect, useRef } from "react";
import { MainLayout } from "layouts";
import { Switch } from "antd";
import { createSelector } from "reselect";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { SelectValue } from "antd/lib/select";

import { MembersPanel, SearchBarMultiple } from "./components";
import { TStore, IMember } from "models";
import {
	getMember,
	getMembers,
	notify,
	setEditProfile,
	setFilterTagsMembers,
	updateProfileAccountSettings,
} from "store";
import { MemberFilterTagUI, HelmetPageTitle, Loading } from "components";
import styles from "./styles.module.scss";
import DirectoryMemberOptedOutPanel from "components/DirectoryMemberOptedOutPanel";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.members,
	(state: TStore) => state.app.currentCommunityId,
	(state: TStore) => state.editProfile.data.user,
	(state: TStore) =>
		state.posts?.meta?.appearance?.member_directory_display_name?.plural,
	(state: TStore) => state.community.mainTags,
	(state: TStore) => state?.member?.member,
	(state: TStore) => state?.editProfile.loading,
	(
		members,
		currentCommunityId,
		userProfileData,
		memberDirectoryDisplayName,
		communityTags,
		member,
		loading,
	) => ({
		members,
		currentCommunityId,
		userProfileData,
		memberDirectoryDisplayName,
		communityTags,
		member,
		loading,
	}),
);

const Members: React.FunctionComponent<any> = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [getMembersLoading, setGetMembersLoading] = useState<boolean>(true);
	const [selectedMember, setSelectedMember] = useState<IMember | undefined>();
	const stateSelector = useCallback(stateSelectorHandle, []);
	const {
		members: globalMembers,
		currentCommunityId,
		userProfileData,
		memberDirectoryDisplayName,
		communityTags,
		member,
		loading,
	} = useSelector(stateSelector);
	const initFetchDataRef = useRef(false);
	const [showSettingsMenu, setShowSettingsMenu] = useState<boolean>(false);
	const [resultedMembers, setResultedMembers] = useState<IMember[] | []>([]);
	const [searchByNameAndBio, setSearchByNameAndBio] = useState<
		string | undefined
	>(undefined);
	const [selectedTagsToFilter] = useState<string[]>([]);
	const [selectedMemberTypeToFilter, setSelectedMemberTypeToFilter] =
		useState<string>("");
	const [selectedLocationToFilter, setSelectedLocationToFilter] =
		useState<string>("");
	const params = useParams<{
		memberId: string;
	}>();

	const selectMember = useCallback(
		(member: IMember | undefined) => {
			setSelectedMember(member);
			if (member && currentCommunityId) {
				dispatch(
					getMember({
						memberId: member.id,
					}),
				);
				navigate(`/members/${member.id}`, { replace: true });
			} else if (!member) {
				navigate(`/members`, { replace: true });
			}
		},
		[dispatch, currentCommunityId, navigate],
	);

	const filterExistingMembers = useCallback(
		(members: IMember[]) => {
			if (!members || !userProfileData?.id) {
				setResultedMembers([]);
				return;
			}

			if (
				!searchByNameAndBio &&
				!selectedMemberTypeToFilter &&
				!selectedLocationToFilter
			) {
				setResultedMembers(members);
			} else {
				const result = members.filter((item) => {
					const checkMemberType = selectedMemberTypeToFilter
						? item.membership_type === selectedMemberTypeToFilter
						: true;
					const checkLocation = selectedLocationToFilter
						? item.location
								.toLowerCase()
								.includes(
									selectedLocationToFilter.toLowerCase(),
								)
						: true;
					return checkMemberType && checkLocation;
				});
				setResultedMembers(result);
				// filterExistingMembers(result)
			}
			setSelectedMember(undefined);
		},
		[
			searchByNameAndBio,
			selectedLocationToFilter,
			selectedMemberTypeToFilter,
			userProfileData,
		],
	);

	const handleSearchMembers = useCallback(
		(nameAndBioQuery?: string) => {
			if (searchByNameAndBio || nameAndBioQuery || selectedTagsToFilter) {
				setGetMembersLoading(true);
				dispatch(
					getMembers({
						q: nameAndBioQuery ?? searchByNameAndBio,
						onSuccessCallback: (members) => {
							setGetMembersLoading(false);
							if (!members) return;
							filterExistingMembers(members);
						},
						onErrorCallback: () => {
							notify(
								"error",
								"An error occurred. Please try again later.",
							);
							setGetMembersLoading(false);
						},
					}),
				);
			} else {
				if (globalMembers.users) {
					filterExistingMembers(globalMembers.users);
				}
			}
			// if(location) [
			// 	member
			// ]
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			dispatch,
			filterExistingMembers,
			globalMembers.users,
			searchByNameAndBio,
		],
	);

	const triggerSearchByMemberNameAndBio:
		| React.KeyboardEventHandler<HTMLInputElement>
		| undefined = useCallback(
		(event: { key: string }) => {
			if (!event) return;
			if (event.key === "Enter") {
				handleSearchMembers();
			}
		},
		[handleSearchMembers],
	);

	const handleSearchByMemberNameAndBio: React.ChangeEventHandler<
		HTMLInputElement
	> = (event) => {
		if (!event) return;
		setSearchByNameAndBio(event.currentTarget.value);
	};

	const handleToggleSettingsPanel = useCallback(() => {
		setShowSettingsMenu(!showSettingsMenu);
	}, [showSettingsMenu]);

	const handleFilterTagsPosts = useCallback(
		(tags: string[]) => {
			let newTag: string[] | [] = [];
			if (tags && tags.length) {
				newTag = tags.filter(
					(item) =>
						selectedTagsToFilter &&
						!selectedTagsToFilter.includes(item),
				);
			}
			dispatch(setFilterTagsMembers({ tags: newTag }));
			handleSearchMembers();
		},
		[dispatch, handleSearchMembers, selectedTagsToFilter],
	);

	const handleOnChangeMemberType = useCallback((value: SelectValue) => {
		setSelectedMemberTypeToFilter(value as string);
	}, []);

	const handleSearchByLocation = useCallback((value: string) => {
		setSelectedLocationToFilter(value as string);
	}, []);

	const handleResetFilteringByLocation = useCallback((value: string) => {
		if (value === "") {
			setSelectedLocationToFilter("");
		} else {
			setSelectedLocationToFilter(value);
		}
	}, []);

	useEffect(() => {
		if (
			params?.memberId === member?.id.toString() &&
			!selectedMember &&
			member?.id
		) {
			selectMember(member);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [member, params?.memberId]);

	useEffect(() => {
		// if the member is opted out -> reset the showSettingsMenu state
		if (!userProfileData.member_directory_opt_in) {
			setShowSettingsMenu(false);
		}
	}, [userProfileData.member_directory_opt_in]);

	useEffect(() => {
		if (
			initFetchDataRef.current ||
			userProfileData.id === "0" ||
			!userProfileData.member_directory_opt_in
		)
			return;
		dispatch(
			getMembers({
				currentMember: params?.memberId,
				onSuccessCallback: (members) => {
					setGetMembersLoading(false);
					if (!members) return;

					setResultedMembers(members);
				},
				onErrorCallback: () => {
					setGetMembersLoading(false);
				},
			}),
		);
		initFetchDataRef.current = true;
	}, [
		dispatch,
		getMembersLoading,
		params?.memberId,
		userProfileData,
		userProfileData.id,
		userProfileData.member_directory_opt_in,
	]);

	useEffect(() => {
		if (params?.memberId) {
			dispatch(
				getMember({
					memberId: parseFloat(params.memberId as string),
				}),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<HelmetPageTitle
				screenTitle={
					memberDirectoryDisplayName
						? memberDirectoryDisplayName
						: "Members"
				}
			/>
			<MainLayout>
				{!userProfileData.member_directory_opt_in && !loading ? (
					<DirectoryMemberOptedOutPanel
						memberDirectoryOptIn={
							userProfileData.member_directory_opt_in
						}
						resetTheSettingsPanelState={handleToggleSettingsPanel}
					/>
				) : (
					<>
						<SearchBarMultiple
							handleSearchByMemberNameAndBio={
								handleSearchByMemberNameAndBio
							}
							onSearch={handleSearchMembers}
							firsInputValue={searchByNameAndBio}
							firstInputPlaceholder={"Search"}
							handleOnChangeMemberType={handleOnChangeMemberType}
							handleSearchByLocation={handleSearchByLocation}
							handleResetFilteringByLocation={
								handleResetFilteringByLocation
							}
							triggerSearchByMemberNameAndBio={
								triggerSearchByMemberNameAndBio
							}
							resetResultsByMemberNameAndBio={handleSearchMembers}
						/>
						<div
							className={styles.tagFiltersContainer}
							style={{ minWidth: "100%" }}
						>
							<MemberFilterTagUI
								onClick={handleFilterTagsPosts}
								tagsList={communityTags.tags}
								selectedTags={globalMembers.selectedTags}
							/>
						</div>
						{getMembersLoading ? (
							<Loading />
						) : (
							<MembersPanel
								handleToggleSettingsPanel={
									handleToggleSettingsPanel
								}
								selectMember={selectMember}
								selectedMember={selectedMember}
								members={resultedMembers}
								showSettingsMenu={showSettingsMenu}
								globalMembers={globalMembers}
							/>
						)}
						{showSettingsMenu && (
							<div>
								<div className={styles.settingPanel}>
									<h1 className={styles.settings}>
										Settings
									</h1>
									<div className={styles.directoryOptInDiv}>
										<h1 className={styles.directoryOptIn}>
											Directory Opt-In
										</h1>
										<div className={styles.switchDiv}>
											<p className={styles.switchState}>
												{userProfileData.member_directory_opt_in
													? "ON"
													: "OFF"}
											</p>
											<Switch
												className={
													styles.switchMemberDirectory
												}
												defaultChecked={
													userProfileData.member_directory_opt_in
												}
												checked={
													userProfileData.member_directory_opt_in
												}
												onChange={(event) => {
													// update the profile account settings.
													const callbackOnSuccess = (
														updatedUserData: any,
													) => {
														dispatch(
															setEditProfile({
																user: updatedUserData
																	.data.user,
															}),
														);
														if (event) {
															dispatch(
																getMembers(),
															);
														}
													};
													dispatch(
														updateProfileAccountSettings(
															{
																userData: {
																	member_directory_opt_in:
																		event,
																},
																callbackOnSuccess,
															},
														),
													);
												}}
											/>
										</div>
									</div>
									<div
										className={styles.closeSettingPanel}
										onClick={() => {
											setShowSettingsMenu(
												(prev) => !prev,
											);
										}}
									>
										<svg
											width="20"
											height="20"
											viewBox="0 0 20 20"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M15 5L5 15M5 5L15 15"
												stroke="black"
												stroke-width="1.3"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
										</svg>
									</div>
								</div>
							</div>
						)}
					</>
				)}
			</MainLayout>
		</>
	);
};

export default memo(Members);
