import { memo, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { Upload, message, Form, FormInstance } from "antd";
import { LoadingOutlined, PlusOutlined, EditOutlined } from "@ant-design/icons";
import classNames from "classnames";

import styles from "./styles.module.scss";
import { TStore } from "models";
import { GetImg } from "utils";
import ImgCrop from "antd-img-crop";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.editProfile.data?.user?.picture,
	(userAvatar) => ({
		userAvatar,
	}),
);

function getBase64(img: any, callback: Function) {
	const reader = new FileReader();
	reader.addEventListener("load", () => callback(reader.result));
	reader.readAsDataURL(img);
}

function beforeUpload(file: File) {
	const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
	if (!isJpgOrPng) {
		message.error("You can only upload JPG/PNG file!");
	}
	const isLt2M = file.size / 1024 / 1024 < 2;
	if (!isLt2M) {
		message.error("Image must smaller than 2MB!");
	}
	return isJpgOrPng && isLt2M;
}

const UserAvatar = ({ form }: { form: FormInstance<any> }) => {
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { userAvatar }: any = useSelector(stateSelector);
	const [loading, setLoading] = useState(false);

	const [imageUrl, setImageUrl] = useState();
	const uploadButton = (
		<div>
			{loading ? <LoadingOutlined /> : <PlusOutlined />}
			<div className="ant-upload-text">Upload</div>
		</div>
	);

	const handleChange = useCallback(
		(info) => {
			form.setFieldValue("avatar", info);

			if (info.file.status === "uploading") {
				setLoading(true);
				return;
			}
			if (info.file.status === "done") {
				// Get this url from response in real world.
				getBase64(info.file.originFileObj, (imageUrl: any) => {
					setLoading(false);
					setImageUrl(imageUrl);
				});
			}
		},
		[form],
	);

	const handleCustomRequest = ({ file, onSuccess }: any) => {
		setTimeout(() => {
			onSuccess("ok");
		}, 0);
	};

	return (
		<div className={styles.container}>
			<Form.Item
				name="avatar"
				className={classNames(styles.userAvatarBox, {
					[styles.hideAvatarBorder]: imageUrl || userAvatar,
				})}
			>
				<ImgCrop
					zoomSlider
					aspectSlider
					rotationSlider
					modalClassName="custom-modal"
					maxZoom={100}
				>
					<Upload
						listType="picture-card"
						className="avatar-uploader"
						showUploadList={false}
						beforeUpload={beforeUpload}
						onChange={handleChange}
						customRequest={handleCustomRequest}
					>
						<div>
							<div style={{ height: 104, width: 104 }}>
								{imageUrl ? (
									<>
										<img src={imageUrl} alt="avatar" />
									</>
								) : userAvatar ? (
									<>
										<GetImg endpoint={userAvatar} />
									</>
								) : (
									uploadButton
								)}
							</div>
							<EditOutlined className={styles.editIcon} />
						</div>
					</Upload>
				</ImgCrop>
			</Form.Item>
		</div>
	);
};

export default memo(UserAvatar);
