import {
	IEditProfileLoadingSetAction,
	IEditProfileGetAction,
	IEditProfileSetAction,
	IUserProfileLoadingSetAction,
	IUserProfileGetAction,
	IUserProfileSetAction,
	IUserProfileSaveAction,
	IEditProfilePostsSetAction,
	IEditProfilePostsGetAction,
	IUserProfileUpdateSettingsAction,
} from "./actions";

// ENUMS
export enum EProfileActionTypes {
	publicProfileLoadingSet = "profile/public/set/loading",
	getPublicProfile = "profile/public/get",
	setPublicProfile = "profile/public/set",
	editProfileLoadingSet = "profile/edit/set/loading",
	getEditProfile = "profile/edit/get",
	setEditProfile = "profile/edit/set",
	addUserImage = "profile/user/set/image",
	addUserLocation = "profile/user/set/location",
	setEditProfilePosts = "profile/edit/set/posts",
	getEditProfilePosts = "profile/edit/get/posts",
	userProfileLoadingSet = "profile/edit/set/loading",
	setUserProfile = "profile/user/set",
	getUserProfile = "profile/user/get",
	saveUserProfile = "profile/user/save",
	setUserProfileError = "profile/user/set/error",
	updateProfileAccountSettings = "profile/update/account/settings/",
	getEditProfileCompleted = "profile/edit/get/completed",
	setUserDetails = "profile/update/user/detail",
}

// userProfile --> logged user

// INTERFACES

export interface IEditProfileUser {
	id: string;
	first_name: string;
	last_name: string;
	location: string;
	about_me: string;
	email: string;
	name: string;
	member_directory_opt_in: boolean;
	picture?: string;
	membership_id?: string;
	interest_list?: any[];
	membership_type?: string;
	all_community_posts?: boolean;
	only_replies_to_my_posts?: boolean;
	no_lists?: boolean;
	postcode?: string;
	community_id?: string;
	joined_community_id?: string;
	subset_list?: { id: number; is_selected: boolean; name: string }[];
	skill_list?: { id: number; is_selected: boolean; name: string }[];
	good_tag_list?: { id: number; is_selected: boolean; name: string }[];
	profession: string | null;
	industry: string | null;
}

export interface IPublicProfileState {
	loading: boolean;
	data: any; //TODO: add proper interfaces here.
}
export interface IEditProfileState {
	loading: boolean;
	data: {
		user: IEditProfileUser;
		posts: {
			pins: any[];
			meta: {
				pagination: {
					current_page: number;
					per_page: number;
					total_pages: number;
					total_objects: number;
					has_next_page: boolean;
				};
			};
		};
	};
}

export interface IUserProfileState {
	loading: boolean;
	data: any;
	error: string;
}

export interface IPublicProfileLoadingPayload {
	loading: boolean;
}
export interface IEditProfileLoadingPayload {
	loading: boolean;
}
export interface IUserProfileLoadingPayload {
	loading: boolean;
}

// TYPES
export type TProfileActions =
	| IEditProfileLoadingSetAction
	| IEditProfileSetAction
	| IEditProfileGetAction
	| IUserProfileSetAction
	| IUserProfileGetAction
	| IUserProfileLoadingSetAction
	| IEditProfilePostsSetAction
	| IUserProfileSaveAction
	| IEditProfilePostsGetAction
	| IUserProfileUpdateSettingsAction;
