import { map } from "lodash";

import {
	ENotificationsActionTypes,
	TNotificationsActions,
	INotificationsState,
} from "models";

const initial: INotificationsState = {
	settings: undefined,
	data: [],
	loading:true,
	settingsSaved: {
		errorMessage: "",
		successMessage: "",
	},
	meta: {
		pagination: {
			current_page: "0",
			per_page: 0,
			total_pages: 0,
			total_objects: 0,
			has_next_page: false,
		},
	},
	pushNotification: null,
	unreadNotificationsCounter: 0,
	notificationLoading:{loading:false,id:undefined}
};

const getSetting = (key: string, value: boolean) => {
	let setting = {
		active: value,
		name: key,
	};
	switch (key) {
		case "email_notifications":
			return {
				...setting,
				title: "Email notifications",
			};
		case "private_message":
			return {
				...setting,
				title: "Someone private messages me",
			};
		case "post_comments":
			return {
				...setting,
				title: "Someone comments on my post",
			};
		case "commented_post":
			return {
				...setting,
				title: "Someone comments on a post I have previously commented on",
			};
		case "community_admin_message":
			return {
				...setting,
				title: "A community admin posts an update",
			};
		case "new_calendar":
			return {
				...setting,
				title: "Any new calendar",
			};
		case "new_video":
			return {
				...setting,
				title: "Any new video",
			};
		case "new_document":
			return {
				...setting,
				title: "Any new document",
			};
		case "new_offer":
			return {
				...setting,
				title: "Any new offer",
			};
		case "new_resource":
			return {
				...setting,
				title: "Any new resource",
			};
		case "new_broadcast":
			return {
				...setting,
				title: "-",
			};
		case "new_followed":
			return {
				...setting,
				title: "-",
			};
		case "unfollower":
			return {
				...setting,
				title: "-",
			};
		case "unfollowed":
			return {
				...setting,
				title: "-",
			};
	}
};

export function notificationsReducer(
	state = initial,
	action: TNotificationsActions,
): INotificationsState {
	switch (action.type) {
		case ENotificationsActionTypes.setNotificationsSettings: {
			const notifications = action.payload.membership;
			delete notifications.id;
			delete notifications.member_id;
			const mappedNotificationsList = map(notifications, (value, key) => {
				return {
					...getSetting(key, value),
				};
			});
			return {
				...state,
				settings: mappedNotificationsList as {
					active: boolean;
					name: string;
					title: string;
				}[],
			};
		}
		case ENotificationsActionTypes.setNotifications: {
			const { meta = undefined, notifications, reset ,loading} = action.payload;
			if (!meta || !notifications) {
				return state;
			}
			const resultedNotifications = reset
				? notifications
				: [...state.data, ...notifications];

			return {
				...state,
				data: resultedNotifications,
				loading: loading,
				meta,
			};
		}
		case ENotificationsActionTypes.setAllNotificationsAsRead: {
			const { notifications = [] } = action.payload;
			if (notifications.length === 0) {
				return state;
			}
			return {
				...state,
				data: [...notifications],
			};
		}
		case ENotificationsActionTypes.updateNotification: {
			const { notification } = action.payload;
			const newNotifications = state?.data ? [...state.data] : [];

			const notificationToBeUpdatedIndex = state?.data?.findIndex(
				(item) => {
					return notification.id === item.id;
				},
			);

			if (
				typeof notificationToBeUpdatedIndex !== "undefined" &&
				notificationToBeUpdatedIndex > -1
			) {
				newNotifications.splice(
					notificationToBeUpdatedIndex,
					1,
					notification,
				);
			}

			return {
				...state,
				data: [...newNotifications],
			};
		}
		case ENotificationsActionTypes.setUnreadNotificationsCounter: {
			const counter = action.payload.unreadNotificationsCounter;
			return {
				...state,
				unreadNotificationsCounter: counter,
			};
		}
		case ENotificationsActionTypes.setNotificationLoading: {
			
			return {
				...state,
				notificationLoading: action.payload,
			};
		}
		default:
			return state;
	}
}
