import { EOfferActionTypes, IOffer } from "models";

export const setOfferLoading = (payload: boolean) => ({
	type: EOfferActionTypes.offerLoadingSet,
	payload,
});

export const setOffer = (payload: { data: IOffer }) => ({
	type: EOfferActionTypes.setOffer,
	payload,
});

export const getOffer = (payload: {
	id: number;
	callbackOnSuccess: () => void;
	callbackOnError: () => void;
	forceCommunityId?: number;
}) => ({
	type: EOfferActionTypes.getOffer,
	payload,
});

export const redeemOffer = ({
	offerId,
	type,
}: {
	offerId: number;
	type: "email" | "link" | "website";
}) => ({
	type: EOfferActionTypes.redeemOffer,
	payload: { offerId, type },
});
