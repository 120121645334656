import { memo, useCallback } from "react";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";

import { ReactComponent as Location } from "assets/images/location.svg";
import { IMember, TStore } from "models";
import { GetImg } from "utils";
import styles from "./styles.module.scss";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.posts.meta.appearance?.industry_display_name,
	(state: TStore) => state.posts.meta.appearance?.profession_display_name,
	(industryDisplayName, professionDisplayName) => ({
		industryDisplayName,
		professionDisplayName,
	}),
);

const MemberCard = ({
	member,
	onClick,
	selected = false,
}: {
	member: IMember;
	onClick: () => void;
	selected?: boolean;
}) => {
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { industryDisplayName, professionDisplayName } =
		useSelector(stateSelector);

	return (
		<section
			className={
				selected
					? styles.memberSelectedCardContainer
					: styles.memberCardContainer
			}
			onClick={onClick}
		>
			<div className={styles.memberDetails}>
				<GetImg className={styles.image} endpoint={member.picture} />
				<div className={styles.details}>
					<h3 className={styles.memberName}>{member.full_name}</h3>
					<p className={styles.memberType}>
						{member.membership_type}
					</p>
					{industryDisplayName?.singular && member?.industry && (
						<p className={styles.memberType}>{member.industry}</p>
					)}
					{professionDisplayName?.singular && member?.profession && (
						<p className={styles.memberType}>{member.profession}</p>
					)}
					<span className={styles.location}>
						<Location /> {member.distance}
					</span>
				</div>
			</div>
		</section>
	);
};
export default memo(MemberCard);
