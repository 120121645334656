import { IGroup, ICommunity, ICommunityMemberPlan } from "models";
import { IUser } from "models/auth";
import {
	setSelectedCommunityToJoinUserLocation,
	setSelectedCommunityToJoinUserProfilePicture,
} from "store";
import {
	ICommunitiesLoadingSetAction,
	ICommunitiesGetAction,
	ICommunitiesSetAction,
	ICommunitiesJoinAction,
	ICommunitiesSetSelectedCommunityToJoinTagsAction,
	ICommunitiesSetSelectedCommunityToJoinMemberTypesAction,
	ICommunitiesSetSelectedCommunityToJoinSkillsAction,
	ICommunitiesSetCommunityToJoinAccessStatusAction,
	ICommunitiesSetSelectedCommunityToJoinMemberPlansAction,
	ICommunitiesSetMemberPlanAction,
	ICommunitiesUpdateJoinedAndApprovedStatusAction,
	ICommunitiesSetCommunityToJoinUserProfileAction,
	ICommunitiesSetCommunityToJoinUserLocationAction,
	ICommunitiesSetInvitationsAction,
	ICommunitiesSetCommunityToJoinSetdCurrencyAction,
	ICommunitiesSetCommunityToJoinProgressBarAction,
	ICommunitiesInitCommunityToJoinProgressBarAction,
	ICommunitiesStartCommunityJoinProcessAction,
} from "./actions";

// ENUMS

export enum ECommunitiesActionTypes {
	communitiesLoadingSet = "communities/set/loading",
	getCommunities = "communities/get",
	getMoreCommunities = "communities/get/more",
	setCommunities = "communities/set",
	joinCommunity = "communities/join",
	joinCommunityCompleted = "communities/join/completed",
	leaveCommunity = "communities/leave",
	setSelectedCommunityToJoinDetails = "communities/joined/details",
	setSelectedCommunityToJoinTags = "communities/selected/community/to/join/tags",
	setSelectedCommunityToJoinMemberType = "communities/selected/community/to/join/member/type",
	setSelectedCommunityToJoinSkills = "communities/selected/community/to/join/skills",
	postUserCommunitiesTags = "communities/user/tags/post",
	storeTagsAndMemberType = "communities/get/membership",
	setCommunityInterests = "communities/joined/set/interests",
	setSelectedCommunityToJoinProcessStage = "communities/joined/set/proccess/stage",
	getSelectedCommunityToJoinAccessStatus = "communities/get/selected/community/to/join/access/status",
	setCommunityAccessStatus = "communities/set/community/access/status",
	setSelectedCommunityToJoinMemberPlans = "communities/set/selected/community/to/join/member/plans",
	setSelectedCommunityToJoinMemberPlan = "communities/set/selected/community/to/join/member/plan",
	updateJoinedAndApprovedStatusForCommunity = "communities/update/joined/and/approved/status",
	setUserProfilePicture = "communities/set/user/profile/picture",
	setUserProfileLocation = "communities/set/user/profile/location",
	getCommunitiesInvitations = "communities/get/invitations",
	setCommunitiesInvitations = "communities/set/invitations",
	communityToJoinSetCurrency = "communities/selected/community/to/join/set/currency",
	setSelectedCommunityToJoinProgressBar = "communities/selected/community/to/join/set/progress/bar",
	initSelectedCommunityToJoinProgressBar = "communities/selected/community/to/join/init/progress/bar",
	startTheJoinCommunityProcess = "communities/start/join/community/process",
}

// INTERFACES AND TYPES
export enum EProcessToJoinCommunityStage {
	"welcome" = "welcome",
	"member plans" = "member-plans",
	"groups" = "groups",
	"pending status" = "pending-status",
	"resume payment process" = "resume-payment-process",
	"init joining" = "init-joining",
}

export enum EJoinCommunityOnboardingSteps {
	"Create account" = "Create account",
	"Choose plan" = "Choose plan",
	"Your profile" = "Your profile",
	"Select groups" = "Select groups",
	"Checkout" = "Checkout",
}

export type TProcessToJoinCommunityStage = EProcessToJoinCommunityStage | null;

export enum ETypeOfCommunities {
	Invites,
	Joined,
	All,
}

export interface ISelectedCommunityToJoin extends Partial<ICommunity> {
	id: number;
	tags: string[];
	memberTypes: string[];
	groups?: IGroup[];
	skills?: { id: number; name: string }[];
	selectedMemberType?: string;
	selectedTags?: string[];
	selectedSkills?: string[];
	userProfilePicture?: ReturnType<
		typeof setSelectedCommunityToJoinUserProfilePicture
	>["payload"];
	userProfileLocation?: ReturnType<
		typeof setSelectedCommunityToJoinUserLocation
	>["payload"];
	selectedCurrency?: string;
	theJoinProcessHasBeenStarted: boolean;
	onboardingProgressBarSteps: {
		[key in EJoinCommunityOnboardingSteps]: {
			checked: boolean;
			isCurrent: boolean;
			isDisabled: boolean;
			isVisible: boolean;
		};
	};
	appearance: {
		id: number;
		skill_tag_display_name: string;
	};
}

export interface ICommunityAppeareance {
	announcement_header_background: string;
	header_background: string;
	post_header_background: string;
	primary_color: string;
	secondary_color: string;
	logo: string;
	member_directory_display_name: {
		singular: string;
		plural: string;
	};
	marketplace_display_name: {
		singular: string;
		plural: string;
	};
	offer_display_name: {
		singular: string;
		plural: string;
	};
	document_display_name: {
		singular: string;
		plural: string;
	};
	video_display_name: {
		singular: string;
		plural: string;
	};
	skill_tag_display_name: {
		singular: string;
		plural: string;
	};
	industry_display_name: {
		singular: string;
		plural: string;
	};
	profession_display_name: {
		singular: string;
		plural: string;
	};
	resource_display_name: string;
	weblink_display_name: string;
}

export interface CommunityInvite {
	id: number;
	name: string;
	slug: string;
	location: string;
	picture: string;
	about: string;
	type: string;
	membership_id: string;
	membership_type: string;
	privacy: string;
	interests: string[];
	contact_email: string;
	welcome_message: string;
	joined: boolean;
	invited: boolean;
	approved: null | boolean;
	invite_member_type: string;
	primary_color: string;
	announcement_header_background: string;
	header_background: string;
	group_count: number;
	open_group_count: number;
	access_status: string;
	uses_stripe: boolean;
	selected_member_plan: Partial<ICommunityMemberPlan> | null;
	eligible_for_trial: boolean;
	tags: string[];
	sub_sets: string[];
	current_user: IUser;
}

export interface ICommunitiesState {
	loading: boolean;
	data?: ICommunity[];
	meta: {
		//TODO: meta needs to be mandatory (temporary)
		invites?: CommunityInvite[];
		pagination: {
			current_page: number;
			per_page: number;
			total_pages: number;
			total_objects: number;
			has_next_page: boolean;
		};
	};
	selectedCommunityToJoinInfo: ISelectedCommunityToJoin;
}

export interface ICommunitiesLoadingPayload {
	loading: boolean;
}

// TYPES
export type TCommunitiesActions =
	| ICommunitiesSetAction
	| ICommunitiesLoadingSetAction
	| ICommunitiesGetAction
	| ICommunitiesJoinAction
	| ICommunitiesSetSelectedCommunityToJoinTagsAction
	| ICommunitiesSetSelectedCommunityToJoinMemberTypesAction
	| ICommunitiesSetSelectedCommunityToJoinSkillsAction
	| ICommunitiesSetCommunityToJoinAccessStatusAction
	| ICommunitiesSetSelectedCommunityToJoinMemberPlansAction
	| ICommunitiesSetMemberPlanAction
	| ICommunitiesUpdateJoinedAndApprovedStatusAction
	| ICommunitiesSetCommunityToJoinUserProfileAction
	| ICommunitiesSetCommunityToJoinUserLocationAction
	| ICommunitiesSetInvitationsAction
	| ICommunitiesSetCommunityToJoinSetdCurrencyAction
	| ICommunitiesSetCommunityToJoinProgressBarAction
	| ICommunitiesInitCommunityToJoinProgressBarAction
	| ICommunitiesStartCommunityJoinProcessAction;
