import React from "react";
import reactHtmlReplace from "react-html-replace";

import { MentionTag, LinkTag } from "components";
import { ILinkedCommunityDocuments } from "models";
import ReadMore from "components/ReadMore";

interface IUseReactHtmlReplace {
	text: string;
	linked_community_documents?: ILinkedCommunityDocuments[];
	words?: number;
	maxHeight?:string
}

export const UseReactHtmlReplace = (props: IUseReactHtmlReplace) => {
	const { text, linked_community_documents, words = 60 ,maxHeight} = props;
	let htmlReplaceIndex = 0;

	return !text ? null : (
		<ReadMore
			maxHeight={maxHeight}
			text={reactHtmlReplace(
				text,
				(
					tag: string,
					props: {
						membermentionid?: string;
						href?: string;
						class?: string;
						tag?: string;
						id?:string
					},
				) => {
					htmlReplaceIndex++;

					if (tag === "a") {
						const {
							membermentionid = undefined,
							href,
							id,
						} = props;
						
						if (membermentionid) {
							return (
								<MentionTag
									key={`mention-tag-${membermentionid}-${htmlReplaceIndex}`}
									memberId={parseInt(membermentionid)}
								/>
							);
							// cssClass === "linkified"
						} else if (href) {
							return (
								<LinkTag
									key={`key-${href}-${htmlReplaceIndex}`}
									href={href}
									linked_community_documents={
										linked_community_documents
									}
									id={id}
								/>
							);
						}
					}
				},
			)}
			maxLines={words ? words : 50}
		/>
	);
};
