import { IVideoState, EVideoActionTypes, TVideoActions } from "models";

const initial: IVideoState = {
	loading: false,
	currentId: undefined,
	data: undefined,
	uploadProgress: undefined,
	videoId: undefined,
	videoSuccessfullyUploaded: undefined,
	createdInterval: undefined,
};

export function videoReducer(
	state = initial,
	action: TVideoActions,
): IVideoState {
	switch (action.type) {
		case EVideoActionTypes.videoLoadingSet: {
			return {
				...state,
				loading: action.payload,
			};
		}
		case EVideoActionTypes.setVideo: {
			return {
				...state,
				data: action.payload,
			};
		}
		case EVideoActionTypes.setVideoUploadProgress: {
			return {
				...state,
				uploadProgress: action.payload,
			};
		}
		case EVideoActionTypes.setVideoId: {
			return {
				...state,
				videoId: action.payload,
			};
		}
		case EVideoActionTypes.setVideoSuccessfullyUploaded: {
			return {
				...state,
				videoSuccessfullyUploaded: action.payload,
			};
		}
		case EVideoActionTypes.setInterval: {
			return {
				...state,
				createdInterval: action.payload,
			};
		}

		default:
			return state;
	}
}
