import { joinGroup } from "store/actions";
import { axiosInstance } from "..";

export const groups = {
	get: (payload: any) =>
		axiosInstance().get(
			`/communities/${payload.currentCommunityId}/groups`,
		),
	getMore: (payload: any) =>
		axiosInstance().get(
			`/communities/${payload.currentCommunityId}/groups`,
			{
				params: payload,
			},
		),
	join: (
		payload: Pick<
			ReturnType<typeof joinGroup>["payload"],
			"communityId" | "groupId"
		>,
	) =>
		axiosInstance().post(
			`communities/${payload.communityId}/groups/${payload.groupId}/group_join_requests`,
		),
	cancelJoinRequest: (payload: any) =>
		axiosInstance().delete(
			`communities/${payload.community_id}/groups/${payload.group_id}/group_join_requests/${payload.request_id}`,
		),
	leave: (payload: {
		communityId: number;
		groupId: number;
		membershipId: number;
	}) =>
		axiosInstance().delete(
			`communities/${payload.communityId}/groups/${payload.groupId}/group_memberships/${payload.membershipId}`,
		),
};
