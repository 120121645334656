import { MainLayout } from "layouts";
import { NotificationDetails as NotificationDetailsComponent } from "components";

const NotificationDetails = () => {
	return (
		<MainLayout>
			<NotificationDetailsComponent />
		</MainLayout>
	);
};

export default NotificationDetails;
