import React, { memo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faFileWord,
	faFilePdf,
	faFileAlt,
	faImage,
} from "@fortawesome/free-solid-svg-icons";

import classNames from "classnames";
import styles from "./styles.module.scss";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface IFileBox {
	docId: number;
	handleSelectedFile: Function;
	image?: string;
	docType: string;
	docExtension: string;
	name: string;
	active?: boolean;
}

const iconFileTypes: any = {
	pdf: faFilePdf,
	word: faFileWord,
	alt: faFileAlt,
};

const FileBox = ({
	docId,
	image,
	docType,
	docExtension,
	name,
	handleSelectedFile,
	/*uploadedBy, date, month,*/ active,
}: IFileBox) => {
	return (
		<div
			onClick={handleSelectedFile(docId)}
			className={classNames(styles.fileBox, active && styles.active)}
		>
			<div className={styles.imageHolder}>
				{docType === "application" ? (
					<FontAwesomeIcon
						icon={
							(iconFileTypes[docExtension]
								? iconFileTypes[docExtension]
								: iconFileTypes.alt) as unknown as IconProp
						}
						style={{
							display: "block",
							fontSize: "50px",
							margin: "auto",
						}}
					/>
				) : (
					<FontAwesomeIcon
						icon={faImage as unknown as IconProp}
						style={{
							display: "block",
							fontSize: "50px",
							margin: "auto",
						}}
					/>
				)}
			</div>
			<div className={styles.fileInfo}>
				<p>{name}</p>
				{/* <span>Uploaded by  {uploadedBy}</span> */}
			</div>
			{/* <div className={styles.date}>
    			<p>{date}</p>
    			<span>{month}</span>
    		</div>  */}
		</div>
	);
};

export default memo(FileBox);
