import { Modal } from "antd";
import { useNavigate } from "react-router-dom";

import styles from "./styles.module.scss";

const ModalManageSubscriptionNotAvailable = () => {
	const navigate = useNavigate();

	const handleOnOkBtn = () => {
		navigate("/dashboard", { replace: true });
	};

	return (
		<Modal
			title={"Your Subscription"}
			visible={true}
			onOk={handleOnOkBtn}
			okType={"primary"}
			centered
			closable={false}
			okText={"Ok"}
			cancelText=""
			className={"modalContainer"}
			cancelButtonProps={{ id: "btn-cancel-delete-account" }}
			okButtonProps={{ id: "btn-ok-leave-group" }}
			getContainer={() =>
				document.getElementById("content") as HTMLElement
			}
		>
			<div className={styles.modalContent}>
				{`It looks like you've purchased your subscription using an iOS device. Please manage your subscription in the App Store.`}
			</div>
		</Modal>
	);
};

export default ModalManageSubscriptionNotAvailable;
