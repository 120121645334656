import { call, put, all, takeLatest } from "redux-saga/effects";

import { EEventsActionTypes, IEventsGetAction } from "models";
import { api, setEventsLoading, setEvents } from "store";
import { rollbar } from "utils";

function* getEventsHandle({ payload }: IEventsGetAction) {

	yield put(setEventsLoading(true));
	try {
		const { data } = yield call(api.events.getMany, payload);

		if (!data) {
			yield put(setEventsLoading(false));
		} else {
			yield put(setEvents(data));
			yield put(setEventsLoading(false));
		}
	} catch (error) {
		rollbar.error(error)
		console.error({ error });
		yield put(setEventsLoading(false));
	}
}

export default function* rootSaga() {
	yield all([takeLatest(EEventsActionTypes.getEvents, getEventsHandle)]);
}
