import React, { memo } from "react";
import { Link } from "react-router-dom";

import styles from "./styles.module.scss";

interface IRelatedEventsBox {
	text: string;
	avatar: string;
	tag: any[];
	tagColor?: string;
	eventId: number;
	description: string;
}

const RelatedEventsBox = ({
	text,
	avatar,
	tag = [],
	tagColor,
	eventId,
	description,
}: IRelatedEventsBox) => {
	return (
		<Link
			to={`/calendar/events/${eventId}`}
			className={styles.relatedEventsBox}
		>
			<p>{text}</p>
			<div className={styles.container}>
				<div className={styles.tagSection}>
					{tag.map((item, index: number) => {
						return (
							<span
								key={`tag_key_${index}`}
								// className={styles[tagColor]}
							>
								{item}
							</span>
						);
					})}
				</div>
			</div>
			{/* <div className={styles.relatedEventImg}>
        <img src={image} alt="" />
      </div> */}
		</Link>
	);
};

export default memo(RelatedEventsBox);
