import {
	memo,
	useEffect,
	useCallback,
	useState,
	ChangeEvent,
	SetStateAction,
	Dispatch,
} from "react";
import { Input } from "antd";
import { useLocation, useMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";

import {
	setPostsQueryAndFilterAction,
	setVideosQueryAndFilterAction,
	setDocsQueryAndFilterAction,
	setOffersQueryAndFilterAction,
	setMarketplaceOffersQuery,
	setResourcesQueryAndFilterAction,
	setVideosQueryAction,
	setResourcesQueryAction,
	setDocsQueryAction,
	setMediaPathAction,
} from "store";
import { ReactComponent as SearchIcon } from "assets/images/search.svg";
import { ReactComponent as XIcon } from "assets/images/x.svg";
import { TStore } from "models";
import styles from "./styles.module.scss";
import { useDebounce } from "utils";

const stateSelectorHandle = (page: keyof TStore) =>
	createSelector(
		(state: TStore) => state.app.currentGroupId,
		(state: TStore) => {
			const index: string = page;
			return state[index]?.query;
		},
		(currentGroupId, storeQuery) => ({
			currentGroupId,
			storeQuery,
		}),
	);

const Search = ({
	setShowFullSearchbox,
	showFullSearchBox,
}: {
	setShowFullSearchbox: Dispatch<SetStateAction<boolean>>;
	showFullSearchBox: boolean;
}) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const [prevRootPath, setPrevRootPath] = useState<string>(
		`/${location.pathname.split("/")[1]}`,
	);
	const [inputValue, setInputValue] = useState("");
	const debouncedInputValue = useDebounce(inputValue, 700);

	const paths =
		"dashboard|documents|files|videos|offers|resources|marketplace";
	const match = useMatch({
		path: `/${paths
			.split("|")
			.find((p) => p === location.pathname.replace("/", ""))}`,
	});

	const stateSelector = useCallback(stateSelectorHandle, []);
	const { storeQuery } = useSelector(
		stateSelector(
			(match?.pathname === "/dashboard"
				? "posts"
				: match?.pathname === "/marketplace"
				? "marketplaceOffers"
				: match?.pathname.replace("/", "")) as unknown as keyof TStore,
		),
	);

	const performSearch = useCallback(
		(value: string) => {
			if (match?.pathname) {
				switch (match.pathname) {
					case "/dashboard": {
						dispatch(setPostsQueryAndFilterAction(value));
						break;
					}
					case "/videos": {
						dispatch(setVideosQueryAndFilterAction(value));
						break;
					}
					case "/documents": {
						dispatch(setDocsQueryAndFilterAction(value));
						break;
					}
					case "/offers": {
						dispatch(setOffersQueryAndFilterAction(value));
						break;
					}
					case "/marketplace": {
						dispatch(setMarketplaceOffersQuery({ query: value }));
						break;
					}
					case "/resources": {
						dispatch(
							setResourcesQueryAndFilterAction({ query: value }),
						);
						break;
					}
					default:
				}
			}
		},
		[dispatch, match?.pathname],
	);

	const onQueryChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value: string = e.target.value;
		setInputValue(value);

		if (value.length <= 0) performSearch("");
	};

	useEffect(() => {
		if (debouncedInputValue?.trim()) {
			performSearch(debouncedInputValue?.trim());
		}
	}, [debouncedInputValue, performSearch]);

	useEffect(() => {
		const currentRootPath = location.pathname.split("/")[1];
		if (currentRootPath !== prevRootPath) {
			const prevSection = prevRootPath.replace("/", "");

			setPrevRootPath(currentRootPath);

			if (prevSection === "videos") {
				dispatch(setVideosQueryAction({ query: "" }));
			}
			if (prevSection === "resources") {
				dispatch(setResourcesQueryAction({ query: "" }));
			}
			if (prevSection === "documents") {
				dispatch(setDocsQueryAction({ query: "" }));
			}
			if (
				prevSection === "resources" ||
				prevSection === "documents" ||
				prevSection === "videos"
			) {
				dispatch(setMediaPathAction({ type: prevSection, path: [] }));
			}
		}
	}, [dispatch, location.pathname, prevRootPath]);

	// useEffect(() => {
	// 	setInputValue(storeQuery);
	// }, [storeQuery]);

	if (!match) return null;

	return (
		<div className={styles.container}>
			<div
				className={
					showFullSearchBox
						? styles.searchInputMobile
						: styles.searchInput
				}
			>
				<Input
					autoFocus={showFullSearchBox}
					value={inputValue}
					onChange={onQueryChange}
					onFocus={() => {
						setShowFullSearchbox(true);
					}}
					onBlur={() =>
						inputValue.length <= 0 && setShowFullSearchbox(false)
					}
					placeholder={"Search"}
					suffix={
						storeQuery ? (
							<div
								onClick={() => performSearch("")}
								style={{
									cursor: "pointer",
									backgroundColor: "#cdcdcd",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									borderRadius: "100%",
									padding: 3,
								}}
							>
								<XIcon
									fill="white"
									width="9px"
									height="9px"
									onClick={() => setShowFullSearchbox(false)}
								/>
							</div>
						) : (
							<SearchIcon width="20px" height="20px" />
						)
					}
				/>
			</div>
			{!showFullSearchBox && (
				<SearchIcon
					className={styles.searchIcon}
					width="20px"
					height="20px"
					onClick={() => {
						setShowFullSearchbox(true);
					}}
				/>
			)}
		</div>
	);
};

export default memo(Search);
