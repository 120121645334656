import React, { memo, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import commonStyles from "../styles.module.scss";
import localStyles from "./styles.module.scss";
import { ESubscriptionStatus, TStore } from "models";
import { Loading } from "components";
import { CancelSubscriptionBtn, ManageSubscriptionBtn } from "../";

const styles = { ...commonStyles, ...localStyles };

const stateSelectorHandle = createSelector(
	(state: TStore) => state.subscription.currentMemberPlan,
	(state: TStore) => state.subscription.subscription,
	(memberPlan, memberSubscription) => ({
		memberPlan,
		memberSubscription,
	}),
);

const Subscription = () => {
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { memberPlan, memberSubscription } = useSelector(stateSelector);
	const currentPlan = memberSubscription
		? memberSubscription.plan
		: memberPlan;

	const diffInDays = useMemo(() => {
		if (memberSubscription?.status === ESubscriptionStatus.trialing) {
			if (memberSubscription?.expiry_date) {
				const today = new Date().getTime();
				const expiryDate = Date.parse(memberSubscription.expiry_date);
				return Math.ceil((expiryDate - today) / (24 * 3600 * 1000));
			}
		} else {
			return null;
		}
	}, [memberSubscription]);

	const subscriptionPriceOption =
		memberSubscription?.plan?.price_options?.find(
			(item) =>
				item.stripe_id === memberSubscription.stripe_plan_price_id,
		);

	return (
		<div>
			<div className={styles.mainContainer}>
				{!currentPlan ? (
					<Loading />
				) : (
					<>
						<div
							className={styles.headerContainer}
							style={{ alignItems: "baseline" }}
						>
							<div
								className={styles.titleContainer}
							>
								<div
									className={styles.headerTitle}
									style={{ alignSelf: "baseline" }}
								>
									{"Subscription"}
								</div>
								<div
									className={styles.planTitle}
								>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
										}}
									>
										<div>{currentPlan.display_name}</div>
										{diffInDays &&
										memberSubscription?.status ===
											ESubscriptionStatus.trialing ? (
											<div
												style={{
													marginLeft: 10,
													color: "red",
												}}
											>
												{diffInDays === 1
													? `${diffInDays} day left on your trial`
													: `${diffInDays} days left on your trial`}
											</div>
										) : null}
									</div>
									{Boolean(
										subscriptionPriceOption?.price_tag,
									) && (
										<div>
											{subscriptionPriceOption?.price_tag}
										</div>
									)}
									{memberSubscription?.cancelation_requested ? (
										<div>
											{`Next billing date: ${memberSubscription.expiry_date}`}
										</div>
									) : null}
								</div>
							</div>
							<div className={styles.buttonsContainer}>
								<CancelSubscriptionBtn />
								<ManageSubscriptionBtn />
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default memo(Subscription);
