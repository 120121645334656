import React, { memo, useCallback, useEffect, useState } from "react";
import { Col } from "antd";
import Scrollbar from "react-scrollbars-custom";
import InfiniteScroll from "react-infinite-scroller";

import { ReactComponent as MemberSetting } from "assets/images/memberSetting.svg";
import styles from "./styles.module.scss";
import { IMember } from "models";
import { Loading } from "components";

import MemberCard from "components/MembersCard";

interface IMembersPanel {
	handleToggleSettingsPanel: () => void;
	selectedMember: any;
	members?: IMember[];
	showSettingsMenu: boolean;
	globalMembers: any;
	selectMember: (member: IMember) => void;
}

const MembersPanel: React.FunctionComponent<IMembersPanel> = ({
	handleToggleSettingsPanel,
	selectedMember,
	members,
	showSettingsMenu,
	globalMembers,
	selectMember,
}) => {
	let [currentPage, setCurrentPage] = useState(0);
	let [hasMoreMembers, setHasMoreMembers] = useState<boolean | undefined>();
	let [membersPaginationData, setMembersPaginationData] = useState<
		IMember[] | undefined
	>(undefined);

	const loadMore = useCallback(() => {
		// get the next 10 members
		if (members?.length && members.length > 0) {
			const startIndex = currentPage * 10;
			const endIndex = startIndex + 10;
			const hasNextPage = members.slice(endIndex + 1);
			if (hasMoreMembers) {
				setMembersPaginationData(
					membersPaginationData?.concat(
						members.slice(startIndex, endIndex),
					),
				);
				setCurrentPage(currentPage + 1);
				setHasMoreMembers(hasNextPage.length > 0);
			} else {
				return null;
			}
		} else {
			return null;
		}
	}, [currentPage, hasMoreMembers, members, membersPaginationData]);

	useEffect(() => {
		if (members?.length) {
			// if the members.length > 10 membersPaginationData.length will be 10
			// if members.length < 10 (e.g because of a filtering process) then membersPaginationData.length = members.length
			const membersLength = members.length > 10 ? 10 : members.length;

			setMembersPaginationData(members.slice(0, membersLength));
			setHasMoreMembers(members.length > 10);
			setCurrentPage(1);
		} else {
			setMembersPaginationData([]);
			setHasMoreMembers(false);
		}
	}, [members]);

	return (
		<Col className={styles.mainContainer}>
			<div className={styles.memberWrapper}>
				<div className={styles.memberCount}>{`Members (${
					members ? members.length : 0
				})`}</div>
				<MemberSetting
					className={styles.settingIcon}
					onClick={handleToggleSettingsPanel}
				/>
			</div>
			<Scrollbar
				scrollerProps={{
					renderer: (props) => {
						const { elementRef, ...restProps } = props;
						return (
							<div
								id="membersPanelList"
								{...restProps}
								ref={elementRef}
							/>
						);
					},
				}}
			>
				{globalMembers.loading ? (
					<Loading />
				) : (
					<InfiniteScroll
						pageStart={0}
						loadMore={loadMore}
						initialLoad={true}
						hasMore={hasMoreMembers}
						threshold={250}
						loader={
							<div key={0} style={{ padding: 10 }}>
								{hasMoreMembers ? (
									<div
										style={{
											cursor: "pointer",
											textAlign: "center",
										}}
										onClick={loadMore}
									>
										{"Click here to load more items..."}
									</div>
								) : (
									<Loading />
								)}
							</div>
						}
						useWindow={false}
						getScrollParent={() =>
							document.getElementById("membersPanelList")
						}
						className={styles.infiniteScrollContainer}
					>
						<div className={styles.membersWrapper}>
							{membersPaginationData &&
							membersPaginationData.length > 0 ? (
								membersPaginationData?.map(
									(member: IMember, index) => {
										return (
											<MemberCard
												key={`${member.id}-${index}`}
												member={member}
												onClick={() =>
													selectMember(member)
												}
											/>
										);
									},
								)
							) : (
								<div className={"emptyPlaceholder"}>
									{"No members, yet."}
								</div>
							)}
						</div>
					</InfiniteScroll>
				)}
			</Scrollbar>
		</Col>
	);
};

export default memo(MembersPanel);
