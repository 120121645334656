import React, { memo, useCallback, useEffect, useRef } from "react";
import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import InfiniteScroll from "react-infinite-scroller";

import { MainLayout } from "layouts";
import { TStore } from "models";
import { getGroups, getMoreGroups } from "store";
import {
	GroupCard,
	HelmetPageTitle,
	Loading,
	ScrollToTopButton,
} from "components";
import styles from "./styles.module.scss";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.groups,
	(state: TStore) => state.app.currentCommunityId,
	(groups, currentCommunityId) => ({
		groups,
		currentCommunityId,
	}),
);

const GroupsList = () => {
	const dispatch = useDispatch();
	const ref: any = useRef();
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { groups, currentCommunityId } = useSelector(stateSelector);

	const loadMoreGroups = useCallback(() => {
		dispatch(getMoreGroups());
	}, [dispatch]);

	useEffect(() => {
		dispatch(getGroups());
	}, [dispatch]);

	return (
		<>
			<HelmetPageTitle screenTitle={"Groups"} />
			<MainLayout>
				<Row gutter={[32, 24]}>
					<Col span={24} order={2}>
						{groups.loading ? (
							<Loading />
						) : groups.data.length === 0 ? (
							<div className={styles.emptyPlaceholder}>
								{"There are no groups available!"}
							</div>
						) : (
							<InfiniteScroll
								pageStart={1}
								loadMore={loadMoreGroups}
								initialLoad={true}
								hasMore={groups.meta?.pagination.has_next_page}
								loader={
									<div
										key={0}
										style={{ padding: "12px 8px" }}
									>
										<Loading />
									</div>
								}
								useWindow={false}
							>
								{groups.data.map((item, index: number) => (
									<GroupCard
										key={`post-${index}`}
										data={item}
										currentCommunityId={currentCommunityId}
										style={{
											backgroundColor: "#ffffff",
										}}
									/>
								))}
							</InfiniteScroll>
						)}
					</Col>
				</Row>
				<ScrollToTopButton parentRef={ref} />
			</MainLayout>
		</>
	);
};

export default memo(GroupsList);
