import { call, put, all, takeEvery, select } from "redux-saga/effects";

import { EMemberActionTypes, IGetMemberDataPayload, TStore } from "models";
import {
	api,
	setMemberLoading,
	setMember,
	setMemberPosts,
	blockMember,
	unblockMember,
	getMember,
	reportMember,
	updateBanStatusForMember,
} from "store";
import { rollbar } from "utils";

const getCommunityId = (state: TStore) => state.app.currentCommunityId;

function* getMemberHandle({ payload }: ReturnType<typeof getMember>) {
	const { memberId, updateTheMemberItem } = payload;
	const communityId: number = yield select(getCommunityId);
	yield put(setMemberLoading(true));
	const { data } = yield call(api.member.get, {
		payload: { memberId, communityId },
	});
	const { data: memberPosts } = yield call(api.posts.getCommunityPosts, {
		communityId,
		userId: memberId,
		pinType: 0,
	});
	const reducerData = { ...data, posts: memberPosts };

	yield put(setMember(reducerData));
	if (updateTheMemberItem && reducerData.user) {
		const { blocked, blocked_by, id } = reducerData.user;
		yield put(updateBanStatusForMember({ blocked, blocked_by, id }));
	}
	yield put(setMemberLoading(false));
}

function* blockMemberHandle({ payload }: ReturnType<typeof blockMember>) {
	const { id, successCallback, errorCallback } = payload;
	try {
		const communityId: number = yield select(getCommunityId);
		const { data } = yield call(api.member.blockMember, {
			id,
			communityId,
		});

		if (data) {
			if (successCallback) {
				successCallback();
			}
			yield put(getMember({ memberId: id, updateTheMemberItem: true }));
		}
	} catch (error) {
		if (errorCallback) {
			errorCallback();
		}
		console.error(error);
	}
}

function* unblockMemberHandle({ payload }: ReturnType<typeof unblockMember>) {
	const { id, successCallback, errorCallback } = payload;
	try {
		const communityId: number = yield select(getCommunityId);

		const { data } = yield call(api.member.unblockMember, {
			id,
			communityId,
		});

		if (data) {
			if (successCallback) {
				successCallback();
			}
			yield put(getMember({ memberId: id, updateTheMemberItem: true }));
		}
	} catch (error) {
		rollbar.error(error);
		if (errorCallback) {
			errorCallback();
		}
		console.error(error);
	}
}

function* reportMemberHandle({ payload }: ReturnType<typeof reportMember>) {
	const { membershipId, message, successCallback, errorCallback } = payload;
	try {
		const communityId: number = yield select(getCommunityId);

		const { data } = yield call(api.member.reportMember, {
			communityId,
			membershipId,
			message,
		});

		if (data && successCallback) {
			successCallback();
		}
	} catch (error) {
		rollbar.error(error);
		if (errorCallback) {
			errorCallback();
		}
		console.error(error);
	}
}

function* getMemberPostsHandle({ payload }: IGetMemberDataPayload) {
	const { memberId, communityId, page } = payload;

	try {
		const { data: memberPosts } = yield call(api.posts.getCommunityPosts, {
			communityId,
			userId: memberId,
			pinType: 0,
			page,
		});
		const reducerData = { posts: memberPosts };
		yield put(setMemberPosts(reducerData));
	} catch (error) {
		rollbar.error(error);
	}
}

export default function* rootSaga() {
	yield all([
		takeEvery(EMemberActionTypes.memberGet, getMemberHandle),
		takeEvery(EMemberActionTypes.getMemberPosts, getMemberPostsHandle),
		takeEvery(EMemberActionTypes.blockMember, blockMemberHandle),
		takeEvery(EMemberActionTypes.unblockMember, unblockMemberHandle),
		takeEvery(EMemberActionTypes.reportMember, reportMemberHandle),
	]);
}
