import { EDocActionTypes, IDoc, IUploadDocActionPayload } from "models";

export const setDocLoading = (payload: boolean) => ({
	type: EDocActionTypes.docLoadingSet,
	payload,
});

export const setDoc = (payload: { data: IDoc }) => ({
	type: EDocActionTypes.setDoc,
	payload,
});
export const getDoc = (payload: {
	id: number;
	callbackOnSuccess: () => void;
	callbackOnError: () => void;
	folderId: number | null;
	forceCommunityId?: number;
}) => ({
	type: EDocActionTypes.getDoc,
	payload,
});

export const uploadDoc = (payload: IUploadDocActionPayload) => ({
	type: EDocActionTypes.uploadDoc,
	payload,
});
