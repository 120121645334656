import { Tag, TagProps } from "antd"
import { ReactNode } from "react";
import styles from "./styles.module.scss";


interface ICustomTag {
    children:ReactNode
}

const CustomTag = ({children,...props}:ICustomTag & TagProps) => {
    return (
        <div className={styles.tag}>
        <Tag  {...props}>
        {children}
    </Tag>
    </div>
    )
}
export default CustomTag