import React, { memo, useCallback } from "react";
import { Form, Button, Input, Row, Col, Radio, Select } from "antd";
import { DollarOutlined } from "@ant-design/icons";

import styles from "./styles.module.scss";

const { TextArea } = Input;
const { Option } = Select;

const FormBox = ({ children }: any) => {
	const onFinish = useCallback(() => {}, []);

	return (
		<div className={styles.form}>
			<Form name="form" layout="vertical" onFinish={onFinish}>
				<Row>
					<Col span={24}>
						<div className={styles.formTitle}>I would like to:</div>
						<div className={styles.radioButtonsBox}>
							<Form.Item>
								<Radio.Group
									defaultValue="sell"
									buttonStyle="solid"
									className={styles.radioButtons}
								>
									<Radio.Button
										className={styles.radioButton}
										value="sell"
									>
										Sell
									</Radio.Button>
									<Radio.Button
										className={styles.radioButton}
										value="buy"
									>
										Buy
									</Radio.Button>
									<Radio.Button
										className={styles.radioButton}
										value="trade"
									>
										Trade
									</Radio.Button>
								</Radio.Group>
							</Form.Item>
						</div>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Form.Item
							label="Title"
							name="title"
							rules={[{ required: true }]}
						>
							<Input />
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Form.Item name="category" label="Category">
							<Select allowClear>
								<Option value="cat 1">Category 1</Option>
								<Option value="cat 2">Category 2</Option>
							</Select>
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Form.Item name="price" label="Price">
							<Input size="middle" suffix={<DollarOutlined />} />
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Form.Item name="location" label="Location">
							<Input size="middle" />
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Form.Item
							name="description"
							label="Description (Max 120 characters)"
							rules={[{ required: true }]}
						>
							<TextArea rows={5} maxLength={120} />
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={24} className={styles.submitButton}>
						<Form.Item name="post">
							<Button
								type="primary"
								htmlType="submit"
								className=""
							>
								Post
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</div>
	);
};

export default memo(FormBox);
