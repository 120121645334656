import { takeEvery, all, select, put, call } from "redux-saga/effects";

import {
	TStore,
	EViewCountsTypes,
	IPostViewCountsIncrement,
	IVideoViewCountsIncrement,
	IDocViewCountsIncrement,
	IOfferViewCountsIncrement,
} from "models";
import {
	addViewCountForPostAction,
	api,
	addViewCountForVideoAction,
	addViewCountForDocAction,
	addViewCountForOfferAction,
} from "store";
import { rollbar } from "utils";
const getCommunityId = (state: TStore) => state.app.currentCommunityId;
const getPostsViewCounts = (state: TStore) => state.viewCounts.posts;
const getOffersViewCounts = (state: TStore) => state.viewCounts.offers;

function* postViewCountsIncrementHandle({ payload }: IPostViewCountsIncrement) {
	try {
		const communityId = yield select(getCommunityId);
		const posts = yield select(getPostsViewCounts);

		if (posts.indexOf(payload) === -1) {
			yield put(addViewCountForPostAction(payload));
			yield call(api.viewCounts.addViewToPost, communityId, payload);
		}
	} catch (error) {
		rollbar.error(
			error
		)
		console.error({ error });
	}
}

function* videoViewCountsIncrementHandle({
	payload,
}: IVideoViewCountsIncrement) {
	try {
		const communityId = yield select(getCommunityId);
		yield put(addViewCountForVideoAction(payload));
		yield call(api.viewCounts.addViewToVideo, communityId, payload);
	} catch (error) {
		console.error({ error });
		rollbar.error(
			error
		)
	}
}

function* docViewCountsIncrementHandle({ payload }: IDocViewCountsIncrement) {
	try {
		const communityId = yield select(getCommunityId);
		yield put(addViewCountForDocAction(payload));
		yield call(api.viewCounts.addViewToDoc, communityId, payload);
	} catch (error) {
		console.error({ error });
		rollbar.error(
			error
		)
	}
}

function* offerViewCountsIncrementHandle({
	payload,
}: IOfferViewCountsIncrement) {
	try {
		const communityId = yield select(getCommunityId);
		const offers = yield select(getOffersViewCounts);

		if (offers.indexOf(payload) === -1) {
			yield put(addViewCountForOfferAction(payload));
			yield call(api.viewCounts.addViewToOffer, communityId, payload);
		}
	} catch (error) {
		console.error({ error });
		rollbar.error(
			error
		)
	}
}

export default function* rootSaga() {
	yield all([
		takeEvery(
			EViewCountsTypes.postViewCountsIncrement,
			postViewCountsIncrementHandle,
		),
		takeEvery(
			EViewCountsTypes.videoViewCountsIncrement,
			videoViewCountsIncrementHandle,
		),
		takeEvery(
			EViewCountsTypes.docViewCountsIncrement,
			docViewCountsIncrementHandle,
		),
		takeEvery(
			EViewCountsTypes.offerViewCountsIncrement,
			offerViewCountsIncrementHandle,
		),
	]);
}
