import { axiosInstance } from "..";

export const marketplaceOffers = {
	get: (payload: {
		page: number;
		communityId: number;
		tagClicked?: boolean;
		groupId?: number | null;
		q?: string;
		isGoods?: boolean;
		tag_clicked?: boolean;
	}) => {
		const { page, q, isGoods, tagClicked, groupId } = payload;
		return axiosInstance().get(
			`/communities/${payload.communityId}/community-posts`,
			{
				params: {
					page,
					q,
					is_goods: isGoods,
					tag_clicked: tagClicked,
					include_pending: true,
					group_id: groupId,
				},
			},
		);
	},
	getTags: (payload: any) =>
		axiosInstance().get(
			`/communities/${payload.community_id}/community-posts/new`,
			{
				params: {
					is_goods: payload.is_goods,
				},
			},
		),
};
