import { memo, useEffect, useCallback, useState } from "react";
import { CodeSandboxOutlined } from "@ant-design/icons";
import { createSelector } from "reselect";
import { useSelector, useDispatch } from "react-redux";

import { CommentBox, CommentInput } from "components";
import { colors, GetImg } from "utils";
import { fetchPostComments, fetchMorePostComments } from "store";
import { TStore } from "models";
import styles from "./styles.module.scss";

const stateSelectorHandle = (postId: number) =>
	createSelector(
		(state: TStore) => (state.comments && state.comments[postId]) || null,
		(comments: any) => ({ comments }),
	);

const Comments = ({ postId }: { postId: number }) => {
	const dispatch = useDispatch();
	const [preview, setPreview] = useState(true);
	const stateSelector = useCallback(
		() => stateSelectorHandle(postId),
		[postId],
	);
	const { comments } = useSelector(stateSelector());

	const fetchMoreComments = useCallback(() => {
		if (preview) {
			setPreview(false);
		} else if (comments.meta.pagination.has_next_page) {
			dispatch(
				fetchMorePostComments({
					postId,
				}),
			);
		}
	}, [comments, postId, dispatch, preview]);

	const collapseComments = useCallback(() => {
		setPreview(true);
	}, []);

	useEffect(() => {
		if (postId) dispatch(fetchPostComments(postId));
	}, [dispatch, postId]);

	if (!postId || !comments) {
		return (
			<div style={{ textAlign: "center" }}>
				<CodeSandboxOutlined
					style={{ fontSize: 24, color: colors.grey5 }}
					spin
				/>
			</div>
		);
	}

	return (
		<>
			<CommentInput postId={postId} />
			{comments && comments.data && !!comments.data.length && (
				<div className={styles.counterContainer}>
					{comments.meta.pagination.total_objects > 3 && (
						<div className={styles.commentsNavigation}>
							<span
								className={styles.underline}
								onClick={fetchMoreComments}
							>
								{"more comments"}
							</span>
							{!preview && (
								<>
									<span>|</span>
									<span
										className={styles.underline}
										onClick={collapseComments}
									>
										{"collapse comments"}
									</span>
								</>
							)}
						</div>
					)}
					<div style={{ marginLeft: "auto", color: "#848484" }}>{`${
						comments.data.length > 3 && !preview
							? comments.data.length
							: Math.min(3, comments.data.length)
					} of ${
						comments.meta.pagination.total_objects
					} comments`}</div>
				</div>
			)}
			{!comments.data.length && (
				<div className={styles.noComments}>{"No comments yet"}</div>
			)}
			{comments?.data
				.filter((_: any, index: number) => (preview ? index < 3 : true))
				.reverse()
				.map((comment: any, index: number) => (
					<CommentBox
						postId={postId}
						commentId={comment.id}
						key={comment.id}
						avatar={<GetImg endpoint={comment.user.picture} />}
						boldName={comment.user.name}
						time={comment.timestamp}
						text={comment.content}
						lastCommentBox={
							(preview && index === 2) ||
							index + 1 === comments.data.length
						}
						user={comment.user}
						mentions={comment.mentions}
					/>
				))}
		</>
	);
};

export default memo(Comments);
