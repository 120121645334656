import { IFolder } from "components";
import { EGroupPrivacy } from "models/groups";
import {
	IDocsLoadingSetAction,
	IDocsGetAction,
	IDocsSetAction,
	IDocsFilterTagsSetAction,
	IDocsFilterTagsSetInStoreAction,
	ISetDocsQueryAction,
	IDocsIncrementDocViewsAction,
	IFilterDocsLoadingSetAction,
	IUpdateDocsCurrentFolderAction,
	IDocsSetOrderAction,
	IDocsSetPathAction,
	ISetDocsRedirectQuery,

} from "./actions";

// ENUMS
export enum EDocsActionTypes {
	docsLoadingSet = "docs/set/loading",
	filterDocsLoadingSet = "docs/filter/set/loading",
	getDocs = "docs/get",
	setDocs = "docs/set",
	setFilterTags = "filter/tags/set",
	setFilterTagsInStore = "filter/tags/set/inStore",
	setQueryAndFilter = "docs/query/setAndFilter",
	setDocsQuery = "docs/query/set",
	incrementDocViews = "docs/increment/doc/views",
	setDocsCurrentFolder = "docs/currentFolder/set",
	updateDocsCurrentFolder = "docs/currentFolder/update",
	updateDocsOrder = "docs/order/update",
	setDocsOrder = "docs/order/set",
	setPath = "docs/path/set",
	docsDelete = 'docs/delete',
	setRedirectQuery = 'docs/setRedirectQuery'

}

// INTERFACES
interface IDoc {
	id: number;
	attachment: { url: string };
	original_filename: string;
	title: string;
	description: string;
	mime_type: string;
	search_tags: string[];
	mentions: any[];
}

export interface IDocsState {
	loading: boolean;
	filterDocsLoading: boolean;
	path: IFolder[];
	data?: IDoc[];
	selectedTags: string[];
	redirectQuery?: string | null;
	query: string;
	currentFolder: undefined | number;
	order: "view_count" | "created_at" | "title" | "latest";
	order_dir: "asc" | "desc";
	meta: {
		pagination: {
			current_page: number;
			per_page: number;
			total_pages: number;
			total_objects: number;
			has_next_page: boolean;
		};
		scope_group: {
			id: number;
			name: string;
			description: string;
			privacy: EGroupPrivacy;
		};
		folders:
		| null
		| {
			children: any[];
			id: number;
			name: string;
			path: string;
		}[];
	};
}

// TYPES
export type TDocsActions =
	| IDocsSetAction
	| IDocsLoadingSetAction
	| IDocsGetAction
	| IDocsFilterTagsSetAction
	| IDocsFilterTagsSetInStoreAction
	| ISetDocsQueryAction
	| IDocsIncrementDocViewsAction
	| IFilterDocsLoadingSetAction
	| IUpdateDocsCurrentFolderAction
	| IDocsSetOrderAction
	| ISetDocsRedirectQuery
	| IDocsSetPathAction;
