import { axiosInstance } from "..";

export const posts = {
	getHomePosts: (payload: any) =>
		axiosInstance().get(`/communities/${payload.communityId}/home`, {
			params: {
				//include_pending: true,
				tag_clicked: payload.tagClicked,
				q: payload.query,
				//isGoods: false,
				group_id: payload.groupId,
				page: payload.page,
			},
		}),
	getPostComments: (payload: any) =>
		axiosInstance().get(
			`/communities/${payload.communityId}/community-posts/${payload.postId}/comments`,
			{
				params: {
					community_id: payload.communityId,
					community_post_id: payload.postId,
					page: payload.page,
					recent: true,
				},
			},
		),
	getCommunityPosts: (payload: any) =>
		axiosInstance().get(
			`/communities/${payload.communityId}/community-posts`,
			{
				params: {
					user_id: payload.userId,
					pin_type: payload.pinType,
					page: payload.page,
				},
			},
		),
	comment: (payload: any) => {
		return axiosInstance().post(
			`/communities/${payload.communityId}/community-posts/${payload.postId}/comments`,
			{
				comment: {
					content: payload.content,
				},
			},
		);
	},
	deletePost: (payload: any) => {
		return axiosInstance().delete(
			`/communities/${payload.community_id}/community-posts/${payload.communityPostId}`,
		);
	},
	add: (payload: any) => {
		return axiosInstance().post(
			`/communities/${payload.community_id}/community-posts`,
			{
				community_post: payload.community_post,
			},
		);
	},
	update: (payload: any) => {
		return axiosInstance().patch(
			`/communities/${payload.community_id}/community-posts/${payload.communityPostId}`,
			{
				community_post: payload.community_post,
				delete_images: payload.delete_images,
			},
		);
	},
	getPostEdit: ({ community_id, community_post_id }: any) =>
		axiosInstance().get(
			`/communities/${community_id}/community-posts/${community_post_id}/edit`,
		),
	getPost: ({ community_id, community_post_id }: any) =>
		axiosInstance().get(
			`/communities/${community_id}/community-posts/${community_post_id}`,
			{
				params: {
					post_id: community_post_id,
					all: true,
				},
			},
		),
	addImage: (payload: any) => {
		const { community_id, community_post_id, image } = payload;
		let formData = new FormData();
		formData.append("images[]", image);
		return axiosInstance().post(
			`/communities/${community_id}/community-posts/${community_post_id}/images`,
			formData,
			{
				headers: {
					"Content-Type": "multipart/form-data",
				},
			},
		);
	},
	deletePostComment: (payload: {
		commentId: number;
		postId: number;
		communityId: number;
	}) => {
		const { commentId, communityId, postId } = payload;
		return axiosInstance().delete(
			`/communities/${communityId}/community-posts/${postId}/comments/${commentId}`,
		);
	},

	editPostComment: (payload: {
		communityId: number;
		postId: number;
		commentId: number;
		content: string;
	}) => {
		return axiosInstance()
			.put(
				`/communities/${payload.communityId}/community-posts/${payload.postId}/comments/${payload.commentId}`,
				{
					comment: {
						content: payload.content,
					},
				},
			)
			.then((response: any) => {
				return response;
			})
			.catch((error: any) => {
				return Promise.reject(
					error.response ? error.response.data : error,
				);
			});
	},
};
