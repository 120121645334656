import React, { memo, useCallback } from "react";
import { Input } from "antd";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";

import styles from "./styles.module.scss";
import { TStore } from "models";
import { ReactComponent as SendMessageIcon } from "assets/images/send-message.svg";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.auth.credentials?.user,
	(authenticatedUser) => ({
		authenticatedUser,
	}),
);

interface ICommentInput {}

const CommentInput: React.FunctionComponent<ICommentInput> = () => {
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { authenticatedUser } = useSelector(stateSelector);

	return !authenticatedUser ? null : (
		<div className={styles.container}>
			<div className={styles.flexRow}>
				<div className={styles.avatar}>
					<img src={authenticatedUser.picture} alt="avatar" />
				</div>
				<div className={styles.inputTextWrapper}>
					<Input
						className={styles.inputElement}
						placeholder="Add a comment..."
						suffix={<SendMessageIcon />}
					/>
				</div>
			</div>
		</div>
	);
};

export default memo(CommentInput);
