import { ReactNode } from "react";
import styles from "./styles.module.scss";

interface IFormWrapperProps {
	children: ReactNode;
	maxWidth?: string;
	paddingTop?: string;
}
const FormWrapper = ({ children, maxWidth, paddingTop }: IFormWrapperProps) => {
	return (
		<div
			className={styles.authenticationBox}
			style={{ maxWidth: maxWidth, paddingTop: paddingTop }}
		>
			<div className={styles.container}>{children}</div>
		</div>
	);
};
export default FormWrapper;
