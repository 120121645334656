import { useCallback, useState } from "react";
import { Modal } from "antd";
import { useDispatch } from "react-redux";
import { Button } from "antd";

import { ReactComponent as X } from "assets/images/x.svg";
import { ReactComponent as LeaveGroup } from "assets/images/icons/common_icons/leave.svg";
import { leaveGroup } from "store";
import { EGroupPrivacy } from "models";
import styles from "./styles.module.scss";

interface ILeaveMenu {
	communityId: number;
	privacy: EGroupPrivacy;
	groupId: number;
	membershipId: number;
}

const LeaveMenu: React.FunctionComponent<ILeaveMenu> = ({
	communityId,
	groupId,
	membershipId,
	privacy,
}) => {
	const dispatch = useDispatch();
	const [confirmModalVisible, setConfirmModalVisible] = useState(false);

	const handleLeaveGroup = useCallback(() => {
		dispatch(
			leaveGroup({
				communityId: communityId,
				groupId,
				membershipId,
				privacy,
			}),
		);
	}, [dispatch, communityId, groupId, membershipId, privacy]);

	const toggleConfirmLeaveModal = useCallback(() => {
		setConfirmModalVisible(!confirmModalVisible);
	}, [confirmModalVisible]);

	return (
		<>
			<Button
				onClick={toggleConfirmLeaveModal}
				className={styles.leaveButton}
			>
				<LeaveGroup fill={"currentColor"} />
			</Button>
			<Modal
				title={"Are you sure you want to leave?"}
				visible={confirmModalVisible}
				onOk={handleLeaveGroup}
				okType={"danger"}
				centered
				closable={false}
				cancelText={"Cancel"}
				cancelButtonProps={{ id: "btn-cancel-delete-account" }}
				okButtonProps={{ id: "btn-ok-leave-group" }}
				okText={"Yes, I am sure"}
				onCancel={toggleConfirmLeaveModal}
				className={"modalContainer"}
				getContainer={() =>
					document.getElementById("content") as HTMLElement
				}
			>
				<div onClick={toggleConfirmLeaveModal} className={"closeModal"}>
					<X fill="#FFFFFF" width={13} height={13} />
				</div>
			</Modal>
		</>
	);
};
export default LeaveMenu;
