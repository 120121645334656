import React, { memo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { createSelector } from "reselect";

import { PostContent } from "components/PostBox/components";
import { getPostRawData, notify } from "store";
import { TStore } from "models";
import Loading from "components/Loading";
import styles from "./styles.module.scss";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.post,
	(post) => {
		return {
			post,
		};
	},
);

interface IPostNotificationDetails {
	postId: number;
	forceCommunityId: number;
}

const PostNotificationDetails: React.FC<IPostNotificationDetails> = ({
	postId,
	forceCommunityId,
}) => {
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { post } = useSelector(stateSelector);

	useEffect(() => {
		if (postId && forceCommunityId) {
			dispatch(
				getPostRawData({
					id: postId,
					forceCommunityId,
					callbackOnSuccess: () => setLoading(false),
					callbackOnError: () => {
						setLoading(false);
						notify(
							"error",
							"An error occurred. Please try again later.",
						);
					},
				}),
			);
		}
	}, [dispatch, postId, forceCommunityId]);

	return (
		<>
			{loading ? (
				<Loading />
			) : post.data?.community_post ? (
				<PostContent
					uniqueKey={post.data.community_post.id.toString()}
					post={post.data.community_post}
					hideDeleteAndEditMenu={true}
				/>
			) : (
				<div className={styles.centeredBox}>
					<div className={styles.heading}>
						We could not find what you are looking for
					</div>
					<div className={styles.description}>
						Please try again later!
					</div>
				</div>
			)}
		</>
	);
};

export default memo(PostNotificationDetails);
