import { ICreateVideoServicePayload, IUploadVideoServicePayload } from "models";
import { axiosInstance } from "..";

export const videos = {
	getEmbed: (payload: string) => axiosInstance().get(payload),
	get: (payload: {
		videoId?: number;
		page?: number;
		communityId?: number;
		groupId?: number | null;
		tag_clicked?: boolean;
		q?: string;
		folder_id?: number | undefined;
		order?: "view_count" | "created_at" | "title";
		order_dir?: "asc" | "desc";
	}) => {
		const {
			videoId,
			page,
			communityId,
			groupId,
			tag_clicked,
			q,
			folder_id,
			order_dir,
			order,
		} = payload;

		return axiosInstance().get(`/communities/${communityId}/videos`, {
			params: {
				video_id: videoId,
				group_id: groupId,
				tag_clicked: tag_clicked || false,
				q: q || null,
				page,
				all: true,
				folder_id,
				order,
				order_dir,
			},
		});
	},
	deleteVideo: (payload: { community_id: number, videoId: number }) => {
		return axiosInstance().delete(`/communities/${payload.community_id}/videos/${payload.videoId}`)
	},
	createVideo: (payload: ICreateVideoServicePayload) => {
		const {
			group_ids,
			member_type_list,
			tag_list,
			folder_id,
			video_link,
		} = payload;

		const formData = new FormData();
		const formKeys = Object.keys(payload);
		formKeys.forEach((element) => {
			const item = element as keyof ICreateVideoServicePayload;
			if (item === "group_ids") {
				if (group_ids && group_ids.length > 0) {
					group_ids.forEach((item) => {
						formData.append(
							"community_document[group_ids][]",
							String(item),
						);
					});
				} else {
					formData.append("community_document[group_ids][]", "");
				}
			} else if (item === "member_type_list") {
				if (member_type_list && member_type_list.length > 0) {
					member_type_list.forEach((item) => {
						formData.append(
							"community_document[member_type_list][]",
							String(item),
						);
					});
				} else {
					formData.append(
						"community_document[member_type_list][]",
						"",
					);
				}
			} else if (item === "tag_list") {
				if (tag_list && tag_list.length > 0) {
					tag_list.forEach((item) => {
						formData.append(
							"community_document[tag_list][]",
							String(item),
						);
					});
				} else {
					formData.append("community_document[tag_list][]", "");
				}
			} else if (item === "folder_id" && folder_id) {
				formData.append(
					"community_document[folder_id]",
					String(folder_id),
				);
			} else if (item === "is_private" || item === "all_groups") {
				formData.append(
					`community_document[${item}]`,
					String(payload[item]),
				);
			} else if (item === "video_link" && video_link) {
				formData.append("community_document[video_link]", video_link);
			} else if (
				item !== "communityId" &&
				item !== "folder_id" &&
				payload[item]
			) {
				formData.append(`community_document[${item}]`, payload[item]);
			}
		});

		const config = {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		};

		return axiosInstance()
			.post(
				`/communities/${payload.communityId}/videos`,
				formData,
				config,
			)
			.then((response: any) => {
				return response;
			})
			.catch((error: any) => {
				console.error({ error });
				return Promise.reject(
					error.response ? error.response.data : error,
				);
			});
	},
	uploadVideo: (payload: IUploadVideoServicePayload) => {
		const formData = new FormData();
		formData.append("video", payload.video);
		const config = {
			headers: {
				"Content-Type": "multipart/form-data",
			},
			onUploadProgress: payload.videoUploadProgress,
		};

		return axiosInstance()
			.post(
				`/communities/${payload.communityId}/community_documents/${payload.videoId}/videos`,
				formData,
				config,
			)
			.then((response: any) => {
				return response;
			})
			.catch((error: any) => {
				console.error({ error });
				return Promise.reject(
					error.response ? error.response.data : error,
				);
			});
	},
};
