import React, { memo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faFile } from "@fortawesome/free-solid-svg-icons";

import { ReactComponent as MsExcelIcon } from "assets/images/icons/common_icons/excel.svg";
import { ReactComponent as MsWordIcon } from "assets/images/icons/common_icons/word.svg";
import { ReactComponent as MsPowerPointIcon } from "assets/images/icons/common_icons/powerpoint.svg";
import { ReactComponent as PdfIcon } from "assets/images/icons/common_icons/pdf.svg";
import { ReactComponent as VideoIcon } from "assets/images/icons/common_icons/video.svg";
import styles from "./styles.module.scss";

const DocumentTypeIcon = ({ type, videoUrl ,thumbnail}) => {
	let icon:
		| string
		| number
		| boolean
		| JSX.Element
		| React.ReactFragment
		| null
		| undefined;

	switch (type) {
		case "application/msword":
		case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
			icon = (
				<MsWordIcon
					className={thumbnail ?styles.thumbnailIcon:styles.bigIcon}

				
				/>
			);
			break;
		case "application/vnd.ms-excel":
		case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
			icon = (
				<MsExcelIcon
					className={thumbnail ?styles.thumbnailIcon:styles.bigIcon}


				
				/>
			);
			break;
		case "image/jpeg":
		case "image/png":
		case "image/gif":
		case "image/webp":
			icon = (
				<img
					src={require("assets/images/image_placeholder.png")}
					alt="picture_placeholder"
					className={thumbnail ?styles.thumbnailIcon:styles.bigIcon}

						// className={thumbnail ?styles.thumbnailIconImage:styles.bigIconImage}
			
				/>
			);
			break;
		case "application/pdf":
			icon = (
				<PdfIcon
				className={thumbnail ?styles.thumbnailIcon:styles.bigIcon}


				
				/>
			);
			break;
		case "application/vnd.ms-powerpoint":
		case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
			icon = (
				<MsPowerPointIcon
					className={thumbnail ?styles.thumbnailIcon:styles.bigIcon}


				
				/>
			);
			break;
		case "":
			icon = (
				<VideoIcon
				fill="#e2e5e7"
				className={thumbnail ?styles.thumbnailIcon:styles.bigIcon}


				
				/>
			);
			break;
		default:
			icon = (
				<FontAwesomeIcon
					className={thumbnail ?styles.thumbnailIconfile:styles.bigIconImage}


					icon={faFile as unknown as IconProp}
			
				/>
			);
			break;
	}

	return <div>{icon}</div>;
};

export default memo(DocumentTypeIcon);
