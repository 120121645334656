import {
	IMessageThread,
	IMessagesSetAction,
	IMessagesGetAction,
	IMessagesSetLoadingAction,
	IMessageThreadUpdateAction,
	IMessagesConversationSetLoadingAction,
	IMessagesSend,
	IMessageAddToThread,
	IMessagesConversationUpdateAction,
	IMessageCreatePinnedConversationAction,
	IMessageThreadSetAction,
	IMessageUpdateThreadsSilentlyAction,
	IMessageSetConversationErrorMessageAction,
	// IMessageUpdatePinnedConversationAction,
} from ".";

// ENUMS
export enum EMessagesActionTypes {
	messagesLoadingSet = "messages/set/loading",
	conversationLoadingSet = "conversation/set/loading",
	getMessages = "messages/get",
	addMessageToThread = "messages/thread/add",
	getConversations = "convesations/get",
	getConversationsSilently = "convesations/get/silently",
	updateThreadsSilently = "threads/update/silently",
	setMessages = "messages/set",
	updateMessagesThreads = "messages/threads/update",
	sendMessage = "messages/send",
	fetchConversation = "convesation/get",
	updateConversation = "conversation/update",
	conversationLoadingSetTest = "conversation/set/loading",
	setSelectdThread = "messages/set/selected/thread",
	createPinnedConversation = "messages/create/pinned/conversation",
	// updatePinnedConversation = "messages/update/pinned/conversation",
	setConversationErrorMessage = "conversation/set/error/message",
}

export interface IThreads {
	[threadId: number]: {
		posts: IMessageThread[];
		meta: {
			[key: string]: string | number | boolean;
		};
	};
}

export interface IConversationThread {
	loading: boolean;
	conversation: IMessageThread[];
	message: IMessageThread;
	meta: any;
}

export interface ISender {
	id: number;
	name: string;
	email: string;
	picture: string;
	postcode: string;
	degree?: number;
	user_id: number;
}

export interface IConversationData {
	[key: number]: IMessageThread;
}
export interface IConversationMeta {
	pagination: {
		current_page: number;
		per_page: number;
		total_pages: number;
		total_objects: number;
		has_next_page: boolean;
	};
	notification_counter: number;
	mailbox_badge: number;
	message_counter: number;
}

export interface IMessagesState {
	loading: boolean;
	orderedThreads: { [key: number]: number[] };
	conversation: {
		loading: boolean;
		errorMessage: string;
		data?: IConversationData[] | null;
		meta?: IConversationMeta | null;
	};
	threads: IThreads | null; // null -> the data has not been fetched from api (initial value)
	pinnedConversation: IMessageThread | null;
	selectedThread: IMessageThread | null;
}

export type TMessagesActions =
	| IMessagesSetAction
	| IMessagesGetAction
	| IMessagesSetLoadingAction
	| IMessageThreadUpdateAction
	| IMessagesConversationSetLoadingAction
	| IMessagesSend
	| IMessageAddToThread
	| IMessageThreadSetAction
	| IMessagesConversationUpdateAction
	| IMessageCreatePinnedConversationAction
	| IMessageUpdateThreadsSilentlyAction
	| IMessageSetConversationErrorMessageAction;
// | IMessageUpdatePinnedConversationAction;
