import { Input, Form } from "antd";
import styles from './styles.module.scss'
import {  api, notify } from "store";
import { useCallback } from "react";

import {
	useNavigate,

} from "react-router-dom";
import { rollbar, validateEmail } from "utils";
import { TStore } from "models";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import FormInput from "components/FormInput";
import FormWrapper from "components/FormWrapper";
import CustomButton from "components/Button";
const stateSelectorHandle = createSelector(
	(state: TStore) => state.auth.loading,
	(loading) => ({ loading }),
);
const ForgetPasswordForm= () => {
const navigate = useNavigate();
const stateSelector = useCallback(stateSelectorHandle, []);

const { loading } = useSelector(stateSelector);

    const handleForgotPassword = useCallback(
		async (values: { email: string }) => {
			try {
				await api.auth.recover(values.email);
				notify(
					"success",
					"Password reset success",
					"Please check your email! You will find further instructions there!",
				);
				navigate("/auth/sign-in");
			} catch (error) {
                rollbar.error(error)
				console.error({ error });
				notify(
					"error",
					"Password reset failed",
					"Sorry ... we cannot find an account with that email address",
				);
			}
		},
		[navigate],
	);
    return (
        <FormWrapper>
        <Form
        className={styles.form}
        onFinish={handleForgotPassword}
    >
        <FormInput
            label="Email"
            name="email"
            rules={[
                {
                    required: true,
                    message: "Please enter your email address!",
                },
                () => ({
                    validator(_, value) {
                        const WARNING_MSG =
                            "Please enter your email address in the format email@example.com!";
                        if (!value) {
                            return Promise.reject(WARNING_MSG);
                        }
                        if (validateEmail(value)) {
                            return Promise.resolve();
                        } else {
                            return Promise.reject(WARNING_MSG);
                        }
                    },
                }),
            ]}
        >
            <Input type="text" placeholder="Enter your email"/>
        </FormInput>
        <FormInput className={styles.formItemCenteredNoBorderBtn}>
            <CustomButton
                htmlType="submit"
                loading={loading}
                buttonText={"Recover Password"}
            />
                
        </FormInput>
    </Form>
    
    </FormWrapper>
    )
}
export default ForgetPasswordForm