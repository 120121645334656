import { EMembersActionTypes, IMembersGetPayload } from "models";

export const setMembersLoading = (payload: boolean) => ({
	type: EMembersActionTypes.membersLoadingSet,
	payload,
});
export const setMembers = (payload: {
	users?: any;
	tags?: any;
	deleteAllMembersAndTags?: boolean;
}) => ({
	type: EMembersActionTypes.setMembers,
	payload,
});
export const getMembers = (payload?: IMembersGetPayload) => ({
	type: EMembersActionTypes.getMembers,
	payload,
});

export const setFilterTagsMembers = (payload: { tags: string[] }) => ({
	type: EMembersActionTypes.setFilterTagsMembers,
	payload,
});
export const updateBanStatusForMember = (payload: {
	id: number;
	blocked: boolean;
	blocked_by: boolean;
}) => ({
	type: EMembersActionTypes.updateTheBanStatusForMember,
	payload,
});
