import { memo, useCallback, useEffect, useState } from "react";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";

import { Loading } from "components";
import { ETypeOfCommunities, ICommunitiesState, TStore } from "models";
import { getCommunities, getMoreCommunities } from "store";
import CommunityBox from "components/CommunityBox";
import NotFoundCommunities from "../notFoundCommunities";
import styles from "./styles.module.scss";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.communities,
	(communities: ICommunitiesState) => ({ communities }),
);

const AllCommunities = () => {
	const dispatch = useDispatch();
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { communities } = useSelector(stateSelector);
	const [getCommunitiesLoading, setGetCommunitiesLoading] = useState(true);

	const loadMoreCommunities = useCallback(() => {
		dispatch(getMoreCommunities());
	}, [dispatch]);

	useEffect(() => {
		dispatch(
			getCommunities({
				onErrorCallback: () => setGetCommunitiesLoading(false),
				onSuccessCallback: () => setGetCommunitiesLoading(false),
			}),
		);
	}, [dispatch]);

	return (
		<div>
			{getCommunitiesLoading ? (
				<Loading />
			) : communities?.data?.length && communities?.data?.length > 0 ? (
				<InfiniteScroll
					className={styles.infiniteScroll}
					pageStart={1}
					loadMore={loadMoreCommunities}
					initialLoad={true}
					hasMore={communities.meta?.pagination.has_next_page}
					loader={
						<div
							key={0}
							style={{
								padding: "12px 8px",
							}}
						>
							<Loading />
						</div>
					}
					useWindow={false}
					getScrollParent={() =>
						document.getElementsByTagName("main")[0]
					}
				>
					{communities.data.map((item) => (
						<CommunityBox
							key={"community-" + item.id}
							data={item}
							sourceOfCommunitiesDataList={ETypeOfCommunities.All}
						/>
					))}
				</InfiniteScroll>
			) : (
				<NotFoundCommunities />
			)}
		</div>
	);
};

export default memo(AllCommunities);
