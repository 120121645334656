/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/iframe-has-title */
import { memo, useCallback, useEffect, useState } from "react";
import ReactPlayer from "react-player/lazy";

import { IVideo } from "models";
import styles from "./styles.module.scss";
import { Loading } from "components";
import { api } from "store";
import { rollbar } from "utils";
interface IVideoItem {
	item: IVideo;
	incrementVideo: () => void;
}

const VideoItem = ({ item, incrementVideo }: IVideoItem) => {
	const [isMediaLoaded, setIsMediaLoaded] = useState(false);
	const [vimeoUrl, setVimeoUrl] = useState<string | undefined>();
	const [reactPlayerError, setReactPlayerError] = useState<
		string | undefined
	>();

	const handleOnError = useCallback((error) => {
		setIsMediaLoaded(true);
		setReactPlayerError(error);
	}, []);

	useEffect(() => {
		const checkVimeoEmbedLink = async () => {
			if (item?.vimeo_uri || item?.resource_embed_uri) {
				try {
					const path =
						item?.vimeo_uri || item?.resource_embed_uri || "";

					const { data } = await api.videos.getEmbed(
						path.replace("/api/v1", ""),
					);

					const iframe = data.match(
						/(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/gi,
					);
					// eslint-disable-next-line no-useless-escape
					const vimeo_video_regex = /src\s*=(?:\"|\')(.+?)(?:\"|\')/gi;
					const vimeo_video_url = vimeo_video_regex.exec(
						iframe[0],
					)?.[1];
					setVimeoUrl(vimeo_video_url);
					// setIsMediaLoaded(true);
				} catch (error) {
					console.error({ error });
					rollbar.error(error)
					// setIsMediaLoaded(true);
				}
			}
		};
		checkVimeoEmbedLink();
	}, [item?.resource_embed_uri]);

	return (
		<>
			<div className={styles.wrapper}>
				{item && (
					<>
						{(item.video_link || vimeoUrl) && (
							<div
								className={styles.reactPlayerContainer}
								style={
									!isMediaLoaded
										? {
												paddingTop: 0,
										  }
										: {}
								} // I added paddingTop: 0 in order to show the Loading spinner in the center
							>
								{!isMediaLoaded && (
									<div className={styles.loaderContainer}>
										<Loading />
									</div>
								)}
								{!reactPlayerError ? (
									<ReactPlayer
										url={
											item?.video_link
												? item?.video_link
												: vimeoUrl
												? vimeoUrl
												: item?.attachment?.url
										}
										className={styles.player}
										onStart={incrementVideo}
										controls={true}
										width="100%"
										height="100%"
										light={false}
										onReady={() => setIsMediaLoaded(true)}
										onError={(error) =>
											handleOnError(error)
										}
										style={
											!isMediaLoaded
												? { visibility: "hidden" }
												: {}
										}
									/>
								) : (
									<div
										style={{
											display: "flex",
											flex: 1,
											position: "absolute",
											width: "100%",
											height: "100%",
											top: 0,
											bottom: 0,
											right: 0,
											left: 0,
										}}
									>
										<iframe
											style={{
												display: "flex",
												flex: 1,
											}}
											src={
												item?.video_link
													? item?.video_link
													: vimeoUrl
													? vimeoUrl
													: item?.attachment?.url
											}
											frameBorder="0"
											title="video"
										></iframe>
									</div>
								)}
							</div>
						)}
					</>
				)}
			</div>
		</>
	);
};

export default memo(VideoItem);
