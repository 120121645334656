import { IPreviewWhiteLabelCommunitySetAction } from "./actions";

// ENUMS
export enum EPreviewWhiteLabelCommunityActionTypes {
	getPreviewCommunityData = "get/preview/white/label/community/data",
	setPreviewCommunityData = "set/preview/white/label/community/data",
}

// INTERFACES
export interface IPreviewWhiteLabelCommunity {
	id: number;
	name: string;
	appearance: {
		primary_color: string;
		secondary_color: string;
		header_background: string;
		announcement_header_background: string;
		post_header_background: string;
		logo: string;
		offer_display_name: {
			singular: string;
			plural: string;
		};
		marketplace_display_name: {
			singular: string;
			plural: string;
		};
		member_directory_display_name: {
			singular: string;
			plural: string;
		};
		document_display_name: {
			singular: string;
			plural: string;
		};
		industry_display_name: {
			singular: string;
			plural: string;
		};
		profession_display_name: {
			singular: string;
			plural: string;
		};
		video_display_name: {
			singular: string;
			plural: string;
		};
		resource_display_name: string;
		weblink_display_name: string;
	};
	picture: string;
	cookie_policy: string;
	privacy_policy: string;
	terms_and_conditions: string;
}

// TYPES
export type TPreviewWhiteLabelCommunityActions =
	IPreviewWhiteLabelCommunitySetAction;
