import { useCallback, useState } from "react";
import { Input, Form, Checkbox, Divider } from "antd";
import styles from "./styles.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { createSelector } from "reselect";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

import { useDispatch, useSelector } from "react-redux";
import { register } from "store";
import { TStore } from "models";
import FormInput from "components/FormInput";
import FormWrapper from "components/FormWrapper";
import CustomButton from "components/Button";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.auth.communityInvitation,
	(state: TStore) => state.previewWhiteLabelCommunity,
	(communityInvitation, previewWhiteLabelCommunity) => ({
		communityInvitation,
		previewWhiteLabelCommunity,
	}),
);
const SignUpForm = () => {
	const [userConsent, setUserConsent] = useState(false);
	const stateSelector = useCallback(stateSelectorHandle, []);
	const [forceConsent, setForceConsent] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { communityInvitation, previewWhiteLabelCommunity } =
		useSelector(stateSelector);

	const handleUserConsent = useCallback((event: CheckboxChangeEvent) => {
		if (event.target.name === "personalDataUsage") {
			setUserConsent(event.target.checked);
		}
	}, []);

	const handleRegister = useCallback(
		(values: {
			email: any;
			password: any;
			firstName: any;
			lastName: any;
		}) => {
			if (userConsent) {
				dispatch(
					register({
						email: values.email,
						password: values.password,
						first_name: values.firstName,
						last_name: values.lastName,
						accepted_tcs: true,
						consented_to_pdp: true,
						is_nested_with_user: true,
					}),
				);
			} else {
				setForceConsent(true);
			}
		},
		[userConsent, dispatch],
	);
	return (
		<>
			<div className={styles.topTitle}>
				Your <span>{previewWhiteLabelCommunity?.name || "UGENIE"}</span>{" "}
				journey starts here. <br /> Create your account and join the
				community.
			</div>
			<div className={styles.form}>
				<FormWrapper maxWidth="552px">
					<div className={styles.title}>
						{"Already have an account? "}
						<Link
							className={
								styles.underlinedTextWithPrimaryColorLink
							}
							to="/auth/sign-in"
						>
							Sign In
						</Link>
					</div>
					<Form
						className={styles.loginForm}
						onFinish={handleRegister}
						initialValues={{
							email:
								(communityInvitation &&
									communityInvitation.email) ||
								"",
						}}
					>
						<FormInput
							label="First Name"
							name="firstName"
							rules={[
								{
									required: true,
									message: "Please input your First Name!",
								},
							]}
						>
							<Input type="text" placeholder="Joe" />
						</FormInput>
						<FormInput
							label="Last Name"
							name="lastName"
							rules={[
								{
									required: true,
									message: "Please input your Last Name!",
								},
							]}
						>
							<Input type="text" placeholder="Bloggs" />
						</FormInput>
						<FormInput
							label="Email"
							name="email"
							rules={[
								{
									type: "email",
									message:
										"Please enter your email address in the format email@example.com!",
								},
								{
									required: true,
									message: "Please enter your email address!",
								},
							]}
						>
							<Input type="text" placeholder="hello@ugenie.io" />
						</FormInput>
						<FormInput
							label="Password"
							name="password"
							hasFeedback
							rules={[
								{
									required: true,
									message: "Please input your Password!",
								},
								{
									min: 6,
									message: "Password minimum length is 6",
								},
								{
									pattern:
										/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
									message:
										"Password is too weak... Please include a mix of capitals, lower case and numbers",
								},
							]}
						>
							<Input.Password placeholder="***********" />
						</FormInput>
						<FormInput
							label="Confirm Password"
							name="confirmPassword"
							dependencies={["password"]}
							hasFeedback
							rules={[
								{
									required: true,
									message: "Please confirm your Password!",
								},
								({ getFieldValue }) => ({
									validator(rule, value) {
										if (
											!value ||
											getFieldValue("password") === value
										) {
											return Promise.resolve();
										}
										return Promise.reject(
											"The two passwords that you entered do not match!",
										);
									},
								}),
							]}
						>
							<Input.Password placeholder="***********" />
						</FormInput>
						<div className={styles.userConsentCheckboxes}>
							<div className={styles.checkboxContent}>
								<Checkbox
									name={"personalDataUsage"}
									onChange={handleUserConsent}
									style={{
										color: forceConsent
											? "#ff0000"
											: undefined,
									}}
									className={styles.checkbox}
								>
									<div className={styles.textLeftAligned}>
										I understand and agree that my personal
										data will be shared with the
										Administrators of the Community of which
										I am joining.
									</div>
								</Checkbox>
							</div>
							<Divider />
							<div className={styles.termsAndConditionsLabel}>
								By clicking Agree & Join, you agree to the
								<span
									onClick={() => {
										navigate("/terms-and-conditions");
									}}
									className={
										styles.underlinedTextWithPrimaryColor
									}
								>
									{previewWhiteLabelCommunity?.name
										? previewWhiteLabelCommunity?.name
										: "Ugenie"}{" "}
									User Agreement
								</span>
								,
								<span
									onClick={() => {
										navigate("/privacy-policy");
									}}
									className={
										styles.underlinedTextWithPrimaryColor
									}
								>
									Privacy Policy
								</span>{" "}
								and
								<span
									onClick={() => {
										navigate("/cookie-policy");
									}}
									className={
										styles.underlinedTextWithPrimaryColor
									}
								>
									Cookie Policy.
								</span>
							</div>
						</div>
						<FormInput
							className={styles.formItemCenteredNoBorderBtn}
							style={{ marginBottom: "0px" }}
						>
							<CustomButton
								htmlType="submit"
								style={{ marginTop: "24px" }}
								disabled={!userConsent}
								buttonText={"Sign up & Continue"}
							/>
						</FormInput>
					</Form>
				</FormWrapper>
			</div>
		</>
	);
};
export default SignUpForm;
