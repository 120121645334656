import React, { memo } from "react";

import { AuthenticationLayout } from "layouts";
import { AuthenticationBox } from "components";

const LogIn = () => {
	return (
		<AuthenticationLayout>
			<AuthenticationBox />
		</AuthenticationLayout>
	);
};

export default memo(LogIn);
