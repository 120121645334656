import { Form } from "antd";

import { Row, Col, Input, Button } from "antd";
import styles from "./styles.module.scss";

const FormItem = Form.Item;

const SearchBar = () => {
	return (
		<div className={styles.searchBar}>
			<Form>
				<Row gutter={8}>
					<Col span={19}>
						<FormItem>
							<Input placeholder="Search by name" />
						</FormItem>
					</Col>
					<Col span={5}>
						<Button htmlType="submit">Search</Button>
					</Col>
				</Row>
			</Form>
		</div>
	);
};

export default SearchBar;
