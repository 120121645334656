import { EEventsActionTypes, IEventsGetPayload } from "models";

export const setEventsLoading = (payload: boolean) => ({
	type: EEventsActionTypes.eventsLoadingSet,
	payload,
});

export const getEvents = (payload: IEventsGetPayload) => ({
	type: EEventsActionTypes.getEvents,
	payload,
});

export const setEvents = (payload: any) => ({
	type: EEventsActionTypes.setEvents,
	payload,
});
export const setCalendarDate = (payload: any) => ({
	type: EEventsActionTypes.setCalendarDate,
	payload,
});
