import { ILinkedCommunityDocuments, IUser } from "models";
import {
	IPostLoadingSetAction,
	IPostStoreAddAction,
	IPostSaveAction,
	IPostCommentsSetLoading,
	IAddLocalPostCommentsAction,
	IUpdateLocalPostCommentsAction,
	IPostInputSetAction,
	IPostSetAction,
	IPostUpdateStoreAction,
	IAppendLocalPostCommentsAction,
	IPostDeleteLocalCommentAction,
	IPostEditLocalCommentAction,
} from "./actions";

// ENUMS
export enum EPostActionTypes {
	postLoadingSet = "post/set/loading",
	postCommentsSetLoading = "post/comments/set/loading",
	postStoreAdd = "post/store/add",
	postUpdateStore = "post/store/update",
	addPost = "post/add",
	addLocalPostComments = "posts/add/localComments",
	updateLocalPostComments = "posts/update/localComments",
	appendLocalPostComments = "posts/append/localComments",
	sendCommentForPost = "post/postComment/send",
	postEditGet = "post/edit/get",
	postRawDataGet = "post/raw/data/get",
	postSet = "post/set",
	postReset = "post/reset",
	postDelete = "post/delete",
	deletePostComment = "post/delete/comment",
	deleteLocalPostComment = "post/delete/local/comment",
	editPostComment = "post/edit/comment",
	editLocalPostComment = "post/edit/local/comment",
}

// INTERFACES
export interface IPost {
	id: number;
	title: string;
	description: string;
	pin_type: number;
	is_approved: boolean;
	good: any;
	images: any;
	comment_btn_label: string;
	recipient_id: string;
	community_post_id: number;
	user_pin: boolean;
	sticky: boolean;
	post_approved: boolean;
	timestamp: string;
	member_type: string;
	video_link: string;
	user: Partial<IUser>;
	tags: string[];
	all_groups: boolean;
	groups: string[];
	group_objects: any;
	membership_id: number;
	mentions: any;
	likes_count: number;
	liked: boolean;
	linked_community_documents: ILinkedCommunityDocuments[];
	community_id?: number;
	website?: string;
	video_title?: string;
	video_uid?: string;
	is_visible: boolean;
	vimeo_uri?: string;
	vimeo_thumbnail_url?: string;
	expiration_date?: string;
}
export interface IPostState {
	loading: boolean;
	data?: {
		community_post: IPost;
		member_types?: string[];
		groups?: string[];
		tags?: string[];
	};
}

export interface IAddUpdatePost {
	community_id: number;
	community_post: {
		title: string;
		description: string;
		pin_type: number; // 0 -> community post;
		all_groups: boolean;
		video_link?: string;
		group_ids?: number[]; // intergers ids
		tag_list?: string[]; // string value for example: "Accounting"
		member_type_list?: string[]; // string value for example: "Accounting"
		expiration_date?: Date;
		image?: Object | null;
	};
	communityPostId: null | number;
	delete_images: any;
}

export interface ICommentsState {
	[key: number]: IPostComments;
}

interface IPostComments {
	loading: false;
	data: any;
	meta: {
		pagination: {
			current_page: number;
			per_page: number;
			total_pages: number;
			total_objects: number;
			has_next_page: boolean;
		};
		label: string;
	};
}

export interface IPostCommentsState {
	[key: number]: IPostComments;
}

export interface IPostLoadingPayload {
	loading: boolean;
}

// TYPES
export type TPostActions =
	| IPostLoadingSetAction
	| IPostCommentsSetLoading
	| IPostStoreAddAction
	| IPostSaveAction
	| IAddLocalPostCommentsAction
	| IUpdateLocalPostCommentsAction
	| IPostInputSetAction
	| IPostSetAction
	| IPostUpdateStoreAction
	| IAppendLocalPostCommentsAction
	| IPostDeleteLocalCommentAction
	| IPostEditLocalCommentAction;
