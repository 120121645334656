import { memo } from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";
import { ReactComponent as CircleExclamationIcon } from "assets/images/circle-exclamation-solid.svg";

const NotFoundCommunities = () => {
	return (
		<section className={styles.container}>
			<div className={styles.imageHolder}>
				<CircleExclamationIcon
					className={classNames(
						styles.positionIcon,
						styles.headerColorIcon,
					)}
				/>
			</div>
			<h1 className={styles.bigFontSizeText}>
				{"We couldn’t find any communities matching your search query"}
			</h1>
			<p className={styles.normalFontSizeText}>
				Please try again or&nbsp;
				<a
					className={styles.underlinedText}
					href={"https://start.ugenie.io/"}
					target="_blank"
					rel="noreferrer"
				>
					{"create your own"}
				</a>
			</p>
		</section>
	);
};

export default memo(NotFoundCommunities);
