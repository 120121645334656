import {
	put,
	all,
	takeEvery,
	takeLatest,
	select,
	call,
} from "redux-saga/effects";

import { EOfferActionTypes, IOffer, TStore } from "models";
import { setOffer, api } from "store";
import { getOffer, redeemOffer } from "store/actions";
import { rollbar } from "utils";

const getCommunityId = (state: TStore) => state.app.currentCommunityId;

function* getOfferHandle({ payload }: ReturnType<typeof getOffer>) {
	const {
		forceCommunityId,
		id,
		callbackOnError,
		callbackOnSuccess,
	} = payload;
	try {
		const communityId: number = yield select(getCommunityId);
		const { data } = yield call(api.offers.get, {
			communityId: forceCommunityId ? forceCommunityId : communityId,
			id: id,
		});

		if (callbackOnSuccess) {
			callbackOnSuccess();
		}
		yield put(setOffer({ data: data.offers[0] as IOffer }));
	} catch (error) {
		rollbar.error(error)

		if (callbackOnError) {
			callbackOnError();
		}
		console.error({ error });
	}
}

function* redeemOfferHandle({ payload }: ReturnType<typeof redeemOffer>) {
	try {
		const { offerId, type } = payload;
		const communityId: number = yield select(getCommunityId);
		yield call(api.offers.redeemOffer, {
			type,
			offerId,
			communityId,
		});
	} catch (error) {
		rollbar.error(error)

		console.error({ error });
	}
}
export default function* rootSaga() {
	yield all([takeEvery(EOfferActionTypes.getOffer, getOfferHandle)]);
	yield all([takeLatest(EOfferActionTypes.redeemOffer, redeemOfferHandle)]);
}
