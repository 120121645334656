import React, { useCallback, useState } from "react";
import Modal from "antd/lib/modal/Modal";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import styles from "./styles.module.scss";
import { TStore } from "models";
import {
	getMembers,
	setEditProfile,
	updateProfileAccountSettings,
} from "store";
import { Loading } from "components";
import { JoinMemberText } from "components/DirectoryMemberOptedOutPanel/joinMemberText";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.editProfile,
	(editProfileData) => ({
		editProfileData,
	}),
);

interface IMentionTag {
	memberId: number;
	children?: any;
	memberName?: string;
}

const MentionTag: React.FunctionComponent<IMentionTag> = (props) => {
	const { children, memberId } = props;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { editProfileData } = useSelector(stateSelector);

	const handleMentionClick = useCallback(
		(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			if (event) {
				event.stopPropagation();
			}
			if (editProfileData.data.user.member_directory_opt_in) {
				navigate(`/members/${memberId}`);
			} else {
				showModal();
			}
		},
		[
			editProfileData?.data?.user?.member_directory_opt_in,
			navigate,
			memberId,
		],
	);

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = useCallback(() => {
		const callbackOnSuccess = (updatedUserData: any) => {
			dispatch(setEditProfile({ user: updatedUserData.data.user }));
			dispatch(getMembers());
			navigate(`/members/${memberId}`);
		};

		setIsModalVisible(false);
		// update the profile account settings and redirect the user to members page.
		dispatch(
			updateProfileAccountSettings({
				userData: { member_directory_opt_in: true },
				callbackOnSuccess,
			}),
		);
	}, [dispatch, navigate, memberId]);

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	return (
		<>
			<div className={styles.mentionContent} onClick={handleMentionClick}>
				{children}
			</div>
			{
				//TODO: the Modal component should be created only once.
			}
			<Modal
				centered={true}
				getContainer={() =>
					document.getElementById("right-section") as any
				}
				title="Member Directory Opt-In"
				visible={
					!editProfileData.data.user.member_directory_opt_in &&
					isModalVisible
				}
				onOk={handleOk}
				onCancel={handleCancel}
				okText={"Opt-In"}
				cancelText={"Cancel"}
			>
				{editProfileData?.loading ? (
					<Loading />
				) : (
					<div className={styles.modalContent}>
					<JoinMemberText/>
					</div>
				)}
			</Modal>
		</>
	);
};

export default MentionTag;
