import React, { memo, useCallback, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";

import { ICommunitiesJoinedState, TStore } from "models";
import { getCommunitiesJoined, getMoreCommunitiesJoined } from "store";
import { Loading } from "components";
import CommunityBox from "components/CommunityBox";
import { ETypeOfCommunities } from "models";
import styles from "./styles.module.scss";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.communitiesJoined,
	(communitiesJoined: ICommunitiesJoinedState) => ({ communitiesJoined }),
);

const ActiveCommunitiesList = () => {
	const dispatch = useDispatch();
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { communitiesJoined } = useSelector(stateSelector);

	const loadMoreCommunities = useCallback(() => {
		dispatch(getMoreCommunitiesJoined());
	}, [dispatch]);

	useEffect(() => {
		dispatch(getCommunitiesJoined());
	}, [dispatch]);

	return (
		<div className={styles.container}>
			{communitiesJoined.loading ? (
				<div className={styles.lodingContainer}>
					<Loading />
				</div>
			) : (
				communitiesJoined?.data &&
				(communitiesJoined?.data?.length > 0 ? (
					<InfiniteScroll
						style={{ width: "100%" ,display:'flex',flexDirection:'column',gap:'10px'}}
						pageStart={1}
						loadMore={loadMoreCommunities}
						initialLoad={true}
						hasMore={
							communitiesJoined.meta?.pagination.has_next_page
						}
						loader={
							<div
								key={0}
								style={{
									padding: "12px 8px",
								}}
							>
								<Loading />
							</div>
						}
						useWindow={false}
						getScrollParent={() =>
							document.getElementsByTagName("main")[0]
						}
					>
						{communitiesJoined.data.map((item) => (
							<CommunityBox
								key={"community-" + item.id}
								data={item}
								sourceOfCommunitiesDataList={
									ETypeOfCommunities.Joined
								}
							/>
						))}
					</InfiniteScroll>
				) : (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignSelf: "center",
							color: "#cdcdcd",
							fontStyle: "italic",
							flex: 1,
						}}
					>
						{"No communities have been found!"}
					</div>
				))
			)}
		</div>
	);
};

export default memo(ActiveCommunitiesList);
