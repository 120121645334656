import {
	IMessagesState,
	EMessagesActionTypes,
	TMessagesActions,
	IMessageThread,
} from "models";

const initial: IMessagesState = {
	loading: false,
	orderedThreads: {},
	threads: null,
	pinnedConversation: null,
	selectedThread: null,
	conversation: {
		loading: false,
		errorMessage: "",
	},
};

export function messagesReducer(
	state = initial,
	action: TMessagesActions,
): IMessagesState {
	switch (action.type) {
		case EMessagesActionTypes.messagesLoadingSet: {
			return {
				...state,
				loading: action.payload,
			};
		}
		case EMessagesActionTypes.setSelectdThread: {
			return {
				...state,
				selectedThread: action.payload,
			};
		}
		case EMessagesActionTypes.updateConversation: {
			const { data, meta } = action.payload;
			return {
				...state,
				conversation: {
					...state.conversation,
					data,
					meta: meta // for some reason the current_page is coming as string from server and I need to store as a number
						? {
								...meta,
								pagination: {
									...meta.pagination,
									current_page: parseInt(
										meta.pagination.current_page + "",
										10,
									) as number,
								},
						  }
						: undefined,
				},
			};
		}
		case EMessagesActionTypes.addMessageToThread: {
			const { message } = action.payload;

			let currentConversation = {
				...state.conversation,
				data: state.conversation?.data
					? { ...state.conversation.data }
					: undefined,
				meta: state?.conversation?.meta
					? { ...state?.conversation?.meta }
					: undefined,
			};

			if (currentConversation?.data) {
				currentConversation.data[message.id] = message;
			} else {
				currentConversation = {
					...state.conversation,
					// @ts-ignore
					data: { [message.id]: message },
					meta: undefined,
				};
			}

			return {
				...state,
				conversation: {
					...currentConversation,
				},
			};
		}
		case EMessagesActionTypes.createPinnedConversation: {
			return {
				...state,
				pinnedConversation: action.payload,
			};
		}
		case EMessagesActionTypes.updateThreadsSilently: {
			let threads: any = state.threads
				? {
						...state.threads[action.payload.communityId]?.posts,
				  }
				: {};

			for (let id in action.payload.updates) {
				threads[id] = {
					...threads[id],
					...action.payload.updates[id],
				};
			}

			return {
				...state,
				orderedThreads: {
					...state.orderedThreads,
					[action.payload.communityId]: action.payload.orderedThreads,
				},
				threads: {
					...state.threads,
					[action.payload.communityId]: state.threads
						? {
								...state.threads[action.payload.communityId],
								posts: threads,
						  }
						: {
								posts: threads,
								meta: {},
						  },
				},
			};
		}

		case EMessagesActionTypes.updateMessagesThreads: {
			let threads: any = state.threads
				? {
						...state.threads[action.payload.communityId]?.posts,
				  }
				: {};
			action.payload.data.posts.map((item: IMessageThread) => {
				threads[item.id] = item;

				return undefined;
			});
			return {
				...state,
				loading: false,
				orderedThreads: {
					...state.orderedThreads,
					[action.payload.communityId]: state.orderedThreads[
						action.payload.communityId
					]
						? Array.from(
								new Set([
									...state.orderedThreads[
										action.payload.communityId
									],
									...action.payload.data.posts.map(
										(item: IMessageThread) => item.id,
									),
								]),
						  )
						: action.payload.data.posts.map(
								(item: IMessageThread) => item.id,
						  ),
				},
				threads: {
					...state.threads,
					[action.payload.communityId]: {
						meta: action.payload.data.meta,
						posts: threads,
					},
				},
			};
		}
		case EMessagesActionTypes.setConversationErrorMessage: {
			const { error } = action.payload;
			return {
				...state,
				conversation: {
					...state.conversation,
					errorMessage: error,
				},
			};
		}
		default:
			return state;
	}
}
