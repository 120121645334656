import {
    call,
    put,
    all,
    takeEvery,
    select,
    takeLatest,
} from "redux-saga/effects";

import { EMarketplaceOfferActionTypes, TStore } from "models";
import {
    api,
    setMarketplaceOfferLoading,
    setMarketplaceOfferEditData,
    notify,
    getMarketplaceOfferRawData,
    setMarketplaceOfferRawData,
    selectMarketplaceOffer,
    addMarketplaceOffer,
    updateMarketplaceOffer,
    setMarketplaceOfferInStore,
} from "store";
import { rollbar } from "utils";

const getCommunityId = (state: TStore) => state.app.currentCommunityId;
const getAppState = (state: TStore) => state.app;

function* addMarketplaceOfferHandle({
    payload,
}: ReturnType<typeof addMarketplaceOffer>) {
    const {
        title,
        description,
        currency,
        goodType,
        minPrice,
        maxPrice,
        audienceCheckboxGroup,
        groupsCheckboxGroup,
        tagsCheckboxGroup,
        postcode,
        website,
        postcodeLat,
        postcodeLng,
        expiryDate,
        postImages,
        all_groups,
    } = payload.data;
    const { successCallback, erroCallback } = payload;

    yield put(setMarketplaceOfferLoading(true));
    const app = yield select(getAppState);
    const communityId = app.currentCommunityId;

    try {
        const { data } = yield call(api.marketplaceOffer.add, {
            community_id: communityId,
            title,
            description,
            website,
            expiration_date: expiryDate,
            community_post: {
                pin_type: 1, // for goods post
                good_attributes: {
                    max_price: maxPrice,
                    how_much: minPrice,
                    good_type: goodType, // Sell, Buy, Share
                    postcode: postcode,
                    latitude: postcodeLat,
                    longitude: postcodeLng,
                    currency, // TODO I need 2 currencyies 1 for min and 1 for max
                },
                member_type_list: audienceCheckboxGroup,
                group_ids: groupsCheckboxGroup,
                tag_list: tagsCheckboxGroup,
                all_groups,
            },
        });

        if (!data) {
            yield call(
                notify,
                "error",
                "Error!",
                "The post has not been saved!",
            );
            yield put(setMarketplaceOfferLoading(false));
        } else {

            if (data.meta.needs_approval) {
                yield call(
                    notify,
                    "success",
                    "Success!",
                    data.meta.message,
                );
            }
            else {
                yield call(
                    notify,
                    "success",
                    "Success!",
                    "The post has been added successfully!",
                );

            }
            // check for images.
            if (postImages && postImages.length > 0) {
                // add the image
                const postId = data.community_post.id;
                yield call(api.marketplaceOffer.addImages, {
                    community_id: communityId,
                    community_post_id: postId,
                    images: postImages,
                });
            }

            const { data: newAddedMarketplaceOfferData } = yield call(
                api.marketplaceOffer.getMarketplaceOfferRawData,
                {
                    communityId: communityId,
                    communityPostId: data.community_post.id,
                },
            );

            yield put(
                setMarketplaceOfferInStore({
                    data: {
                        ...newAddedMarketplaceOfferData.community_post,
                    },
                    newMarketplaceOffer: true,
                }),
            );
            if (successCallback) {
                successCallback();
            }
            yield put(setMarketplaceOfferLoading(false));

        }
    } catch (error) {
        rollbar.error(error)
        if (erroCallback) {
            erroCallback();
        }
        console.error({ error });
        yield put(setMarketplaceOfferLoading(false));
    }
}

function* updateMarketplaceOfferHandle({
    payload,
}: ReturnType<typeof updateMarketplaceOffer>) {
    const {
        id,
        title,
        description,
        currency,
        goodType,
        minPrice,
        maxPrice,
        audienceCheckboxGroup,
        groupsCheckboxGroup,
        tagsCheckboxGroup,
        postcode,
        website,
        postcodeLat,
        postcodeLng,
        expiryDate,
        //groupsCheckAll,
        postImages,
        imagesToBeRemoved,
        all_groups,
    } = payload.data;
    const { successCallback, errorCallback } = payload;

    const app = yield select(getAppState);
    const communityId = app.currentCommunityId;

    try {
        const { data } = yield call(api.marketplaceOffer.update, {
            post_id: id,
            community_id: communityId,
            title,
            description,
            website,
            expiration_date: expiryDate,
            community_post: {
                pin_type: 1, // for goods post
                good_attributes: {
                    max_price: maxPrice,
                    how_much: minPrice,
                    good_type: goodType, // Sell, Buy, Share
                    postcode: postcode,
                    latitude: postcodeLat,
                    longitude: postcodeLng,
                    currency, // TODO I need 2 currencyies 1 for min and 1 for max
                },
                member_type_list: audienceCheckboxGroup,
                group_ids: groupsCheckboxGroup,
                tag_list: tagsCheckboxGroup,
                all_groups,
            },
            delete_images: imagesToBeRemoved,
        });

        if (!data) {
            yield call(
                notify,
                "error",
                "Error!",
                "The post has not been saved!",
            );
        } else {
            if (postImages && postImages.length > 0) {
                // add the image
                const postId = data.community_post.id;
                yield call(api.marketplaceOffer.addImages, {
                    community_id: communityId,
                    community_post_id: postId,
                    images: postImages,
                });
            }

            const { data: updatedMarketplaceOfferData } = yield call(
                api.marketplaceOffer.getMarketplaceOfferRawData,
                {
                    communityId: communityId,
                    communityPostId: data.community_post.id,
                },
            );

            yield put(
                setMarketplaceOfferInStore({
                    data: {
                        ...updatedMarketplaceOfferData.community_post,
                    },
                    newMarketplaceOffer: false,
                }),
            );
            if (successCallback) {
                successCallback();
            }
            yield call(
                notify,
                "success",
                "Success!",
                "The post has been updated successfully!",
            );
        }
    } catch (error) {
        rollbar.error(error)
        if (errorCallback) {
            errorCallback();
        }
        console.error({ error });
    }
}

function* selectMarketplaceOfferHandle({
    payload,
}: ReturnType<typeof selectMarketplaceOffer>) {
    const { marketplaceOfferId, successCallback, erroCallback } = payload;
    try {
        const communityId: number = yield select(getCommunityId);

        const { data } = yield call(
            api.marketplaceOffer.getMarketplaceOfferForEdit,
            {
                community_id: communityId,
                community_post_id: marketplaceOfferId,
            },
        );

        yield put(setMarketplaceOfferEditData({ data }));
        if (successCallback) {
            successCallback();
        }
    } catch (error) {
        rollbar.error(error)
        if (erroCallback) {
            erroCallback();
        }

        console.error(error);
    }
}

function* getMarketplaceOfferRawDataHandle({
    payload,
}: ReturnType<typeof getMarketplaceOfferRawData>) {
    const { callbackOnError, callbackOnSuccess, id, forceCommunityId } =
        payload;
    try {
        const communityId: number = yield select(getCommunityId);
        if (!communityId) throw new Error("Missing parameters!");
        const { data } = yield call(
            api.marketplaceOffer.getMarketplaceOfferRawData,
            {
                communityId: forceCommunityId ? forceCommunityId : communityId,
                communityPostId: id,
            },
        );

        yield put(setMarketplaceOfferRawData({ data: data.community_post }));

        callbackOnSuccess();
    } catch (error) {
        rollbar.error(error)
        if (callbackOnError) {
            callbackOnError();
        }
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(
            EMarketplaceOfferActionTypes.addMarketplaceOffer,
            addMarketplaceOfferHandle,
        ),
        takeEvery(
            EMarketplaceOfferActionTypes.updateMarketplaceOffer,
            updateMarketplaceOfferHandle,
        ),
        takeEvery(
            EMarketplaceOfferActionTypes.selectMarketplaceOffer,
            selectMarketplaceOfferHandle,
        ),
        takeLatest(
            EMarketplaceOfferActionTypes.getMarketplaceOfferRawData,
            getMarketplaceOfferRawDataHandle,
        ),
    ]);
}
