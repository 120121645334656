import React, { memo, ReactElement, useCallback, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import PrivateRoute from "./private";
import GuestRoute from "./guest";
import BoardingRoute from "./boarding";
import UhubsRoute from "./uHubs";
import WhiteLabelRoute from "./whiteLabel";
import { TStore } from "models";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

const Dashboard = React.lazy(() => import("screens/dashboard"));
const LogIn = React.lazy(() => import("screens/login"));
const LogOut = React.lazy(() => import("screens/logout"));
const SignUp = React.lazy(() => import("screens/signup"));
const ForgotPassword = React.lazy(() => import("screens/forgotPassword"));
const ResetPassword = React.lazy(() => import("screens/resetPassword"));
const Profile = React.lazy(() => import("screens/profile"));
const SubscriptionManagement = React.lazy(
	() => import("screens/subscriptionManagement"),
);
const ProfileAccountSettings = React.lazy(
	() => import("screens/profileAccountSettings"),
);
const ProfileManageNotifications = React.lazy(
	() => import("screens/profileManageNotifications"),
);
const Media = React.lazy(() => import("screens/media"));
const Video = React.lazy(() => import("screens/video"));
const PendingJoinWhiteLabelCommunity = React.lazy(
	() => import("screens/pendingJoinWhiteLabelCommunity"),
);
const InitializeJoinWhiteLabelCommunity = React.lazy(
	() => import("screens/initializeJoinWhiteLabelCommunity"),
);
const MediaItem = React.lazy(() => import("components/MediaItem"));
const Communities = React.lazy(() => import("screens/communities"));
const JoinCommunity = React.lazy(
	() => import("screens/communities/components/joinCommunity"),
);
const GroupsList = React.lazy(() => import("screens/groups/groupsList"));
const Members = React.lazy(() => import("screens/members"));
const MembersDetails = React.lazy(() => import("screens/membersDetail"));
const Marketplace = React.lazy(() => import("screens/marketplace"));
const MarketplaceView = React.lazy(() => import("screens/marketplaceView"));
const CalendarEvent = React.lazy(() => import("screens/calendarEvent"));
const CalendarEventList = React.lazy(() => import("screens/calendarEventList"));
const Calendar = React.lazy(() => import("screens/calendar"));
const TermsAndConditions = React.lazy(
	() => import("screens/termsAndConditions"),
);
const Messages = React.lazy(() => import("screens/messages"));
const Offers = React.lazy(() => import("screens/offers"));
const Invites = React.lazy(() => import("screens/invites"));
const AuthToken = React.lazy(() => import("screens/authToken"));
const NotificationDetails = React.lazy(
	() => import("screens/notificationDetails"),
);
const LiveStream = React.lazy(() => import("screens/liveStream"));
const ChangeSubscription = React.lazy(
	() => import("screens/changeSubscription"),
);
const ProcessingChangingPlansWithSetupIntentPayment = React.lazy(
	() => import("screens/processingChangingPlansWithSetupIntentPayment"),
);

const LegalAgreements = React.lazy(
	() => import("../components/legalAgreements"),
);

interface route {
	path: string;
	component: React.ReactElement;
	boarding?: boolean;
	private?: boolean;
	guest?: boolean;
	uHubsSpecificPatch?: boolean;
	whiteLabelSpecificPatch?: boolean;
}

const routes: route[] = [
	{
		guest: true,
		path: "/auth/token/:token",
		component: <AuthToken />,
	},
	{
		guest: true,
		path: "/terms-and-conditions",
		component: <LegalAgreements />,
	},
	{
		guest: true,
		path: "/privacy-policy",
		component: <LegalAgreements />,
	},
	{
		guest: true,
		path: "/cookie-policy",
		component: <LegalAgreements />,
	},
	{
		guest: true,
		path: "/invites/:token",
		component: <Invites key={"invitesByToken"} />,
	},
	{
		guest: true,
		path: "/hubs/:token/sign-up",
		component: <Invites key={"invitesAndSignUpByToken"} />,
	},
	{
		guest: true,
		path: "/auth/sign-in",
		component: <LogIn />,
	},
	{
		guest: true,
		path: "/auth/sign-up",
		component: <SignUp />,
	},
	{
		guest: true,
		path: "/auth/forgot-password",
		component: <ForgotPassword />,
	},
	{
		guest: true,
		path: "/reset-password",
		component: <ResetPassword />,
	},
	{
		private: true,
		path: "/profile",
		component: <Profile />,
	},
	{
		private: true,
		path: "/profile/account-settings",
		component: <ProfileAccountSettings />,
	},
	{
		private: true,
		path: "/profile/manage-notifications",
		component: <ProfileManageNotifications />,
	},
	{
		private: true,
		path: "/communities",
		component: <Communities key={"joinedCommunities"} />,
		uHubsSpecificPatch: true,
	},
	{
		private: true,
		path: "/communities/all",
		component: <Communities key={"allCommunities"} />,
		uHubsSpecificPatch: true,
	},
	{
		private: true,
		path: "/communities/invites",
		component: <Communities key={"invites"} />,
	},
	{
		// guest: true,
		private: true,
		path: "/communities/:communityId/:onboardingStage",
		uHubsSpecificPatch: true,
		component: <JoinCommunity />,
	},
	{
		private: true,
		path: "/groups",
		component: <GroupsList />,
	},
	{
		private: true,
		path: "/dashboard",
		component: <Dashboard />,
	},
	{
		private: true,
		path: "/members/:memberId",
		component: <MembersDetails key={"allMembers"} />, // the /members/:memberId and /members paths have the same key in order to prevent a completely rerender when the URL is updated from /members/memberId to /members and vice-versa
	},
	{
		private: true,
		path: "/members",
		component: <Members key={"allMembers"} />,
	},

	{
		private: true,
		path: "/offers",
		component: <Offers />,
	},
	{
		private: true,
		path: "/marketplace",
		component: <Marketplace key={"allMarketplaces"} />,
	},
	{
		private: true,
		path: "/marketplace/:section", // :section can be 'add' or 'edit' (I kept 'section' to assure the backward compatibility when I upgraded from react-router-dom v5 to v6)
		component: <Marketplace key={"addMarketplacePost"} />,
	},
	{
		private: true,
		path: "/marketplace/:section/:postId",
		component: <Marketplace key={"editMarketplacePost"} />,
	},
	{
		private: true,
		path: "/marketplace/view/:marketplaceId",
		component: <MarketplaceView />,
	},
	{
		private: true,
		path: "/calendar/events/:id",
		component: <CalendarEvent />,
	},
	{
		private: true,
		path: "/calendar",
		component: <Calendar />,
	},
	{
		private: true,
		path: "/calendar/eventList",
		component: <CalendarEventList />,
	},
	{
		private: true,
		path: "/terms-and-conditions",
		component: <TermsAndConditions />,
	},
	{
		private: true,
		path: "/community/terms-and-conditions",
		component: <TermsAndConditions key={"termsAndConditions"} />,
	},
	{
		private: true,
		path: "/community/about-us",
		component: <TermsAndConditions key={"aboutUs"} />,
	},
	{
		private: true,
		path: "/community/contact-details",
		component: <TermsAndConditions key={"contactDetails"} />,
	},
	{
		private: true,
		path: "/resources",
		component: <Media type="resources" key={"resources"} />,
	},
	{
		private: true,
		path: "/videos",
		component: <Media type="videos" key={"videos"} />,
	},
	{
		private: true,
		path: "/documents",
		component: <Media type="documents" key={"documents"} />,
	},
	{
		private: true,
		path: "/messages",
		component: <Messages />,
	},
	{
		private: true,
		path: "/videos/:id",
		component: <Video />,
	},
	{
		private: true,
		path: "/resources/:id",
		component: <MediaItem type="resources" key={"resources"} />,
	},
	{
		private: true,
		path: "/documents/:id",
		component: <MediaItem type="documents" key={"documents"} />,
	},
	{
		private: true,
		path: "/notification/details",
		component: <NotificationDetails />,
	},
	{
		private: true,
		path: "/live-stream",
		component: <LiveStream />,
	},
	{
		private: true,
		path: "/subscription-management",
		component: <SubscriptionManagement />,
	},
	{
		private: true,
		path: "/subscription-management/change-plan",
		component: <ChangeSubscription />,
	},
	{
		private: true,
		path: "/processing-setup-intent-payment",
		component: <ProcessingChangingPlansWithSetupIntentPayment />,
	},
	{
		private: true,
		whiteLabelSpecificPatch: true,
		path: "/joining/:communityId/:onboardingStage",
		component: <JoinCommunity />,
	},
	{
		private: true,
		whiteLabelSpecificPatch: true,
		path: "/joining/:id/pending-status",
		component: <PendingJoinWhiteLabelCommunity />,
	},
	{
		private: true,
		whiteLabelSpecificPatch: true,
		path: "/joining/init",
		component: <InitializeJoinWhiteLabelCommunity />,
	},
];

const stateSelectorHandle = createSelector(
	(state: TStore) => state.app.currentGroupId,
	(currentGroupId) => ({
		currentGroupId,
	}),
);

const MainRoutes = () => {
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { currentGroupId } = useSelector(stateSelector);
	const location = useLocation();
	const container = document.getElementById("content");

	useEffect(() => {
		if (container?.style !== undefined) {
			if (
				location.pathname.includes("members") ||
				location.pathname.includes("calendar")
			) {
				container.style.maxWidth = "100%";
				// container.style.overflowX = 'hidden'
			} else {
				container.style.maxWidth = "1024px";
			}
		}
	}, [location.pathname, container?.style]);

	return (
		<Routes>
			{routes
				.filter((item) => {
					if (currentGroupId) {
						if (item.path !== "/groups") {
							return true;
						} else {
							return false;
						}
					}

					return true;
				})
				.map((item) => {
					if (item.guest) {
						return (
							<Route
								key={item.path}
								path={item.path}
								element={
									<GuestRoute>{item.component}</GuestRoute>
								}
							/>
						);
					} else if (item.private) {
						let privateElement: ReactElement<any, any> | null =
							null;
						if (item.uHubsSpecificPatch) {
							privateElement = (
								<UhubsRoute>{item.component}</UhubsRoute>
							);
						} else if (item.whiteLabelSpecificPatch) {
							privateElement = (
								<WhiteLabelRoute>
									{item.component}
								</WhiteLabelRoute>
							);
						} else {
							privateElement = (
								<PrivateRoute>{item.component}</PrivateRoute>
							);
						}
						return (
							<Route
								key={item.path}
								path={item.path}
								element={privateElement}
							/>
						);
					} else if (item.boarding) {
						return (
							<Route
								key={item.path}
								path={item.path}
								element={
									<BoardingRoute>
										{item.component}
									</BoardingRoute>
								}
							/>
						);
					}

					return undefined;
				})}
			<Route
				path={"/auth/sign-out"}
				element={
					<PrivateRoute>
						<LogOut />
					</PrivateRoute>
				}
			/>
			<Route path="*" element={<Navigate to="/auth/sign-in" replace />} />
		</Routes>
	);
};

export default memo(MainRoutes);
