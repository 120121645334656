import { Row, Col } from "antd";

import styles from "./styles.module.scss";

const CookiePolicy = ({
	data,
	communityName,
}: {
	data?: any;
	communityName?: string;
}) => {
	return (
		<div className={styles.content}>
			<div className={styles.title}>
				{communityName ? communityName : "UGENIE"}
			</div>
			<div className={styles.subTitle}>PLATFORM COOKIE POLICY</div>
			{data ? (
				<Row className={styles.row}>
					<Col span={24} className={styles.normal}>
						<div dangerouslySetInnerHTML={{ __html: data }} />
					</Col>
				</Row>
			) : (
				<>
					<Row className={styles.row}>
						<Col span={1} className={styles.bold}>
							1
						</Col>
						<Col span={23} className={styles.bold}>
							Introduction
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							1.1
						</Col>
						<Col span={22} className={styles.normal}>
							These terms and conditions shall govern your use of
							our website,{" "}
							<a
								href="https://www.ugenie.io"
								target="_blank"
								rel="noreferrer"
							>
								www.ugenie.io
							</a>
							, and any associated websites, its mobile app and
							the services provided through it (together, the{" "}
							<strong>“Platform”</strong>
							).
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							1.2
						</Col>
						<Col span={22} className={styles.normal}>
							The term <strong>“we”</strong> means Ugenie Ltd, the
							owner and operator of the Platform, whose registered
							office is at 1 Langford Close, London, Ealing
							(London Borough), United Kingdom, W3 8JZ and whose
							company registration number is 09966790 (
							<strong>“us”</strong> and <strong>“our”</strong>
							will be construed accordingly).
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							1.3
						</Col>
						<Col span={22} className={styles.normal}>
							The term <strong>“you”</strong> refers to the user
							or viewer of our Platform and includes any member of
							a community that is created through the Ugenie app
							(a <strong>“Community”</strong>
							).
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							1.4
						</Col>
						<Col span={22} className={styles.normal}>
							By using continuing to browse our Platform, you
							agree to accept these terms and conditions. If you
							disagree with any of these terms and conditions, you
							must not use the Platform.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							1.5
						</Col>
						<Col span={22} className={styles.normal}>
							Our Platform uses cookies. By using our Platform,
							you consent to our use of cookies in accordance with
							the terms of our privacy and cookies policy at:{" "}
							<a
								href="https://www.ugenie.io/privacy-policy"
								target="_blank"
								rel="noreferrer"
							>
								https://www.ugenie.io/privacy-policy
							</a>
							.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							1.6
						</Col>
						<Col span={22} className={styles.normal}>
							You must be at least 18 years of age to use our
							Platform. The Platform is not targeted toward,
							marketed to, or intended for use by anyone under the
							age of 18. We encourage parents to supervise their
							children’s digital activities and to consider using
							parental control tools available from online
							services and software manufacturers that help
							provide a child-friendly online environment. These
							tools can also keep children from disclosing their
							name, address and other personal information without
							parental consent online.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={1} className={styles.bold}>
							2
						</Col>
						<Col span={23} className={styles.bold}>
							Copyright
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							2.1
						</Col>
						<Col span={22} className={styles.normal}>
							This Platform contains content which is owned by or
							licensed to us (the{" "}
							<strong>“Ugenie Content”</strong>). This Content
							includes, but is not limited to, the information,
							design, layout, look, appearance and graphics.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							2.2
						</Col>
						<Col span={22} className={styles.normal}>
							You are granted a licence to use the Ugenie Content
							subject to the restrictions described in these terms
							and conditions.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							2.3
						</Col>
						<Col span={22} className={styles.normal}>
							All Ugenie Content contained in this Platform is and
							shall remain at all times the copyright of Ugenie
							Ltd.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							2.4
						</Col>
						<Col span={22} className={styles.normal}>
							You must retain, and must not delete or remove all
							copyright notices and other proprietary notices
							placed by us on any Ugenie Content.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={1} className={styles.bold}>
							3
						</Col>
						<Col span={23} className={styles.bold}>
							Licence to use the Platform
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							3.1
						</Col>
						<Col span={22} className={styles.normal}>
							You may:
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(a)
						</Col>
						<Col span={22} className={styles.normal}>
							view pages from our Platform in a web browser or
							mobile app;
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(b)
						</Col>
						<Col span={22} className={styles.normal}>
							download pages from our Platform for caching in a
							web browser or mobile app;
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(c)
						</Col>
						<Col span={22} className={styles.normal}>
							print pages from our Platform;
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}></Col>
						<Col span={22} className={styles.normal}>
							subject to the other provisions of these terms and
							conditions.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							3.2
						</Col>
						<Col span={22} className={styles.normal}>
							Except as expressly permitted by Section 3.1 or the
							other provisions of these terms and conditions, you
							must not download any Ugenie Content from our
							Platform or save Ugenie Content to your computer.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							3.3
						</Col>
						<Col span={22} className={styles.normal}>
							Except as expressly permitted by these terms and
							conditions, you must not edit or otherwise modify
							any Ugenie Content on our Platform.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							3.5
						</Col>
						<Col span={22} className={styles.normal}>
							Unless you own or control the relevant rights in the
							material, you must not:
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(a)
						</Col>
						<Col span={22} className={styles.normal}>
							republish material from our Platform (including
							republication on another Platform), except in the
							case of social media such as Facebook and Twitter in
							which case you are permitted to publish extracts in
							order to promote use of the Platform;
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(b)
						</Col>
						<Col span={22} className={styles.normal}>
							sell, rent or sub-license material from our
							Platform;
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(c)
						</Col>
						<Col span={22} className={styles.normal}>
							show any material from our Platform in public;
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(d)
						</Col>
						<Col span={22} className={styles.normal}>
							exploit material from our Platform for a commercial
							purpose (other than as a registered seller in
							accordance with these terms and conditions); or
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(e)
						</Col>
						<Col span={22} className={styles.normal}>
							redistribute material from our Platform.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							3.6
						</Col>
						<Col span={22} className={styles.normal}>
							We reserve the right to restrict access to areas of
							our Platform, or indeed our whole Platform, at our
							discretion. You must not circumvent or bypass, or
							attempt to circumvent or bypass, any access
							restriction measures on our Platform.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.bold}>
							4
						</Col>
						<Col span={22} className={styles.bold}>
							Acceptable use
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							4.1
						</Col>
						<Col span={22} className={styles.normal}>
							You must not:
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(a)
						</Col>
						<Col span={22} className={styles.normal}>
							use our Platform in any way or take any action that
							causes, or may cause, damage to the Platform or
							impairment of the performance, availability or
							accessibility of the Platform;
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(b)
						</Col>
						<Col span={22} className={styles.normal}>
							use our Platform in any way that is unlawful,
							illegal, fraudulent or harmful, or in connection
							with any unlawful, illegal, fraudulent or harmful
							purpose or activity;
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(c)
						</Col>
						<Col span={22} className={styles.normal}>
							use our Platform to copy, store, host, transmit,
							send, use, publish or distribute any material which
							consists of (or is linked to) any spyware, computer
							virus, Trojan horse, worm, keystroke logger, rootkit
							or other malicious computer software; or
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(d)
						</Col>
						<Col span={22} className={styles.normal}>
							conduct any systematic or automated data collection
							activities (including without limitation scraping,
							data mining, data extraction and data harvesting) on
							or in relation to our Platform without our express
							written consent.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							4.2
						</Col>
						<Col span={22} className={styles.normal}>
							You must ensure that all the information you supply
							to us through our Platform, or in relation to our
							Platform, is true, accurate, current and
							non-misleading.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.bold}>
							5
						</Col>
						<Col span={22} className={styles.bold}>
							Use on behalf of an organisation
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							5.1
						</Col>
						<Col span={22} className={styles.normal}>
							If you use our Platform in the course of a business
							or other organisation, then by so doing you bind
							both yourself and the company or other legal entity
							that operates that business to these terms and
							conditions, and in these circumstances references to
							"you" in these terms and conditions are to both the
							individual user and the relevant company or legal
							entity.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.bold}>
							6
						</Col>
						<Col span={22} className={styles.bold}>
							Your content licence
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							6.1
						</Col>
						<Col span={22} className={styles.normal}>
							In these terms and conditions,{" "}
							<strong>"your personal content"</strong> means all
							works and materials (including without limitation
							text, graphics, images, audio material, video
							material, audio-visual material, scripts, software
							and files) that you submit to us or our Platform for
							storage or publication on, processing by, or
							transmission via, our Platform for the purposes of
							your Community.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							6.2
						</Col>
						<Col span={22} className={styles.normal}>
							You grant to us a worldwide, irrevocable,
							non-exclusive, royalty-free licence to process,
							store, publish, and distribute your personal content
							solely for the purposes of and within the rules of
							your Community and subject to the terms of our{" "}
							<a
								href="https://www.ugenie.io/privacy-policy"
								target="_blank"
								rel="noreferrer"
							>
								Privacy Policy
							</a>{" "}
							.{/* TODO: fix this link functionality */}
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							6.3
						</Col>
						<Col span={22} className={styles.normal}>
							You may edit your personal content to the extent
							permitted using the editing functionality made
							available on our Platform.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							6.4
						</Col>
						<Col span={22} className={styles.normal}>
							In these terms and conditions,{" "}
							<strong>"your public content"</strong> means all
							works and materials (including without limitation
							text, graphics, images, audio material, video
							material, audio-visual material, scripts, software
							and files) that you submit to us or our Platform for
							storage or publication on, processing by, or
							transmission via, our Platform, which is not part of
							your personal content.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							6.5
						</Col>
						<Col span={22} className={styles.normal}>
							You grant to us a worldwide, irrevocable,
							non-exclusive, royalty-free licence to use,
							reproduce, store, adapt, publish, translate and
							distribute your public content in any existing or
							future media and to reproduce, store and publish
							your public content on and in relation to this
							Platform and any successor website.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							6.6
						</Col>
						<Col span={22} className={styles.normal}>
							You grant to us the right to sub-license the rights
							licensed under Section 6.5.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							6.7
						</Col>
						<Col span={22} className={styles.normal}>
							You grant to us the right to bring an action for
							infringement of the rights licensed under Section
							6.5.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							6.8
						</Col>
						<Col span={22} className={styles.normal}>
							You hereby waive all your moral rights in your
							public content to the maximum extent permitted by
							applicable law; and you warrant and represent that
							all other moral rights in your content have been
							waived to the maximum extent permitted by applicable
							law.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							6.9
						</Col>
						<Col span={22} className={styles.normal}>
							You may edit your public content to the extent
							permitted using the editing functionality made
							available on our Platform.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							6.10
						</Col>
						<Col span={22} className={styles.normal}>
							Without prejudice to our other rights under these
							terms and conditions, if you breach any provision of
							these terms and conditions in any way, or if we
							reasonably suspect that you have breached these
							terms and conditions in any way, we may delete,
							unpublish or edit any or all of your public content.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.bold}>
							7
						</Col>
						<Col span={22} className={styles.bold}>
							Your content rules
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							7.1
						</Col>
						<Col span={22} className={styles.normal}>
							You warrant and represent that your public and
							personal content will comply with these terms and
							conditions.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							7.2
						</Col>
						<Col span={22} className={styles.normal}>
							Your content must not be illegal or unlawful, must
							not infringe any person's legal rights, and must not
							be capable of giving rise to legal action against
							any person (in each case in any jurisdiction and
							under any applicable law).
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							7.3
						</Col>
						<Col span={22} className={styles.normal}>
							Your content, and the use of your content by us in
							accordance with these terms and conditions, must
							not:
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(a)
						</Col>
						<Col span={22} className={styles.normal}>
							be libellous or maliciously false;
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(b)
						</Col>
						<Col span={22} className={styles.normal}>
							be obscene or indecent;
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(c)
						</Col>
						<Col span={22} className={styles.normal}>
							infringe any copyright, moral right, database right,
							trade mark right, design right, right in passing
							off, or other intellectual property right;
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(d)
						</Col>
						<Col span={22} className={styles.normal}>
							infringe any right of confidence, right of privacy
							or right under data protection legislation;
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(e)
						</Col>
						<Col span={22} className={styles.normal}>
							constitute negligent advice or contain any negligent
							statement;
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(f)
						</Col>
						<Col span={22} className={styles.normal}>
							constitute or promote any criminal activity;
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(g)
						</Col>
						<Col span={22} className={styles.normal}>
							be in contempt of any court, or in breach of any
							court order;
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(h)
						</Col>
						<Col span={22} className={styles.normal}>
							be in breach of racial or religious hatred or
							discrimination legislation;
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(i)
						</Col>
						<Col span={22} className={styles.normal}>
							be blasphemous;
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(j)
						</Col>
						<Col span={22} className={styles.normal}>
							be in breach of any contractual obligation owed to
							any person;
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(k)
						</Col>
						<Col span={22} className={styles.normal}>
							be untrue, false, inaccurate or misleading;
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(l)
						</Col>
						<Col span={22} className={styles.normal}>
							contain any instructions, advice or other
							information which may be acted upon and could, if
							acted upon, cause illness, injury or death, or any
							other loss or damage;
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(m)
						</Col>
						<Col span={22} className={styles.normal}>
							constitute spam; or
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(n)
						</Col>
						<Col span={22} className={styles.normal}>
							be offensive, deceptive, fraudulent, threatening,
							abusive, harassing, anti-social, menacing, hateful,
							discriminatory or inflammatory.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							7.4
						</Col>
						<Col span={22} className={styles.normal}>
							The Community you join may allow you to send
							messages to other users of the Platform. You agree
							that Ugenie may terminate your permission to send
							messages at any time and for any reason, without
							notice or liability to you. If you receive an
							objectionable message from another user, please
							notify your Community manager, and/or send us an
							e-mail to{" "}
							<a
								href="mailto:info@ugenie.io"
								target="_blank"
								rel="noreferrer"
							>
								info@ugenie.io
							</a>{" "}
							You agree that Ugenie may monitor Community messages
							for compliance with these Terms.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.bold}>
							8
						</Col>
						<Col span={22} className={styles.bold}>
							Breaches of these terms and conditions
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							8.1
						</Col>
						<Col span={22} className={styles.normal}>
							Without prejudice to our other rights under these
							terms and conditions, if you breach these terms and
							conditions in any way, or if we reasonably suspect
							that you have breached these terms and conditions in
							any way, we may:
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(a)
						</Col>
						<Col span={22} className={styles.normal}>
							send you one or more formal warnings;
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(b)
						</Col>
						<Col span={22} className={styles.normal}>
							temporarily suspend your access to our Platform;
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(c)
						</Col>
						<Col span={22} className={styles.normal}>
							permanently prohibit you from accessing our
							Platform;
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(d)
						</Col>
						<Col span={22} className={styles.normal}>
							block computers using your IP address from accessing
							our Platform;
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(e)
						</Col>
						<Col span={22} className={styles.normal}>
							contact any or all of your internet service
							providers and request that they block your access to
							our Platform;
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(f)
						</Col>
						<Col span={22} className={styles.normal}>
							commence legal action against you, whether for
							breach of contract or otherwise; and/or
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(g)
						</Col>
						<Col span={22} className={styles.normal}>
							{" "}
							suspend or delete your account on our Platform.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.bold}>
							9
						</Col>
						<Col span={22} className={styles.bold}>
							Third party websites
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							9.1
						</Col>
						<Col span={22} className={styles.normal}>
							Our Platform may include links to other websites or
							apps owned and operated by third parties. We have no
							responsibility for the content of such third party
							websites or apps.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							9.2
						</Col>
						<Col span={22} className={styles.normal}>
							We have no control over third party websites or apps
							and their contents, and we accept no responsibility
							for them or for any loss or damage that may arise
							from your use of them.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.bold}>
							10
						</Col>
						<Col span={22} className={styles.bold}>
							Trade marks
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							10.1
						</Col>
						<Col span={22} className={styles.normal}>
							The registered and unregistered trade marks or
							service marks on our Platform are the property of
							their respective owners and, unless stated otherwise
							in these terms and conditions, we are not affiliated
							with any of the holders of any such rights and as
							such we cannot grant any licence to exercise such
							rights.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.bold}>
							11
						</Col>
						<Col span={22} className={styles.bold}>
							Copyright complaints
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							11.1
						</Col>
						<Col span={22} className={styles.normal}>
							If you believe that your intellectual property is
							being violated on the Platform, you can submit a
							complaint and request for takedown of specific
							material at: info@ugenie.io.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							11.2
						</Col>
						<Col span={22} className={styles.normal}>
							The Digital Millennium Copyright Act of 1998 (the
							“DMCA”) protects the rights of copyright owners who
							believe that material appearing on the internet
							infringes their rights under U.S. copyright law. If
							you believe in good faith that materials posted on
							the Services infringe your copyright, you (or your
							agent) may send Ugenie a “Notice of Claimed
							Infringement” at{" "}
							<a
								href="mailto:info@ugenie.io"
								target="_blank"
								rel="noreferrer"
							>
								info@ugenie.io
							</a>{" "}
							requesting that the material be removed. The Notice
							must include the following information:
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(a)
						</Col>
						<Col span={22} className={styles.normal}>
							a physical or electronic signature of a person
							authorized to act on behalf of the owner of the
							works that have been allegedly infringed;
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(b)
						</Col>
						<Col span={22} className={styles.normal}>
							identification of the copyrighted work alleged to
							have been infringed (or if multiple copyrighted
							works located on the Services are covered by a
							single notification, a representative list of those
							works);
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(c)
						</Col>
						<Col span={22} className={styles.normal}>
							identification of the specific material alleged to
							be infringing or the subject of infringing activity,
							and information reasonably sufficient to allow
							Ugenie to locate the material on the Platform;
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(d)
						</Col>
						<Col span={22} className={styles.normal}>
							your name, address, telephone number, and email
							address (if available);
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(e)
						</Col>
						<Col span={22} className={styles.normal}>
							a statement that you have a good faith belief that
							use of the material in the manner complained of is
							not authorised by the copyright owner, its agent, or
							the law; and
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(f)
						</Col>
						<Col span={22} className={styles.normal}>
							a statement that the information in the notification
							is accurate and that the complaining party is
							authorised to act on behalf of the owner of an
							exclusive right that is allegedly infringed.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							11.3
						</Col>
						<Col span={22} className={styles.normal}>
							If you believe in good faith that a notice of
							copyright infringement has been wrongly filed
							against you, the DMCA permits you to send Ugenie a
							counter-notice. You should consult your legal
							advisor before filing a notice or counter-notice as
							there are penalties for false claims under the DMCA.
							Also, please note that if you knowingly misrepresent
							that any activity or material on our Platform is
							infringing, you may be liable to Ugenie for costs
							and damages.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.bold}>
							12
						</Col>
						<Col span={22} className={styles.bold}>
							Apple Terms
						</Col>
					</Row>

					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							12.1
						</Col>
						<Col span={22} className={styles.normal}>
							If you are accessing the Platform on an iOS-based
							device, you agree to and acknowledge the following
							terms:
						</Col>
					</Row>

					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(a)
						</Col>
						<Col span={22} className={styles.normal}>
							You acknowledge that these Terms are between you and
							Ugenie only, not with Apple, and Apple is not
							responsible for any App or the content thereof.
							Apple has no obligation whatsoever to furnish any
							maintenance and support services with respect to any
							App. In the event of any failure of any App to
							conform to any applicable warranty, then you may
							notify Apple and request that Apple refund the
							purchase price for the relevant App to you; and, to
							the maximum extent permitted by applicable law,
							Apple has no other warranty obligation whatsoever
							with respect to any App. Apple is not responsible
							for addressing any claims by you or any third party
							relating to any App or your possession and/or use of
							any App, including, but not limited to: (i) product
							liability claims; (ii) any claim that any App fails
							to conform to any applicable legal or regulatory
							requirement; and (iii) claims arising under consumer
							protection or similar legislation.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(b)
						</Col>
						<Col span={22} className={styles.normal}>
							Apple is not responsible for the investigation,
							defense, settlement, and discharge of any third
							party claim that any App or your possession and use
							of any App infringes that third party’s intellectual
							property rights. You will comply with any applicable
							third-party terms, when using any App. Apple, and
							Apple’s subsidiaries, are third-party beneficiaries
							of this section of these Terms, and upon your
							acceptance of these Terms, Apple will have the right
							(and will be deemed to have accepted the right) to
							enforce this section of these Terms against you as a
							third-party beneficiary of these Terms.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(c)
						</Col>
						<Col span={22} className={styles.normal}>
							You hereby represent and warrant that (a) you are
							not located in a country that is subject to a U.S.
							Government embargo, or that has been designated by
							the U.S. Government as a “terrorist supporting”
							country; and (b) you are not listed on any U.S.
							Government list of prohibited or restricted parties.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.bold}>
							13
						</Col>
						<Col span={22} className={styles.bold}>
							General Terms
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={3} className={styles.normal}>
							13.1
						</Col>
						<Col span={21} className={styles.normal}>
							If any of the Terms are held to be illegal or
							unenforceable, such provisions shall be severed and
							the rest of the Terms shall remain in full force and
							effect.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={3} className={styles.normal}>
							13.2
						</Col>
						<Col span={21} className={styles.normal}>
							The Terms constitute the entire agreement and
							replace any previous agreement or understanding
							(whether oral or written, express or implied)
							between us in respect of the matters contained or
							referred to in the Terms. Each of us agree that, in
							entering the Terms, we have not relied on and have
							no remedy in respect of, any representation,
							warranty or other provision (oral or written,
							express or implied) of any person which is not
							expressly set out in the Terms. The only remedy
							available in respect of any misrepresentation or
							untrue statement shall be a claim for breach of
							contract under the Terms. This paragraph does not
							operate to limit or exclude any liability arising
							from any fraudulent or dishonest statement, act or
							omission.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={3} className={styles.normal}>
							13.3
						</Col>
						<Col span={21} className={styles.normal}>
							We reserve the right at all times to edit, refuse to
							post, or to remove from the Ugenie Platform any
							information or materials for any reason whatsoever,
							and to disclose any information we deem appropriate
							to satisfy any obligation we may have under
							applicable laws, regulatory requirements, legal
							processes, or to satisfy any request of the police,
							government or any regulatory body.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={3} className={styles.normal}>
							13.4
						</Col>
						<Col span={21} className={styles.normal}>
							You may not assign, transfer or sub-contract any of
							your rights under the Terms without our prior
							written consent. We may assign, transfer or
							sub-contract all or any of our rights at any time
							without consent.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={3} className={styles.normal}>
							13.5
						</Col>
						<Col span={21} className={styles.normal}>
							A person who is not a party to the Terms shall not
							have any rights under the Contracts (Rights of Third
							Parties) Act 1999 to enforce any provision in the
							Terms.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={3} className={styles.normal}>
							13.6
						</Col>
						<Col span={21} className={styles.normal}>
							No waiver by either of us shall be effective unless
							in writing, and no waiver shall constitute a
							continuing waiver so as to prevent us or you from
							acting upon any continuing or subsequent breach or
							default.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={3} className={styles.normal}>
							13.7
						</Col>
						<Col span={21} className={styles.normal}>
							We may revise these terms and conditions from time
							to time. The revised terms and conditions shall
							apply to the use of our Platform from the date of
							publication of the revised terms and conditions on
							the Platform, and you hereby waive any right you may
							otherwise have to be notified of, or to consent to,
							revisions of these terms and conditions. If you do
							not agree to the revised terms and conditions, you
							must stop using our Platform.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={3} className={styles.normal}>
							13.8
						</Col>
						<Col span={21} className={styles.normal}>
							The Terms shall be subject to the laws of England
							and the parties shall submit to the exclusive
							jurisdiction of the English courts. The Platform is
							operated by Ugenie from its offices within the UK.
							Ugenie makes no representation that use of the
							Platform is appropriate or available for use in all
							other locations. Those who access the Platform from
							other locations are responsible for compliance with
							any applicable local laws.
						</Col>
					</Row>
				</>
			)}
		</div>
	);
};

export default CookiePolicy;
