import { StripeError } from "@stripe/stripe-js";
import { Button, ButtonProps } from "antd";
import classNames from "classnames";
import React, { ReactNode } from "react";
import styles from "./styles.module.scss";

interface ICustomButtonProps {
	buttonText?: string;
	children?: ReactNode;
	varient?: "outline" | "solid" | "denger" | "gray";
	error?: Record<string, string | boolean | number> | StripeError | null;
	disabled?: boolean;
}
//todo have to add processing and errror conditions for button

const getVariantClassname = (
	variant: "outline" | "solid" | "denger" | "gray" | undefined,
) => {
	switch (variant) {
		case "solid":
			return styles.solidPrimaryColorBackgroundBtn;
		case "outline":
			return styles.outlinePrimaryColorBackgroundBtn;
		case "denger":
			return styles.solidDangerColorBackgroundBtn;
		case "gray":
			return styles.outlineGrayBackgroundBtn;
		default:
			return styles.solidPrimaryColorBackgroundBtn;
	}
};

const CustomButton = ({
	buttonText,
	children,
	varient,
	disabled,
	...props
}: ICustomButtonProps & ButtonProps) => {
	return (
		<Button
			disabled={disabled}
			className={classNames(getVariantClassname(varient))}
			{...props}
		>
			{children}
			{buttonText}
		</Button>
	);
};

export default CustomButton;
