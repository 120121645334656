import React, { memo, useCallback, useMemo } from "react";
import { Table } from "antd";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import styles from "../../components/styles.module.scss";
import { TStore } from "models";
import PdfIcon from "assets/images/pdf.png";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.subscription.invoices,
	(invoices) => ({
		invoices,
	}),
);

interface IInvoices {
	toggleSeeAllInvoices: () => void;
	seeAllInvoices: boolean;
}

const Invoices: React.FunctionComponent<IInvoices> = ({
	toggleSeeAllInvoices,
	seeAllInvoices,
}) => {
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { invoices } = useSelector(stateSelector);

	const parsedInvoices = useMemo(() => {
		if (invoices && invoices.length > 0) {
			const dateTimeFormat = new Intl.DateTimeFormat("en", {
				year: "numeric",
				month: "long",
				day: "numeric",
			});
			const reverseOrderInvoices = [...invoices].reverse();
			const resultedInvoices =
				!seeAllInvoices && reverseOrderInvoices.length > 3
					? reverseOrderInvoices.slice(0, 3)
					: reverseOrderInvoices;
			return resultedInvoices.map((item, index) => {
				const { amount, currency_symbol, plan_name, hosted_url } = item;
				return {
					key: index + 1,
					paidAt: dateTimeFormat.format(new Date(item.paid_at)),
					service: plan_name ? plan_name : "Legacy plan",
					amount: {
						number: amount ?? 0,
						currencySymbol: currency_symbol ?? "",
					},
					receiptUrl: hosted_url,
				};
			});
		} else {
			return [];
		}
	}, [invoices, seeAllInvoices]);

	return (
		<div className={styles.mainContainer}>
			<div className={styles.headerContainer}>
				<div className={styles.headerTitle}>{"Invoices"}</div>
				<div style={{ display: "flex" }}>
					{invoices && invoices.length > 3 && (
						<>
							{
								<div
									style={{
										cursor: "pointer",
										paddingRight: 16,
									}}
									onClick={toggleSeeAllInvoices}
								>
									{!seeAllInvoices ? "Show all" : "Show less"}
								</div>
							}
						</>
					)} 
				</div>
			</div>
			<div>
				<Table
					dataSource={parsedInvoices}
					// columns={columns}
					pagination={false}
					style={{ background: "#ffffff" ,overflow:'auto'}}
					className={styles.table}
				>
					<Table.Column
						title="Date"
						dataIndex="paidAt"
						key="paidAt"
						render={(paidAt) => paidAt}
					/>
					<Table.Column
						title="Service"
						dataIndex="service"
						key="service"
						render={(service) => service}
					/>
					<Table.Column
						title="Amount"
						dataIndex="amount"
						key="amount"
						render={(amount) =>
							`${amount.currencySymbol}${(
								amount.number / 100
							).toFixed(2)}`
						}
					/>
					<Table.Column
						title="Invoice"
						dataIndex="receiptUrl"
						key="receiptUrl"
						width={"8%"}
						align="center"
						render={(receiptUrl) => {
							return (
								<a
									href={receiptUrl}
									target={"_blank"}
									rel="noreferrer"
								>
									<img
										src={PdfIcon}
										style={{ width: 18 }}
										alt="invoice"
									/>
								</a>
							);
						}}
					/>
				</Table>
			</div>
		</div>
	);
};

export default memo(Invoices);
