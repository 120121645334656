import { memo, useCallback, useMemo, useState } from "react";
import { Upload, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { LoadingOutlined } from "@ant-design/icons";

import styles from "./styles.module.scss";
import { ISelectedCommunityToJoin, TStore } from "models";
import { GetImg, rollbar } from "utils";
import uploadPhoto from "assets/images/upload-photo.png";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import { setSelectedCommunityToJoinUserProfilePicture } from "store";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.auth?.credentials?.user,
	(authenticatedUser) => ({ authenticatedUser }),
);

function getBase64(img: any, callback: any) {
	const reader = new FileReader();
	reader.addEventListener("load", () => callback(reader.result));
	reader.readAsDataURL(img);
}

function beforeUpload(file: any) {
	try{const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
	if (!isJpgOrPng) {
		message.error("You can only upload JPG/PNG file!");
	}
	const isLt2M = file.size / 1024 / 1024 < 2;
	if (!isLt2M) {
		message.error("Image must smaller than 2MB!");
	}
	return isJpgOrPng && isLt2M;}
	catch (error) {
		rollbar.error(error)
		return false;
	}
}

interface IProfilePicture {
	selectedCommunityToJoinInfo: ISelectedCommunityToJoin;
}

const ProfilePicture: React.FunctionComponent<IProfilePicture> = ({
	selectedCommunityToJoinInfo,
}) => {
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { authenticatedUser } = useSelector(stateSelector);
	const [imageLoading, setImageLoading] = useState(false);
	const dispatch = useDispatch();

	const handleUploadImage = useCallback(
		(info: UploadChangeParam<UploadFile<any>>) => {
			if (info.file.status === "uploading") {
				setImageLoading(true);
				return;
			}
			if (info.file.status === "done") {
				getBase64(info.file.originFileObj, (imageUrl: any) => {
					dispatch(
						setSelectedCommunityToJoinUserProfilePicture({
							profileImage: info,
							imageUrl,
						}),
					);
					setImageLoading(false);
				});
			}
		},
		[dispatch],
	);

	const handleCustomRequest = ({ file, onSuccess }: any) => {
		setTimeout(() => {
			onSuccess("ok");
		}, 0);
	};

	const hasTheUserUploadedAProfilePicture = useMemo((): boolean => {
		if (!authenticatedUser) return false;
		const userAvatarRegex = new RegExp(
			"avatar_placeholder-[a-zA-Z0-9]+.png",
		);
		const regexArray = userAvatarRegex.exec(authenticatedUser.picture);
		if (regexArray) return false;
		else return true;
	}, [authenticatedUser]);

	const uploadButton = (
		<div>
			{imageLoading ? (
				<LoadingOutlined
					style={{
						fontSize: 28,
					}}
				/>
			) : (
				<div className={styles.uploadIconDiv}>
					<img
						src={uploadPhoto}
						alt="upload"
						style={{ borderRadius: 0 }}
					/>
				</div>
			)}
		</div>
	);

	return !authenticatedUser ? null : (
		<div className={styles.container}>
			<div className={styles.uploadSection}>
				<Upload
					accept=".png,.jpg,.jpeg"
					name="avatar"
					listType="picture-card"
					className={styles.avatarUploader}
					showUploadList={false}
					beforeUpload={beforeUpload}
					onChange={handleUploadImage}
					customRequest={handleCustomRequest}
				>
					{selectedCommunityToJoinInfo.userProfilePicture
						?.imageUrl ? (
						<img
							src={
								selectedCommunityToJoinInfo.userProfilePicture
									.imageUrl
							}
							alt="avatar"
							style={{ width: "100%" }}
						/>
					) : !hasTheUserUploadedAProfilePicture ? (
						uploadButton
					) : (
						<GetImg endpoint={authenticatedUser.picture} />
					)}
				</Upload>
				<div className={styles.boldCenteredText}>
					Add a profile picture
					<div className={styles.asteriskGreyText}>Optional</div>
				</div>
			</div>
		</div>
	);
};

export default memo(ProfilePicture);
