import emptyState from "assets/images/empty-state.png";
import CustomButton from "components/Button";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";

const InvitesEmptyState = () => {
	const navigate = useNavigate()
	return (
		<div className={styles.emptyInvitesContainer}>
			<img src={emptyState} alt="no communities joined" />

			<div className={styles.titleContainer}>
				<h3 className={styles.title}>You don't have invites</h3>

				<p className={styles.description}>
					At the moment you don't have any new invites, but don't worry! You
					can go and discover other amazing communities by clicking on
					the all communities button bellow!
				</p>
			</div>

			<div className={styles.buttonContainer}>
				<CustomButton
					varient="outline"
					onClick={() => navigate("/communities")}
				>
					My Communities
				</CustomButton>
				<CustomButton
					varient="solid"
					onClick={() => navigate("/communities/all")}
				>
					All communitites
				</CustomButton>
			</div>
		</div>
	);
};

export default InvitesEmptyState;
