import { axiosInstance } from "..";

export const userNotifications = {
	getSettings: (payload: { communityId: number }) =>
		axiosInstance().get(
			`/communities/${payload.communityId}/notifications/settings/edit`,
		),
	toggle: (payload: { communityId: number; membership: any }) =>
		axiosInstance().patch(
			`/communities/${payload.communityId}/notifications/settings`,
			{
				membership: payload.membership,
			},
		),
	getNotifications: (payload: { page: number; communityId: number }) =>
		axiosInstance().get(
			`/communities/${payload.communityId}/notifications`,
			{
				params: {
					page: payload.page,
				},
			},
		),
	openNotification: (communityId: number, notificationId: number) =>
		axiosInstance().get(
			`/communities/${communityId}/notifications/${notificationId}`,
		),

	markAllAsRead: (communityId: number) =>
		axiosInstance().put(
			`/communities/${communityId}/notifications/mark_all_as_read`,
		),
};
