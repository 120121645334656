import { IFirebaseState, EFirebaseActionTypes, TFirebaseActions } from "models";

const initial: IFirebaseState = {
	postsDocuments: null,
	postComments: null,
};

export function firebaseReducer(
	state = initial,
	action: TFirebaseActions,
): IFirebaseState {
	switch (action.type) {
		case EFirebaseActionTypes.updateLocalPostDocument: {
			const postId: number = action.payload.postId;
			return {
				...state,
				postsDocuments: {
					...state.postsDocuments,
					[postId]: action.payload.data,
				},
			};
		}
		case EFirebaseActionTypes.addFirebaseLocalPostComments: {
			const postId: number = action.payload.postId;
			return {
				...state,
				postComments: {
					...state.postComments,
					[postId]: action.payload.data,
				},
			};
		}
		case EFirebaseActionTypes.updateFirebaseLocalPostComments: {
			const postId: number = action.payload.postId;
			const postComments: any = state.postComments
				? state.postComments[action.payload.postId] || []
				: [];
			return {
				...state,
				postComments: {
					...state.postComments,
					[postId]: [...action.payload.data, ...postComments],
				},
			};
		}
		case EFirebaseActionTypes.addFirebaseLocalCommentForPost: {
			const postComments: any = state.postComments
				? state.postComments[action.payload.postId] || []
				: [];
			const postDocument: any = state.postsDocuments
				? state.postsDocuments[action.payload.postId] || []
				: [];
			return {
				...state,
				postsDocuments: {
					...state.postsDocuments,
					[action.payload.postId]: {
						...postDocument,
						count: postDocument.count + 1,
					},
				},
				postComments: {
					...state.postComments,
					[action.payload.postId]: [
						...postComments,
						action.payload.comment,
					],
				},
			};
		}
		default:
			return state;
	}
}
