import { PostImgSize } from "utils";

export const useComputePostImgSize = ({
	containerWidth,
	images,
}: {
	containerWidth: number;
	images: {
		image: string;
		mobile: string;
		id: number;
		desktop: {
			xs?: string;
			sm?: string;
			md?: string;
			l?: string;
			xl?: string;
		};
	}[];
}) => {
	let returnedImage: any = null;
	if (images && images.length > 0) {
		const postImages = images[0];
		// if (
		// 	containerWidth >= parseInt(PostImgSize.xl.split("x")[0]) &&
		// 	postImages.desktop.xl
		// ) {
		// 	returnedImage = postImages.desktop.xl;
		// 	returnedImage = postImages.image;
		// } else if (
		// 	containerWidth >= parseInt(PostImgSize.l.split("x")[0]) &&
		// 	postImages.desktop.l
		// ) {
		// 	returnedImage = postImages.desktop.l;
		// } else if (
		// 	containerWidth >= parseInt(PostImgSize.md.split("x")[0]) &&
		// 	postImages.desktop.md
		// ) {
		// 	returnedImage = postImages.desktop.md;
		// } else if (
		// 	containerWidth >= parseInt(PostImgSize.sm.split("x")[0]) &&
		// 	postImages.desktop.sm
		// ) {
		// 	returnedImage = postImages.desktop.sm;
		// } else if (
		// 	containerWidth >= parseInt(PostImgSize.xs.split("x")[0]) &&
		// 	postImages.desktop.xs
		// ) {
		// 	returnedImage = postImages.desktop.xs;
		// } else {
		// 	returnedImage = postImages.mobile;
		// }

		// TODO: at the moment the images are not processed correctly on the backend and therefore we will use the original image and the extra small image.
		if (
			containerWidth >= parseInt(PostImgSize.sm.split("x")[0]) &&
			postImages.image
		) {
			returnedImage = postImages.image;
		} else {
			returnedImage = postImages.desktop.xs;
		}
	}
	return returnedImage;
};
