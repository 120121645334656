import React, { memo, useMemo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dropdown, Menu, Tag } from "antd";
import { createSelector } from "reselect";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import linkifyHtml from "linkifyjs/html";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faBan,
	faFlag,
	faMessage,
	// faPhone,
	// faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { Modal, Input } from "antd";

import { GetImg, mentionRegex } from "utils";
import styles from "./styles.module.scss";
import { TStore, IMember } from "models";
import { PostsData } from "./components";
import {
	blockMember,
	fetchConversation,
	notify,
	reportMember,
	unblockMember,
} from "store";
import { ReactComponent as BlockIcon1 } from "assets/images/block1.svg";
import { ReactComponent as ShieldIcon } from "assets/images/shield.svg";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.auth.credentials?.user,
	(state: TStore) => state.member,
	(state: TStore) => state.app.currentCommunityId,
	(user, member, currentCommunityId) => ({
		user,
		storeMember: member,
		currentCommunityId,
	}),
);

interface IFullProfileBox {
	member: IMember;
}

const { TextArea } = Input;

const FullProfileBox: React.FunctionComponent<IFullProfileBox> = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [reportMemberText, setReportMemberText] = useState<string | null>(
		null,
	);
	const stateSelector = useCallback(stateSelectorHandle, []);

	const { user, storeMember } = useSelector(stateSelector);

	const handleOnChangeTextArea = (
		event: React.ChangeEvent<HTMLTextAreaElement>,
	) => {
		setReportMemberText(event.target.value);
	};

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = () => {
		setIsModalVisible(false);
		if (storeMember && reportMemberText) {
			dispatch(
				reportMember({
					message: reportMemberText,
					membershipId: storeMember.member.membership_id,
					successCallback: () =>
						notify("success", "Member reported successfully."),
					errorCallback: () =>
						notify(
							"error",
							"An error occurred. Please try again later.",
						),
				}),
			);
		}
	};

	const handleCancel = () => {
		setReportMemberText(null);
		setIsModalVisible(false);
	};

	const handleBlockAndUnblockMember = useCallback(() => {
		if (storeMember?.member) {
			if (!storeMember.member.blocked) {
				dispatch(
					blockMember({
						id: storeMember.member.id,
						successCallback: () =>
							notify(
								"success",
								"The member was blocked successfully.",
							),
						errorCallback: () =>
							notify(
								"error",
								"An error occurred. Please try again later.",
							),
					}),
				);
			} else {
				dispatch(
					unblockMember({
						id: storeMember.member.id,
						successCallback: () =>
							notify(
								"success",
								"The member was unblocked successfully.",
							),
						errorCallback: () =>
							notify(
								"error",
								"An error occurred. Please try again later.",
							),
					}),
				);
			}
		}
	}, [dispatch, storeMember.member]);

	const menu = useMemo(() => {
		if (storeMember.member) {
			return (
				<Menu onClick={() => { }} className={styles.menuContainer}>
					<Menu.Item key="edit" onClick={handleBlockAndUnblockMember}>
						<div className={styles.menuItem}>
							<div className={styles.icon}>
								<FontAwesomeIcon
									icon={faBan as unknown as IconProp}
									style={{
										marginRight: 8,
										alignSelf: "center",
										color: "grey",
										fontSize: 16,
									}}
								/>
							</div>
							<div>
								<div className={styles.boldText}>
									{!storeMember.member.blocked
										? "Block member"
										: "Unblock member"}
								</div>
								<div className={styles.smallSizeText}>
									{!storeMember.member.blocked
										? "Blocked contacts will no longer be able to message you."
										: "Unblocked contacts will be able to message you."}
								</div>
							</div>
						</div>
					</Menu.Item>
					<Menu.Item key="delete" onClick={showModal}>
						<div className={styles.menuItem}>
							<div className={styles.icon}>
								<FontAwesomeIcon
									icon={faFlag as unknown as IconProp}
									style={{
										marginRight: 8,
										alignSelf: "center",
										color: "grey",
										fontSize: 16,
									}}
								/>
							</div>
							<div>
								<div className={styles.boldText}>
									{"Report member"}
								</div>
								<div className={styles.smallSizeText}>
									{
										"Recognize and Report Spam, Inappropriate, and Abusive Content."
									}
								</div>
							</div>
						</div>
					</Menu.Item>
				</Menu>
			);
		} else {
			return null;
		}
	}, [handleBlockAndUnblockMember, storeMember.member]);

	const goToChat = useCallback(() => {
		if (storeMember?.member?.membership_id) {
			navigate("/messages", {
				state: { member: storeMember.member },
			});
			dispatch(
				fetchConversation({
					communityPostId: 0,
					recipientId: storeMember.member.membership_id,
					conversationId: 0,
					page: 1,
				}),
			);
		}
	}, [dispatch, navigate, storeMember?.member]);

	const clean = useMemo(() => {
		let source = DOMPurify.sanitize(storeMember.member.about_me, {
			ALLOWED_TAGS: ["a"],
			ALLOWED_ATTR: ["href"],
		});
		const instances = source.match(mentionRegex);
		instances &&
			instances.map((instance: string) => {
				const parts = instance.split(" [uid: ");
				source = source.replace(
					instance,
					`<a data-mention="/members/${parts[1].replace("]", "")}">${parts[0]
					}</a>`,
				);

				return undefined;
			});

		return source;
	}, [storeMember.member.about_me]);

	return storeMember?.member && user ? (
		<div className={styles.fullProfileBox}>
			{
				<>
					<div className={styles.topInfo}>
						<div className={styles.avatarHolder}>
							<div className={styles.avatar}>
								{storeMember.member.blocked && (
									<BlockIcon1
										viewBox={"2 2 20 20"}
										style={{
											left: 0,
											width: "175px",
											color: "#ffffff",
											position: "absolute",
										}}
									/>
								)}
								<GetImg endpoint={storeMember.member.picture} />
							</div>
							<div style={{ display: "flex", gap: 5, justifyContent: 'center' }}>
								{storeMember.member.id !== user?.id &&
									!storeMember.member.blocked &&
									!storeMember.member.blocked_by && (
										<button
											className={styles.messagesBtn}
											onClick={goToChat}
										>
											<FontAwesomeIcon
												fontSize={18}
												icon={faMessage as unknown as IconProp}
											/>
											Message
										</button>
									)}
								{/* <button
							className={styles.messagesBtn}
							onClick={() =>
								navigate(
									`/video-call/${storeMember.member.membership_id}?audio=on&video=off`,
								)
							}
						>
							<FontAwesomeIcon
								fontSize={18}
								icon={faPhone as unknown as IconProp}
							/>
							Audio Call
						</button>
						<button
							className={styles.messagesBtn}
							onClick={() =>
								navigate(
									`/video-call/${storeMember.member.membership_id}?audio=on&video=on`,
								)
							}
						>
							<FontAwesomeIcon
								fontSize={18}
								icon={faVideo as unknown as IconProp}
							/>
							Video Call
						</button> */}
							</div>
						</div>

						<div className={styles.profileInfo}>
							<h3
								dangerouslySetInnerHTML={{
									__html: storeMember.member.name,
								}}
							></h3>
							<h4>
								<span>
									{storeMember.member.membership_type}
								</span>
								<span className={styles.location}>
									{storeMember.member.location}
								</span>
							</h4>

							<div className={styles.tagsContainer}>

								{
									storeMember.member?.interest_list?.map((interest, index) => {
										return (
											<small
												key={`tag_${index}_${interest}`}
												className={styles.tag}
											// onClick={handleFilterTagsPosts([item.tagToFilter])}
											>
												{interest}
											</small>
										)
									})
								}
							</div>
							<div className={styles.tagsContainer}>
								{
									storeMember.member?.skill_list?.map((skill, index) => {
										if (skill.is_selected) {
											return (
												<small
													key={`tag_${index}_${skill}`}
													className={styles.tag}
												// onClick={handleFilterTagsPosts([item.tagToFilter])}
												>
													#{skill.name}
												</small>
											)
										}
										else {
											return null
										}
									})
								}
							</div>
							{/* {storeMember.member.} */}

							<p
								style={{
									whiteSpace: "pre-wrap",
									wordBreak: "break-all",
								}}
								dangerouslySetInnerHTML={{
									__html: linkifyHtml(clean),
								}}
							/>
						</div>
						{storeMember.member.id !== user.id && menu && (
							<div className={styles.dropdownMenu}>
								<Dropdown
									overlay={menu}
									placement="bottomRight"
								>
									<Button style={{ marginLeft: "auto" }}>
										<ShieldIcon
											width={"26px"}
											height={"26px"}
											color={"grey"}
										/>
									</Button>
								</Dropdown>
							</div>
						)}
					</div>

					{storeMember.member.subset_list && (
						<div className={styles.tags}>
							{storeMember.member.subset_list
								.filter((item) => {
									return item.is_selected;
								})
								.map((item, index: any) => {
									return (
										<Tag key={`key_tag_${index}`}>
											{item.name}
										</Tag>
									);
								})}
						</div>
					)}
					<div className={styles.posts}>
						<PostsData member={storeMember.member} />
					</div>
					<Modal
						title="Report reason"
						visible={isModalVisible}
						onOk={handleOk}
						onCancel={handleCancel}
						getContainer={() =>
							document.getElementById("right-section") as any
						}
						className={styles.modal}
						okButtonProps={{
							disabled: !reportMemberText ? true : false,
						}}
						maskClosable={true}
						closable={false}
						keyboard={false}
					>
						<div className={styles.textarea}>
							<TextArea
								placeholder={
									"Please share the reportable behavior here."
								}
								showCount
								maxLength={300}
								rows={4}
								onChange={(event) =>
									handleOnChangeTextArea(event)
								}
							/>
						</div>
					</Modal>
				</>
			}
		</div>
	) : null;
};

export default memo(FullProfileBox);
