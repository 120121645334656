import { call, put, all, takeEvery } from "redux-saga/effects";

import {
	EPreviewWhiteLabelCommunityActionTypes,
	IPreviewWhiteLabelCommunity,
} from "models";
import {
	api,
	getPreviewWhiteLabelCommunityAction,
	setPreviewWhiteLabelCommunityAction,
} from "store";
import { whiteLabelConfig } from "config";
import { hexToRgbA } from "utils";

function* getPreviewWhiteLabelCommunityHandle({
	payload,
}: ReturnType<typeof getPreviewWhiteLabelCommunityAction>) {
	const { onErrorCallback, onSuccessCallback } = payload;
	try {
		const whiteLabelCommunityId = whiteLabelConfig?.community?.id ?? null;
		if (!whiteLabelCommunityId) {
			throw new Error("Missing parameters.");
		}
		// TODO: community_previews endpoint needs to be updated to support the community's id as a parameter
		if (whiteLabelConfig?.community?.slug) {
			const { data }: { data: IPreviewWhiteLabelCommunity } = yield call(
				api.auth.communityPreviews,
				{
					token: whiteLabelConfig?.community?.slug,
				},
			);
			const {
				header_background,
				announcement_header_background,
				post_header_background,
				primary_color,
				secondary_color,
			} = data.appearance;
			document.documentElement.style.setProperty(
				"--headerBackground",
				header_background,
			);
			document.documentElement.style.setProperty(
				"--headerBackgroundRgb",
				hexToRgbA(header_background),
			);

			document.documentElement.style.setProperty(
				"--announcementHeaderBackground",
				announcement_header_background,
			);
			document.documentElement.style.setProperty(
				"--announcementHeaderBackgroundRgb",
				hexToRgbA(announcement_header_background),
			);
			document.documentElement.style.setProperty(
				"--postHeaderBackground",
				post_header_background,
			);
			document.documentElement.style.setProperty(
				"--postHeaderBackgroundRgb",
				hexToRgbA(post_header_background),
			);
			document.documentElement.style.setProperty(
				"--primaryColor",
				primary_color,
			);
			document.documentElement.style.setProperty(
				"--primaryColorRgb",
				hexToRgbA(primary_color),
			);
			document.documentElement.style.setProperty(
				"--secondaryColor",
				secondary_color,
			);
			document.documentElement.style.setProperty(
				"--secondaryColorRgb",
				hexToRgbA(secondary_color),
			);
			yield put(setPreviewWhiteLabelCommunityAction({ ...data }));
		}
		onSuccessCallback();
	} catch (error) {
		console.error({ error });
		onErrorCallback();
	}
}

export default function* rootSaga() {
	yield all([
		takeEvery(
			EPreviewWhiteLabelCommunityActionTypes.getPreviewCommunityData,
			getPreviewWhiteLabelCommunityHandle,
		),
	]);
}
