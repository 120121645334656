import { Row, Col } from "antd";

import styles from "./styles.module.scss";

const PrivacyPolicy = ({
	data,
	communityName,
}: {
	data?: any;
	communityName?: string;
}) => {
	return (
		<div className={styles.content}>
			<div className={styles.title}>
				{communityName ? communityName : "UGENIE"}
			</div>
			<div className={styles.subTitle}>PLATFORM PRIVACY POLICY</div>
			{data ? (
				<Row className={styles.row}>
					<Col span={24} className={styles.normal}>
						<div dangerouslySetInnerHTML={{ __html: data }} />
					</Col>
				</Row>
			) : (
				<>
					<Row className={styles.row}>
						<Col span={1} className={styles.bold}>
							1
						</Col>
						<Col span={23} className={styles.bold}>
							Introduction
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							1.1
						</Col>
						<Col span={22} className={styles.normal}>
							We are committed to safeguarding the privacy of our
							website visitors and mobile app service users. This
							policy is designed to ensure that we safely handle
							your personal data in accordance with relevant
							regulations and legislation such as Data Protection
							Act 1998 and EU General Data Protection Regulations
							2018 (the{" "}
							<strong>“Data Protection Regulations”</strong>).
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							1.2
						</Col>
						<Col span={22} className={styles.normal}>
							This policy applies in those cases where we act as a
							data controller for the personal data of our website
							visitors and mobile app service users. This means
							those cases where we can decide the purposes and
							method of processing your personal data.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							1.3
						</Col>
						<Col span={22} className={styles.normal}>
							By using our website or our mobile app, you consent
							to the terms of this policy.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							1.4
						</Col>
						<Col span={22} className={styles.normal}>
							These privacy rules explain what data we may collect
							from you, what we will do with that data and
							explains how you can you limit the publication of
							your information and how you can choose whether or
							not you would like to receive direct marketing
							communications.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							1.5
						</Col>
						<Col span={22} className={styles.normal}>
							In this policy, <strong>"we", "us"</strong> and{" "}
							<strong>"our"</strong> refer to Ugenie Ltd. Further
							details about us can be found below, in section 10
							of this Privacy Policy.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={1} className={styles.bold}>
							2
						</Col>
						<Col span={23} className={styles.bold}>
							How we use your personal data
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							2.1
						</Col>
						<Col span={22} className={styles.normal}>
							In this Section 2 we set out:
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(a)
						</Col>
						<Col span={22} className={styles.normal}>
							the general categories of personal data that we may
							process;
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(b)
						</Col>
						<Col span={22} className={styles.normal}>
							the purposes for which we may process personal data;
							and
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(c)
						</Col>
						<Col span={22} className={styles.normal}>
							the legal basis of the processing in each case.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							2.2
						</Col>
						<Col span={22} className={styles.normal}>
							We may process data about your use of our website
							and mobile app services (
							<strong>"usage data"</strong>). The usage data may
							include your IP address, geographical location,
							browser type and version, operating system, referral
							source, length of visit, page views and navigation
							paths, as well as information about the timing,
							frequency and pattern of your website or mobile app
							service use. The source of the usage data is our
							analytics tracking system. This usage data may be
							processed for the purposes of analyzing the use of
							the website and mobile app services. The legal basis
							for this processing is either your specific consent
							or where we are not legally required to ask for
							consent, we may process this data for our legitimate
							interests, namely monitoring and improving our
							website and mobile app services.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							2.3
						</Col>
						<Col span={22} className={styles.normal}>
							We may process your account data (
							<strong>"account data"</strong>). The account data
							may include your name, email address and postal
							address. The account data may be processed for the
							purposes of operating our website and mobile app,
							providing our services, ensuring the security of our
							website and mobile app services, maintaining
							back-ups of our databases and communicating with
							you. The legal basis for this processing is either
							your specific consent or where we are not legally
							required to ask for consent, we may process this
							data for our legitimate interests, namely monitoring
							and improving our website and mobile app services.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							2.4
						</Col>
						<Col span={22} className={styles.normal}>
							We may process your information included in your
							personal profile on our website and mobile app (
							<strong>"profile data"</strong>). The profile data
							may include your name, address, email address,
							profile picture, interests and hobbies. The profile
							data may be processed for the purposes of enabling
							and monitoring your use of our website and mobile
							app services. The legal basis for this processing is
							either your specific consent or where we are not
							legally required to ask for consent, we may process
							this data for our legitimate interests, namely
							monitoring and improving our website and mobile app
							services.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							2.5
						</Col>
						<Col span={22} className={styles.normal}>
							We may process personal data that is provided in the
							course of the use of our services (
							<strong>"service data"</strong>
							). The service data may include your name, email
							address, home address, contact telephone number and
							credit card details. The service data may be
							processed for the purposes of operating our website
							and mobile app, providing our services, ensuring the
							security of our website and mobile app services,
							maintaining back-ups of our databases and
							communicating with you. The legal basis for this
							processing is either your specific consent or where
							we are not legally required to ask for consent, we
							may process this data for our legitimate interests,
							namely monitoring and improving our website and
							mobile app services.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							2.6
						</Col>
						<Col span={22} className={styles.normal}>
							We may process information that you post for
							publication on our website or through our mobile app
							services (<strong>"publication data"</strong>). The
							publication data may be processed for the purposes
							of enabling such publication and for the reasons set
							out in our Participant Terms and Conditions. The
							legal basis for this processing is your specific
							consent under those terms and conditions.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							2.7
						</Col>
						<Col span={22} className={styles.normal}>
							We may process information contained in any enquiry
							you submit to us regarding goods and/or services (
							<strong>"enquiry data"</strong>). The enquiry data
							may be processed for the purposes of offering,
							marketing and selling relevant goods and/or services
							to you. The legal basis for this processing is
							either your specific consent or where we are not
							legally required to ask for consent, we may process
							this data for our legitimate interests, namely
							monitoring and improving our website and mobile app
							services.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							2.8
						</Col>
						<Col span={22} className={styles.normal}>
							We may process information relating to transactions,
							including purchases of goods and services that you
							enter into with us and/or through our website or
							mobile app (<strong>"transaction data"</strong>).
							The transaction data may include your contact
							details, your card details and the transaction
							details. The transaction data may be processed for
							the purpose of supplying goods or services and
							keeping proper records of those transactions. The
							legal basis for this processing is the performance
							of a contract between you and us and/or taking
							steps, at your request, to enter into such a
							contract and our legitimate interests, namely our
							interest in the proper administration of our
							website, mobile app and business.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							2.9
						</Col>
						<Col span={22} className={styles.normal}>
							We may process any of your personal data identified
							in this policy where necessary for administrative
							purposes including in the exercise or defence of
							legal claims. The legal basis for this processing is
							our legitimate interests, namely for administrative
							record keeping, processing transactions and
							maintaining business records or for the protection
							and assertion of our legal rights.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							2.10
						</Col>
						<Col span={22} className={styles.normal}>
							If you supply any other person's personal data to
							us, you must do so only if you have the authority of
							such person to do so and you must comply with any
							obligations imposed upon you under the Data
							Protection Regulations.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={1} className={styles.bold}>
							3
						</Col>
						<Col span={23} className={styles.bold}>
							Providing your personal data to others
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							3.1
						</Col>
						<Col span={22} className={styles.normal}>
							We may disclose your personal data to our insurers
							and/or professional advisers insofar as reasonably
							necessary for the purposes of obtaining or
							maintaining insurance coverage, managing risks,
							obtaining professional advice, or to exercise or
							defend legal claims.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							3.2
						</Col>
						<Col span={22} className={styles.normal}>
							We may pass your personal information to credit
							reference agencies or other agencies that provide
							services to verify your identity or for any other
							checks or searches required by legislation or our
							regulators relating to money laundering. These
							agencies may keep a record of any search that they
							do.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							3.3
						</Col>
						<Col span={22} className={styles.normal}>
							Financial transactions relating to our website and
							mobile app services are handled by our payment
							services providers, . We share transaction data with
							our payment services providers to the extent
							necessary for the purposes of processing your
							payments, refunding such payments and dealing with
							complaints and queries relating to such payments and
							refunds. You may also wish to review our payment
							services providers' privacy policies and practices.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							3.4
						</Col>
						<Col span={22} className={styles.normal}>
							We may share your personal information, including
							name, email and location to the administrators of
							your community to allow them to communicate with you
							directly.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							3.5
						</Col>
						<Col span={22} className={styles.normal}>
							We may outsource or contract the provision of IT
							services to third parties. If we do, those third
							parties may hold and process your personal data. In
							these circumstances, we will require that the IT
							supplier only processes your personal data for us,
							as directed by us, and in accordance with the Data
							Protection Regulations.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							3.6
						</Col>
						<Col span={22} className={styles.normal}>
							In addition to the specific disclosures of personal
							data set out in this Section 3, we may disclose your
							personal data where such disclosure is necessary for
							compliance with a legal obligation to which we are
							subject, or in order to protect your legal interests
							or the legal interests of another person.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.bold}>
							4
						</Col>
						<Col span={22} className={styles.bold}>
							International transfers of your personal data
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							4.1
						</Col>
						<Col span={22} className={styles.normal}>
							In this Section 4, we provide information about the
							circumstances in which your personal data may be
							transferred to countries outside the European
							Economic Area (<strong>EEA</strong>).
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							4.2
						</Col>
						<Col span={22} className={styles.normal}>
							We are not aware of any current circumstances where
							your personal data may be transferred to any country
							outside the EEA.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							4.3
						</Col>
						<Col span={22} className={styles.normal}>
							You acknowledge that the internet can never be
							completely secure and although we are committed to
							using all reasonable commercial efforts to ensure
							the security and safety of your data, you understand
							that we cannot guarantee that security breaches
							cannot occur.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.bold}>
							5
						</Col>
						<Col span={22} className={styles.bold}>
							Retaining and deleting personal data
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							5.1
						</Col>
						<Col span={22} className={styles.normal}>
							This Section 5 sets out our data retention policies
							and procedure, which are designed to help ensure
							that we comply with our legal obligations in
							relation to the retention and deletion of personal
							data.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							5.2
						</Col>
						<Col span={22} className={styles.normal}>
							Personal data that we process for any purpose shall
							not be kept for longer than is necessary for that
							purpose. This means that unless there is a good
							reason to do so we won't keep your personal data
							more than 7 years after our business relationship
							has ended.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							5.3
						</Col>
						<Col span={22} className={styles.normal}>
							Notwithstanding the other provisions of this Section
							5, we may retain your personal data where such
							retention is necessary for compliance with a legal
							obligation to which we are subject, or in order to
							protect your legal interests or the legal interests
							of another person.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.bold}>
							6
						</Col>
						<Col span={22} className={styles.bold}>
							Amendments
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							6.1
						</Col>
						<Col span={22} className={styles.normal}>
							We may update this policy from time to time by
							publishing a new version on our website and our
							mobile app.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							6.2
						</Col>
						<Col span={22} className={styles.normal}>
							You should check these spaces occasionally to ensure
							you are happy with any changes to this policy.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							6.3
						</Col>
						<Col span={22} className={styles.normal}>
							We may notify you of changes to this policy by email
							or through the private messaging system on our
							website or mobile app.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.bold}>
							7
						</Col>
						<Col span={22} className={styles.bold}>
							Your rights
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							7.1
						</Col>
						<Col span={22} className={styles.normal}>
							In this Section 7, we have summarised the rights
							that you have under data protection law. Some of the
							rights are complex, and not all of the details have
							been included in our summaries. Accordingly, you
							should read the relevant laws and guidance from the
							regulatory authorities for a full explanation of
							these rights.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							7.2
						</Col>
						<Col span={22} className={styles.normal}>
							Your principal rights under data protection law are:
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(a)
						</Col>
						<Col span={22} className={styles.normal}>
							the right to access;
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(b)
						</Col>
						<Col span={22} className={styles.normal}>
							the right to rectification;
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(c)
						</Col>
						<Col span={22} className={styles.normal}>
							the right to erasure;
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(d)
						</Col>
						<Col span={22} className={styles.normal}>
							the right to restrict processing;
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(e)
						</Col>
						<Col span={22} className={styles.normal}>
							the right to object to processing;
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(f)
						</Col>
						<Col span={22} className={styles.normal}>
							the right to data portability;
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(g)
						</Col>
						<Col span={22} className={styles.normal}>
							the right to complain to a supervisory authority;
							and
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(h)
						</Col>
						<Col span={22} className={styles.normal}>
							the right to withdraw consent.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							7.3
						</Col>
						<Col span={22} className={styles.normal}>
							You have the right to confirmation as to whether or
							not we process your personal data and, where we do,
							access to the personal data, together with certain
							additional information. That additional information
							includes details of the purposes of the processing,
							the categories of personal data concerned and the
							recipients of the personal data. Providing the
							rights and freedoms of others are not affected, we
							will supply to you a copy of your personal data, as
							described below (clause 7.13).
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							7.4
						</Col>
						<Col span={22} className={styles.normal}>
							You have the right to have any inaccurate personal
							data about you rectified and, taking into account
							the purposes of the processing, to have any
							incomplete personal data about you completed.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							7.5
						</Col>
						<Col span={22} className={styles.normal}>
							In some circumstances, you have the right to the
							erasure of your personal data without undue delay.
							Those circumstances include: the personal data is no
							longer necessary in relation to the purposes for
							which it was collected or otherwise processed; you
							withdraw consent to consent-based processing; you
							object to the processing under certain rules of
							applicable data protection law; the processing is
							for direct marketing purposes; and the personal data
							have been unlawfully processed. However, there are
							exclusions of the right to erasure. The general
							exclusions include where processing is necessary:
							for exercising the right of freedom of expression
							and information; for compliance with a legal
							obligation; or for the establishment, exercise or
							defence of legal claims.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							7.6
						</Col>
						<Col span={22} className={styles.normal}>
							In some circumstances you have the right to restrict
							the processing of your personal data. Those
							circumstances are: you contest the accuracy of the
							personal data; processing is unlawful, but you
							oppose erasure; we no longer need the personal data
							for the purposes of our processing, but you require
							personal data for the establishment, exercise or
							defense of legal claims; and you have objected to
							processing, pending the verification of that
							objection. Where processing has been restricted on
							this basis, we may continue to store your personal
							data. However, we will only otherwise process it:
							with your consent; for the establishment, exercise
							or defense of legal claims; for the protection of
							the rights of another natural or legal person; or
							for reasons of important public interest.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							7.7
						</Col>
						<Col span={22} className={styles.normal}>
							You have the right to object to our processing of
							your personal data on grounds relating to your
							particular situation, but only to the extent that
							the legal basis for the processing is that the
							processing is necessary for: the performance of a
							task carried out in the public interest or in the
							exercise of any official authority vested in us; or
							the purposes of the legitimate interests pursued by
							us or by a third party. If you make such an
							objection, we will cease to process the personal
							information unless we can demonstrate compelling
							legitimate grounds for the processing which override
							your interests, rights and freedoms, or the
							processing is for the establishment, exercise or
							defense of legal claims.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							7.8
						</Col>
						<Col span={22} className={styles.normal}>
							You have the right to object to our processing of
							your personal data for direct marketing purposes
							(including profiling for direct marketing purposes).
							If you make such an objection, we will cease to
							process your personal data for this purpose.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							7.9
						</Col>
						<Col span={22} className={styles.normal}>
							You have the right to object to our processing of
							your personal data for scientific or historical
							research purposes or statistical purposes on grounds
							relating to your particular situation, unless the
							processing is necessary for the performance of a
							task carried out for reasons of public interest.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							7.10
						</Col>
						<Col span={22} className={styles.normal}>
							To the extent that the legal basis for our
							processing of your personal data is:
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(a)
						</Col>
						<Col span={22} className={styles.normal}>
							consent; or
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(b)
						</Col>
						<Col span={22} className={styles.normal}>
							that the processing is necessary for the performance
							of a contract to which you are party or in order to
							take steps at your request prior to entering into a
							contract, and such processing is carried out by
							automated means, you have the right to receive your
							personal data from us in a structured, commonly used
							and machine-readable format. However, this right
							does not apply where it would adversely affect the
							rights and freedoms of others.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							7.11
						</Col>
						<Col span={22} className={styles.normal}>
							If you consider that our processing of your personal
							information infringes data protection laws, you have
							a legal right to lodge a complaint with a
							supervisory authority responsible for data
							protection. You may do so in the EU member state of
							your habitual residence, your place of work or the
							place of the alleged infringement.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							7.12
						</Col>
						<Col span={22} className={styles.normal}>
							To the extent that the legal basis for our
							processing of your personal information is consent,
							you have the right to withdraw that consent at any
							time. Withdrawal will not affect the lawfulness of
							processing before the withdrawal.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							7.13
						</Col>
						<Col span={22} className={styles.normal}>
							You may request that we provide you with any
							personal information we hold about you. Provision of
							this information will be subject to:
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(a)
						</Col>
						<Col span={22} className={styles.normal}>
							the payment of a fee (currently fixed at (
							<strong>GBP</strong>) £10.00); and
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(b)
						</Col>
						<Col span={22} className={styles.normal}>
							the supply of appropriate evidence of your identity
							(for this purpose, we will usually accept a
							photocopy of your passport certified by a solicitor
							or bank plus an original copy of a utility bill
							showing your current address).
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.bold}>
							8
						</Col>
						<Col span={22} className={styles.bold}>
							About cookies
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							8.1
						</Col>
						<Col span={22} className={styles.normal}>
							A cookie is a small file containing an identifier (a
							string of letters and numbers) that is sent by a web
							server to a web browser asking permission to be
							placed on your computer's hard drive. The file is
							added and the cookie helps analyze web traffic or
							lets you know when you visit a particular site.
							Cookies allow web applications to respond to you as
							an individual. The web application can tailor its
							operations to your needs, likes and dislikes by
							gathering and remembering information about your
							preferences.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							8.2
						</Col>
						<Col span={22} className={styles.normal}>
							Cookies may be either <strong>"persistent"</strong>{" "}
							cookies or <strong>"session"</strong> cookies: a
							persistent cookie will be stored by a web browser
							and will remain valid until its set expiry date,
							unless deleted by the user before the expiry date; a
							session cookie, on the other hand, will expire at
							the end of the user session, when the web browser is
							closed.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							8.3
						</Col>
						<Col span={22} className={styles.normal}>
							Cookies do not typically contain any information
							that personally identifies a user, but personal
							information that we store about you may be linked to
							the information stored in and obtained from cookies.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.bold}>
							9
						</Col>
						<Col span={22} className={styles.bold}>
							Cookies that we use
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							9.1
						</Col>
						<Col span={22} className={styles.normal}>
							We use traffic log cookies to identify which pages
							are being used. This helps us analyse data about web
							page traffic and mobile app use improve our services
							in order to tailor them to customer needs. We only
							use this information for statistical analysis
							purposes and then the data is removed from the
							system.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							9.2
						</Col>
						<Col span={22} className={styles.normal}>
							Overall, cookies help us provide you with a better
							experience, by enabling us to monitor which pages
							you find useful and which you do not. A cookie in no
							way gives us access to your computer or any
							information about you, other than the data you
							choose to share with us.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							9.3
						</Col>
						<Col span={22} className={styles.normal}>
							You can choose to accept or decline cookies. Most
							web browsers automatically accept cookies, but you
							can usually modify your browser setting to decline
							cookies if you prefer. This may prevent you from
							taking full advantage of our services.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							9.4
						</Col>
						<Col span={22} className={styles.normal}>
							We may use Google Analytics to analyse the use of
							our website. Google Analytics gathers information
							about website use by means of cookies. The
							information gathered relating to our website is used
							to create reports about the use of our website.
							Google's privacy policy can be found at the
							following web address:
							<a href="https://www.google.com/policies/privacy/">
								https://www.google.com/policies/privacy/
							</a>
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.bold}>
							10
						</Col>
						<Col span={22} className={styles.bold}>
							Our details
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							10.1
						</Col>
						<Col span={22} className={styles.normal}>
							This website and mobile app is owned and operated by
							Ugenie Ltd.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							10.2
						</Col>
						<Col span={22} className={styles.normal}>
							We are registered in England and Wales under
							registration number 09966790, and our registered
							office is at 1 Langford Close, London, Ealing
							(London Borough), United Kingdom, W3 8JZ
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							10.3
						</Col>
						<Col span={22} className={styles.normal}>
							Our principal place of business is at the above
							address.
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							10.4
						</Col>
						<Col span={22} className={styles.normal}>
							You can contact us:
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(a)
						</Col>
						<Col span={22} className={styles.normal}>
							by post, to the postal address given above;
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(b)
						</Col>
						<Col span={22} className={styles.normal}>
							by telephone, on the contact number published on our
							website or mobile app from time to time; or
						</Col>
					</Row>
					<Row className={styles.row}>
						<Col span={2} className={styles.normal}>
							(c)
						</Col>
						<Col span={22} className={styles.normal}>
							by email, using the email address published on our
							website or mobile app from time to time.
						</Col>
					</Row>
				</>
			)}
		</div>
	);
};

export default PrivacyPolicy;
