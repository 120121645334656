import { call, put, all, select, takeLatest } from "redux-saga/effects";

import {
    ECommunitiesActionTypes,
    ECommunityAccessStatus,
    EProcessToJoinCommunityStage,
    ICommunitiesGetAction,
    ICommunitiesState,
    IMetaData,
    IPost,
    ISelectedCommunityToJoin,
    ITags,
    TStore,
} from "models";
import {
    api,
    setCommunitiesLoading,
    setCommunities,
    setSelectedCommunityToJoinDetails,
    setDeepLinkedSignUp,
    joinCommunity,
    storeTagsAndMemberType,
    getSelectedCommunityToJoinAccessStatus,
    setCommunityAccessStatus,
    leaveCommunity,
    getCommunities,
    updateJoinedAndApprovedStatusForCommunityAction,
    updateJoinedAndApprovedStatusForJoinedCommunityAction,
    history,
} from "store";
import {
    appChangeCommunity,
    getCommunitiesInvitations,
    initSelectedCommunityToJoinProgressBar,
    setAppLoading,
    setCommunitiesInvitations,
    setCommunityInterests,
    startTheJoinCommunityProcessAction,
} from "store/actions";
import { rollbar, isItAWhiteLabel } from "utils";

function* getInvitationsHandle({
    payload,
}: ReturnType<typeof getCommunitiesInvitations>) {
    const { onErrorCallback, onSuccessCallback } = payload;
    try {
        const {
            data,
        }: {
            data: {
                communities: ICommunitiesState["data"];
                meta: ICommunitiesState["meta"];
            };
        } = yield call(api.communities.get, payload);
        if (!data) return;
        yield put(
            setCommunitiesInvitations({
                meta: data.meta,
            }),
        );

        if (onSuccessCallback) {
            onSuccessCallback();
        }
    } catch (error) {
        if (onErrorCallback) {
            onErrorCallback();
        }
        rollbar.error(error)
        console.error({ error });
    }
}
function* getCommunitiesHandle({
    q,
    onErrorCallback,
    onSuccessCallback,
}: ReturnType<typeof getCommunities>) {
    yield put(setCommunitiesLoading(true));
    try {
        const payload = { page: 1, q };
        const { data } = yield call(api.communities.get, payload);
        if (!data) {
            yield put(setCommunitiesLoading(false));
        } else {
            yield put(
                setCommunities({
                    ...data,
                    init: true,
                }),
            );
            yield put(setCommunitiesLoading(false));
        }
        if (onSuccessCallback) {
            onSuccessCallback();
        }
    } catch (error) {
        if (onErrorCallback) {
            onErrorCallback();
        }
        rollbar.error(error)
        console.error({ error });
        yield put(setCommunitiesLoading(false));
    }
}

const getCommunitiesPagination = (state: TStore) =>
    state.communities.meta?.pagination;

function* getMoreCommunitiesHandle({
    q = "",
    slug = "",
}: ICommunitiesGetAction) {
    try {
        const pagination = yield select(getCommunitiesPagination);
        const { data } = yield call(api.communities.get, {
            page: parseInt(pagination.current_page) + 1,
            q,
            slug,
        });
        yield put(setCommunities({ ...data }));
    } catch (error) {
        rollbar.error(error)
        console.error({ error });
    }
}

function* joinCommunityHandle({ payload }: ReturnType<typeof joinCommunity>) {
    const { id, onErrorCallback, onSuccessCallback } = payload;
    yield put(setCommunitiesLoading(true));
    try {
        if (!id) throw Error("Missing params!");
        const {
            data,
        }: {
            data: {
                tags: ITags;
                meta: {
                    community: ISelectedCommunityToJoin;
                    appearance: {
                        id: number,
                        skill_tag_display_name: string
                    }
                    [key: string]: Partial<ISelectedCommunityToJoin>;
                };
            };
        } = yield call(api.community.getOneJoin, { community_id: id });
        if (!data) {
            yield put(setCommunitiesLoading(false));
        } else {

            yield put(
                setSelectedCommunityToJoinDetails({
                    data,
                    communityId: id as number,
                }),
            );
            const usesStripe = data.meta.community.uses_stripe;

            const processToJoinStage = usesStripe
                ? EProcessToJoinCommunityStage["member plans"]
                : EProcessToJoinCommunityStage.welcome;
            yield put(
                initSelectedCommunityToJoinProgressBar({
                    isConnectedCommunity: usesStripe ?? false,
                    hasGroups: data.meta.community
                        ? Boolean(Number(data.meta.community?.group_count))
                        : false,
                }),
            );
            yield put(startTheJoinCommunityProcessAction(true));
            // the redirection should be the last line
            if (isItAWhiteLabel()) {
                const {
                    access_status,
                    approved,
                    joined,
                    selected_member_plan,
                    id,
                    uses_stripe
                } = data.meta.community;

                if (joined) {
                    if (approved) {
                        // * If the plan is not longer available we must redirect the user to choose another one
                        if (!selected_member_plan?.price && uses_stripe) {
                            yield history.push(`/joining/${id}/${processToJoinStage}`);
                            return yield put(setAppLoading(false));
                        }

                        if (access_status === ECommunityAccessStatus.granted) {
                            yield put(
                                appChangeCommunity({
                                    communityId: id,
                                }),
                            );
                        } else if (
                            !selected_member_plan?.free &&
                            access_status ===
                            ECommunityAccessStatus.subscription_inactive
                        ) {
                            // resume the payment process
                            yield history.push(
                                `/joining/${id}/${EProcessToJoinCommunityStage["resume payment process"]}`,
                            );
                            yield put(setAppLoading(false));
                        } else {
                            yield history.push(
                                `/joining/${id}/${EProcessToJoinCommunityStage["pending status"]}`,
                            );
                            yield put(setAppLoading(false));
                        }
                    } else {

                        yield history.push(
                            `/joining/${id}/${EProcessToJoinCommunityStage["pending status"]}`,
                        );
                        yield put(setAppLoading(false));
                    }
                } else {
                    yield history.push(`/joining/${id}/${processToJoinStage}`);
                    yield put(setAppLoading(false));
                }
            } else {
                yield history.push(`/communities/${id}/${processToJoinStage}`);
                yield put(setAppLoading(false));
            }
            onSuccessCallback?.();
        }
    } catch (error) {
        console.error({ error });
        yield put(setCommunitiesLoading(false));
        onErrorCallback?.();
    }
    yield put({ type: ECommunitiesActionTypes.joinCommunityCompleted });
}

function* leaveCommunityHandle({ payload }: ReturnType<typeof leaveCommunity>) {
    const { communityId, onErrorCallback, onSuccessCallback } = payload;
    yield put(setCommunitiesLoading(true));
    try {
        yield call(api.community.leave, {
            community_id: communityId,
        });

        yield put(
            updateJoinedAndApprovedStatusForCommunityAction({
                communityId,
                joined: false,
                approved: false,
            }),
        );
        yield put(
            updateJoinedAndApprovedStatusForJoinedCommunityAction({
                communityId,
                joined: false,
                approved: false,
            }),
        );
        yield put(setCommunitiesLoading(false));
        if (onSuccessCallback) {
            onSuccessCallback();
        }
    } catch (error) {
        if (onErrorCallback) {
            onErrorCallback();
        }
        rollbar.error(error)
        console.error({ error });
        yield put(setCommunitiesLoading(false));
    }
}

function* storeTagsAndMemberTypeHandle({
    payload,
}: ReturnType<typeof storeTagsAndMemberType>) {
    const {
        communityId,
        tags,
        skill_tags,
        membershipType,
        stripePlanPriceId,
        successCallback,
        errorCallback,
    } = payload;

    yield put(setCommunitiesLoading(true));
    try {
        if (!communityId || !membershipType) {
            throw new Error("Parameter missing or invalid!");
        }

        // Create membership in community
        const { data } = yield call(api.community.setMembership, {
            communityId,
            tags,
            skill_tags,
            membership_type: membershipType,
            stripe_plan_price_id: stripePlanPriceId,
        });
        if (!data) {
            yield put(setCommunitiesLoading(false));
        } else {
            yield put(setDeepLinkedSignUp(null));
        }
        if (successCallback) {
            successCallback();
        }
    } catch (error: any) {
        if (error?.response?.data?.meta) {
            if (
                Object.keys(error.response.data.meta).indexOf("is_approved") !==
                -1 &&
                !error.response.data.meta.is_approved
            ) {
                yield put(setDeepLinkedSignUp(null));
            }
        }
        if (errorCallback) {
            errorCallback();
        }
        rollbar.error(error)
        console.error({ error });
        yield put(setCommunitiesLoading(false));
    }
}
function* setCommunityInterestsHandle({
    payload,
}: ReturnType<typeof setCommunityInterests>) {
    const { communityId, errorCallback, successCallback } = payload;
    try {
        if (!communityId) {
            throw new Error("Parameter missing!");
        }
        yield call(api.community.setCommunityInterests, {
            community_id: communityId,
        });

        successCallback();
    } catch (error: any) {
        rollbar.error(error)
        if (error?.response?.data?.meta) {
            if (
                Object.keys(error.response.data.meta).indexOf("is_approved") !==
                -1 &&
                !error.response.data.meta.is_approved
            ) {
                yield put(setDeepLinkedSignUp(null));
            }
        }
        errorCallback();
        console.error({ error });
    }
}

function* getCommunityAccessStatusHandle({
    payload,
}: ReturnType<typeof getSelectedCommunityToJoinAccessStatus>) {
    const { communityId, successCallback, errorCallback } = payload;

    try {
        if (!communityId) {
            throw new Error("Parameter missing or invalid!");
        }
        const {
            data,
        }: {
            data: { posts: IPost[]; meta: IMetaData };
        } = yield call(api.community.home, {
            community_id: communityId,
        });

        yield put(
            setCommunityAccessStatus({
                accessStatus: data?.meta?.access_status ?? null,
            }),
        );
        if (successCallback) {
            successCallback();
        }
    } catch (error: any) {
        // Because of the way the backend team has implemented this endpoint, the access status can be send through error path.

        rollbar.error(error)
        if (error?.response?.data?.meta?.access_status) {
            yield put(
                setCommunityAccessStatus({
                    accessStatus: error.response.data.meta.access_status,
                }),
            );
            successCallback?.();
        } else {
            errorCallback?.();
        }
    }
}

export default function* rootSaga() {
    yield all([
        takeLatest(
            ECommunitiesActionTypes.getCommunities,
            getCommunitiesHandle,
        ),
        takeLatest(
            ECommunitiesActionTypes.getMoreCommunities,
            getMoreCommunitiesHandle,
        ),
        takeLatest(ECommunitiesActionTypes.joinCommunity, joinCommunityHandle),
        takeLatest(
            ECommunitiesActionTypes.leaveCommunity,
            leaveCommunityHandle,
        ),
        takeLatest(
            ECommunitiesActionTypes.storeTagsAndMemberType,
            storeTagsAndMemberTypeHandle,
        ),
        takeLatest(
            ECommunitiesActionTypes.setCommunityInterests,
            setCommunityInterestsHandle,
        ),
        takeLatest(
            ECommunitiesActionTypes.getSelectedCommunityToJoinAccessStatus,
            getCommunityAccessStatusHandle,
        ),
        takeLatest(
            ECommunitiesActionTypes.getCommunitiesInvitations,
            getInvitationsHandle,
        ),
    ]);
}
