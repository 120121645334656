import React, {
	memo,
	useCallback,
	useMemo,
	useRef,
	useEffect,
	useState,
} from "react";
import { Form, Button, Input, Row, Col } from "antd";
import { YoutubeOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { DatePicker } from "antd";
import { MentionsInput, Mention, SuggestionDataItem } from "react-mentions";
import classNames from "classnames";
import moment from "moment";

import styles from "./styles.module.scss";
import { IPostState, TStore } from "models";
import { CheckboxGroupView } from "components";
import { addPost, setPost } from "store";
import { disabledDate, parseContentWithMentions, rollbar } from "utils";
import UploadImg from "./uploadImg";
import EnablePostForm from "./enablePostForm";

const TITLE_MAX_NO_OF_CHARACTERS = 50;
const DESCRIPTION_MAX_NO_OF_CHARACTERS = 5000;

const stateSelectorHandle = createSelector(
	(state: TStore) => state.community.data,
	(state: TStore) => state.community.mainTags,
	(state: TStore) => state.groups.data,
	(state: TStore) => state.members.users,
	(state: TStore) => state.post.data,
	(state: TStore) => state.app.currentGroupId,
	(state: TStore) => state.auth?.credentials?.user?.membership_type,
	(
		community,
		communityTags,
		groups,
		members,
		post,
		currentGroupId,
		authUserMembership,
	) => ({
		community,
		communityTags,
		groups: groups.filter((group) => group.is_joined),
		members,
		post,
		currentGroupId,
		authUserMembership,
	}),
);

interface IFormBox {
	hideEnablePostFormComponent?: boolean;
	uniqueKey: string;
	editPostData?: IPostState["data"];
	hidePostForm?: () => void;
}

const PostForm: React.FunctionComponent<IFormBox> = ({
	hideEnablePostFormComponent = false,
	hidePostForm,
	uniqueKey,
	editPostData,
}) => {
	const dispatch = useDispatch();
	const contentRef = useRef<HTMLDivElement | null>(null);
	const [showPostForm, setShowPostForm] = useState(
		hideEnablePostFormComponent,
	);
	const [
		descriptionNoOfCharactersRemaining,
		setDescriptionNoOfCharactersRemaining,
	] = useState(DESCRIPTION_MAX_NO_OF_CHARACTERS);
	const [titleNoOfCharactersRemaining, setTitleNoOfCharactersRemaining] =
		useState(TITLE_MAX_NO_OF_CHARACTERS);
	const [editPostDeleteImageId, setEditPostDeleteImageId] =
		useState(undefined);
	const suggestionsPortalHostRef = useRef<any>();
	const [editedPostImage, setEditedPostImage] = useState(undefined);
	const [form] = Form.useForm();
	const watch = Form.useWatch("audienceCheckboxGroup", form);
	const watchLink = Form.useWatch("link", form);
	const watchImage = Form.useWatch("image", form);

	const stateSelector = useCallback(stateSelectorHandle, []);
	const {
		community,
		communityTags,
		groups,
		members,
		currentGroupId,
		authUserMembership,
	} = useSelector(stateSelector);

	const users = useMemo(() => {
		return members?.map((item) => {
			return { id: item.id, display: item.full_name };
		});
	}, [members]);

	const handleShowPostForm = useCallback(() => {
		setShowPostForm(true);
	}, []);

	const handleHidePostForm = useCallback(() => {
		setShowPostForm(false);
		hidePostForm && hidePostForm();
		dispatch(setPost({}));
		setTitleNoOfCharactersRemaining(TITLE_MAX_NO_OF_CHARACTERS);
		setDescriptionNoOfCharactersRemaining(DESCRIPTION_MAX_NO_OF_CHARACTERS);
		form.resetFields();
	}, [dispatch, form, hidePostForm]);

	const onFinish = useCallback(
		(values) => {
			try {
				if (community?.id) {
					const {
						title,
						details,
						link,
						audienceCheckboxGroup,
						groupsCheckboxGroup,
						tagsCheckboxGroup,
						expiryDate,
						image,
					} = values;

					const { source } = parseContentWithMentions({
						content: details,
						typeOfConversion: "fromInputToSystem",
					});
					//parse values
					let payload = {
						community_id: community.id, // TODO check this value on refresh (after logout)
						community_post: {
							title: title,
							description: source,
							pin_type: 0, // community post
							video_link: link,
							group_ids: currentGroupId
								? [currentGroupId]
								: groupsCheckboxGroup, // integers ids
							tag_list: tagsCheckboxGroup, // string value for example: "Accounting"
							member_type_list: audienceCheckboxGroup, // string value for example: "Accounting"
							expiration_date: expiryDate,
							image: image ? image[0]?.originFileObj : null,
							all_groups: currentGroupId
								? false
								: !groupsCheckboxGroup ||
								  groupsCheckboxGroup?.length === 0
								? true
								: false,
						},
						communityPostId: null as null | number,
						delete_images: editPostDeleteImageId
							? [editPostDeleteImageId]
							: [],
					};
					if (editPostData?.community_post) {
						if (image)
							payload.delete_images =
								editPostData.community_post.images.map(
									(image: any) => image.id,
								);
						payload.communityPostId =
							editPostData.community_post.id;
					}
					dispatch(addPost(payload));
					handleHidePostForm();
				} else {
					throw Error("Missing community data.");
				}
			} catch (error) {
				rollbar.error(error);
				console.error({ error });
			}
		},
		[
			editPostDeleteImageId,
			community?.id,
			currentGroupId,
			dispatch,
			handleHidePostForm,
			editPostData?.community_post,
		],
	);

	const onValuesChange = useCallback(
		(changedValues) => {
			switch (Object.keys(changedValues)[0]) {
				case "image": {
					if (changedValues.image.length > 1)
						form.setFieldsValue({
							image: [
								changedValues.image[
									changedValues.image.length - 1
								],
							],
						});
					break;
				}
				case "audienceCheckAll": {
					form.setFieldsValue({
						audienceCheckboxGroup: communityTags.memberTypes.map(
							(item: any) => item.name,
						),
					});
					break;
				}
				case "audienceCheckNone": {
					form.setFieldsValue({
						audienceCheckboxGroup: [authUserMembership],
					});
					break;
				}
				case "groupsCheckAll": {
					form.setFieldsValue({
						groupsCheckboxGroup: groups.map((item: any) => item.id),
					});

					break;
				}
				case "groupsCheckNone": {
					form.setFieldsValue({
						groupsCheckboxGroup: [],
					});

					break;
				}
				case "audienceCheckboxGroup": {
					if (
						changedValues.audienceCheckboxGroup.length ===
						communityTags.memberTypes.length
					) {
						form.setFieldsValue({
							audienceCheckAll: true,
						});
					} else {
						form.setFieldsValue({
							audienceCheckAll: false,
						});
					}
					break;
				}
				case "groupsCheckboxGroup": {
					if (
						changedValues.groupsCheckboxGroup.length ===
						groups.length
					) {
						form.setFieldsValue({
							groupsCheckAll: true,
						});
					} else {
						form.setFieldsValue({
							groupsCheckAll: false,
						});
					}

					break;
				}
				case "expiryDate": {
					if (moment.isMoment(changedValues.expiryDate)) {
						form.setFieldsValue({
							expiryDate: changedValues.expiryDate.endOf("day"),
						});
					} else {
						form.setFieldsValue({
							expiryDate: undefined,
						});
					}
					break;
				}
				default:
			}
		},
		[form, communityTags.memberTypes, authUserMembership, groups],
	);

	const handleRenderSuggestions = useCallback(
		(
			suggestion: SuggestionDataItem,
			search: string,
			highlightedDisplay: React.ReactNode,
			index: number,
			focused: boolean,
		) => {
			return (
				<div
					key={`key_${index}`}
					className={classNames(styles.listItem, {
						[styles.listItemFocused]: focused,
					})}
				>
					{highlightedDisplay}
				</div>
			);
		},
		[],
	);

	const handleChangePostBody = useCallback(
		(event: { target: { value: string } } | undefined) => {
			if (!event) return;
			let parsedBody = event.target.value;
			if (event.target.value.length > 5000) {
				parsedBody = event.target.value.substring(0, 5000);
			}
			setDescriptionNoOfCharactersRemaining(5000 - parsedBody.length);

			form.setFieldsValue({
				details: parsedBody,
			});
		},
		[form],
	);

	const handleChangePostTitle = useCallback(
		(event: React.ChangeEvent<HTMLInputElement> | undefined) => {
			if (!event) return null;
			setTitleNoOfCharactersRemaining(50 - event.target.value.length);
			form.setFieldsValue({
				title: event.target.value, // source
			});
		},
		[form],
	);

	const initialValues = useMemo(
		() => ({
			audienceCheckAll: false,
			groupsCheckboxGroup: [],
			details: "",
		}),
		[],
	);

	const handleDeletePostImage = () => {
		if (!editPostData?.community_post?.images) return;
		setEditPostDeleteImageId(editPostData.community_post.images[0].id);
		setEditedPostImage(undefined);
	};

	useEffect(() => {
		if (!showPostForm) return;
		if (!form) return;

		if (editPostData?.community_post) {
			const { community_post, member_types, groups, tags } = editPostData;
			const { title, video_link, expiration_date, images } =
				community_post;
			const { source } = parseContentWithMentions({
				content: editPostData.community_post.description,
				typeOfConversion: "fromSystemToInput",
			});

			if (contentRef.current)
				contentRef.current.scrollIntoView({
					behavior: "smooth",
				});

			form.setFieldsValue({
				title,
				details: source,
				link: video_link,
				audienceCheckboxGroup: member_types
					? member_types
							.filter(
								(member_type: any) => member_type.is_selected,
							)
							.map((member_type: any) => member_type.name)
					: [],
				groupsCheckboxGroup: groups
					? groups
							.filter((group: any) => group.is_selected)
							.map((group: any) => group.id)
					: [],
				tagsCheckboxGroup: tags
					? tags
							.filter((tag: any) => tag.is_selected)
							.map((tag: any) => tag.name)
					: [],
				expiryDate: expiration_date ? moment(expiration_date) : null,
			});
			setTitleNoOfCharactersRemaining(
				TITLE_MAX_NO_OF_CHARACTERS - title.length,
			);
			setDescriptionNoOfCharactersRemaining(
				DESCRIPTION_MAX_NO_OF_CHARACTERS - source.length,
			);
			setEditedPostImage(images[0] ? images[0].image : undefined);
		} else {
			setTitleNoOfCharactersRemaining(TITLE_MAX_NO_OF_CHARACTERS);
			setDescriptionNoOfCharactersRemaining(
				DESCRIPTION_MAX_NO_OF_CHARACTERS,
			);
			form.resetFields();
			form.setFieldValue("audienceCheckboxGroup", [authUserMembership]);
		}
	}, [form, editPostData, authUserMembership, showPostForm]);

	return (
		<>
			{!hideEnablePostFormComponent && (
				<div
					key={`enable-post-form-${uniqueKey}`}
					style={showPostForm ? { display: "none" } : undefined}
				>
					<EnablePostForm handleShowPostForm={handleShowPostForm} />
				</div>
			)}
			<div
				key={`post-form-${uniqueKey}`}
				className={styles.form}
				style={
					!showPostForm && !hideEnablePostFormComponent
						? { display: "none" }
						: undefined
				}
				ref={contentRef}
			>
				<Form
					name={`${uniqueKey}-post-form`}
					form={form}
					layout="vertical"
					onFinish={onFinish}
					initialValues={initialValues}
					onValuesChange={onValuesChange}
				>
					<div className={styles.headerFormBox}>
						<div className={styles.formTitle}>{"Create Post"}</div>
						<div>
							<Button
								className={styles.cancelButton}
								type={"ghost"}
								onClick={handleHidePostForm}
							>
								{"Cancel"}
							</Button>
						</div>
					</div>
					<Row>
						<Col span={24}>
							<Form.Item
								name="title"
								rules={[
									{
										required: true,
										message: "Post title is required",
									},
								]}
							>
								<Input
									onChange={handleChangePostTitle}
									size="middle"
									placeholder={"Add your title here..."}
									maxLength={50}
								/>
							</Form.Item>
							<div className={styles.noOfCharactersRemaining}>
								{!(titleNoOfCharactersRemaining === 0)
									? `${titleNoOfCharactersRemaining} ${
											titleNoOfCharactersRemaining > 1
												? "characters"
												: "character"
									  }  remaining`
									: "Limit reached"}
							</div>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Form.Item
								name="details"
								rules={[
									{
										required: true,
										message: "Post description is required",
									},
								]}
							>
								<MentionsInput
									onChange={handleChangePostBody}
									placeholder={
										"Posts with detailed descriptions get more views and replies."
									}
									className={"detailsInput"}
									classNames={styles}
									maxLength={5000}
									suggestionsPortalHost={
										suggestionsPortalHostRef.current
									}
									style={{
										suggestions: {
											list: {
												maxHeight: 300,
												overflowY: "auto",
											},
										},
									}}
								>
									<Mention
										trigger="@"
										data={users ? users : []}
										className={styles.mention}
										renderSuggestion={
											handleRenderSuggestions
										}
										appendSpaceOnAdd={true}
										displayTransform={(_, display: any) =>
											`@${display}`
										}
									/>
								</MentionsInput>
							</Form.Item>
							<div
								ref={suggestionsPortalHostRef}
								className={styles.divPositionRelativeWrapper}
							/>
							<div className={styles.noOfCharactersRemaining}>
								{!(descriptionNoOfCharactersRemaining === 0)
									? `${descriptionNoOfCharactersRemaining} ${
											descriptionNoOfCharactersRemaining >
											1
												? "characters"
												: "character"
									  }  remaining`
									: "Limit reached"}
							</div>
						</Col>
					</Row>
					{(!watchImage || watchImage.length < 1) && (
						<Row>
							<Col span={24}>
								<Form.Item
									name="link"
									rules={[
										{
											pattern:
												// eslint-disable-next-line no-useless-escape
												/^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be|vimeo\.com\/))(\/(?:[\w\-]+\?v=|embed\/|[0-9]+|v\/)?)?([\w\-]+)(\S+)?$/gim,
											message:
												"Invalid URL provided, please insert a YouTube or a Vimeo link.",
										},
									]}
								>
									<Input
										className={styles.link}
										size="middle"
										prefix={<YoutubeOutlined />}
										placeholder={
											"Add YouTube or Vimeo link to upload a video"
										}
									/>
								</Form.Item>
							</Col>
						</Row>
					)}
					{!watchLink && (
						<Row>
							<Col span={24}>
								{editedPostImage && (
									<div style={{ position: "relative" }}>
										<div
											className={
												styles.absolutePositionDiv
											}
											onClick={handleDeletePostImage}
										>
											{"Delete"}
										</div>
										<img
											alt=""
											src={editedPostImage}
											style={{
												width: "100%",
												marginBottom: 15,
												maxHeight: "500px",
												objectFit: "contain",
											}}
										/>
									</div>
								)}
								<div>
									<UploadImg />
								</div>
							</Col>
						</Row>
					)}
					<Row>
						<Col span={24}>
							{
								<>
									{communityTags.memberTypes &&
										communityTags.memberTypes.length >
											0 && (
											<>
												<div className={styles.tags}>
													<CheckboxGroupView
														rules={[
															{
																required: true,
																message:
																	"'Who should see this post?' is required",
															},
														]}
														title={
															"Who should see this post?"
														}
														checkAllTitle={
															"All Members"
														}
														category="audience"
														options={communityTags.memberTypes.map(
															(item: any) =>
																item.name,
														)}
														checkedValues={watch}
														disabled={
															authUserMembership
														}
														setValue={
															form.setFieldValue
														}
														key={"audience"}
													/>
												</div>
											</>
										)}

									{!currentGroupId &&
										groups &&
										groups.length > 0 && (
											<>
												<div className={styles.tags}>
													<CheckboxGroupView
														title={"GROUPS"}
														checkAllTitle={
															"All Groups"
														}
														category="groups"
														options={groups.map(
															(item: any) => ({
																label: item.name,
																value: item.id,
															}),
														)}
														key={"groups"}
													/>
												</div>
											</>
										)}
									{communityTags.tags &&
										communityTags.tags.length > 0 && (
											<>
												<div className={styles.tags}>
													<CheckboxGroupView
														rules={[
															() => ({
																validator(
																	rule: any,
																	value: any,
																) {
																	if (
																		value &&
																		value.length >=
																			1 &&
																		value.length <=
																			3
																	) {
																		return Promise.resolve();
																	} else {
																		return Promise.reject(
																			"Please choose at least one tag and three tags at most!",
																		);
																	}
																},
															}),
														]}
														checkAllTitle={""}
														title={
															"What is this post about? (Select up to 3 tags that best describe this post)."
														}
														category="tags"
														key={"tags"}
														options={(
															communityTags.tags as {
																id: number;
																name: string;
																is_selected: boolean;
															}[]
														).map(
															(item: any) =>
																item.name,
														)}
													/>
												</div>
											</>
										)}
								</>
							}
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Form.Item
								label="When will this post expire?"
								name="expiryDate"
							>
								<DatePicker
									className={styles.datePicker}
									disabledDate={disabledDate}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col span={24} className={styles.submitButton}>
							<Form.Item style={{ width: "100%" }}>
								<Button
									onClick={handleHidePostForm}
									type="default"
									htmlType="button"
									className={classNames(styles.cancelButton)}
									style={{ marginRight: "auto" }}
								>
									{"Cancel"}
								</Button>
								<Button
									type="primary"
									htmlType="submit"
									className={styles.right}
									style={{
										marginLeft:
											!editPostData?.community_post
												? "auto"
												: undefined,
									}}
								>
									{"Post"}
								</Button>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</div>
		</>
	);
};

export default memo(PostForm);
