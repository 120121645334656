import React, { memo } from "react";
import { Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { Form } from "antd";

import styles from "./styles.module.scss";

const UploadImg: React.FunctionComponent<any> = ({ showUploadBtn }) => {
	const normFile = (e: any) => {
		if (Array.isArray(e)) {
			return e;
		}
		return e && e.fileList;
	};
	const dummyRequest = ({ file, onSuccess }: any) => {
		// // Now our file’s data can be passed to the Form without throwing an action error.
	};

	return (
		<Form.Item
			name="image"
			valuePropName="fileList"
			getValueFromEvent={normFile}
			noStyle
		>
			<Upload.Dragger
				name="files"
				listType="picture"
				accept=".png,.jpg,.jpeg"
				showUploadList={true}
				customRequest={dummyRequest}
				className={styles.uploadButton}
				multiple={false}
				// onRemove={(file) => {
				//   setFileList([]);
				// }}
				beforeUpload={(file: any) => {
					// Upload files manually after beforeUpload returns false.
					return false;
				}}
				// fileList={fileList}
			>
				<p className="ant-upload-drag-icon">
					<InboxOutlined />
				</p>
				<p className="ant-upload-text">
					Click or drag file to this area to upload
				</p>
				<p className="ant-upload-hint">Supports one Jpeg or PNG.</p>
				{/* <Button>
          <UploadOutlined /> Upload
        </Button> */}
			</Upload.Dragger>
		</Form.Item>
	);
};

export default memo(UploadImg);
