import { call, put, all, takeEvery, select } from "redux-saga/effects";

import {
    ECommunitiesJoinedActionTypes,
    TStore,
    ICommunitiesJoinedGetAction,
} from "models";
import {
    api,
    setCommunitiesJoinedLoading,
    setCommunitiesJoined,
    getCommunitiesJoined,
} from "store";
import { rollbar } from "utils";

export const checkForCommunityApprovalStatus = async (
    data: any,
): Promise<any> => {
    let joinedCommunitesData = data;

    for (let i = 0; i < joinedCommunitesData.communities.length; i++) {
        try {
            if (joinedCommunitesData.communities[i].privacy === "open") {
                continue;
            }
            await api.community
                .home({ community_id: joinedCommunitesData.communities[i].id })
                .then(
                    (homeData: {
                        data: { meta: { is_approved: boolean } };
                    }) => {
                        const isApproved = homeData.data.meta.is_approved;
                        joinedCommunitesData.communities[i] = {
                            ...joinedCommunitesData.communities[i],
                            isApproved,
                        };
                    },
                )
                .catch((error) => {
                    console.error(error);
                });
        } catch (error) {
            rollbar.error(error)
            continue;
        }
    }
    return joinedCommunitesData;
};

function* getCommunitiesJoinedHandle({
    q,
    slug,
    successCallback,
    errorCallback,
}: ReturnType<typeof getCommunitiesJoined>) {
    try {
        const payload = { page: 1, q };
        yield put(setCommunitiesJoinedLoading(true));
        const { data } = yield call(api.communitiesJoined.get, payload);

        if (!data) {
            if (errorCallback) {
                errorCallback();
            }
            yield put(setCommunitiesJoinedLoading(false));
        } else {
            // filter all communities and check for approval status
            const joinedCommunitesData = yield call(
                checkForCommunityApprovalStatus,
                data,
            );

            yield put(
                setCommunitiesJoined({
                    ...joinedCommunitesData,
                    init: true,
                }),
            );
            yield put(setCommunitiesJoinedLoading(false));
            if (successCallback) {
                successCallback();
            }
        }
    } catch (error) {
        rollbar.error(error)
        if (errorCallback) {
            errorCallback();
        }
        console.error({ error });
        yield put(setCommunitiesJoinedLoading(false));
    }
}

const getCommunitiesJoinedPagination = (state: TStore) =>
    state.communitiesJoined.meta?.pagination;

function* getMoreCommunitiesJoinedHandle({
    q,
    slug,
}: ICommunitiesJoinedGetAction) {
    try {
        const pagination = yield select(getCommunitiesJoinedPagination);
        const { data } = yield call(api.communities.get, {
            page: parseInt(pagination.current_page) + 1,
            q,
            slug,
        });
        const joinedCommunitesData = yield call(
            checkForCommunityApprovalStatus,
            data,
        );
        yield put(setCommunitiesJoined(joinedCommunitesData));
    } catch (error) {
        rollbar.error(error)
        console.error({ error });
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(
            ECommunitiesJoinedActionTypes.getMoreCommunitiesJoined,
            getMoreCommunitiesJoinedHandle,
        ),
        takeEvery(
            ECommunitiesJoinedActionTypes.getCommunitiesJoined,
            getCommunitiesJoinedHandle,
        ),
    ]);
}
