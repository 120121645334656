import React, { memo } from "react";

import styles from "./styles.module.scss";
import { ReactComponent as Info } from "assets/images/icons/menu_icons/info-icon.svg";
import { Link } from "react-router-dom";

const SiderBottomInfo = ({ absoluteSideBar }: any) => {
	return (
		<>
			{absoluteSideBar ? (
				<div className={styles.absoluteBottomInfo}>
					<Link to='/community/about-us' className={styles.info}>
						<Info />
					</Link>
					<div className={styles.siderBottomInfo}>
						<div className={styles.title}>
							{"Powered by "}
							<a
								href="https://ugenie.io"
								target="_blank"
								className={styles.logo}
								rel="noopener noreferrer"
							>
								{"Ugenie"}
							</a>
						</div>
					</div>
				</div>)
				: (<div className={styles.footerWrapper}>

					<Link to='/community/about-us' className={styles.aboutUsWrapper}

					>
						<Info />
						<span>
							About Us
						</span>
					</Link>
					<div className={styles.siderBottomInfo}>
						<div className={styles.title}>
							{"This UHub is powered by "}
							<a
								href="https://ugenie.io"
								target="_blank"
								className={styles.logo}
								rel="noopener noreferrer"
							>
								{"Ugenie"}
							</a>
						</div>
					</div>
				</div>)}
		</>
	);
};

export default memo(SiderBottomInfo);
