import {
    ICommunitiesState,
    ECommunitiesActionTypes,
    TCommunitiesActions,
    ISelectedCommunityToJoin,
    EJoinCommunityOnboardingSteps,
} from "models";

const initial: ICommunitiesState = {
    loading: false,
    data: undefined,
    meta: {
        invites: undefined,
        pagination: {
            current_page: 1,
            per_page: 10,
            has_next_page: false,
            total_objects: 0,
            total_pages: 0,
        },
    },
    selectedCommunityToJoinInfo: {
        id: 0,
        tags: [],
        memberTypes: [],
        selectedMemberType: "",
        selectedTags: [],
        skills: [],
        selectedSkills: [],
        access_status: null,
        userProfileLocation: {
            address: "",
            latitude: 0,
            longitude: 0,
        },
        appearance: {
            id: 0,
            skill_tag_display_name: "",
        },
        theJoinProcessHasBeenStarted: false,
        onboardingProgressBarSteps: {
            "Create account": {
                checked: true,
                isCurrent: false,
                isDisabled: false,
                isVisible: true,
            },
            "Choose plan": {
                checked: false,
                isCurrent: false,
                isDisabled: false,
                isVisible: false,
            },
            "Your profile": {
                checked: false,
                isCurrent: false,
                isDisabled: true,
                isVisible: true,
            },
            "Select groups": {
                checked: false,
                isCurrent: false,
                isDisabled: true,
                isVisible: false,
            },
            Checkout: {
                checked: false,
                isCurrent: false,
                isDisabled: true,
                isVisible: false,
            },
        },
    },

    //TODO: add meta property
};

export function communitiesReducer(
    state = initial,
    action: TCommunitiesActions,
): ICommunitiesState {
    switch (action.type) {
        case ECommunitiesActionTypes.communitiesLoadingSet: {
            return {
                ...state,
                loading: action.payload,
            };
        }
        case ECommunitiesActionTypes.setCommunities: {
            const communitiesData = action.payload.communities.map(
                (item: any) => {
                    const { selected_member_plan, ...itemData } = item;
                    return {
                        ...itemData,
                    };
                },
            );

            return {
                ...state,
                data: action.payload.init
                    ? communitiesData
                    : state?.data
                        ? [...state.data].concat(communitiesData)
                        : communitiesData,
                meta: action.payload.meta,
            };
        }
        case ECommunitiesActionTypes.setSelectedCommunityToJoinDetails: {
            const { communityId, data } = action.payload;

            return {
                ...state,
                selectedCommunityToJoinInfo: {
                    ...state.selectedCommunityToJoinInfo,
                    ...data?.meta?.community,
                    id: communityId,
                    tags: data?.tags,
                    skills: data?.meta?.skill_tags,
                    memberTypes: data?.meta?.member_types,
                    appearance: data?.meta?.appearance
                },
                // selectedCommunityId: action.payload.communityId,
            };
        }
        case ECommunitiesActionTypes.setSelectedCommunityToJoinMemberType: {
            const { memberType } = action.payload;
            return {
                ...state,
                selectedCommunityToJoinInfo: {
                    ...state.selectedCommunityToJoinInfo,
                    selectedMemberType: memberType,
                },
            };
        }
        case ECommunitiesActionTypes.setSelectedCommunityToJoinTags: {
            const { tags } = action.payload;
            return {
                ...state,
                selectedCommunityToJoinInfo: {
                    ...state.selectedCommunityToJoinInfo,
                    selectedTags: tags,
                },
            };
        }
        case ECommunitiesActionTypes.setSelectedCommunityToJoinSkills: {
            const { skills } = action.payload;
            return {
                ...state,
                selectedCommunityToJoinInfo: {
                    ...state.selectedCommunityToJoinInfo,
                    selectedSkills: skills,
                },
            };
        }

        case ECommunitiesActionTypes.setCommunityAccessStatus: {
            const { accessStatus } = action.payload;

            return {
                ...state,
                selectedCommunityToJoinInfo: {
                    ...state.selectedCommunityToJoinInfo,
                    access_status: accessStatus,
                },
            };
        }
        case ECommunitiesActionTypes.setSelectedCommunityToJoinMemberPlans: {
            const { plans } = action.payload;
            if (!plans || plans.length === 0) {
                return {
                    ...state,
                    selectedCommunityToJoinInfo: {
                        ...state.selectedCommunityToJoinInfo,
                        memberPlans: [],
                    },
                };
            }
            for (let plan of plans) {
                if (!plan.price_options) {
                    continue;
                }
                for (let i = 0; i < plan.price_options.length; i++) {
                    if (plan.price_options[i].synced) continue;
                    plan.price_options.splice(i, 1);
                }
            }

            return {
                ...state,
                selectedCommunityToJoinInfo: {
                    ...state.selectedCommunityToJoinInfo,
                    memberPlans: plans,
                },
            };
        }
        case ECommunitiesActionTypes.setSelectedCommunityToJoinMemberPlan: {
            const { plan } = action.payload;

            return {
                ...state,
                selectedCommunityToJoinInfo: {
                    ...state.selectedCommunityToJoinInfo,
                    selected_member_plan: {
                        ...state.selectedCommunityToJoinInfo
                            .selected_member_plan,
                        ...plan,
                    },
                },
            };
        }
        case ECommunitiesActionTypes.updateJoinedAndApprovedStatusForCommunity: {
            const { communityId, joined, approved } = action.payload;

            if (communityId && state?.data) {
                let selectedCommunityIndex = 0;
                const selectedCommunity = [...state.data].find(
                    (item, index) => {
                        if (item.id === communityId) {
                            selectedCommunityIndex = index;
                            return true;
                        } else {
                            return false;
                        }
                    },
                );

                if (selectedCommunity) {
                    selectedCommunity.joined = joined ?? false;
                    selectedCommunity.approved = approved ?? false;

                    const resultedState = {
                        ...state,
                        data: [...state.data],
                    };
                    resultedState.data[selectedCommunityIndex] = {
                        ...selectedCommunity,
                    };

                    return resultedState;
                } else {
                    return { ...state };
                }
            } else {
                return { ...state };
            }
        }
        case ECommunitiesActionTypes.setUserProfilePicture: {
            if (!action.payload) {
                return state;
            }
            return {
                ...state,
                selectedCommunityToJoinInfo: {
                    ...state.selectedCommunityToJoinInfo,
                    userProfilePicture: action.payload,
                },
            };
        }
        case ECommunitiesActionTypes.setCommunitiesInvitations: {
            if (!action.payload.meta?.invites) {
                return state;
            }

            return {
                ...state,
                meta: {
                    ...state.meta,
                    invites: action.payload.meta.invites,
                },
            };
        }
        case ECommunitiesActionTypes.setUserProfileLocation: {
            if (!action.payload) {
                return {
                    ...state,
                    selectedCommunityToJoinInfo: {
                        ...state.selectedCommunityToJoinInfo,
                        userProfileLocation: undefined,
                    },
                };
            }
            return {
                ...state,
                selectedCommunityToJoinInfo: {
                    ...state.selectedCommunityToJoinInfo,
                    userProfileLocation: { ...action.payload },
                },
            };
        }
        case ECommunitiesActionTypes.communityToJoinSetCurrency: {
            if (!action.payload) {
                return state;
            }
            return {
                ...state,
                selectedCommunityToJoinInfo: {
                    ...state.selectedCommunityToJoinInfo,
                    selectedCurrency: action.payload.currency,
                },
            };
        }

        case ECommunitiesActionTypes.setSelectedCommunityToJoinProgressBar: {
            const { onboardingStep, showTheCheckoutStep } = action.payload;
            // Deep cloning
            let parseOnboardingSteps: ISelectedCommunityToJoin["onboardingProgressBarSteps"] =
                JSON.parse(
                    JSON.stringify(
                        state.selectedCommunityToJoinInfo
                            ?.onboardingProgressBarSteps,
                    ),
                );

            switch (onboardingStep) {
                case EJoinCommunityOnboardingSteps["Your profile"]: {
                    if (parseOnboardingSteps["Your profile"].checked) break;
                    parseOnboardingSteps = {
                        ...parseOnboardingSteps,
                        "Choose plan": {
                            ...parseOnboardingSteps["Choose plan"],
                            checked: true,
                            isCurrent: false,
                        },
                        "Your profile": {
                            ...parseOnboardingSteps["Your profile"],
                            isCurrent: true,
                            isDisabled: false,
                        },
                    };
                    break;
                }
                case EJoinCommunityOnboardingSteps["Select groups"]: {
                    if (parseOnboardingSteps["Select groups"].checked) break;
                    parseOnboardingSteps = {
                        ...parseOnboardingSteps,
                        "Your profile": {
                            ...parseOnboardingSteps["Your profile"],
                            checked: true,
                            isCurrent: false,
                        },
                        "Select groups": {
                            ...parseOnboardingSteps["Select groups"],
                            isCurrent: true,
                            isDisabled: false,
                        },
                    };
                    break;
                }
                case EJoinCommunityOnboardingSteps["Checkout"]: {
                    if (parseOnboardingSteps["Checkout"].checked) break;

                    parseOnboardingSteps = {
                        ...parseOnboardingSteps,
                        "Select groups": {
                            ...parseOnboardingSteps["Select groups"],
                            checked: true,
                            isCurrent: false,
                        },
                        Checkout: {
                            ...parseOnboardingSteps["Checkout"],
                            isCurrent: true,
                            isDisabled: false,
                        },
                    };
                    break;
                }
            }
            if (showTheCheckoutStep) {
                parseOnboardingSteps = {
                    ...parseOnboardingSteps,
                    Checkout: {
                        ...parseOnboardingSteps["Checkout"],
                        isVisible: true,
                    },
                };
            }

            return {
                ...state,
                selectedCommunityToJoinInfo: {
                    ...state.selectedCommunityToJoinInfo,
                    onboardingProgressBarSteps: parseOnboardingSteps,
                },
            };
        }
        case ECommunitiesActionTypes.initSelectedCommunityToJoinProgressBar: {
            const {
                isConnectedCommunity,
                hasGroups,
                isTheProcessResumed,
                resetData,
            } = action.payload;
            if (resetData) {
                return {
                    ...state,
                    selectedCommunityToJoinInfo: {
                        ...state.selectedCommunityToJoinInfo,
                        onboardingProgressBarSteps: {
                            "Create account": {
                                checked: true,
                                isCurrent: false,
                                isDisabled: false,
                                isVisible: true,
                            },
                            "Choose plan": {
                                checked: false,
                                isCurrent: false,
                                isDisabled: false,
                                isVisible: false,
                            },
                            "Your profile": {
                                checked: false,
                                isCurrent: false,
                                isDisabled: true,
                                isVisible: true,
                            },
                            "Select groups": {
                                checked: false,
                                isCurrent: false,
                                isDisabled: true,
                                isVisible: false,
                            },
                            Checkout: {
                                checked: false,
                                isCurrent: false,
                                isDisabled: true,
                                isVisible: false,
                            },
                        },
                    },
                };
            } else {
                // deep cloning
                let resultedOnboardingProgressBar: ISelectedCommunityToJoin["onboardingProgressBarSteps"] =
                    JSON.parse(
                        JSON.stringify(
                            state.selectedCommunityToJoinInfo
                                .onboardingProgressBarSteps,
                        ),
                    );
                if (isTheProcessResumed) {
                    resultedOnboardingProgressBar = {
                        ...resultedOnboardingProgressBar,
                        [EJoinCommunityOnboardingSteps["Choose plan"]]: {
                            ...resultedOnboardingProgressBar["Choose plan"],
                            isVisible: true,
                            isCurrent: false,
                            checked: true,
                            isDisabled: false,
                        },
                        [EJoinCommunityOnboardingSteps["Your profile"]]: {
                            ...resultedOnboardingProgressBar["Your profile"],
                            isVisible: true,
                            checked: true,
                            isDisabled: false,
                        },
                        [EJoinCommunityOnboardingSteps["Checkout"]]: {
                            ...resultedOnboardingProgressBar["Checkout"],
                            isVisible: true,
                            checked: false,
                            isCurrent: true,
                            isDisabled: false,
                        },
                    };
                    if (hasGroups) {
                        resultedOnboardingProgressBar = {
                            ...resultedOnboardingProgressBar,

                            [EJoinCommunityOnboardingSteps["Select groups"]]: {
                                ...resultedOnboardingProgressBar[
                                "Select groups"
                                ],
                                isVisible: true,
                                checked: true,
                                isDisabled: false,
                            },
                        };
                    }
                } else {
                    resultedOnboardingProgressBar = {
                        ...resultedOnboardingProgressBar,
                        [EJoinCommunityOnboardingSteps["Your profile"]]: {
                            ...resultedOnboardingProgressBar["Your profile"],
                            isVisible: true,
                        },
                    };
                    if (isConnectedCommunity) {
                        resultedOnboardingProgressBar = {
                            ...resultedOnboardingProgressBar,
                            [EJoinCommunityOnboardingSteps["Choose plan"]]: {
                                ...resultedOnboardingProgressBar["Choose plan"],
                                isVisible: true,
                                isCurrent: true,
                                isDisabled: false,
                            },
                        };
                    } else {
                        resultedOnboardingProgressBar = {
                            ...resultedOnboardingProgressBar,
                            [EJoinCommunityOnboardingSteps["Your profile"]]: {
                                ...resultedOnboardingProgressBar[
                                "Your profile"
                                ],
                                isCurrent: true,
                                isDisabled: false,
                            },
                        };
                    }
                    if (hasGroups) {
                        resultedOnboardingProgressBar = {
                            ...resultedOnboardingProgressBar,
                            [EJoinCommunityOnboardingSteps["Select groups"]]: {
                                ...resultedOnboardingProgressBar[
                                "Select groups"
                                ],
                                isVisible: true,
                            },
                        };
                    }
                }

                return {
                    ...state,
                    selectedCommunityToJoinInfo: {
                        ...state.selectedCommunityToJoinInfo,
                        onboardingProgressBarSteps:
                            resultedOnboardingProgressBar,
                    },
                };
            }
        }
        case ECommunitiesActionTypes.startTheJoinCommunityProcess: {
            return {
                ...state,
                selectedCommunityToJoinInfo: {
                    ...state.selectedCommunityToJoinInfo,
                    theJoinProcessHasBeenStarted: action.payload,
                },
            };
        }
        default:
            return state;
    }
}
