import React, { memo, MouseEventHandler, useCallback, useMemo } from "react";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import VisibilitySensor from "react-visibility-sensor";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faPen } from "@fortawesome/free-solid-svg-icons";

import styles from "./styles.module.scss";
import imagePlaceholder from "assets/images/image-placeholder.jpg";

import {
	useComputePostImgSize,
	useParseCommunityTags,
	UseShowDescriptionWithMentions,
} from "utils";
import { IMarketplaceOfferRawData, TStore } from "models";
import {
	fetchConversation,
	incrementPostViewCountAction,
	notify,
	setFilterTagsMarketplaceOffers,
} from "store";
import { ReactComponent as InfoIcon } from "assets/images/info.svg";
import { ReactComponent as MessageSquareIcon } from "assets/images/message-square-icon.svg";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.app.feedPostsContainerWidth,
	(state: TStore) => state.auth?.credentials?.user,
	(feedPostsContainerWidth, authenticatedUser) => ({
		feedPostsContainerWidth,
		authenticatedUser,
	}),
);

interface ICardView {
	marketplaceOffer: IMarketplaceOfferRawData;
	onClick?: (id: number) => MouseEventHandler<HTMLDivElement>;
	disableFilterByTags?: boolean;
}

const CardView: React.FunctionComponent<ICardView> = ({
	marketplaceOffer,
	disableFilterByTags = false,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { feedPostsContainerWidth, authenticatedUser } =
		useSelector(stateSelector);
	const { id, title, description, tags, user, good, membership_id } =
		marketplaceOffer;
	const { parsedTags } = useParseCommunityTags({ tags });

	const cover = useComputePostImgSize({
		containerWidth: feedPostsContainerWidth,
		images:
			marketplaceOffer?.images?.length > 0
				? marketplaceOffer?.images
				: [],
	});
	const handleSelectedOffer = useCallback(
		(marketplaceOfferId: number, type: string) => () => {
			navigate(`/marketplace/edit/${marketplaceOfferId}`, {
				state: type,
			});
		},
		[navigate],
	);

	const onVisibilityChange = useCallback(
		(isVisible) => {
			if (isVisible && marketplaceOffer.id)
				dispatch(incrementPostViewCountAction(marketplaceOffer.id));
		},
		[dispatch, marketplaceOffer?.id],
	);

	const handleFilterTagsOffers = useCallback(
		(filterTags?: string[]) => () => {
			if (disableFilterByTags) {
				return;
			}
			let newFilterTags: string[] = [];
			if (tags && tags.length) {
				newFilterTags = tags.filter(
					(item: any) => filterTags && !filterTags.includes(item),
				);
			}

			dispatch(
				setFilterTagsMarketplaceOffers({
					tags: newFilterTags as string[],
				}),
			);
		},
		[dispatch, tags, disableFilterByTags],
	);
	const goToDetails = useCallback(() => {
		navigate(`/marketplace/view/${id}`);
	}, [navigate, id]);

	const goToChat = () => {
		if (!membership_id) {
			notify("error", "An error occurred. Please try again later.");
			return;
		}
		navigate("/messages", {
			state: { member: { ...user, membership_id } },
		});
		dispatch(
			fetchConversation({
				communityPostId: 0,
				recipientId: membership_id,
				conversationId: 0,
				page: 1,
			}),
		);
	};

	const parsedPrice = useMemo((): null | {
		minPrice?: string;
		maxPrice?: string;
		price?: string;
		currency: string;
	} => {
		if (!good || !good.price) {
			return null;
		}
		const splittedPrice = good.price.split("-");
		if (!splittedPrice) {
			// for the scenarios when the good.price is "0"
			return null;
		}
		if (splittedPrice.length === 1) {
			return {
				price: splittedPrice[0].trim(),
				currency: good.currency,
			};
		}
		return {
			minPrice: splittedPrice[0].trim(),
			maxPrice: splittedPrice[1].trim(),
			currency: good.currency,
		};
	}, [good]);

	return (
		<VisibilitySensor onChange={onVisibilityChange}>
			<div className={styles.container}>
				<div className={styles.cover}>
					<img
						src={cover ? cover : imagePlaceholder}
						alt="cover"
						className={styles.image}
					/>
					{parsedPrice && (
						<div className={styles.absoluteFlexRowWrapper}>
							<div
								className={classNames(
									styles.boldWhiteText,
									styles.greyBackgroundColor,
								)}
								style={{
									padding: "0px 4px",
									borderRadius: 6,
								}}
							>
								{parsedPrice.price
									? `${parsedPrice.currency}${parsedPrice.price}`
									: `${parsedPrice.currency}${parsedPrice.minPrice}-${parsedPrice.currency}${parsedPrice.maxPrice}`}
							</div>
						</div>
					)}
					<div className={styles.absoluteGradientBottomToTop} />
				</div>
				<div className={styles.content}>
					<h3 className={styles.boldTxtSecondaryColor}>{title}</h3>
					<div>
						<span className={styles.fadeGreyColor}>
							{"Sold by:"}
						</span>
						<span className={styles.contact}>
							{marketplaceOffer?.user?.full_name}
						</span>
					</div>
					<div>
						{marketplaceOffer?.good?.postcode && (
							<span
								className={styles.contact}
								style={{ marginLeft: 0 }}
							>
								{marketplaceOffer.good.postcode}
							</span>
						)}
					</div>
					<div className={styles.description}>
						<UseShowDescriptionWithMentions
							text={description}
							maxHeight={"100px"}
							words={60}
							mentions={marketplaceOffer.mentions}
						/>
					</div>
					{marketplaceOffer?.groups?.length > 0 && (
						<div>
							{"in:"}
							{marketplaceOffer.groups.map((item: string) => {
								return <span key={`key_${item}`}>{item}</span>;
							})}
						</div>
					)}
					{parsedTags && parsedTags.length > 0 && (
						<div className={styles.tagsContainer}>
							{parsedTags?.map((item: any) => {
								return (
									<div
										key={`tag_${item}`}
										className={styles.tag}
										style={
											disableFilterByTags
												? { cursor: "default" }
												: {}
										}
										onClick={handleFilterTagsOffers([item])}
									>
										{item}
									</div>
								);
							})}
						</div>
					)}
				</div>
				<div
					className={classNames(
						styles.flexRowJustifyContentStart,
						styles.borderBottomRadius15,
						styles.headerBackgroundColor,
						{
							[styles.flexBoxSpaceBetween]:
								authenticatedUser?.id === user?.id,
						},
					)}
					style={{ color: "#fff" }}
				>
					{authenticatedUser?.id === user?.id && (
						<div
							className={classNames(
								styles.flexCenter,
								styles.cursorPointer,
							)}
							style={{ borderRight: "1px solid #fff" }}
							onClick={handleSelectedOffer(id, good.type)}
						>
							<FontAwesomeIcon
								icon={faPen as unknown as IconProp}
								color={"#cdcdcd"}
								style={{
									display: "block",
									fontSize: 12,
									margin: "0px 2px",
									color: "#fff",
								}}
							/>
							{"Edit"}
						</div>
					)}
					<div
						className={classNames(
							styles.flexCenter,
							styles.cursorPointer,
						)}
						style={{ borderRight: "1px solid #fff" }}
						onClick={goToDetails}
					>
						<InfoIcon width={12} />
						{"Details"}
					</div>
					{authenticatedUser?.id !== user.id && (
						<div
							className={classNames(
								styles.flexCenter,
								styles.cursorPointer,
							)}
							onClick={goToChat}
						>
							<MessageSquareIcon width={12} />
							{"Message"}
						</div>
					)}
				</div>
			</div>
		</VisibilitySensor>
	);
};

export default memo(CardView);
