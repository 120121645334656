import React, { memo, useEffect } from "react";
import { useDispatch } from "react-redux";

import { resetStore } from "store";
import { Loading } from "components";
import { isItAWhiteLabel } from "utils";

const Logout = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(resetStore());
		if (isItAWhiteLabel()) return;
		/**
		 * If the app is configured based on a white label DO NOT reset the colors used across the app.
		 */
		document.documentElement.style.setProperty("--headerBackground", null);
		document.documentElement.style.setProperty(
			"--headerBackgroundRgb",
			null,
		);

		document.documentElement.style.setProperty(
			"--announcementHeaderBackground",
			null,
		);
		document.documentElement.style.setProperty(
			"--announcementHeaderBackgroundRgb",
			null,
		);
		document.documentElement.style.setProperty(
			"--postHeaderBackground",
			null,
		);
		document.documentElement.style.setProperty(
			"--postHeaderBackgroundRgb",
			null,
		);
		document.documentElement.style.setProperty("--primaryColor", null);
		document.documentElement.style.setProperty("--primaryColorRgb", null);
		document.documentElement.style.setProperty("--secondaryColor", null);
		document.documentElement.style.setProperty("--secondaryColorRgb", null);
	}, [dispatch]);

	return <Loading />;
};

export default memo(Logout);
