import { useCallback, useEffect, useMemo, useState } from "react";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useDispatch, useSelector } from "react-redux";

import styles from "./styles.module.scss";
import {
	changeSubscriptionSetCurrencyAction,
	// notify,
	setSelectedCommunityToJoinCurrency,
} from "store";
import { ECommunityType, TStore } from "models";
import { createSelector } from "reselect";
import { communityPlansCurrenciesList } from "utils";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.subscription,
	(state: TStore) => state.communities.selectedCommunityToJoinInfo,
	(state: TStore) => state.community,
	(subscriptionState, selectedCommunityToJoinInfo, communityData) => ({
		subscriptionState,
		selectedCommunityToJoinInfo,
		communityData,
	}),
);

interface ICurrenciesList {
	setSelectedCurrency: (checkedValue: string) => void;
	typeOfCommunity: ECommunityType;
}

const CurrenciesList: React.FunctionComponent<ICurrenciesList> = ({
	setSelectedCurrency,
	typeOfCommunity,
}) => {
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { subscriptionState, selectedCommunityToJoinInfo, communityData } =
		useSelector(stateSelector);
	const [checkboxValue, setCheckboxValue] = useState<string | undefined>(
		undefined,
	);
	const dispatch = useDispatch();

	const currenciesList: null | Set<string> = useMemo(() => {
		if (
			typeOfCommunity === ECommunityType.current &&
			subscriptionState?.subscription?.currency
		) {
			/**
			 * * After "change plan" is clicked if a member already has a subscription, only prices in that sub subscription's currency should be shown.
			 */
			return null;
		}

		return communityPlansCurrenciesList(
			selectedCommunityToJoinInfo?.memberPlans?.length
				? selectedCommunityToJoinInfo?.memberPlans
				: communityData.data?.memberPlans,
		);
	}, [
		communityData.data?.memberPlans,
		selectedCommunityToJoinInfo?.memberPlans,
		subscriptionState.subscription,
		typeOfCommunity,
	]);

	const checkboxOptions = !currenciesList
		? null
		: Array.from(currenciesList).map((item) => {
				return item;
		  });

	const handleOnChange = (checkedValue: CheckboxChangeEvent) => {
		setSelectedCurrency(
			checkedValue.target.checked ? checkedValue.target.value : "",
		);
		setCheckboxValue(
			checkedValue.target.checked ? checkedValue.target.value : "",
		);
		if (typeOfCommunity === ECommunityType.join) {
			dispatch(
				setSelectedCommunityToJoinCurrency(
					checkedValue.target.checked
						? checkedValue.target.value
						: "",
				),
			);
		} else {
			dispatch(
				changeSubscriptionSetCurrencyAction({
					currency: checkedValue.target.checked
						? checkedValue.target.value
						: "",
				}),
			);
		}
	};

	const defaultCurrency = useMemo(() => {
		if (!currenciesList) {
			return;
		}

		const [defaultCurrency] = currenciesList.values();

		return defaultCurrency;
	}, [currenciesList]);

	useEffect(() => {
		if (
			checkboxValue ||
			!defaultCurrency
			// typeOfCommunity !== ECommunityType.join
		)
			return;

		setSelectedCurrency(defaultCurrency);
		setCheckboxValue(defaultCurrency);
		if (typeOfCommunity === ECommunityType.join) {
			dispatch(setSelectedCommunityToJoinCurrency(defaultCurrency));
		}
	}, [
		checkboxValue,
		defaultCurrency,
		dispatch,
		setSelectedCurrency,
		typeOfCommunity,
	]);

	useEffect(() => {
		// set the default currency for the change subscription flow
		if (
			!subscriptionState.subscription ||
			typeOfCommunity !== ECommunityType.current
		)
			return;

		const { currency } = subscriptionState.subscription;

		// if (!currency) {
		// 	notify("error", "An error occurred. Please try again later.");
		// 	return;
		// }

		dispatch(
			changeSubscriptionSetCurrencyAction({
				currency: currency ? currency : defaultCurrency ?? "GBP",
			}),
		);
		setSelectedCurrency(currency ? currency : defaultCurrency ?? "GBP");
	}, [
		dispatch,
		setSelectedCurrency,
		subscriptionState.subscription,
		typeOfCommunity,
		defaultCurrency,
	]);

	return !currenciesList ? null : (
		<>
			{!checkboxOptions ? null : (
				<div className={styles.flexRow}>
					<div style={{ marginRight: 10, color: "gray" }}>
						{"Please select desired currency"}
					</div>
					<div className={styles.currencyWrapper}>
						{checkboxOptions.map((item) => {
							return (
								<Checkbox
									key={item}
									className={styles.checkbox}
									onChange={handleOnChange}
									checked={item === checkboxValue}
									value={item}
								>
									{item}
								</Checkbox>
							);
						})}
					</div>
				</div>
			)}
		</>
	);
};

export default CurrenciesList;
