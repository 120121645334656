import { ESubscriptionStatus, IPaymentMethod } from "models";
import {
	IPaymentsSetMemberSubscriptionToPendingAction,
	IPaymentsSetMemberSubscriptionAction,
} from "./actions";

// ENUMS
export enum EPaymentsTypes {
	createMemberSubscription = "payments/create/member/subscription",
	setMemberSubscription = "payments/set/member/subscription",
	resumePaymentProcess = "payments/resume/payment/process",
	changeTheMemberSubscriptionToPending = "payments/change/member/subscription/to/pending",
	setTheMemberSubscriptionToPending = "payments/set/member/subscription/to/pending",
	completeThePaymentProcess = "payments/complete/payment/process",
	changeSubscriptionPlan = "payments/change/subscription/plan",
	changeSubscriptionPlanWithPaymentIntent = "payments/change/subscription/plan/with/payment/intent",
}

// INTERFACES

export interface IMemberSubscriptionResponse {
	success: boolean;
	payload: {
		client_secret: string | null;
		pending_url: string | null;
		payment_methods: IPaymentMethod[];
		subscription_id: number | null;
		stripe_linked_account_id?: string | null;
		subscription_status?: ESubscriptionStatus | null;
	};
	setup_intent_client_secret?: string;
}

export interface IPaymentIntent {
	amount: number;
	canceledAt: null | unknown;
	captureMethod: string;
	clientSecret: string;
	confirmationMethod: string;
	created: string;
	currency: string;
	description: string;
	id: string;
	lastPaymentError: null | unknown;
	livemode: boolean;
	paymentMethodId: string;
	receiptEmail: null | string;
	shipping: null | string;
	status: string;
}

export interface IPaymentError {
	code: "Failed" | string;
	declineCode: null | unknown;
	localizedMessage: string;
	message: string;
	stripeErrorCode: "payment_intent_unexpected_state" | string;
	type: "invalid_request_error" | string;
}

export interface IPaymentsState {
	memberSubscription: IMemberSubscriptionResponse | undefined | null;
}

// TYPES
export type TPaymentsActions =
	| IPaymentsSetMemberSubscriptionAction
	| IPaymentsSetMemberSubscriptionToPendingAction;
