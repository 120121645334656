import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";

import { MainLayout } from "layouts";
import { HelmetPageTitle, Loading } from "components";
import {
	Subscription,
	PaymentMethods,
	Invoices,
	ModalManageSubscriptionNotAvailable,
} from "./components";
import styles from "./styles.module.scss";
import { getSubscriptionManagementActionSaga } from "store";
import { ESubscriptionPaymentService, TStore } from "models";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.subscription.subscription?.cancelation_requested,
	(state: TStore) => state.subscription.currentMemberPlan,
	(state: TStore) => state.subscription.subscription,
	(cancelation_requested, currentMemberPlan, subscription) => ({
		cancelation_requested,
		currentMemberPlan,
		subscription,
	}),
);

const SubscriptionManagement = () => {
	const dispatch = useDispatch();
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { cancelation_requested, currentMemberPlan, subscription } =
		useSelector(stateSelector);
	const [seeAllInvoices, setSeeAllInvoices] = useState(false);
	const [loading, setLoading] = useState(true);

	const toggleSeeAllInvoices = useCallback(() => {
		setSeeAllInvoices(!seeAllInvoices);
	}, [seeAllInvoices]);

	useEffect(() => {
		dispatch(
			getSubscriptionManagementActionSaga({
				onSuccessCallback: () => {
					setLoading(false);
				},
				onErrorCallback: () => {
					setLoading(false);
				},
			}),
		);
	}, [dispatch]);

	return (
		<>
			<HelmetPageTitle screenTitle={"Pricing"} />
			<MainLayout>
				{loading ? (
					<Loading />
				) : subscription?.service ===
				  ESubscriptionPaymentService.apple_pay ? (
					<ModalManageSubscriptionNotAvailable />
				) : (
					<div
						className={styles.container}
						id="subscription-management-layout"
					>
						{!seeAllInvoices && (
							<>
								<Subscription />
								<hr className={styles.horizontalLine} />

								{!cancelation_requested &&
								!currentMemberPlan?.free ? (
									<>
										<PaymentMethods />
										<hr className={styles.horizontalLine} />
									</>
								) : null}
							</>
						)}

						<Invoices
							toggleSeeAllInvoices={toggleSeeAllInvoices}
							seeAllInvoices={seeAllInvoices}
						/>
					</div>
				)}
			</MainLayout>
		</>
	);
};

export default memo(SubscriptionManagement);
