import {
	EPreviewWhiteLabelCommunityActionTypes,
	IPreviewWhiteLabelCommunity,
} from "models";

export const setPreviewWhiteLabelCommunityAction = (
	payload: IPreviewWhiteLabelCommunity,
) => ({
	type: EPreviewWhiteLabelCommunityActionTypes.setPreviewCommunityData,
	payload,
});
export const getPreviewWhiteLabelCommunityAction = (payload: {
	onSuccessCallback: () => void;
	onErrorCallback: () => void;
}) => ({
	type: EPreviewWhiteLabelCommunityActionTypes.getPreviewCommunityData,
	payload,
});
