import DOMPurify from "dompurify";

import { announcementLinkRegex, getRegexMatches } from "utils";

export const convertAnnouncementLinkIntoHref = (
	description: string,
): string => {
	let source: string = DOMPurify.sanitize(description, {
		ALLOWED_TAGS: ["a"],
		ALLOWED_ATTR: ["href", "membermentionid"],
	});
	

	const instances = source.match(announcementLinkRegex);
	var announcementTypesAndIds = getRegexMatches({
		string: source,
		regex: announcementLinkRegex,
		index: 1,
	});
	var announcementTitles = getRegexMatches({
		string: source,
		regex: announcementLinkRegex,
		index: 2,
	});

	const instancesLength =
		announcementTypesAndIds &&
		instances &&
		announcementTitles &&
		announcementTypesAndIds.length === announcementTitles.length &&
		announcementTypesAndIds.length === instances.length
			? instances.length
			: 0;

	if (instancesLength && instances) {
		let dataTypeAndId: any = announcementTypesAndIds[0];
		let dataType: "video_id" | "document_id" | "resource_id" = dataTypeAndId
			.split(":")[0]
			.trim();

		let anchorHref = "";
		switch (dataType) {
			case "video_id": {
				anchorHref = `/videos`;
				break;
			}
			case "document_id": {
				anchorHref = `/documents`;
				break;
			}
			case "resource_id": {
				anchorHref = `/resources`;
				break;
			}
			default:
				break;
		}

		for (let i = 0; i < instancesLength; i++) {
			if( i === 1) 
			{
				source = source.replace(
					instances[i],
					`<a class='linkified' href=${anchorHref} target="_self" id=${announcementTitles[
						i
					] // @ts-ignore
						.replace("display: ", "")
						.replaceAll("'", "")} >${announcementTitles[
						i
					] // @ts-ignore
						.replace("display: ", "")
						.replaceAll("'", "")}</a>`,
				);
			}
			else{
				source = source.replace(
					instances[i],
					`<a class='linkified' href=${anchorHref} target="_self"  >${announcementTitles[
						i
					] // @ts-ignore
						.replace("display: ", "")
						.replaceAll("'", "")}</a>`,
				);
			}
		
		}
	}

	return source;
};
