import React, { memo, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { createSelector } from "reselect";

import { getResource } from "store";
import { IDoc, IVideo, TStore } from "models";
import Loading from "components/Loading";
import { DocNotificationDetails, VideoNotificationDetails } from "..";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.resource?.data,
	(resourceData) => {
		return {
			resourceData,
		};
	},
);

interface IResourceNotificationDetails {
	resourceId: number;
	forceCommunityId: number;
	folderId: number | null;
}

const ResourceNotificationDetails: React.FC<IResourceNotificationDetails> = ({
	resourceId,
	forceCommunityId,
	folderId,
}) => {
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { resourceData } = useSelector(stateSelector);

	const isVideo = useMemo(() => {
		if (resourceData) {
			const isVideo =
				(resourceData as IVideo).video_link ||
				(resourceData as IVideo).vimeo_uri ||
				(resourceData as IVideo).resource_embed_uri;

			return isVideo ? true : false;
		}
	}, [resourceData]);

	useEffect(() => {
		if (resourceId && forceCommunityId) {
			dispatch(
				getResource({
					id: resourceId,
					forceCommunityId,
					folderId,
					callbackOnSuccess: () => setLoading(false),
					callbackOnError: () => setLoading(false),
				}),
			);
		}
	}, [dispatch, resourceId, folderId, forceCommunityId]);

	return loading ? (
		<Loading />
	) : resourceData ? (
		isVideo ? (
			<VideoNotificationDetails videoData={resourceData as IVideo} />
		) : (
			<DocNotificationDetails
				docData={resourceData as IDoc}
				type={"resources"}
			/>
		)
	) : null;
};

export default memo(ResourceNotificationDetails);
