import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";

import PrivacyPolicy from "./components/privacyPolicy";
import GlobalTermsAndConditions from "./components/termsAndConditions";
import CookiePolicy from "./components/cookiePolicy";
import { TStore } from "models";
import styles from "./styles.module.scss";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.previewWhiteLabelCommunity,
	(previewWhiteLabelCommunity) => ({
		previewWhiteLabelCommunity,
	}),
);

const LegalAgreements = () => {
	const location = useLocation();
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { previewWhiteLabelCommunity } = useSelector(stateSelector);

	return (
		<div className={styles.container}>
			{location.pathname.indexOf("/terms-and-conditions") !== -1 ? (
				<GlobalTermsAndConditions
					data={previewWhiteLabelCommunity?.terms_and_conditions}
					communityName={previewWhiteLabelCommunity?.name}
				/>
			) : location.pathname.indexOf("/privacy-policy") !== -1 ? (
				<PrivacyPolicy
					data={previewWhiteLabelCommunity?.privacy_policy}
					communityName={previewWhiteLabelCommunity?.name}
				/>
			) : (
				<CookiePolicy
					data={previewWhiteLabelCommunity?.privacy_policy}
					communityName={previewWhiteLabelCommunity?.name}
				/>
			)}
		</div>
	);
};

export default LegalAgreements;
