import { Input, Form } from "antd";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { api, notify } from "store";
import { TStore } from "models";
import { createSelector } from "reselect";
import FormInput from "components/FormInput";
import FormWrapper from "components/FormWrapper";
import CustomButton from "components/Button";
import { rollbar } from "utils";
import styles from "./styles.module.scss";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.auth.loading,
	(loading) => ({ loading }),
);
const ResetPasswordForm = () => {
	const navigate = useNavigate();
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { loading } = useSelector(stateSelector);
	const location = useLocation();
	const searchParams = useMemo(
		() => new URLSearchParams(location.search),
		[location],
	);

	const handleResetPassword = useCallback(
		async (values: { password: string }) => {
			try {
				await api.auth.resetPassword(
					values.password,
					searchParams.get("access_token") || "",
				);
				notify(
					"success",
					"Password reset success",
					"You can now login with your new password!",
				);
				navigate("/auth/sign-in");
			} catch (error) {
				rollbar.error(error);
				console.error({ error });
				notify(
					"error",
					"Password reset failed",
					"Sorry ... we could not update your password",
				);
			}
		},
		[navigate, searchParams],
	);
	return (
		<FormWrapper>
			<Form className={styles.form} onFinish={handleResetPassword}>
				<FormInput
					label="Password"
					name="password"
					hasFeedback
					rules={[
						{
							required: true,
							message: "Please input your Password!",
						},
						{
							min: 6,
							message: "Password minimum length is 6",
						},
						{
							pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
							message:
								"Password is too weak... Please include a mix of capitals, lower case and numbers",
						},
					]}
				>
					<Input.Password placeholder="Enter your Password" />
				</FormInput>
				<FormInput
					label="Confirm Password"
					name="confirm_password"
					dependencies={["password"]}
					hasFeedback
					rules={[
						{
							required: true,
							message: "Please confirm your Password!",
						},
						({ getFieldValue }) => ({
							validator(rule, value) {
								if (
									!value ||
									getFieldValue("password") === value
								) {
									return Promise.resolve();
								}
								return Promise.reject(
									"The two passwords that you entered do not match!",
								);
							},
						}),
					]}
				>
					<Input.Password placeholder="Confirm your Password" />
				</FormInput>

				<FormInput className={styles.formItemCenteredNoBorderBtn}>
					<CustomButton
						htmlType="submit"
						loading={loading}
						buttonText="Update Password"
					/>
					{/* Update Password
            </CustomButton> */}
				</FormInput>
			</Form>
		</FormWrapper>
	);
};
export default ResetPasswordForm;
