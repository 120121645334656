import {
	TPreviewWhiteLabelCommunityActions,
	EPreviewWhiteLabelCommunityActionTypes,
	IPreviewWhiteLabelCommunity,
} from "models";

const initial: IPreviewWhiteLabelCommunity | null = null;

export function previewWhiteLabelCommunityReducer(
	state = initial,
	action: TPreviewWhiteLabelCommunityActions,
): IPreviewWhiteLabelCommunity | null {
	switch (action.type) {
		case EPreviewWhiteLabelCommunityActionTypes.setPreviewCommunityData: {
			if (action.payload) {
				return {
					...action.payload,
				};
			} else {
				return null;
			}
		}
		default:
			return state;
	}
}
