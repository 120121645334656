import {
	call,
	put,
	all,
	takeLatest,
	takeEvery,
	select,
	take,
} from "redux-saga/effects";

import { EProfileActionTypes, TStore } from "models";
import {
	api,
	notify,
	setEditProfileLoading,
	setEditProfile,
	setUserProfileLoading,
	setEditProfilePosts,
	setUser,
	getCommunity,
	updateProfileAccountSettings,
	saveUserProfile,
	getEditProfile,
	updateUserDetails,
} from "store";
import { rollbar } from "utils";

const getUser = (state: TStore) => state.auth.credentials?.user;
const getCurrentCommunityId = (state: TStore) => state.app.currentCommunityId;
const getProfilePostsPagination = (state: TStore) =>
	state.editProfile.data.posts.meta.pagination;

// EDIT
function* getEditProfileHandle({ payload }: ReturnType<typeof getEditProfile>) {
	const { userId, communityId, onErrorCallback, onSuccessCallback } = payload;
	try {
		yield put(setEditProfileLoading(true));
		const { data: editProfileData } = yield call(
			api.profile.getEditProfile,
			{
				user_id: userId,
				community_id: communityId,
			},
		);
		const { data: profileAccountSettingsData } = yield call(
			api.profile.getProfileAccountSettings,
			{
				user_id: userId,
				community_id: communityId,
			},
		);
		const posts = yield call(api.profile.getEditProfilePosts, {
			user_id: userId,
			community_id: communityId,
		});
		if (!editProfileData) {
			yield put(setEditProfileLoading(false));
			onErrorCallback?.();
		} else {
			const userData = {
				user: {
					...editProfileData.user,
					// ...editUserProfileData.user,
					member_directory_opt_in:
						profileAccountSettingsData.user.member_directory_opt_in,
					community_id: communityId,
					joined_community_id: communityId,
				},
			};
			yield put(setEditProfile(userData));
			yield put(setEditProfilePosts({ data: posts.data }));
			yield put(
				getCommunity({
					communityId,
					memberDirectoryOptIn:
						profileAccountSettingsData.user.member_directory_opt_in,
				}),
			);
			yield put(setEditProfileLoading(false));
			onSuccessCallback?.();
		}
	} catch (error) {
		rollbar.error(error);
		console.error({ error });
		yield put(setEditProfileLoading(false));
		onErrorCallback?.();
	}
	yield put({ type: EProfileActionTypes.getEditProfileCompleted });
}

function* saveUserProfileHandle({
	payload,
}: ReturnType<typeof saveUserProfile>) {
	const {
		firstName,
		lastName,
		email,
		address,
		latitude,
		longitude,
		aboutMe,
		communityCheckboxGroup,
		marketplaceCheckboxGroup,
		skillsCheckboxGroup,
		avatar = "",
		id, // TODO: check if we can replace this id with the auth.credentials.id
		dontRedirect = false,
		communityId,
		onErrorCallback,
		onSuccessCallback,
		industry,
		profession,
	} = payload;

	try {
		const user = yield select(getUser);
		const globalStoreCommunityId = yield select(getCurrentCommunityId);
		const resultedCommunityId = communityId
			? communityId
			: globalStoreCommunityId;
		if (!user || !resultedCommunityId) {
			throw new Error("Missing parameters.");
		}
		const userData: any = {};
		if (firstName) userData.first_name = firstName;
		if (lastName) userData.last_name = lastName;
		if (email) userData.email = email;
		if (address) userData.location = address;
		if (latitude) userData.latitude = latitude;
		if (longitude) userData.longitude = longitude;
		userData.profession = profession;
		userData.industry = industry;

		const parsedUserProfilePayload = {
			user_id: user.id,
			user: {
				id: user.id,
				...userData,
			},
			avatar,
		};

		yield put(setUserProfileLoading(true));
		let { data } = yield call(
			api.profile.saveUserProfile,
			parsedUserProfilePayload,
		);

		if (data && id) {
			const parsedEditProfilePayload = {
				community_id: resultedCommunityId,
				user_id: id,
				user: {
					about_me: aboutMe,
					subset_list: communityCheckboxGroup,
					good_tag_list: marketplaceCheckboxGroup,
					skill_list: skillsCheckboxGroup,
				},
			};
			const { data: profile } = yield call(
				api.profile.saveEditProfile,
				parsedEditProfilePayload,
			);
			data.user = {
				...data.user,
				...profile.user,
				id: parseInt(profile.user.id), // backend issue the id is sent as a string instead to be sent as a number.
			};
		}
		if (!data) {
			yield call(notify, "error", "Error!", "Data could not be saved!");
			yield put(setUserProfileLoading(false));
		} else {
			yield call(
				notify,
				"success",
				"Success!",
				"Profile saved successfully!",
			);
			yield put(
				setUser({
					credentials: data,
				}),
			);
			yield put(
				getEditProfile({
					communityId: resultedCommunityId,
					userId: user.id,
				}),
			);
			yield take(EProfileActionTypes.getEditProfileCompleted);
			if (dontRedirect) {
				yield put(setUserProfileLoading(false));
				return;
			}
			yield put(setUserProfileLoading(false));
			onSuccessCallback?.();
		}
	} catch (error) {
		rollbar.error(error);
		console.error({ error });
		yield call(notify, "error", "Error!", "Data could not be saved!");
		yield put(setEditProfileLoading(false));
		onErrorCallback?.();
	}
}

function* updateProfileAccountSettingsHandle({
	userData,
	callbackOnSuccess,
}: ReturnType<typeof updateProfileAccountSettings>) {
	try {
		yield put(setEditProfileLoading(true));
		if (!userData || Object.values(userData).length === 0) {
			yield put(setEditProfileLoading(false));
			yield call(notify, "error", "Error!", "Data could not be saved!");
		}

		const user = yield select(getUser);

		const updatedUserData = yield call(
			api.profile.updateProfileAccountSettings,
			user.id,
			userData,
		);

		if (!updatedUserData) {
			yield put(setEditProfileLoading(false));
			yield call(notify, "error", "Error!", "Data could not be saved!");
		} else {
			if (callbackOnSuccess) {
				callbackOnSuccess(updatedUserData);
			}
			yield put(setEditProfileLoading(false));
			yield call(
				notify,
				"success",
				"Success!",
				"Data successfully saved!",
			);
		}
	} catch (error) {
		rollbar.error(error);
		console.error({ error });
		yield put(setEditProfileLoading(false));
	}
}

function* getEditProfilePostsHandle({ payload }: any) {
	try {
		const currentCommunityId = yield select(getCurrentCommunityId);
		const user = yield select(getUser);
		const pagination = yield select(getProfilePostsPagination);
		const { data } = yield call(api.posts.getCommunityPosts, {
			communityId: currentCommunityId,
			userId: user.id,
			pinType: 0,
			page:
				payload && payload.resetPosts
					? 1
					: parseInt(pagination.current_page) + 1,
		});
		yield put(setEditProfilePosts({ data }));
	} catch (error) {
		rollbar.error(error);
		console.error({ error });
	}
}
function* updateUserDetailsHandle({
	user,
}: ReturnType<typeof updateUserDetails>) {
	const users = yield select(getUser);
	const currentCommunityId = yield select(getCurrentCommunityId);

	try {
		yield call(
			api.profile.setUserDetails,
			currentCommunityId,
			users.id,
			user,
		);
	} catch (error) {
		rollbar.error(error);
		console.error({ error });
	}
}

export default function* rootSaga() {
	yield all([
		takeLatest(EProfileActionTypes.setUserDetails, updateUserDetailsHandle),
		takeLatest(EProfileActionTypes.getEditProfile, getEditProfileHandle),
		takeEvery(
			EProfileActionTypes.getEditProfilePosts,
			getEditProfilePostsHandle,
		),
		takeLatest(EProfileActionTypes.saveUserProfile, saveUserProfileHandle),
		takeLatest(
			EProfileActionTypes.updateProfileAccountSettings,
			updateProfileAccountSettingsHandle,
		),
	]);
}
