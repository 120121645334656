import styles from "./styles.module.scss";

interface IFooterLayout {}

const FooterLayout: React.FunctionComponent<IFooterLayout> = () => {
	return (
		<section className={styles.container}>
			<h1>
				This uHub is powered by&nbsp;
				<a
					className={styles.underlinedTextWithPrimaryColor}
					href="https://ugenie.io"
					target="_blank"
					rel="noopener noreferrer"
				>
					Ugenie
				</a>
			</h1>
		</section>
	);
};

export default FooterLayout;
