import { useCallback } from "react";
import { Col, Button } from "antd";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";

import { setCalendarDate } from "store";
import { TStore } from "models";
import Events from "../events";
import styles from "./styles.module.scss";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.events.selectedCalendarDate,
	(selectedCalendarDate) => ({ selectedCalendarDate }),
);

const ListOfEvent = () => {
	const dispatch = useDispatch();
	const stateSelector = useCallback(stateSelectorHandle, []);

	const { selectedCalendarDate } = useSelector(stateSelector);

	const handleFilterEvents = useCallback(
		(value: "upcoming" | "nerby" | "specific") => () => {
			switch (value) {
				case "upcoming": {
					// get upcoming events
					// reset selected Data
					dispatch(setCalendarDate({ date: undefined }));
					break;
				}
				default:
			}
		},
		[dispatch],
	);
	return (
		<Col xs={24} xl={8} style={{ minHeight: "100%" }}>
			<section className={styles.feauturedEvents}>
				<div className={styles.topSection}>
					<h3>{"List of Events"}</h3>
				</div>
				<div className={styles.buttonContainer}>
					<Button
						type="link"
						className={classNames(styles.button, {
							[styles.activeButton]: !selectedCalendarDate,
						})}
						onClick={handleFilterEvents("upcoming")}
					>
						{"Upcoming"}
					</Button>
				</div>
				<Events />
			</section>
		</Col>
	);
};
export default ListOfEvent;
