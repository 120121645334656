import {
	IGroupsState,
	EGroupsActionTypes,
	TGroupsActions,
	EGroupPrivacy,
} from "models";

const initial: IGroupsState = {
	loading: false,
	data: [],
	meta: {
		pagination: {
			current_page: 1,
			per_page: 10,
			total_pages: 0,
			total_objects: 0,
			has_next_page: false,
		},
	},
};

export function groupsReducer(
	state = initial,
	action: TGroupsActions,
): IGroupsState {
	switch (action.type) {
		case EGroupsActionTypes.groupsLoadingSet: {
			return {
				...state,
				loading: action.payload,
			};
		}
		case EGroupsActionTypes.setGroups: {
			return {
				...state,
				data:
					parseInt(action.payload.meta.pagination.current_page) === 1
						? action.payload.groups
						: [...state.data].concat(action.payload.groups),
				meta: action.payload.meta,
			};
		}
		case EGroupsActionTypes.updateLocaJoinedGroups: {
			const { privacy, typeOfAction, group_id, ...rest } = action.payload;

			if (!action.payload || Object.values(action.payload).length === 0)
				return state;
			let updatedGroupIndex = state.data.findIndex(
				(item) => item.id === group_id,
			);
			const updatedGroup = state.data[updatedGroupIndex];

			if (!updatedGroup) return state;

			if (privacy && privacy === EGroupPrivacy.secret) {
				return {
					...state,
					data: [
						...state.data.filter((item) => item.id !== group_id),
					],
				};
			} else {
				let numberOfMembers = updatedGroup.number_of_members;
				if (
					typeOfAction === "join" &&
					updatedGroup.privacy === EGroupPrivacy.open
				) {
					numberOfMembers++;
				} else if (typeOfAction === "leave") {
					numberOfMembers--;
				}
				const updatedGroups = state.data.map((item) => {
					if (item.id === group_id) {
						return {
							...item,
							...rest,
							number_of_members: numberOfMembers,
						};
					}
					return item;
				});
				return {
					...state,
					data: updatedGroups,
				};
			}
		}
		default:
			return state;
	}
}
