import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import InfiniteScroll from "react-infinite-scroller";
import { useNavigate } from "react-router-dom";

import { OnboardingLayout } from "layouts";
import styles from "./styles.module.scss";
import {
	EJoinCommunityOnboardingSteps,
	EProcessToJoinCommunityStage,
	IGroup,
	TStore,
} from "models";
import {
	getGroups,
	joinGroup,
	getMoreGroups,
	cancelJoinGroupRequest,
	setSelectedCommunityToJoinProgressBar,
	notify,
} from "store";
import { Loading, HelmetPageTitle } from "components";
import { JoinCommunityGroupCard } from "screens/communities/components";
import { isItAWhiteLabel } from "utils";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.groups,
	(state: TStore) => state.communities.selectedCommunityToJoinInfo,
	(groups, selectedCommunityToJoinInfo) => ({
		groups,
		selectedCommunityToJoinInfo,
	}),
);

const Groups = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { groups, selectedCommunityToJoinInfo } = useSelector(stateSelector);
	const [loading, setLoading] = useState<boolean>(true);
	const { data: groupsData = [] } = groups;

	const getData = useCallback(() => {
		if (!selectedCommunityToJoinInfo?.id) return;
		dispatch(
			getGroups({
				communityId: selectedCommunityToJoinInfo.id,
				successCallback: (groups: IGroup[]) => {
					if (groups.length === 0) {
						// skip the groups page
						navigate(
							`${
								isItAWhiteLabel()
									? "/joining/"
									: "/communities/"
							}${selectedCommunityToJoinInfo.id}/${
								EProcessToJoinCommunityStage["pending status"]
							}`,
						);
					} else {
						setLoading(false);
					}
				},
				errorCallback: () => {
					setLoading(false);
				},
			}),
		);
	}, [selectedCommunityToJoinInfo.id, dispatch, navigate]);

	const loadMoreGroups = useCallback(() => {
		if (!selectedCommunityToJoinInfo?.id) return;
		dispatch(
			getMoreGroups({
				currentCommunityId: selectedCommunityToJoinInfo.id,
			}),
		);
	}, [dispatch, selectedCommunityToJoinInfo?.id]);

	const handleJoinGroup = useCallback(
		(groupId: number) => {
			if (!selectedCommunityToJoinInfo?.id || !groupId) return;
			dispatch(
				joinGroup({
					communityId: selectedCommunityToJoinInfo.id,
					groupId,
				}),
			);
		},
		[dispatch, selectedCommunityToJoinInfo?.id],
	);

	const handleCancelJoinGroupRequest = useCallback(
		(groupId: number, requestId: number) => {
			if (!groupId || !requestId || !selectedCommunityToJoinInfo?.id)
				return;
			dispatch(
				cancelJoinGroupRequest({
					communityId: selectedCommunityToJoinInfo.id,
					groupId,
					requestId,
				}),
			);
		},
		[dispatch, selectedCommunityToJoinInfo?.id],
	);

	const back = useCallback(async () => {
		navigate(-1);
	}, [navigate]);

	const complete = useCallback(async () => {
		if (!Number(selectedCommunityToJoinInfo.id)) {
			notify("error", "Something went wrong.", "Please try again later.");
		}
		setLoading(true);

		if (
			selectedCommunityToJoinInfo.onboardingProgressBarSteps?.Checkout
				?.isVisible
		) {
			dispatch(
				setSelectedCommunityToJoinProgressBar({
					onboardingStep: EJoinCommunityOnboardingSteps["Checkout"],
				}),
			);
		}

		if (isItAWhiteLabel()) {
			return navigate("/joining/init");
		}
		navigate(
			`/communities/${selectedCommunityToJoinInfo.id}/${EProcessToJoinCommunityStage["pending status"]}`,
		);
	}, [
		dispatch,
		navigate,
		selectedCommunityToJoinInfo.id,
		selectedCommunityToJoinInfo.onboardingProgressBarSteps?.Checkout
			?.isVisible,
	]);

	useEffect(() => {
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<HelmetPageTitle screenTitle={"Communities"} />
			{loading ? (
				<Loading />
			) : (
				<>
					<OnboardingLayout
						//TODO: the header title and the header subtitle need to be updated because currently we decided to show all of them
						headerTitle={
							groupsData.length
								? `Join ${selectedCommunityToJoinInfo.name} Groups`
								: ""
						}
						headerSubtitle={
							groupsData.length
								? `Select one or more groups you want to join and start to discover our community`
								: ""
						}
						footerLayout={false}
						handleBack={back}
						handleNext={complete}
					>
						<section className={styles.groupsWrapper}>
							{
								<>
									{groupsData.length > 0 ? (
										<InfiniteScroll
											style={{
												display: "flex",
												flex: 1,
												flexDirection: "column",
												gap: "20px",
												width: "100%",
												// padding: "20px 0px",
											}}
											pageStart={1}
											loadMore={loadMoreGroups}
											initialLoad={true}
											hasMore={
												groups.meta?.pagination
													.has_next_page
											}
											loader={
												<div
													key={0}
													style={{
														padding: "12px 8px",
														display: "flex",
														flexDirection: "column",
														justifyContent:
															"center",
														alignItems: "center",
													}}
												>
													<Loading />
												</div>
											}
											useWindow={false}
											getScrollParent={() =>
												document.getElementsByTagName(
													"main",
												)[0]
											}
										>
											{groupsData.map(
												(item, index: number) => (
													<JoinCommunityGroupCard
														key={
															"community-" + index
														}
														handleCancelJoinGroupRequest={
															handleCancelJoinGroupRequest
														}
														handleJoinGroup={
															handleJoinGroup
														}
														currentCommunityId={
															selectedCommunityToJoinInfo?.id
														}
														data={item}
													/>
												),
											)}
										</InfiniteScroll>
									) : (
										<div
											className={styles.emptyDataGreyText}
										>
											{"There are no groups to be shown!"}
										</div>
									)}
								</>
							}
						</section>
					</OnboardingLayout>
					{/* <div style={{width:'100%',background:'white',display:'flex',justifyContent:'center'}}>
				<div className={styles.btnContainer}>
									<CustomButton
										onClick={back}
										style={{width:'120px'}}
										// className={
										// 	styles.onboardingSecondaryBtn
										// }
									>
										<LeftOutlined
											className={
												styles.onboardingSecondartBtnIcon
											}
										/>
										{"Back"}
									</CustomButton>
									<CustomButton
										htmlType="submit"
										varient="solid"
										style={{width:'120px'}}

										onClick={complete}
										// className={classNames(
										// 	styles.primaryColorBackgroundBtn,
										// 	styles.onboardingPrimaryBtnSpaceBetweenTxt,
										// )}
									>
										{primaryButtonLabel}
										<RightOutlined />
									</CustomButton>
								</div>
								</div> */}
				</>
			)}
		</>
	);
};

export default memo(Groups);
