import { whiteLabelConfig } from "config";
import DOMPurify from "dompurify";
import { ICommunityMemberPlan, IMemberPlanPriceOptions } from "models";
import moment, { Moment } from "moment";

export * from "./useDebounce";
export * from "./GetImg";
export * from "./useLinkify";
export * from "./useTrimmedText";
export * from "./useReactHtmlReplace";
export * from "./convertMentionIntoHref";
export * from "./useShowDescriptionWithMentions";
export * from "./useCheckForCommunityApproval";
export * from "./useUnload";
export * from "./useCheckInvitesAndJoinedCommunities";
export * from "./useComputePostImgSize";
export * from "./useSessionStorage";
export * from "./useResponsiveQuery";
export * from "./useParseCommunityTags";
export * from "./useSelectCommunityByProcess";
export * from "./usePeriodOfPayment";
export * from "./useHookWithRefCallback";
export * from "./useResizeObserver";
export * from "./useMemberPlanPrice";
export { default as rollbar } from "./rollbar";
export { default as OneSignalAuth } from "./oneSignal";
export * from './getCookie';

export const getRegexMatches = ({
    string,
    regex,
    index,
}: {
    string: string;
    regex: any;
    index: number;
}) => {
    index || (index = 1); // default to the first capturing group
    var matches = [];
    var match;
    while ((match = regex.exec(string))) {
        // @ts-ignore
        matches.push(match[index]);
    }
    return matches;
};

export const mentionRegex: RegExp = /(?:@)(.*?)(\[uid: \d+\])/gimu;
export const mentionsInputLibraryDefaultRegex: RegExp =
    /(?:@)(\[.*?\])(\(\d+\))/gimu;
export const announcementLinkRegex: RegExp = new RegExp(
    /\{((?:video_id|resource_id|document_id):\s'\d+'),\s(display:\s'.[^}]*')\}/gimu,
);
// export const mentionRegex = /@(((\p{L}|\p{N}|\p{S}|_|\(|\)|\[|\]|-)+\s)+(\[uid:\s([0-9]*)\])+)/giu;
// export const mentionRegex = /(((@.+?)(\[uid: (.+?))\])+)/giu;
// export const mentionRegex = /@((\p{L})+\s)+\[uid:\s(.*)\]/giu;
// export const BACKEND_URIS: { [key: string]: string } = {
//   local: "https://dashboard.ugenie.io/api/v1",
//   development: "https://dashboard.ugenie.io/api/v1",
//   staging: "https://staging.ugenie.io/api/v1",
//   production: "https://production.ugenie.io/api/v1",
// };

//** The LocalStorageKey is used to store the authorization token that is used along the request. */
export const LocalStorageKey =
    "$2y$12$R/zvQNEuwgBbsUUn.vtfN.dGZR459H7Ounekpi6kdRMkIoF5ODkie";
//** The LocalStorageKeyCustomToken is used to store the user's id, community's id and the authorization token that is used along the request. */
export const LocalStorageKeyCustomToken =
    "$2y$12$R/zvQNEuwgBbsUUn.vtfN.dGZR459H7Ounekpi6kdRMkcIoFuOst";
export const CustomTokenPassword = "thisisasimplepassword#33";

export const PAYMENT_INTERVAL = {
    DAILY: "DAY",
    WEEKLY: "WEEK",
    MONTHLY: "MONTH",
    YEARLY: "YEAR",
};

export const scrollTopContainer = (element: any, duration: number = 20) => {
    const intervalId = setInterval(() => {
        if (element.scrollTop > 0) {
            element.scrollTop = element.scrollTop - 50;
        } else {
            clearInterval(intervalId);
        }
    }, duration);
};

export const getKeyValue =
    <U extends keyof T, T extends object>(key: U) =>
        (obj: T) =>
            obj[key];

export const colors = {
    grey10: "#21252A",
    grey9: "#343A40",
    grey8: "#495057",
    grey7: "#868E96",
    grey6: "#ADB5BD",
    grey5: "#CFD4DA",
    grey4: "#DEE2E6",
    grey3: "#E7EBEC",
    grey2: "#F1F3F5",
    grey1: "#F8F9FA",
    darkGreen: "#00541D",
    gren: "#0BC24A",
    lightGreen: "#94F1B3",
    darkRed: "#B51515",
    red: "#E53232",
    lightRed: "#FFD4D4",
    darkBlue: "#16549C",
    blue: "#177BF1",
    lightBlue: "#BBD8F9",
    darkOrange: "#A95F1B",
    orange: "#F29137",
    lightOrange: "#FFCC9D",
};

export const PostImgSize = {
    xs: "300x200",
    sm: "500×334",
    md: "750×500",
    l: "1000x667",
    xl: "1500x1000",
};

export const forceDownloadByMimeType = [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
    "application/vnd.ms-word.document.macroEnabled.12",
    "application/vnd.ms-word.template.macroEnabled.12",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
    "application/vnd.ms-excel.sheet.macroEnabled.12",
    "application/vnd.ms-excel.template.macroEnabled.12",
    "application/vnd.ms-excel.addin.macroEnabled.12",
    "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.openxmlformats-officedocument.presentationml.template",
    "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
    "application/vnd.ms-powerpoint.addin.macroEnabled.12",
    "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
    "application/vnd.ms-powerpoint.template.macroEnabled.12",
    "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
    "application/vnd.ms-access",
    // "application/pdf",
    "text/plain",
    "text/csv",
    "application/zip",
    ".zip",
    "application/x-zip-compressed",

    // "image/png",
    // "image/jpeg",
    // "image/gif",
    // "image/webp",
    "application/octet-stream",
];

export const whitelistSrc = [
    "https://dashboard.ugenie.io/profile-fallback-thumb.png",
];

export const parseContentWithMentions = ({
    content = "",
    typeOfConversion,
}: {
    content: string;
    typeOfConversion: "fromInputToSystem" | "fromSystemToInput";
}) => {
    if (content === "") {
        return { source: "", mentionsRawList: {} };
    } else {
        let source = DOMPurify.sanitize(content, {
            ALLOWED_TAGS: ["a"],
            ALLOWED_ATTR: ["href"],
        });

        let instances: any = null;
        if (typeOfConversion === "fromInputToSystem") {
            instances = source.match(mentionsInputLibraryDefaultRegex);
        } else {
            instances = source.match(mentionRegex);
        }

        if (instances) {
            const instancesLength = instances.length;
            for (let i = 0; i < instancesLength; i++) {
                // get the match for every capturing group.
                if (typeOfConversion === "fromInputToSystem") {
                    const match = mentionsInputLibraryDefaultRegex.exec(source);

                    if (match) {
                        const userName = match[1].replace(/[[\]]+/g, "").trim();
                        const userId = match[2].replace(/[()]+/g, "").trim();
                        source = source.replace(
                            instances[i],
                            `@${userName} [uid: ${userId}]`,
                        );
                    }
                } else {
                    const match = mentionRegex.exec(source);
                    if (match) {
                        const userName = match[1].trim();
                        const userId = match[2]
                            .replace(/[[uid:\]]+/g, "")
                            .trim();
                        source = source.replace(
                            instances[i],
                            `@[${userName}](${userId})`,
                        );
                    }
                }
            }
        }
        return { source };
    }
};

export const hexToRgbA = (hex: string) => {
    let c: any;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return "" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",");
    }
    throw new Error("Bad Hex");
};

export const timeSince = (date: string) => {
    const checkedISODateTime: null | RegExpMatchArray = date.match(
        `\\d{4}-[01]\\d-[0-3]\\dT[0-2]\\d:[0-5]\\d:[0-5]\\d\\.\\d+([+-][0-2]\\d:[0-5]\\d|Z)`,
    );
    if (checkedISODateTime) {
        let parseTimestamp: any = new Date(date);
        moment.locale("en", {
            relativeTime: {
                future: "in %s",
                past: "%s ago",
                s: "seconds",
                m: "1 minute", //change that to "%d minute"
                mm: "%d minutes",
                h: "1 hour", //here too
                hh: "%d hours",
                d: "1 day", //and here
                dd: "%d days",
                M: "1 month", //and so on...
                MM: "%d months",
                y: "1 year",
                yy: "%d years",
            },
        });

        var seconds = Math.floor(((new Date() as any) - parseTimestamp) / 1000);

        var interval = seconds / 31536000;

        if (interval > 1) {
            return moment
                .duration(-Math.floor(interval), "years")
                .humanize(true);
        }
        interval = seconds / 2592000;
        if (interval > 1) {
            return moment
                .duration(-Math.floor(interval), "months")
                .humanize(true);
        }
        interval = seconds / 86400;
        if (interval > 1) {
            return moment
                .duration(-Math.floor(interval), "days")
                .humanize(true);
        }
        interval = seconds / 3600;
        if (interval > 1) {
            return moment
                .duration(-Math.floor(interval), "hours")
                .humanize(true);
        }
        interval = seconds / 60;
        if (interval > 1) {
            return moment
                .duration(-Math.floor(interval), "minutes")
                .humanize(true);
        }
        return moment.duration(-Math.floor(interval), "seconds").humanize(true);
    } else {
        return date;
    }
};

export function settingSelectedCommunityToJoinColors({
    headerBackground = null,
    headerBackgroundRgb = null,
    announcementHeaderBackground = null,
    announcementHeaderBackgroundRgb = null,
    primaryColor = null,
    primaryColorRgb = null,
}: {
    headerBackground?: string | null;
    headerBackgroundRgb?: string | null;
    announcementHeaderBackground?: string | null;
    announcementHeaderBackgroundRgb?: string | null;
    primaryColor?: string | null;
    primaryColorRgb?: string | null;
} = {}) {
    document.documentElement.style.setProperty(
        "--selectedCommunityToJoinHeaderBackground",
        headerBackground,
    );
    document.documentElement.style.setProperty(
        "--selectedCommunityToJoinHeaderBackgroundRgb",
        headerBackgroundRgb,
    );

    document.documentElement.style.setProperty(
        "--selectedCommunityToJoinAnnouncementHeaderBackground",
        announcementHeaderBackground,
    );
    document.documentElement.style.setProperty(
        "--selectedCommunityToJoinAnnouncementHeaderBackgroundRgb",
        announcementHeaderBackgroundRgb,
    );
    document.documentElement.style.setProperty(
        "--selectedCommunityToJoinPrimaryColor",
        primaryColor,
    );
    document.documentElement.style.setProperty(
        "--selectedCommunityToJoinPrimaryColorRgb",
        primaryColorRgb,
    );
}

export function settingDefaultPalleteOfColors() {
    // TODO: this function needs to be rewritten to use scss variables
    document.documentElement.style.setProperty("--headerBackground", "#126887");
    document.documentElement.style.setProperty(
        "--headerBackgroundRgb",
        hexToRgbA("#126887"),
    );

    document.documentElement.style.setProperty(
        "--announcementHeaderBackground",
        "#25bed8",
    );
    document.documentElement.style.setProperty(
        "--announcementHeaderBackgroundRgb",
        hexToRgbA("#25bed8"),
    );
    document.documentElement.style.setProperty("--primaryColor", "#0688a9");
    document.documentElement.style.setProperty(
        "--primaryColorRgb",
        hexToRgbA("#0688a9"),
    );
}

export const validateEmail = (mail: string): boolean => {
    // eslint-disable-next-line no-useless-escape
    if (/^\w+([\.\-\+]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+\s*$/.test(mail)) {
        return true;
    }
    return false;
};

export const disabledDate = (current: Moment) => {
    const yesterday = moment().add(-1, "days").startOf("day");
    const currentDate = moment(current).startOf("day");
    return moment(currentDate).isSameOrBefore(yesterday);
};

export const communityPlansCurrenciesList = (
    plans: ICommunityMemberPlan[] | null | undefined,
): null | Set<string> => {
    if (!plans || plans?.length === 0) return null;
    let currencies = new Set<string>();
    const plansLength = plans.length;
    for (let i = 0; i < plansLength; i++) {
        const planPriceOptions = plans[i].price_options;
        const planPriceOptionsLength = planPriceOptions?.length ?? 0;
        if (!planPriceOptions || planPriceOptionsLength === 0) continue;
        for (let j = 0; j < planPriceOptionsLength; j++) {
            currencies.add(planPriceOptions[j].currency);
        }
    }
    return currencies;
};

export const getMemberPlanPriceOptionByCurrency = (
    selectedCurrency?: string,
    priceOptions?: IMemberPlanPriceOptions[],
): null | IMemberPlanPriceOptions => {
    if (!selectedCurrency || !priceOptions || priceOptions?.length === 0)
        return null;

    const resultedPrice = priceOptions.find(
        (item) =>
            item.currency.toLowerCase().trim() ===
            selectedCurrency.toLowerCase().trim(),
    );

    return resultedPrice ?? null;
};

export const getMemberPlanPriceTagByStripePlanPriceId = (
    stripePlanPriceId?: string,
    priceOptions?: IMemberPlanPriceOptions[],
): undefined | string => {
    if (!stripePlanPriceId || !priceOptions || priceOptions?.length === 0)
        return undefined;

    const resultedPrice = priceOptions.find(
        (item) => item.stripe_id === stripePlanPriceId,
    );

    return resultedPrice?.price_tag ?? undefined;
};

export const getCurrentSubscriptionPriceOption = (
    currentSubscriptionPlanPriceOptions: IMemberPlanPriceOptions[],
    currentSubscritpionPlanStripeId: string,
): null | IMemberPlanPriceOptions => {
    if (
        !currentSubscriptionPlanPriceOptions ||
        currentSubscriptionPlanPriceOptions?.length === 0 ||
        !currentSubscritpionPlanStripeId
    )
        return null;
    return (
        currentSubscriptionPlanPriceOptions.find(
            (item) => item.stripe_id === currentSubscritpionPlanStripeId,
        ) ?? null
    );
};

export const isItAWhiteLabel = () => {
    return whiteLabelConfig?.community?.id ?? false;
};
