import React, { memo, useState } from "react";

import { IImage, IMarketplaceOfferRawData } from "models";
import styles from "./styles.module.scss";

interface IImagesSection extends Pick<IMarketplaceOfferRawData, "images"> {}

const ImagesSection: React.FunctionComponent<IImagesSection> = ({
	images = [],
}) => {
	const [selectedImg, setSelectedImg] = useState(
		images.length ? images[0] : undefined,
	);

	const handleSelectedImg = (item: IImage) => () => {
		setSelectedImg(item);
	};

	return (
		<div className={styles.container}>
			<div className={styles.featuredImage}>
				<img src={selectedImg?.image} alt={"marketplace"} />
			</div>
			{images.length > 1 && (
				<div className={styles.imageList}>
					{images.map((item, index) => {
						return (
							<div className={styles.previewImage}>
								<img
									onClick={handleSelectedImg(item)}
									key={item.id}
									src={item.image}
									alt={`list-${index}`}
								/>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};

export default memo(ImagesSection);
