import { memo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { Button, Modal } from "antd";
import { useNavigate } from "react-router-dom";

import styles from "./styles.module.scss";
import { EChangeSubscriptionStage, TStore } from "models";
import { changeSubscriptionStageAction } from "store";
import { ReactComponent as X } from "assets/images/x.svg";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.subscription.currentMemberPlan,
	(state: TStore) => state.subscription.subscription,
	(memberPlan, memberSubscription) => ({
		memberPlan,
		memberSubscription,
	}),
);

const ManageSubscriptionBtn = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [
		isChangeSubscriptionModalVisible,
		setIsChangeSubscriptionModalVisible,
	] = useState(false);
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { memberPlan, memberSubscription } = useSelector(stateSelector);
	const currentPlan = memberSubscription
		? memberSubscription.plan
		: memberPlan;

	const toggleChangeSubscriptionModal = useCallback(() => {
		setIsChangeSubscriptionModalVisible(!isChangeSubscriptionModalVisible);
	}, [isChangeSubscriptionModalVisible]);

	const changePlan = useCallback(() => {
		dispatch(
			changeSubscriptionStageAction({
				stage: EChangeSubscriptionStage.memberPlans,
			}),
		);
		navigate("/subscription-management/change-plan");
	}, [navigate, dispatch]);

	return (
		<>
			<div className={styles.container}>
				{!currentPlan ? null : (
					<div className={styles.buttonContainer}>
						<Button
							onClick={toggleChangeSubscriptionModal}
							type="link"
							size={"middle"}
							style={{ color: "#fff" }}
						>
							{"Change Plan"}
						</Button>
					</div>
				)}
			</div>
			<Modal
				title={"Change Subscription"}
				visible={isChangeSubscriptionModalVisible}
				onOk={changePlan}
				okType={"danger"}
				centered
				closable={false}
				cancelText={"Cancel"}
				cancelButtonProps={{
					id: "btn-cancel-delete-account",
				}}
				okButtonProps={{ id: "btn-ok-leave-group" }}
				okText={"Yes. I am sure"}
				onCancel={toggleChangeSubscriptionModal}
				className={"modalContainer"}
				getContainer={() =>
					document.getElementById(
						"subscription-management-layout",
					) as HTMLElement
				}
				maskClosable={false}
			>
				<div className={styles.modalContent}>
					<div>
						{
							"Are you sure you want to change the current subscription?"
						}
					</div>
				</div>
				<div
					onClick={toggleChangeSubscriptionModal}
					className={"closeModal"}
				>
					<X fill="#FFFFFF" width={13} height={13} />
				</div>
			</Modal>
		</>
	);
};

export default memo(ManageSubscriptionBtn);
