import { IAppState, EAppActionTypes, TAppActions } from "models";

const initial: IAppState = {
	loading: true,
	currentCommunityId: null,
	currentGroupId: null,
	messagesCounter: 0,
	feedPostsContainerWidth: 1024, // max. value
	siderMenu: {
		showJoinedCommuntiesList: false,
		collapsed: false,
	},
	welcomeModal: false,
};

export function appReducer(state = initial, action: TAppActions): IAppState {
	switch (action.type) {
		case EAppActionTypes.appLoadingSet: {
			return {
				...state,
				loading: action.payload,
			};
		}
		case EAppActionTypes.appSetData: {
			const { currentCommunityId, currentGroupId } = action.payload;
			return {
				...state,
				currentCommunityId: currentCommunityId,
				currentGroupId: currentGroupId,
			};
		}
		case EAppActionTypes.appSetGroup: {
			const { currentGroupId } = action.payload;
			return {
				...state,
				currentGroupId: currentGroupId,
			};
		}
		case EAppActionTypes.updateMessageCounter: {
			return {
				...state,
				messagesCounter: action.payload,
			};
		}
		case EAppActionTypes.setFeedPostsContainerWidth: {
			return {
				...state,
				feedPostsContainerWidth: action.payload.containerWidth,
			};
		}
		case EAppActionTypes.siderMenuSetShowJoinedCommunitiesList: {
			return {
				...state,
				siderMenu: {
					...state.siderMenu,
					showJoinedCommuntiesList: action.payload,
				},
			};
		}
		case EAppActionTypes.siderMenuSetCollapsed: {
			return {
				...state,
				siderMenu: {
					...state.siderMenu,
					collapsed: action.payload,
				},
			};
		}
		case EAppActionTypes.SetWelcomeModal: {
			return {
				...state,
				welcomeModal: action.payload,
			};
		}
		default:
			return state;
	}
}
