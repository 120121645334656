interface IUsePeriodOfPayment {
	priceTag?: "free" | string;
	intervalCount?: number;
	interval?: string;
}

export const usePeriodOfPayment = ({
	interval,
	intervalCount,
	priceTag,
}: IUsePeriodOfPayment): string | null => {
	if (!priceTag) return null;
	if (priceTag === "free") return null;
	else if (interval && intervalCount && intervalCount > 0) {
		return intervalCount === 1
			? `${interval.toLowerCase()}`
			: `${intervalCount} ${interval.toLowerCase()}s`;
	} else {
		return null;
	}
};
