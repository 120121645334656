import React, { memo, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";

import { ReactComponent as DownloadIcon } from "assets/images/icons/common_icons/document_download_icon.svg";
import { ReactComponent as DeleteIcon } from "assets/images/icons/common_icons/delete.svg";
import { ReactComponent as ViewDocument } from "assets/images/icons/common_icons/join_group.svg";
import { ReactComponent as X } from "assets/images/x.svg";
import {
	UseShowDescriptionWithMentions,
	forceDownloadByMimeType,
	rollbar,
	useParseCommunityTags,
} from "utils";
import { IDoc, TStore } from "models";
import {
	deleteDocsAction,
	deleteResourceAction,
	deleteVediosAction,
	getDocs,
	getResources,
	getVideos,
	incrementDocViewCountAction,
	incrementVideoViewCountAction,
	notify,
	setDocsFilterTagsAction,
	setResourcesFilterTagsAction,
	setVideosFilterTagsAction,
} from "store";
import DocumentTypeIcon from "components/DocumentTypeIcon";
import styles from "./styles.module.scss";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.posts.meta.appearance,
	(state: TStore) => state.auth.credentials?.user.membership_id,

	(postsMeta, auth_member_id) => ({
		postsMeta,
		auth_member_id,
	}),
);

export interface IMediaBox extends IDoc {
	type: "videos" | "documents" | "resources";
	id: number;
	title: string;
	mime_type: string;
	description: string;
	search_tags: any;
	attachment: any;
	mentions: string[];
	thumbnail?: any;
	tags?: any;
	vimeo_thumbnail_url?: any;
	membership_id?: number;
	image?: { url?: string };
}

const MediaBox: React.FunctionComponent<IMediaBox> = (props) => {
	const navigate = useNavigate();
	const {
		id,
		title,
		mime_type,
		thumbnail,
		vimeo_thumbnail_url,
		description,
		tags,
		type,
		search_tags,
		attachment,
		mentions,
		membership_id,
		image,
	} = props;
	const { parsedTags } = useParseCommunityTags({ tags, search_tags });
	const dispatch = useDispatch();
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { auth_member_id } = useSelector(stateSelector);
	const [confirmModalVisible, setConfirmModalVisible] = useState(false);
	const [isImageBroken, setIsImageBroken] = useState(false);
	const check_mime_type = [
		"application/pdf",
		"text/plain",
		"text/csv",
		"image/png",
		"image/jpeg",
		"image/gif",
		"image/webp",
		"image/jpg",
	];
	const newVimeoUrl = useMemo(() => {
		if (vimeo_thumbnail_url) {
			const byUnderscore = vimeo_thumbnail_url.split("_");
			const byDot = byUnderscore[1].split(".");

			return `${byUnderscore[0]}_320x180${
				byDot[1] ? "." + byDot[1] : ""
			}`;
		} else {
			return undefined;
		}
	}, [vimeo_thumbnail_url]);

	const watchTheVideo = useCallback(
		(itemId: number, event) => () => {
			if (event.target.id.toLowerCase() === "read_more") return;

			if (
				check_mime_type.indexOf(mime_type) !== -1 ||
				thumbnail ||
				vimeo_thumbnail_url
			) {
				navigate(`/${type}/${itemId}`);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[mime_type, thumbnail, vimeo_thumbnail_url, navigate, type],
	);

	const handleDelete = useCallback(
		(event: React.MouseEvent<HTMLElement, MouseEvent>, Id: number) => {
			event.stopPropagation();
			try {
				switch (type) {
					case "videos":
						dispatch(deleteVediosAction({ videoId: Id }));
						setConfirmModalVisible(!confirmModalVisible);
						break;

					case "resources":
						dispatch(deleteResourceAction({ resourceId: Id }));
						setConfirmModalVisible(!confirmModalVisible);
						break;

					case "documents":
						dispatch(deleteDocsAction({ docId: Id }));
						setConfirmModalVisible(!confirmModalVisible);
						break;
				}
			} catch (error) {
				// Handle the error (e.g., display an error message, log the error, etc.)
				rollbar.error(error);
			}
		},
		[confirmModalVisible, dispatch, type],
	);

	const handleViewCountForDownload = useCallback(
		(id: number) => {
			if (!type) {
				notify("error", "An error occurred. Please try again later.");
			}
			switch (type) {
				case "videos":
					dispatch(incrementVideoViewCountAction(id));
					break;
				case "resources":
					dispatch(incrementDocViewCountAction(id));

					break;
				case "documents":
					dispatch(incrementDocViewCountAction(id));
					break;
			}
		},
		[dispatch, type],
	);

	const handleTagFiltering = useCallback(
		(event: React.MouseEvent<HTMLElement, MouseEvent>, tag: string) => {
			event.stopPropagation();

			if (!type || !tag) {
				notify("error", "An error occurred. Please try again later.");
				return;
			}
			try {
				const parsedTag = tag.substring(1);
				switch (type) {
					case "videos":
						dispatch(
							setVideosFilterTagsAction({ tag: [parsedTag] }),
						);
						dispatch(
							getVideos({
								reset: true,
							}),
						);
						break;
					case "resources":
						dispatch(
							setResourcesFilterTagsAction({ tag: [parsedTag] }),
						);
						dispatch(
							getResources({
								reset: true,
							}),
						);
						break;
					case "documents":
						dispatch(setDocsFilterTagsAction({ tag: [parsedTag] }));
						dispatch(
							getDocs({
								reset: true,
							}),
						);
						break;
				}
			} catch (error) {
				rollbar.error(error);
			}
		},
		[dispatch, type],
	);

	return (
		<>
			<div
				className={styles.videoBox}
				style={
					check_mime_type.indexOf(mime_type) !== -1 ||
					thumbnail ||
					vimeo_thumbnail_url
						? {}
						: { cursor: "default" }
				}
				onClick={(event) => watchTheVideo(id, event)()}
			>
				<div className={styles.videoHolder}>
					{!isImageBroken &&
					image?.url !== null &&
					image?.url !==
						"https://dashboard.ugenie.io/community_document_image_placeholder.png" ? (
						<div style={{ textAlign: "center" }}>
							<img
								onError={() => setIsImageBroken(true)}
								loading={"lazy"}
								src={
									image?.url
										? image.url
										: vimeo_thumbnail_url
										? newVimeoUrl
										: ""
								}
								style={
									thumbnail || vimeo_thumbnail_url
										? {
												objectFit: "cover",
												aspectRatio: 1,
												borderRadius: "8px",
										  }
										: {}
								}
								alt={"thumbnail"}
							/>
							<div className={styles.iconHolder}>
								<DocumentTypeIcon
									type={mime_type}
									videoUrl={thumbnail}
									thumbnail={true}
								/>
							</div>
						</div>
					) : thumbnail || vimeo_thumbnail_url ? (
						<img
							loading={"lazy"}
							src={
								thumbnail
									? thumbnail
									: vimeo_thumbnail_url
									? newVimeoUrl
									: ""
							}
							style={
								thumbnail || vimeo_thumbnail_url
									? {
											width: "100%",
											objectFit: "cover",
											aspectRatio: 1,
											borderRadius: "8px",
									  }
									: {}
							}
							alt={"thumbnail"}
						/>
					) : (
						<DocumentTypeIcon
							type={mime_type}
							videoUrl={thumbnail}
							thumbnail={false}
						/>
					)}
				</div>
				<div className={styles.positionedIconHolder}>
					<DocumentTypeIcon
						type={mime_type}
						videoUrl={thumbnail}
						thumbnail={true}
					/>
				</div>
				<div className={styles.videoDetails}>
					<h2>{title}</h2>
					<div className={styles.description}>
						<UseShowDescriptionWithMentions
							text={description}
							mentions={mentions}
						/>
					</div>
					{parsedTags?.length > 0 && (
						<div className={styles.tagsContainer}>
							{parsedTags.map((item: any, index: number) => {
								return (
									<small
										key={`tag_${index}_${item}`}
										className={styles.tag}
										onClick={(event) =>
											handleTagFiltering(event, item)
										}
									>
										{item}
									</small>
								);
							})}
						</div>
					)}
					<div className={styles.groupButtons}>
						<Button
							className={styles.viewButton}
							style={{
								display:
									check_mime_type.indexOf(mime_type) !== -1 ||
									vimeo_thumbnail_url ||
									thumbnail
										? "inherit"
										: "none",
							}}
							onClick={() => {
								navigate(`/${type}/${id}`);
							}}
						>
							<ViewDocument
								fill={"currentColor"}
								width={20}
								height={20}
							/>{" "}
							{vimeo_thumbnail_url || thumbnail
								? "View Video"
								: "View Document"}
						</Button>
						{forceDownloadByMimeType.indexOf(mime_type) !== -1 && (
							<a
								style={{ marginTop: "auto" }}
								href={attachment?.url}
								download={"Document " + id}
							>
								<Button
									className={styles.viewButton}
									onClick={() => {
										handleViewCountForDownload(id);
									}}
									icon={
										<DownloadIcon
											width={20}
											height={20}
											fill={"currentColor"}
										/>
									}
								>
									Download
								</Button>
							</a>
						)}
						{membership_id?.toString() === auth_member_id && (
							<Button
								onClick={(e) => {
									e.stopPropagation();
									setConfirmModalVisible(
										!confirmModalVisible,
									);
								}}
								className={styles.deleteButton}
								icon={
									<DeleteIcon
										className={styles.deleteIcon}
										width={20}
										height={20}
									/>
								}
							/>
						)}
					</div>
				</div>
			</div>
			{confirmModalVisible && (
				<Modal
					title={"Are you sure you want to Delete?"}
					visible={confirmModalVisible}
					onOk={(e) => handleDelete(e, id)}
					okType={"danger"}
					centered
					closable={false}
					cancelText={"Cancel"}
					cancelButtonProps={{ id: "btn-cancel-delete-account" }}
					okButtonProps={{ id: "btn-ok-leave-group" }}
					okText={"Yes, I am sure"}
					onCancel={() =>
						setConfirmModalVisible(!confirmModalVisible)
					}
					className={"modalContainer"}
					getContainer={() =>
						document.getElementById("content") as HTMLElement
					}
				>
					<div
						onClick={() =>
							setConfirmModalVisible(!confirmModalVisible)
						}
						className={"closeModal"}
					>
						<X fill="#FFFFFF" width={13} height={13} />
					</div>
				</Modal>
			)}
		</>
	);
};

export default memo(MediaBox);
