import {
	ILiveStreamSetLoadingAction,
	ILiveStreamSetConfigAction,
	ILiveStreamSetClientAction,
	ILiveStreamSetCodecAction,
	ILiveStreamSetVideoAction,
	ILiveStreamSetAudioAction,
	ILiveStreamSetScreenAction,
	ILiveStreamSetDevicesListAction,
	ILiveStreamSetLocalStreamAction,
	ILiveStreamSetProfileAction,
	ILiveStreamSetCurrentStreanAction,
	ILiveStreamAddStreamAction,
	ILiveStreamRemoveStreamAction,
	ILiveStreamClearAllStreamAction,
} from "./actions";

// ENUMS
export enum ELiveStreamActionTypes {
	setLoading = "livestream/set/loading",
	setConfig = "livestream/set/config",
	setClient = "livestream/set/client",
	setCodec = "livestream/set/codec",
	setVideo = "livestream/set/video",
	setAudio = "livestream/set/audio",
	setScreen = "livestream/set/screen",
	setDevicesList = "livestream/set/devices/list",
	setLocalStream = "livestream/set/local/stream",
	setProfile = "livestream/set/profile",
	setCurrentStream = "livestream/set/current/stream",
	addStream = "livestream//add/stream",
	removeStream = "livestream/remove/stream",
	clearAllStream = "livestream/clear/all/stream",
}

// INTERFACES
export interface ILiveStreamState {
	loading: boolean;
	streams: unknown[];
	localStream: unknown;
	currentStream: unknown;
	otherStreams: unknown[];
	devicesList: unknown[];
	client?: any;
	video?: any;
	audio?: any;
	// web sdk params
	config: {
		uid: number;
		host: boolean;
		channelName: string;
		token: string; // process.env.REACT_APP_AGORA_APP_TOKEN;
		resolution: string; // "480p";
		// ...readDefaultState(),
		microphoneId: string;
		cameraId: string;
	};
	agoraClient: unknown;
	mode: string; //"live";
	codec: string; //"vp8";
	muteVideo: boolean;
	muteAudio: boolean;
	screen: boolean;
	profile: boolean;
}

// TYPES
export type TLiveStreamActions =
	| ILiveStreamSetLoadingAction
	| ILiveStreamSetConfigAction
	| ILiveStreamSetClientAction
	| ILiveStreamSetCodecAction
	| ILiveStreamSetVideoAction
	| ILiveStreamSetAudioAction
	| ILiveStreamSetScreenAction
	| ILiveStreamSetDevicesListAction
	| ILiveStreamSetLocalStreamAction
	| ILiveStreamSetProfileAction
	| ILiveStreamSetCurrentStreanAction
	| ILiveStreamAddStreamAction
	| ILiveStreamRemoveStreamAction
	| ILiveStreamClearAllStreamAction;
