import React, { memo, useCallback } from "react";
import { View, Messages, NavigateAction, Navigate } from "react-big-calendar";
import classNames from "classnames";
import { DatePicker } from "antd";
import moment from "moment";

import { ReactComponent as DownArrow } from "assets/images/downArrow.svg";
import styles from "./styles.module.scss";

interface ICustomToolbar {
	date: Date;
	view: View;
	views: any; //ViewsProps<any, any>;
	label: string;
	localizer: { messages: Messages };
	onNavigate: (navigate: NavigateAction, date?: Date) => void;
	onView: (view: View) => void;
	children?: React.ReactNode;
}

const CustomToolbar: React.FunctionComponent<ICustomToolbar> = ({
	onView,
	label,
	views,
	onNavigate,
	view: currentView,
}) => {
	const handleNavigate = (newDate?: moment.Moment | null) => {
		const date = newDate?.toDate();
		onNavigate(Navigate.DATE, date);
	};

	const handleOnView = useCallback(
		(view) => {
			onView(view);
		},
		[onView],
	);
	const downArrow = <DownArrow height={"16px"} width={"16px"} />;
	const dateSplit = label.split(" ");

	return (
		<div className={styles.container}>
			<div className={styles.monthPicker}>
				<div className={styles.monthLabel}>{dateSplit[0]}</div>
				<DatePicker
					picker="month"
					defaultValue={moment(new Date())}
					suffixIcon={downArrow}
					onChange={(e) => {
						handleNavigate(e);
					}}
				/>
			</div>
			<div className={styles.buttonsContainer}>
				<div className={styles.horizontalyButtonsContainer}>
					{views
						.filter(
							(item: "month" | "week" | "day" | "agenda") =>
								["week", "day", "agenda"].indexOf(item) === -1,
						)
						.map((view: any) => (
							<div
								className={
									view === "month"
										? classNames(styles.btn, {
												[styles.activeBtn]:
													view === currentView,
										  })
										: classNames(styles.disabledBtn)
								}
								key={view}
								onClick={() =>
									view === "month" && handleOnView(view)
								}
								aria-disabled={true}
							>
								{view}
							</div>
						))}
				</div>
			</div>
			<div className={styles.yearPicker}>
				<div className={styles.yearLabel}>{dateSplit[1]}</div>
				<DatePicker
					picker="year"
					defaultValue={moment(new Date())}
					suffixIcon={downArrow}
					onChange={(e) => handleNavigate(e)}
				/>
			</div>
		</div>
	);
};

export default memo(CustomToolbar);
