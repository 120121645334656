import React, {
	memo,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { Button, DatePicker, Form, Input } from "antd";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import moment from "moment";
import { Mention, MentionsInput, SuggestionDataItem } from "react-mentions";
import { createSelector } from "reselect";

import styles from "./styles.module.scss";
import { TStore } from "models";
import { CheckboxGroupView } from "components";
import { UploadFile } from "./components";
import {
	notify,
	createVideo,
	setVideoUploadProgressAction,
	setResourceUploadProgressAction,
} from "store";
import { disabledDate, parseContentWithMentions } from "utils";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.community.mainTags,
	(state: TStore) => state.video.loading,
	(state: TStore) => state.groups.data,
	(state: TStore) => state.videos?.path,
	(state: TStore) => state.members.users,
	(state: TStore) => state.app.currentGroupId,
	(state: TStore) => state.resources?.path,
	(state: TStore) => state.auth?.credentials?.user?.membership_type,

	(
		communityTags,
		videoLoading,
		groups,
		videosPath,
		members,
		currentGroupId,
		resourcesPath,
		authUserMembership,
	) => ({
		communityTags,
		videoLoading,
		groups: groups.filter((group: any) => group.is_joined),
		videosPath,
		members,
		currentGroupId,
		resourcesPath,
		authUserMembership,
	}),
);
const DESCRIPTION_MAX_NO_OF_CHARACTERS = 5000;

interface IUploadVideoForm {
	closeTheForm?: (askTheUserToConfirm?: boolean) => void;
}
const UploadVideoForm: React.FunctionComponent<IUploadVideoForm> = ({
	closeTheForm,
}) => {
	const stateSelector = useCallback(stateSelectorHandle, []);
	const {
		communityTags,
		videoLoading,
		groups,
		videosPath,
		members,
		currentGroupId,
		resourcesPath,
		authUserMembership,
	} = useSelector(stateSelector);
	const [
		descriptionNoOfCharactersRemaining,
		setDescriptionNoOfCharactersRemaining,
	] = useState(DESCRIPTION_MAX_NO_OF_CHARACTERS);
	const dispatch = useDispatch();
	const [isVideoLink, setIsVideoLink] = useState(false);
	const [isVideo, setIsVideo] = useState(false);
	const location = useLocation();
	const [form] = Form.useForm();
	const watch = Form.useWatch("audienceCheckboxGroup", form);
	useEffect(() => {
		form.setFieldValue("audienceCheckboxGroup", [authUserMembership]);
	}, [authUserMembership, form]);
	const suggestionsPortalHostRef = useRef<any>(undefined);

	const users = useMemo(() => {
		return members?.map((item) => {
			return { id: item.id, display: item.full_name };
		});
	}, [members]);

	const handleChangePostBody = useCallback(
		(event: { target: { value: string } } | undefined) => {
			if (!event) return;
			let parsedBody = event.target.value;

			if (event.target.value.length > 5000) {
				parsedBody = event.target.value.substring(0, 5000);
			}
			setDescriptionNoOfCharactersRemaining(5000 - parsedBody.length);

			form.setFieldsValue({
				longDescription: parsedBody, // source
			});
		},
		[form],
	);

	const handleRenderSuggestions = useCallback(
		(
			suggestion: SuggestionDataItem,
			search: string,
			highlightedDisplay: React.ReactNode,
			index: number,
			focused: boolean,
		) => {
			return (
				<div
					key={`key_${index}`}
					className={classnames(styles.listItem, {
						[styles.listItemFocused]: focused,
					})}
				>
					{highlightedDisplay}
				</div>
			);
		},
		[],
	);

	const handleUploadError = useCallback(() => {
		notify(
			"error",
			"The upload failed",
			"Sorry ... we could not upload your video",
		);
	}, []);

	const handleUploadSuccess = useCallback(
		(approve?: boolean) => {
			if (closeTheForm) closeTheForm();
		},
		[closeTheForm],
	);

	const onValuesChange = useCallback(
		(changedValues: {
			document: any[];
			audienceCheckboxGroup: any[];
			groupsCheckboxGroup: any[];
			video_link: string;
			video: any;
			expirationDate: Date;
		}) => {
			switch (Object.keys(changedValues)[0]) {
				case "video": {
					if (changedValues.video.length > 0) {
						setIsVideo(true);
					} else {
						setIsVideo(false);
					}
					break;
				}
				case "video_link": {
					if (changedValues.video_link.length > 0) {
						setIsVideoLink(true);
					} else {
						setIsVideoLink(false);
					}
					break;
				}
				case "document": {
					if (changedValues.document.length > 1)
						form.setFieldsValue({
							document: [
								changedValues.document[
									changedValues.document.length - 1
								],
							],
						});
					break;
				}
				case "audienceCheckAll": {
					form.setFieldsValue({
						audienceCheckboxGroup: communityTags.memberTypes.map(
							(item: any) => item.name,
						),
					});
					break;
				}
				case "audienceCheckNone": {
					form.setFieldsValue({
						audienceCheckboxGroup: [authUserMembership],
					});
					break;
				}
				case "groupsCheckAll": {
					form.setFieldsValue({
						groupsCheckboxGroup: groups.map((item: any) => item.id),
					});

					break;
				}
				case "groupsCheckNone": {
					form.setFieldsValue({
						groupsCheckboxGroup: [],
					});

					break;
				}
				case "audienceCheckboxGroup": {
					// check if all checkboxes are checked
					if (
						changedValues.audienceCheckboxGroup.length ===
						communityTags.memberTypes.length
					) {
						form.setFieldsValue({
							audienceCheckAll: true,
						});
					} else {
						form.setFieldsValue({
							audienceCheckAll: false,
						});
					}
					break;
				}
				case "groupsCheckboxGroup": {
					// check if all checkboxes are checked
					if (
						changedValues.groupsCheckboxGroup.length ===
						groups.length
					) {
						form.setFieldsValue({
							groupsCheckAll: true,
						});
					} else {
						form.setFieldsValue({
							groupsCheckAll: false,
						});
					}
					break;
				}
				case "expirationDate": {
					if (moment.isMoment(changedValues.expirationDate)) {
						form.setFieldsValue({
							expirationDate:
								changedValues.expirationDate.endOf("day"),
						});
					} else {
						form.setFieldsValue({
							expirationDate: undefined,
						});
					}
					break;
				}
				default:
			}
		},
		[authUserMembership, communityTags.memberTypes, form, groups],
	);

	const onVideoUploadProgress = useCallback(
		(progressEvent: any) => {
			var percentCompleted = Math.round(
				(progressEvent.loaded * 100) / progressEvent.total,
			);
			dispatch(setVideoUploadProgressAction(percentCompleted));
		},
		[dispatch],
	);

	const onResourceUploadProgress = useCallback(
		(progressEvent: any) => {
			var percentCompleted = Math.round(
				(progressEvent.loaded * 100) / progressEvent.total,
			);
			dispatch(setResourceUploadProgressAction(percentCompleted));
		},
		[dispatch],
	);

	const onFinish = useCallback(
		(values: {
			title: string;
			video: any;
			longDescription?: string;
			expirationDate?: Date;
			audienceCheckboxGroup?: string[];
			groupsCheckboxGroup?: number[];
			tagsCheckboxGroup?: string[];
			video_link?: string;
		}) => {
			const {
				audienceCheckboxGroup,
				groupsCheckboxGroup,
				tagsCheckboxGroup,
				video_link,
				longDescription,
			} = values;

			try {
				const { source } = parseContentWithMentions({
					content: longDescription ? longDescription : "",
					typeOfConversion: "fromInputToSystem",
				});
				const allGroups = currentGroupId
					? false
					: !groupsCheckboxGroup || groupsCheckboxGroup?.length === 0
					? true
					: false;

				dispatch(
					createVideo({
						...values,
						longDescription: source,
						audienceGroup: audienceCheckboxGroup,
						groupsGroup: currentGroupId
							? [currentGroupId]
							: groupsCheckboxGroup,
						tagsGroup: tagsCheckboxGroup,
						video: values.video
							? values.video[0]?.originFileObj
							: null,
						video_link: video_link ? video_link : null,
						onError: handleUploadError,
						onSuccess: handleUploadSuccess,
						all_groups: allGroups,
						is_private: !allGroups,
						parent:
							location.pathname === "/videos"
								? "Videos"
								: "Resources",
						videoUploadProgress: onVideoUploadProgress,
						resourceUploadProgress: onResourceUploadProgress,
					}),
				);
			} catch (error) {}
		},
		[
			currentGroupId,
			dispatch,
			handleUploadError,
			handleUploadSuccess,
			location.pathname,
			onResourceUploadProgress,
			onVideoUploadProgress,
		],
	);

	const initialValues = useMemo(
		() => ({
			audienceCheckAll: false,
			groupsCheckboxGroup: [],
			longDescription: "",
		}),
		[],
	);
	const folderPath = useMemo(() => {
		const defaultPath = "Home";
		if (location.pathname === "/videos" && videosPath.length > 0) {
			return videosPath.slice(-1).shift()?.name ?? defaultPath;
		} else if (
			location.pathname === "/resources" &&
			resourcesPath.length > 0
		) {
			return resourcesPath.slice(-1).shift()?.name ?? defaultPath;
		} else {
			return defaultPath;
		}
	}, [videosPath, location.pathname, resourcesPath]);

	const handleBackToTheDocs = useCallback(() => {
		if (closeTheForm) closeTheForm(true);
	}, [closeTheForm]);

	return (
		<div className={styles.container}>
			<div className={styles.titleContainer}>
				<div className={styles.backBackButtonContainer}>
					<div onClick={handleBackToTheDocs}>
						<FontAwesomeIcon
							icon={faArrowLeft as unknown as IconProp}
							style={{
								cursor: "pointer",
								fontSize: "20px",
							}}
						/>
						<span>{"Back"}</span>
					</div>
				</div>
				<div className={styles.centeredText}>
					<span className={styles.title}>{"Current folder:"}</span>
					<span className={styles.name}>{folderPath}</span>
				</div>
			</div>
			<Form
				name="upload-doc-form"
				form={form}
				layout="vertical"
				onFinish={onFinish}
				initialValues={initialValues}
				onValuesChange={onValuesChange}
			>
				<Form.Item
					name="title"
					rules={[
						{
							required: true,
							message: "'Title' is required",
						},
					]}
				>
					<div className={styles.input}>
						<Input
							type={"text"}
							placeholder={"What is the name of your video?"}
						/>
					</div>
				</Form.Item>
				<Form.Item name="longDescription">
					<MentionsInput
						onChange={handleChangePostBody}
						placeholder={"Short description"}
						className={"detailsInput"}
						classNames={styles}
						maxLength={5000}
						suggestionsPortalHost={suggestionsPortalHostRef.current}
						style={{
							suggestions: {
								list: {
									maxHeight: 300,
									overflowY: "auto",
								},
							},
						}}
					>
						<Mention
							trigger="@"
							data={users ? users : []}
							className={styles.mention}
							renderSuggestion={handleRenderSuggestions}
							appendSpaceOnAdd={true}
							displayTransform={(_, display: any) =>
								`@${display}`
							}
						/>
					</MentionsInput>
				</Form.Item>
				<div
					ref={suggestionsPortalHostRef}
					className={styles.divPositionRelativeWrapper}
				/>
				<div className={styles.noOfCharactersRemaining}>
					{!(descriptionNoOfCharactersRemaining === 0)
						? `${descriptionNoOfCharactersRemaining} ${
								descriptionNoOfCharactersRemaining > 1
									? "characters"
									: "character"
						  }  remaining`
						: "Limit reached"}
				</div>
				<UploadFile isVideoLink={isVideoLink} isVideo={isVideo} />
				{communityTags.memberTypes &&
					communityTags.memberTypes.length > 0 && (
						<>
							<div className={styles.tags}>
								<CheckboxGroupView
									title={"AUDIENCE"}
									checkAllTitle={"All Members"}
									category="audience"
									options={communityTags.memberTypes.map(
										(item: any) => item.name,
									)}
									checkedValues={watch}
									setValue={form.setFieldValue}
									disabled={authUserMembership}
								/>
							</div>
						</>
					)}
				{!currentGroupId && groups.length > 0 && (
					<>
						<div className={styles.tags}>
							<CheckboxGroupView
								title={"GROUPS"}
								checkAllTitle={"All Groups"}
								category="groups"
								options={groups.map((item: any) => ({
									label: item.name,
									value: item.id,
								}))}
							/>
						</div>
					</>
				)}
				{communityTags.tags && communityTags.tags.length > 0 && (
					<>
						<div className={styles.tags}>
							<CheckboxGroupView
								checkAllTitle={""}
								title={"TAGS"}
								category="tags"
								options={(
									communityTags.tags as {
										id: number;
										name: string;
										is_selected: boolean;
									}[]
								).map((item: any) => item.name)}
							/>
						</div>
					</>
				)}
				<div className={styles.datepicker}>
					<Form.Item
						name={"expirationDate"}
						label={"Choose the expiration date"}
					>
						<DatePicker
							placeholder={"Select"}
							size={"middle"}
							disabledDate={disabledDate}
						/>
					</Form.Item>
				</div>
				<Form.Item>
					<div className={styles.primaryButton}>
						<Button
							type="primary"
							htmlType="submit"
							className={styles.right}
							loading={videoLoading}
							disabled={videoLoading}
						>
							{"Post"}
						</Button>
					</div>
				</Form.Item>
			</Form>
		</div>
	);
};

export default memo(UploadVideoForm);
