import React, { memo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { createSelector } from "reselect";
import ReactPlayer from "react-player";

import styles from "./styles.module.scss";
import { api, getVideo } from "store";
import { IVideo, TStore } from "models";
import Loading from "components/Loading";
import { rollbar, useParseCommunityTags } from "utils";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.video.data,
	(video) => {
		return {
			video,
		};
	},
);

interface IVideoNotificationDetails {
	forceCommunityId?: number;
	videoId?: number;
	videoData?: IVideo;
	folderId?: number;
}

const VideoNotificationDetails: React.FC<IVideoNotificationDetails> = ({
	videoId,
	forceCommunityId,
	videoData,
	folderId,
}) => {
	const [loading, setLoading] = useState(videoData ? false : true);
	const [isMediaLoaded, setIsMediaLoaded] = useState(false);
	const [vimeoUrl, setVimeoUrl] = useState<string | undefined>();
	const dispatch = useDispatch();
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { video } = useSelector(stateSelector);
	const videoItem = videoData ? videoData : video;
	const [reactPlayerError, setReactPlayerError] = useState<
		string | undefined
	>();

	const handleOnProgress = (onProgress: {
		played: number;
		playedSeconds: number;
		loaded: number;
		loadedSeconds: number;
	}) => {
		// if playedSeconds > 3 seconds. increment the views.
	};

	const { parsedTags } = useParseCommunityTags({
		tags: videoItem?.tags || [],
	});

	// const incrementVideoViewCount = useCallback(() => {
	// 	dispatch(incrementVideoViewCountAction(videoId));
	// }, [dispatch, videoId]);

	const handleOnError = useCallback((error) => {
		setIsMediaLoaded(true);
		setReactPlayerError(error);
	}, []);

	useEffect(() => {
		if (videoId && !videoData) {
			dispatch(
				getVideo({
					videoId,
					callbackOnSuccess: () => setLoading(false),
					callbackOnError: () => setLoading(false),
					forceCommunityId,
					folderId,
				}),
			);
		}
	}, [dispatch, videoId, videoData, forceCommunityId, folderId]);

	useEffect(() => {
		const checkVimeoEmbedLink = async () => {
			try {
				if (videoItem?.resource_embed_uri || videoItem?.vimeo_uri) {
					const vimeoPath: string =
						videoItem.vimeo_uri ||
						videoItem.resource_embed_uri ||
						"";

					setIsMediaLoaded(true);
					const { data } = await api.videos.getEmbed(
						vimeoPath.replace("/api/v1", ""),
					);
					const iframe = data.match(
						/(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/gi,
					);
					const vimeo_video_regex =
						// eslint-disable-next-line no-useless-escape
						/src\s*=(?:\"|\')(.+?)(?:\"|\')/gi;
					setVimeoUrl(vimeo_video_regex.exec(iframe[0])?.[1]);
					setIsMediaLoaded(false);
				} else {
					throw Error("Video link not found.");
				}
			} catch (error) {
				console.error({ error });
				rollbar.error(error);
				setIsMediaLoaded(false);
			}
		};
		checkVimeoEmbedLink();
	}, [videoItem?.resource_embed_uri, videoItem?.vimeo_uri]);

	return (
		<>
			{loading ? (
				<Loading />
			) : videoItem ? (
				<div className={styles.wrapper}>
					{!isMediaLoaded && (
						<div className={styles.loaderContainer}>
							<Loading />
						</div>
					)}
					{isMediaLoaded && (
						<h3 className={styles.title}>{videoItem.title}</h3>
					)}
					<div
						className={styles.reactPlayerContainer}
						style={!isMediaLoaded ? { paddingTop: 0 } : {}} // I added paddingTop: 0 in order to show the Loading spinner in the center
					>
						{!reactPlayerError ? (
							<ReactPlayer
								onProgress={handleOnProgress}
								url={
									videoItem?.video_link
										? videoItem?.video_link
										: vimeoUrl
										? vimeoUrl
										: videoItem?.attachment?.url
								}
								className={styles.player}
								// onStart={incrementVideoViewCount}
								controls={true}
								width="100%"
								height="100%"
								light={false}
								onReady={() => setIsMediaLoaded(true)}
								onError={(error) => handleOnError(error)}
							/>
						) : (
							<div
								style={{
									display: "flex",
									flex: 1,
									position: "absolute",
									width: "100%",
									height: "100%",
									top: 0,
									bottom: 0,
									right: 0,
									left: 0,
								}}
							>
								<iframe
									style={{
										display: "flex",
										flex: 1,
									}}
									src={
										videoItem?.video_link
											? videoItem?.video_link
											: vimeoUrl
											? vimeoUrl
											: videoItem?.attachment?.url
									}
									frameBorder="0"
									title="video"
								></iframe>
							</div>
						)}
					</div>
					{isMediaLoaded && parsedTags && parsedTags.length > 0 && (
						<div className={styles.tagsContainer}>
							{parsedTags?.map((item: any, index: number) => {
								return (
									<small
										key={`tag_${item}`}
										className={styles.tag}
									>
										{item}
									</small>
								);
							})}
						</div>
					)}
				</div>
			) : null}
		</>
	);
};

export default memo(VideoNotificationDetails);
