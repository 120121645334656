import {
	IMarketplaceOfferState,
	EMarketplaceOfferActionTypes,
	TMarketplaceOfferActions,
} from "models";

const initial: IMarketplaceOfferState = {
	loading: false,
	editData: undefined,
};

export function marketplaceOfferReducer(
	state = initial,
	action: TMarketplaceOfferActions,
): IMarketplaceOfferState {
	switch (action.type) {
		case EMarketplaceOfferActionTypes.marketplaceOfferLoadingSet: {
			return {
				...state,
				loading: action.payload,
			};
		}
		case EMarketplaceOfferActionTypes.setMarketplaceOfferEditData: {
			return {
				...state,
				editData: action.payload.data,
			};
		}
		case EMarketplaceOfferActionTypes.setMarketplaceOfferRawData: {
			return {
				...state,
				rawData: action.payload.data,
			};
		}
		case EMarketplaceOfferActionTypes.resetMarketplaceOffer: {
			return {
				...state,
				editData: undefined,
			};
		}

		default:
			return state;
	}
}
