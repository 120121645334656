import { memo, useCallback, useEffect, useLayoutEffect, useState } from "react";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";

import {
	CheckoutForm,
	CommunityMemberPlans,
	HelmetPageTitle,
	Loading,
} from "components";
import styles from "./styles.module.scss";
import { ChangeSubscriptionLayout } from "layouts";
import { EChangeSubscriptionStage, ECommunityType, TStore } from "models";
import { useNavigate } from "react-router-dom";
import { getCommunityMemberPlans, notify } from "store";
import {
	hexToRgbA,
	settingSelectedCommunityToJoinColors,
	useMemberPlanPrice,
} from "utils";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.community,
	(state: TStore) => state.subscription.currentMemberPlan,
	(state: TStore) => state.subscription.changeSubscriptionStage,
	(state: TStore) => state.posts.meta.appearance,
	(community, currentMemberPlan, changeSubscriptionStage, appearance) => ({
		community,
		currentMemberPlan,

		changeSubscriptionStage,

		appearance,
	}),
);

const ChangeSubscription = () => {
	const [loading, setLoading] = useState(true);
	const stateSelector = useCallback(stateSelectorHandle, []);
	const {
		community,
		currentMemberPlan,
		changeSubscriptionStage,
		appearance,
	} = useSelector(stateSelector);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { data: communityData } = community || {};

	const memberPlanPrice = useMemberPlanPrice({
		typeOfCommunity: ECommunityType.current,
	});

	useEffect(() => {
		if (loading && !changeSubscriptionStage && !currentMemberPlan) {
			// redirect the user to the subscription-management page.
			navigate("/subscription-management", { replace: true });
		}
	}, [navigate, loading, changeSubscriptionStage, currentMemberPlan]);

	useEffect(() => {
		if (loading && communityData?.id) {
			dispatch(
				getCommunityMemberPlans({
					communityId: communityData?.id,
					successCallback: () => {
						setLoading(false);
					},
					errorCallback: () => {
						navigate(-1);
						notify(
							"error",
							"An error occurred. Please try again later.",
						);
						setLoading(false);
					},
					typeOfCommunity: ECommunityType.current,
				}),
			);
		}
	}, [dispatch, loading, navigate, communityData?.id]);

	useLayoutEffect(() => {
		if (appearance) {
			const {
				header_background,
				primary_color,
				announcement_header_background,
			} = appearance;
			settingSelectedCommunityToJoinColors({
				headerBackground: header_background ?? null,
				headerBackgroundRgb: header_background
					? hexToRgbA(header_background)
					: null,
				announcementHeaderBackground:
					announcement_header_background ?? null,
				announcementHeaderBackgroundRgb: announcement_header_background
					? hexToRgbA(announcement_header_background)
					: null,
				primaryColor: primary_color ?? null,
				primaryColorRgb: primary_color
					? hexToRgbA(primary_color)
					: null,
			});
		}
	}, [appearance]);

	console.log(changeSubscriptionStage, memberPlanPrice?.stripe_id);

	return (
		<>
			<HelmetPageTitle screenTitle={"Change Subscription"} />
			<ChangeSubscriptionLayout
				headerTitle={
					changeSubscriptionStage ===
					EChangeSubscriptionStage.memberPlans
						? "Select a member plan"
						: changeSubscriptionStage ===
						  EChangeSubscriptionStage.checkout
						? "Checkout Form"
						: ""
				}
			>
				{loading ? (
					<Loading />
				) : changeSubscriptionStage ===
				  EChangeSubscriptionStage.memberPlans ? (
					communityData?.memberPlans &&
					communityData.memberPlans.length > 0 ? (
						<>
							<CommunityMemberPlans
								memberPlans={communityData.memberPlans}
								typeOfCommunity={ECommunityType.current}
							/>
						</>
					) : (
						<div className={styles.emptyPlaceholder}>
							{"There are no plans available."}
						</div>
					)
				) : changeSubscriptionStage ===
						EChangeSubscriptionStage.checkout &&
				  memberPlanPrice?.stripe_id ? (
					<CheckoutForm typeOfCommunity={ECommunityType.current} />
				) : (
					<Loading />
				)}
			</ChangeSubscriptionLayout>
		</>
	);
};

export default memo(ChangeSubscription);
