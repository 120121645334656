import { put, all, select, takeEvery, call } from "redux-saga/effects";

import { EEventActionTypes, TStore } from "models";
import { setEventLoading, setEvent, getEvent, api } from "store";
import { rollbar } from "utils";

const getCommunityId = (state: TStore) => state.app.currentCommunityId;
// const checkEvents = (state: TStore) => state.events;
// const getAppState = (state: any) => state.app;

function* getEventHandle({ payload }: ReturnType<typeof getEvent>) {
	const { id, forceCommunityId, callbackOnError, callbackOnSuccess } =
		payload;
	try {
		yield put(setEventLoading(true));
		const communityId: number = yield select(getCommunityId);
		if (!communityId) {
			throw new Error("Missing data.");
		}
		const { data } = yield call(api.events.getOne, {
			communityId: forceCommunityId ?? communityId,
			eventId: id,
		});

		yield put(setEvent({ data: data.event }));
		callbackOnSuccess?.();
		yield put(setEventLoading(false));
	} catch (error) {
		callbackOnError?.();
		rollbar.error(error)

		console.error({ error });
		yield put(setEventLoading(false));
	}
}

export default function* rootSaga() {
	yield all([takeEvery(EEventActionTypes.getEvent, getEventHandle)]);
}
