import React, { memo, useCallback } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import { TStore } from "models";

interface IPrivateRoute {
	children: any;
}

const stateSelectorHandle = createSelector(
	(state: TStore) => state.auth.credentials,
	(state: TStore) => state.community,
	(authenticatedUser, community) => ({ authenticatedUser, community }),
);

const PrivateRoute: React.FunctionComponent<IPrivateRoute> = ({ children }) => {
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { authenticatedUser, community } = useSelector(stateSelector);
	const location = useLocation();

	if (Boolean(authenticatedUser)) {
		if (
			[
				"/subscription-management",
				"/subscription-management/change-plan",
			].includes(location.pathname) &&
			(authenticatedUser?.user.is_a_manager ||
				!community.data?.uses_stripe)
		) {
			return (
				<Navigate
					to={{
						pathname: "/dashboard",
					}}
				/>
			);
		}
		return children;
	} else {
		return (
			<Navigate
				to={{
					pathname: "/auth/sign-in",
				}}
				state={{ from: location }}
			/>
		);
	}
};

export default memo(PrivateRoute);
