import { axiosInstance } from "..";
import { IGetMemberApi } from "models";

export const member = {
	get: ({ payload }: IGetMemberApi) =>
		axiosInstance().get(
			`/communities/${payload.communityId}/profiles/${payload.memberId}/public`,
		),
	blockMember: (payload: { communityId: number; id: number }) => {
		const { communityId, id } = payload;
		return axiosInstance()
			.post(`/communities/${communityId}/users/${id}/block`)
			.then((response: any) => {
				return response;
			})
			.catch((error: any) => {
				return Promise.reject(
					error.response ? error.response.data : error,
				);
			});
	},
	unblockMember: (payload: { communityId: number; id: number }) => {
		const { communityId, id } = payload;
		return axiosInstance()
			.delete(`/communities/${communityId}/users/${id}/unblock`)
			.then((response: any) => {
				return response;
			})
			.catch((error: any) => {
				return Promise.reject(
					error.response ? error.response.data : error,
				);
			});
	},
	reportMember: (payload: {
		communityId: number;
		membershipId: number;
		message: string;
	}) => {
		const { communityId, membershipId, message } = payload;
		return axiosInstance()
			.post(
				`/communities/${communityId}/memberships/${membershipId}/report`,
				{
					body: message,
				},
			)
			.then((response: any) => {
				return response;
			})
			.catch((error: any) => {
				return Promise.reject(
					error.response ? error.response.data : error,
				);
			});
	},
};
