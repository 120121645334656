import {
	ICreateVideoServicePayload,
	IUploadDocServicePayload,
	IUploadVideoServicePayload,
} from "models";
import { axiosInstance } from "..";

export const resources = {
	getEmbed: (payload: string) => axiosInstance().get(payload),
	get: (payload: {
		order?: "view_count" | "created_at" | "title";
		order_dir?: "asc" | "desc";
		page?: number;
		communityId?: number;
		groupId?: number | null;
		tag_clicked?: boolean;
		folder_id?: number | undefined;
		q?: string;
		resourceId?: number;
	}) =>
		axiosInstance().get(`/communities/${payload.communityId}/resources`, {
			params: {
				group_id: payload.groupId,
				tag_clicked: payload.tag_clicked || false,
				q: payload.q || null,
				folder_id: payload.folder_id,
				page: payload.page,
				all: true,
				order: payload.order,
				order_dir: payload.order_dir,
				resource_id: payload.resourceId,
			},
		}),
		deleteReesource:(payload:{community_id:number,resourceId:number}) =>{
			return axiosInstance().delete(`/communities/${payload.community_id}/resources/${payload.resourceId}`)
		},
	createVideoForResources: (payload: ICreateVideoServicePayload) => {
		const { group_ids, member_type_list, tag_list, folder_id, video_link } =
			payload;

		const formData = new FormData();
		const formKeys = Object.keys(payload);
		formKeys.forEach((element) => {
			const item = element as keyof ICreateVideoServicePayload;
			if (item === "group_ids") {
				if (group_ids && group_ids.length > 0) {
					group_ids.forEach((item) => {
						formData.append(
							"community_document[group_ids][]",
							String(item),
						);
					});
				} else {
					formData.append("community_document[group_ids][]", "");
				}
			} else if (item === "member_type_list") {
				if (member_type_list && member_type_list.length > 0) {
					member_type_list.forEach((item) => {
						formData.append(
							"community_document[member_type_list][]",
							String(item),
						);
					});
				} else {
					formData.append(
						"community_document[member_type_list][]",
						"",
					);
				}
			} else if (item === "tag_list") {
				if (tag_list && tag_list.length > 0) {
					tag_list.forEach((item) => {
						formData.append(
							"community_document[tag_list][]",
							String(item),
						);
					});
				} else {
					formData.append("community_document[tag_list][]", "");
				}
			} else if (item === "folder_id" && folder_id) {
				formData.append(
					"community_document[folder_id]",
					String(folder_id),
				);
			} else if (item === "is_private" || item === "all_groups") {
				formData.append(
					`community_document[${item}]`,
					String(payload[item]),
				);
			} else if (item === "video_link" && video_link) {
				formData.append("community_document[video_link]", video_link);
			} else if (
				item !== "communityId" &&
				item !== "folder_id" &&
				payload[item]
			) {
				formData.append(`community_document[${item}]`, payload[item]);
			}
		});

		const config = {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		};

		return axiosInstance()
			.post(
				`/communities/${payload.communityId}/resources`,
				formData,
				config,
			)
			.then((response: any) => {
				return response;
			})
			.catch((error: any) => {
				console.error({ error });
				return Promise.reject(
					error.response ? error.response.data : error,
				);
			});
	},
	uploadVideoForResources: (payload: IUploadVideoServicePayload) => {
		const formData = new FormData();
		formData.append("video", payload.video);
		const config = {
			headers: {
				"Content-Type": "multipart/form-data",
			},
			onUploadProgress: payload.videoUploadProgress,
		};

		return axiosInstance()
			.post(
				`/communities/${payload.communityId}/community_documents/${payload.resourceId}/videos`,
				formData,
				config,
			)
			.then((response: any) => {
				return response;
			})
			.catch((error: any) => {
				console.error({ error });
				return Promise.reject(
					error.response ? error.response.data : error,
				);
			});
	},
	uploadDocumentForResources: (payload: IUploadDocServicePayload) => {
		const { group_ids, member_type_list, tag_list, folder_id } = payload;

		const formData = new FormData();
		const formKeys = Object.keys(payload);
		formKeys.forEach((element) => {
			const item = element as keyof IUploadDocServicePayload;
			if (item === "group_ids") {
				if (group_ids && group_ids.length > 0) {
					group_ids.forEach((item) => {
						formData.append(
							"community_document[group_ids][]",
							String(item),
						);
					});
				} else {
					formData.append("community_document[group_ids][]", "");
				}
			} else if (item === "member_type_list") {
				if (member_type_list && member_type_list.length > 0) {
					member_type_list.forEach((item) => {
						formData.append(
							"community_document[member_type_list][]",
							String(item),
						);
					});
				} else {
					formData.append(
						"community_document[member_type_list][]",
						"",
					);
				}
			} else if (item === "tag_list") {
				if (tag_list && tag_list.length > 0) {
					tag_list.forEach((item) => {
						formData.append(
							"community_document[tag_list][]",
							String(item),
						);
					});
				} else {
					formData.append("community_document[tag_list][]", "");
				}
			} else if (item === "folder_id" && folder_id) {
				formData.append(
					"community_document[folder_id]",
					String(folder_id),
				);
			} else if (item === "is_private" || item === "all_groups") {
				formData.append(
					`community_document[${item}]`,
					String(payload[item]),
				);
			} else if (
				item !== "communityId" &&
				item !== "folder_id" &&
				payload[item]
			) {
				formData.append(`community_document[${item}]`, payload[item]);
			}
		});

		return axiosInstance()
			.post(`/communities/${payload.communityId}/resources`, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((response: any) => {
				return response;
			})
			.catch((error: any) => {
				console.error({ error });
				return Promise.reject(
					error.response ? error.response.data : error,
				);
			});
	},
};
