import {
	IAppLoadingSetAction,
	IAppSetUserAction,
	IAppSetDataAction,
	IAppSetGroupAction,
	IAppOneSignalSetInitialized,
	IAppOneSignalSetId,
	IAppOneSignalResetId,
	IAppUpdateMessageCounterAction,
	IAppSetFeedPostsContainerWidthAction,
	IAppSiderMenuSetShowJoinedCommunitiesListAction,
	IAppSiderMenuSetCollpsedAction,
	IWelcomeCommunityModal,
} from "./actions";

// ENUMS
export enum EAppActionTypes {
	resetStore = "reset/store",
	resetStoreSuccess = "reset/store/success",
	appLoadingSet = "app/loading/set",
	appInit = "app/init",
	appInitOneSignal = "app/oneSignal/init",
	appOneSignalSetInitialized = "app/oneSignal/set/init",
	appOneSignalSetId = "app/oneSignal/id/set",
	appOneSignalResetId = "app/oneSignal/id/reset",
	appInitSet = "app/init/set",
	appSetUser = "app/set/user",
	appSetData = "app/set/data",
	appSetGroup = "app/set/group",
	appChangeCommunity = "app/change/community",
	updateMessageCounter = "app/messageCounter/update",
	setFeedPostsContainerWidth = "app/set/feed/posts/container/width",
	siderMenuSetShowJoinedCommunitiesList = "app/sider/menu/show/joined/communities/list",
	siderMenuSetCollapsed = "app/sider/menu/set/collapsed",
	SetWelcomeModal = "app/set/welcomeModal",
}

// INTERFACES
export interface IImage {
	id: number;
	image: string;
	thumb: string;
	desktop: {
		xl: string;
		l: string;
		md: string;
		sm: string;
		xs: string;
	};
	mobile: string;
}

export interface IAppState {
	loading: boolean;
	currentCommunityId: null | number;
	currentGroupId: null | number;
	messagesCounter: number;
	feedPostsContainerWidth: number;
	siderMenu: {
		showJoinedCommuntiesList: boolean;
		collapsed: boolean;
	};
	welcomeModal: boolean;
}

export interface IAppLoadingPayload {
	loading: boolean;
}

// TYPES
export type TAppActions =
	| IAppLoadingSetAction
	| IAppSetUserAction
	| IAppSetGroupAction
	| IAppSetDataAction
	| IAppOneSignalSetInitialized
	| IAppOneSignalSetId
	| IAppOneSignalResetId
	| IAppUpdateMessageCounterAction
	| IAppSetFeedPostsContainerWidthAction
	| IAppSiderMenuSetShowJoinedCommunitiesListAction
	| IWelcomeCommunityModal
	| IAppSiderMenuSetCollpsedAction;
