import React, {
	memo,
	useCallback,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { Button, Input } from "antd";
import { createSelector } from "reselect";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { useLocation } from "react-router-dom";

import styles from "./styles.module.scss";
import { GetImg } from "utils";
import { IMessageThread,  TStore } from "models";
import { sendMessageAction } from "store";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.auth.credentials?.user,
	(user) => ({ user }),
);

interface IMessengerEditor {
	selectedThread: IMessageThread | null;
}

const MessengerEditor: React.FunctionComponent<IMessengerEditor> = ({
	selectedThread,
}) => {
	const dispatch = useDispatch();
	const [textareaValue, setTextareaValue] = useState<string>("");
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { user } = useSelector(stateSelector);
	const shiftPressed = useRef(false);
	const textareaMaskRef = useRef<HTMLDivElement | null>(null);
	const textareaInputRef = useRef<HTMLElement | null>(null);
	const handleKeyUp = useCallback((e) => {
		if (e.keyCode === 16) shiftPressed.current = false;
	}, []);

	const location = useLocation();



	const handleTextareaChange = useCallback(
		(event: React.ChangeEvent<HTMLTextAreaElement>) => {

			if(event.target.value === "\n") {
				setTextareaValue('')
				return
			}
			const value = event.target.value;
			
			// if(textareaValue < 1 && )			
			setTextareaValue(value);

			if (
				textareaMaskRef?.current &&
				typeof textareaMaskRef?.current?.innerHTML !== "undefined"
			) {
				textareaMaskRef.current.innerHTML = value;
			}
		},
		[],
	);

	const sendMessage = useCallback((e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		// TODO: this function need to updated because is not called on enter key anymore
		if (
			!shiftPressed.current &&
			textareaValue.length &&
			selectedThread &&
			(selectedThread.user?.id ||
				selectedThread.user?.id ||
				(selectedThread.users &&
					selectedThread.users[0] &&
					selectedThread.users[0].id))
		) {
			let recipientId = 0;
			if (selectedThread.id === 0) {
				if (location.state?.post) {
					recipientId = selectedThread.user?.id
						? selectedThread.user?.id
						: selectedThread.users
						? selectedThread.users[0].id
						: 0;
				} else {
					recipientId = selectedThread.user?.membership_id
						? selectedThread.user?.membership_id
						: selectedThread.users
						? selectedThread.users[0].id
						: 0;
				}
			} else {
				recipientId = selectedThread.user?.id
					? selectedThread.user?.id
					: selectedThread.users
					? selectedThread.users[0].id
					: 0;
			}

			const postId =
				selectedThread.community_post_id ||
				(selectedThread.activity_type === "community_post" &&
					selectedThread.id) ||
				0;

			dispatch(
				sendMessageAction({
					postId,
					content: textareaValue.trim(),
					recipientId: recipientId!,
					conversationId:
						selectedThread.conversation_id || selectedThread.id,
				}),
			);
			setTextareaValue("");
			if (
				textareaMaskRef?.current &&
				typeof textareaMaskRef?.current?.innerHTML !== "undefined"
			) {
				textareaMaskRef.current.innerHTML = "";
			}
		}
	},[dispatch, location.state?.post, selectedThread, textareaValue]);

	const handleKeyDown = useCallback((e) => {
		if (e.keyCode === 16) shiftPressed.current = true;
		if (e.key === 'Enter') sendMessage(e)
		if (e.key === 'Enter' && e.shiftKey === true){
			e.preventDefault();
			setTextareaValue(textareaValue + '\n')
		}
	}, [sendMessage, textareaValue]);
	const updateFlexDirectionForSendBtn: boolean = useMemo(() => {
		if (textareaValue) {
			return textareaInputRef.current && textareaMaskRef.current
				? textareaInputRef.current.offsetWidth - 90 >
				  textareaMaskRef.current?.offsetWidth
					? false
					: true
				: false;
		} else {
			return false;
		}
	}, [textareaValue]);

	useLayoutEffect(() => {
		const textareaElement =
			document.getElementById("message-textarea")?.parentElement;
		const textareaMaskElement = document.getElementById(
			"textarea-mask",
		) as HTMLDivElement | null;

		if (textareaElement && textareaMaskElement) {
			textareaInputRef.current = textareaElement;
			textareaMaskRef.current = textareaMaskElement;
		}
	}, []);

	return (
		<div className={styles.container}>
			<GetImg endpoint={user?.picture} className={styles.avatar} />
			<div
				className={classNames(styles.textContainer, {
					[styles.flexColumnDirection]: updateFlexDirectionForSendBtn,
				})}
			>
				<div
					id={"textarea-mask"}
					ref={textareaMaskRef}
					style={{ position: "absolute", zIndex: 0 }}
				/>
				<Input.TextArea
					id={"message-textarea"}
					autoSize={{ maxRows: 4 }}
					placeholder={"Message"}
					onKeyUp={handleKeyUp}
					onKeyDown={handleKeyDown}
					value={textareaValue}
					onChange={handleTextareaChange}
				/>
				<div
					className={classNames(styles.sendButtonContainer, {
						[styles.flexRowDirection]:
							updateFlexDirectionForSendBtn,
					})}
				>
					<Button
						disabled={!textareaValue ? true : false}
						onClick={sendMessage}
					>
						{"Send"}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default memo(MessengerEditor);
