import { EGroupPrivacy, EGroupsActionTypes, IGroup } from "models";

export const setGroupsLoading = (payload: boolean) => ({
	type: EGroupsActionTypes.groupsLoadingSet,
	payload,
});

export const updateJoinedGroup = (payload: {
	typeOfAction: "join" | "cancel request to join" | "leave";
	group_id: number;
	membership_id: number;
	is_joined: boolean;
	join_request_status: "none" | "pending" | "approved";
	privacy?: string;
	request_id: string | null;
}) => ({
	type: EGroupsActionTypes.updateLocaJoinedGroups,
	payload,
});

export const getGroups = (payload?: {
	communityId: number;
	successCallback?: (groups: IGroup[]) => void;
	errorCallback?: () => void;
}) => ({
	type: EGroupsActionTypes.getGroups,
	payload,
});

export const getMoreGroups = (payload?: any) => ({
	type: EGroupsActionTypes.getMoreGroups,
	payload,
});

export const setGroups = (payload: any) => ({
	type: EGroupsActionTypes.setGroups,
	payload,
});

export const joinGroup = (payload: {
	groupId: number;
	communityId: number;
}) => ({
	type: EGroupsActionTypes.joinGroup,
	payload,
});

export const leaveGroup = (payload: {
	communityId: number;
	groupId: number;
	membershipId: number;
	privacy: EGroupPrivacy;
}) => ({
	type: EGroupsActionTypes.leaveGroup,
	payload,
});

export const cancelJoinGroupRequest = (payload: any) => ({
	type: EGroupsActionTypes.cancelJoinGroupRequest,
	payload: {
		community_id: payload.communityId,
		group_id: payload.groupId,
		request_id: payload.requestId,
	},
});
