import { memo, FC } from "react";
import classNames from "classnames";
import linkifyHtml from "linkifyjs/html";

import { IMessageThread, IUser } from "models";
import { GetImg } from "utils";
import styles from "./styles.module.scss";
import avatar from "assets/images/avatar.jpg";

const MessengerCard: FC<{
	user: IUser | undefined;
	data: IMessageThread;
	sameMsgBlock: boolean;
}> = ({ user, data, sameMsgBlock = false }) => {
	if (!data || !user) return null;

	const { sender, timestamp: date, body: message } = data;

	return (
		<div
			className={classNames(styles.container, {
				[styles.loggedUserMsg]: sender?.user_id === user.membership_id,
				[styles.sameMsgBlock]: sameMsgBlock,
			})}
		>
			<div
				className={classNames(styles.top, {
					[styles.reverseTop]: sender?.user_id === user.membership_id,
				})}
			>
				{sender?.user_id !== user.membership_id ? (
					<>
						<div className={styles.leftSide}>
							{!sameMsgBlock && (
								<>
									<GetImg
										endpoint={sender?.picture || ""}
										className={styles.avatar}
										loadingSize={30}
									/>
									<div className={styles.userName}>
										{sender?.name}
									</div>
								</>
							)}
						</div>
						<div className={styles.rightSide}>{date}</div>
					</>
				) : (
					<>
						<div className={styles.rightSide}>{date}</div>
						<div className={styles.leftSide}>
							{!sameMsgBlock && (
								<>
									<GetImg
										endpoint={avatar}
										className={styles.avatar}
										loadingSize={30}
									/>
									<div className={styles.userName}>
										{sender?.name}
									</div>
								</>
							)}
						</div>
					</>
				)}
			</div>
			<div
				className={styles.bottom}
				style={{
					textAlign:
						sender?.user_id !== user.membership_id
							? "left"
							: "right",
					paddingRight: "5px",
					paddingLeft: "5px",
					paddingTop: "5px",
				}}
				dangerouslySetInnerHTML={{
					__html: linkifyHtml(message || ""),
				}}
			></div>
		</div>
	);
};

export default memo(MessengerCard);
