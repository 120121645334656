import { useCallback } from "react";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";

import { ECommunityType, TStore } from "models";
import { getMemberPlanPriceOptionByCurrency } from "utils";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.subscription,
	(state: TStore) => state.communities.selectedCommunityToJoinInfo,
	(subscription, selectedCommunityToJoinInfo) => ({
		subscription,
		selectedCommunityToJoinInfo,
	}),
);

interface IUseMemberPlanPrice {
	typeOfCommunity: ECommunityType;
}

export const useMemberPlanPrice = ({
	typeOfCommunity,
}: IUseMemberPlanPrice) => {
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { subscription, selectedCommunityToJoinInfo } =
		useSelector(stateSelector);
	if (!typeOfCommunity) return null;
	if (typeOfCommunity === ECommunityType.join) {
		if (selectedCommunityToJoinInfo.selected_member_plan?.price) {
			// on the resume process the selected_member_plan has the 'price' key populated with the selected price.
			return (
				selectedCommunityToJoinInfo.selected_member_plan.price ?? null
			);
		} else {
			return getMemberPlanPriceOptionByCurrency(
				selectedCommunityToJoinInfo?.selectedCurrency,
				selectedCommunityToJoinInfo?.selected_member_plan
					?.price_options,
			);
		}
	}
	return getMemberPlanPriceOptionByCurrency(
		subscription.changeSubscriptionSelectedCurrency,
		subscription.changeSubscriptionSelectedMemberPlan?.price_options,
	);
};
