/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useCallback, useMemo, useState } from "react";
import { createSelector } from "reselect";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";

import styles from "../../styles.module.scss";
import { IDocsState, IResourcesState, IVideosState, TStore } from "models";
import {
	getDocs,
	getResources,
	getVideos,
	setDocsFilterTagsAction,
	setResourcesFilterTagsAction,
	setVideosFilterTagsAction,
} from "store";
import { ReactComponent as XIcon } from "assets/images/x.svg";

const stateSelectorHandle = (type: keyof TStore) =>
	createSelector(
		(state: TStore) => state.community.mainTags,
		(state: TStore) => {
			const result: IVideosState | IDocsState | IResourcesState = state[
				type
			] as IVideosState | IDocsState | IResourcesState;
			return result.selectedTags;
		},
		(communityTags, selectedTags) => {
			return {
				communityTags,
				selectedTags,
			};
		},
	);

interface IFilterByTags {
	type: keyof TStore;
	count: number;
}

const FilterByTags: React.FunctionComponent<IFilterByTags> = ({
	type,
	count,
}) => {
	const stateSelector = useCallback(stateSelectorHandle(type), []);
	const { communityTags, selectedTags } = useSelector(stateSelector);
	const dispatch = useDispatch();
	const [tagsDropdownOpen, setTagsDropdownOpen] = useState<boolean>(false);

	const tagsOptions: null | { label: string; value: string }[] =
		useMemo(() => {
			if (!communityTags?.tags || communityTags?.tags?.length === 0) {
				return null;
			} else {
				return (
					communityTags.tags as {
						id: number;
						name: string;
						is_selected: boolean;
					}[]
				).map((item) => {
					return { label: item.name, value: item.name };
				});
			}
		}, [communityTags.tags]);

	const getRecords = (newTag: string[]) => {
		switch (type) {
			case "videos":
				dispatch(
					setVideosFilterTagsAction({
						tag: newTag,
					}),
				);
				dispatch(
					getVideos({
						reset: true,
					}),
				);
				break;
			case "resources":
				dispatch(
					setResourcesFilterTagsAction({
						tag: newTag,
					}),
				);
				dispatch(
					getResources({
						reset: true,
					}),
				);
				break;
			case "documents":
				dispatch(
					setDocsFilterTagsAction({
						tag: newTag,
					}),
				);
				dispatch(
					getDocs({
						reset: true,
					}),
				);
				break;
		}
	};

	const handleFilterTagsPosts = useCallback(
		(tags: string[]) => {
			let newTag;

			if ([tags].length) {
				newTag = [tags].filter(
					(item: any) => selectedTags && !selectedTags.includes(item),
				);
			}

			getRecords(newTag);
		},
		[dispatch, selectedTags, type],
	);

	return (
	
			<div className={styles.filterByTagsContainer}>
				<span className={styles.counter}>{`Files (${count})`}</span>
				<div style={{ display: "flex", alignItems: "center", gap: 15 }}>
					<h1 className={styles.filterByTags}>Filter by tags</h1>
					<Select
						style={{
							width: 200,
							fontSize: 14,
							lineHeight: 20,
							fontFamily: "Nunito-Regular",
							color: "#4D5A81",
							textAlign: "left",
						}}
						placeholder="No tags Selected"
						open={tagsDropdownOpen}
						onDropdownVisibleChange={() =>
							setTagsDropdownOpen((prev) => !prev)
						}
						value={
							selectedTags?.length > 0
								? [selectedTags[selectedTags.length - 1]]
								: []
						}
						onChange={handleFilterTagsPosts}
						className={styles.tagsDropdown}
						suffixIcon={
							<div
								style={{
									transform: !tagsDropdownOpen
										? "rotate(180deg)"
										: "rotate(360deg)",
									transition: "all 0.3s",
								}}
							>
								<svg
									width="14"
									height="8"
									viewBox="0 0 14 8"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M7.70601 0.395882C7.31552 0.0161814 6.68136 0.0161813 6.29086 0.395881L0.292871 6.22808C-0.0976237 6.60778 -0.0976238 7.22441 0.292871 7.60411C0.683365 7.98381 1.31753 7.98381 1.70802 7.60411L7 2.45841L12.292 7.60107C12.6825 7.98077 13.3166 7.98077 13.7071 7.60107C14.0976 7.22137 14.0976 6.60474 13.7071 6.22504L7.70914 0.392844L7.70601 0.395882Z"
										fill="#32363D"
									/>
								</svg>
							</div>
						}
						dropdownStyle={{
							boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.08)",
							borderRadius: 8,
							backgroundColor: "white",
						}}
					>
						{tagsOptions?.map(
							(
								tag: { label: string; value: string },
								index: number,
							) => (
								<Select.Option
									key={`${tag.label}-${index}`}
									style={{
										fontSize: 14,
										lineHeight: "20px",
										fontFamily: "Nunito-Regular",
										color: "#4D5A81",
										backgroundColor: "white",
									}}
									value={tag?.value}
								>
									{tag.value}
								</Select.Option>
							),
						)}
					</Select>
					{selectedTags.length > 0 && (
						<XIcon
							fill="#4D5A81"
							width="16px"
							height="16px"
							style={{ cursor: "pointer" }}
							onClick={() => getRecords([""])}
						/>
					)}
				</div>
			</div>
		);
	};

export default memo(FilterByTags);
