import { useCallback, useEffect, useMemo } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { ReactComponent as ArrowLeft } from "assets/images/arrow-left.svg";

import { EProcessToJoinCommunityStage, EStartPagePath, TStore } from "models";
import styles from "./newstyles.module.scss";
import { ReactComponent as UgenieLogoWhite } from "assets/images/ugenie-logo-white.svg";
import { Avatar, Dropdown, Menu } from "antd";
import { LogoutMenuItem } from "components/AppBar/components";
import { ReactComponent as DownArrow } from "assets/images/downArrow.svg";
import { GetImg, isItAWhiteLabel } from "utils";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.auth.communityInvitation,
	(state: TStore) => state.communities.selectedCommunityToJoinInfo,
	(state: TStore) => state.auth.credentials,
	(state: TStore) => state.previewWhiteLabelCommunity,
	(
		communityInvitation,
		selectedCommunityToJoinInfo,
		authUser,
		previewWhiteLabelCommunity,
	) => ({
		communityInvitation,
		selectedCommunityToJoinInfo,
		authUser,
		previewWhiteLabelCommunity,
	}),
);

interface IOnboardingLayoutHeader {}

const OnboardingLayoutHeader: React.FunctionComponent<
	IOnboardingLayoutHeader
> = () => {
	const location = useLocation();
	const urlParams: { communityId?: string; onboardingStage?: string } =
		useParams();
	const navigate = useNavigate();
	const stateSelector = useCallback(stateSelectorHandle, []);
	const {
		communityInvitation,
		selectedCommunityToJoinInfo,
		authUser,
		previewWhiteLabelCommunity,
	} = useSelector(stateSelector);
	const showTheBackButton =
		urlParams.onboardingStage ===
		EProcessToJoinCommunityStage["member plans"];

	const communityLogo = useMemo((): {
		path?: string;
		rounded: boolean;
		isDefault?: boolean;
	} => {
		if (isItAWhiteLabel() && previewWhiteLabelCommunity) {
			return { path: previewWhiteLabelCommunity.picture, rounded: true };
		}
		if (!communityInvitation && !selectedCommunityToJoinInfo)
			return { rounded: false, isDefault: true };

		if (selectedCommunityToJoinInfo.picture) {
			return {
				path: selectedCommunityToJoinInfo.picture!,
				rounded: true,
			};
		} else if (
			communityInvitation &&
			location.pathname === EStartPagePath.signup
		) {
			return {
				path: communityInvitation.community.picture,
				rounded: true,
			};
		} else {
			return { rounded: false, isDefault: true };
		}
	}, [
		communityInvitation,
		location.pathname,
		previewWhiteLabelCommunity,
		selectedCommunityToJoinInfo,
	]);

	const goToCommunities = useCallback(() => {
		navigate("/communities/all");
	}, [navigate]);

	useEffect(() => {
		if (showTheBackButton && communityInvitation) {
			window.history.pushState(null, "", window.location.href);
			window.onpopstate = function () {
				window.history.go(1);
			};
		}
	}, [communityInvitation, location, showTheBackButton]);

	const dropdownMenu = useMemo(
		() => (
			<Menu
				style={{
					fontFamily: `"Avenir Light", sans-serif`,
					borderRadius: "8px",
				}}
			>
				<Menu.Item>
					<LogoutMenuItem />
				</Menu.Item>
			</Menu>
		),
		[],
	);

	return (
		<div
			className={styles.header}
			style={
				!authUser
					? { justifyContent: "center" }
					: showTheBackButton && communityInvitation
					? { justifyContent: "center" }
					: {}
			}
			id="onboarding-header"
		>
			<div
				className={styles.leftPositionedButton}
				style={{
					visibility: !isItAWhiteLabel() ? "visible" : "hidden",
				}}
			>
				{!authUser ? null : showTheBackButton &&
				  communityInvitation ? null : (
					<div className={styles.back} onClick={goToCommunities}>
						<ArrowLeft
							width={20}
							className={styles.arrowLeftIcon}
						/>
						<span>Go to all communities</span>
					</div>
				)}
			</div>
			{!communityLogo.isDefault ? (
				<img
					className={styles.communityLogo}
					src={communityLogo.path}
					alt="onboarding-logo"
				/>
			) : (
				<div style={{ display: "flex", height: "100%" }}>
					<UgenieLogoWhite className={styles.roundLogo} />
				</div>
			)}
			{authUser ? (
				<div className={styles.rightPositionedDiv}>
					<Dropdown overlay={dropdownMenu}>
						<div
							style={{
								display: "flex",
								gap: "4px",
								cursor: "pointer",
							}}
						>
							<Avatar
								size={24}
								src={
									<GetImg endpoint={authUser.user.picture} />
								}
							/>
							<div
								style={{
									display: "flex",
									alignItems: "center",
								}}
							>
								<p className={styles.avaterText}>
									{authUser?.user?.full_name ||
										authUser?.user.name}
								</p>
								<DownArrow
									height={"16px"}
									width={"16px"}
									style={{ marginLeft: "4px" }}
								/>
							</div>
						</div>
					</Dropdown>
				</div>
			) : (
				<div></div>
			)}
		</div>
	);
};

export default OnboardingLayoutHeader;
