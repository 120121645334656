import { EMediaItemActionTypes, TStore } from "models";

export const fetchMediaItemAction = ({
	type,
	mediaItemId,
}: {
	type: keyof TStore;
	mediaItemId: number;
}) => ({
	type: EMediaItemActionTypes.fetchItem,
	payload: { type, mediaItemId },
});

export const mediaItemLoadingSetAction = (payload: boolean) => ({
	type: EMediaItemActionTypes.setLoading,
	payload,
});

export const mediaItemSetAction = (payload: any) => ({
	type: EMediaItemActionTypes.update,
	payload,
});

export const setMediaPathAction = (payload: any) => ({
	type: EMediaItemActionTypes.setPath,
	payload,
});
