import { memo, useCallback, useMemo } from "react";
import { createSelector } from "reselect";
import { Divider } from "antd";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { FooterLayout } from "layouts/components";
import { isItAWhiteLabel } from "utils";
import { EStartPagePath, TStore } from "models";
import OnboardingProgressBar from "layouts/onboardingProgressBar";
import { ReactComponent as UgenieLogoWhite } from "assets/images/ugenie-logo-white.svg";
import styles from "./styles.module.scss";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.auth.communityInvitation,
	(state: TStore) => state.previewWhiteLabelCommunity,
	(state: TStore) => state.communities.selectedCommunityToJoinInfo,
	(
		communityInvitation,
		previewWhiteLabelCommunity,
		selectedCommunityToJoinInfo,
	) => ({
		communityInvitation,
		previewWhiteLabelCommunity,
		selectedCommunityToJoinInfo,
	}),
);
const AuthenticationLayout = (props: any) => {
	const { children } = props;
	const stateSelector = useCallback(stateSelectorHandle, []);
	const {
		communityInvitation,
		previewWhiteLabelCommunity,
		selectedCommunityToJoinInfo,
	} = useSelector(stateSelector);
	const location = useLocation();

	const communityLogo = useMemo((): {
		path?: string;
		rounded: boolean;
		isDefault?: boolean;
	} => {
		if (isItAWhiteLabel() && previewWhiteLabelCommunity) {
			return { path: previewWhiteLabelCommunity.picture, rounded: true };
		}
		if (!communityInvitation && !selectedCommunityToJoinInfo)
			return { rounded: false, isDefault: true };

		if (selectedCommunityToJoinInfo.picture) {
			return {
				path: selectedCommunityToJoinInfo.picture!,
				rounded: true,
			};
		} else if (
			communityInvitation &&
			location.pathname === EStartPagePath.signup
		) {
			return {
				path: communityInvitation.community.picture,
				rounded: true,
			};
		} else {
			return { rounded: false, isDefault: true };
		}
	}, [
		communityInvitation,
		location.pathname,
		previewWhiteLabelCommunity,
		selectedCommunityToJoinInfo,
	]);

	return (
		<section className={styles.container}>
			{!communityInvitation ? null : (
				<>
					<Divider style={{ margin: "0px" }} />
					<OnboardingProgressBar props={""} />
				</>
			)}
			<section className={styles.authenticationLayout}>
				{!communityLogo.isDefault ? (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<img
							className={styles.communityLogo}
							src={communityLogo.path}
							alt="onboarding-logo"
						/>
					</div>
				) : (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<UgenieLogoWhite className={styles.roundLogo} />
					</div>
				)}
				{children}
			</section>
			{!isItAWhiteLabel() && <FooterLayout />}
		</section>
	);
};

export default memo(AuthenticationLayout);
