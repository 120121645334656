import React, { memo, useMemo, useCallback } from "react";
import { Divider } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import ClassNames from "classnames";
import VisibilitySensor from "react-visibility-sensor";

import {
	getOffers,
	setOffersFilterTagsInStoreAction,
	incrementOfferViewCountAction,
	redeemOffer,
} from "store";
import { GetImg, UseShowDescriptionWithMentions } from "utils";
import { IOffer, TStore } from "models";
import styles from "./styles.module.scss";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.offers.selectedTags,
	(selectedTags) => ({
		selectedTags,
	}),
);

interface IOfferBox {
	offer: IOffer;
	disableFilteringByTags?: boolean;
}

const OfferBox: React.FunctionComponent<IOfferBox> = ({
	offer,
	disableFilteringByTags = false,
}) => {
	const {
		business_name,
		description,
		cover,
		redeem_email,
		redeem_url,
		website,
		tags,
		distance,
		id,
	} = offer;
	const dispatch = useDispatch();
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { selectedTags } = useSelector(stateSelector);
	const handleFilterTagsOffers = useCallback(
		(tags?: string[]) => () => {
			if (disableFilteringByTags) {
				return;
			}
			let newTag: string[] = [];
			if (tags && tags.length) {
				newTag = tags.filter(
					(item: any) => selectedTags && !selectedTags.includes(item),
				);
			}

			// TODO: Remove setOffersFilterTagsAction and the functions that does the same thing. Store tags directly without saga
			// dispatch(setOffersFilterTagsAction({ tag: newTag }));
			dispatch(setOffersFilterTagsInStoreAction({ parsedTags: newTag }));
			dispatch(getOffers({ reset: true }));
		},
		[disableFilteringByTags, dispatch, selectedTags],
	);

	const parsedTags = useMemo(() => {
		if (tags?.length === 0) {
			return [];
		} else {
			const newTags = tags?.map((item: string) => {
				return {
					tagToFilter: item,
					showTag: `#${item.toLocaleLowerCase()}`,
				};
			});
			return newTags;
		}
	}, [tags]);

	const handleRedeemByEmail = useCallback(() => {
		dispatch(redeemOffer({ type: "email", offerId: id }));
	}, [dispatch, id]);

	const handleRedeemByLink = useCallback(() => {
		dispatch(redeemOffer({ type: "link", offerId: id }));
	}, [dispatch, id]);

	const handleRedeemByWebsite = useCallback(() => {
		dispatch(redeemOffer({ type: "website", offerId: id }));
	}, [dispatch, id]);

	const onVisibilityChange = useCallback(
		(isVisible) => {
			if (isVisible && offer.id)
				dispatch(incrementOfferViewCountAction(offer.id));
		},
		[dispatch, offer],
	);

	return (
		<VisibilitySensor onChange={onVisibilityChange}>
			<div className={styles.offerBox}>
				<div className={styles.cover}>
					<GetImg endpoint={cover} />
				</div>
				<div className={styles.content}>
					<div className={styles.title}>{business_name}</div>

					<div
						className={ClassNames(
							styles.description,
							styles.overflow,
						)}
					>
						<UseShowDescriptionWithMentions
							text={description}
							maxHeight={"100px"}
							words={60}
						/>
					</div>
					{parsedTags && parsedTags.length > 0 && (
						<div className={styles.tagsContainer}>
							{parsedTags?.map((item: any, index: number) => {
								return (
									<small
										key={`tag_${index}_${item.showTag}`}
										className={styles.tag}
										style={
											disableFilteringByTags
												? { cursor: "default" }
												: {}
										}
										onClick={handleFilterTagsOffers([
											item.tagToFilter,
										])}
									>
										{item.showTag}
									</small>
								);
							})}
						</div>
					)}
					{website && (
						<div className={styles.websitePhoneContainer}>
							{website && (
								<a
									className={styles.website}
									href={website}
									rel="noopener noreferrer"
									target="_blank"
									onClick={handleRedeemByWebsite}
								>
									{"Visit website"}
								</a>
							)}
						</div>
					)}
					{distance && (
						<div className={styles.locationAndExpiryDate}>
							{distance && (
								<div style={{ marginRight: 8 }}>
									<FontAwesomeIcon
										icon={
											faMapMarker as unknown as IconProp
										}
										style={{
											color: "grey",
											marginRight: "4px",
										}}
									/>
									{distance}
								</div>
							)}
						</div>
					)}
					{(redeem_email || redeem_url) && (
						<>
							<Divider />
							<div className={styles.redeemContainer}>
								<div className={styles.label}>
									{"Redeem via:"}
								</div>
								<div className={styles.redeemButtons}>
									{redeem_email && (
										<a
											href={`mailto:${redeem_email}`}
											onClick={handleRedeemByEmail}
										>
											{"Email"}
										</a>
									)}
									{redeem_url && (
										<a
											href={redeem_url}
											rel="noopener noreferrer"
											target="_blank"
											onClick={handleRedeemByLink}
										>
											{"Link"}
										</a>
									)}
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</VisibilitySensor>
	);
};

export default memo(OfferBox);
