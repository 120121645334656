import React, { memo, useCallback } from "react";
import { matchPath, useNavigate, useLocation } from "react-router-dom";
import { Avatar, Dropdown, Menu, Tabs } from "antd";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";

import styles from "./styles.module.scss";
import { ReactComponent as UgenieLogoWhite } from "assets/images/ugenie-logo-white.svg";
import { ReactComponent as DownArrow } from "assets/images/downArrow.svg";
import { TStore } from "models";
import { LogoutMenuItem } from "components/AppBar/components";
import { GetImg } from "utils";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.auth,
	(authUser) => ({
		authUser,
	}),
);
const CommunitiesLayout = (props: any) => {
	const navigate = useNavigate();
	const { children } = props;
	const location = useLocation();
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { authUser } = useSelector(stateSelector);
	const matchJoinedCommunities = matchPath("/communities", location.pathname);

	const matchAllCommunities = matchPath(
		"/communities/all",
		location.pathname,
	);

	const goToJoinedCommunities = () => {
		navigate("/communities");
	};

	const goToAllCommunities = () => {
		navigate("/communities/all");
	};

	const goToInvites = () => {
		navigate("/communities/invites");
	};

	const dropdownMenu = () => (
		<Menu
			style={{
				fontFamily: `"Avenir Light", sans-serif`,
				borderRadius: "8px",
			}}
		>
			<Menu.Item>
				<LogoutMenuItem />
				{/* <Menu.Item>
					<div onClick={logout}>{"Log Out"}</div>
				</Menu.Item> */}
			</Menu.Item>
		</Menu>
	);

	return (
		<div className={styles.container}>
			<div className={styles.horizontalSection}>
				<div className={styles.leftPositionedDiv}>
					<UgenieLogoWhite className={styles.roundLogo} />

					{/* {matchJoinedCommunities ? (
						<Button
							onClick={goToAllCommunities}
							className={classNames(
								styles.onboardingPrimaryBtnSpaceBetweenTxt,
								styles.primaryColorTextBtn,
								styles.noMarginBtn,
							)}
						>
							<span>Join more</span>
							<UsersIcon  />
						</Button>
					) : matchAllCommunities &&
					  (invites || joinedCommunities) ? (
						<Button
							onClick={goToJoinedCommunities}
							className={classNames(
								styles.primaryColorTextBtn,
								styles.onboardingPrimaryBtnSpaceBetweenTxt,
								styles.noMarginBtn,
							)}
						>
							<span className={styles.buttonText}>My communities</span>
							<UsersIcon fill="black" />
						</Button>
					) : null} */}
				</div>
				<div className={styles.centerPositionedDiv}>
					<Tabs
						onChange={(e) => {
							e === "All communities"
								? goToAllCommunities()
								: e === "My communities"
								? goToJoinedCommunities()
								: goToInvites();
						}}
						defaultActiveKey={
							matchAllCommunities
								? "All communities"
								: matchJoinedCommunities
								? "My communities"
								: "Invites"
						}
						items={[
							"All communities",
							"My communities",
							"Invites",
						].map((items, i) => {
							const id = items;

							return {
								label: <span>{items}</span>,
								key: id,
							};
						})}
					/>
				</div>
				<div className={styles.rightPositionedDiv}>
					{/* <Button
						className={classNames(styles.primaryColorTextBtn,styles.onboardingPrimaryBtnSpaceBetweenTxt)}
						onClick={logout}
					>
						<span className={styles.buttonText}>Logout</span>
					<LogoutIcon className={styles.logoutButton}/>
					</Button> */}
					<Dropdown overlay={dropdownMenu}>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								gap: "4px",
								cursor: "pointer",
							}}
						>
							<Avatar
								size={24}
								src={
									<GetImg
										endpoint={
											authUser.credentials?.user.picture
										}
									/>
								}
							/>
							<div
								style={{
									display: "flex",
									alignItems: "center",
								}}
							>
								<div className={styles.avaterText}>
									{authUser?.credentials?.user.full_name ||
										authUser?.credentials?.user.name}
								</div>
								<DownArrow
									style={{
										marginLeft: "4px",
										width: 15,
										height: 15,
									}}
								/>
							</div>
						</div>
					</Dropdown>
				</div>
			</div>
			<div className={styles.childrenContainer}>{children}</div>
		</div>
	);
};

export default memo(CommunitiesLayout);
