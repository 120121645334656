import { useEffect, useRef, useState } from "react";
import { api } from "store";

export const useCheckForCommunityApproval = ({
	joined,
	privacy,
	id,
}: {
	id: number;
	joined: boolean;
	privacy: string;
}): { loading: boolean; isApproved: boolean } => {
	const status = useRef({ is_approved: privacy === "open" || false });
	const [loading, setLoading] = useState(privacy !== "open");

	useEffect(() => {
		if (joined && id) {
			const getStatus = async () => {
				try {
					setLoading(true);
					const { data } = await api.community.home({
						community_id: id,
					});
					status.current = data.meta;
					setLoading(false);
				} catch (error) {
					status.current = {
						is_approved: false,
					};
					console.error({ error });
					setLoading(false);
				}
			};
			if (privacy !== "open") {
				getStatus();
			} else {
				status.current = {
					is_approved: true,
				};
			}
		} else {
			status.current = {
				is_approved: false,
			};
			setLoading(false);
		}
	}, [id, joined, privacy]);

	return { loading, isApproved: status.current.is_approved };
};
