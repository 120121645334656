import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";

import styles from "./styles.module.scss";
import { TStore } from "models";
import DetailedContent from "../detailedContent";
import { getMarketplaceOfferRawData, notify } from "store";
import { Comments, Loading } from "components";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.marketplaceOffer.rawData,
	(marketplaceOffer) => ({
		marketplaceOffer,
	}),
);

const DetailedView = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { marketplaceOffer } = useSelector(stateSelector);
	const { marketplaceId } = useParams();
	const [loading, setLoading] = useState(true);

	const handleBackBtn = () => {
		navigate(-1);
	};

	useEffect(() => {
		if (marketplaceId == null) {
			notify("error", "Something went wrong.", "Please try again later.");
			return;
		}
		dispatch(
			getMarketplaceOfferRawData({
				id: parseInt(marketplaceId, 10),
				callbackOnSuccess: () => {
					setLoading(false);
				},
				callbackOnError: () => {
					notify(
						"error",
						"Something went wrong.",
						"Please try again later.",
					);
					setLoading(false);
				},
			}),
		);
	}, [dispatch, marketplaceId]);

	return loading ? (
		<Loading />
	) : marketplaceOffer && marketplaceId ? (
		<div className={styles.container}>
			<div
				className={styles.boldText}
				style={{ cursor: "pointer" }}
				onClick={handleBackBtn}
			>
				{"< Back to all marketplace posts"}
			</div>
			<DetailedContent marketplacePost={marketplaceOffer} />
			<div className={styles.whiteAndRoundedBackgroundWrapper}>
				<Comments postId={parseInt(marketplaceId, 10)} />
			</div>
		</div>
	) : (
		<div className={styles.emptyPlaceholder}>{"No data available!"}</div>
	);
};

export default DetailedView;
