import {
	IEventsLoadingSetAction,
	IEventsGetAction,
	IEventsSetAction,
	IEventsSeSelectedCalendarDateAction,
} from "./actions";
import { Moment } from "moment";

// ENUMS
export enum EEventsActionTypes {
	eventsLoadingSet = "events/set/loading",
	getEvents = "events/get",
	setEvents = "events/set",
	setCalendarDate = "events/set/calendar/date",
}

// INTERFACES
export interface IEventsState {
	loading: boolean;
	data: any[];
	selectedCalendarDate?: Moment;
	meta: {
		scope_group: null;
	};
}

export interface IEventsLoadingPayload {
	loading: boolean;
}

// TYPES
export type TEventsActions =
	| IEventsSetAction
	| IEventsLoadingSetAction
	| IEventsGetAction
	| IEventsSeSelectedCalendarDateAction;
