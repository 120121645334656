import React, { memo, useCallback, useState } from "react";

import { PostForm } from "components";
import { PostContent } from "./components";
import { IPost, IPostState } from "models";

interface IPostBox {
	uniqueKey: string;
	data: IPost;
	postImg?: any;
	title?: any;
	text?: any;
	getScrollParent?: () => HTMLElement | null;
}

const PostBox: React.FunctionComponent<IPostBox> = ({
	uniqueKey,
	data: post,
	getScrollParent,
}) => {
	const [enabledEditing, setEnabledEditing] = useState(false);
	const [editPostData, setEditPostData] = useState<
		IPostState["data"] | undefined
	>(undefined);
	const hidePostForm = useCallback(() => {
		setEnabledEditing(!enabledEditing);
	}, [enabledEditing]);

	const handleEnableEdit = useCallback(() => {
		setEnabledEditing(true);
	}, []);

	const handlePostEdit = (post: IPostState["data"]) => {
		setEditPostData(post);
	};

	return enabledEditing && editPostData ? (
		<PostForm
			uniqueKey={`postForm-${uniqueKey}`}
			hideEnablePostFormComponent={true}
			hidePostForm={hidePostForm}
			editPostData={editPostData}
		/>
	) : (
		<PostContent
			uniqueKey={uniqueKey}
			post={post}
			enableEditing={handleEnableEdit}
			handlePostEdit={handlePostEdit}
			getScrollParent={getScrollParent}
		/>
	);
};

export default memo(PostBox);
