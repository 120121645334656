import {
	TSubscriptionActions,
	ESubscriptionActionTypes,
	ISubscriptionState,
	EChangeSubscriptionStage,
} from "models";

const initialState: ISubscriptionState = {
	currentMemberPlan: undefined,
	paymentMethods: undefined,
	invoices: undefined,
	subscription: undefined,
	loading: false,
};

export function subscriptionReducer(
	state = initialState,
	action: TSubscriptionActions,
): ISubscriptionState {
	switch (action.type) {
		case ESubscriptionActionTypes.SET_SUBSCRIPTION_MANAGEMENT: {
			const {
				currentMemberPlan,
				paymentMethods,
				invoices,
				subscription,
				loading,
			} = action.payload;
			return {
				...state,
				currentMemberPlan,
				paymentMethods,
				invoices,
				subscription,
				loading,
			};
		}
		case ESubscriptionActionTypes.SET_SUBSCRIPTION_MANAGEMENT_LOADING: {
			return {
				...state,
				loading: action.payload,
			};
		}
		case ESubscriptionActionTypes.DELETE_PAYMENT_METHOD: {
			const paymentMethods = state.paymentMethods?.filter(
				(paymentMethod) => paymentMethod.data.id !== action.payload,
			);

			return {
				...state,
				paymentMethods,
			};
		}
		case ESubscriptionActionTypes.SET_PAYMENT_METHODS: {
			const { paymentMethods } = action.payload;

			return {
				...state,
				paymentMethods: paymentMethods ? paymentMethods : undefined,
			};
		}
		case ESubscriptionActionTypes.SET_SUBSCRIPTION: {
			return {
				...state,
				subscription: { ...state.subscription, ...action.payload },
			};
		}
		case ESubscriptionActionTypes.INITIATE_THE_RESUBSCRIBE_PROCESS: {
			return {
				...state,
				resubscribe: {
					...state.resubscribe,
					initiateTheProcess: action.payload,
				},
			};
		}

		case ESubscriptionActionTypes.CHANGE_SUBSCRIPTION_STAGE: {
			return {
				...state,
				changeSubscriptionStage: action.payload.stage,
			};
		}
		case ESubscriptionActionTypes.SET_CHANGE_SUBSCRIPTION_SELECTED_MEMBER_PLAN: {
			const { memberPlan, updateTheStageToCheckoutForm } = action.payload;
			const resultedState = { ...state };

			resultedState.changeSubscriptionSelectedMemberPlan = memberPlan;
			if (updateTheStageToCheckoutForm) {
				resultedState.changeSubscriptionStage =
					EChangeSubscriptionStage.checkout;
			}

			return {
				...resultedState,
			};
		}
		case ESubscriptionActionTypes.SET_CHANGE_SUBSCRIPTION_CURRENCY: {
			return {
				...state,
				changeSubscriptionSelectedCurrency: action.payload.currency,
			};
		}
		default:
			return state;
	}
}
