import { all, call, put, takeLatest, select, take } from "redux-saga/effects";

import {
	EAuthActionTypes,
	ESubscriptionActionTypes,
	ICommunityMemberPlan,
	IPaymentMethod,
	IUser,
	TCurrentSubscription,
	TStore,
} from "models";
import {
	addANewPaymentMethodAction,
	api,
	cancelSubscriptionActionSaga,
	deletePaymentMethodAction,
	deletePaymentMethodActionSaga,
	getSubscriptionManagementActionSaga,
	getUserCurrentSubscriptionAction,
	notify,
	resubscribeSagaAction,
	setDefaultPaymentMethodSagaAction,
	setPaymentMethods,
	setSubscriptionAction,
	setSubscriptionManagementAction,
	setUserCurrentSubscriptionAction,
} from "store";
import { rollbar } from "utils";

const getCommunityId = (state: TStore) => state.app.currentCommunityId;
const getCurrentPlanId = (state: TStore) =>
	state.auth.credentials?.user?.current_plan_id;
const getCurrentSubscription = (state: TStore) =>
	state.auth.credentials?.user?.current_subscription;
const getCurrentMemberPlan = (state: TStore) =>
	state.subscription?.currentMemberPlan;
const getSubscriptionPaymentMethods = (state: TStore) =>
	state.subscription.paymentMethods;
const getAuthenticatedUser = (state: TStore) => state.auth.credentials?.user;

function* getSubscriptionManagement({
	payload,
}: ReturnType<typeof getSubscriptionManagementActionSaga>) {
	const { onSuccessCallback, onErrorCallback, communityId } = payload || {};
	const globalStoreCommunityId: number = yield select(getCommunityId);
	const currentPlanId: number = yield select(getCurrentPlanId);
	const currentSubscription = yield select(getCurrentSubscription);
	const activeMemberPlan = yield select(getCurrentMemberPlan);
	const resultedCommunityId = communityId
		? communityId
		: globalStoreCommunityId;
	try {
		if (
			!resultedCommunityId &&
			!activeMemberPlan.free &&
			!currentSubscription
		) {
			throw new Error("Missing parameters.");
		}
		// get all member plans
		const { data: memberPlans } = yield call(
			api.subscription.getMembersPlans,
			{
				communityId: resultedCommunityId,
			},
		);

		// filter the current member plan
		const currentMemberPlan = memberPlans.plans.filter(
			(plan: ICommunityMemberPlan) => plan.id === currentPlanId,
		);

		// get current subscription
		let memberSubscription: any = null;

		const { data } = yield call(api.subscription.getCurrentSubscription, {
			communityId: resultedCommunityId,
		});
		memberSubscription = data;
		// * When loading Subscription Management set again the users current subscription.
		yield put(
			setUserCurrentSubscriptionAction({
				currenSubscription: memberSubscription?.current_subscription,
				currentPlanId:
					memberSubscription?.current_subscription?.plan?.id,
			}),
		);

		// get memb	er invoices
		const { data: memberInvoices } = yield call(
			api.subscription.getMemberInvoices,
			{
				communityId: resultedCommunityId,
			},
		);

		yield put(
			setSubscriptionManagementAction({
				currentMemberPlan: memberSubscription?.current_subscription
					?.plan
					? memberSubscription.current_subscription.plan
					: currentMemberPlan[0],
				paymentMethods: memberSubscription
					? memberSubscription.payment_methods
					: undefined,
				invoices: memberInvoices.member_invoices,
				subscription: memberSubscription
					? memberSubscription.current_subscription
					: undefined,
				loading: false,
			}),
		);

		onSuccessCallback?.();
	} catch (error) {
		rollbar.error(
			error
		)
		onErrorCallback?.();
		console.error(error);
	}
	yield put({
		type: ESubscriptionActionTypes.COMPLETE_GET_SUBSCRIPTION_MANAGEMENT,
	});
}

function* deletePaymentMethod({
	payload,
}: ReturnType<typeof deletePaymentMethodActionSaga>) {
	const { paymentMethodId, onErrorCallback, onSuccessCallback } = payload;
	const communityId = yield select(getCommunityId);
	const paymentMethods: IPaymentMethod[] = yield select(
		getSubscriptionPaymentMethods,
	);
	try {
		if (!communityId || (paymentMethods && paymentMethods.length === 0)) {
			throw new Error("Missing parameters!");
		}
		if (paymentMethods.length === 1) {
			notify(
				"error",
				"Error!",
				"Sorry, the only payment method cannot be deleted!",
			);
			throw new Error(
				"Sorry, the only payment method cannot be deleted!",
			);
		}
		onSuccessCallback?.();
		yield call(api.subscription.deleteMemberPaymentMethod, {
			communityId,
			paymentMethodId,
		});

		yield put(deletePaymentMethodAction(paymentMethodId));
	} catch (error) {
		rollbar.error(
			error
		)
		console.error({ error });
		onErrorCallback?.();
	}
}

function* cancelSubscription({
	payload,
}: ReturnType<typeof cancelSubscriptionActionSaga>) {
	const { onErrorCallback, onSuccessCallback } = payload;
	const communityId: number = yield select(getCommunityId);
	const currentSubscription: TCurrentSubscription = yield select(
		getCurrentSubscription,
	);
	const paymentMethods: IPaymentMethod[] = yield select(
		getSubscriptionPaymentMethods,
	);
	const defaultPaymentMethodId: string | null =
		typeof currentSubscription === "object"
			? currentSubscription.stripe_payment_method_id
			: null;

	try {
		if (
			!currentSubscription ||
			typeof currentSubscription === "string" ||
			!currentSubscription?.id
		) {
			throw new Error("No subscription found!");
		} else {
			const { data } = yield call(api.subscription.cancelSubscription, {
				communityId,
				subscriptionId: currentSubscription.id,
			});
			if ((data.message = "Successfully canceled subscription")) {
				yield put(setSubscriptionAction(data.subscription));
				yield put(getUserCurrentSubscriptionAction({ communityId }));
			}

			// The default payment method is not deleted.
			const parsedPaymentMethods = paymentMethods?.filter(
				(item: IPaymentMethod) =>
					item.data.id !== defaultPaymentMethodId,
			);
			const parsedPaymentMethodsLength = parsedPaymentMethods
				? parsedPaymentMethods.length
				: 0;

			// delete all the payment methods associated with the deleted subscription.
			// TODO: don't delete the default payment method
			for (let i = 0; i < parsedPaymentMethodsLength; i++) {
				yield call(api.subscription.deleteMemberPaymentMethod, {
					communityId,
					paymentMethodId: parsedPaymentMethods[i].data.id,
				});

				yield put(
					deletePaymentMethodAction(parsedPaymentMethods[i].data.id),
				);
			}
		}
		onSuccessCallback?.();
	} catch (error) {
		rollbar.error(
			error
		)
		onErrorCallback?.();
		console.error({ error });
	}
}

function* addANewPaymentMethodHandle({
	payload,
}: ReturnType<typeof addANewPaymentMethodAction>) {
	const {
		onErrorCallback,
		onSuccessCallback,
		paymentMethodId,
		selectedCommunityId,
	} = payload;

	try {
		const communityId: number = selectedCommunityId
			? selectedCommunityId
			: yield select(getCommunityId);
		const currentSubscription = yield select(getCurrentSubscription);
		if (!currentSubscription) {
			throw new Error("Missing parameters.");
		}
		const {
			data,
		}: {
			data: {
				customer: { success: boolean; errors: string[][] };
				subscription: { success: boolean; errors: string[][] };
			};
		} = yield call(api.subscription.addANewPaymentMethod, {
			communityId,
			stripeId: paymentMethodId,
		});

		if (data.customer.success && data.subscription.success) {
			const { data }: { data: { payment_methods: IPaymentMethod[] } } =
				yield call(api.subscription.getCurrentSubscription, {
					communityId: communityId,
				});

			if (data?.payment_methods) {
				yield put(
					setPaymentMethods({
						paymentMethods: data?.payment_methods,
					}),
				);
			}
			onSuccessCallback?.();
		} else if (data?.customer?.errors) {
			onErrorCallback?.(data?.customer?.errors);
		}
	} catch (error: any) {
		onErrorCallback?.(error?.customer?.errors);
		console.error({ error });
	}
}
function* setDefaultPaymentMethodHandle({
	payload,
}: ReturnType<typeof setDefaultPaymentMethodSagaAction>) {
	const {
		onErrorCallback,
		onSuccessCallback,
		paymentMethodId,
		skipCustomer,
		skipSubscription,
	} = payload;
	const communityId: number = yield select(getCommunityId);

	try {
		if (paymentMethodId && communityId) {
			yield call(api.subscription.setDefaultPaymentMethod, {
				communityId: communityId,
				paymentMethodId,
				skipCustomer,
				skipSubscription,
			});
			// refetch the subscription data.
			yield put(getSubscriptionManagementActionSaga());
			yield take(
				ESubscriptionActionTypes.COMPLETE_GET_SUBSCRIPTION_MANAGEMENT,
			);
			yield put(getUserCurrentSubscriptionAction({ communityId }));
			yield take(EAuthActionTypes.getUserCurrentSubscriptionCompleted);
		} else {
			throw new Error("Missing parameters!");
		}

		onSuccessCallback?.();
	} catch (error: any) {
		rollbar.error(
			error
		)
		onErrorCallback?.();
		console.error({ error });
	}
	yield put({
		type: ESubscriptionActionTypes.SET_DEFAULT_PAYMENT_METHOD_COMPLETED,
	});
}
function* resubscribeHandle({
	payload,
}: ReturnType<typeof resubscribeSagaAction>) {
	const { onErrorCallback, onSuccessCallback } = payload;
	const communityId: number = yield select(getCommunityId);
	const currentSubscription: TCurrentSubscription = yield select(
		getCurrentSubscription,
	);
	const authenticatedUser: IUser = yield select(getAuthenticatedUser);
	try {
		if (
			communityId &&
			typeof currentSubscription === "object" &&
			currentSubscription?.id &&
			authenticatedUser
		) {
			const { data } = yield call(
				api.subscription.reactivateSubscription,
				{
					communityId,
					subscriptionId: currentSubscription.id,
				},
			);
			yield put(setSubscriptionAction({ ...data.subscription }));
			yield put(
				getUserCurrentSubscriptionAction({
					communityId,
					userId: authenticatedUser.id,
				}),
			);
		} else {
			throw new Error("Missing parameters!");
		}
		onSuccessCallback?.();
	} catch (error: any) {
		rollbar.error(
			error
		)
		onErrorCallback?.();
		console.error({ error });
	}
}

export default function* rootSaga() {
	yield all([
		takeLatest(
			ESubscriptionActionTypes.GET_SUBSCRIPTION_MANAGEMENT,
			getSubscriptionManagement,
		),
		takeLatest(
			ESubscriptionActionTypes.DELETE_PAYMENT_METHOD_SAGA,
			deletePaymentMethod,
		),
		takeLatest(
			ESubscriptionActionTypes.CANCEL_SUBSCRIPTION,
			cancelSubscription,
		),
		takeLatest(
			ESubscriptionActionTypes.ADD_NEW_PAYMENT_METHOD,
			addANewPaymentMethodHandle,
		),
		takeLatest(ESubscriptionActionTypes.RESUBSCRIBE, resubscribeHandle),
		takeLatest(
			ESubscriptionActionTypes.SET_DEFAULT_PAYMENT_METHOD,
			setDefaultPaymentMethodHandle,
		),
	]);
}
