import { combineReducers } from "redux";
import * as reduxForm from "redux-form";
import { History } from "history";
import { createRouterReducer } from "@lagunovsky/redux-react-router";

import { authReducer } from "./auth";
import { commentsReducer } from "./comments";
import { communitiesReducer } from "./communities";
import { communityReducer } from "./community";
import { groupsReducer } from "./groups";
import { messagesReducer } from "./messages";
import { membersReducer } from "./members";
import { memberReducer } from "./member";
import { editProfileReducer } from "./profile";
import { postsReducer } from "./posts";
import { postReducer } from "./post";
import { appReducer } from "./app";
import { oneSignalReducer } from "./oneSignal";
import { marketplaceOffersReducer } from "./marketplaceOffers";
import { marketplaceOfferReducer } from "./marketplaceOffer";
import { eventsReducer } from "./events";
import { eventReducer } from "./event";
import { firebaseReducer } from "./firebase";
import { communitiesJoinedReducer } from "./communitiesJoined";
import { docReducer } from "./doc";
import { docsReducer } from "./docs";
import { videoReducer } from "./video";
import { videosReducer } from "./videos";
import { resourcesReducer } from "./resources";
import { offerReducer } from "./offer";
import { offersReducer } from "./offers";
import { viewCountsReducer } from "./viewCounts";
import { mediaItemReducer } from "./mediaItem";
import { notificationsReducer } from "./notifications";
import { EAppActionTypes } from "models";
import { resourceReducer } from "./resource";
import { paymentsReducer } from "./payments";
import { liveStreamReducer } from "./liveStream";
import { subscriptionReducer } from "./subscription";
import { previewWhiteLabelCommunityReducer } from "./previewWhiteLabelCommunity";
import { isItAWhiteLabel } from "utils";
import { settingDefaultPalleteOfColors } from "utils";

const appReducers = (history: History) =>
	combineReducers(
		// @ts-ignore
		Object.fromEntries(
			Object.entries({
				router: createRouterReducer(history),
				auth: authReducer,
				form: reduxForm.reducer,
				comments: commentsReducer,
				communities: communitiesReducer,
				community: communityReducer,
				groups: groupsReducer,
				messages: messagesReducer,
				members: membersReducer,
				editProfile: editProfileReducer,
				posts: postsReducer,
				post: postReducer,
				app: appReducer,
				oneSignal: oneSignalReducer,
				marketplaceOffers: marketplaceOffersReducer,
				marketplaceOffer: marketplaceOfferReducer,
				events: eventsReducer,
				calendarEventData: eventReducer,
				firebase: firebaseReducer,
				communitiesJoined: communitiesJoinedReducer,
				member: memberReducer,
				document: docReducer,
				documents: docsReducer,
				video: videoReducer,
				videos: videosReducer,
				resources: resourcesReducer,
				offer: offerReducer,
				offers: offersReducer,
				viewCounts: viewCountsReducer,
				mediaItem: mediaItemReducer,
				notifications: notificationsReducer,
				resource: resourceReducer,
				payments: paymentsReducer,
				liveStream: liveStreamReducer,
				subscription: subscriptionReducer,
				previewWhiteLabelCommunity: previewWhiteLabelCommunityReducer,
			}).sort(),
		),
	);

export const rootReducer =
	(history: History) =>
	(state: any, action: { type: string; payload?: any }) => {
		if (action.type === EAppActionTypes.resetStoreSuccess) {
			if (isItAWhiteLabel()) {
				// When the whole state is set to undefined, check if the current config is based on a whitelabel app.
				// If so, reset all the state data except previewWhiteLabelCommunity key, which contains data (picture, colors etc) for the white label app.
				const previewWhiteLabelCommunity =
					state.previewWhiteLabelCommunity;
				state = { previewWhiteLabelCommunity };
			} else {
				state = undefined;
				settingDefaultPalleteOfColors();
			}
		}
		// @ts-ignore
		return appReducers(history)(state, action);
	};
