import React from "react";
import { Modal } from "antd";

import styles from "./styles.module.scss";
import { Loading } from "components";

interface IIsProcessingModal {
	visible: boolean;
	showErrorMessage: boolean;
}

const IsProcessingModal: React.FunctionComponent<IIsProcessingModal> = ({
	visible,
	showErrorMessage = false,
}) => {
	return (
		<Modal
			closable={false}
			visible={visible}
			title="Processing..."
			className={styles.modal}
			wrapClassName={styles.modalWrap}
			footer={[]}
		>
			<div className={styles.spinnerContainer}>
				{showErrorMessage ? (
					"Payment server system error. Please try again."
				) : (
					<Loading />
				)}
			</div>
		</Modal>
	);
};

export default IsProcessingModal;
