import React, { memo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate, useMatch } from "react-router-dom";

import { Loading } from "components";
import { getInvitationDataByCommunityLink, getInvite } from "store";

const Invites = () => {
	const navigate = useNavigate();
	const { token }: { token?: string } = useParams();
	const dispatch = useDispatch();
	let communitySpecificLinkInvitationMatch = useMatch("/hubs/:token/sign-up");

	useEffect(() => {
		if (!token) {
			navigate("/auth/sign-up");
		} else {
			if (!communitySpecificLinkInvitationMatch) {
				dispatch(getInvite(token));
			} else {
				dispatch(getInvitationDataByCommunityLink({ token }));
			}
		}
	}, [communitySpecificLinkInvitationMatch, dispatch, navigate, token]);

	return <Loading />;
};

export default memo(Invites);
