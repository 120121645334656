/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useCallback, useEffect, useMemo } from "react";
import { IDocsState, IResourcesState, IVideosState, TStore } from "models";
import InfiniteScroll from "react-infinite-scroller";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { Col, Row } from "antd";
import Scrollbar from "react-scrollbars-custom";

import { Loading, MediaBox } from "components";
import { getDocs, getResources, getVideos } from "store";
import styles from "./styles.module.scss";

const stateSelectorHandle = (type: keyof TStore) =>
	createSelector(
		(state: TStore) =>
			state[type] as IVideosState | IDocsState | IResourcesState,
		(state: TStore) => state.app.currentGroupId,
		(
			items: IVideosState | IDocsState | IResourcesState,
			currentGroupId,
		) => {
			return {
				items,
				currentGroupId,
			};
		},
	);

interface IList {
	type: keyof TStore;
}

const List: React.FunctionComponent<IList> = ({ type }) => {
	const dispatch = useDispatch();

	const stateSelector = useCallback(stateSelectorHandle(type), []);
	const { items, currentGroupId } = useSelector(stateSelector);

	const noResultsMessage: string = useMemo(() => {
		if (type === "documents") {
			return "No documents, yet.";
		} else if (type === "videos") {
			return "No videos, yet.";
		} else if (type === "resources") {
			return "No resources, yet.";
		} else {
			return "";
		}
	}, []);

	const handleLoadMore = useCallback(() => {
		if (items?.loading || !items?.meta?.pagination?.has_next_page) {
			return;
		} else {
			switch (type) {
				case "videos":
					dispatch(getVideos({}));
					break;
				case "resources":
					dispatch(getResources({}));
					break;
				case "documents":
					dispatch(getDocs({}));
					break;
				default:
					break;
			}
		}
	}, [
		dispatch,
		items?.loading,
		items?.meta?.pagination?.has_next_page,
		type,
	]);

	useEffect(() => {
		switch (type) {
			case "videos":
				dispatch(
					getVideos({
						reset: true,
					}),
				);
				break;
			case "resources":
				dispatch(
					getResources({
						reset: true,
					}),
				);
				break;
			case "documents":
				dispatch(
					getDocs({
						reset: true,
					}),
				);
				break;
		}
	}, [currentGroupId, dispatch, type]);

	return (
		<Scrollbar
			scrollerProps={{
				renderer: (props) => {
					const { elementRef, ...restProps } = props;
					return (
						<div
							id="listSectionId"
							{...restProps}
							ref={elementRef}
						/>
					);
				},
			}}
		>
			<InfiniteScroll
				pageStart={1}
				loadMore={handleLoadMore}
				hasMore={items.meta.pagination.has_next_page}
				initialLoad={false}
				useWindow={false}
				loader={
					<div
						key={0}
						style={{
							padding: "12px 8px",
							flexGrow: 1,
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<Loading />
					</div>
				}
				getScrollParent={() => document.getElementById("listSectionId")}
				style={{
					display: "flex",
					flex: 1,
					flexDirection: "column",
				}}
			>
				{items.data && !items.data.length && (
					<div className={"emptyPlaceholder"}>{noResultsMessage}</div>
				)}
				{items?.data?.map((item, index: number) => (
					<Row
						style={{
							display: "flex",
							flex: 1,
							margin: "5px 0",
						}}
						key={`item_${type}_${index}`}
					>
						<Col
							xs={24}
							sm={24}
							className={styles.mediaBoxContainer}
						>
							<MediaBox {...item} type={type} />
						</Col>
					</Row>
				))}
			</InfiniteScroll>
		</Scrollbar>
	);
};

export default memo(List);
