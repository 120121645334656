/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useCallback, useEffect, useMemo } from "react";
// import { Breadcrumb, List } from "antd";
// import { FolderOpenOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";

import { IDocsState, IResourcesState, IVideosState, TStore } from "models";
import { ReactComponent as Folder } from "assets/images/folder.svg";
import { ReactComponent as ArrowDown } from "assets/images/arrow-down.svg";
import { ReactComponent as Home } from "assets/images/home.svg";
import { Loading } from "components";
import {
	setDocsCurrentFolderAction,
	setDocsRedirectQuery,
	setMediaPathAction,
	setResourcesCurrentFolderAction,
	setResourcesRedirectQuery,
	setVideosCurrentFolderAction,
	setVideosRedirectQuery,
} from "store";
import styles from "../../styles.module.scss";
import classes from "./styles.module.scss";

import { Tree } from 'antd';
import type { DataNode, DirectoryTreeProps } from 'antd/es/tree';

const { DirectoryTree } = Tree;

const stateSelectorHandle = (type: keyof TStore) =>
	createSelector(
		(state: TStore) => {
			const result: IVideosState | IDocsState | IResourcesState = state[
				type
			] as IVideosState | IDocsState | IResourcesState;
			return {
				folders: result.meta.folders,
				loading: result.loading,
				path: result.path,
			};
		},
		(data) => {
			return {
				...data,
			};
		},
	);

interface IFilterByPath {
	type: keyof TStore;
}

const formatFolders = (folders, pKey) => {
	if(!folders?.length) return [];

	const formattedArr: any[] = [];

	for (let index = 0; index < folders.length; index++) {
		const item = folders[index];

		formattedArr.push({ ...item, key: pKey ? `${pKey}-${item.id}` : String(item.id), title: item.name, children: item.children.length? formatFolders(item.children, pKey ? `${pKey}-${item.id}` : String(item.id)): [] });
	}

	return formattedArr;
};

const FilterByPath: React.FunctionComponent<IFilterByPath> = ({ type }) => {
	const dispatch = useDispatch();
	const stateSelector = useCallback(stateSelectorHandle(type), []);
	const { folders, loading, path } = useSelector(stateSelector);
	// const [expendSubFolders, setExpandSubFolders] = useState<number[]>([]);

	const handleFolderClick = (item: any) => () => {
		dispatch(setMediaPathAction({ type, item }));
	};

	const breadcrumbClick = (index: undefined | number) => () => {
		if (!path.length) return;
		if (index === undefined) {
			dispatch(setMediaPathAction({ type, path: [] }));
			dispatch(setVideosRedirectQuery({redirectQuery:null}));
			dispatch(setDocsRedirectQuery({redirectQuery:null}));
			dispatch(setResourcesRedirectQuery({redirectQuery:null}));

		} else {
			dispatch(setMediaPathAction({ type, index }));
			dispatch(setVideosRedirectQuery({redirectQuery:null}));
			dispatch(setDocsRedirectQuery({redirectQuery:null}));
			dispatch(setResourcesRedirectQuery({redirectQuery:null}));

		}
	};

	useEffect(() => {
		switch (type) {
			case "videos":
				dispatch(
					setVideosCurrentFolderAction(
						path.length > 0 ? path[path.length - 1].id : 0,
					),
				);
				break;
			case "resources":
				dispatch(
					setResourcesCurrentFolderAction(
						path.length > 0 ? path[path.length - 1].id : 0,
					),
				);
				break;
			case "documents":
				dispatch(
					setDocsCurrentFolderAction(
						path.length > 0 ? path[path.length - 1].id : 0,
					),
				);
				break;
		}
	}, [dispatch, path, type]);

	const onSelect: DirectoryTreeProps['onSelect'] = (_keys, info) => {
		handleFolderClick(info.selectedNodes[0])();
	};

	const treeData = useMemo(() => formatFolders(folders, null), [folders]);

	return (
		<div className={styles.filterContainer}>
			{loading && <Loading />}
			{!loading && (
				<div>
					<div className={classes.cardTitleContainer}>
						<h1 className={classes.cardTitle}>Folders</h1>
					</div>
					{path && path?.length > 0 ? (
						<div className={classes.folders} style={{ padding: 0 }}>
							<div
								className={classes.folder}
								style={{
									height: "100%",
									display: "flex",
									alignItems: "center",
								}}
							>
								<div
									className={classes.backToRoot}
									onClick={breadcrumbClick(undefined)}
								>
									<Home width={18} height={18} />
								</div>
								<div
									onClick={() => {
										if (
											path.length > 1 &&
											path[path.length - 1].name !==
												path[path.length - 2].name
										)
											breadcrumbClick(1)();
										else breadcrumbClick(undefined)();
									}}
									style={{
										padding: "0 10px",
										display: "flex",
										alignItems: "center",
										gap: "10px",
									}}
								>
									<ArrowDown className={classes.leftArrow} />
									<div className={classes.folder}>

									<h1
										style={{
											fontSize: 16,
											lineHeight: "18px",
										}}
									>
										{path[path?.length - 1].name}
									</h1>
									</div>
								</div>
							</div>
						</div>
					) : null}

					<DirectoryTree
						onSelect={onSelect}
						treeData={treeData as unknown as DataNode[]}
						icon={<Folder width={24} height={20} />}
						rootClassName="media-directory-tree"
						switcherIcon={<ArrowDown />}
					/>
				</div>
			)}
		</div>
	);
};

export default memo(FilterByPath);
