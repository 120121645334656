import { axiosInstance } from "..";

export const profile = {
	getPublicProfile: (payload: any) =>
		axiosInstance().get(
			`/communities/${payload.community_id}/profiles/${payload.user_id}/public`,
		),
	getEditProfile: (payload: any) =>
		axiosInstance()
			.get(
				`/communities/${payload.community_id}/profiles/${payload.user_id}/edit`,
			)
			.then((response) => {
				return response;
			}),

	getProfileAccountSettings: (payload: {
		community_id: number;
		user_id: number;
	}) =>
		axiosInstance().get(
			`/communities/${payload.community_id}/users/${payload.user_id}/edit`,
		),

	getEditProfilePosts: (payload: any) =>
		axiosInstance().get(
			`/communities/${payload.community_id}/community-posts`,
			{
				params: { user_id: payload.user_id, pin_type: 0 },
			},
		),
	getUserProfile: (payload: any) =>
		axiosInstance().get(
			`/communities/${payload.community_id}/users/${payload.user_id}`,
		),

	saveUserProfile: (payload: any) => {
		let formData = new FormData();
		const { avatar } = payload;
		const chekForNewAvatar =
			avatar && avatar.file && avatar.file.originFileObj ? true : false;
		if (chekForNewAvatar) {
			formData.append(`user[picture]`, avatar.file.originFileObj);
		}
		if (payload.user) {
			Object.keys(payload.user).map((element) => {
				formData.append(`user[${element}]`, payload.user[element]);

				return undefined;
			});
		}
		return axiosInstance().patch(`/users/${payload.user_id}`, formData, {
			headers: {
				accept: "application/json",
				"Content-Type": "multipart/form-data",
			},
		});
	},

	saveUserProfileAvatar: (payload: any) => {
		let formData = new FormData();
		const { avatar } = payload;
		const chekForNewAvatar =
			avatar && avatar.file && avatar.file.originFileObj ? true : false;
		if (chekForNewAvatar) {
			formData.append("picture", avatar.file.originFileObj);
			return axiosInstance().patch(
				`/users/${payload.user_id}`,
				formData,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				},
			);
		}
	},

	saveEditProfile: (payload: any) =>
		axiosInstance().patch(
			`/communities/${payload.community_id}/profiles/${payload.user_id}`,
			{ user: payload.user },
		),

	addUserImage: (payload: any) => {
		const { user_id, picture } = payload;
		let formData = new FormData();
		formData.append("picture", picture);
		return axiosInstance().patch(`/users/${user_id}`, formData, {
			headers: {
				"Content-Type": "mutiform/formData",
			},
		});
	},

	addUserLocation: (payload: any) => {
		const { user_id, user } = payload;
		return axiosInstance().patch(`/users/${user_id}`, user, {
			headers: {
				"Content-Type": "application/json",
			},
		});
	},

	setUserDetails: (communityId: number, userId: number, user: any) =>
		axiosInstance().patch(
			`communities/${communityId}/profiles/${userId}`,
			user,
		),

	updateProfileAccountSettings: (
		userId: number,
		user?: {
			password?: string;
			member_directory_opt_in?: boolean;
		},
	) => axiosInstance().patch(`users/${userId}`, { user }),
};
