import { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";

import { ICommunitiesState, TStore, ETypeOfCommunities } from "models";
import CommunityBox from "components/CommunityBox";
import styles from "./styles.module.scss";
import { getCommunitiesInvitations } from "store";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.auth.deepLink,
	(state: TStore) => state.communities,
	(state: TStore) => state.communitiesJoined.searchQuery,
	(
		deepLink: any,
		communities: ICommunitiesState,
		communitiesSearchQuery,
	) => ({
		deepLink,
		communities,
		communitiesSearchQuery,
	}),
);

const InvitesCommunitiesList = () => {
	const dispatch = useDispatch();
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { communities, deepLink, communitiesSearchQuery } =
		useSelector(stateSelector);

	const resultedInvites = useMemo(() => {
		if (
			(!communities.meta?.invites ||
				communities.meta?.invites?.length === 0) &&
			!deepLink
		) {
			return [];
		} else {
			const invites = communities.meta?.invites?.filter(
				(item) =>
					!item.approved &&
					item.name
						.toLowerCase()
						.includes(communitiesSearchQuery.toLowerCase()),
			);
			let invitesAndDeepLinkData: any = [];
			if (deepLink) {
				invitesAndDeepLinkData.push(deepLink);
			}
			if (invites && invites.length > 0) {
				invitesAndDeepLinkData = invitesAndDeepLinkData.concat(invites);
			}
			return [...invitesAndDeepLinkData];
		}
	}, [communities.meta.invites, communitiesSearchQuery, deepLink]);

	useEffect(() => {
		dispatch(getCommunitiesInvitations());
	}, [dispatch]);

	return resultedInvites.length === 0 ? null : (
		<div className={styles.container}>
			<div className={styles.label}>{"Invites"}</div>
			{resultedInvites.map((item) => {
				return (
					<CommunityBox
						key={"community-" + item.id}
						data={item}
						sourceOfCommunitiesDataList={ETypeOfCommunities.Invites}
					/>
				);
			})}
		</div>
	);
};

export default memo(InvitesCommunitiesList);
