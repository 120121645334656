import { ECommunitiesJoinedActionTypes } from "models";

export const setCommunitiesJoinedLoading = (payload: boolean) => ({
	type: ECommunitiesJoinedActionTypes.communitiesJoinedLoadingSet,
	payload,
});

export const getCommunitiesJoined = ({
	q,
	slug,
	successCallback,
	errorCallback,
}: {
	successCallback?: () => void;
	errorCallback?: () => void;
	q?: string;
	slug?: string;
} = {}) => ({
	type: ECommunitiesJoinedActionTypes.getCommunitiesJoined,
	q,
	slug,
	successCallback,
	errorCallback,
});

export const getMoreCommunitiesJoined = ({
	q,
	slug,
}: { q?: string; slug?: string } = {}) => ({
	type: ECommunitiesJoinedActionTypes.getMoreCommunitiesJoined,
	payload: { q, slug },
});

export const setCommunitiesJoined = (payload: any) => ({
	type: ECommunitiesJoinedActionTypes.setCommunitiesJoined,
	payload,
});
export const setCommunitiesJoinedSearchQuery = ({ q }: { q: string }) => ({
	type: ECommunitiesJoinedActionTypes.setSearchQuery,
	q,
});

export const updateJoinedAndApprovedStatusForJoinedCommunityAction = (payload: {
	communityId: number;
	joined: boolean;
	approved: boolean;
}) => ({
	type:
		ECommunitiesJoinedActionTypes.updateJoinedAndApprovedStatusForJoinedCommunity,
	payload,
});
