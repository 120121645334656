import {
	IOffersState,
	EOffersActionTypes,
	TOffersActions,
	EGroupPrivacy,
} from "models";

const initial: IOffersState = {
	loading: false,
	data: undefined,
	selectedTags: [],
	query: "",
	meta: {
		pagination: {
			current_page: 0,
			per_page: 0,
			total_pages: 0,
			total_objects: 0,
			has_next_page: true,
		},
	},
	group: {
		id: 0,
		name: "",
		description: "",
		privacy: EGroupPrivacy.closed,
	},
};

export function offersReducer(
	state = initial,
	action: TOffersActions,
): IOffersState {
	switch (action.type) {
		case EOffersActionTypes.offersLoadingSet: {
			return {
				...state,
				loading: action.payload,
			};
		}
		case EOffersActionTypes.setOffers: {
			const { data, reset } = action.payload;
			const offers = reset
				? data.offers
				: state?.data
				? [...state.data].concat(data.offers)
				: data.offers;

			return {
				...state,
				data: offers,
				meta: action.payload.data.meta,
			};
		}
		case EOffersActionTypes.setFilterTagsInStore: {
			const query = !action.payload.parsedTags.length ? "" : state.query;
			return {
				...state,
				selectedTags: action.payload.parsedTags,
				query,
			};
		}
		case EOffersActionTypes.setOffersQuery: {
			return {
				...state,
				query: action.payload.query,
				selectedTags: [],
			};
		}
		case EOffersActionTypes.incrementRedeemByEmailCounter: {
			return {
				...state,
			};
		}
		case EOffersActionTypes.incrementRedeemByLinkCounter: {
			return {
				...state,
			};
		}
		case EOffersActionTypes.incrementWebsiteViews: {
			return {
				...state,
			};
		}

		default:
			return state;
	}
}
