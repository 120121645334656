import Rollbar, { Configuration } from "rollbar";

export const rollbarConfig: Configuration = {
	accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
	environment: process.env.REACT_APP_ENVIRONMENT,
	captureUncaught: true,
	captureUnhandledRejections: true,
	enabled: process.env.REACT_APP_ENVIRONMENT !== "local",
};

const rollbar = new Rollbar({
	...rollbarConfig,
});

export default rollbar;
