import { axiosInstance } from "..";

export const subscription = {
	getMembersPlans: (payload: { communityId: number }) => {
		const { communityId } = payload;
		return axiosInstance().get(`/communities/${communityId}/member_plans`);
	},

	createSubscription: (payload: {
		communityId: number;
		stripe_plan_price_id: string;
	}) => {
		const { communityId, stripe_plan_price_id } = payload;
		return axiosInstance().post(
			`/communities/${communityId}/member_subscriptions`,
			{
				subscription: {
					stripe_plan_price_id: stripe_plan_price_id,
				},
			},
		);
	},

	getCurrentSubscription: (payload: { communityId: number }) => {
		const { communityId } = payload;
		return axiosInstance().get(
			`/communities/${communityId}/member_subscriptions/current_subscription`,
		);
	},

	subscriptionPending: (payload: {
		communityId: number;
		subscriptionId: number;
	}) => {
		const { communityId, subscriptionId } = payload;
		return axiosInstance().post(
			`/communities/${communityId}/member_subscriptions/${subscriptionId}/pending`,
			{},
		);
	},

	cancelSubscription: (payload: {
		communityId: number;
		subscriptionId: number;
	}) => {
		const { communityId, subscriptionId } = payload;
		return axiosInstance().delete(
			`/communities/${communityId}/member_subscriptions/${subscriptionId}/cancel`,
		);
	},

	reactivateSubscription: (payload: {
		communityId: number;
		subscriptionId: number;
	}) => {
		const { communityId, subscriptionId } = payload;
		return axiosInstance().delete(
			`/communities/${communityId}/member_subscriptions/${subscriptionId}/cancel?cancel=false`,
		);
	},

	changeSubscriptionPlan: (payload: {
		communityId: number;
		subscriptionId: number;
	}) => {
		const { communityId, subscriptionId } = payload;
		return axiosInstance().patch(
			`/communities/${communityId}/member_subscriptions/${subscriptionId}`,
		);
	},

	getMemberInvoices: (payload: { communityId: number }) => {
		const { communityId } = payload;
		return axiosInstance().get(
			`/communities/${communityId}/member_invoices`,
		);
	},

	deleteMemberPaymentMethod: (payload: {
		communityId: number;
		paymentMethodId: string;
	}) => {
		const { communityId, paymentMethodId } = payload;
		return axiosInstance().delete(
			`/communities/${communityId}/payment_methods/${paymentMethodId}`,
		);
	},
	addANewPaymentMethod: (payload: {
		communityId: number;
		stripeId: string;
	}) => {
		const { communityId, stripeId } = payload;
		return axiosInstance().post(
			`/communities/${communityId}/payment_methods`,
			{
				payment_method: {
					stripe_id: stripeId,
				},
			},
		);
	},
	setDefaultPaymentMethod: (payload: {
		paymentMethodId: string;
		communityId: number;
		skipCustomer?: boolean; //**  skip setting the method as default for respective entities */
		skipSubscription?: boolean;
	}) => {
		const { communityId, paymentMethodId, skipCustomer, skipSubscription } =
			payload;
		return axiosInstance().post(
			`/communities/${communityId}/payment_methods`,
			{
				payment_method: {
					stripe_id: paymentMethodId,
				},
				skip_customer: skipCustomer,
				skip_subscription: skipSubscription,
			},
		);
	},
};
