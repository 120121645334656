import { IMessageThreadUser, IPost, IThreads, TStore } from "models";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { createSelector } from "reselect";
import { createPinnedConversation, setSelectedThread } from "store";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.app.currentCommunityId,
	(state: TStore) => state.messages.threads,
	(state: TStore) => state.messages.pinnedConversation,
	(state: TStore) => state.messages.orderedThreads,
	(state: TStore) => state.messages.selectedThread,
	(
		communityId: number | null,
		threads: IThreads | null,
		pinnedConversation,
		orderedThreads,
		selectedThread,
	) => {
		return {
			communityId,
			threads,
			pinnedConversation,
			orderedThreads:
				communityId && orderedThreads[communityId]
					? orderedThreads[communityId]
					: [],
			selectedThread,
		};
	},
);

interface IUseSetPinnedConversation {}

export const useSetPinnedConversation: React.FunctionComponent<
	IUseSetPinnedConversation
> = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const stateSelector = useCallback(stateSelectorHandle, []);
	const {
		communityId,
		threads: allThreads,
		pinnedConversation,
		orderedThreads,
		selectedThread,
	} = useSelector(stateSelector);
	const [pinnedConv, setPinnedConv] = useState(false);
	const location = useLocation();

	const communityThreads:
		| undefined // undefined if there is no thread for current community id
		| null // data is not yet fetched from api
		| { [key: string]: any } = useMemo(() => {
		if (allThreads && communityId) {
			return allThreads[communityId].posts;
		} else {
			return null;
		}
	}, [allThreads, communityId]);

	useEffect(() => {
		// this useEffect is used to create a pinned conversation
		if (
			!pinnedConv &&
			communityThreads &&
			allThreads &&
			communityId &&
			!selectedThread
		) {
			let member: IMessageThreadUser | undefined = undefined;
			let post: IPost | undefined = undefined;
			setPinnedConv(true);
			if (location.state?.member) {
				member = location.state.member;
				// remove the member from the history stack
				navigate("/messages", { replace: true, state: null });
			} else if (location.state?.post) {
				post = location.state.post;
			}

			if (
				!member &&
				!post &&
				allThreads[communityId].posts &&
				orderedThreads?.length > 0
			) {
				let firstMessageThread: any = null;
				const orderedThreadsLength = orderedThreads.length;
				for (let i = 0; i <= orderedThreadsLength; i++) {
					if (
						allThreads[communityId].posts[orderedThreads[i]]
							?.activity_type === "message"
					) {
						firstMessageThread =
							allThreads[communityId].posts[orderedThreads[i]];
						break;
					}
				}

				dispatch(setSelectedThread(firstMessageThread));
			} else {
				if (member || post) {
					let activeThreadId = 0;
					for (let id in communityThreads) {
						const user: IMessageThreadUser =
							communityThreads[id].user;

						let users = communityThreads[id].users;
						if (users) {
							users = users
								.filter((u: IMessageThreadUser) => !!u.id)
								.map((u: IMessageThreadUser) => u.id);
						}
						if (
							(member &&
								user?.user_id === member.id &&
								communityThreads[id].member_dialogue) ||
							(post &&
								(user?.id === post.membership_id ||
									users?.indexOf(post.membership_id) !==
										-1) &&
								communityThreads[id].community_post_id ===
									post.community_post_id)
						) {
							activeThreadId = parseInt(id);
							break;
						}
					}

					if (activeThreadId && communityThreads) {
						dispatch(
							createPinnedConversation({
								...communityThreads[activeThreadId],
							}),
						);
						dispatch(
							setSelectedThread({
								...communityThreads[activeThreadId],
							}),
						);
					} else if (!activeThreadId) {
						if (member) {
							dispatch(
								createPinnedConversation({
									id: 0,
									user: member,
								}),
							);
							dispatch(
								setSelectedThread({
									id: 0,
									user: member,
								}),
							);
						} else if (post && post.user) {
							const {
								email,
								name,
								picture,
								postcode,
								full_name,
							} = post.user;
							dispatch(
								createPinnedConversation({
									id: 0,
									community_post_id:
										(post && post.community_post_id) || 0,
									title: (post && post.title) || "",
									user: {
										email: email as string,
										name: name as string,
										picture: picture as string,
										postcode: postcode as string,
										full_name: full_name as string,
										user_id: post.user.id as number,
										id: post.membership_id,
									},
								}),
							);
							dispatch(
								setSelectedThread({
									id: 0,
									community_post_id:
										(post && post.community_post_id) || 0,
									title: (post && post.title) || "",
									user: {
										email: email as string,
										name: name as string,
										picture: picture as string,
										postcode: postcode as string,
										full_name: full_name as string,
										user_id: post.user.id as number,
										id: post.membership_id,
									},
								}),
							);
						}
					}
				}
			}
		}
	}, [
		communityThreads,
		orderedThreads,
		dispatch,
		pinnedConversation,
		allThreads,
		communityId,
		pinnedConv,
		location,
		selectedThread,
		navigate,
	]);

	useEffect(() => {
		// this useEffect will update an existent pinned conversation
		if (pinnedConversation && communityThreads) {
			for (let id in communityThreads) {
				if (
					communityThreads[id].id === pinnedConversation.id &&
					!communityThreads[id].community_post_id &&
					communityThreads[id].body !== pinnedConversation.body
				) {
					dispatch(
						createPinnedConversation({
							...communityThreads[id],
						}),
					);
					break;
				}
			}
		}
	}, [communityThreads, dispatch, pinnedConversation]);

	return null;
};
