// import { IResource } from "models";
import { IFolder } from "components";
import { IDoc } from "models/doc";
import { EGroupPrivacy } from "models/groups";
import { IVideo } from "models/video";
import {
	IResourceSetAction,
	ISetResourceCreatedIntervalAction,
	ISetResourceIdAction,
	ISetResourceSuccessfullyUploadedAction,
	ISetResourceUploadProgressAction,
} from "./actions";

// ENUMS
export enum EResourceActionTypes {
	resourcesLoadingSet = "resource/set/loading",
	getResource = "resource/get",
	setResource = "resource/set",
	setResourceUploadProgress = "resource/set/upload/progress", // used for progress bar
	setResourceId = "set/resource/id", // used for progress bar
	setResourceSuccessfullyUploaded = "set/resource/successfully/uploaded", // used for progress bar
	getResourceForProgressbar = "get/resource/for/progressbar", // used for progress bar
	setInterval = "set/resource/createdInterval", // used for progress bar
}

// INTERFACES
export interface IResourceState {
	loading: boolean;
	path: IFolder[];
	data?: IVideo | IDoc;
	currentFolder: undefined | number;
	order: "view_count" | "created_at" | "title" | "latest";
	order_dir: "asc" | "desc";
	meta: {
		pagination: {
			current_page: number;
			per_page: number;
			total_pages: number;
			total_objects: number;
			has_next_page: boolean;
		};
		scope_group: {
			id: number;
			name: string;
			description: string;
			privacy: EGroupPrivacy;
		};
		folders:
			| null
			| {
					children: any[];
					id: number;
					name: string;
					path: string;
			  }[];
	};
	uploadProgress: number | undefined; // used for progress bar
	resourceId: number | undefined; // used for progress bar
	resourceSuccessfullyUploaded: boolean | undefined; // used for progress bar
	createdInterval: number | undefined; // used for progress bar
}

// TYPES
export type TResourceActions =
	| IResourceSetAction
	| ISetResourceUploadProgressAction
	| ISetResourceIdAction
	| ISetResourceSuccessfullyUploadedAction
	| ISetResourceCreatedIntervalAction;
