import { call, put, all, takeEvery, select } from "redux-saga/effects";

import { api } from "store";
import {
	getResource,
	setResource,
	setResourceSuccessfullyUploadedAction,
} from "store/actions";
import { EResourceActionTypes } from "models/resource";
import { TStore } from "models";
import { rollbar } from "utils";

const getCommunityId = (state: TStore) => state.app.currentCommunityId;

function* getResourceHandle({ payload }: ReturnType<typeof getResource>) {
	const {
		callbackOnError,
		callbackOnSuccess,
		id,
		forceCommunityId,
	} = payload;
	try {
		const currentCommunityId: number = yield select(getCommunityId);

		const { data } = yield call(api.resources.get, {
			resourceId: id,
			communityId: forceCommunityId
				? forceCommunityId
				: currentCommunityId,
		});

		yield put(setResource({ data: data.resources[0], meta: data.meta }));

		if (callbackOnSuccess) {
			callbackOnSuccess();
		}
	} catch (error) {
		rollbar.error(
			error
		)
		console.error({ error });
		if (callbackOnError) {
			callbackOnError();
		}
	}
}

function* getResourceForProcessbar({
	payload,
}: ReturnType<typeof getResource>) {
	const { id, forceCommunityId } = payload;
	try {
		const currentCommunityId: number = yield select(getCommunityId);
		const { data } = yield call(api.resources.get, {
			resourceId: id,
			communityId: forceCommunityId
				? forceCommunityId
				: currentCommunityId,
		});

		if (data.resources[0]) {
			yield put(setResourceSuccessfullyUploadedAction(true));
		}
	} catch (error) {
		rollbar.error(
			error
		)
		console.error({ error });
	}
}

export default function* rootSaga() {
	yield all([
		takeEvery(EResourceActionTypes.getResource, getResourceHandle),
		takeEvery(
			EResourceActionTypes.getResourceForProgressbar,
			getResourceForProcessbar,
		),
	]);
}
