import axios from "axios";
import { axiosInstance } from "../";

export const community = {
	get: (payload: { community_id: number }) =>
		axiosInstance().get(`/communities/${payload.community_id}`),
	getTags: (payload: { community_id: number }) =>
		axiosInstance().get(
			`/communities/${payload.community_id}/community-posts/new`,
		),
	getMembers: (payload: { community_id: number }) =>
		axiosInstance().get(`/communities/${payload.community_id}/users`),
	addPost: (payload: { community_id: number; community_post: unknown }) =>
		axiosInstance().post(
			`/communities/${payload.community_id}/community-posts`,
			{
				community_post: payload.community_post,
			},
		),
	getOneJoin: (payload: { community_id: number }) =>
		axiosInstance().get(`/communities/${payload.community_id}/join`),
	home: (payload: { community_id: number }) =>
		axiosInstance().get(`/communities/${payload.community_id}/home`),
	about: (payload: { community_id: number }) =>
		axiosInstance().get(`/communities/${payload.community_id}/about`),
	terms: (payload: { community_id: number }) =>
		axiosInstance().get(`/communities/${payload.community_id}`),
	leave: (payload: { community_id: number }) =>
		axiosInstance().delete(`/communities/${payload.community_id}/leave`),
	setMembership: (payload: {
		communityId: number;
		tags: string[];
		skill_tags?: string[];
		membership_type: string;
		stripe_plan_price_id: string | null | undefined;
	}) => {
		const { communityId, ...data } = payload;

		return axiosInstance().post(
			`/communities/${communityId}/memberships`,
			data,
		);
	},
	setCommunityInterests: (payload: { community_id: number }) =>
		// Backend quirk: I need to call this endpoint in order to make 'profile_completed' key = true.
		// profile_completed is returned by the /session endpoint
		axiosInstance().post(`/communities/${payload.community_id}/interests`, {
			tags: [""],
		}),
	checkJoinCommunityStatus: (payload: { community_id: number }) =>
		axios({
			method: "post",
			url: "http://localhost:4002/check-join-community-status",
		})
			.then((response) => {
				return response;
			})
			.catch((error) => {
				return error;
			}),
	getMemberPlans: ({
		communityId,
		invited,
	}: {
		communityId: number;
		invited?: boolean;
	}) =>
		axiosInstance().get(`/communities/${communityId}/member_plans`, {
			params: {
				invited, //if 'invited' === true => fetch the plan the user was invited for
			},
		}),
};
