import { axiosInstance } from "..";
import { IEventsGetPayload } from "models";

export const events = {
	getMany: (payload: IEventsGetPayload) =>
		axiosInstance().get(
			`/communities/${payload.communityId}/community_events`,
			{
				params: {
					start: payload.start,
					end: payload.end,
					group_id: payload.groupId,
				},
			},
		),
	getOne: (payload: { communityId: number; eventId: number }) =>
		axiosInstance().get(
			`/communities/${payload.communityId}/community_events/${payload.eventId}`,
		),
};
