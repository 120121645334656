import React, { memo, ReactElement } from "react";

import styles from "./styles.module.scss";
import { FooterLayout, OnboardingLayoutHeader } from "layouts/components";
import { isItAWhiteLabel } from "utils";

interface IOnboardingLayout {
	children: ReactElement;
	fullWidthContainerForChildren?: boolean;
	headerTitle?: string;
	headerSubtitle?: string;
}

const WhiteLabelInitOnboardingLayout: React.FunctionComponent<
	IOnboardingLayout
> = ({
	headerTitle = "Welcome",
	headerSubtitle = ``,
	children,
	fullWidthContainerForChildren = false,
}) => {
	return (
		<section
			className={styles.onboardingLayout}
			style={fullWidthContainerForChildren ? { alignItems: "unset" } : {}}
		>
			<OnboardingLayoutHeader />
			<div
				className={styles.container}
				style={
					fullWidthContainerForChildren ? { maxWidth: "unset" } : {}
				}
			>
				<section className={styles.header}>
					<h1 className={styles.title}>{headerTitle}</h1>

					<h3 className={styles.subtitle}>{headerSubtitle}</h3>
				</section>
				{children}
			</div>
			{!isItAWhiteLabel() && <FooterLayout />}
		</section>
	);
};

export default memo(WhiteLabelInitOnboardingLayout);
