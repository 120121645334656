import { IMemberState, EMemberActionTypes, TMemberActions } from "models";

const initial: IMemberState = {
	loading: false,
	member: {
		id: 0,
		about_me: "",
		location: "",
		subset_list: [],
		blocked: false,
		blocked_by: false,
		membership_id: 0,
		interest_list: [],
		skill_list: [],
		name: "",
		email: "",
		full_name: "",
		distance: "",
		picture: "",
		membership_type: "",
		posts: {
			pins: [],
			meta: {
				pagination: {
					current_page: 0,
					per_page: 10,
					total_pages: 0,
					total_objects: 0,
					has_next_page: false,
				},
			},
		},
		industry: "",
		profession: "",
	},
};

export function memberReducer(
	state = initial,
	action: TMemberActions,
): IMemberState {
	switch (action.type) {
		case EMemberActionTypes.memberLoadingSet: {
			return {
				...state,
				loading: action.payload,
			};
		}
		case EMemberActionTypes.memberSet: {
			if (action.payload.deleteTheMember) {
				return {
					...state,
					// @ts-ignore
					member: {
						id: 0,
						about_me: "",
						location: "",
						subset_list: [],
						blocked: false,
						blocked_by: false,
						membership_id: 0,
						name: "",
						picture: "",
						membership_type: "",
						skill_list: [],
						interest_list: [],
						posts: {
							pins: [],
							meta: {
								pagination: {
									current_page: 0,
									per_page: 10,
									total_pages: 0,
									total_objects: 0,
									has_next_page: false,
								},
							},
						},
					},
				};
			} else {
				const {
					about_me,
					location,
					subset_list,
					id,
					blocked,
					blocked_by,
					membership_id,
					name,
					picture,
					membership_type,
					interest_list,
					skill_list,
					industry,
					profession,
				} = action.payload.user;

				return {
					...state,
					// @ts-ignore
					member: {
						id,
						about_me,
						location,
						subset_list,
						posts: action.payload.posts,
						blocked,
						blocked_by,
						membership_id,
						name,
						picture,
						membership_type,
						interest_list,
						skill_list,
						industry,
						profession,
					},
				};
			}
		}
		case EMemberActionTypes.setMemberPosts: {
			return {
				...state,
				member: {
					...state.member,
					posts: {
						...state.member.posts,
						pins: state.member.posts.pins.concat(
							action.payload.posts.pins,
						),
						meta: {
							pagination: {
								...action.payload.posts.pagination,
							},
						},
					},
				},
			};
		}
		default:
			return state;
	}
}
