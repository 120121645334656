import React, { memo } from "react";

import { Form } from "antd";
// import '@ant-design/compatible/assets/index.css';

import { Row, Col, Input, Button } from "antd";
import styles from "./styles.module.scss";

const FormItem = Form.Item;

const SearchBarMultiple = ({ children }: any) => {
	return (
		<div className={styles.searchBarMultiple}>
			<Form>
				<Row gutter={8}>
					<Col span={6}>
						<FormItem>
							<Input placeholder="Keyword" />
						</FormItem>
					</Col>
					<Col span={5}>
						<FormItem>
							<Input placeholder="Location" />
						</FormItem>
					</Col>
					<Col span={5}>
						<FormItem>
							<Input placeholder="Profession" />
						</FormItem>
					</Col>
					<Col span={3}>
						<FormItem>
							<Input placeholder="Tag" />
						</FormItem>
					</Col>
					<Col span={5}>
						<Button htmlType="submit">Search</Button>
					</Col>
				</Row>
			</Form>
		</div>
	);
};

export default memo(SearchBarMultiple);
