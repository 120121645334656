import React, { memo, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import PaymentMethodCard from "./paymentMethodCard";
import commonStyles from "../styles.module.scss";
import localStyles from "./styles.module.scss";
import { IPaymentMethod, TStore } from "models";
import { ReactComponent as AddPlusIcon } from "assets/images/add-plus.svg";
import { Loading, StripeWrapper } from "components";
import StripeCardElement from "./stripeCardElement";
const styles: Record<string, any> = { ...commonStyles, ...localStyles };

const stateSelectorHandle = createSelector(
	(state: TStore) => state.subscription.paymentMethods,
	(state: TStore) =>
		state.subscription?.subscription?.stripe_linked_account_id,
	(state: TStore) => state.auth.credentials?.user?.current_subscription,
	(paymentMethods, stripeLinkedAccountId, currentSubscription) => ({
		paymentMethods,
		stripeLinkedAccountId,
		currentSubscription,
	}),
);

const PaymentMethods = () => {
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { paymentMethods, stripeLinkedAccountId, currentSubscription } =
		useSelector(stateSelector);
	const [showStripeCardElement, setShowStripeCardElement] = useState(false);

	const toggleShowAddANewPaymentForm = useCallback(() => {
		setShowStripeCardElement(!showStripeCardElement);
	}, [showStripeCardElement]);

	return (
		<div className={styles.mainContainer}>
			{!showStripeCardElement && (
				<div className={styles.flexRowSpaceBetween}>
					<div className={styles.headerTitle}>
						{"Payment methods"}
					</div>

					<div
						className={styles.divBtnAndText}
						onClick={toggleShowAddANewPaymentForm}
					>
						<AddPlusIcon width={26} height={26} />
						<div className={styles.addPayment} id="btn-text">{"Add Payment Method"}</div>
					</div>
				</div>
			)}
			{!showStripeCardElement ? (
				<div className={styles.paymentDetail}>
					{paymentMethods?.map((paymentMethod: IPaymentMethod) => (
						<div key={`payment-method${paymentMethod.data.id}`}>
							<PaymentMethodCard
								defaultPaymentMethodId={
									currentSubscription &&
									typeof currentSubscription === "object"
										? currentSubscription?.stripe_payment_method_id
										: null
								}
								paymentMethod={paymentMethod}
							/>
						</div>
					))}
				</div>
			) : stripeLinkedAccountId ? (
				<StripeWrapper stripeLinkedAccountId={stripeLinkedAccountId}>
					<StripeCardElement
						onCancelButton={toggleShowAddANewPaymentForm}
					/>
				</StripeWrapper>
			) : (
				<Loading />
			)}
		</div>
	);
};

export default memo(PaymentMethods);
