import React, { memo, useCallback, useState } from "react";
import { Select } from "antd";
import { useDispatch } from "react-redux";

import styles from "./styles.module.scss";
import {
	updateDocsOrderAction,
	updateResourcesOrderAction,
	updateVideosOrderAction,
} from "store/actions";
import { TStore } from "models";

const { Option } = Select;

interface IFilterHeaderButtons {
	type: keyof TStore;
}

const FilterHeaderButtons: React.FunctionComponent<IFilterHeaderButtons> = ({
	type,
}) => {
	const dispatch = useDispatch();
	const [tagsDropdownOpen, setTagsDropdownOpen] = useState<boolean>(false);

	const handleChange = useCallback(
		(value: string, option: any) => {
			switch (type) {
				case "videos":
					dispatch(updateVideosOrderAction(value.split("|")));
					break;
				case "resources":
					dispatch(updateResourcesOrderAction(value.split("|")));
					break;
				case "documents":
					dispatch(updateDocsOrderAction(value.split("|")));
					break;
			}
		},
		[dispatch, type],
	);

	return (
		<div className={styles.container}>
			<Select
				className={styles.select}
				defaultValue="created_at|desc"
				style={{
					width: 200,
				}}
				onChange={handleChange}
				open={tagsDropdownOpen}
				onDropdownVisibleChange={() =>
					setTagsDropdownOpen((prev) => !prev)
				}
				suffixIcon={
					<div
						style={{
							transform: !tagsDropdownOpen
								? "rotate(180deg)"
								: "rotate(360deg)",
							transition: "all 0.3s",
						}}
					>
						<svg
							width="14"
							height="8"
							viewBox="0 0 14 8"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M7.70601 0.395882C7.31552 0.0161814 6.68136 0.0161813 6.29086 0.395881L0.292871 6.22808C-0.0976237 6.60778 -0.0976238 7.22441 0.292871 7.60411C0.683365 7.98381 1.31753 7.98381 1.70802 7.60411L7 2.45841L12.292 7.60107C12.6825 7.98077 13.3166 7.98077 13.7071 7.60107C14.0976 7.22137 14.0976 6.60474 13.7071 6.22504L7.70914 0.392844L7.70601 0.395882Z"
								fill="#32363D"
							/>
						</svg>
					</div>
				}
				dropdownStyle={{
					boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.08)",
					borderRadius: 8,
					backgroundColor: "white",
				}}
			>
				<Option
					value="created_at|desc"
					style={{
						fontSize: 14,
						lineHeight: "20px",
						fontFamily: "Nunito-Regular",
						color: "#4D5A81",
						backgroundColor: "white",
					}}
				>
					Latest first
				</Option>
				<Option
					value="view_count|desc"
					style={{
						fontSize: 14,
						lineHeight: "20px",
						fontFamily: "Nunito-Regular",
						color: "#4D5A81",
						backgroundColor: "white",
					}}
				>
					Most viewed
				</Option>
				<Option
					value="created_at|asc"
					style={{
						fontSize: 14,
						lineHeight: "20px",
						fontFamily: "Nunito-Regular",
						color: "#4D5A81",
						backgroundColor: "white",
					}}
				>
					Oldest first
				</Option>
				<Option
					value="title|asc"
					style={{
						fontSize: 14,
						lineHeight: "20px",
						fontFamily: "Nunito-Regular",
						color: "#4D5A81",
						backgroundColor: "white",
					}}
				>
					Alphabetical
				</Option>
				<Option
					value="title|desc"
					style={{
						fontSize: 14,
						lineHeight: "20px",
						fontFamily: "Nunito-Regular",
						color: "#4D5A81",
						backgroundColor: "white",
					}}
				>
					Alphabetical Reverse
				</Option>
			</Select>
		</div>
	);
};

export default memo(FilterHeaderButtons);
