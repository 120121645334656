import { all, call, select, put, takeLatest } from "redux-saga/effects";

import { EPostActionTypes, TStore } from "models";
import {
	api,
	setPost,
	getPosts,
	getEditProfilePosts,
	deletePostComment,
} from "store";
import {
	deleteLocalPostComment,
	deletePostAction,
	editLocalPostComment,
	editPostComment,
	getEditPostAction,
	getPostRawData,
} from "store/actions";
import { rollbar } from "utils";

const getCurrentCommunityId = (state: TStore) => state.app.currentCommunityId;

function* getEditPostHandle({ payload }: ReturnType<typeof getEditPostAction>) {
	const { callbackOnError, callbackOnSuccess, id } = payload;

	try {
		const currentCommunityId = yield select(getCurrentCommunityId);
		if (!id || !currentCommunityId) {
			throw new Error("Missing parameters.");
		}
		const { data } = yield call(api.posts.getPostEdit, {
			community_id: currentCommunityId,
			community_post_id: id,
		});

		yield put(setPost(data));
		if (callbackOnSuccess) {
			callbackOnSuccess(data);
		}
	} catch (error) {
		rollbar.error(
			error
		)
		if (callbackOnError) {
			callbackOnError();
		}
		console.error({ error });
	}
}

function* postResetHandle() {
	try {
		yield put(setPost({}));
	} catch (error) {
		rollbar.error(
			error
		)
		console.error({ error });
	}
}

function* postDeleteHandle({ payload }: ReturnType<typeof deletePostAction>) {
	const { postId, location } = payload;
	try {
		const currentCommunityId = yield select(getCurrentCommunityId);
		if (!postId || !currentCommunityId) {
			throw new Error("Missing parameters!");
		}
		yield call(api.posts.deletePost, {
			community_id: currentCommunityId,
			communityPostId: postId,
		});
		if (location === "/dashboard")
			yield put(getPosts({ resetPosts: true }));
		else if (location === "/profile")
			yield put(getEditProfilePosts({ resetPosts: true }));
	} catch (error) {
		rollbar.error(
			error
		)
		console.error({ error });
	}
}

function* getRawPostDataHandle({ payload }: ReturnType<typeof getPostRawData>) {
	const { id, callbackOnError, callbackOnSuccess, forceCommunityId } =
		payload;
	try {
		const currentCommunityId: number = yield select(getCurrentCommunityId);
		const { data } = yield call(api.posts.getPost, {
			community_id: forceCommunityId || currentCommunityId,
			community_post_id: id,
		});

		yield put(setPost(data));

		if (callbackOnSuccess) {
			callbackOnSuccess();
		}
	} catch (error) {
		rollbar.error(
			error
		)
		if (callbackOnError) {
			callbackOnError();
		}
		console.error({ error });
	}
}

function* handleDeletePostComment({
	payload,
}: ReturnType<typeof deletePostComment>) {
	const { postId, commentId } = payload;
	try {
		const currentCommunityId = yield select(getCurrentCommunityId);
		const { data } = yield call(api.posts.deletePostComment, {
			postId,
			communityId: currentCommunityId,
			commentId,
		});
		if (!data) {
			throw Error(`The comment couldn't be deleted!`);
		} else {
			yield put(deleteLocalPostComment({ postId, commentId }));
		}
	} catch (error) {
		rollbar.error(
			error
		)
		console.error({ error });
	}
}

function* handleEditPostComment({
	payload,
}: ReturnType<typeof editPostComment>) {
	const { postId, commentId, content } = payload;
	try {
		if (content === "") {
			// if the content is "" delete the comment.
			return;
		}
		const currentCommunityId = yield select(getCurrentCommunityId);
		const { data } = yield call(api.posts.editPostComment, {
			postId,
			communityId: currentCommunityId,
			commentId,
			content,
		});

		if (!data) {
			throw Error(`The comment couldn't be updated!`);
		} else {
			yield put(editLocalPostComment({ postId, commentId, content }));
		}
	} catch (error) {
		rollbar.error(
			error
		)
		console.error({ error });
	}
}

export default function* rootSaga() {
	yield all([
		takeLatest(EPostActionTypes.postEditGet, getEditPostHandle),
		takeLatest(EPostActionTypes.postRawDataGet, getRawPostDataHandle),
		takeLatest(EPostActionTypes.postReset, postResetHandle),
		takeLatest(EPostActionTypes.postDelete, postDeleteHandle),
		takeLatest(EPostActionTypes.deletePostComment, handleDeletePostComment),
		takeLatest(EPostActionTypes.editPostComment, handleEditPostComment),
	]);
}
