import React, { memo, useCallback, useRef, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { Loading } from "components";
import InfiniteScroll from "react-infinite-scroller";
import Scrollbar from "react-scrollbars-custom";
import { createSelector } from "reselect";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { IMessageThread, IMessageThreadUser, TStore } from "models";
import { getConversations, setSelectedThread, updateConversation } from "store";
import { PreviewMessageCard } from "..";
import { ReactComponent as MessagesIcon } from "assets/images/messages.svg";
import styles from "./styles.module.scss";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.app.currentCommunityId,
	(state: TStore) => state.messages.threads,
	(state: TStore) => state.messages.orderedThreads,
	(state: TStore) => state.messages.pinnedConversation,
	(state: TStore) => state.messages.selectedThread,
	(state: TStore) => state.messages.loading,
	(
		communityId: number | null,
		threads: any,
		orderedThreads: { [key: number]: number[] },
		pinnedConversation: IMessageThread | null,
		selectedThread: IMessageThread | null,
		loading: boolean,
	) => {
		return {
			communityId,
			threadsData:
				threads === null
					? null
					: //@ts-ignore
					communityId && threads[communityId]
					? //@ts-ignore
					  threads[communityId]
					: {},
			orderedThreads:
				communityId && orderedThreads[communityId]
					? orderedThreads[communityId]
					: [],
			pinnedConversation,
			selectedThread,
			loading,
		};
	},
);

const Threads = () => {
	const dispatch = useDispatch();
	const threadsContainerRef = useRef<any>(null);

	const stateSelector = useCallback(stateSelectorHandle, []);
	const {
		threadsData,
		orderedThreads,
		pinnedConversation,
		selectedThread,
		loading,
	} = useSelector(stateSelector);

	const { posts: threads, meta: { pagination } = { pagination: {} } }: any =
		threadsData || {};
	const [activeMultiThread, setActiveMultiThread] =
		useState<IMessageThread | null>(null);

	const closeMultiThread = useCallback(() => {
		setActiveMultiThread(null);
		dispatch(setSelectedThread(null));
		dispatch(updateConversation({ data: null, meta: null })); // reset the conversation data
	}, [dispatch]);

	const loadMore = useCallback(() => {
		if (!loading) {
			dispatch(getConversations());
		}
	}, [loading, dispatch]);

	return (
		<section className={styles.threadsSection} ref={threadsContainerRef}>
			{!activeMultiThread && (
				<div className={styles.flexBoxSpaceBetween}>
					<h3 className={styles.ThreadsText}>{"Threads"}</h3>
					<Link className={styles.messageIcon} to="/members">
						<MessagesIcon />
					</Link>
				</div>
			)}
			{(!!orderedThreads.length || pinnedConversation) && (
				<Scrollbar
					className={styles.scrollBar}
					scrollerProps={{
						renderer: (props) => {
							const { elementRef, ...restProps } = props;
							return (
								<div
									id={"threadsContainerId"}
									{...restProps}
									ref={elementRef}
								></div>
							);
						},
					}}
				>
					<InfiniteScroll
						pageStart={1}
						loadMore={loadMore}
						initialLoad={false}
						hasMore={
							!activeMultiThread
								? pagination.has_next_page
								: false
						}
						loader={
							<div key={0}>
								<Loading />
							</div>
						}
						useWindow={false}
						getScrollParent={() =>
							document.getElementById("threadsContainerId")
						}
						style={{
							display: "flex",
							flex: 1,
							flexDirection: "column",
						}}
					>
						{!orderedThreads.length && !pinnedConversation && (
							<div className={styles.noActiveChats}>
								{"No active chats"}
							</div>
						)}
						{activeMultiThread && (
							<div
								style={{
									flexGrow: 1,
									paddingTop: 16,
									display: "flex",
									flexDirection: "column",
								}}
							>
								<div
									style={{
										marginTop: -32,
									}}
								>
									<Tooltip title="Back to all messages">
										<Button
											icon={<ArrowLeftOutlined />}
											style={{
												borderRadius: 15,
											}}
											onClick={closeMultiThread}
										>
											{"Back"}
										</Button>
									</Tooltip>
								</div>
								<div
									style={{
										flexGrow: 1,
									}}
								>
									{activeMultiThread.users
										? activeMultiThread.users.map(
												(
													threadUser: IMessageThreadUser,
													index: number,
												) => {
													return (
														<PreviewMessageCard
															key={`message_card_${activeMultiThread.id}_${threadUser.id}`}
															thread={{
																...activeMultiThread,
																user: threadUser,
															}}
															isSelected={
																selectedThread
																	? (selectedThread.user &&
																			selectedThread.id ===
																				activeMultiThread.id &&
																			selectedThread
																				.user
																				.id ===
																				threadUser.id) ||
																	  false
																	: false
															}
														/>
													);
												},
										  )
										: null}
								</div>
							</div>
						)}
						{!activeMultiThread && pinnedConversation && (
							<PreviewMessageCard
								key={`message_card_new_chat`}
								thread={pinnedConversation}
								isSelected={
									(pinnedConversation && !selectedThread) ||
									(selectedThread
										? selectedThread.id ===
										  pinnedConversation.id
										: false)
								}
							/>
						)}
						{!activeMultiThread &&
							!!orderedThreads.length &&
							orderedThreads
								.filter(
									(threadId: number) =>
										threadId !== pinnedConversation?.id,
								)
								.map((threadId: number, index: number) => {
									return (
										<PreviewMessageCard
											key={`message_card_${threadId}-${index}`}
											thread={threads[threadId]}
											openMultiThread={
												setActiveMultiThread
											}
											isSelected={
												selectedThread
													? selectedThread.id ===
													  threadId
													: false
											}
										/>
									);
								})}
					</InfiniteScroll>
				</Scrollbar>
			)}
		</section>
	);
};

export default memo(Threads);
