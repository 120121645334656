import React from "react";
import { Form, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";

import styles from "./styles.module.scss";

const { Dragger } = Upload;

const UploadFile: React.FunctionComponent<any> = () => {
	const normFile = (e: any) => {
		if (Array.isArray(e)) {
			return e;
		}
		return e && e.fileList;
	};

	const dummyRequest = ({ file, onSuccess }: any) => {
		// Now our file’s data can be passed to the Form without throwing an action error.
	};

	return (
		<div className={styles.uploadInput}>
			<Form.Item
				name={"document"}
				rules={[
					{
						required: true,
						message: "'Document' is required",
					},
				]}
				valuePropName="fileList"
				getValueFromEvent={normFile}
			>
				<Dragger
					listType="picture"
					showUploadList={true}
					accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .zip, application/zip, application/x-zip-compressed,.mp3,audio/*"
					customRequest={dummyRequest}
					multiple={false}
					beforeUpload={(file: any) => {
						// Upload files manually after beforeUpload returns false.
						return false;
					}}
				>
					<p className="ant-upload-drag-icon">
						<InboxOutlined />
					</p>
					<p className="ant-upload-text">
						{"Click or drag file to this area to upload"}
					</p>
					<p className="ant-upload-hint">
						{"Support for a single upload."}
						<br />
						{"Supported document types: .doc, .docx and .pdf"}
						<br />
						{"Supported image types: .jpg, .jpeg and .png,"}
					</p>
				</Dragger>
			</Form.Item>
		</div>
	);
};

export default UploadFile;
