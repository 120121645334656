import React, { memo, useEffect, useRef } from "react";
import { Card, Progress } from "antd";
import { useDispatch } from "react-redux";

import {
	getResourceForProgressbar,
	getResources,
	notify,
	setRerourceCreatedIntervalAction,
	setResourceIdAction,
	setResourceSuccessfullyUploadedAction,
	setResourceUploadProgressAction,
} from "store";

export const UploadResourceProgress = memo(
	({
		uploadProgress,
		resourceId,
		resourceSuccessfullyUploaded,
		createdInterval,
	}: {
		uploadProgress: number | undefined;
		resourceId: number | undefined;
		resourceSuccessfullyUploaded: boolean | undefined;
		createdInterval: number | undefined;
	}) => {
		const dispatch = useDispatch();
		const ref = useRef<any>(createdInterval);

		useEffect(() => {
			if (resourceId && !createdInterval && uploadProgress === 100) {
				ref.current = setInterval(() => {
					dispatch(getResourceForProgressbar({ id: resourceId }));
				}, 5000);
				dispatch(setRerourceCreatedIntervalAction(ref.current));
			}
		}, [dispatch, createdInterval, resourceId, uploadProgress]);

		useEffect(() => {
			if (resourceSuccessfullyUploaded) {
				dispatch(setResourceUploadProgressAction(undefined));
				dispatch(setResourceIdAction(undefined));
				dispatch(setResourceSuccessfullyUploadedAction(undefined));
				dispatch(setRerourceCreatedIntervalAction(undefined));
				dispatch(getResources({ reset: true }));
				notify("success", "File upload successful");
				clearInterval(ref.current);
			}
		}, [dispatch, resourceSuccessfullyUploaded]);

		return (
			<>
				{uploadProgress !== undefined ? (
					<Card style={{ marginBottom: 30, borderRadius: 10 }}>
						{uploadProgress !== 100 ? (
							<p style={{ marginBottom: 5, fontSize: 15 }}>
								Uploading... {uploadProgress}%
							</p>
						) : (
							<p style={{ marginBottom: 5, fontSize: 15 }}>
								Processing...
							</p>
						)}
						<Progress
							showInfo={false}
							status={
								uploadProgress === 100 ? "active" : "normal"
							}
							percent={uploadProgress}
						/>
					</Card>
				) : null}
			</>
		);
	},
);
