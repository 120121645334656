import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { useNavigate } from "react-router-dom";

import { ECommunityType, TStore } from "models";
import { CommunityMemberPlans, Loading } from "components";
import {
	getCommunityMemberPlans,
	notify,
	setSelectedCommunityToJoinMemberPlan,
	setSelectedCommunityToJoinMemberType,
} from "store";
import { OnboardingLayout } from "layouts";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.communities.selectedCommunityToJoinInfo,
	(state: TStore) => state.communities?.meta?.invites,
	(selectedCommunityToJoinInfo, invites) => ({
		selectedCommunityToJoinInfo,
		invites,
	}),
);

const JoinCommunityMemberPlans = () => {
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { selectedCommunityToJoinInfo } = useSelector(stateSelector);
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const [hasTheCommunityAnInvitation, setHasTheCommunityAnInvitation] =
		useState<boolean | null>(null);
	const memberPlansHasBeenFetch = useRef<boolean | null>(null);
	const navigate = useNavigate();

	const handleTheCommunityInvitation = useCallback(() => {
		if (
			!selectedCommunityToJoinInfo.invite_member_type ||
			selectedCommunityToJoinInfo.memberPlans == null
		)
			return;
		// // Note that the member plan name is unique, so, it can be safely used to select the member plan based on the 'invite_member_type' value
		const selectedMemberPlan = selectedCommunityToJoinInfo.memberPlans.find(
			(item) =>
				item.display_name ===
				selectedCommunityToJoinInfo.invite_member_type,
		);
		if (!selectedMemberPlan) {
			setHasTheCommunityAnInvitation(false);
		} else {
			dispatch(
				setSelectedCommunityToJoinMemberPlan({
					plan: selectedMemberPlan ?? null,
				}),
			);
			dispatch(
				setSelectedCommunityToJoinMemberType({
					memberType: selectedMemberPlan.display_name,
				}),
			);
			setHasTheCommunityAnInvitation(true);
		}
	}, [
		dispatch,
		selectedCommunityToJoinInfo?.memberPlans,
		selectedCommunityToJoinInfo?.invite_member_type,
	]);

	useEffect(() => {
		if (
			!selectedCommunityToJoinInfo?.id ||
			memberPlansHasBeenFetch.current !== null
		)
			return;

		memberPlansHasBeenFetch.current = true;
		dispatch(
			getCommunityMemberPlans({
				communityId: selectedCommunityToJoinInfo.id,
				successCallback: () => {
					setLoading(false);
				},
				errorCallback: () => {
					navigate(-1);
					notify(
						"error",
						"An error occurred. Please try again later.",
					);
					setLoading(false);
				},
				typeOfCommunity: ECommunityType.join,
				invited: selectedCommunityToJoinInfo.invite_member_type
					? true
					: false,
			}),
		);
	}, [
		dispatch,
		selectedCommunityToJoinInfo?.id,
		selectedCommunityToJoinInfo?.invite_member_type,
		navigate,
	]);

	useEffect(() => {
		if (
			selectedCommunityToJoinInfo?.id == null ||
			hasTheCommunityAnInvitation !== null
		)
			return;

		if (!selectedCommunityToJoinInfo.invite_member_type) {
			setHasTheCommunityAnInvitation(false);
		} else {
			handleTheCommunityInvitation();
		}
	}, [
		handleTheCommunityInvitation,
		hasTheCommunityAnInvitation,
		selectedCommunityToJoinInfo?.invite_member_type,
		selectedCommunityToJoinInfo?.id,
	]);

	return (
		<OnboardingLayout
			fullWidthContainerForChildren={true}
			headerTitle={"Please choose your member plan"}
			headerSubtitle={
				"Below are the different plans available for this community. All prices are set by the community."
			}
			footerLayout={false}
		>
			{loading || hasTheCommunityAnInvitation === null ? (
				<Loading />
			) : (
				<CommunityMemberPlans
					memberPlans={selectedCommunityToJoinInfo.memberPlans}
					typeOfCommunity={ECommunityType.join}
				/>
			)}
		</OnboardingLayout>
	);
};

export default memo(JoinCommunityMemberPlans);
