import { TStore } from "models";
import React, { memo, useCallback } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.community.data?.name,
	(currentCommunityName) => ({
		currentCommunityName,
	}),
);

interface IHelmetPageTitle {
	screenTitle: string;
}

const HelmetPageTitle = ({ screenTitle }: IHelmetPageTitle) => {
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { currentCommunityName } = useSelector(stateSelector);

	return (
		<Helmet>
			<meta charSet="utf-8" />
			<title>{`UHubs Members Portal | ${
				screenTitle.charAt(0).toUpperCase() + screenTitle.slice(1)
			} | ${currentCommunityName}`}</title>
		</Helmet>
	);
};

export default memo(HelmetPageTitle);
