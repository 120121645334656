import DOMPurify from "dompurify";

import { mentionRegex } from "utils";

export const convertMentionIntoHref = (description: string): string => {
	let source = DOMPurify.sanitize(description, {
		ALLOWED_TAGS: ["a"],
		ALLOWED_ATTR: ["href"],
	});
	const instances = source.match(mentionRegex);
	instances &&
		instances.map((instance: string) => {
			const parts = instance.split(" [uid: ");

			source = source.replace(
				instance,
				`<a membermentionid="${parts[1].replace("]", "")}">${
					parts[0]
				}</a>`,
			);
			return undefined;
		});

	return source;
};
