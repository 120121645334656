import {
	EFirebaseActionTypes,
	ILocalFirebasePostDocument,
	ILocalFirebasePostComment,
	EPostActionTypes,
} from "models";

export const fetchFirebaseDocument = (payload: number) => ({
	type: EFirebaseActionTypes.fetchPostDocument,
	payload,
});

export const updateLocalFirebasePostDocument = (
	postId: number,
	data: ILocalFirebasePostDocument,
) => ({
	type: EFirebaseActionTypes.updateLocalPostDocument,
	payload: {
		postId,
		data,
	},
});

export const fetchFirebasePostComments = (payload: number) => ({
	type: EFirebaseActionTypes.fetchFirebasePostComments,
	payload,
});

export const fetchPostComments = (payload: number) => ({
	type: EFirebaseActionTypes.fetchPostComments,
	payload,
});

export const addLocalPostComments = (postId: number, data: any) => ({
	type: EPostActionTypes.addLocalPostComments,
	payload: {
		postId,
		data,
	},
});

export const updateLocalPostComments = (postId: number, data: {}) => ({
	type: EPostActionTypes.updateLocalPostComments,
	payload: {
		postId,
		data,
	},
});

export const appendLocalPostComments = (postId: number, data: {}) => ({
	type: EPostActionTypes.appendLocalPostComments,
	payload: {
		postId,
		data,
	},
});

export const addLocalFirebasePostComments = (
	postId: number,
	data: ILocalFirebasePostComment[],
) => ({
	type: EFirebaseActionTypes.addFirebaseLocalPostComments,
	payload: {
		postId,
		data,
	},
});

export const updateLocalFirebasePostComments = (
	postId: number,
	data: ILocalFirebasePostComment[],
) => ({
	type: EFirebaseActionTypes.updateFirebaseLocalPostComments,
	payload: {
		postId,
		data,
	},
});

export const sendFirebaseCommentForPost = (payload: {
	postId: number;
	message: string;
}) => ({
	type: EFirebaseActionTypes.sendFirebaseCommentForPost,
	payload,
});

export const addLocalFirebasePostComment = (payload: {
	postId: number;
	comment: any;
}) => ({
	type: EFirebaseActionTypes.addFirebaseLocalCommentForPost,
	payload,
});

export const fetchMoreFirebasePostComments = (payload: {
	postId: number;
	commentDoc: any;
}) => ({
	type: EFirebaseActionTypes.fetchFirebaseMorePostComments,
	payload,
});

export const fetchMorePostComments = (payload: { postId: number }) => ({
	type: EFirebaseActionTypes.fetchMorePostComments,
	payload,
});
