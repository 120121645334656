import {
	put,
	all,
	takeEvery,
	takeLatest,
	select,
	call,
} from "redux-saga/effects";

import { EDocActionTypes, TStore } from "models";
import { setDocLoading, setDoc, uploadDoc, api, getDoc, notify } from "store";
import { rollbar } from "utils";

const getCurrentCommunityId = (state: TStore) => state.app.currentCommunityId;
const selectDocsCurrentFolderId = (state: TStore) =>
	state.documents.currentFolder;
const selectResourcesCurrentFolderId = (state: TStore) =>
	state.resources.currentFolder;

function* getDocHandle({ payload }: ReturnType<typeof getDoc>) {
	const {
		callbackOnError,
		callbackOnSuccess,
		id,
		forceCommunityId,
		folderId,
	} = payload;
	try {
		const currentCommunityId: number = yield select(getCurrentCommunityId);
		const { data } = yield call(api.docs.documentsOne, {
			communityId: forceCommunityId
				? forceCommunityId
				: currentCommunityId,
			id,
			folderId,
		});

		if (data) {
			yield put(setDoc({ data: data.documents[0] }));
		}
		if (callbackOnSuccess) {
			callbackOnSuccess();
		}
	} catch (error) {
		rollbar.error(error)
		console.error({ error });
		if (callbackOnError) {
			callbackOnError();
		}
	}
}

function* uploadDocHandle({ payload }: ReturnType<typeof uploadDoc>) {
	const {
		document,
		title,
		tagsGroup,
		audienceGroup,
		groupsGroup,
		longDescription,
		expirationDate,
		onSuccess,
		onError,
		all_groups,
		is_private,
		parent,
	} = payload;
	try {
		yield put(setDocLoading(true));
		const currentCommunityId = yield select(getCurrentCommunityId);
		const docsCurrentFolderId = yield select(selectDocsCurrentFolderId);
		const resourcesCrrentFolderId = yield select(
			selectResourcesCurrentFolderId,
		);

		if (parent === "Docs") {
			const response = yield call(api.docs.uploadDocument, {
				communityId: currentCommunityId,
				title,
				attachment: document,
				group_ids: groupsGroup || [],
				member_type_list: audienceGroup || [],
				is_private,
				all_groups,
				folder_id: docsCurrentFolderId,
				description: longDescription,
				expiry_date: expirationDate,
				tag_list: tagsGroup,
			});

			if (response.data.meta.needs_approval) {
				notify('success', response.data.meta.message)
				onSuccess(true);

			}
			else {
				if (onSuccess) {
					onSuccess();
				}
			}
		}


		if (parent === "Resources") {
			const response = yield call(api.resources.uploadDocumentForResources, {
				communityId: currentCommunityId,
				title,
				attachment: document,
				group_ids: groupsGroup || [],
				member_type_list: audienceGroup || [],
				is_private,
				all_groups,
				folder_id: resourcesCrrentFolderId,
				description: longDescription,
				expiry_date: expirationDate,
				tag_list: tagsGroup,
			});
			if (response.data.meta.needs_approval) {
				notify('success', response.data.meta.message)
				onSuccess(true);

			}
			else {
				if (onSuccess) {
					notify('success', 'Document upload successful')

					onSuccess();
				}
			}
		}



		yield put(setDocLoading(false));
	} catch (error) {
		rollbar.error(error)
		if (onError) {
			onError();
		}
		yield put(setDocLoading(false));
	}
}

export default function* rootSaga() {
	yield all([takeEvery(EDocActionTypes.getDoc, getDocHandle)]);
	yield all([takeLatest(EDocActionTypes.uploadDoc, uploadDocHandle)]);
}
