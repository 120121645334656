import { useMemo } from "react";
import linkifyHtml from "linkifyjs/html";

import { convertMentionIntoHref } from "./convertMentionIntoHref";
import { UseReactHtmlReplace } from "./useReactHtmlReplace";
import { convertAnnouncementLinkIntoHref } from "./convertAnnouncementLinkIntoHref";
import { ILinkedCommunityDocuments } from "models";

interface IUseShowDescriptionWithMentions {
	text: string;
	mentions?: string[];
	linked_community_documents?: ILinkedCommunityDocuments[];
	words?: number;
	maxHeight?:string
}

export const UseShowDescriptionWithMentions = (
	props: IUseShowDescriptionWithMentions,
) => {
	const {
		text,
		mentions = [],
		linked_community_documents = [],
		words = 60,
		maxHeight
	} = props;

	const clean: string = useMemo(() => {
		if (text) {
			let convertedText = text;
			if (mentions.length > 0) {
				// convert the text only if the current post has at least one mention
				convertedText = convertMentionIntoHref(text);
			}
			if (linked_community_documents.length > 0) {
				// convert the text only if the current post is an announcement
				convertedText = convertAnnouncementLinkIntoHref(convertedText);
			}
			return linkifyHtml(convertedText);
		} else {
			return "";
		}
	}, [linked_community_documents.length, mentions.length, text]);

	return !text ? null : (
		<UseReactHtmlReplace
			text={clean}
			words={words}
			linked_community_documents={linked_community_documents}
			maxHeight={maxHeight}
		/>
	);
};
