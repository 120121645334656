import {
	ECommunityActionTypes,
	ECommunityType,
	ICommunityMemberPlan,
} from "models";

export const setCommunityLoading = (payload: boolean) => ({
	type: ECommunityActionTypes.communityLoadingSet,
	payload,
});

export const getCommunity = (payload: {
	communityId: number;
	memberDirectoryOptIn?: boolean;
}) => ({
	type: ECommunityActionTypes.getCommunity,
	payload,
});

export const setCommunity = (payload: any) => ({
	type: ECommunityActionTypes.setCommunity,
	payload,
});

export const setCommunityTags = (payload: any) => ({
	type: ECommunityActionTypes.setCommunityTags,
	payload,
});

export const getCommunityBySlug = (payload: string) => ({
	type: ECommunityActionTypes.getBySlug,
	payload,
});

export const setCommunityMemberPlansAction = (payload: {
	plans: ICommunityMemberPlan[];
}) => ({
	type: ECommunityActionTypes.setMemberPlans,
	payload,
});

export const getCommunityMemberPlans = (payload: {
	successCallback: () => void;
	errorCallback: () => void;
	communityId: number | number;
	typeOfCommunity: ECommunityType;
	invited?: boolean;
}) => ({
	type: ECommunityActionTypes.getMembersPlans,
	payload,
});
