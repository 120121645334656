import { ESubscriptionStatus } from "models";
import {
	IDeletePaymentMethodAction,
	ISetSubscriptionAction,
	ISetSubscriptionManagementAction,
	ISetSubscriptionManagementLoadingAction,
	ISetSubscriptionPaymentMethodsAction,
	IInititateTheResubscribeProcessAction,
	ISetChangeSubscriptionStageAction,
	ISetChangeSubscriptionSelectedMemberPlanAction,
	ISetChangeSubscriptionCurrencyAction,
} from ".";

export enum ESubscriptionActionTypes {
	GET_SUBSCRIPTION_MANAGEMENT = "SUBSCRIPTION/GET/SUBSCRIPTION/MANAGEMENT",
	COMPLETE_GET_SUBSCRIPTION_MANAGEMENT = "SUBSCRIPTION/COMPLETE/GET/SUBSCRIPTION/MANAGEMENT",
	SET_SUBSCRIPTION_MANAGEMENT = "SUBSCRIPTION/SET/SUBSCRIPTION/MANAGEMENT",
	SET_SUBSCRIPTION_MANAGEMENT_LOADING = "SUBSCRIPTION/SET/SUBSCRIPTION/MANAGEMENT/LOADING",
	DELETE_PAYMENT_METHOD_SAGA = "SUBSCRIPTION/DELETE/PAYMENT/METHOD/SAGA",
	DELETE_PAYMENT_METHOD = "SUBSCRIPTION/DELETE/PAYMENT/METHOD",
	CANCEL_SUBSCRIPTION = "SUBSCRIPTION/CANCEL/SUBSCRIPTION",
	SET_SUBSCRIPTION = "SUBSCRIPTION/SET/SUBSCRIPTION",
	ADD_NEW_PAYMENT_METHOD = "SUBSCRIPTION/ADD/NEW/PAYMENT/METHOD",
	SET_PAYMENT_METHODS = "SUBSCRIPTION/SET/PAYMENT/METHODS",
	INITIATE_THE_RESUBSCRIBE_PROCESS = "SUBSCRIPTION/INITIATE/THE/RESUBSCRIBE/PROCESS",
	RESUBSCRIBE = "SUBSCRIPTION/RESUBSCRIBE",
	CHANGE_SUBSCRIPTION_STAGE = "SUBSCRIPTION/CHANGE/SUBSCRIPTION/STAGE",
	SET_CHANGE_SUBSCRIPTION_SELECTED_MEMBER_PLAN = "SUBSCRIPTION/SET/CHANGE/SUBSCRIPTION/SELECTED/MEMBER/PLAN",
	SET_DEFAULT_PAYMENT_METHOD = "SUBSCRIPTION/SET/DEFAULT/PAYMENT/METHOD",
	SET_DEFAULT_PAYMENT_METHOD_COMPLETED = "SUBSCRIPTION/SET/DEFAULT/PAYMENT/METHOD/COMPLETED",
	SET_CHANGE_SUBSCRIPTION_CURRENCY = "SUBSCRIPTION/SET/CHANGE/SUBSCRIPTION/CURRENCY",
}

export interface IMemberPlanPriceOptions {
	amount: string;
	currency: string; // iso downcase
	price_tag: string;
	stripe_id: null | string; // used to be stripe_plan_price_id
	synced: boolean; // when false, the price option shouldn't be shown.
}
export interface ICommunityMemberPlan {
	id: number;
	/**
	 * @deprecated this key will pe removed in the future, use the 'price_options' key instead
	 */
	// stripe_plan_price_id: string;
	trial_period: { interval: "DAY" | string; interval_count: number };
	display_name: string;
	description: string;
	/**
	 * @deprecated this key will pe removed in the future, use the 'free' key instead
	 */
	// price_tag: string;
	benefits: string[];
	cover: {
		image: string;
		thumb: string;
		desktop: Record<string, string> | {};
		mobile: string;
	} | null;
	period: {
		interval: string;
		interval_count: number;
	};
	free?: boolean; //if true the price tag should be free
	price_options?: IMemberPlanPriceOptions[];
	available: boolean; // if true, the plan is available, if false – discontinued
	price?: null | IMemberPlanPriceOptions; // if the price is null, then show message depending on the 'available` flag of the plan: if true – Price not available –> show plan option, if false – Plan discontinued -> show plan options
	stripe_id: string;
}

export interface ICard {
	brand: string;
	checks: {
		cvc_check: string;
	};
	exp_month: number;
	exp_year: number;
	fingerprint: string;
	last4: string;
}

export interface IPaymentMethod {
	casted_data: any;
	data: {
		card: ICard;
		id: string;
		type: string;
	};
	errors: any;
}

export interface IPaymentInvoice {
	amount: number;
	billing_reason: string;
	created_at: string;
	currency: string;
	currency_symbol: string;
	download_url: string;
	hosted_url: string;
	id: number;
	number: string;
	paid_at: string;
	period_end: string;
	period_start: string;
	status: string;
	stripe_customer_id: string;
	stripe_id: string;
	stripe_subscription_id: string;
	updated_at: string;
	stripe_plan_price_id: string;
	plan_name: string | "nil"; // If updates, disconnect or delete cause the stripe_plan_price_id to be changed/nullified, the plan_name will be returned as nil
	receipt_available: boolean | null;
}

export enum EChangeSubscriptionStage {
	memberPlans = "member plans",
	checkout = "checkout",
}

export enum ESubscriptionPaymentService {
	stripe = "stripe",
	apple_pay = "apple_pay",
	google_pay = "google_pay",
}

export interface ISubscription {
	cancel_url: string;
	cancelation_requested: boolean;
	currency: string;
	expiry_date: string;
	invoice: string;
	plan: ICommunityMemberPlan;
	status: string;
	stripe_id: string;
	stripe_plan_price_id: string;
	stripe_linked_account_id: string;
	service: ESubscriptionPaymentService;
}

export interface ICurrentSubscription extends ISubscription {
	expiry: string;
	id: number;
	trial_end: string;
	stripe_payment_method_id: string | null;
}

export interface IMemberSubscriptionResponce {
	success: boolean;
	payload: {
		client_secret: string | null;
		pending_url: string | null;
		payment_methods: IPaymentMethod[];
		subscription_id: number | null;
		stripe_linked_account_id?: string | null;
		subscription_status?: ESubscriptionStatus | null;
	};
}

export interface IResubscribe {
	initiateTheProcess: boolean /** use this state to prevent the user to render the resubscribe page using the URL bar. */;
}
export interface IChangeSubscription {
	initiateTheProcess: boolean /** use this state to prevent the user to render the change subscription page using the URL bar. */;
}

export interface ISubscriptionState {
	loading: boolean;
	currentMemberPlan: ICommunityMemberPlan | undefined;
	paymentMethods: IPaymentMethod[] | undefined;
	invoices: IPaymentInvoice[] | undefined;
	subscription: ISubscription | undefined;
	changeSubscriptionSelectedCurrency?: string;
	resubscribe?: IResubscribe;
	changeSubscriptionStage?: EChangeSubscriptionStage; //** It is ok that changeSubscriptionStage and changeSubscriptionSelectedMemberPlan fields to be outside subscription field because the subscription can be undefined whereas those fields can hold some values. */
	changeSubscriptionSelectedMemberPlan?: ICommunityMemberPlan;
}

export type TSubscriptionActions =
	| ISetSubscriptionManagementAction
	| ISetSubscriptionManagementLoadingAction
	| IDeletePaymentMethodAction
	| ISetSubscriptionAction
	| ISetSubscriptionPaymentMethodsAction
	| IInititateTheResubscribeProcessAction
	| ISetChangeSubscriptionStageAction
	| ISetChangeSubscriptionSelectedMemberPlanAction
	| ISetChangeSubscriptionCurrencyAction;
