import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";
import { rollbar } from "utils";

interface IPreviewLinkCard {
	originalUrl: string;
	url?: string;
	image?: string;
	title?: string;
	description?: string;
}

const PreviewLinkCard: React.FunctionComponent<IPreviewLinkCard> = ({
	url,
	image,
	title,
	description,
	originalUrl,
}) => {
	const [imgFailedToLoad, setImgFailedToLoad] = useState(false);
	const [showTitle, setShowTitle] = useState<boolean>(true);
	function validateURL(link: string) {
		const linkWithoutDotAtTheEnd = link.replace(/\.+$/, "");
		if (link.indexOf("http://") === -1 && link.indexOf("https://") === -1) {
			return `https://${linkWithoutDotAtTheEnd}`;
		} else {
			return linkWithoutDotAtTheEnd;
		}
	}

	const onFailedToLoadImg = () => {
		setImgFailedToLoad(true);
	};

	const originalUrlHostName = useMemo(() => {
		try{// run against regex
		const matches = originalUrl.match(
			/^(?:https?:\/\/)?(?:w{3}\.)?.*?([^.\r\n\]+.)([^.\r\n/]+\.[^.\r\n/]{2,6}(?:\.[^.\r\n/]{2,6})?).*$/i,
		);
		// extract hostname (will be null if no match is found)
		return matches?.length ? matches[1] : null;}
		catch(error){
			rollbar.error(error)
		}
	}, [originalUrl]);

	useEffect(() => {
		// TODO: Temporary solution. This needs to be fixed on the AWS function.
		if (!title) return;
		if (title.toLowerCase().trim() === "access denied") {
			setShowTitle(false);
			setImgFailedToLoad(true);
		}
	}, [title]);

	return (
		<div
			style={url || title || description ? {} : { minHeight: "unset" }}
			className={styles.container}
		>
			<a
				className={styles.anchor}
				href={validateURL(originalUrl)}
				target="_blank"
				rel="noreferrer"
			>
				{image ? (
					!imgFailedToLoad ? (
						<img
							src={image}
							className={styles.imageContainer}
							alt={"preview-link"}
							onError={() => {
								onFailedToLoadImg();
							}}
						/>
					) : null
				) : null}
				{originalUrlHostName || title || description ? (
					<div className={classNames(styles.textContainer)}>
						{originalUrlHostName ? (
							<div
								className={classNames(styles.text, styles.url)}
							>
								{originalUrlHostName}
							</div>
						) : null}
						{showTitle && title ? (
							<div className={styles.text}>{title}</div>
						) : null}
						{description ? (
							<div
								className={classNames(
									styles.text,
									styles.twoLinesEllipsis,
								)}
								style={{ fontSize: 14, color: "#555555" }}
							>
								{description}
							</div>
						) : null}
					</div>
				) : (
					<div
						className={classNames(
							styles.textContainer,
							styles.text,
							styles.url,
						)}
					>
						{originalUrlHostName ? (
							<div className={styles.fadeText}>
								{originalUrlHostName}
							</div>
						) : null}
						<div className={styles.oneLineEllipsis}>
							{`${originalUrl}`}
						</div>
					</div>
				)}
			</a>
		</div>
	);
};

export default PreviewLinkCard;
