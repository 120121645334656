import React, { memo, Children, useCallback, useState } from "react";
import { Popover } from "antd";
import styles from "./styles.module.scss";
import { useDispatch } from "react-redux";
import { getEvent } from "store";
import { useNavigate } from "react-router-dom";

const dateFormater = (date: Date) => {
  const newDate = new Date(date)


  const formattedDate = newDate.toLocaleString("en-US", {
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  }).replace(",", "");
  return formattedDate;
};

const Event = ({ children, ...rest }) => {

  return React.cloneElement(Children.only(children), {
    className: styles.eventWrapper,
    style: {
      ...children.style,
    },
    ...rest,
  });
};

const PopOverComponent = (props: any) => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [showAdditionalTags, setShowAdditionalTags] = useState(false);

  const handleClickReadMore = () => {

    setShowAdditionalTags(true);
  };
  const handleGoToEvent = useCallback(() => {

    dispatch(getEvent({ id: props.event.id }));
    navigate(`/calendar/events/${props.event.id}`);
  }, [dispatch, props, navigate]);
  return (
    <div className={styles.featuredEventBox} >
      <div onClick={handleGoToEvent}>
        <div className={styles.topLine}>
          <div className={styles.calendar}
          // onClick={handleGoToEvent}
          >
            {/* <div className={styles.dateBox}>
							<span className={styles.day}>{day}</span>
							<span className={styles.date}>{date}</span>
						</div> */}
            <p>{props?.event?.title}</p>
          </div>
        </div>
        <div className={styles.infoBox}
        // onClick={handleGoToEvent}
        >
          <div className={styles.timePlace}>
            <p>
              {`${dateFormater(props?.event?.start)} - ${dateFormater(props?.event?.end)}`}
            </p>
            {props?.event?.location}
          </div>
        </div>
      </div>

      <div className={styles.tagSection}>
        {props?.event?.event_tag_list.map((tag, index) => {
          if (index < 3 || showAdditionalTags) {
            return <span key={`key_${index}`}>{tag}</span>;
          }
          return null;
        })}
      </div>
      {!showAdditionalTags && props?.event?.event_tag_list.length > 3 && (
        <p className={styles.readMore} onClick={handleClickReadMore}>
          Read more ...
        </p>
      )}


    </div>
  )
}

const EventWrapper = (props) => {

  return (
    <Popover
      className={styles.popoverContainer}
      placement={'rightTop'}
      content={<PopOverComponent event={props.event} />}
    >
      <Event {...props} />
    </Popover>
  );
};

export default memo(EventWrapper);