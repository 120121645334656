import {
	INotificationingGetNotificationAction,
	INotificationingSaveSettingsAction,
	INotificationingSetNotificationAction,
	INotificationingStatusChangeAction,
	INotificationsLoadingSetAction,
	INotificationingSaveSettingsSuccessAction,
	INotificationingSaveSettingsErrorAction,
	INotificationingGetNotificationsSettingsAction,
	INotificationingSetNotificationsSettingsAction,
	INotificationingSaveSettingsLocallyAction,
	INotificationSetPushNotificationAction,
	INotificationOpenAction,
	INotificationUpdateNotificationAction,
	INotificationSetUnreadNotificationsCounterAction,
	INotificationSetAllAsReadAction,
	INotificationLoadingAction,
} from "./actions";
import { IMetaData, IPost, IVideo } from "models";
import { IDoc } from "models/doc";

export enum ENotificationTypes {
	private_message = "private_message",
	manager_message = "manager_message",
	comment = "comment",
	comment_mention = "comment_mention",
	comment_thread = "comment_thread",
	comment_thread_mention = "comment_thread_mention",
	new_community_post = "new_community_post",
	new_community_post_access = "new_community_post_access",
	new_community_post_mention = "new_community_post_mention",
	new_community_good = "new_community_good",
	new_community_good_mention = "new_community_good_mention",
	community_post_approved = "community_post_approved",
	community_good_approved = "community_good_approved",
	new_community_video = "new_community_video",
	new_community_video_access = "new_video_access",
	new_community_video_mention = "new_community_video_mention",
	video_approved = "video_approved",
	new_resource = "new_resource",
	new_resource_access = "new_resource_access",
	new_community_resource_mention = "new_resource_mention",
	resource_approved = "resource_approved",

	new_community_document = "new_community_document",
	new_community_document_access = "new_community_document_access",
	new_community_document_mention = "new_community_document_mention",
	document_approved = "document_approved",
	new_offer = "new_offer",
	new_community_event = "new_community_event",
	approved_membership = "approved_membership",
	membership_updated = "membership_updated",
	group_join_request_rejected = "group_join_request_rejected",
	group_join_request_approved = "group_join_request_approved",
	group_status_update = "group_status_update",
	community_updated = "community_updated",
	member_blocked = "member_blocked",
	member_unblocked = "member_unblocked",
	new_community_invite = "new_community_invite",
	pin_approved = "pin_approved",
	invite_token_used = "invite_token_used",
	available_vimeo = "available_vimeo",
	tags_updated = "tags_updated",
	membership_type_updated = "membership_type_updated",
	profile_updated = "profile_updated",
}

// Warning! These actions were taken from the mobile version. It is possible that some actions don't fit on the web version and will be subsequently deleted.
export enum ENotificationsActionTypes {
	notificationsLoadingSet = "notifications/set/loading",
	getNotifications = "notifications/get",
	getUnreadNotificationsCounter = "notifications/get/unread/notifications/counter",
	setUnreadNotificationsCounter = "notifications/set/unread/notifications/counter",
	getNotificationsSettings = "notifications/get/settings",
	setNotificationsSettings = "notifications/set/settings",
	updateNotification = "notifications/update/notification",
	setNotifications = "notifications/set",
	setAllNotificationsAsRead = "notifications/set/all/notifications/as/read",
	statusChange = "notifications/status/change",
	saveSettings = "notifications/settings/save",
	saveSettingsLocally = "notifications/settings/save/locally",
	saveSettingsError = "save/settings/error",
	saveSettingsSuccess = "save/settings/success",
	openNotification = "notification/open",
	saveEmailNotificationOption = "notification/email/save",
	markAllAsRead = "notifications/mark/all/as/read",
	setPushNotification = "set/push/notification",
	setNotificationLoading = "set/notification/loading"
}

export interface INotification {
	id: number;
	community_id: number;
	type: ENotificationTypes;
	date: string;
	exp_date: string;
	read: boolean;
	object_id: number;
	code: string;
	body: string;
	image: string;
	commentable_id?: number;
	commentable_type?: string;
	notifiable_type?: string;
	folder_id?: number | null;
	group_id?: number
}

export interface INotificationSetting {
	active: boolean;
	name: string;
	title: string;
}

export interface INotificationsState {
	meta: IMetaData;
	loading: boolean;
	settingsSaved: {
		errorMessage: string;
		successMessage: string;
	};
	data: INotification[];
	pushNotification: any;
	settings?: INotificationSetting[];
	unreadNotificationsCounter: number;
	notificationDetails?: IPost | IVideo | IDoc;
	notificationLoading?:{loading:boolean,id:number|undefined}
}

// Warning! These actions were taken from the mobile version. It is possible that some actions don't fit on the web version and will be subsequently deleted.
export enum ENotificationActionsTypes {
	privateMessage = "private/message",
	manager_message = "manager/message",
	comment = "comment",
	commentMention = "comment/mention",
	comment_thread = "comment/thread",
	commentThreadMention = "comment/thread/mention",
	newCommunityPost = "new/community/post",
	newCommunityPostAccess = "new/community/post/access",
	newCommunityPostMention = "new/community/post/mention",
	newCommunityGood = "new/community/good",
	newCommunityGoodMention = "new/community/good/mention",
	communityPostApproved = "community/post/approved",
	communityGoodApproved = "community/good/approved",
	newCommunityVideo = "new/community/video",
	newCommunityVideoAccess = "new/video/access",
	newCommunityVideoMention = "new/community/video/mention",
	newResource = "new/resource",
	newResourceAccess = "new/resource/access",
	newCommunityResourceMention = "new/resource/mention",
	newCommunityDocument = "new/community/document",
	newCommunityDocumentAccess = "new/community/document/access",
	newCommunityDocumentMention = "new/community/document/mention",
	newOffer = "new/offer",
	newCommunityEvent = "new/community/event",
	approvedMembership = "approved/membership",
	membershipUpdated = "membership/updated",
	groupJoinRequestRejected = "group/join/request/rejected",
	groupJoinRequestApproved = "group/join/request/approved",
	groupStatusUpdate = "group/status/update",
	communityUpdated = "community/updated",
	memberBlocked = "member/blocked",
	memberUnblocked = "member/unblocked",
	newCommunityInvite = "new/community/invite",
	pinApproved = "pin/approved",
	inviteTokenUsed = "invite/token/used",
}

// TYPES
export type TNotificationsActions =
	| INotificationingSaveSettingsLocallyAction
	| INotificationingGetNotificationAction
	| INotificationingSaveSettingsAction
	| INotificationingSetNotificationAction
	| INotificationingStatusChangeAction
	| INotificationsLoadingSetAction
	| INotificationLoadingAction
	| INotificationingSaveSettingsSuccessAction
	| INotificationingSaveSettingsErrorAction
	| INotificationingGetNotificationsSettingsAction
	| INotificationingSetNotificationsSettingsAction
	| INotificationSetPushNotificationAction
	| INotificationOpenAction
	| INotificationUpdateNotificationAction
	| INotificationSetUnreadNotificationsCounterAction
	| INotificationSetAllAsReadAction;
