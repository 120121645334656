import { axiosInstance } from "..";

export const offers = {
	get: (payload: {
		communityId?: number;
		groupId?: number | null;
		tag_clicked?: boolean;
		q?: string;
		reset?: boolean;
		page?: number;
		id?: number;
	}) =>
		axiosInstance().get(`/communities/${payload.communityId}/offers`, {
			params: {
				group_id: payload.groupId,
				tag_clicked: payload.tag_clicked || false,
				q: payload.q || null,
				page: payload.page,
				by_name: true,
				offer_id: payload.id,
			},
		}),

	redeemOffer: (payload: {
		type: "email" | "link" | "website";
		communityId: number;
		offerId: number;
	}) =>
		axiosInstance().post(
			`/communities/${payload.communityId}/offers/${payload.offerId}/track_redeems`,
			{
				type: payload.type,
			},
		),
};
