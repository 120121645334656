import React, { memo, useState, useCallback } from "react";
import { Button } from "antd";
import classNames from "classnames";

import { UploadDocForm, UploadVideoForm } from "components";
import styles from "./styles.module.scss";

interface IUploadResourceForm {
	closeTheForm: (askTheUserToConfirm?: boolean) => void;
}
const UploadResourceForm: React.FunctionComponent<IUploadResourceForm> = ({
	closeTheForm,
}) => {
	const [currentTab, setCurrentTab] = useState("doc");

	const toggleCurrentTag = useCallback(
		(key: string) => () => {
			setCurrentTab(key);
		},
		[],
	);

	return (
		<>
			<div className={styles.buttonsContainer}>
				<div
					className={classNames(styles.primaryButton, {
						[styles.activeButton]:
							currentTab === "doc" ? true : false,
					})}
				>
					<Button
						onClick={toggleCurrentTag("doc")}
						type="primary"
						shape="round"
						size={"middle"}
					>
						Add Doc
					</Button>
				</div>
				<div
					className={classNames(styles.primaryButton, {
						[styles.activeButton]:
							currentTab === "video" ? true : false,
					})}
				>
					<Button
						onClick={toggleCurrentTag("video")}
						type="primary"
						shape="round"
						size={"middle"}
					>
						Add Video
					</Button>
				</div>
			</div>
			{currentTab === "doc" ? (
				<UploadDocForm closeTheForm={closeTheForm} />
			) : (
				<UploadVideoForm closeTheForm={closeTheForm} />
			)}
		</>
	);
};

export default memo(UploadResourceForm);
