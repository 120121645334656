import React, { memo, useEffect, useState, useCallback } from "react";
import { Button } from "antd";

import { scrollTopContainer } from "utils";
import styles from "./styles.module.scss";

const ScrollToTop: React.FunctionComponent<any> = ({ parentRef }) => {
	const [showButton, setShowButton] = useState<boolean>(false);

	const handleBackToTop = useCallback(() => {
		scrollTopContainer(parentRef.current.parentNode, 5);
	}, [parentRef]);

	useEffect(() => {
		const containerRef = parentRef;
		const scrollListen: any =
			containerRef &&
			containerRef.current &&
			containerRef.current.parentNode.addEventListener(
				"scroll",
				(): any => {
					if (
						containerRef &&
						containerRef.current &&
						containerRef.current.parentNode.scrollTop > 1350
					) {
						setShowButton(true);
					} else {
						setShowButton(false);
					}
				},
				{ capture: true, passive: true },
			);
		return () => {
			containerRef &&
				containerRef.current &&
				containerRef.current.parentNode.removeEventListener(
					"scroll",
					scrollListen,
				);
		};
	}, [parentRef]);

	if (!showButton) return null;
	return (
		<div className={styles.container}>
			<Button onClick={handleBackToTop} className={styles.buttonBox}>
				Back To Top
			</Button>
		</div>
	);
};

export default memo(ScrollToTop);
