import React, { memo, ReactElement } from "react";

import styles from "./styles.module.scss";
import { ChangeSubscriptionHeader, FooterLayout } from "layouts/components";
import { isItAWhiteLabel } from "utils";

interface IChangeSubscriptionLayout {
	children: ReactElement;
	headerTitle?: string;
	headerSubtitle?: string;
}

const ChangeSubscriptionLayout: React.FunctionComponent<
	IChangeSubscriptionLayout
> = ({ headerTitle, headerSubtitle, children }) => {
	return (
		<section
			className={styles.onboardingLayout}
			style={{ alignItems: "unset" }}
		>
			<ChangeSubscriptionHeader />
			<div className={styles.container} style={{ maxWidth: "unset" }}>
				<section className={styles.header}>
					<h1 className={styles.title}>{headerTitle}</h1>

					<h3 className={styles.subtitle}>{headerSubtitle}</h3>
				</section>
				{children}
			</div>
			{!isItAWhiteLabel() && <FooterLayout />}
		</section>
	);
};

export default memo(ChangeSubscriptionLayout);
