import { memo } from "react";
import { CloseCircleFilled } from "@ant-design/icons";

interface IClearIcon {
	handleClearData: (string) => void;
}

const ClearIcon: React.FunctionComponent<IClearIcon> = memo(
	({ handleClearData }) => {
		return <CloseCircleFilled onClick={() => handleClearData("")} />;
	},
);

export default ClearIcon;
