import {
	EMarketplaceOffersActionTypes,
	IMarketplaceOfferRawData,
} from "models";

export const setMarketplaceOffersLoading = (payload: boolean) => ({
	type: EMarketplaceOffersActionTypes.marketplaceOffersLoadingSet,
	payload,
});

export const getMarketplaceOffers = (payload?: {
	tagClicked?: boolean;
	query?: string;
	isGoods?: boolean;
	reset?: boolean;
}) => ({
	type: EMarketplaceOffersActionTypes.getMarketplaceOffers,
	payload,
});

export const setMarketplaceOffers = (payload: any) => ({
	type: EMarketplaceOffersActionTypes.setMarketplaceOffers,
	payload,
});
export const setMarketplaceOfferInStore = (payload: {
	data: Partial<IMarketplaceOfferRawData>;
	newMarketplaceOffer: boolean;
}) => ({
	type: EMarketplaceOffersActionTypes.setMarketplaceOfferInStore,
	payload,
});
export const setMarketplaceOffersTags = (payload: any) => ({
	type: EMarketplaceOffersActionTypes.setMarketplaceOffersTags,
	payload,
});
export const setFilterTagsMarketplaceOffers = (payload: {
	tags: string[];
}) => ({
	type: EMarketplaceOffersActionTypes.setFilterTagsMarketplaceOffers,
	payload,
});
export const removeFilterTagMarketplaceOffers = (payload: any) => ({
	type: EMarketplaceOffersActionTypes.removeFilterTagMarketplaceOffers,
	payload,
});
export const setMarketplaceOffersQuery = (payload: { query: string }) => ({
	type: EMarketplaceOffersActionTypes.setMarketplaceOffersQuery,
	payload,
});
