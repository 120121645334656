import { memo, useLayoutEffect, useCallback } from "react";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";

import { TStore } from "models";
import { AuthenticationBox } from "components";
import { AuthenticationLayout } from "layouts";
import {
	hexToRgbA,
	isItAWhiteLabel,
	settingSelectedCommunityToJoinColors,
} from "utils";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.auth.communityInvitation,
	(state: TStore) => state.previewWhiteLabelCommunity,
	(communityInvitation, previewWhiteLabelCommunity) => ({
		communityInvitation,
		previewWhiteLabelCommunity,
	}),
);

const SignUp = () => {
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { communityInvitation, previewWhiteLabelCommunity } =
		useSelector(stateSelector);

	useLayoutEffect(() => {
		if (!isItAWhiteLabel() && communityInvitation) {
			const {
				header_background,
				announcement_header_background,
				primary_color,
			} = communityInvitation.community.appearance;
			settingSelectedCommunityToJoinColors({
				headerBackground: header_background ?? null,
				headerBackgroundRgb: header_background
					? hexToRgbA(header_background)
					: null,
				announcementHeaderBackground:
					announcement_header_background ?? null,
				announcementHeaderBackgroundRgb: announcement_header_background
					? hexToRgbA(announcement_header_background)
					: null,
				primaryColor: primary_color ?? null,
				primaryColorRgb: primary_color
					? hexToRgbA(primary_color)
					: null,
			});
		}

		return () => {
			settingSelectedCommunityToJoinColors();
		};
	}, [communityInvitation, previewWhiteLabelCommunity]);

	return (
		<AuthenticationLayout>
			<AuthenticationBox signUp />
		</AuthenticationLayout>
	);
};

export default memo(SignUp);
