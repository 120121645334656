import { call, put, all, select, takeLatest } from "redux-saga/effects";

import {
    TStore,
    ENotificationsActionTypes,
    IMetaData,
    INotification,
    INotificationSetting,
    IPostsMeta,
    IPost,
} from "models";
import { api, notify } from "store";
import {
    changeSettingStatusAction,
    getNotificationsAction,
    getNotificationsSettingsAction,
    getUnreadNotificationsCounterSagaAction,
    setAllNotificationsAsReadAction,
    setNotificationLoading,
    markAllAsReadSagaAction,
    openNotificationAction,
    setNotificationsAction,
    setNotificationsSettingsAction,
    setUnreadNotificationsCounterAction,
    updateNotification,
} from "store/actions/notifications";
import { rollbar } from "utils";

const getCurrentCommunityId = (state: TStore) => state.app?.currentCommunityId;
const selectNotificationsSettings: any = (state: TStore) =>
    state.notifications?.settings;
const selectNotificationsMeta = (state: TStore) => state.notifications?.meta;
const selectNotifications = (state: TStore) => state.notifications;

function* changeSettingStatusHandle({
    payload,
}: ReturnType<typeof changeSettingStatusAction>) {
    try {
        const communityId: number = yield select(getCurrentCommunityId);
        const settings: INotificationSetting[] = yield select(
            selectNotificationsSettings,
        );

        const result = [...settings].map((item: any) => {
            if (item.name === payload.name) {
                item.active = !item.active;
            }

            return item;
        });

        const { data } = yield call(api.userNotifications.toggle, {
            communityId,
            membership: result.reduce((acc: any, cur: any) => {
                acc[cur.name] = cur.active;
                return acc;
            }, {}),
        });

        yield put(
            setNotificationsSettingsAction({ membership: data.membership }),
        );
        yield call(notify, "success", "Settings saved successfully");
    } catch (error) {
        rollbar.error(error)

        yield call(notify, "error", "Ooops ... something went wrong");
    }
}

function* getNotificationsSettingsHandle({
    payload,
}: ReturnType<typeof getNotificationsSettingsAction>) {
    const { callbackOnSuccess, callbackOnError } = payload;
    try {
        const communityId: number = yield select(getCurrentCommunityId);
        const { data } = yield call(api.userNotifications.getSettings, {
            communityId,
        });

        yield put(
            setNotificationsSettingsAction({ membership: data.membership }),
        );
        if (callbackOnSuccess) {
            callbackOnSuccess();
        }
    } catch (error) {
        rollbar.error(error)

        if (callbackOnError) {
            callbackOnError();
        }
    }
}

function* getNotificationsHandle({
    payload,
}: ReturnType<typeof getNotificationsAction>) {
    const { reset = false, callbackOnSuccess, callbackOnError } = payload || {};

    try {
        const communityId: number = yield select(getCurrentCommunityId);
        if (!communityId) {
            throw new Error("Missing arguments.");
        }
        const notificationsMeta: IMetaData = yield select(
            selectNotificationsMeta,
        );
        const page = reset
            ? 1
            : notificationsMeta?.pagination?.current_page
                ? parseInt(notificationsMeta?.pagination?.current_page) + 1
                : 1;

        const {
            data,
        }: {
            data: { meta: IMetaData; notifications: INotification[] };
        } = yield call(api.userNotifications.getNotifications, {
            page,
            communityId: communityId,
        });

        yield put(
            setNotificationsAction({
                meta: data.meta,
                notifications: data.notifications,
                loading: false,
                reset,
            }),
        );
        if (callbackOnSuccess) {
            callbackOnSuccess();
        }
    } catch (error) {
        rollbar.error(error)

        if (callbackOnError) {
            callbackOnError();
        }
    }
}

function* openNotificationHandle({
    payload,
}: ReturnType<typeof openNotificationAction>) {
    const { id, forceCommunityId } = payload;
    yield put(setNotificationLoading({ loading: true, id: id }))

    try {
        const communityId: number = yield select(getCurrentCommunityId);
        const { data } = yield api.userNotifications.openNotification(
            forceCommunityId || communityId,
            id,
        );
        const {
            notification,
            // notificationCounter
        } = data;
        yield put(setNotificationLoading({ loading: false, id: id }))

        yield put(updateNotification({ notification }));
        // get the new notifications counter.
        yield put(getUnreadNotificationsCounterSagaAction());
    } catch (error) {
        rollbar.error(error)
        console.error({ error });
    }
}

function* markAllAsReadHandle({
    payload,
}: ReturnType<typeof markAllAsReadSagaAction>) {
    const { onErrorCallback, onSuccessCallback } = payload;
    try {
        const communityId: number = yield select(getCurrentCommunityId);
        const storeNotifications: {
            meta: IMetaData;
            data: INotification[];
        } = yield select(selectNotifications);
        if (!communityId || !storeNotifications) {
            throw new Error("Something went wrong!");
        }
        yield api.userNotifications.markAllAsRead(communityId);

        const notificationsDataMarkedAsRead = storeNotifications.data.map(
            (item) => {
                return {
                    ...item,
                    read: true,
                };
            },
        );
        yield put(
            setAllNotificationsAsReadAction({
                notifications: notificationsDataMarkedAsRead,
            }),
        );

        onSuccessCallback?.();
    } catch (error) {
        rollbar.error(error)
        console.error(error);
        onErrorCallback?.();
    }
}
function* handleGetUnreadNotificationsCounter({
    payload,
}: ReturnType<typeof getUnreadNotificationsCounterSagaAction>) {
    const { onErrorCallback, onSuccessCallback, communityId } = payload || {};
    try {
        const globalStoreCommunityId: number = yield select(
            getCurrentCommunityId,
        );
        const resultedCommunityId = communityId
            ? communityId
            : globalStoreCommunityId;

        if (!resultedCommunityId) {
            throw new Error("Something went wrong!");
        }

        const { data }: { data: { meta: IPostsMeta; posts: IPost[] } } =
            yield call(api.posts.getHomePosts, {
                communityId: resultedCommunityId,
                groupId: undefined,
                query: undefined,
                tagClicked: false,
            });

        if (!data) {
            throw new Error("Something went wrong!");
        }
        yield put(
            setUnreadNotificationsCounterAction({
                unreadNotificationsCounter: data.meta.notification_counter ?? 0,
            }),
        );

        onSuccessCallback?.();
    } catch (error) {
        rollbar.error(error)
        console.error(error);
        onErrorCallback?.();
    }
}

export default function* rootSaga() {
    yield all([
        takeLatest(
            ENotificationsActionTypes.getNotificationsSettings,
            getNotificationsSettingsHandle,
        ),
        takeLatest(
            ENotificationsActionTypes.statusChange,
            changeSettingStatusHandle,
        ),
        takeLatest(
            ENotificationsActionTypes.getNotifications,
            getNotificationsHandle,
        ),
        takeLatest(
            ENotificationsActionTypes.openNotification,
            openNotificationHandle,
        ),
        takeLatest(
            ENotificationsActionTypes.markAllAsRead,
            markAllAsReadHandle,
        ),
        takeLatest(
            ENotificationsActionTypes.getUnreadNotificationsCounter,
            handleGetUnreadNotificationsCounter,
        ),
    ]);
}
