import { all } from "redux-saga/effects";

import auth from "./auth";
import communities from "./communities";
import community from "./community";
import groups from "./groups";
import profile from "./profile";
import posts from "./posts";
import post from "./post";
import app from "./app";
import marketplaceOffers from "./marketplaceOffers";
import marketplaceOffer from "./marketplaceOffer";
import events from "./events";
import event from "./event";
import firebase from "./firebase";
import communitiesJoined from "./communitiesJoined";
import member from "./member";
import members from "./members";
import messages from "./messages";
import doc from "./doc";
import docs from "./docs";
import video from "./video";
import videos from "./videos";
import resources from "./resources";
import offer from "./offer";
import offers from "./offers";
import viewCounts from "./viewCounts";
import mediaItem from "./mediaItem";
import notifications from "./notifications";
import resource from "./resource";
import payments from "./payments";
import subscription from "./subscription";
import previewWhiteLabelCommunity from "./previewWhiteLabelCommunity";

export default function* rootSaga() {
	yield all([
		auth(),
		communities(),
		community(),
		groups(),
		profile(),
		posts(),
		post(),
		app(),
		marketplaceOffers(),
		marketplaceOffer(),
		events(),
		event(),
		firebase(),
		communitiesJoined(),
		member(),
		members(),
		messages(),
		doc(),
		docs(),
		video(),
		videos(),
		resources(),
		offer(),
		offers(),
		viewCounts(),
		mediaItem(),
		notifications(),
		resource(),
		payments(),
		subscription(),
		previewWhiteLabelCommunity(),
	]);
}
