import { memo } from "react";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import classNames from "classnames";

import styles from "./styles.module.scss";
import CustomCheckBok from "components/CustomCheckBox";

interface IInterestsAndSkills {
	errorNoInterestsSelected: boolean;
	tags: string[];
	updateSelectedSkills: (selectedSkills: CheckboxValueType[]) => void;
	updateSelectedInterests: (selectedInterests: CheckboxValueType[]) => void;
	skills: { id: number; name: string }[];
	selectedTags: string[];
	selectedSkills: string[];
	appearance: {
		id: number;
		skill_tag_display_name: string;
	};
}

const InterestsAndSkills: React.FunctionComponent<IInterestsAndSkills> = ({
	errorNoInterestsSelected,
	tags,
	updateSelectedInterests,
	updateSelectedSkills,
	skills,
	selectedSkills,
	selectedTags,
	appearance,
}) => {
	return tags.length === 0 ? null : ( //TODO: add a warning message here. If the community doesn't have at least one tag, the joining process cannot be accomplished.
		<div className={styles.container}>
			<div
				className={styles.boldWeightLabel}
				style={{ margin: "unset", marginTop: "5px" }}
			>
				{"What are you interested in?"}
			</div>

			<>
				<div
					className={classNames(styles.subTitle, {
						[styles.errorText]: errorNoInterestsSelected,
						[styles.greyText]: !errorNoInterestsSelected,
					})}
				>
					{"Please choose at least one tag."}
				</div>

				<div
					id="checkbox-interests"
					className={classNames(styles.accents)}
				>
					<CustomCheckBok
						name={"checkbox-group"}
						options={tags.map((item: any) => item.name)}
						onChange={updateSelectedInterests}
						value={selectedTags}
					/>
				</div>

				{skills.length === 0 ? null : (
					<div className={styles.verticalMarginBox}>
						<div
							className={styles.boldWeightLabel}
							style={{ marginBottom: "8px" }}
						>
							{`Please choose your ${
								appearance?.skill_tag_display_name
									? appearance.skill_tag_display_name
									: "skills"
							}:`}
						</div>

						<div
							id="checkbox-skills"
							className={classNames(styles.accents)}
						>
							<CustomCheckBok
								options={skills.map((item) => item.name)}
								onChange={updateSelectedSkills}
								value={selectedSkills}
							/>
						</div>
					</div>
				)}
			</>
		</div>
	);
};

export default memo(InterestsAndSkills);
