import {
	IResourcesState,
	EResourcesActionTypes,
	TResourcesActions,
	EGroupPrivacy,
} from "models";

const initial: IResourcesState = {
	loading: false,
	path: [],
	data: undefined,
	selectedTags: [],
	redirectQuery: '',
	query: "",
	currentFolder: undefined,
	order: "latest",
	order_dir: "desc",
	meta: {
		pagination: {
			current_page: 0,
			per_page: 0,
			total_pages: 0,
			total_objects: 0,
			has_next_page: true,
		},
		scope_group: {
			id: 0,
			name: "",
			description: "",
			privacy: EGroupPrivacy.closed,
		},
		folders: null,
	},
};

export function resourcesReducer(
	state = initial,
	action: TResourcesActions,
): IResourcesState {
	switch (action.type) {
		case EResourcesActionTypes.resourcesLoadingSet: {
			return {
				...state,
				loading: action.payload,
			};
		}
		case EResourcesActionTypes.setResourcesOrder: {
			return {
				...state,
				order: action.payload[0],
				order_dir: action.payload[1],
			};
		}
		case EResourcesActionTypes.setResources: {
			const { data, reset } = action.payload;
			const resources = reset
				? data.resources
				: state?.data
					? [...state.data].concat(data.resources)
					: data.resources;

			return {
				...state,
				data: resources,
				meta: action.payload.data.meta,
			};
		}
		case EResourcesActionTypes.setFilterTagsInStore: {
			const query = !action.payload.parsedTags.length ? "" : state.query;
			return {
				...state,
				selectedTags: action.payload.parsedTags,
				query,
			};
		}
		case EResourcesActionTypes.setResourcesQuery: {
			return {
				...state,
				query: action.payload.query,
				selectedTags: [],
			};
		}
		case EResourcesActionTypes.setResourcesRedirectQuery: {

			return {
				...state,
				redirectQuery: action.payload.redirectQuery,
				selectedTags: [],
			};
		}
		case EResourcesActionTypes.incrementResourceViews: {
			return {
				...state,
			};
		}
		case EResourcesActionTypes.updateResourcesCurrentFolder: {
			return {
				...state,
				currentFolder: action.payload,
			};
		}
		case EResourcesActionTypes.setPath: {
			const { index, path, item } = action.payload;
			let data: any = [];
			if (path) {
				data = path;
			} else if (index) {
				data = [...state.path].filter((_, i: number) => i <= index);
			} else if (item) {
				data = [...state.path].concat([item]);
			}

			return {
				...state,
				path: data,
			};
		}
		default:
			return state;
	}
}
