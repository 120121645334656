import React, { memo, useState, useEffect, useCallback } from "react";
import { Upload, Modal, Form } from "antd";

import styles from "./styles.module.scss";
import {ReactComponent as UploadPhoto} from "assets/images/upload_photo.svg";
import {ReactComponent as InfoIcon} from "assets/images/infos.svg";

function getBase64(file: any) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();

		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});
}

interface IPhotoForm {
	images?: any[];
}

const PhotoForm: React.FunctionComponent<IPhotoForm> = ({ images }) => {
	const [form] = Form.useForm();
	const [previewVisible, setPreviewVisible] = useState(false);
	const [previewImage, setPreviewImage] = useState("");
	const [openInfo,setOpenInfo] = useState(false)
	const [fileList, setFileList] = useState<any[]>([]);
	const handleCancel = () => setPreviewVisible(false);
	const handleFileListChange = ({ file, fileList }: any) => {
		if (file && file.status === "done") {
			// I need to send data to form provider
			form.submit();
		}
		setFileList([...fileList]);
	};

	const uploadButton = (
		<div className={styles.uploadIconDiv}>
			<UploadPhoto 
			className={styles.uploadPhotoIcon}
			// fill="black"
			width={45}
			// height={20}
			/>
			{/* <img src={uploadPhoto} alt="upload" style={{ borderRadius: 0 }} /> */}
		</div>
	);

	const handlePreview = async (file: any) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj);
		}

		setPreviewImage(file.url || file.preview);
		setPreviewVisible(true);
	};

	const handleCustomRequest = ({ file, onSuccess }: any) => {
		setTimeout(() => {
			onSuccess("ok");
		}, 0);
	};

	useEffect(() => {
		let postImages: any = [];
		if (images && images.length > 0) {
			const thumbImages = images.map(
				(
					item: { id: string; image: string; thumb: string },
					index: number,
				) => {
					return {
						uid: item.id,
						name: item.thumb,
						status: "done",
						url: item.image,
						dbImage: true,
					};
				},
			);
			postImages = thumbImages;
		}
		setFileList(postImages);
	}, [images]);

	const handleRemoveImages = useCallback(
		(img) => {
			if (img.dbImage) {
				const imagesList = form.getFieldValue("imagesToBeRemoved");
				form.setFieldsValue({
					imagesToBeRemoved: [...imagesList, img.uid],
				});
			}
		},
		[form],
	);

	return (
		<Form
			form={form}
			name="photoForm"
			layout="vertical"
			initialValues={{ imagesToBeRemoved: [] }}
		>
			<div className={styles.container}>
				<div className={styles.photoBoxes}>
					<Form.Item
						name="postImages"
						style={{
							justifyContent: "center",
							display: "flex",
							alignItems: "center",
						}}
						rules={[
							{
								required: true,
								message: "Add at least one photo",
							},
						]}
					>
						<Upload
							accept=".png,.jpg,.jpeg"
							listType="picture-card"
							fileList={fileList}
							onPreview={handlePreview}
							onChange={handleFileListChange}
							onRemove={handleRemoveImages}
							showUploadList={{ showDownloadIcon: false }}
							customRequest={handleCustomRequest}
							style={{
								justifyContent: "center",
								display: "flex",
								alignItems: "center",
								flexWrap: "wrap",
							}}
						>
							{fileList && fileList.length >= 9
								? null
								: uploadButton}
						</Upload>
					</Form.Item>
					<Modal
						visible={previewVisible}
						footer={null}
						onCancel={handleCancel}
					>
						<img
							alt=""
							style={{ width: "100%" }}
							src={previewImage}
						/>
					</Modal>
					<div className={styles.photoText}>
						<div
							style={{
								display:'flex',
								gap:7,
								fontFamily: `"Avenir Heavy", sans-serif`,
							}}
						>
							Add photos
							<InfoIcon 
							onClick={() => setOpenInfo(!openInfo)}
								className={styles.uploadPhotoIcon}
							width={20}
							/>
						</div>
						{openInfo && <>
						<div>{"You can add up to 9 images."}</div>
						<div
							style={{ fontSize: "normal", fontWeight: "normal" }}
						>
							{
								"For better UX an image of 1500x1000 pixel resolution or higher is recommended. Images with aspect ratio of 1.5:1 will look like the original file, others will be cropped to fit."
							}
						</div>
						</>}
					</div>
				</div>
			</div>
		</Form>
	);
};

export default memo(PhotoForm);
