import React, {
	memo,
	useCallback,
	useLayoutEffect,
	useMemo,
	useState,
} from "react";
import { useDispatch } from "react-redux";
import DOMPurify from "dompurify";
import { Divider } from "antd";
import Scrollbar from "react-scrollbars-custom";
import classNames from "classnames";
import { useNavigate, useParams } from "react-router-dom";

import {
	ECommunityType,
	EJoinCommunityOnboardingSteps,
	EProcessToJoinCommunityStage,
	ICommunityMemberPlan,
	ISelectedCommunityToJoin,
	ISubscriptionState,
} from "models";
import {
	setSelectedCommunityToJoinMemberType,
	setSelectedCommunityToJoinMemberPlan,
	changeSubscriptionPlanSagaAction,
	setChangeSubscriptionSelectedMemberPlanAction,
	setSelectedCommunityToJoinProgressBar,
	notify,
} from "store";
import {
	getMemberPlanPriceOptionByCurrency,
	usePeriodOfPayment,
	isItAWhiteLabel,
} from "utils";
import styles from "./styles.module.scss";
import CustomButton from "components/Button";
import { CheckCircleOutlined } from "@ant-design/icons";
import ReadMore from "components/ReadMore";

interface IPricing {
	memberPlan: ICommunityMemberPlan;
	selectedCurrency: string | null;
	resultedCommunity: ISelectedCommunityToJoin | null;
	elligibleForTrial?: boolean;
	itIsCurrentMemberPlan?: boolean; //** if the member plan is the one that the user has already paid for it */
	communityType?: ECommunityType;
	subscriptionCurrentMemberPlan?: ICommunityMemberPlan;
	subscriptionState?: ISubscriptionState;
}

const LISTED_BENEFITS_MAX_HEIGHT = 180;

const PricingCard: React.FunctionComponent<IPricing> = ({
	memberPlan,
	itIsCurrentMemberPlan = false,
	communityType = ECommunityType.join,
	subscriptionState,
	elligibleForTrial,
	selectedCurrency,
	resultedCommunity,
}) => {
	const {
		benefits,
		description,
		display_name,
		cover,
		period: { interval, interval_count },
		trial_period,
		free,
		price_options,
	} = memberPlan;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	// const [isShowMore, setIsShowMore] = useState(true);
	const [processChangeSubscription, setProcessChangeSubscription] =
		useState(false);
	const [benefitsItemsElement, setBenefitsItemsElement] = useState<{
		scrollHeight: null | number;
	}>({
		scrollHeight: null,
	});
	const { communityId }: { communityId?: string } = useParams();

	const priceTagParsedTest = useMemo((): {
		wholeNumber: string;
		fractionalPart: string;
		originalPriceTag: string;
	} => {
		if (!memberPlan) {
			return {
				wholeNumber: "",
				fractionalPart: "",
				originalPriceTag: "",
			};
		}
		if (free) {
			return {
				wholeNumber: "free",
				fractionalPart: "",
				originalPriceTag: "",
			};
		} else if (!price_options) {
			return {
				wholeNumber: "free",
				fractionalPart: "",
				originalPriceTag: "",
			};
		} else {
			let priceOption;

			if (!selectedCurrency) {
				// if there is no selected currency, priceTag will be the first available price.
				priceOption = price_options[0];
			} else {
				priceOption = price_options.find((item) => {
					return (
						item.currency.toLowerCase() ===
						selectedCurrency.toLowerCase()
					);
				});
			}

			if (
				priceOption?.price_tag &&
				priceOption.price_tag?.indexOf(".") !== -1
			) {
				// if there is a fractional part
				const priceTagArray = priceOption.price_tag.split(".");
				return {
					wholeNumber: priceTagArray[0],
					fractionalPart: priceTagArray[1],
					originalPriceTag: priceOption?.price_tag,
				};
			} else {
				return {
					wholeNumber: priceOption?.price_tag ?? "",
					fractionalPart: "",
					originalPriceTag: priceOption?.price_tag ?? "",
				};
			}
		}
	}, [free, memberPlan, price_options, selectedCurrency]);

	const periodOfPayment = usePeriodOfPayment({
		interval: interval,
		intervalCount: interval_count,
		priceTag: priceTagParsedTest.originalPriceTag,
	});

	const listedItemsStyle: { height: number } | {} = useMemo(() => {
		if (!benefitsItemsElement.scrollHeight || !benefits)
			return { maxHeight: LISTED_BENEFITS_MAX_HEIGHT };
		return {
			height: (benefitsItemsElement.scrollHeight + 10) * benefits.length,
			maxHeight: LISTED_BENEFITS_MAX_HEIGHT,
		};
	}, [benefitsItemsElement.scrollHeight, benefits]);

	const cleanedDescription = DOMPurify.sanitize(description, {
		ALLOWED_TAGS: ["a"],
		ALLOWED_ATTR: ["href"], // TODO: for security purposes I need to allow just a specific href structure
	});
	// const trimmedText: any = useTrimmedText({
	// 	description: cleanedDescription,
	// 	carriageReturnArrayLength: 2,
	// 	splitByEmptySpaceArrayLength: 30,
	// });
	// const { trimmed, trimmedValue, parsed } = trimmedText;

	// const toggleShowMore = useCallback(() => {
	// 	setIsShowMore(!isShowMore);
	// }, [isShowMore]);

	const buttonLabel: { mainLabel: string; secondaryLabel?: string } =
		useMemo(() => {
			if (!itIsCurrentMemberPlan) {
				if (free) {
					return {
						mainLabel: "Choose",
					};
				} else if (trial_period !== null) {
					if (elligibleForTrial === false) {
						return {
							mainLabel: "Subscribe & Pay",
							secondaryLabel:
								"You have already had a trial in this community.",
						};
					} else {
						return {
							mainLabel: "Start Trial",
						};
					}
				} else {
					return {
						mainLabel: "Subscribe & Pay",
					};
				}
			} else {
				return { mainLabel: "Current Plan" };
			}
		}, [elligibleForTrial, itIsCurrentMemberPlan, free, trial_period]);

	const handleSelectedMemberPlan = useCallback(() => {
		if (communityType === ECommunityType.join) {
			if (!display_name || !memberPlan || !communityId) {
				console.error("Missing params!");
				return;
			}
			dispatch(
				setSelectedCommunityToJoinMemberPlan({ plan: memberPlan }),
			);
			dispatch(
				setSelectedCommunityToJoinMemberType({
					memberType: display_name,
				}),
			);
			dispatch(
				setSelectedCommunityToJoinProgressBar({
					onboardingStep:
						EJoinCommunityOnboardingSteps["Your profile"],
					showTheCheckoutStep: !memberPlan.free,
				}),
			);
			navigate(
				`/${
					isItAWhiteLabel() ? "joining" : "communities"
				}/${communityId}/${EProcessToJoinCommunityStage.welcome}`,
			);
		} else if (communityType === ECommunityType.current) {
			const memberPlanPrice = getMemberPlanPriceOptionByCurrency(
				subscriptionState?.changeSubscriptionSelectedCurrency
					? subscriptionState?.changeSubscriptionSelectedCurrency
					: subscriptionState?.subscription?.plan?.price_options &&
							subscriptionState?.subscription?.plan?.price_options.find(
								(price_option) =>
									price_option.stripe_id ===
									subscriptionState?.subscription
										?.stripe_plan_price_id,
							)?.currency,
				memberPlan?.price_options,
			);

			if (!memberPlanPrice?.stripe_id) {
				notify(
					"error",
					"Something went wrong.",
					"Please try again later.",
				);
				return;
			}

			if (
				subscriptionState?.paymentMethods &&
				subscriptionState.paymentMethods.length > 0
			) {
				setProcessChangeSubscription(true);
				dispatch(
					changeSubscriptionPlanSagaAction({
						stripePlanPriceId: memberPlanPrice.stripe_id,
						onSuccessCallback: () => {
							navigate("/subscription-management");
							setProcessChangeSubscription(false);
						},
						onErrorCallback: () => {
							setProcessChangeSubscription(false);
							notify(
								"error",
								"Something went wrong.",
								"Please try again later.",
							);
						},
					}),
				);
			} else {
				setProcessChangeSubscription(true);

				dispatch(
					changeSubscriptionPlanSagaAction({
						stripePlanPriceId: memberPlanPrice.stripe_id,
						onSuccessCallback: () => {
							// if the previous plan was free and the current one is also free
							if (memberPlan.free) {
								navigate("/subscription-management");
								setProcessChangeSubscription(false);
							} else {
								dispatch(
									setChangeSubscriptionSelectedMemberPlanAction(
										{
											memberPlan,
											updateTheStageToCheckoutForm: true,
										},
									),
								);
								setProcessChangeSubscription(false);
							}
						},
						onErrorCallback: () => {
							setProcessChangeSubscription(false);
						},
					}),
				);
			}
		}
	}, [
		communityType,
		display_name,
		memberPlan,
		communityId,
		dispatch,
		navigate,
		subscriptionState,
	]);

	useLayoutEffect(() => {
		const listedItemsElementContentProps =
			document.getElementById("content-props");
		if (
			benefitsItemsElement.scrollHeight === null &&
			listedItemsElementContentProps
		) {
			setBenefitsItemsElement({
				...benefitsItemsElement,
				scrollHeight: listedItemsElementContentProps?.scrollHeight ?? 0,
			});
		}
	}, [benefitsItemsElement]);

	return !memberPlan ? null : (
		<div className={styles.card}>
			{/* <div className={styles.priceContainer}>
				<div className={styles.priceContent}>
					<div className={styles.priceText}>
						{priceTagParsedTest.wholeNumber}
						{priceTagParsedTest.fractionalPart ? (
							<span className={styles.smallBoldText}>
								{`.${priceTagParsedTest.fractionalPart}`}
							</span>
						) : null}
					</div>
					{periodOfPayment ? (
						<div className={styles.lightText}>
							{periodOfPayment}
						</div>
					) : null}
				</div>
			</div> */}

			<div className={styles.container}>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						gap: "16px",
					}}
				>
					<div style={{ display: "flex", gap: "8px" }}>
						{cover?.image ? (
							<div className={styles.headerWithImage}>
								<img src={cover.image} alt="laptopIcon" />
							</div>
						) : (
							<div className={styles.headerWithoutImage} />
						)}
						<div
							style={{ display: "flex", flexDirection: "column" }}
						>
							<div className={styles.title}>{display_name}</div>
							{free ? (
								<div className={styles.planPrice}>Free</div>
							) : (
								<div className={styles.planPrice}>
									{priceTagParsedTest.wholeNumber}
									<div className={styles.month}>/</div>
									<div className={styles.month}>
										{periodOfPayment}
									</div>
								</div>
							)}
						</div>
					</div>
					{description ? (
						<div className={classNames(styles.description)}>
							<ReadMore text={cleanedDescription} maxLines={40} />
						</div>
					) : null}
					<Divider style={{ margin: 0 }} />
				</div>
				{benefits?.length ? (
					<div className={styles.listedItemsContainer}>
						<div
							className={styles.listedItems}
							id="listed-items"
							style={{ ...listedItemsStyle }}
						>
							<Scrollbar
								contentProps={{
									renderer: (props) => {
										const { elementRef, ...restProps } =
											props;
										return (
											<div
												{...restProps}
												ref={elementRef}
												id="content-props"
												style={{
													minHeight: "unset",
													minWidth: "unset",
												}}
											/>
										);
									},
								}}
								wrapperProps={{
									renderer: (props) => {
										const { elementRef, ...restProps } =
											props;
										return (
											<div
												{...restProps}
												ref={elementRef}
												id="content-wrapper"
											/>
										);
									},
								}}
							>
								{benefits.map((item, index) => {
									return (
										<React.Fragment
											key={`${item}-${index}`}
										>
											<div
												className={
													styles.benefitContainer
												}
											>
												<div
													className={
														styles.checkboxContainer
													}
												>
													<CheckCircleOutlined
														width={18}
														height={18}
													/>
												</div>
												<div
													className={
														styles.benefitText
													}
												>
													{item}
												</div>
											</div>
											{/* {index !== benefits.length - 1 && (
												<div
													className={
														styles.horizontalLine
													}
												/>
											)} */}
										</React.Fragment>
									);
								})}
							</Scrollbar>
						</div>
					</div>
				) : null}
				<div className={styles.buttonContainer}>
					<CustomButton
						// className={styles.buttonStyle}
						// style={itIsCurrentMemberPlan ? { color: "yellow" } : {}}
						// type={"primary"}
						onClick={handleSelectedMemberPlan}
						disabled={itIsCurrentMemberPlan}
						loading={processChangeSubscription}
						buttonText={buttonLabel.mainLabel}
					/>

					{buttonLabel.secondaryLabel ? (
						<div className={styles.smallGreyText}>
							{buttonLabel.secondaryLabel}
						</div>
					) : (
						<div style={{ height: 20 }} />
					)}
				</div>
				{/* <div className={styles.whiteShadow} /> */}
			</div>
		</div>
	);
};

export default memo(PricingCard);
