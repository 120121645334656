import React, { memo, useEffect, useRef } from "react";
import { Card, Progress } from "antd";
import { useDispatch } from "react-redux";

import {
	getVideoForProgressbar,
	getVideos,
	notify,
	setVideoCreatedIntervalAction,
	setVideoIdAction,
	setVideoSuccessfullyUploadedAction,
	setVideoUploadProgressAction,
} from "store";

export const UploadVideoProgress = memo(
	({
		uploadProgress,
		videoId,
		videoSuccessfullyUploaded,
		createdInterval,
	}: {
		uploadProgress: number | undefined;
		videoId: number | undefined;
		videoSuccessfullyUploaded: boolean | undefined;
		createdInterval: number | undefined;
	}) => {
		const dispatch = useDispatch();
		const ref = useRef<any>(createdInterval);

		useEffect(() => {
			if (videoId && !createdInterval && uploadProgress === 100) {
				ref.current = setInterval(() => {
					if (videoId) {
						dispatch(getVideoForProgressbar({ videoId: videoId }));
					}
				}, 5000);
				dispatch(setVideoCreatedIntervalAction(ref.current));
			}
		}, [createdInterval, dispatch, uploadProgress, videoId]);

		useEffect(() => {
			if (videoSuccessfullyUploaded) {
				dispatch(setVideoUploadProgressAction(undefined));
				dispatch(setVideoIdAction(undefined));
				dispatch(setVideoSuccessfullyUploadedAction(undefined));
				dispatch(setVideoCreatedIntervalAction(undefined));
				dispatch(getVideos({ reset: true }));
				notify("success", "File upload successful");
				clearInterval(ref.current);
			}
		}, [dispatch, videoSuccessfullyUploaded]);

		return (
			<>
				{uploadProgress !== undefined ? (
					<Card style={{ marginBottom: 30, borderRadius: 10 }}>
						{uploadProgress !== 100 ? (
							<p style={{ marginBottom: 5, fontSize: 15 }}>
								Uploading... {uploadProgress}%
							</p>
						) : (
							<p style={{ marginBottom: 5, fontSize: 15 }}>
								Processing...
							</p>
						)}
						<Progress
							showInfo={false}
							status={
								uploadProgress === 100 ? "active" : "normal"
							}
							percent={uploadProgress}
						/>
					</Card>
				) : null}
			</>
		);
	},
);
