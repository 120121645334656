import { IMediaItemState, EMediaItemActionTypes, TMediaItemActions } from "models";

const initial: IMediaItemState = {
	loading: false,
	data: null
};

export function mediaItemReducer(
	state = initial,
	action: TMediaItemActions,
): IMediaItemState {
	switch (action.type) {
		case EMediaItemActionTypes.setLoading: {
			return {
				...state,
				loading: action.payload,
			};
		}
		case EMediaItemActionTypes.update: {
			return {
				...state,
				data: action.payload
			};
		}

		default:
			return state;
	}
}
