import React, { memo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { createSelector } from "reselect";

import { getOffer } from "store";
import { TStore } from "models";
import Loading from "components/Loading";
import { OfferBox } from "components";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.offer?.data,
	(offerData) => {
		return {
			offerData,
		};
	},
);

interface IOfferNotificationDetails {
	offerId: number;
	forceCommunityId: number;
}

const OfferNotificationDetails: React.FC<IOfferNotificationDetails> = ({
	offerId,
	forceCommunityId,
}) => {
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { offerData } = useSelector(stateSelector);

	useEffect(() => {
		if (offerId && forceCommunityId) {
			dispatch(
				getOffer({
					id: offerId,
					forceCommunityId,
					callbackOnSuccess: () => setLoading(false),
					callbackOnError: () => setLoading(false),
				}),
			);
		}
	}, [dispatch, offerId, forceCommunityId]);

	return loading ? (
		<Loading />
	) : offerData ? (
		<div>
			<OfferBox offer={offerData} disableFilteringByTags={true} />
		</div>
	) : null;
};

export default memo(OfferNotificationDetails);
