import { EAppActionTypes, TAppActions, IOneSignalState } from "models";

const initial: IOneSignalState = {
	initialized: false,
	id: null,
	forbidden: false,
};

export function oneSignalReducer(
	state = initial,
	action: TAppActions,
): IOneSignalState {
	switch (action.type) {
		case EAppActionTypes.appOneSignalSetInitialized: {
			return {
				...state,
				initialized: action.payload,
			};
		}
		case EAppActionTypes.appOneSignalSetId: {
			return {
				...state,
				initialized: true,
				id: action.payload,
				forbidden: false,
			};
		}
		case EAppActionTypes.appOneSignalResetId: {
			return {
				...state,
				initialized: true,
				id: null,
				// forbidden: true,
			};
		}
		default:
			return state;
	}
}
