import React, { memo } from "react";
import { Dropdown, Menu, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";

import styles from "./styles.module.scss";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface IDeleteComment {
	onDeleteComment: () => void;
	onEditComment: () => void;
}

const DeleteEditComment: React.FunctionComponent<IDeleteComment> = ({
	onDeleteComment,
	onEditComment,
}) => {
	const menu = (
		<Menu>
			<Menu.Item key="1" onClick={onEditComment}>
				{"Edit"}
			</Menu.Item>
			<Menu.Item key="2" onClick={onDeleteComment}>
				{"Delete"}
			</Menu.Item>
		</Menu>
	);
	return (
		<Tooltip title="Edit or delete comment">
			<Dropdown
				className={styles.dropDownBtn}
				trigger={["click"]}
				overlay={menu}
				placement="bottomCenter"
			>
				<div>
					<FontAwesomeIcon
						icon={faEllipsisH as unknown as IconProp}
						color={"#cdcdcd"}
						style={{
							display: "block",
							fontSize: 17,
						}}
					/>
				</div>
			</Dropdown>
		</Tooltip>
	);
};

export default memo(DeleteEditComment);
