import { memo, useCallback, useEffect, useLayoutEffect, useMemo } from "react";
import { useDispatch, useSelector} from "react-redux";

import { useMatch } from "react-router-dom";

import {  HelmetPageTitle, InvitesEmptyState } from "components";
import styles from "./styles.module.scss";
import {
	setSelectedCommunityToJoinMemberType,
	setSelectedCommunityToJoinTags,
	setSelectedCommunityToJoinSkills,
	initSelectedCommunityToJoinProgressBar,
	getCommunitiesJoined,
	getCommunitiesInvitations,
} from "store";
import { ETypeOfCommunities, ICommunitiesJoinedState, ICommunitiesState, TStore } from "models";
import { ActiveCommunities, AllCommunities } from "./components";
import { MainLayout, CommunitiesLayout } from "layouts";
import CommunitySearch from "layouts/components/communitySearch";
import { settingDefaultPalleteOfColors } from "utils";
import { createSelector } from "reselect";
import CommunityEmptyState from "components/CommunityEmptyState";
import InvitesList from "./components/invitesList";

const stateSelectorHandle = createSelector(
    (state: TStore) => state.auth.deepLink,
    (state: TStore) => state.communities,
    (state: TStore) => state.communitiesJoined.searchQuery,
    (state: TStore) => state.communitiesJoined,
    (
        deepLink: any,
        communities: ICommunitiesState,
        communitiesSearchQuery,
        communitiesJoined: ICommunitiesJoinedState,
    ) => ({
        deepLink,
        communities,
        communitiesSearchQuery,
        communitiesJoined
    }),
);

const Communities = () => {
	const dispatch = useDispatch();



	let matchJoinedCommunities = useMatch({
		path: "/communities",
	});
	let matchAllCommunities = useMatch({
		path: "/communities/all",
	});

	let matchInvites = useMatch({
		path: "/communities/invites",
	});

	const stateSelector = useCallback(stateSelectorHandle, []);

	const { communities, deepLink, communitiesSearchQuery ,communitiesJoined} =
		useSelector(stateSelector);

	const resultedInvites = useMemo(() => {
		if (
			(!communities.meta?.invites ||
				communities.meta?.invites?.length === 0) &&
			!deepLink
		) {
			return [];
		} else {
			const invites = communities.meta?.invites?.filter(
				(item) =>
					!item.approved &&
					item.name
						.toLowerCase()
						.includes(communitiesSearchQuery.toLowerCase()),
			);
			let invitesAndDeepLinkData: any = [];
			if (deepLink) {
				invitesAndDeepLinkData.push(deepLink);
			}
			if (invites && invites.length > 0) {
				invitesAndDeepLinkData = invitesAndDeepLinkData.concat(invites);
			}
			return [...invitesAndDeepLinkData];
		}
	}, [communities.meta.invites, communitiesSearchQuery, deepLink]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		// clean up the selectedCommunityToJoin data
		dispatch(
			setSelectedCommunityToJoinTags({
				tags: [],
			}),
		);
		dispatch(
			setSelectedCommunityToJoinSkills({
				skills: [],
			}),
		);
		dispatch(
			setSelectedCommunityToJoinMemberType({
				memberType: "",
			}),
		);
		dispatch(
			initSelectedCommunityToJoinProgressBar({
				resetData: true,
			}),
		);

		dispatch(getCommunitiesJoined());

		dispatch(getCommunitiesInvitations());


	}, [dispatch]);


	useLayoutEffect(() => {
		settingDefaultPalleteOfColors();
	}, []);

	return (
		<>
			<HelmetPageTitle screenTitle={"Communities"} />
			<MainLayout>
				<CommunitiesLayout>
					{!matchInvites &&
						((matchJoinedCommunities &&
							(communitiesJoined?.data?.length ||
								resultedInvites?.length)) ||
						matchAllCommunities ? (
							<section
								id="communities-wrapper"
								className={styles.communitiesWrapper}
							>
								<section style={{ padding: "10px" }}>
									<section className={styles.header}>
										<div className={styles.title}>
											{matchJoinedCommunities
												? "My Communities"
												: "All communities"}
										</div>
										<div className={styles.subTitle}>
											{matchJoinedCommunities
												? "Visit and manage your communities below."
												: "Here is all our communities, choose one or more and start together engaging discussions, AI-powered matchmaking, curated newsfeeds, memberships and events all inside your custom branded community."}
										</div>
									</section>

									{matchJoinedCommunities && (
										<ActiveCommunities />
									)}

									{matchAllCommunities && (
										<>
											<div
												className={
													styles.fullWidthInputField
												}
											>
												<CommunitySearch
													communitiesCategory={
														matchJoinedCommunities
															? ETypeOfCommunities.Joined
															: ETypeOfCommunities.All
													}
												/>
											</div>{" "}
											<AllCommunities />
										</>
									)}
								</section>
							</section>
						) : (
							!communitiesJoined.loading && (
								<CommunityEmptyState />
							)
						))}

					{matchInvites &&
						(resultedInvites?.length ? (
							<section
								id="communities-wrapper"
								className={styles.communitiesWrapper}
							>
								<section style={{ padding: "10px" }}>
									<section className={styles.header}>
										<div className={styles.title}>
											Invites
										</div>
										<div className={styles.subTitle}>
											Here is your invites to communities,
											choose one or more and start
											together engaging discussions,
											AI-powered matchmaking, curated
											newsfeeds, memberships and eventsall
											inside your custom branded
											community.
										</div>
									</section>

									<InvitesList
										invites={resultedInvites}
										isLoading={communities?.loading}
									/>
								</section>
							</section>
						) : (
							<InvitesEmptyState />
						))}
				</CommunitiesLayout>
			</MainLayout>
		</>
	);
};

export default memo(Communities);
