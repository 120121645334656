import { call, put, all, takeEvery, select } from "redux-saga/effects";

import {
	EOffersActionTypes,
	TStore,
	IOffersIncrementWebsiteViewsAction,
	IOffersIncrementRedeemByEmailCounterAction,
	IOffersIncrementRedeemByLinkCounterAction,
} from "models";
import {
	api,
	setOffersLoading,
	setOffers,
	setOffersFilterTagsInStoreAction,
	setOffersQueryAction,
} from "store";
import {
	getOffers,
	incrementWebsiteViewsAction,
	incrementRedeemByEmailAction,
	incrementRedeemByLinkAction,
} from "store/actions";
import { rollbar } from "utils";

const getCommunityId = (state: TStore) => state.app.currentCommunityId;
const getGroupId = (state: TStore) => state.app.currentGroupId;
const getCommunityTags = (state: TStore) => state.community.mainTags.tags;
const getSelectedTags = (state: TStore) => state.offers.selectedTags;
const getQuery = (state: TStore) => state.offers.query;
const getOffersData = (state: TStore) => state.offers.data;
const getOffersMeta = (state: TStore) => state.offers.meta;

function* getOffersHandle({ payload }: ReturnType<typeof getOffers>) {
	yield put(setOffersLoading(true));
	try {
		let page: number;
		const communityId = yield select(getCommunityId);
		const groupId = yield select(getGroupId);
		const selectedTags = yield select(getSelectedTags);
		const q = yield select(getQuery);
		const offersData = yield select(getOffersData);
		const offersMeta = yield select(getOffersMeta);

		if (payload?.reset || offersData?.length === 0 || !offersData) {
			page = 1;
		} else if (offersMeta?.pagination?.has_next_page) {
			page = parseInt(offersMeta?.pagination?.current_page) + 1;
		} else {
			return;
		}

		const { data } = yield call(api.offers.get, {
			communityId,
			groupId,
			tag_clicked: !!selectedTags.length,
			q: !!selectedTags.length ? selectedTags[0] : q,
			page,
		});
		if (!data) {
			yield put(setOffersLoading(false));
		} else {
			yield put(setOffers({ data, reset: payload?.reset }));
			yield put(setOffersLoading(false));
		}
	} catch (error) {
		rollbar.error(
			error
		)
		console.error({ error });
		yield put(setOffersLoading(false));
	}
}

// TODO: remove all the functions that does the same thing like this function and store tags in store without using saga
function* setFilterTagsHandle({ payload }: any) {
	const tags: {
		id: number;
		name: string;
		is_selected: boolean;
	}[] = yield select(getCommunityTags);

	const parsedTags = tags
		.filter((item) => {
			return payload.tag.find((element: string) => element === item.name);
		})
		.map((item) => item.name);
	yield put(setOffersFilterTagsInStoreAction({ parsedTags }));
}

function* handleSetQueryAndFilterTags({ payload }: any) {
	try {
		yield put(setOffersQueryAction({ query: payload }));
		yield put(getOffers({ reset: true }));
	} catch (error) {
		rollbar.error(
			error
		)
		console.error({ error });
	}
}
function* incrementWebsiteViewsHandle({
	payload,
}: IOffersIncrementWebsiteViewsAction) {
	try {
		yield put(incrementWebsiteViewsAction({ offerId: 0 }));
	} catch (error) {
		rollbar.error(
			error
		)
		// console.error({ error });
	}
}
function* incrementRedeemByEmailHandle({
	payload,
}: IOffersIncrementRedeemByEmailCounterAction) {
	try {
		yield put(incrementRedeemByEmailAction({ offerId: 0 }));
	} catch (error) {
		rollbar.error(
			error
		)
		// console.error({ error });
	}
}
function* incrementRedeemByLinkHandle({
	payload,
}: IOffersIncrementRedeemByLinkCounterAction) {
	try {
		yield put(incrementRedeemByLinkAction({ offerId: 0 }));
	} catch (error) {
		rollbar.error(error)
		// console.error({ error });
	}
}

export default function* rootSaga() {
	yield all([
		takeEvery(EOffersActionTypes.getOffers, getOffersHandle),
		takeEvery(
			EOffersActionTypes.setQueryAndFilter,
			handleSetQueryAndFilterTags,
		),
		takeEvery(EOffersActionTypes.setFilterTags, setFilterTagsHandle),
		takeEvery(
			EOffersActionTypes.incrementWebsiteViews,
			incrementWebsiteViewsHandle,
		),
		takeEvery(
			EOffersActionTypes.incrementRedeemByEmailCounter,
			incrementRedeemByEmailHandle,
		),
		takeEvery(
			EOffersActionTypes.incrementRedeemByLinkCounter,
			incrementRedeemByLinkHandle,
		),
	]);
}
