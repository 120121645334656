import React, {
	memo,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";

import heartIco from "assets/images/icons/common_icons/heart_ico.png";
import styles from "./styles.module.scss";
import { timeSince, UseShowDescriptionWithMentions } from "utils";
import { DeleteEditComment } from "./components";
import { TStore } from "models";
import { deletePostComment, notify } from "store";
import { CommentInput } from "components";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.auth.credentials?.user,
	(authenticatedUser) => ({
		authenticatedUser,
	}),
);

const checkTimestampForSecondsOrMinutes = new RegExp(
	"(\\d+)\\s(seconds?|minutes?)\\sago",
	"i",
);
interface ICommentBox {
	commentId: number;
	postId: number;
	avatar: any;
	boldName: string;
	text: string;
	time: string;
	lastCommentBox: boolean;
	likes?: any;
	tags?: any;
	user: { id: number; name: string };
	mentions: string[];
}

const CommentBox: React.FunctionComponent<ICommentBox> = ({
	avatar,
	likes,
	boldName,
	tags,
	text,
	time,
	lastCommentBox = false,
	user,
	commentId,
	postId,
	mentions,
}) => {
	const dispatch = useDispatch();
	const [enableEdit, setEnableEdit] = useState(false);
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { authenticatedUser } = useSelector(stateSelector);
	const navigate = useNavigate();
	const [commentTimestamp, setCommentTimestamp] = useState<string>("");
	const autoUpdateCommentTimestampIntervalId = useRef<
		NodeJS.Timer | undefined
	>(undefined);
	const parsedTimestamp: string = useMemo(() => {
		return timeSince(time);
	}, [time]);

	const handleDeleteComment = useCallback(() => {
		if (window.confirm("Are you sure you want to delete this comment?")) {
			dispatch(deletePostComment({ postId, commentId }));
		}
	}, [dispatch, postId, commentId]);

	const handleEditComment = useCallback(() => {
		setEnableEdit(!enableEdit);
	}, [enableEdit]);

	const handleEscapeKeyOnEdit = () => {
		setEnableEdit(false);
	};

	const handleCompleteEdit = () => {
		setEnableEdit(false);
	};

	const goToCommentAuthorProfilePage = useCallback(() => {
		if (!user) {
			notify("error", "An error occurred. Please try again later.");
			return;
		}
		navigate("/members/" + user.id);
	}, [navigate, user]);

	const updateTheCommentTimestamp = useCallback((timestamp: string) => {
		if (!timestamp) return;

		const instances = checkTimestampForSecondsOrMinutes.exec(timestamp);
		if (!instances) {
			setCommentTimestamp(timestamp);
			return;
		}

		if (instances[2].toLowerCase().indexOf("second") !== -1) {
			setCommentTimestamp("< 1 minute ago");
		} else {
			setCommentTimestamp(`${instances["input"]}`);
		}
		const newIntervalId = setInterval(() => {
			setCommentTimestamp((prevState) => {
				const instances =
					checkTimestampForSecondsOrMinutes.exec(prevState);

				if (!instances) return prevState;

				if (instances["input"] === "< 1 minute ago") {
					return "1 minute ago";
				} else {
					let minutes: number = parseInt(instances[1], 10) + 1;
					if (minutes === 60) {
						if (autoUpdateCommentTimestampIntervalId.current) {
							clearInterval(
								autoUpdateCommentTimestampIntervalId.current as any,
							);
							autoUpdateCommentTimestampIntervalId.current =
								undefined;
						}
						return "1 hour ago";
					} else {
						return `${minutes} minutes ago`;
					}
				}
			});
		}, 60000);
		autoUpdateCommentTimestampIntervalId.current = newIntervalId;
	}, []);

	useEffect(() => {
		if (!time) return;
		updateTheCommentTimestamp(parsedTimestamp);
	}, [updateTheCommentTimestamp, parsedTimestamp, time]);

	return (
		<div
			className={classNames(styles.commentBox, {
				[styles.lastCommentBox]: lastCommentBox,
			})}
		>
			<div className={styles.avatarSection}>
				<div
					className={styles.avatar}
					onClick={goToCommentAuthorProfilePage}
					style={{ cursor: "pointer" }}
				>
					{avatar}
				</div>
				{likes && (
					<a href="#!" className={styles.likes}>
						<img src={heartIco} alt="" />
						{likes}
					</a>
				)}
			</div>
			<div className={styles.commentInfo}>
				{boldName && commentTimestamp && (
					<div className={styles.inlineElements}>
						<h3
							onClick={goToCommentAuthorProfilePage}
							className={styles.commentAuthor}
						>
							{boldName}
						</h3>
						<div className={styles.commentTime}>
							{commentTimestamp}
						</div>
						<div className={styles.textAndBtnContainer}>
							{authenticatedUser?.id === user.id && (
								<DeleteEditComment
									onDeleteComment={handleDeleteComment}
									onEditComment={handleEditComment}
								/>
							)}
						</div>
					</div>
				)}
				{tags && (
					<span className={styles.primaryColorTags}>{tags}</span>
				)}
				{!enableEdit ? (
					<div className={styles.textWithEnters}>
						<UseShowDescriptionWithMentions
							text={text}
							mentions={mentions}
						/>
					</div>
				) : (
					<CommentInput
						autoFocus={true}
						postId={postId}
						commentId={commentId}
						text={text}
						handleEscapeKeyOnEdit={handleEscapeKeyOnEdit}
						handleDeleteComment={handleDeleteComment}
						handleCompleteEdit={handleCompleteEdit}
					/>
				)}
			</div>
		</div>
	);
};

export default memo(CommentBox);
