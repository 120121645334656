import { EPostActionTypes, TPostActions, IPostCommentsState } from "models";

const initial: IPostCommentsState = {};

export function commentsReducer(
	state = initial,
	action: TPostActions,
): IPostCommentsState {
	switch (action.type) {
		case EPostActionTypes.postCommentsSetLoading: {
			return {
				...state,
				[action.payload.postId]: {
					...state[action.payload.postId],
					loading: action.payload.loading,
				},
			};
		}
		case EPostActionTypes.addLocalPostComments: {
			return {
				...state,
				[action.payload.postId]: {
					...state[action.payload.postId],
					data: [...action.payload.data.comments],
					meta: action.payload.data.meta,
				},
			};
		}
		case EPostActionTypes.appendLocalPostComments: {
			let newState = {
				...state[action.payload.postId],
				data: state[action.payload.postId]
					? action.payload.data.comments.concat(
							state[action.payload.postId].data,
					  )
					: [...action.payload.data.comments],
			};
			if (action.payload.data.meta)
				newState.meta = action.payload.data.meta;
			else {
				newState.meta = {
					...state[action.payload.postId].meta,
					pagination: {
						...state[action.payload.postId].meta.pagination,
						total_objects:
							state[action.payload.postId].meta.pagination
								.total_objects + 1,
					},
				};
			}
			return {
				...state,
				[action.payload.postId]: newState,
			};
		}
		case EPostActionTypes.updateLocalPostComments: {
			let newState = {
				...state[action.payload.postId],
				data: state[action.payload.postId]
					? state[action.payload.postId].data.concat(
							action.payload.data.comments,
					  )
					: [...action.payload.data.comments],
			};
			if (action.payload.data.meta)
				newState.meta = action.payload.data.meta;
			else {
				newState.meta = {
					...state[action.payload.postId].meta,
					pagination: {
						...state[action.payload.postId].meta.pagination,
						total_objects:
							state[action.payload.postId].meta.pagination
								.total_objects + 1,
					},
				};
			}
			return {
				...state,
				[action.payload.postId]: newState,
			};
		}
		case EPostActionTypes.deleteLocalPostComment: {
			const { commentId, postId } = action.payload;
			if (postId && commentId) {
				const post = state[postId];
				const { total_objects, per_page } = post.meta.pagination;
				let newCommentsData: any = [];
				newCommentsData = post.data.filter(
					(comment: { id: number }) => {
						if (comment.id !== commentId) {
							return true;
						} else {
							return false;
						}
					},
				);

				const newCommentsMeta = {
					...post.meta,
					label: `Comments ${newCommentsData.length}`,
					pagination: {
						...post.meta.pagination,
						current_page: Math.ceil(
							newCommentsData.length / per_page,
						),
						has_next_page:
							total_objects - 1 > newCommentsData.length
								? true
								: false,
						total_objects: total_objects - 1,
						total_pages: Math.ceil((total_objects - 1) / per_page),
					},
				};
				const newState = {
					...state,
					[postId]: {
						...state[postId],
						data: newCommentsData,
						meta: {
							...state[postId].meta,
							...newCommentsMeta,
						},
					},
				};
				return {
					...newState,
				};
			} else {
				return { ...state };
			}
		}
		case EPostActionTypes.editLocalPostComment: {
			const { commentId, postId, content } = action.payload;
			if (postId && commentId && content) {
				const post = state[postId];
				const editedCommentIndex = post.data.findIndex(
					(item: {
						id: number;
						content: string;
						[key: string]: any;
					}) => item.id === commentId,
				);

				if (editedCommentIndex !== -1) {
					const newState = {
						...state,
						[postId]: {
							...state[postId],
							data: [...state[postId].data],
						},
					};

					newState[postId].data[editedCommentIndex].content = content;

					return {
						...newState,
					};
				} else {
					return {
						...state,
					};
				}
			} else {
				return { ...state };
			}
		}
		default:
			return state;
	}
}
