import { axiosInstance } from "..";

export const viewCounts = {
	addViewToPost: (communityId: number, postId: number) =>
		axiosInstance().post(
			`/communities/${communityId}/community-posts/${postId}/view`,
		),
	addViewToVideo: (communityId: number, videoId: number) =>
		axiosInstance().post(
			`/communities/${communityId}/community_documents/${videoId}/view`,
		),
	addViewToDoc: (communityId: number, docId: number) =>
		axiosInstance().post(
			`/communities/${communityId}/community_documents/${docId}/view`,
		),
	addViewToOffer: (communityId: number, offerId: number) =>
		axiosInstance().post(
			`/communities/${communityId}/offers/${offerId}/view`,
		),
};
