import axios, { AxiosRequestConfig } from "axios";

import {
    //   BACKEND_URIS,
    LocalStorageKey,
    LocalStorageKeyCustomToken,
} from "utils";
import { auth } from "./auth";
import { communities } from "./communities";
import { communitiesJoined } from "./communitiesJoined";
import { community } from "./community";
import { docs } from "./docs";
import { events } from "./events";
import { groups } from "./groups";
import { marketplaceOffer } from "./marketplaceOffer";
import { marketplaceOffers } from "./marketplaceOffers";
import { member } from "./member";
import { members } from "./members";
import { messages } from "./messages";
import { offers } from "./offers";
import { posts } from "./posts";
import { profile } from "./profile";
import { userNotifications } from "./userNotifications";
import { videos } from "./videos";
import { resources } from "./resources";
import { viewCounts } from "./viewCounts";
import { payments } from "./payments";
import { subscription } from "./subscription";

export const BACKEND_URIS: { [key: string]: string } = {
    local: "http://127.0.0.1:3000/api/v1",
    development: "https://dashboard.ugenie.io/api/v1",
    staging: "https://staging-api.ugenie.io/api/v1",
    production: "https://production.ugenie.io/api/v1",
};

const defaultOptions = {
    baseURL: BACKEND_URIS[process.env.REACT_APP_ENVIRONMENT || "development"],
    headers: {
        "Content-Type": "application/json",
        "X-UHUBS-PLATFORM": "web",
        "X-UHUBS-PLATFORM-VERSION": process.env.REACT_APP_APP_VERSION as string,
    }
    // withCredentials: true
};

// Create instance
let instance = axios.create(defaultOptions);
let interceptor: number | null = null;
export const updateToken = (token: string) => {
    if (token) localStorage.setItem(LocalStorageKey, token);
    if (interceptor) instance.interceptors.request.eject(interceptor);
    interceptor = instance.interceptors.request.use(function (
        config: AxiosRequestConfig<any>,
    ) {
        if (!config) {
            return;
        } else {
            // At this, typescript erroneously thinks that config might be undefined
            // @ts-ignore
            config.headers["Authorization"] = token ?? "";
            return config;
        }
    });
};

export const updateHeaders = (headers: { [key: string]: string }[]) => {

    if (interceptor) instance.interceptors.request.eject(interceptor);
    interceptor = instance.interceptors.request.use(function (
        config: AxiosRequestConfig<any>,
    ) {
        if (!config) {
            return;
        } else {
            headers.forEach((header) => {
                // @ts-ignore
                config.headers[header.name.toString()] = header.value || '';
            })
            return config;
        }
    });
}

export const clearToken = () => {
    localStorage.removeItem(LocalStorageKeyCustomToken);
    localStorage.removeItem(LocalStorageKey);
    instance = axios.create(defaultOptions);
};

// if (responseInterceptor)
//   instance.interceptors.response.eject(responseInterceptor);

// Add a response interceptor
axios.interceptors.response.use(
    function (response) {
        // Do something with the response
        return response;
    },
    function (error) {
        // try {
        //   const response = JSON.parse(
        //     String.fromCharCode.apply(
        //       null,
        //       // @ts-ignore
        //       new Uint8Array(error.response.data)
        //     )
        //   );
        //   if (response && response.errors === "Unauthorized access!") {
        //     history.push("/auth/sign-out");
        //   }
        // } catch (e) {}
        return Promise.reject(error);
    },
);

export const axiosInstance = () => instance;

export const api = {
    auth,
    communities,
    community,
    groups,
    profile,
    posts,
    marketplaceOffers,
    marketplaceOffer,
    events,
    communitiesJoined,
    member,
    members,
    messages,
    docs,
    videos,
    resources,
    offers,
    userNotifications,
    viewCounts,
    payments,
    subscription,
};
export { notify } from "./notify";
