import React, { useEffect, useState } from "react";
import axios from "axios";

import PreviewLinkCard from "../previewLinkCard";
import { Loading } from "components";

interface IPreviewPostLink {
	postUrls: string[];
}

const PreviewPostLink: React.FunctionComponent<IPreviewPostLink> = ({
	postUrls,
}) => {
	const [previewLink, setPreviewLink] = useState<null | {
		originalUrl: string;
		url: string;
		images: string[];
		title: string;
		description: string;
	}>(null);
	const [fetchPreviewLinkLoading, setFetchPreviewLinkLoading] =
		useState(true);

	useEffect(() => {
		if (postUrls && postUrls.length > 0 && fetchPreviewLinkLoading) {
			const api =
				"https://g7mpxopqn3.execute-api.eu-west-2.amazonaws.com/staging";
			const data = {
				urls: [postUrls[0]], // we create a preview card only for the first link
			};
			axios
				.post(api, { body: data })
				.then((response) => {
					if (response?.data?.body?.message?.length > 0) {
						setPreviewLink(response.data?.body?.message[0]);
						setFetchPreviewLinkLoading(false);
					}
				})
				.catch((error) => {
					setFetchPreviewLinkLoading(false);
					console.error(error);
				});
		}
	}, [postUrls, fetchPreviewLinkLoading]);

	return (
		<>
			{fetchPreviewLinkLoading ? (
				<Loading />
			) : previewLink?.originalUrl != null ? (
				<PreviewLinkCard
					originalUrl={previewLink.originalUrl}
					url={previewLink.url}
					image={previewLink.images[0]}
					title={previewLink.title}
					description={previewLink.description}
				/>
			) : null}
		</>
	);
};

export default PreviewPostLink;
