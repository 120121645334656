import React, { memo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import classNames from "classnames";

import styles from "./styles.module.scss";
import { MainLayout } from "layouts";
import { HelmetPageTitle, FilterTag } from "components";
import { ListItems, MainForm } from "./components";
import { TStore } from "models";
import {
	getMarketplaceOffers,
	setFilterTagsMarketplaceOffers,
	setMarketplaceOffersQuery,
} from "store";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.marketplaceOffers,
	(state: TStore) => state.auth.credentials,
	(state: TStore) => state.app.currentCommunityId,
	(state: TStore) =>
		state.posts?.meta?.appearance?.marketplace_display_name?.plural,
	(
		marketplaceOffers,
		credentials,
		currentCommunityId,
		marketplaceOffersDisplayName,
	) => ({
		marketplaceOffers,
		credentials,
		currentCommunityId,
		marketplaceOffersDisplayName,
	}),
);

const Marketplace = () => {
	const { section }: { section?: "add" } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const stateSelector = useCallback(stateSelectorHandle, []);
	const {
		marketplaceOffers,
		credentials,
		currentCommunityId,
		marketplaceOffersDisplayName,
	} = useSelector(stateSelector);
	const { selectedTags, tags, query } = marketplaceOffers;

	const handleSwitchScreen = useCallback(() => {
		if (!section) {
			navigate("/marketplace/add");
		} else {
			navigate("/marketplace");
		}
	}, [section, navigate]);

	const handleFilterTagsPosts = useCallback(
		(tags: CheckboxValueType[]) => {

			let newTag: CheckboxValueType[] = [];
			if (tags?.length) {
				newTag = tags.filter(
					(item: any) => selectedTags && !selectedTags.includes(item),
				);
			}

			dispatch(
				setFilterTagsMarketplaceOffers({ tags: newTag as string[] }),
			);
		},
		[dispatch, selectedTags],
	);

	const createAPost = useCallback(() => {
		if (!section) {
			navigate("/marketplace/add", { state: "Sell" });
		} else {
			navigate("/marketplace");
		}
	}, [section, navigate]);

	useEffect(() => {
		if (currentCommunityId)
			dispatch(
				getMarketplaceOffers({
					reset: true,
				}),
			);
	}, [dispatch, credentials, query, selectedTags, currentCommunityId]);

	useEffect(() => {
		return () => {
			dispatch(setMarketplaceOffersQuery({ query: "" }));
		};
	}, [dispatch]);

	return (
		<>
			<HelmetPageTitle
				screenTitle={
					marketplaceOffersDisplayName
						? marketplaceOffersDisplayName
						: "Marketplace"
				}
			/>
			<MainLayout>
				{!marketplaceOffers.pins ? null : (
					<>
						<div className={styles.container}>
							{!section ? (
								<div className={styles.flexColumn}>
									<div
										className={classNames(
											(selectedTags &&
												selectedTags.length > 0) ||
												query
												? styles.flexRowMobile
												: styles.flexRow,
											styles.whiteAndRoundedBackground,
										)}
									>
										<div
											className={classNames(
												((selectedTags &&
													selectedTags.length > 0) ||
													query) &&
													styles.btnFullWidthMobile,
												styles.btnFullWidth,
											)}
										>
											<Button onClick={createAPost}>
												{"Add Post"}
											</Button>
										</div>
										<div className={styles.filterSection}>
											<FilterTag
												onClick={handleFilterTagsPosts}
												tagsList={tags}
												selectedTags={selectedTags}
												query={query}
											/>
										</div>
									</div>
									<ListItems />
								</div>
							) : (
								<div className={styles.flexColumn}>
									<MainForm
										handleSwitchScreen={handleSwitchScreen}
									/>
								</div>
							)}
						</div>
					</>
				)}
			</MainLayout>
		</>
	);
};

export default memo(Marketplace);
