import React, { memo, useCallback, useEffect, useMemo } from "react";
import { TStore } from "models";
import { createSelector } from "reselect";
import classNames from "classnames";
import Scrollbar from "react-scrollbars-custom";
import InfiniteScroll from "react-infinite-scroller";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import styles from "./styles.module.scss";
import {
	appChangeCommunity,
	appSiderMenuSetShowJoinedCommunitiesList,
	getCommunitiesJoined,
	getMoreCommunitiesJoined,
} from "store";
import { GetImg } from "utils";
import { Loading } from "components";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.communitiesJoined,
	(state: TStore) => state.app.currentCommunityId,
	(communitiesJoined, currentCommunityId) => {
		return { communitiesJoined, currentCommunityId };
	},
);

const SiderJoinedCommunitiesList = () => {
	const stateSelector = useCallback(stateSelectorHandle, []);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { communitiesJoined, currentCommunityId } =
		useSelector(stateSelector);

	const whitelistSrc = [
		"https://dashboard.ugenie.io/profile-fallback-thumb.png",
	];

	const communitiesJoinedFiltered = useMemo((): any[] => {
		if (!communitiesJoined?.data || communitiesJoined?.data?.length === 0) {
			return [];
		} else {
			return communitiesJoined.data.filter(
				(item) => item.approved || item.privacy === "open",
			);
		}
	}, [communitiesJoined.data]);

	const handleChangeCommunity = useCallback(
		(communityId: number) => () => {
			dispatch(
				appChangeCommunity({
					communityId,
					onSuccessCallback: () => {
						dispatch(
							appSiderMenuSetShowJoinedCommunitiesList(false),
						);
					},
				}),
			);
		},
		[dispatch],
	);

	const goToCommunities = useCallback(() => {
		navigate("/communities/all");
	}, [navigate]);

	const handleLoadMore = useCallback(() => {
		if (
			communitiesJoined?.meta?.pagination?.has_next_page &&
			!communitiesJoined?.loading
		) {
			dispatch(getMoreCommunitiesJoined());
		}
		return null;
	}, [
		communitiesJoined?.loading,
		communitiesJoined?.meta?.pagination?.has_next_page,
		dispatch,
	]);

	useEffect(() => {
		dispatch(getCommunitiesJoined({ q: communitiesJoined.searchQuery }));
	}, [communitiesJoined.searchQuery, dispatch]);

	return communitiesJoined.loading ? (
		<Loading />
	) : (
		<div className={styles.menuList}>
			<Scrollbar
				scrollerProps={{
					renderer: (props) => {
						const { elementRef, ...restProps } = props;
						return (
							<div
								id="sideMenuJoinedCommunitiesList"
								{...restProps}
								ref={elementRef}
							/>
						);
					},
				}}
			>
				<InfiniteScroll
					pageStart={1}
					loadMore={handleLoadMore}
					hasMore={communitiesJoined?.meta?.pagination?.has_next_page}
					initialLoad={true} // in order to load data if component still isn't scrollable
					useWindow={false}
					loader={
						<div
							key={0}
							style={{
								padding: "12px 8px",
								flexGrow: 1,
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<Loading />
						</div>
					}
					getScrollParent={() =>
						document.getElementById("sideMenuJoinedCommunitiesList")
					}
					style={{
						display: "flex",
						flex: 1,
						flexDirection: "column",
					}}
				>
					{communitiesJoinedFiltered.map((item, index: number) => {
						return (
							<div
								key={`community_${index}`}
								className={classNames(styles.item, {
									[styles.itemActive]:
										item.id === currentCommunityId,
								})}
								onClick={handleChangeCommunity(item.id)}
							>
								<div className={styles.info}>
									<div className={styles.name}>
										{item.name}
									</div>
									<div className={styles.avatar}>
										{whitelistSrc.indexOf(item.picture) ===
										-1 ? (
											<GetImg endpoint={item.picture} />
										) : (
											<img src={item.picture} alt="" />
										)}
									</div>
								</div>
								{item.id === currentCommunityId && (
									<div className={styles.activeCommunity}>
										<div>
											{"current selected community"}
										</div>
									</div>
								)}
							</div>
						);
					})}
				</InfiniteScroll>
				<div style={{ display: "flex" }} onClick={goToCommunities}>
					<Button className={styles.joinMore}>{"Join More"}</Button>
				</div>
			</Scrollbar>
		</div>
	);
};

export default memo(SiderJoinedCommunitiesList);
