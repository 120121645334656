import React from "react";
import { Modal } from "antd";

import CustomButton from "../Button";

import { ReactComponent as ThuumbsUpIcon } from "assets/images/thumbs-up.svg";
import styles from "./styles.module.scss";

interface ICommunityWelcomeModal {
	isOpen?: boolean;
	onClose?: () => void;
	mainButtonHandler?: () => void;
}

const CommunityWelcomeModal: React.FunctionComponent<
	ICommunityWelcomeModal
> = ({ isOpen, onClose, mainButtonHandler }) => {
	return (
		<>
			<Modal
				open={isOpen}
				title={false}
				closable
				footer={false}
				onCancel={onClose}
				className={styles.welcomeModal}
			>
				<div className={styles.confirmationModalContainer}>
					<ThuumbsUpIcon />
					<div className={styles.textContainer}>
						<div className={styles.heading}>
							Welcome to our community!
						</div>

						<div className={styles.subHeading}>
							We're so excited to have you as part of community.
						</div>
					</div>
					<CustomButton varient="solid" onClick={mainButtonHandler}>
						Start Now
					</CustomButton>
				</div>
			</Modal>
		</>
	);
};

export default React.memo(CommunityWelcomeModal);
