import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import {
	ECommunitiesActionTypes,
	ECommunityAccessStatus,
	ICommunitiesState,
	ICommunityMemberPlan,
	ISelectedCommunityToJoin,
} from "models";

export const setCommunitiesLoading = (payload: boolean) => ({
	type: ECommunitiesActionTypes.communitiesLoadingSet,
	payload,
});

export const getCommunities = ({
	q,
	slug,
	onSuccessCallback,
	onErrorCallback,
}: {
	q?: string;
	slug?: string;
	onSuccessCallback?: () => void;
	onErrorCallback?: () => void;
} = {}) => ({
	type: ECommunitiesActionTypes.getCommunities,
	q,
	slug,
	onErrorCallback,
	onSuccessCallback,
});

export const getMoreCommunities = (payload?: any) => ({
	type: ECommunitiesActionTypes.getMoreCommunities,
	payload,
});

export const setCommunities = ({
	init,
	communities,
	meta,
}: {
	communities: ICommunitiesState["data"];
	meta: ICommunitiesState["meta"];
	init?: boolean;
}) => ({
	type: ECommunitiesActionTypes.setCommunities,
	payload: { init, communities, meta },
});

export const joinCommunity = (payload: {
	id: number;
	communityInvitationMemberType?: string;
	slug?: string;
	onSuccessCallback?: () => void;
	onErrorCallback?: () => void;
}) => ({
	type: ECommunitiesActionTypes.joinCommunity,
	payload,
});

export const leaveCommunity = (payload: {
	communityId: number;
	onErrorCallback: () => void;
	onSuccessCallback?: () => void;
}) => ({
	type: ECommunitiesActionTypes.leaveCommunity,
	payload,
});

export const setSelectedCommunityToJoinDetails = (payload: {
	data: any;
	communityId: number | null;
}) => ({
	type: ECommunitiesActionTypes.setSelectedCommunityToJoinDetails,
	payload,
});

export const storeTagsAndMemberType = (payload: {
	communityId: number;
	tags: string[];
	membershipType: string;
	successCallback: () => void;
	errorCallback: () => void;
	skill_tags?: string[];
	stripePlanPriceId?: string | null; // if the community is closed, send the stripePlanPriceId value to be cached inside the params
}) => ({
	type: ECommunitiesActionTypes.storeTagsAndMemberType,
	payload,
});

export const setSelectedCommunityToJoinTags = (payload: {
	tags: string[];
}) => ({
	type: ECommunitiesActionTypes.setSelectedCommunityToJoinTags,
	payload,
});

export const setSelectedCommunityToJoinMemberType = (payload: {
	memberType: string;
}) => ({
	type: ECommunitiesActionTypes.setSelectedCommunityToJoinMemberType,
	payload,
});

export const setSelectedCommunityToJoinSkills = (payload: {
	skills: string[];
}) => ({
	type: ECommunitiesActionTypes.setSelectedCommunityToJoinSkills,
	payload,
});

export const getSelectedCommunityToJoinAccessStatus = (payload: {
	communityId: number;
	successCallback: () => void;
	errorCallback: () => void;
}) => ({
	type: ECommunitiesActionTypes.getSelectedCommunityToJoinAccessStatus,
	payload,
});

export const setCommunityAccessStatus = (payload: {
	accessStatus: ECommunityAccessStatus | null;
}) => ({
	type: ECommunitiesActionTypes.setCommunityAccessStatus,
	payload,
});

export const setSelectedCommunityToJoinMemberPlans = ({
	plans,
}: {
	plans: ICommunityMemberPlan[] | null;
}) => ({
	type: ECommunitiesActionTypes.setSelectedCommunityToJoinMemberPlans,
	payload: { plans },
});

export const setSelectedCommunityToJoinMemberPlan = ({
	plan,
}: {
	plan: ICommunityMemberPlan | null;
}) => ({
	type: ECommunitiesActionTypes.setSelectedCommunityToJoinMemberPlan,
	payload: { plan },
});
export const updateJoinedAndApprovedStatusForCommunityAction = (payload: {
	communityId: number;
	joined: boolean;
	approved: boolean;
}) => ({
	type: ECommunitiesActionTypes.updateJoinedAndApprovedStatusForCommunity,
	payload,
});
export const setSelectedCommunityToJoinUserProfilePicture = (payload: {
	profileImage: UploadChangeParam<UploadFile<any>>;
	imageUrl: string;
}) => ({
	type: ECommunitiesActionTypes.setUserProfilePicture,
	payload,
});

export const setSelectedCommunityToJoinUserLocation = (payload: {
	address: string;
	latitude: number;
	longitude: number;
}) => ({
	type: ECommunitiesActionTypes.setUserProfileLocation,
	payload,
});

export const setCommunityInterests = (payload: {
	communityId: number;
	successCallback: () => void;
	errorCallback: () => void;
}) => ({
	type: ECommunitiesActionTypes.setCommunityInterests,
	payload,
});

export const getCommunitiesInvitations = (
	payload: {
		onSuccessCallback?: () => void;
		onErrorCallback?: () => void;
	} = {},
) => ({
	type: ECommunitiesActionTypes.getCommunitiesInvitations,
	payload,
});

export const setCommunitiesInvitations = (payload: {
	meta: ICommunitiesState["meta"];
}) => ({
	type: ECommunitiesActionTypes.setCommunitiesInvitations,
	payload,
});
export const setSelectedCommunityToJoinCurrency = (currency?: string) => ({
	type: ECommunitiesActionTypes.communityToJoinSetCurrency,
	payload: { currency },
});

export const setSelectedCommunityToJoinProgressBar = (payload: {
	onboardingStep: keyof ISelectedCommunityToJoin["onboardingProgressBarSteps"];
	showTheCheckoutStep?: boolean;
}) => ({
	type: ECommunitiesActionTypes.setSelectedCommunityToJoinProgressBar,
	payload,
});

export const initSelectedCommunityToJoinProgressBar = (payload: {
	isConnectedCommunity?: boolean;
	hasGroups?: boolean;
	isTheProcessResumed?: boolean;
	resetData?: boolean;
}) => ({
	type: ECommunitiesActionTypes.initSelectedCommunityToJoinProgressBar,
	payload,
});
export const startTheJoinCommunityProcessAction = (payload: boolean) => ({
	type: ECommunitiesActionTypes.startTheJoinCommunityProcess,
	payload,
});
