import { IMembersState, EMembersActionTypes, TMembersActions } from "models";

const initial: IMembersState = {
	loading: false,
	tags: [],
	users: undefined,
	selectedTags: [],
};

export function membersReducer(
	state = initial,
	action: TMembersActions,
): IMembersState {
	switch (action.type) {
		case EMembersActionTypes.membersLoadingSet: {
			return {
				...state,
				loading: action.payload,
			};
		}
		case EMembersActionTypes.setMembers: {
			if (action.payload.deleteAllMembersAndTags) {
				return {
					...state,
					users: [],
					tags: [],
				};
			} else {
				return {
					...state,
					users: action.payload.users,
					tags: action.payload.tags,
				};
			}
		}
		case EMembersActionTypes.updateTheBanStatusForMember: {
			const { blocked, blocked_by, id } = action.payload;
			if (state.users && id) {
				const memberToUpdateIndex = state.users.findIndex(
					(item) => item.id === id,
				);
				let members = [...state.users];
				members[memberToUpdateIndex].blocked = blocked;
				members[memberToUpdateIndex].blocked_by = blocked_by;
				return {
					...state,
					users: members,
				};
			} else {
				return { ...state };
			}
		}
		case EMembersActionTypes.setFilterTagsMembers: {
			return {
				...state,
				selectedTags: action.payload.tags,
			};
		}
		default:
			return state;
	}
}
