import React, { memo, useCallback, useMemo } from "react";
import { Button } from "antd";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";

import styles from "./styles.module.scss";
import { IMarketplaceOfferRawData, TStore } from "models";
import { ReactComponent as WebsiteIcon } from "assets/images/website-icon.svg";
import { ReactComponent as MessageSquareIcon } from "assets/images/message-square-icon.svg";
import ImagesSection from "../imagesSection";
import { fetchConversation, notify } from "store";
import { UseShowDescriptionWithMentions, useResponsiveQuery } from "utils";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.auth.credentials?.user,
	(authenticatedUser) => ({
		authenticatedUser,
	}),
);

interface IDetailedView {
	marketplacePost: IMarketplaceOfferRawData;
}

const DetailedView: React.FunctionComponent<IDetailedView> = ({
	marketplacePost,
}) => {
	const {
		user,
		title,
		description,
		good,
		tags = [],
		images,
		membership_id,
		website,
		mentions,
	} = marketplacePost;
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { authenticatedUser } = useSelector(stateSelector);
	const { isTabletOrMobile, isMobile } = useResponsiveQuery();

	const goToChat = () => {
		if (!membership_id) {
			notify("error", "An error occurred. Please try again later.");
			return;
		}
		navigate("/messages", {
			state: { member: { ...user, membership_id } },
		});
		dispatch(
			fetchConversation({
				communityPostId: 0,
				recipientId: membership_id,
				conversationId: 0,
				page: 1,
			}),
		);
	};

	const parsedPrice = useMemo((): null | {
		minPrice: string;
		maxPrice: string;
		currency: string;
	} => {
		if (!good || !good.price) {
			return null;
		}
		const splittedPrice = good.price.split("-");
		if (!splittedPrice || splittedPrice.length !== 2) {
			// for the scenarios when the good.price is "0"
			return null;
		}
		return {
			minPrice: splittedPrice[0].trim(),
			maxPrice: splittedPrice[1].trim(),
			currency: good.currency,
		};
	}, [good]);

	const visitProductWebsite = useCallback(() => {
		if (!website) {
			console.error("Missing parameters!");
			return;
		}
		window?.open(website, "_blank")?.focus();
	}, [website]);

	return (
		<div className={styles.container}>
			<div className={styles.flexWrapper}>
				{images.length > 0 ? <ImagesSection images={images} /> : null}
				<section
					className={classNames(
						styles.flexOneBox,
						styles.flexColumn,
						{ [styles.marginBottom16]: isMobile },
					)}
				>
					<div>
						<span
							className={classNames(
								styles.smallText,
								styles.greyColor,
							)}
						>
							{"Sold by "}
						</span>
						<span
							className={classNames(
								styles.smallText,
								styles.bold,
								styles.greyColor,
							)}
						>
							{user.full_name}
						</span>
					</div>
					<div className={styles.headerText}> {title}</div>
					{parsedPrice && (
						<div className={styles.boldBlackText}>
							{`${parsedPrice.currency}${parsedPrice.minPrice}-${parsedPrice.currency}${parsedPrice.maxPrice}`}
						</div>
					)}
					<div className={styles.textWithEnters}>
						<UseShowDescriptionWithMentions
							text={description}
							mentions={mentions}
							words={300}
						/>
					</div>
					<div className={styles.tagsWrapper}>
						{tags.length > 0 &&
							tags.map((item) => {
								return (
									<div className={styles.tagItem}>
										{`#${item}`}
									</div>
								);
							})}
					</div>
				</section>
			</div>
			<div
				className={classNames(styles.flexRowCenter, {
					[styles.flexColumn]: isTabletOrMobile,
				})}
			>
				{website ? (
					<Button
						onClick={visitProductWebsite}
						className={styles.button}
						icon={<WebsiteIcon />}
					>
						{"Visit Product Website"}
					</Button>
				) : null}
				{authenticatedUser?.id !== user.id && (
					<Button
						className={styles.button}
						icon={<MessageSquareIcon />}
						onClick={goToChat}
					>
						{"Message Seller"}
					</Button>
				)}
			</div>
		</div>
	);
};

export default memo(DetailedView);
