import { memo, useCallback, useLayoutEffect, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { useParams } from "react-router";

import { EProcessToJoinCommunityStage, TStore } from "models";
import { Groups } from "screens";
import { hexToRgbA, settingSelectedCommunityToJoinColors } from "utils";
import { PendingStatus, JoinCommunityMemberPlans } from "components";
import JoinCommunityUserSetUp from "../joinCommunityUserSetUp";
import { useLocation } from "react-router-dom";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.communities.selectedCommunityToJoinInfo,
	(state: TStore) => state.communities?.meta?.invites,
	(selectedCommunityToJoinInfo, invites) => ({
		selectedCommunityToJoinInfo,
		invites,
	}),
);

const JoinCommunity = () => {
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { selectedCommunityToJoinInfo } = useSelector(stateSelector);
	const [
		saveSelectedToJoinCommunityColors,
		setSaveAddSelectedToJoinCommunityColors,
	] = useState(false);

	const {
		communityId,
		onboardingStage,
	}: { communityId?: string; onboardingStage?: string } = useParams();
	const { id } = selectedCommunityToJoinInfo;

	const joinProcessStage = useCallback((): JSX.Element => {
		switch (onboardingStage) {
			case EProcessToJoinCommunityStage.welcome: {
				return <JoinCommunityUserSetUp />;
			}
			case EProcessToJoinCommunityStage["member plans"]: {
				return <JoinCommunityMemberPlans />;
			}
			case EProcessToJoinCommunityStage.groups: {
				return <Groups />;
			}
			case EProcessToJoinCommunityStage["pending status"]: {
				return <PendingStatus communityId={parseInt(communityId!)} />;
			}
			case EProcessToJoinCommunityStage["resume payment process"]: {
				return (
					<PendingStatus
						resumePaymentProcess={true}
						communityId={parseInt(communityId!)}
					/>
				);
			}
			default: {
				return <></>;
			}
		}
	}, [communityId, onboardingStage]);

	useLayoutEffect(() => {
		if (communityId && id && !saveSelectedToJoinCommunityColors) {
			const {
				primary_color,
				header_background,
				announcement_header_background,
			} = selectedCommunityToJoinInfo;
			settingSelectedCommunityToJoinColors({
				headerBackground: header_background ?? null,
				headerBackgroundRgb: header_background
					? hexToRgbA(header_background)
					: null,
				announcementHeaderBackground:
					announcement_header_background ?? null,
				announcementHeaderBackgroundRgb: announcement_header_background
					? hexToRgbA(announcement_header_background)
					: null,
				primaryColor: primary_color ?? null,
				primaryColorRgb: primary_color
					? hexToRgbA(primary_color)
					: null,
			});
			setSaveAddSelectedToJoinCommunityColors(true);
		}
	}, [
		communityId,
		id,
		saveSelectedToJoinCommunityColors,
		selectedCommunityToJoinInfo,
	]);

	const { pathname } = useLocation();

	useEffect(() => {
		const onboardingHeader = document.getElementById("onboarding-header");

		if (onboardingHeader) {
			onboardingHeader.scrollIntoView({
				behavior: "smooth",
				block: "end",
				inline: "nearest",
			});
		}
	}, [pathname]);

	return <>{joinProcessStage()}</>;
};

export default memo(JoinCommunity);
