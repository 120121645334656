import {
	ECommunityType,
	EPaymentsTypes,
	ESubscriptionStatus,
	IMemberSubscriptionResponse,
} from "models";

export const paymentsCreateMemberSubscription = (payload: {
	stripePlanPriceId: string;
	communityId: number;
	onErrorCallback: () => void;
	onSuccessCallback?: (paymentsMemberSubscriptionData: any) => void;
}) => ({
	type: EPaymentsTypes.createMemberSubscription,
	payload,
});
export const paymentsSetMemberSubscription = (
	payload: IMemberSubscriptionResponse | null,
) => ({
	type: EPaymentsTypes.setMemberSubscription,
	payload,
});

export const resumePaymentProcessAction = (payload: {
	communityId: number;
	onSuccessCallback: () => void;
	onErrorCallback: ({
		isTheSelectedMemberPlanAvailable,
	}?: {
		isTheSelectedMemberPlanAvailable: boolean;
	}) => void;
}) => ({
	type: EPaymentsTypes.resumePaymentProcess,
	payload,
});

export const changeTheMemberSubscriptionToPendingAction = (payload: {
	communityId: number;
	subscriptionId: number;
	onErrorCallback: () => void;
	onSuccessCallback?: () => void;
}) => ({
	type: EPaymentsTypes.changeTheMemberSubscriptionToPending,
	payload,
});

export const setTheMemberSubscriptionToPendingAction = (payload: {
	subscription_status: ESubscriptionStatus;
}) => ({
	type: EPaymentsTypes.setTheMemberSubscriptionToPending,
	payload,
});
export const completePaymentProcessSagaAction = (payload: {
	onSuccessCallback: () => void;
	onErrorCallback: () => void;
	typeOfCommunity: ECommunityType;
}) => ({
	type: EPaymentsTypes.completeThePaymentProcess,
	payload,
});
export const changeSubscriptionPlanSagaAction = (payload: {
	stripePlanPriceId: string;
	onSuccessCallback: () => void;
	onErrorCallback: () => void;
}) => ({
	type: EPaymentsTypes.changeSubscriptionPlan,
	payload,
});

export const changeSubscriptionPlanWithPaymentIntentAction = (payload: {
	stripePlanPriceId: string;
	currentSubscriptionId: number;
	communityId: number;
	onSuccessCallback: () => void;
	onErrorCallback: () => void;
}) => ({
	type: EPaymentsTypes.changeSubscriptionPlanWithPaymentIntent,
	payload,
});
