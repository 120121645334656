import React, { memo, useCallback, useEffect, useRef, useMemo } from "react";
import { Row, Col } from "antd";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";

import { MainLayout } from "layouts";
import styles from "./styles.module.scss";
import {
	Messenger,
	MessengerEditor,
	Threads,
	useSetPinnedConversation,
} from "./components";
import {
	getConversations,
	createPinnedConversation,
	setSelectedThread,
} from "store";
import { TStore, IMessageThread } from "models";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.app.currentCommunityId,
	(state: TStore) => state.messages.selectedThread,
	(state: TStore) => state.members?.users,
	(
		communityId: number | null,
		selectedThread: IMessageThread | null,
		members,
	) => {
		return {
			communityId,
			selectedThread,
			members,
		};
	},
);

const Messages = () => {
	const dispatch = useDispatch();
	const threadRef = useRef<any>(null);
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { communityId, selectedThread, members } = useSelector(stateSelector);

	useSetPinnedConversation({});

	const checkAvailabilityToWriteMsg: boolean = useMemo(() => {
		if (members && selectedThread) {
			const selectedThreadMember = members.find(
				(item) => selectedThread?.user?.user_id === item.id,
			);
			if (selectedThreadMember) {
				return (
					!selectedThreadMember?.blocked &&
					!selectedThreadMember?.blocked_by
				);
			} else {
				return true;
			}
		} else {
			return true;
		}
	}, [members, selectedThread]);

	useEffect(() => {
		dispatch(getConversations({ reset: true }));

		return () => {
			dispatch(createPinnedConversation(null));
			dispatch(setSelectedThread(null));
		};
	}, [dispatch]);

	return (
		<MainLayout>
			<Row gutter={[32, 24]} className="customRow">
				<Col span={24}>
					<Row gutter={[32, 24]} className={styles.threatsWrapper}>
						<Col span={10} className={styles.threatList}>
							<Threads />
						</Col>
						<Col span={14} className={styles.threatList}>
							<section
								className={styles.chatSection}
								ref={threadRef}
							>
								{selectedThread && (
									<Messenger
										communityId={communityId}
										threadRef={threadRef}
										selectedThread={selectedThread}
									/>
								)}
								{!selectedThread && (
									<div
										className={styles.noActiveChats}
										style={{
											position: "absolute",
											top: 0,
											bottom: 0,
											left: 0,
											right: 0,
										}}
									>
										{"Please select a conversation"}
									</div>
								)}
								{selectedThread &&
									checkAvailabilityToWriteMsg && (
										<MessengerEditor
											selectedThread={selectedThread}
										/>
									)}
							</section>
						</Col>
					</Row>
				</Col>
			</Row>
		</MainLayout>
	);
};

export default memo(Messages);
