import {
	DeleteOutlined,
	EditOutlined,
	MessageOutlined,
	MoreOutlined,
} from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import React, {
	memo,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { matchPath, useNavigate, useLocation } from "react-router-dom";
import VisibilitySensor from "react-visibility-sensor";
import { createSelector } from "reselect";
import classNames from "classnames";

import { Comments } from "components";
import { IPost, IPostState, TStore } from "models";
import {
	api,
	deletePostAction,
	getEditPostAction,
	getPosts,
	incrementPostViewCountAction,
	notify,
	setFilterTagsMiddleware,
} from "store";
import {
	GetImg,
	timeSince,
	useComputePostImgSize,
	UseShowDescriptionWithMentions,
} from "utils";
import { useGetUrlsFromPostText } from "utils/useGetUrlsFromPostText";
import { PostImage, PreviewPostLink } from "..";
import styles from "./styles.module.scss";

const stateSelectorHandle = (postId: number) =>
	createSelector(
		(state: TStore) =>
			state?.editProfile?.data?.user?.membership_id ||
			state?.auth?.credentials?.user?.membership_id ||
			null,
		(state: TStore) => state.posts.selectedTags,
		(state: TStore) => state.app.feedPostsContainerWidth,
		(membershipId, selectedTags, feedPostsContainerWidth) => ({
			membershipId,
			selectedTags,
			feedPostsContainerWidth,
		}),
	);

const checkTimestampForSecondsOrMinutes = new RegExp(
	"(\\d+)\\s(seconds?|minutes?)\\sago",
	"i",
);

interface IPostContent {
	uniqueKey: string;
	post: IPost;
	hideDeleteAndEditMenu?: boolean;
	handlePostEdit?: (post: IPostState["data"]) => void;
	enableEditing?: () => void;
	getScrollParent?: () => HTMLElement | null;
}

const PostContent: React.FunctionComponent<IPostContent> = ({
	post,
	uniqueKey,
	handlePostEdit,
	enableEditing,
	hideDeleteAndEditMenu = false,
	getScrollParent,
}) => {
	const urlInstances = useGetUrlsFromPostText(post.description);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const matchMembersPage: boolean = useMemo(() => {
		const match = matchPath("/members/*", location.pathname);

		return match ? Boolean(match) : false;
	}, [location.pathname]);

	const {
		user,
		user_pin,
		membership_id,
		is_approved,
		images,
		member_type,
		timestamp,
		all_groups,
		groups,
		tags,
		title,
		description,
		video_link,
		id,
		vimeo_uri,
		mentions,
		linked_community_documents,
	} = post; // eslint-disable-next-line
	const stateSelector = useCallback(stateSelectorHandle(post.id), []);
	const { membershipId, selectedTags, feedPostsContainerWidth } =
		useSelector(stateSelector);
	const [embedCode, setEmbedCode] = useState<any>(null);
	const postRef = useRef<HTMLDivElement | null>(null);
	const links = useRef<Array<any>>([]);
	const [postTimestamp, setPostTimestamp] = useState<string>("");
	const autoUpdatePostTimestampIntervalId = useRef<NodeJS.Timer | undefined>(
		undefined,
	);

	const updateThePostTimestamp = useCallback((timestamp: string) => {
		if (!timestamp) return;

		const instances = checkTimestampForSecondsOrMinutes.exec(timestamp);
		if (!instances) {
			setPostTimestamp(timestamp);
			return;
		}

		if (instances[2].toLowerCase().indexOf("second") !== -1) {
			setPostTimestamp("< 1 minute ago");
		} else {
			setPostTimestamp(`${instances["input"]}`);
		}
		const newIntervalId = setInterval(() => {
			setPostTimestamp((prevState) => {
				const instances =
					checkTimestampForSecondsOrMinutes.exec(prevState);

				if (!instances) return prevState;

				if (instances["input"] === "< 1 minute ago") {
					return "1 minute ago";
				} else {
					let minutes: number = parseInt(instances[1], 10) + 1;
					if (minutes === 60) {
						if (autoUpdatePostTimestampIntervalId.current) {
							clearInterval(
								autoUpdatePostTimestampIntervalId.current as any,
							);
							autoUpdatePostTimestampIntervalId.current =
								undefined;
						}
						return "1 hour ago";
					} else {
						return `${minutes} minutes ago`;
					}
				}
			});
		}, 60000);
		autoUpdatePostTimestampIntervalId.current = newIntervalId;
	}, []);

	const parsedTimestamp: string = useMemo(() => {
		return timeSince(timestamp);
	}, [timestamp]);

	const handleFilterTagsPosts = useCallback(
		(tags?: string[]) => () => {
			let newTag: string[] = [];
			if (tags && tags.length) {
				newTag = tags.filter(
					(item: any) => selectedTags && !selectedTags.includes(item),
				);
			}
			dispatch(setFilterTagsMiddleware({ tag: newTag }));
			dispatch(getPosts({ resetPosts: true }));

			const scrollParentElement = getScrollParent?.();
			if (scrollParentElement) {
				scrollParentElement.scrollTo({ top: 0, behavior: "smooth" });
			}
		},
		[dispatch, getScrollParent, selectedTags],
	);

	const postImage = useComputePostImgSize({
		containerWidth: feedPostsContainerWidth,
		images: images.length > 0 ? images : null,
	});

	const postGroupsDetails: string | null = useMemo(() => {
		if (all_groups) {
			return "All Groups";
		} else if (groups && groups.length > 0) {
			return groups.join(", ");
		} else return null;
	}, [all_groups, groups]);

	const parsedTags = useMemo(() => {
		if (tags.length === 0) {
			return [];
		} else {
			const newTags = tags.map((item: string) => {
				return {
					tagToFilter: item,
					showTag: `#${item.toLocaleLowerCase()}`,
				};
			});
			return newTags;
		}
	}, [tags]);

	const onAction = useCallback(
		({ item, key, keyPath, domEvent }: any) => {
			if (!post) {
				notify("error", "An error occurred. Please try again later.");
				return;
			}
			if (key === "edit") {
				dispatch(
					getEditPostAction({
						id: post.id,
						callbackOnSuccess: (editPost) => {
							if (!handlePostEdit) return;
							handlePostEdit(editPost);
						},
					}),
				);
				if (enableEditing) {
					enableEditing();
				}
			} else if (
				window.confirm("Are you sure you want to delete this post?")
			) {
				dispatch(
					deletePostAction({
						postId: post.id,
						location: location.pathname,
					}),
				);
			}
		},
		[post, dispatch, enableEditing, handlePostEdit, location.pathname],
	);

	const menu = useMemo(
		() => (
			<Menu onClick={onAction}>
				<Menu.Item key="edit" icon={<EditOutlined />}>
					{"Edit Post"}
				</Menu.Item>
				<Menu.Item key="delete" icon={<DeleteOutlined />}>
					{"Delete Post"}
				</Menu.Item>
			</Menu>
		),
		[onAction],
	);

	const onVisibilityChange = useCallback(
		(isVisible) => {
			if (isVisible) dispatch(incrementPostViewCountAction(post.id));
		},
		[dispatch, post],
	);

	const goToMemberId = useCallback(
		(e: any) => {
			navigate("/members/" + user.id, {
				state: { post },
			});
		},
		[navigate, user, post],
	);

	const goToChat = useCallback(() => {
		navigate("/messages", {
			state: { post },
		});
	}, [navigate, post]);

	const goToMember = useCallback(
		(e: React.SyntheticEvent<HTMLAnchorElement>) => {
			const to: string | undefined = e.currentTarget.dataset.mention;
			to && navigate(to);
		},
		[navigate],
	);

	useEffect(() => {
		postRef &&
			postRef.current &&
			postRef.current
				.querySelectorAll("a[data-mention]")
				.forEach((a: any) => {
					if (links.current.indexOf(a.dataset.mention) === -1) {
						a.removeEventListener("click", goToMember);
						a.addEventListener("click", goToMember);
						links.current.push(a.dataset.mention);
					}
				});
	}, [description, goToMember]);

	useEffect(() => {
		const getEmbed = async () => {
			if (vimeo_uri) {
				const { data } = await api.videos.getEmbed(
					vimeo_uri.replace("/api/v1", ""),
				);
				const iframe = data.match(
					/(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/gi,
				);
				setEmbedCode(
					<div
						className={styles.positionHack}
						dangerouslySetInnerHTML={{ __html: iframe[0] }}
					/>,
				);
			}
		};
		getEmbed();
	}, [vimeo_uri]);

	useEffect(() => {
		if (!parsedTimestamp) return;
		updateThePostTimestamp(parsedTimestamp);
	}, [updateThePostTimestamp, parsedTimestamp]);

	return (
		<>
			<React.Fragment key={uniqueKey}>
				<VisibilitySensor onChange={onVisibilityChange}>
					<div
						style={{
							boxShadow:
								"0 1px 9px 0 rgb(0 0 0 / 2%), 0 2px 6px 0 rgb(0 0 0 / 1%), 0 10px 34px 0 rgb(0 0 0 / 3%)",
						}}
						className={classNames(styles.postBox, {
							[styles.communityBox]: !user_pin,
							[styles.communityMemberBox]: user_pin,
							[styles.boxShadow]: matchMembersPage,
						})}
					>
						<div className={styles.profileInfo}>
							<div
								className={styles.avatar}
								onClick={goToMemberId}
							>
								<GetImg endpoint={user.picture} />
							</div>
							<div style={{ width: "100%" }}>
								<div className={styles.header}>
									<span
										className={styles.postAuthor}
										onClick={goToMemberId}
									>
										{user.full_name}
									</span>
									{membershipId &&
										parseInt(membershipId as string) !==
											membership_id && (
											<MessageOutlined
												twoToneColor="#eb2f96"
												onClick={goToChat}
												style={{
													marginRight: "auto",
													marginTop: "5px",
												}}
											/>
										)}
									{!hideDeleteAndEditMenu &&
										membershipId &&
										parseInt(membershipId as string) ===
											membership_id && (
											<Dropdown.Button
												overlay={menu}
												placement="bottomRight"
												icon={<MoreOutlined />}
												style={{ marginLeft: "auto" }}
												className={styles.dropdownBtn}
											/>
										)}
								</div>

								<div className={styles.smallText}>
									<div>
										{member_type && (
											<span
												className={classNames(
													styles.highlightText,
													styles.normalFontWeight,
												)}
											>
												{member_type}
											</span>
										)}
										{member_type && user?.location && " | "}
										{user?.location && (
											<span
												className={
													styles.normalFontWeight
												}
											>
												{user?.location}
											</span>
										)}
									</div>
									<div>
										{`${postTimestamp} into `}
										{postGroupsDetails && (
											<span
												className={classNames(
													styles.highlightText,
													styles.normalFontWeight,
												)}
											>
												{postGroupsDetails}
											</span>
										)}
									</div>
								</div>
								{parsedTags.length > 0 &&
									parsedTags.map(
										(item: any, index: number) => {
											return (
												<small
													key={`tag_${index}_${item.showTag}`}
													className={styles.tag}
													onClick={handleFilterTagsPosts(
														[item.tagToFilter],
													)}
												>
													{item.showTag}
												</small>
											);
										},
									)}
							</div>
						</div>
						{title ? <h2 className={styles.title}>{title}</h2> : ""}
						<div className={styles.contentBoxInfo}>
							{description ? (
								<div
									className={styles.showMoreContainer}
									style={{ paddingBottom: 0 }}
								>
									<div ref={postRef}>
										<UseShowDescriptionWithMentions
											text={description}
											mentions={mentions}
											words={60}
											linked_community_documents={
												linked_community_documents
											}
										/>
									</div>
								</div>
							) : (
								""
							)}
						</div>
						{urlInstances && (
							<PreviewPostLink postUrls={urlInstances} />
						)}
						{video_link ? (
							<div className={styles.videoPlayer}>
								<ReactPlayer url={video_link} controls={true} />
							</div>
						) : postImage ? (
							<PostImage postImage={postImage} />
						) : (
							embedCode
						)}

						{!is_approved && (
							<div className={styles.waitingApproval}>
								{"Pending approval"}
							</div>
						)}
						{is_approved && <Comments postId={id} />}
					</div>
				</VisibilitySensor>
			</React.Fragment>
		</>
	);
};

export default memo(PostContent);
