import {
	EChangeSubscriptionStage,
	ESubscriptionActionTypes,
	ICommunityMemberPlan,
	IPaymentMethod,
	ISubscription,
	ISubscriptionState,
} from "models";

export const getSubscriptionManagementActionSaga = (payload?: {
	communityId?: number;
	onSuccessCallback?: () => void;
	onErrorCallback?: () => void;
}) => ({
	type: ESubscriptionActionTypes.GET_SUBSCRIPTION_MANAGEMENT,
	payload,
});

export const setSubscriptionManagementAction = (
	payload: ISubscriptionState,
) => ({
	type: ESubscriptionActionTypes.SET_SUBSCRIPTION_MANAGEMENT,
	payload,
});

export const setSubscriptionManagementLoadingAction = (payload: boolean) => ({
	type: ESubscriptionActionTypes.SET_SUBSCRIPTION_MANAGEMENT_LOADING,
	payload,
});

export const deletePaymentMethodActionSaga = (payload: {
	paymentMethodId: string;
	onSuccessCallback: () => void;
	onErrorCallback: () => void;
}) => ({
	type: ESubscriptionActionTypes.DELETE_PAYMENT_METHOD_SAGA,
	payload,
});

export const deletePaymentMethodAction = (payload: string) => ({
	type: ESubscriptionActionTypes.DELETE_PAYMENT_METHOD,
	payload,
});

export const cancelSubscriptionActionSaga = (payload: {
	onSuccessCallback: () => void;
	onErrorCallback: () => void;
}) => ({
	type: ESubscriptionActionTypes.CANCEL_SUBSCRIPTION,
	payload,
});

export const setSubscriptionAction = (payload: ISubscription) => ({
	type: ESubscriptionActionTypes.SET_SUBSCRIPTION,
	payload,
});

export const addANewPaymentMethodAction = (payload: {
	onSuccessCallback: () => void;
	onErrorCallback: (errors: string[][]) => void;
	paymentMethodId: string;
	selectedCommunityId?: number;
}) => ({
	type: ESubscriptionActionTypes.ADD_NEW_PAYMENT_METHOD,
	payload,
});

export const setPaymentMethods = (payload: {
	paymentMethods: IPaymentMethod[];
}) => ({
	type: ESubscriptionActionTypes.SET_PAYMENT_METHODS,
	payload,
});

export const initiateTheResubscribeProcessAction = (payload: boolean) => ({
	type: ESubscriptionActionTypes.INITIATE_THE_RESUBSCRIBE_PROCESS,
	payload,
});

//**The resubscribeSagaAction function it is called if the user wants to reactivate the subscription. */
export const resubscribeSagaAction = (payload: {
	onSuccessCallback: () => void;
	onErrorCallback: () => void;
}) => ({
	type: ESubscriptionActionTypes.RESUBSCRIBE,
	payload,
});

export const changeSubscriptionStageAction = (payload: {
	stage: EChangeSubscriptionStage;
}) => ({
	type: ESubscriptionActionTypes.CHANGE_SUBSCRIPTION_STAGE,
	payload,
});

export const setChangeSubscriptionSelectedMemberPlanAction = (payload: {
	memberPlan: ICommunityMemberPlan;
	updateTheStageToCheckoutForm?: boolean;
}) => ({
	type: ESubscriptionActionTypes.SET_CHANGE_SUBSCRIPTION_SELECTED_MEMBER_PLAN,
	payload,
});

export const setDefaultPaymentMethodSagaAction = (payload: {
	paymentMethodId: string;
	onSuccessCallback: () => void;
	onErrorCallback: () => void;
	skipCustomer?: boolean; //**  skip setting the method as default for respective entities */
	skipSubscription?: boolean;
}) => ({
	type: ESubscriptionActionTypes.SET_DEFAULT_PAYMENT_METHOD,
	payload,
});
export const changeSubscriptionSetCurrencyAction = (payload: {
	currency: string;
}) => ({
	type: ESubscriptionActionTypes.SET_CHANGE_SUBSCRIPTION_CURRENCY,
	payload,
});
