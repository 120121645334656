import React, { memo, useCallback } from "react";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";

import styles from "./styles.module.scss";
import { GetImg } from "utils";
import { TStore } from "models";

interface IEnablePostForm {
	handleShowPostForm: () => void;
}

const stateSelectorHandle = createSelector(
	(state: TStore) => state.auth.credentials?.user,
	(user) => ({
		user,
	}),
);

const EnablePostForm: React.FunctionComponent<IEnablePostForm> = ({
	handleShowPostForm,
}) => {
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { user } = useSelector(stateSelector);

	return (
		<div className={styles.container}>
			<div className={styles.avatar}>
				<GetImg endpoint={user?.picture} />
			</div>
			<div className={styles.inputBox} onClick={handleShowPostForm}>
				{"Add a post here"}
			</div>
		</div>
	);
};

export default memo(EnablePostForm);
