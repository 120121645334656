import { useMediaQuery } from "react-responsive";

export const useResponsiveQuery = () => {
	const isDesktopOrLaptop = useMediaQuery({
		query: "(min-device-width: 1224px)",
	});
	const isBigScreen = useMediaQuery({ query: "(min-device-width: 1824px)" });
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
	const isTabletOrMobileDevice = useMediaQuery({
		query: "(max-device-width: 1224px)",
	});
	const isMobile = useMediaQuery({
		query: "(max-device-width: 599px)",
	});
	const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
	const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });
	const md = useMediaQuery({ minWidth: 768 });
	const lg = useMediaQuery({ minWidth: 992 });
	const xlg = useMediaQuery({ minWidth: 1200 });
	const xsm = useMediaQuery({ maxWidth: 576 });
	const sm = useMediaQuery({ minWidth: 576 });

	return {
		isDesktopOrLaptop,
		isBigScreen,
		isTabletOrMobile,
		isTabletOrMobileDevice,
		isPortrait,
		isRetina,
		sm,
		md,
		xsm,
		xlg,
		lg,
		isMobile,
	};
};
