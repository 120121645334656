import {
	ILiveStreamState,
	TLiveStreamActions,
	ELiveStreamActionTypes,
} from "models";

const readDefaultState = () => {
	try {
		if (window.sessionStorage.getItem("custom_storage")) {
			// @ts-ignore
			return JSON.parse(window.sessionStorage.getItem("custom_storage"));
		}
	} catch (err) {
		return {};
	}
};

const initial: ILiveStreamState = {
	// loading effect
	loading: false,
	// media devices
	streams: [],
	localStream: null,
	currentStream: null,
	otherStreams: [],
	devicesList: [],
	// web sdk params
	config: {
		uid: 0,
		host: true,
		channelName: "",
		token: process.env.REACT_APP_AGORA_APP_TOKEN,
		resolution: "480p",
		...readDefaultState(),
		microphoneId: "",
		cameraId: "",
	},
	agoraClient: null,
	mode: "live",
	codec: "vp8",
	muteVideo: true,
	muteAudio: true,
	screen: false,
	profile: false,
	// beauty: false
};

export function liveStreamReducer(
	state = initial,
	action: TLiveStreamActions,
): ILiveStreamState {
	switch (action.type) {
		case ELiveStreamActionTypes.setLoading: {
			return {
				...state,
				loading: action.payload,
			};
		}
		case ELiveStreamActionTypes.setConfig: {
			return {
				...state,
				config: action.payload,
			};
		}
		case ELiveStreamActionTypes.setClient: {
			return {
				...state,
				client: action.payload,
			};
		}
		case ELiveStreamActionTypes.setCodec: {
			return {
				...state,
				codec: action.payload,
			};
		}
		case ELiveStreamActionTypes.setVideo: {
			return {
				...state,
				video: action.payload,
			};
		}
		case ELiveStreamActionTypes.setAudio: {
			return {
				...state,
				audio: action.payload,
			};
		}
		case ELiveStreamActionTypes.setScreen: {
			return {
				...state,
				screen: action.payload,
			};
		}
		case ELiveStreamActionTypes.setDevicesList: {
			return {
				...state,
				devicesList: action.payload,
			};
		}
		case ELiveStreamActionTypes.setLocalStream: {
			return {
				...state,
				localStream: action.payload,
			};
		}
		case ELiveStreamActionTypes.setProfile: {
			return {
				...state,
				profile: action.payload,
			};
		}
		case ELiveStreamActionTypes.setCurrentStream: {
			const streams = { ...state.streams };
			const newCurrentStream = action.payload;
			const otherStreams = streams.filter(
				(it: any) => it.getId() !== newCurrentStream.getId(),
			);
			return { ...state, currentStream: newCurrentStream, otherStreams };
		}
		case ELiveStreamActionTypes.addStream: {
			const { streams, currentStream } = { ...state };
			const newStream = action.payload;
			let newCurrentStream: any = currentStream;
			if (!newCurrentStream) {
				newCurrentStream = newStream;
			}
			if (streams.length === 4) return { ...state };
			const newStreams = [...streams, newStream];
			const otherStreams = newStreams.filter(
				(it) => it.getId() !== newCurrentStream.getId(),
			);
			return {
				...state,
				streams: newStreams,
				currentStream: newCurrentStream,
				otherStreams,
			};
		}
		case ELiveStreamActionTypes.removeStream: {
			const { streams, currentStream }: any = { ...state };
			const { stream, uid } = action.payload as any;
			const targetId = stream ? stream.getId() : uid;
			let newCurrentStream = currentStream;
			const newStreams = streams.filter(
				(stream: any) => stream.getId() !== targetId,
			);
			if (currentStream && targetId === currentStream.getId()) {
				if (newStreams.length === 0) {
					newCurrentStream = null;
				} else {
					newCurrentStream = newStreams[0];
				}
			}
			const otherStreams = newCurrentStream
				? newStreams.filter(
						(it: any) => it.getId() !== newCurrentStream.getId(),
				  )
				: [];
			return {
				...state,
				streams: newStreams,
				currentStream: newCurrentStream,
				otherStreams,
			};
		}

		case ELiveStreamActionTypes.clearAllStream: {
			// const {streams, localStream, currentStream, beauty} = state;
			const { streams, localStream, currentStream }: any = { ...state };
			streams.forEach((stream: any) => {
				if (stream.isPlaying()) {
					stream.stop();
				}
				// stream.close();
			});

			if (localStream) {
				localStream.isPlaying() && localStream.stop();
				localStream.close();
			}
			if (currentStream) {
				currentStream.isPlaying() && currentStream.stop();
				currentStream.close();
			}
			return {
				...state,
				currentStream: null,
				localStream: null,
				streams: [],
			};
		}

		default:
			return state;
	}
}
