import React, {
	memo,
	useRef,
	useCallback,
	useState,
	useMemo,
	useLayoutEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { MentionsInput, Mention, SuggestionDataItem } from "react-mentions";
import classNames from "classnames";
import { Button } from "antd";
import { createSelector } from "reselect";

import styles from "./styles.module.scss";
import { GetImg, parseContentWithMentions } from "utils";
import { TStore } from "models";
import { editPostComment, sendCommentForPost } from "store";
import { ReactComponent as SendButton } from "assets/images/messageSend.svg";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.auth.credentials?.user.picture,
	(state: TStore) => state.members.users,
	(picture, members) => ({ picture, members }),
);

interface ICommentInput {
	postId: number;
	commentId?: number;
	text?: string;
	handleEscapeKeyOnEdit?: () => void;
	handleDeleteComment?: () => void;
	handleCompleteEdit?: () => void;
	autoFocus?: boolean;
}

const CommentInput: React.FunctionComponent<ICommentInput> = ({
	postId,
	text = "",
	commentId,
	handleEscapeKeyOnEdit,
	handleDeleteComment,
	handleCompleteEdit,
	autoFocus = false,
}) => {
	const dispatch = useDispatch();
	const { source: commentSource } = parseContentWithMentions({
		content: text,
		typeOfConversion: "fromSystemToInput",
	});
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { picture, members } = useSelector(stateSelector);
	const messageInputRef = useRef<HTMLTextAreaElement>(null);
	const messageRaw = useRef<{ [key: string]: string }>();
	const [commentMsg, setCommentMsg] = useState(commentSource);
	const shiftPressed = useRef(false);
	const mentionsInputParent = useRef<HTMLElement | null>(null);
	const mentionsInputContainer = useRef<HTMLElement | null>(null);
	const suggestionsPortalHostRef = useRef<any>(undefined);
	const [onInputFocus, setOnInputFocus] = useState<boolean>(false);

	const users = useMemo(() => {
		return members?.map((item) => {
			return {
				id: item.id,
				display: item.full_name,
				avatar: item.picture,
			};
		});
	}, [members]);

	const handleChangeCommentMsg = useCallback((event: any) => {
		console.log({ event });
		setCommentMsg(event.target.value);
	}, []);

	const handleKeyUp = useCallback((e) => {
		if (e.keyCode === 16) {
			shiftPressed.current = false;
		}
	}, []);

	const sendMessage = useCallback(
		(e) => {
			e.preventDefault();
			let { source } = parseContentWithMentions({
				content: commentMsg,
				typeOfConversion: "fromInputToSystem",
			});

			if (!commentId) {
				// on adding case
				if (!!source.trim().length) {
					dispatch(
						sendCommentForPost({
							postId,
							content: source,
						}),
					);
					messageRaw.current = {};
					setCommentMsg("");
				}
			} else {
				if (!!source.trim().length && handleCompleteEdit) {
					dispatch(
						editPostComment({ postId, commentId, content: source }),
					);
					handleCompleteEdit();
				} else if (handleDeleteComment) {
					// delete
					handleDeleteComment();
				}
			}
		},
		[
			commentMsg,
			commentId,
			dispatch,
			postId,
			handleDeleteComment,
			handleCompleteEdit,
		],
	);

	const handleOnKeyDown = useCallback(
		(e) => {
			if (e.keyCode === 16) shiftPressed.current = true;
			else if (
				messageInputRef.current &&
				!shiftPressed.current &&
				e.key === "Enter"
			) {
				sendMessage(e);
			} else if (e.keyCode === 27 && handleEscapeKeyOnEdit) {
				handleEscapeKeyOnEdit();
			} else {
				return;
			}
		},
		[handleEscapeKeyOnEdit, sendMessage],
	);

	const handleRenderSuggestions = useCallback(
		(
			suggestion: SuggestionDataItem & { avatar: string },
			search: string,
			highlightedDisplay: React.ReactNode,
			index: number,
			focused: boolean,
		) => {
			return (
				<div
					key={`key_${index}-${suggestion?.avatar}-${search}`}
					className={classNames(styles.listItem, {
						[styles.listItemFocused]: focused,
					})}
				>
					{/* <img
						src={
							(suggestion?.avatar as string) ||
							"https://dashboard.ugenie.io/assets/avatar_placeholder-78c12dc4ddf99c849cb2abc99eeefce353705fd07f6be0097217ddf844bdb22b.png"
						}
						alt="avatar"
						style={{ width: 30, height: 30, borderRadius: 50 }}
					/> */}
					{highlightedDisplay}
				</div>
			);
		},
		[],
	);

	const updateFlexDirectionForSendBtn: boolean = useMemo(() => {
		if (commentMsg) {
			return mentionsInputContainer.current && mentionsInputParent.current
				? mentionsInputContainer.current.offsetWidth - 80 >
				  mentionsInputParent.current.offsetWidth
					? false
					: true
				: false;
		} else {
			return false;
		}
	}, [commentMsg]);

	useLayoutEffect(() => {
		const mentionsInputParentElement =
			document.getElementById("mentionsInput")?.parentElement;
		const mentionsInputContainerElement = document.getElementById(
			"mentionsInputContainer",
		)?.firstChild?.parentElement;

		if (mentionsInputParentElement && mentionsInputContainerElement) {
			mentionsInputParent.current = mentionsInputParentElement;
			mentionsInputContainer.current = mentionsInputContainerElement;
		}
	}, []);

	return (
		<>
			<div className={styles.commentBar}>
				{!commentId && (
					<div className={styles.avatar}>
						<GetImg endpoint={picture} />
					</div>
				)}
				<div className={styles.commentInput}>
					<div
						className={classNames(styles.mentionsInputContainer, {
							[styles.flexColumnDirection]:
								updateFlexDirectionForSendBtn,
						})}
					>
						<div style={{ width: "100%" }}>
							<MentionsInput
								autoFocus={autoFocus}
								onFocus={(e) => {
									e.currentTarget.setSelectionRange(
										e.currentTarget.value.length,
										e.currentTarget.value.length,
									);
									setOnInputFocus(true);
								}}
								value={commentMsg}
								onChange={handleChangeCommentMsg}
								className="mentionsInput"
								classNames={styles}
								onKeyDown={handleOnKeyDown}
								onKeyUp={handleKeyUp}
								inputRef={messageInputRef}
								placeholder="Write your thoughts here"
								suggestionsPortalHost={
									suggestionsPortalHostRef.current
								}
							>
								<Mention
									trigger="@"
									data={users ? users : []}
									className={styles.mention}
									// @ts-ignore
									renderSuggestion={handleRenderSuggestions}
									appendSpaceOnAdd={true}
									displayTransform={(_, display: any) =>
										`@${display}`
									}
								/>
							</MentionsInput>
						</div>
						<div
							style={{
								display: !onInputFocus ? "none" : "block",
							}}
							className={classNames(styles.sendButtonContainer, {
								[styles.flexRowDirection]:
									updateFlexDirectionForSendBtn,
							})}
						>
							<Button
								onClick={sendMessage}
								disabled={!commentMsg}
								icon={
									<SendButton
										width={20}
										height={20}
										className={
											commentMsg.length > 0
												? styles.sendSvgEnabled
												: styles.sendSvgDisabled
										}
									/>
								}
							/>
						</div>
					</div>
				</div>
				<div
					ref={suggestionsPortalHostRef}
					className={styles.divPositionRelativeWrapper}
				/>
			</div>
			{commentId && (
				<div style={{ fontSize: 12, color: "grey" }}>
					{"Press ESC key to cancel."}
				</div>
			)}
		</>
	);
};

export default memo(CommentInput);
