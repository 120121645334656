import React from "react";
import { Form, Input, Upload } from "antd";
import { InboxOutlined, YoutubeOutlined } from "@ant-design/icons";

import styles from "./styles.module.scss";

const { Dragger } = Upload;

interface IUploadVideo {
	isVideoLink: boolean;
	isVideo: boolean;
}

const UploadFile: React.FunctionComponent<IUploadVideo> = ({
	isVideoLink,
	isVideo,
}) => {
	const normFile = (e: any) => {
		if (Array.isArray(e)) {
			return e;
		}
		return e && e.fileList;
	};

	const dummyRequest = ({ file, onSuccess }: any) => {
		// Now our file’s data can be passed to the Form without throwing an action error.
	};

	return (
		<>
			<Form.Item
				name={"linkOrVideo"}
				rules={[
					{
						required: !isVideoLink && !isVideo ? true : false,
						message: "One of video link or video file is required.",
					},
				]}
			>
				{!isVideo && (
					<Form.Item name="video_link"
					rules={[
						{
							pattern:
								// eslint-disable-next-line no-useless-escape
								/^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be|vimeo\.com\/))(\/(?:[\w\-]+\?v=|embed\/|[0-9]+|v\/)?)?([\w\-]+)(\S+)?$/gim,
							message:
								"Video link is invalid",
						},
					]}>
						<Input
							className={styles.link}
							size="middle"
							prefix={<YoutubeOutlined />}
							placeholder={
								"Add YouTube or Vimeo link to upload a video"
							}
							
						/>
					</Form.Item>
				)}

				{!isVideoLink && (
					<div className={styles.uploadInput}>
						<Form.Item
							name={"video"}
							valuePropName="fileList"
							getValueFromEvent={normFile}
						>
							<Dragger
								listType="picture"
								showUploadList={true}
								accept="video/*, .mp4"
								customRequest={dummyRequest}
								multiple={false}
								beforeUpload={(file: any) => {
									// Upload files manually after beforeUpload returns false.
									return false;
								}}
							>
								<p className="ant-upload-drag-icon">
									<InboxOutlined />
								</p>
								<p className="ant-upload-text">
									{
										"Click or drag file to this area to upload"
									}
								</p>
								<p className="ant-upload-hint">
									{"Support for a single upload."}
									<br />
									{"Support all type of videos."}
								</p>
							</Dragger>
						</Form.Item>
					</div>
				)}
			</Form.Item>
		</>
	);
};

export default UploadFile;
