import React, { memo, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import LoginForm from "screens/login/components/Forms/loginForm";
import SignUpForm from "screens/login/components/Forms/signUpForm";
import ForgetPasswordForm from "screens/login/components/Forms/resetPasswordForm";
import ResetPasswordForm from "screens/login/components/Forms/resetPassword";

const AuthenticationBox: React.FunctionComponent<{
	signUp?: boolean;
	forgotPassword?: boolean;
	resetPassword?: boolean;
}> = ({ signUp, forgotPassword, resetPassword }) => {
	const navigate = useNavigate();
	const accessToken = useRef<string | null>("");
	const location = useLocation();

	if (location.pathname === "/reset-password") {
		const vars = new URLSearchParams(location.search);
		if (vars.has("access_token"))
			accessToken.current = vars.get("access_token");
		if (!accessToken.current) navigate("/auth/sign-in");
	}

	return (
		<>
			{resetPassword ? (
				<ResetPasswordForm />
			) : forgotPassword ? (
				<ForgetPasswordForm />
			) : signUp ? (
				<SignUpForm />
			) : (
				<LoginForm />
			)}
		</>
	);
};

export default memo(AuthenticationBox);
