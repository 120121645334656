import React, { memo, useCallback } from "react";
import { Button } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import styles from "./styles.module.scss";

const ScreenNameBar = ({
	icon,
	svgIcon,
	title,
	showButton = false,
	showBackButton = false,
	backButtonLabel = "Back",
	parentRef,
	style,
}: any) => {
	const navigate = useNavigate();
	const handleBackButton = useCallback(() => {
		navigate(-1);
	}, [navigate]);

	return (
		<div ref={parentRef} className={styles.screenNameBar} style={style}>
			{!svgIcon ? <img src={icon} alt="" /> : svgIcon}

			<h1>{title}</h1>
			{showBackButton && (
				<div className={styles.backButtom}>
					<Button onClick={handleBackButton}>
						{backButtonLabel}
					</Button>
				</div>
			)}
			{showButton && (
				<Button
					ghost
					shape="round"
					type="primary"
					icon={<LogoutOutlined />}
					size="large"
				>
					Project Manager Group
				</Button>
			)}
		</div>
	);
};

export default memo(ScreenNameBar);
