import styles from "./styles.module.scss";
export const JoinMemberText =() =>{
return(
<>
    <div className={styles.title}>
						Would you like to join the member <br/>directory?
					</div>
					<div className={styles.subTitle}>
						{
							"By joining the directory, you will become visible to other members and be able to message them."
						}
					</div>
					</>
					)
}