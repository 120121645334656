import { takeEvery, all, call, put, select } from "redux-saga/effects";
// import firebase, { firestore } from "firebase";

import { EFirebaseActionTypes, TStore } from "models";
import {
	//   reduxSagaFirebase,
	//   updateLocalFirebasePostDocument,
	//   updateLocalFirebasePostComments,
	//   addLocalFirebasePostComment,
	//   addLocalFirebasePostComments,
	api,
	addLocalPostComments,
	updateLocalPostComments,
} from "store";
import { rollbar } from "utils";

const getCommentsByPost = (state: TStore, postId: number) =>
	state.comments[postId];
const getCurrentCommunityId = (state: TStore) => state.app.currentCommunityId;

// function* fetchFirebaseDocumentHandle({ payload }: any) {
//   try {
//     let snapshot = yield call(
//       reduxSagaFirebase.firestore.getDocument,
//       `postsComments/${payload.id}`
//     );
//     if (!snapshot.data()) {
//       yield call(
//         reduxSagaFirebase.firestore.setDocument,
//         `postsComments/${payload.id}`,
//         {
//           count: 0,
//           authorId: payload.user.id,
//           authorName: payload.user.full_name,
//           authorAvatar: payload.user.picture,
//           createdAt: new Date(),
//         },
//         {}
//       );
//       snapshot = yield call(
//         reduxSagaFirebase.firestore.getDocument,
//         `postsComments/${payload.id}`
//       );
//     }
//     yield put(updateLocalFirebasePostDocument(payload.id, snapshot.data()));
//   } catch (error) {
//     console.error({ error });
//   }
// }

function* fetchPostCommentsHandle({ payload }: any) {
	try {
		const currentCommunityId = yield select(getCurrentCommunityId);
		const { data } = yield call(api.posts.getPostComments, {
			communityId: currentCommunityId,
			postId: payload,
			page: 1,
		});
		yield put(addLocalPostComments(payload, data));
	} catch (error) {
		rollbar.error(error)

	}
}

function* fetchMorePostCommentsHandle({ payload }: any) {
	try {
		const currentCommunityId = yield select(getCurrentCommunityId);
		const comments = yield select(getCommentsByPost, payload.postId);
		if (comments.meta.pagination.has_next_page) {
			const { data } = yield call(api.posts.getPostComments, {
				communityId: currentCommunityId,
				postId: payload.postId,
				page: parseInt(comments.meta.pagination.current_page) + 1,
			});
			yield put(updateLocalPostComments(payload.postId, data));
		}
	} catch (error) {
		rollbar.error(error)
		console.error({ error });
	}
}

// function* fetchFirebasePostCommentsHandle({ payload }: any) {
//   try {
//     const snapshot = yield call(
//       //@ts-ignore
//       reduxSagaFirebase.firestore.getCollection,
//       firestore()
//         .collection(`postsComments/${payload}/comments`)
//         .orderBy("createdAt", "desc")
//         .limit(3)
//     );
//     let comments: any[] = [];
//     snapshot.forEach((comment: any) => {
//       comments.push({
//         id: comment.id,
//         ...comment.data(),
//       });
//     });
//     yield put(addLocalFirebasePostComments(payload, comments.reverse()));
//   } catch (error) {}
// }

// function* fetchFirebaseMorePostComments({ payload }: any) {
//   try {
//     const { id, ...comment } = payload.commentDoc;
//     const snapshot = yield call(
//       //@ts-ignore
//       reduxSagaFirebase.firestore.getCollection,
//       firestore()
//         .collection(`postsComments/${payload.postId}/comments`)
//         .orderBy("createdAt", "desc")
//         .startAfter(comment.createdAt)
//         .limit(3)
//     );
//     let comments: any[] = [];
//     snapshot.forEach((comment: any) => {
//       comments.push({
//         id: comment.id,
//         ...comment.data(),
//       });
//     });
//     yield put(
//       updateLocalFirebasePostComments(payload.postId, comments.reverse())
//     );
//   } catch (error) {}
// }

// const getUser = (state: any) => state.auth.credentials.user;
// function* sendCommentForPostHandle({ payload }: any) {
//   try {
//     const user = yield select(getUser);
//     let comment = yield call(
//       reduxSagaFirebase.firestore.addDocument,
//       `postsComments/${payload.postId}/comments`,
//       {
//         message: payload.message,
//         authorId: user.id,
//         authorName: user.first_name + " " + user.last_name,
//         authorAvatar: user.picture,
//         createdAt: new Date(),
//       }
//     );
//     comment = yield comment.get();
//     yield put(
//       addLocalFirebasePostComment({
//         postId: payload.postId,
//         comment: {
//           id: comment.id,
//           ...comment.data(),
//         },
//       })
//     );
//     yield call(
//       // @ts-ignore
//       reduxSagaFirebase.firestore.setDocument,
//       `postsComments/${payload.postId}`,
//       { count: firebase.firestore.FieldValue.increment(1) },
//       { merge: true }
//     );
//   } catch (error) {
//     console.error({ error });
//   }
// }

export default function* rootSaga() {
	yield all([
		// takeEvery(
		//   EFirebaseActionTypes.fetchPostDocument,
		//   fetchFirebaseDocumentHandle
		// ),
		takeEvery(
			EFirebaseActionTypes.fetchMorePostComments,
			fetchMorePostCommentsHandle,
		),
		// takeEvery(
		//   EFirebaseActionTypes.fetchFirebasePostComments,
		//   fetchFirebasePostCommentsHandle
		// ),
		takeEvery(
			EFirebaseActionTypes.fetchPostComments,
			fetchPostCommentsHandle,
		),
		// takeEvery(
		//   EFirebaseActionTypes.sendFirebaseCommentForPost,
		//   sendCommentForPostHandle
		// ),
		// takeEvery(
		//   EFirebaseActionTypes.fetchFirebaseMorePostComments,
		//   fetchFirebaseMorePostComments
		// ),
	]);
}
