import { useMemo } from "react";

export const useParseCommunityTags = ({
	tags,
	search_tags,
}: {
	tags: string[];
	search_tags?: string[];
}) => {
	const parsedTags: string[] = useMemo(() => {
		if (
			(!tags || tags?.length === 0) &&
			(!search_tags || search_tags?.length === 0)
		) {
			return [];
		} else {
			if (search_tags && search_tags?.length > 0) {
				return search_tags.map(
					(item: string) => `#${item.toLowerCase()}`,
				);
			} else if (tags?.length > 0) {
				return tags.map((item: string) => `#${item.toLowerCase()}`);
			} else {
				return [];
			}
		}
	}, [search_tags, tags]);

	return { parsedTags };
};
