import { EAppActionTypes } from "models";

export const setAppLoading = (payload: boolean) => ({
	type: EAppActionTypes.appLoadingSet,
	payload,
});

export const SetWelcomeModal = (payload: boolean) => ({
	type: EAppActionTypes.SetWelcomeModal,
	payload,
})
export const setAppInit = (payload: any) => ({
	type: EAppActionTypes.appInitSet,
	payload,
});

export const appInit = (payload: any) => ({
	type: EAppActionTypes.appInit,
	payload,
});

export const appSetUser = (payload: {
	onErrorCallback: () => void;
	onSuccessCallback: () => void;
}) => ({
	type: EAppActionTypes.appSetUser,
	payload,
});
export const appSetData = (payload: any) => ({
	type: EAppActionTypes.appSetData,
	payload,
});
export const appSetGroup = (payload: { currentGroupId: number | null }) => ({
	type: EAppActionTypes.appSetGroup,
	payload,
});

export const appChangeCommunity = (payload: {
	communityId: number;
	dontRedirect?: boolean;
	onSuccessCallback?: () => void;
	onErrorCallback?: () => void;
}) => ({
	type: EAppActionTypes.appChangeCommunity,
	payload,
});

export const initOneSignalAction = () => ({
	type: EAppActionTypes.appInitOneSignal,
});

export const updateOneSignalId = (id: string) => ({
	type: EAppActionTypes.appOneSignalSetId,
	payload: id,
});

export const resetOneSignalId = () => ({
	type: EAppActionTypes.appOneSignalResetId,
});

export const setOneSignalInitialized = (payload: boolean) => ({
	type: EAppActionTypes.appOneSignalSetInitialized,
	payload,
});

export const resetStore = (payload?: any) => ({
	type: EAppActionTypes.resetStore,
	payload,
});

export const resetStoreSuccess = () => ({
	type: EAppActionTypes.resetStoreSuccess,
});

export const appSetFeedPostsContainerWidth = (payload: {
	containerWidth: number;
}) => ({
	type: EAppActionTypes.setFeedPostsContainerWidth,
	payload,
});

export const appSiderMenuSetShowJoinedCommunitiesList = (payload: boolean) => ({
	type: EAppActionTypes.siderMenuSetShowJoinedCommunitiesList,
	payload,
});
export const appSiderMenuSetCollapsed = (payload: boolean) => ({
	type: EAppActionTypes.siderMenuSetCollapsed,
	payload,
});
