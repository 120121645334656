import { axiosInstance } from "..";

/*{
	communityId: number;
	userId: number;
	recipientId: number;
	postId: number;
	content: string;
	conversationId: number;
  }*/

export const messages = {
	sendMessage: (payload: any) => {
		// let data: { [key: string]: string | number | boolean } = {
		let data: any = {
			body: payload.content,
			recipient_id: payload.recipientId,
			from_post: false,
		};
		if (payload.postId) {
			data.community_post_id = payload.postId;
			data.from_post = true;
		} else {
			data.conversation_id = payload.conversationId;
			data.community_post_id = 0;
		}

		return axiosInstance().post(
			`/communities/${payload.communityId}/users/${payload.userId}/conversations`,
			data,
		);
	},
	openConversation: (payload: {
		communityId: number;
		userId: number;
		recipientId: number;
		communityPostId: number;
		conversationId: number;
		page: number;
	}) =>
		axiosInstance().put(
			`/communities/${payload.communityId}/community-posts/${payload.communityPostId}/open`,
			{
				recipient_id: payload.recipientId,
				conversation_id: payload.conversationId,
			},
		),
	getConversation: (payload: {
		communityId: number;
		userId: number;
		recipientId: number;
		communityPostId: number;
		conversationId: number;
		page: number;
	}) =>
		axiosInstance().get(
			`/communities/${payload.communityId}/users/${payload.userId}/conversations/for`,
			{
				params: {
					recipient_id: payload.recipientId,
					community_post_id: payload.communityPostId,
					conversation_id: payload.conversationId,
					page: payload.page,
				},
			},
		),
	getConversations: (payload: {
		communityId: number;
		userId: number;
		page: number;
	}) =>
		axiosInstance().get(
			`/communities/${payload.communityId}/users/${payload.userId}/my-activity`,
			{
				params: {
					page: payload.page,
				},
			},
		),
};
