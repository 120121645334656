import {
	ISetVideoCreatedIntervalAction,
	ISetVideoIdAction,
	ISetVideoSuccessfullyUploadedAction,
	ISetVideoUploadProgressAction,
	IVideoLoadingSetAction,
	IVideoSetAction,
} from "./actions";

// ENUMS
export enum EVideoActionTypes {
	videoLoadingSet = "video/set/loading",
	setVideo = "video/set",
	getVideo = "video/get",
	createVideo = "video/create",
	setVideoUploadProgress = "video/set/upload/progress", // used for progress bar
	setVideoId = "set/video/id", // used for progress bar
	setVideoSuccessfullyUploaded = "set/video/successfully/uploaded", // used for progress bar
	getVideoForProgressbar = "get/video/for/progressbar", // used for progress bar
	setInterval = "set/video/createdInterval", // used for progress bar
}

// INTERFACES
export interface IVideo {
	attachment: { url: string };
	description: string;
	long_description: string;
	id: number;
	mime_type: string;
	original_filename: string;
	search_tags: string[];
	title: string;
	videoType?: string;
	videoExtension?: string;
	is_like: boolean;
	member_type_list: string[];
	mentions: { full_name: string; id: string }[];
	tags: string[];
	thumbnail: string;
	video_title: string;
	video_link: string;
	folder_id: null | number;
	video_uid?: string;
	vimeo_thumbnail_url?: null | string;
	vimeo_uri?: null | string;
	resource_embed_uri?: null | string;
}

export interface IVideoState {
	loading: boolean;
	data?: IVideo;
	currentId?: number;
	uploadProgress: number | undefined; // used for progress bar
	videoId: number | undefined; // used for progress bar
	videoSuccessfullyUploaded: boolean | undefined; // used for progress bar
	createdInterval: number | undefined; // used for progress bar
}

export interface IVideoLoadingPayload {
	loading: boolean;
}

export interface ICreateVideoPayload {
	title: string;
	video?: any[];
	video_link?: string | null;
	longDescription?: string;
	expirationDate?: Date;
	audienceGroup?: string[];
	groupsGroup?: number[];
	tagsGroup?: string[];
	onError: () => void;
	onSuccess: (e?: boolean) => void;
	all_groups: boolean;
	is_private: boolean;
	parent: string;
	videoUploadProgress?: any;
	resourceUploadProgress?: any;
}

export interface ICreateVideoServicePayload {
	communityId: number;
	title: string;
	description?: string;
	expiry_date?: Date;
	is_private: boolean;
	video_link?: string | null;
	folder_id: number;
	member_type_list: string[] | [];
	group_ids: number[] | [];
	tag_list?: string[];
	[key: string]: any;
}

export interface IUploadVideoServicePayload {
	communityId: number;
	videoId?: number;
	resourceId?: number;
	video: any;
	videoUploadProgress?: any;
}

// TYPES
export type TVideoActions =
	| IVideoLoadingSetAction
	| IVideoSetAction
	| ISetVideoUploadProgressAction
	| ISetVideoIdAction
	| ISetVideoSuccessfullyUploadedAction
	| ISetVideoCreatedIntervalAction;
