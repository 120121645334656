import { IFolder } from "components";
import { IVideo } from "models";
import { EGroupPrivacy } from "models/groups";
import {
	IVideosLoadingSetAction,
	IVideosGetAction,
	IVideosSetAction,
	IVideosFilterTagsSetAction,
	IVideosFilterTagsSetInStoreAction,
	ISetVideosQueryAction,
	IVideosIncrementVideoViewsAction,
	IUpdateVideosCurrentFolderAction,
	IVideosSetOrderAction,
	IVideosSetPathAction,
	ISetRedirectQuery,

} from "./actions";

// ENUMS
export enum EVideosActionTypes {
	videosLoadingSet = "videos/set/loading",
	getVideos = "videos/get",
	setVideos = "videos/set",
	setFilterTags = "videos/filter/tags/set",
	setFilterTagsInStore = "videos/filter/tags/set/inStore",
	setQueryAndFilter = "videos/query/setAndFilter",
	setVideosQuery = "videos/query/set",
	setVideosCurrentFolder = "videos/currentFolder/set",
	updateVideosCurrentFolder = "videos/currentFolder/update",
	incrementVideoViews = "videos/increment/views",
	updateVideosOrder = "videos/order/update",
	setVideosOrder = "videos/order/set",
	setPath = "videos/path/set",
	deleteVideos = 'videos/delete',
	setRedirectQuery = 'videos/setRedirectQuery'
}

// INTERFACES
export interface IVideosState {
	loading: boolean;
	path: IFolder[];
	data?: IVideo[];
	selectedTags: string[];
	redirectQuery?: string | null;
	query: string;
	currentFolder: undefined | number;
	order: "view_count" | "created_at" | "title" | "latest";
	order_dir: "asc" | "desc";
	meta: {
		pagination: {
			current_page: number;
			per_page: number;
			total_pages: number;
			total_objects: number;
			has_next_page: boolean;
		};
		scope_group: {
			id: number;
			name: string;
			description: string;
			privacy: EGroupPrivacy;
		};
		folders:
		| null
		| {
			children: any[];
			id: number;
			name: string;
			path: string;
		}[];
	};
}

// TYPES
export type TVideosActions =
	| IVideosSetAction
	| IVideosLoadingSetAction
	| IVideosGetAction
	| IVideosFilterTagsSetAction
	| IVideosFilterTagsSetInStoreAction
	| ISetVideosQueryAction
	| IVideosIncrementVideoViewsAction
	| IUpdateVideosCurrentFolderAction
	| IVideosSetOrderAction
	| ISetRedirectQuery
	| IVideosSetPathAction;
