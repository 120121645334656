import { all, call, put, select, take, takeLatest } from "redux-saga/effects";

import {
    EAuthActionTypes,
    ECommunityAccessStatus,
    ECommunityType,
    EPaymentsTypes,
    ESubscriptionStatus,
    ICommunity,
    ICommunityMemberPlan,
    ISelectedCommunityToJoin,
    IUser,
    TCurrentSubscription,
    TStore,
} from "models";
import {
    api,
    paymentsCreateMemberSubscription,
    paymentsSetMemberSubscription,
    resumePaymentProcessAction,
    setSelectedCommunityToJoinMemberPlans,
    setSelectedCommunityToJoinDetails,
    changeTheMemberSubscriptionToPendingAction,
    setTheMemberSubscriptionToPendingAction,
    completePaymentProcessSagaAction,
    appChangeCommunity,
    changeSubscriptionPlanSagaAction,
    getUserCurrentSubscriptionAction,
    changeSubscriptionPlanWithPaymentIntentAction,
    initSelectedCommunityToJoinProgressBar,
    startTheJoinCommunityProcessAction,
    setSelectedCommunityToJoinMemberPlan,
    history,
} from "store";
import { rollbar } from "utils";

const getCommunityId = (state: TStore) => state.app.currentCommunityId;
const selectSelectedCommunityToJoinInfo = (state: TStore) =>
    state.communities.selectedCommunityToJoinInfo;
const getCurrentSubscription = (state: TStore) =>
    state.auth.credentials?.user?.current_subscription;
const getAuthenticatedUser = (state: TStore) => state.auth.credentials?.user;

function* createMemberSubscriptionHandle({
    payload,
}: ReturnType<typeof paymentsCreateMemberSubscription>) {
    const {
        stripePlanPriceId,
        communityId,
        onSuccessCallback,
        onErrorCallback,
    } = payload;
    try {
        if (!communityId || !stripePlanPriceId) {
            throw new Error("Parameter missing or invalid!");
        }
        // this block of code is executed whenever a new subscription is created
        // or a subscription that is in pending have to be changed back to inactive status because of a failed transactions.
        // note that before any transaction is started the subscription's status is changed to pending.
        const { data } = yield call(api.payments.createMemberSubscribe, {
            communityId,
            stripe_plan_price_id: stripePlanPriceId,
        });

        if (data) {
            yield put(paymentsSetMemberSubscription(data));
            yield put(getUserCurrentSubscriptionAction({ communityId }));
            yield take(EAuthActionTypes.getUserCurrentSubscriptionCompleted);
            onSuccessCallback?.(data);
        }
    } catch (error) {
        rollbar.error(
            error
        )
        onErrorCallback?.();
    }
}

function* resumePaymentProcessHandle({
    payload,
}: ReturnType<typeof resumePaymentProcessAction>) {
    const { communityId, onSuccessCallback, onErrorCallback } = payload;
    let selectedMemberPlan: ICommunityMemberPlan | undefined;

    try {
        if (!communityId) {
            throw new Error("Missing parameters!");
        }

        const {
            data: {
                meta: { community: selectedCommunity, appearance },
            },
        }: {
            data: {
                meta: {
                    community: ICommunity, appearance: {
                        id: number,
                        skill_tag_display_name: string
                    }
                }
            }
        } = yield call(
            api.community.getOneJoin,
            {
                community_id: communityId,
            },
        );

        if (
            !selectedCommunity ||
            selectedCommunity.access_status !==
            ECommunityAccessStatus.subscription_inactive
        ) {
            throw Error("Wrong community!");
        } else {
            yield put(
                setSelectedCommunityToJoinDetails({
                    data: {
                        meta: { community: selectedCommunity, appearance },
                    },
                    communityId,
                }),
            );

            const {
                data: memberPlansData,
            }: { data: { plans: ICommunityMemberPlan[] } } = yield call(
                api.community.getMemberPlans,
                {
                    communityId: selectedCommunity.id,
                },
            );

            yield put(
                setSelectedCommunityToJoinMemberPlans({
                    plans: memberPlansData.plans,
                }),
            );

            // check if member plan that has been selected by the user is still available (wasn't deleted or archived).
            if (!memberPlansData.plans || memberPlansData.plans.length === 0) {
                throw new Error("There is no member plans data.");
            }

            selectedMemberPlan = memberPlansData.plans.find((item) => {
                const { selected_member_plan } = selectedCommunity;
                if (!selected_member_plan) return false;
                const { id, price } = selected_member_plan;
                return item.id === id && price;
            });

            if (!selectedMemberPlan) {
                throw new Error("The selected plan is not available anymore.");
            }
            yield put(startTheJoinCommunityProcessAction(true));
            yield put(
                initSelectedCommunityToJoinProgressBar({
                    isConnectedCommunity:
                        selectedCommunity.uses_stripe ?? false,
                    hasGroups: !!Number(selectedCommunity.group_count),
                    isTheProcessResumed: true,
                }),
            );
            yield put(
                setSelectedCommunityToJoinMemberPlan({
                    plan: selectedMemberPlan,
                }),
            );
        }

        if (onSuccessCallback) {
            onSuccessCallback();
        }
    } catch (error) {
        rollbar.error(
            error
        )
        if (onErrorCallback) {
            onErrorCallback({
                isTheSelectedMemberPlanAvailable: Boolean(selectedMemberPlan),
            });
        }
        console.error({ error });
    }
}

function* changeMemberSubscriptionToPendingHandle({
    payload,
}: ReturnType<typeof changeTheMemberSubscriptionToPendingAction>) {
    const { subscriptionId, communityId, onErrorCallback, onSuccessCallback } =
        payload;
    try {
        if (!communityId || !subscriptionId) {
            throw new Error("Parameter missing or invalid!");
        }
        const {
            data,
        }: {
            data: {
                show_url: string;
                status: "success";
                subscription_id: number;
            };
        } = yield call(api.payments.subscriptionPending, {
            communityId,
            subscriptionId,
        });
        if (data.status === "success") {
            yield put(
                setTheMemberSubscriptionToPendingAction({
                    subscription_status: ESubscriptionStatus.pending,
                }),
            );
        }
        onSuccessCallback?.();
    } catch (error) {
        rollbar.error(
            error
        )
        console.error({ error });
        onErrorCallback?.();
    }
}

function* completeThePaymentProcessHandle({
    payload,
}: ReturnType<typeof completePaymentProcessSagaAction>) {
    const { typeOfCommunity, onErrorCallback, onSuccessCallback } = payload;

    const timeoutResolver: any = (ms: number) => {
        return new Promise((resolve) => {
            setTimeout(function () {
                resolve(true);
            }, ms);
        });
    };
    try {
        let noOfCalls = 0;
        const selectedCommunityToJoin: ISelectedCommunityToJoin = yield select(
            selectSelectedCommunityToJoinInfo,
        );

        const communityId: number = yield select(getCommunityId);

        let resultedCommunityId =
            typeOfCommunity === ECommunityType.join && selectedCommunityToJoin
                ? selectedCommunityToJoin.id
                : communityId;
        if (!resultedCommunityId) {
            throw new Error("Missing community!");
        }

        while (noOfCalls < 3) {
            try {
                yield call(timeoutResolver, 4000);
                yield call(api.community.home, {
                    community_id: resultedCommunityId,
                });

                if (typeOfCommunity === ECommunityType.join) {
                    yield put(
                        appChangeCommunity({
                            communityId: resultedCommunityId,
                        }),
                    );
                } else {
                    yield history.push("/dashboard");
                }
                break;
            } catch (error: any) {
                rollbar.error(
                    error
                )
                if (
                    error?.response?.data?.meta?.access_status ===
                    ECommunityAccessStatus.subscription_pending
                ) {
                    noOfCalls++;
                } else {
                    if (typeOfCommunity === ECommunityType.join) {
                        yield put(
                            appChangeCommunity({
                                communityId: resultedCommunityId,
                            }),
                        );
                    } else {
                        yield history.push("/dashboard");
                    }
                    break;
                }
            }
        }
        onSuccessCallback?.();
    } catch (error) {
        onErrorCallback?.();
        console.error(error);
    }
}
function* changeSubscriptionHandle({
    payload,
}: ReturnType<typeof changeSubscriptionPlanSagaAction>) {
    const { onErrorCallback, onSuccessCallback, stripePlanPriceId } = payload;
    const communityId: number = yield select(getCommunityId);
    const currentSubscription: TCurrentSubscription = yield select(
        getCurrentSubscription,
    );
    const authenticatedUser: IUser = yield select(getAuthenticatedUser);

    try {
        if (
            !communityId ||
            typeof currentSubscription !== "object" ||
            !currentSubscription?.id ||
            !stripePlanPriceId
        ) {
            throw new Error("Missing parameters!");
        } else {
            console.log(
                "%c Mihai-Log",
                "color:blue; font-style: italic; font-size:26px;",
                { payload },
            );

            const { data } = yield call(api.payments.changeMemberSubscription, {
                communityId,
                subscriptionId: currentSubscription.id,
                stripe_plan_price_id: stripePlanPriceId,
            });

            if (data?.stripe_errors) {
                const { data: createSubscription } = yield call(
                    api.payments.createMemberSubscribe,
                    {
                        communityId,
                        stripe_plan_price_id: stripePlanPriceId,
                    },
                );

                console.log(
                    "%c Mihai-Log",
                    "color:#2171b1; font-style: italic; font-size:26px;",
                    { createSubscription },
                );
                console.log({ createSubscription });
                yield put(paymentsSetMemberSubscription(createSubscription));
            } else {
                yield put(paymentsSetMemberSubscription(data));
            }

            yield put(
                getUserCurrentSubscriptionAction({
                    communityId,
                    userId: authenticatedUser.id,
                }),
            );
            yield take(EAuthActionTypes.getUserCurrentSubscriptionCompleted);

            onSuccessCallback?.();
        }
    } catch (error: any) {
        rollbar.error(
            error
        )
        onErrorCallback?.();
        console.error({ error });
    }
}

function* changeSubscriptionPlanWithPaymentIntentHandle({
    payload,
}: ReturnType<typeof changeSubscriptionPlanWithPaymentIntentAction>) {
    const {
        onErrorCallback,
        onSuccessCallback,
        stripePlanPriceId,
        currentSubscriptionId,
        communityId,
    } = payload;

    try {
        if (!communityId || !currentSubscriptionId || !stripePlanPriceId) {
            throw new Error("Missing parameters!");
        } else {
            // change the plan

            yield call(api.payments.changeMemberSubscription, {
                communityId,
                subscriptionId: currentSubscriptionId,
                stripe_plan_price_id: stripePlanPriceId,
            });

            onSuccessCallback?.();
        }
    } catch (error: any) {
        rollbar.error(
            error
        )
        console.error({ error });
        onErrorCallback?.();
    }
}

export default function* rootSaga() {
    yield all([
        takeLatest(
            EPaymentsTypes.createMemberSubscription,
            createMemberSubscriptionHandle,
        ),
        takeLatest(
            EPaymentsTypes.resumePaymentProcess,
            resumePaymentProcessHandle,
        ),
        takeLatest(
            EPaymentsTypes.changeTheMemberSubscriptionToPending,
            changeMemberSubscriptionToPendingHandle,
        ),
        takeLatest(
            EPaymentsTypes.completeThePaymentProcess,
            completeThePaymentProcessHandle,
        ),
        takeLatest(
            EPaymentsTypes.changeSubscriptionPlan,
            changeSubscriptionHandle,
        ),
        takeLatest(
            EPaymentsTypes.changeSubscriptionPlanWithPaymentIntent,
            changeSubscriptionPlanWithPaymentIntentHandle,
        ),
    ]);
}
