import { ILinkedCommunityDocuments } from "models";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
	notify,
	setDocsQueryAndFilterAction,
	setMediaPathAction,
	setResourcesQueryAndFilterAction,
	setVideosQueryAndFilterAction,
} from "store";

import styles from "./styles.module.scss";

interface ILinkTag {
	href: string;
	children?: string;
	linked_community_documents?: ILinkedCommunityDocuments[];
	id?:string
}

const LinkTag: React.FunctionComponent<ILinkTag> = (props) => {
	const { children, linked_community_documents, href,id } = props;

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleOnClick = useCallback(() => {
		if (linked_community_documents?.length) {
			// In the dashboard ecosystem, a user can add a document, video, or resource as an announcement.
			// The announcement will be shown on the dashboard page and the linked_community_documents will contain data about how the document can be accessed in the videos, documents, or resources screens
			// Keep in mind that the announcement has a default description and cannot contains another links

			const linked_community_document = linked_community_documents[0];

			if (
				linked_community_document.document_section_name ===
					"Documents" ||
				linked_community_document.document_section_name ===
					"Resources" ||
				linked_community_document.document_section_name === "Videos"
			) {
				const folderDataId = linked_community_document?.folder_id;
				const folderDataTitle = linked_community_document?.title;
				let folderPath: any = [];
				if (
					folderDataId &&
					linked_community_document?.folder_tree &&
					(linked_community_document?.folder_tree?.ancestors
						?.length ||
						linked_community_document?.folder_tree?.subtree?.length)
				) {
					const getTheFolderPath = ({ tree }: { tree: any }) => {
						if (tree) {
							folderPath.push(tree);
						}

						if (tree.id === folderDataId) {
							return;
						}

						if (tree?.children?.length) {
							getTheFolderPath({
								tree: tree.children[0],
							});
						} else {
							return;
						}
					};

					if (linked_community_document.folder_tree?.ancestors?.[0]) {
						getTheFolderPath({
							tree: linked_community_document.folder_tree
								.ancestors[0],
						});
					}
					if (linked_community_document.folder_tree?.subtree?.[0]) {
						getTheFolderPath({
							tree: linked_community_document.folder_tree
								.subtree[0],
						});
					}
				}
				
				if (
					linked_community_document.document_section_name ===
					"Documents"
				) {
					
					dispatch(setDocsQueryAndFilterAction(folderDataTitle));
				}
				 else if (
					linked_community_document.document_section_name === "Videos"
				) {

					dispatch(setVideosQueryAndFilterAction(folderDataTitle));
				}
				 else if (
					linked_community_document.document_section_name ===
					"Resources"
				) {
					dispatch(
						setResourcesQueryAndFilterAction({
							query: folderDataTitle,
						}),
					);
				}
				if (folderPath.length > 0) {
					dispatch(
						setMediaPathAction({
							type: linked_community_document.document_section_name
								.toLowerCase()
								.trim(),
							path: folderPath,
						}),
					);
				}

				navigate(!id?`${href}/${linked_community_document.id}`:href,{state:linked_community_document.id});
			}
		} else if (href) {
			// if there is no linked document data it means that we have a simple link
			window.open(href, "_blank");
		} else {
			notify("error", "An error occurred. Please try again later.");
		}
	}, [dispatch, navigate, href, linked_community_documents,id]);

	return (
		<span className={styles.anchor} onClick={handleOnClick}>
			{children}
		</span>
	);
};

export default LinkTag;
