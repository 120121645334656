import { Checkbox } from "antd"
import { CheckboxGroupProps } from "antd/lib/checkbox"
import { ReactNode } from "react"
import styles from './styles.module.scss'
interface ICustomCheckBox {
    children?:ReactNode
    options?:string[]
}
const CustomCheckBok =({children,options,...props}:ICustomCheckBox & CheckboxGroupProps) => {
    
    return (
        <div className={styles.CustomCheckBox} >
        <Checkbox.Group  {...props}>
       {options?.map((option)=> <Checkbox value={option}>
            {option}
            {children}
        </Checkbox>)}
        </Checkbox.Group>
        </div>
    )
}
export default CustomCheckBok