import { memo, useCallback, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu } from "antd";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import classNames from "classnames";

import { Loading } from "components";
import { TStore } from "models";
import { ReactComponent as LinkIcon } from "assets/images/icons/menu_icons/link.svg";
import { ReactComponent as ChartLineUpLight } from "assets/images/icons/menu_icons/feedIcon.svg";
import { ReactComponent as UsersLight } from "assets/images/icons/menu_icons/users-light.svg";
import { ReactComponent as GiftCard } from "assets/images/icons/menu_icons/gift-card-light.svg";
import { ReactComponent as GroupLight } from "assets/images/icons/menu_icons/user-group-light.svg";
import { ReactComponent as CalendarDays } from "assets/images/icons/menu_icons/calendar-days-light.svg";
import { ReactComponent as Resources } from "assets/images/icons/menu_icons/photo-film-music-light.svg";
import { ReactComponent as Video } from "assets/images/icons/menu_icons/video-light.svg";
import { ReactComponent as Document } from "assets/images/icons/menu_icons/file-lines-light.svg";
import { ReactComponent as Store } from "assets/images/icons/menu_icons/store-light.svg";
import styles from "./styles.module.scss";
import { useDispatch } from "react-redux";
import { appSiderMenuSetCollapsed } from "store";

// const { SubMenu } = Menu;

const stateSelectorHandle = createSelector(
	(state: TStore) => state.posts.meta.appearance,
	(state: TStore) => state.community?.data?.services,
	(state: TStore) => state.app.currentGroupId,
	(state: TStore) => state.app.siderMenu.collapsed,

	(appearance, services, currentGroupId, collapsed) => ({
		appearance,
		services,
		currentGroupId,
		collapsed,
	}),
);

const SiderCustomMenu = ({ handleTriggerDrawerMenu, absoluteSideBar }) => {
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { appearance, services, currentGroupId, collapsed } =
		useSelector(stateSelector);
	const dispatch = useDispatch();
	let location = useLocation();

	const MenuItems = useMemo(() => {
		const map = {
			Posts: {
				name: "Feed",
				link: "/dashboard",
				iconComponent: <ChartLineUpLight style={{}} />,
				disabled: false,
			},
			Groups: {
				name: "Groups",
				link: "/groups",
				iconComponent: (
					<GroupLight
						style={{
							width: "24px",
							height: "24px",
							minWidth: "24px",
							minHeight: "24px",
						}}
					/>
				),
				disabled: false,
			},
			Recommendations: {
				name: appearance?.offer_display_name.plural,
				link: "/offers",
				iconComponent: (
					<GiftCard
						style={{
							width: "24px",
							height: "24px",
							minWidth: "24px",
							minHeight: "24px",
						}}
					/>
				),
				disabled: false,
			},
			Directory: {
				name: appearance?.member_directory_display_name?.plural,
				link: "/members",
				iconComponent: (
					<UsersLight
						style={{
							width: "24px",
							height: "24px",
							minWidth: "24px",
							minHeight: "24px",
						}}
					/>
				),
				disabled: false,
			},
			Calendar: {
				name: "Calendar",
				link: "/calendar",
				iconComponent: (
					<CalendarDays
						style={{
							width: "24px",
							height: "24px",
							minWidth: "24px",
							minHeight: "24px",
						}}
					/>
				),
				disabled: false,
			},
			Resources: {
				name: appearance?.resource_display_name || "Resources",
				link: "/resources",
				iconComponent: (
					<Resources
						style={{
							width: "24px",
							height: "24px",
							minWidth: "24px",
							minHeight: "24px",
						}}
					/>
				),
				disabled: false,
			},
			Videos: {
				name: appearance?.video_display_name?.plural,
				link: "/videos",
				iconComponent: (
					<Video
						style={{
							width: "24px",
							height: "24px",
							minWidth: "24px",
							minHeight: "24px",
						}}
					/>
				),
				disabled: false,
			},
			Docs: {
				name: appearance?.document_display_name?.plural,
				link: "/documents",
				iconComponent: (
					<Document
						style={{
							width: "24px",
							height: "24px",
							minWidth: "24px",
							minHeight: "24px",
						}}
					/>
				),
				disabled: false,
			},
			Goods: {
				name: appearance?.marketplace_display_name.singular,
				link: "/marketplace",
				iconComponent: (
					<Store
						style={{
							width: "24px",
							height: "24px",
							minWidth: "24px",
							minHeight: "24px",
						}}
					/>
				),
				disabled: false,
			},
			Weblink: {
				iconComponent: (
					<LinkIcon
						style={{
							width: "24px",
							height: "24px",
						}}
						fill={"white"}
					/>
				),
				name: appearance?.weblink_display_name,
				link:
					services &&
					!!services.length &&
					services.find(
						(service: {
							label: string;
							show: boolean;
							url: string;
						}) => service.label === "Weblink",
					).url,
				weblink: true,
			},
			// About: {
			// 	iconComponent: (
			// 		<ContainerOutlined
			// 			style={{
			// 				width: '24px',
			// 				height: '24px'
			// 			}}
			// 		// style={{ width: "auto", marginRight: 10 }}
			// 		/>
			// 	),
			// 	name: "About Us",
			// 	link: "/community/about-us",
			// },
		};

		if (services && !!services.length)
			services.map(
				(service: { label: string; show: boolean; url: string }) => {
					if (!service.show && map[service.label]) {
						delete map[service.label];
					}

					return undefined;
				},
			);

		if (currentGroupId) {
			return Object.values(map).filter(
				(item: any) => item.link !== "/groups",
			);
		} else {
			return Object.values(map);
		}
	}, [appearance, services, currentGroupId]);

	return (
		<>
			<Menu
				mode="inline"
				theme="dark"
				selectedKeys={["/" + location?.pathname?.split("/")[1]]}
				className={classNames(styles.siderCustomMenuContainer, {
					[styles.groupSiderCustomMenuBackground]: currentGroupId,
					[styles.absoluteMenuContainer]: absoluteSideBar,
					[styles.menuContainer]: !absoluteSideBar,
				})}
			>
				{appearance ? (
					MenuItems.map(
						(menuItem: {
							name: string | undefined;
							link: string;
							iconComponent: any;
							disabled?: boolean;
							weblink?: boolean;
						}) => (
							<Menu.Item
								className={styles.menuItem}
								key={menuItem.link}
								disabled={menuItem.disabled}
							>
								{!menuItem?.weblink ? (
									<Link to={menuItem.link}>
										<span className={styles.linkItem}>
											{menuItem.iconComponent
												? menuItem.iconComponent
												: null}
											{!absoluteSideBar && menuItem.name}
										</span>
									</Link>
								) : (
									<a
										target={"_blank"}
										href={menuItem.link}
										className={styles.defaultLinks}
										rel="noreferrer"
									>
										{menuItem.iconComponent
											? menuItem.iconComponent
											: null}
										{!absoluteSideBar && menuItem.name}
									</a>
								)}
							</Menu.Item>
						),
					)
				) : (
					<Loading />
				)}
			</Menu>
			{!collapsed && !absoluteSideBar && (
				<Menu
					mode="inline"
					theme="dark"
					selectedKeys={["/" + location?.pathname?.split("/")[1]]}
					className={
						absoluteSideBar
							? styles.absoluteMenuContainer
							: styles.mobileMenuContainer
					}
					onClick={() => {
						dispatch(appSiderMenuSetCollapsed(true));
					}}
				>
					{appearance ? (
						MenuItems.map(
							(menuItem: {
								name: string | undefined;
								link: string;
								iconComponent: any;
								disabled?: boolean;
								weblink?: boolean;
							}) => (
								<Menu.Item
									className={styles.menuItem}
									key={menuItem.link}
									disabled={menuItem.disabled}
								>
									{!menuItem?.weblink ? (
										<Link to={menuItem.link}>
											<span className={styles.linkItem}>
												{menuItem.iconComponent
													? menuItem.iconComponent
													: null}
												{!absoluteSideBar &&
													menuItem.name}
											</span>
										</Link>
									) : (
										<a
											target={"_blank"}
											href={menuItem.link}
											className={styles.defaultLinks}
											rel="noreferrer"
										>
											{menuItem.iconComponent
												? menuItem.iconComponent
												: null}
											{!absoluteSideBar && menuItem.name}
										</a>
									)}
								</Menu.Item>
							),
						)
					) : (
						<Loading />
					)}
				</Menu>
			)}
		</>
	);
};

export default memo(SiderCustomMenu);
