import React, {
	memo,
	useCallback,
	useState,
	useMemo,
	useEffect,
	useRef,
} from "react";
import {
	Form,
	Button,
	Input,
	Row,
	Col,
	Radio,
	Select,
	DatePicker,
	AutoComplete,
} from "antd";
import { CreditCardOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import moment from "moment";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import { Mention, MentionsInput, SuggestionDataItem } from "react-mentions";

import { IMarketplaceOfferEditData, TStore } from "models";
import { CheckboxGroupView } from "components";
import { axiosInstance } from "store/services";
import { disabledDate, rollbar } from "utils";
import styles from "./styles.module.scss";

const { Option } = Select;

const stateSelectorHandle = createSelector(
	(state: TStore) => state.community.mainTags,
	(state: TStore) => state.marketplaceOffers?.tags,
	(state: TStore) => state.auth?.credentials?.user?.membership_type,
	(state: TStore) => state.members.users,
	(state: TStore) => state.app.currentGroupId,
	(
		communityTags,
		marketplaceTags,
		authUserMembership,
		members,
		currentGroupId,
	) => ({
		communityTags,
		marketplaceTags,
		authUserMembership,
		members,
		currentGroupId,
	}),
);

const DESCRIPTION_MAX_NO_OF_CHARACTERS = 5000;
interface IFormBox {
	marketplaceOffer?: IMarketplaceOfferEditData;
}

const FormBox = ({ marketplaceOffer }: IFormBox) => {
	const [form] = Form.useForm();
	const [locations, setLocations] = useState<any>([]);
	const [locationDetails, setLocationDetails] = useState<{
		lat: number;
		lng: number;
		postcode: string;
	}>({ lat: 0, lng: 0, postcode: "" });
	const [
		descriptionNoOfCharactersRemaining,
		setDescriptionNoOfCharactersRemaining,
	] = useState(DESCRIPTION_MAX_NO_OF_CHARACTERS);
	const { state }: { state: "Sell" | "Buy" | "Share" } = useLocation();
	const suggestionsPortalHostRef = useRef<any>(undefined);
	const [marketplaceOfferType, setMarketplaceOfferType] = useState<
		"Sell" | "Buy" | "Share"
	>(state as ("Sell" | "Buy" | "Share") | "Sell");
	const stateSelector = useCallback(stateSelectorHandle, []);
	const {
		communityTags,
		marketplaceTags,
		authUserMembership,
		members,
		currentGroupId,
	} = useSelector(stateSelector);
	const watch = Form.useWatch("audienceCheckboxGroup", form);

	const users = useMemo(() => {
		return members?.map((item) => {
			return { id: item.id, display: item.full_name };
		});
	}, [members]);

	const onValuesChange = useCallback(
		(changedValues, allValues) => {
			switch (Object.keys(changedValues)[0]) {
				case "audienceCheckAll": {
					form.setFieldsValue({
						audienceCheckboxGroup: communityTags.memberTypes.map(
							(item: any) => item.name,
						),
					});

					break;
				}
				case "audienceCheckNone": {
					form.setFieldsValue({
						audienceCheckboxGroup: [authUserMembership],
					});

					break;
				}
				case "groupsCheckAll": {
					form.setFieldsValue({
						groupsCheckboxGroup: communityTags.groups.map(
							(item: any) => item.id,
						),
					});

					break;
				}
				case "groupsCheckNone": {
					form.setFieldsValue({
						groupsCheckboxGroup: [],
					});

					break;
				}
				case "audienceCheckboxGroup": {
					if (
						changedValues.audienceCheckboxGroup.length ===
						communityTags.memberTypes.length
					) {
						form.setFieldsValue({
							audienceCheckAll: true,
						});
					} else {
						form.setFieldsValue({
							audienceCheckAll: false,
						});
					}
					break;
				}
				case "groupsCheckboxGroup": {
					if (
						changedValues.groupsCheckboxGroup.length ===
						communityTags.groups.length
					) {
						form.setFieldsValue({
							groupsCheckAll: true,
						});
					} else {
						form.setFieldsValue({
							groupsCheckAll: false,
						});
					}
					break;
				}
				case "goodType": {
					setMarketplaceOfferType(changedValues.goodType);
					break;
				}
				case "location": {
					var parsed = changedValues.location;

					try {
						parsed = JSON.parse(changedValues.location);
						setLocationDetails({
							...locationDetails,
							postcode: parsed.address,
							lat: parsed.latitude,
							lng: parsed.longitude,
						});
						form.setFieldsValue({
							location: parsed.address,
							postcode: parsed.address,
							postcodeLat: parsed.latitude,
							postcodeLng: parsed.longitude,
						});
					} catch (error) {
						rollbar.error(error);
						console.error({ error });
					}
					break;
				}
				case "expiryDate": {
					if (moment.isMoment(changedValues.expiryDate)) {
						form.setFieldsValue({
							expiryDate: changedValues.expiryDate.endOf("day"),
						});
					} else {
						form.setFieldsValue({
							expiryDate: undefined,
						});
					}
					break;
				}
				default:
			}
		},
		[
			authUserMembership,
			communityTags.groups,
			communityTags.memberTypes,
			form,
			locationDetails,
		],
	);

	const handleChangePostBody = useCallback(
		(event: { target: { value: string } } | undefined) => {
			if (!event) return;
			let parsedBody = event.target.value;
			if (event.target.value.length > 5000) {
				parsedBody = event.target.value.substring(0, 5000);
			}
			setDescriptionNoOfCharactersRemaining(5000 - parsedBody.length);

			form.setFieldsValue({
				details: parsedBody,
			});
		},
		[form],
	);

	const handleRenderSuggestions = useCallback(
		(
			suggestion: SuggestionDataItem,
			search: string,
			highlightedDisplay: React.ReactNode,
			index: number,
			focused: boolean,
		) => {
			return (
				<div
					key={`key_${index}`}
					className={classNames(styles.listItem, {
						[styles.listItemFocused]: focused,
					})}
				>
					{highlightedDisplay}
				</div>
			);
		},
		[],
	);

	const locationsOptions =
		locations &&
		locations.map((item: { label: string; value: string }) => {
			return (
				<Option key={item.label} value={item.value}>
					{item.label}
				</Option>
			);
		});

	const onSearch = useCallback((val: string) => {
		axiosInstance()
			.get("/geocoder", {
				params: { address: val },
			})
			.then((data) => {
				const parseData =
					data &&
					data.data.results.map(
						(item: {
							address: string;
							latitude: number;
							longitude: number;
						}) => {
							const { address, latitude, longitude } = item;
							return {
								label: address,
								value: JSON.stringify({
									address,
									latitude,
									longitude,
								}),
							};
						},
					);
				setLocations(parseData);
			})
			.catch((error) => {
				console.error({ error });
				rollbar.error(error);
				setLocations([]);
			});
	}, []);

	const initialValues = useMemo(() => {
		if (!marketplaceOffer) {
			return {
				audienceCheckAll: false,
				goodType: "Sell",
				currency: "£",
				description: "",
			};
		} else {
			const { title, description, website, good, expiration_date } =
				marketplaceOffer.community_post;

			const { type, currency, max_price, min_price, postcode } = good;
			const { groups, member_types, tags } = marketplaceOffer;
			const selectedGroups = groups
				.filter(
					(item: {
						id: number;
						name: string;
						is_selected: boolean;
					}) => {
						return item.is_selected;
					},
				)
				.map(
					(item: {
						id: number;
						name: string;
						is_selected: boolean;
					}) => {
						return item.id;
					},
				);
			const selectedAudience = member_types
				.filter(
					(item: {
						id: number;
						name: string;
						is_selected: boolean;
					}) => {
						return item.is_selected;
					},
				)
				.map(
					(item: {
						id: number;
						name: string;
						is_selected: boolean;
					}) => {
						return item.name;
					},
				);
			const selectedTags = tags
				.filter(
					(item: {
						id: number;
						name: string;
						is_selected: boolean;
					}) => {
						return item.is_selected;
					},
				)
				.map(
					(item: {
						id: number;
						name: string;
						is_selected: boolean;
					}) => {
						return item.name;
					},
				);

			return {
				audienceCheckAll: false,
				goodType: type,
				currency: currency,
				title,
				description,
				website,
				expiryDate: expiration_date ? moment(expiration_date) : null,
				minPrice: min_price,
				maxPrice: max_price,
				location: postcode,
				groupsCheckboxGroup: selectedGroups,
				audienceCheckboxGroup: selectedAudience,
				tagsCheckboxGroup: selectedTags,
			};
		}
	}, [marketplaceOffer]);

	useEffect(() => {
		form.setFieldValue("audienceCheckboxGroup", [authUserMembership]);
	}, [authUserMembership, form]);

	return (
		<div className={styles.form}>
			<Form
				initialValues={initialValues}
				name="fieldsForm"
				layout="vertical"
				onFinish={() => {}}
				form={form}
				onValuesChange={onValuesChange}
			>
				<Form.Item name="postcode" style={{ display: "none" }}>
					<Input type="text" />
				</Form.Item>
				<Form.Item name="postcodeLat" style={{ display: "none" }}>
					<Input type="text" />
				</Form.Item>
				<Form.Item name="postcodeLng" style={{ display: "none" }}>
					<Input type="text" />
				</Form.Item>
				<Row>
					<Col span={24}>
						<div className={styles.formTitle}>I would like to:</div>
						<div className={styles.radioButtonsBox}>
							<Form.Item name="goodType">
								<Radio.Group
									buttonStyle="solid"
									className={styles.radioButtons}
								>
									<Radio.Button
										className={styles.radioButton}
										value="Sell"
									>
										Sell
									</Radio.Button>
									<Radio.Button
										className={styles.radioButton}
										value="Buy"
									>
										Buy
									</Radio.Button>
									<Radio.Button
										className={styles.radioButton}
										value="Share"
									>
										Trade
									</Radio.Button>
								</Radio.Group>
							</Form.Item>
						</div>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Form.Item
							label="Title (Max 40 characters)"
							name="title"
							rules={[
								{
									required: true,
									message: "'Title' is required'",
								},
							]}
						>
							<Input maxLength={40} />
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Form.Item label="Website" name="website">
							<Input />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={8}>
					{marketplaceOfferType !== "Share" && (
						<>
							<Col span={24}>
								<div className={styles.priceInfoLabel}>
									<CreditCardOutlined
										className={styles.cardIcon}
									/>
									{marketplaceOfferType === "Sell" ? (
										<div className={styles.text}>
											{"The price for my item is:"}
										</div>
									) : marketplaceOfferType === "Buy" ? (
										<div className={styles.text}>
											{
												"The price I am willing to pay is:"
											}
										</div>
									) : (
										""
									)}
								</div>
							</Col>
							<Col span={3} className={styles.currencyWrapper}>
								<Form.Item
									className={styles.currency}
									label=""
									name="currency"
									rules={[{ required: true }]}
								>
									<Select>
										<Option value="£">£</Option>
										<Option value="$">$</Option>
										<Option value="€">€</Option>
									</Select>
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item
									className={styles.priceInput}
									label=""
									name="minPrice"
								>
									<Input placeholder={"0.00"} type="number" />
								</Form.Item>
							</Col>
							<Col span={2}>
								<div className={styles.minToMaxPriceLabel}>
									{"to"}
								</div>
							</Col>
							<Col span={6}>
								<Form.Item
									className={styles.priceInput}
									label=""
									name="maxPrice"
								>
									<Input placeholder={"0.00"} type="number" />
								</Form.Item>
							</Col>
						</>
					)}
				</Row>
				<Row>
					<Col span={24}>
						<Form.Item name="location" label="Location">
							<AutoComplete
								onSearch={onSearch}
								placeholder="Location"
							>
								{locationsOptions}
							</AutoComplete>
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col style={{ width: "100%" }}>
						<Form.Item name="expiryDate" label="Expiry Date">
							<DatePicker
								size="middle"
								disabledDate={disabledDate}
								showNow={false}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Form.Item
							name="description"
							label="Description (Max 5000 characters)"
							rules={[
								{
									required: true,
									message: "'Description' is required",
								},
							]}
							validateTrigger="onSubmit"
						>
							<MentionsInput
								onChange={handleChangePostBody}
								placeholder={
									"Posts with detailed descriptions get more views and replies."
								}
								className={"detailsInput"}
								classNames={styles}
								maxLength={5000}
								suggestionsPortalHost={
									suggestionsPortalHostRef.current
								}
								style={{
									suggestions: {
										list: {
											maxHeight: 300,
											overflowY: "auto",
										},
									},
								}}
							>
								<Mention
									trigger="@"
									data={users ? users : []}
									className={styles.mention}
									renderSuggestion={handleRenderSuggestions}
									appendSpaceOnAdd={true}
									displayTransform={(_, display: any) =>
										`@${display}`
									}
								/>
							</MentionsInput>
						</Form.Item>
						<div
							ref={suggestionsPortalHostRef}
							className={styles.divPositionRelativeWrapper}
						/>
						<div className={styles.noOfCharactersRemaining}>
							{!(descriptionNoOfCharactersRemaining === 0)
								? `${descriptionNoOfCharactersRemaining} ${
										descriptionNoOfCharactersRemaining > 1
											? "characters"
											: "character"
								  }  remaining`
								: "Limit reached"}
						</div>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						{
							<>
								{communityTags.memberTypes &&
									communityTags.memberTypes.length > 0 && (
										<>
											<div className={styles.tags}>
												<CheckboxGroupView
													rules={[
														{
															required: true,
															message: `"Who should see this post?" is required`,
														},
													]}
													title={
														"Who should see this post?"
													}
													checkAllTitle={
														"All Members"
													}
													category="audience"
													options={communityTags.memberTypes.map(
														(item: any) =>
															item.name,
													)}
													checkedValues={watch}
													setValue={
														form.setFieldValue
													}
													disabled={
														authUserMembership
													}
												/>
											</div>
										</>
									)}

								{communityTags.groups &&
									communityTags.groups.length > 0 &&
									!currentGroupId && (
										<div className={styles.tags}>
											<CheckboxGroupView
												title={"GROUPS"}
												checkAllTitle={"All Groups"}
												category="groups"
												options={communityTags.groups.map(
													(item: any) => ({
														label: item.name,
														value: item.id,
													}),
												)}
											/>
										</div>
									)}
								{marketplaceTags && marketplaceTags.length > 0 && (
									<div className={styles.tags}>
										<CheckboxGroupView
											rules={[
												() => ({
													validator(
														rule: any,
														value: any,
													) {
														if (
															value &&
															value.length >= 1 &&
															value.length <= 3
														) {
															return Promise.resolve();
														} else {
															return Promise.reject(
																"Please choose at least one tag and three tags at most!",
															);
														}
													},
												}),
											]}
											checkAllTitle={""}
											title={"TAGS"}
											category="tags"
											options={(
												marketplaceTags as {
													id: number;
													name: string;
													is_selected: boolean;
												}[]
											).map((item: any) => item.name)}
										/>
									</div>
								)}
							</>
						}
					</Col>
				</Row>
				<Row>
					<Col span={24} className={styles.submitButton}>
						<Form.Item name="post">
							<Button type="primary" htmlType="submit">
								{"Post"}
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</div>
	);
};

export default memo(FormBox);
