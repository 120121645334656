import React, { memo, useCallback, useEffect } from "react";
import { Row, Col } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import { MainLayout } from "layouts";
import { Loading } from "components";
import { Form, MyPosts } from "./components";
import styles from "./styles.module.scss";
import { TStore } from "models";
import { getEditProfilePosts } from "store";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.editProfile.data.posts,
	(state: TStore) => state.editProfile.loading,
	(state: TStore) => state.app.currentCommunityId,
	(posts, loading, currentCommunityId) => ({
		posts,
		loading,
		currentCommunityId,
	}),
);

const Profile: React.FunctionComponent<any> = () => {
	const dispatch = useDispatch();
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { posts, loading, currentCommunityId } = useSelector(stateSelector);

	useEffect(() => {
		if (currentCommunityId) {
			dispatch(getEditProfilePosts());
		}
	}, [currentCommunityId, dispatch]);

	return (
		<MainLayout>
			<Row className={styles.container}>
				<Form />
			</Row>
			<div className={styles.divider} />
			<Row gutter={[32, 24]}>
				<Col span={24} className={styles.postsColumn}>
					{loading ? (
						<Loading />
					) : posts && posts.pins.length > 0 ? (
						<div className={styles.infiniteScrollerContainer}>
							<MyPosts />
						</div>
					) : (
						<div className={"emptyPlaceholder"}>
							{"No posts, yet."}
						</div>
					)}
				</Col>
			</Row>
		</MainLayout>
	);
};

export default memo(Profile);
