import { EDocsActionTypes, IDocsGetPayload, IDocsUpdateData } from "models";

export const deleteDocsAction = (payload: {
    docId: number;
}) => ({
    type: EDocsActionTypes.docsDelete,
    payload,
});

export const setDocsLoading = (payload: boolean) => ({
    type: EDocsActionTypes.docsLoadingSet,
    payload,
});

export const setFilterDocsLoading = (payload: boolean) => ({
    type: EDocsActionTypes.filterDocsLoadingSet,
    payload,
});

export const getDocs = (payload?: IDocsGetPayload) => ({
    type: EDocsActionTypes.getDocs,
    payload,
});

export const setDocs = (payload: any) => ({
    type: EDocsActionTypes.setDocs,
    payload,
});

export const setFilesFilterTagsAction = (payload: any) => ({
    type: EDocsActionTypes.setFilterTags,
    payload,
});

export const setFilesFilterTagsInStoreAction = (payload: any) => ({
    type: EDocsActionTypes.setFilterTagsInStore,
    payload,
});

export const setDocsQueryAndFilterAction = (payload: string) => ({
    type: EDocsActionTypes.setQueryAndFilter,
    payload,
});

export const setDocsQueryAction = (payload: { query: string }) => ({
    type: EDocsActionTypes.setDocsQuery,
    payload,
});

export const incrementDocViewsAction = (payload: IDocsUpdateData) => ({
    type: EDocsActionTypes.incrementDocViews,
    payload,
});

export const setDocsCurrentFolderAction = (payload: any) => ({
    type: EDocsActionTypes.setDocsCurrentFolder,
    payload,
});

export const updateDocsCurrentFolderAction = (payload: any) => ({
    type: EDocsActionTypes.updateDocsCurrentFolder,
    payload,
});

export const updateDocsOrderAction = (payload: any) => ({
    type: EDocsActionTypes.updateDocsOrder,
    payload,
});

export const setDocsOrderAction = (payload: any) => ({
    type: EDocsActionTypes.setDocsOrder,
    payload,
});

export const setDocsFilterTagsAction = (payload: any) => ({
    type: EDocsActionTypes.setFilterTags,
    payload,
});

export const setDocsFilterTagsInStoreAction = (payload: any) => ({
    type: EDocsActionTypes.setFilterTagsInStore,
    payload,
});

export const setDocsPathAction = (payload: any) => ({
    type: EDocsActionTypes.setPath,
    payload,
});
export const setDocsRedirectQuery = (payload: { redirectQuery?: string | null }) => (
    {
        type: EDocsActionTypes.setRedirectQuery,
        payload,
    });
