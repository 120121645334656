import { memo, useState, useCallback, ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import classNames from "classnames";
import { Skeleton } from "antd";

import {
	changeTheMemberSubscriptionToPendingAction,
	completePaymentProcessSagaAction,
	notify,
	paymentsCreateMemberSubscription,
} from "store";
import { ECommunityType, IPaymentMethod, TStore } from "models";
import styles from "./styles.module.scss";
import { StripeError } from "@stripe/stripe-js";
import { useStripe } from "@stripe/react-stripe-js";
import { ReactComponent as VisaCardIcon } from "assets/images/visaCard.svg";
import { ReactComponent as MasterCardIcon } from "assets/images/masterCard.svg";
import { IsProcessingModal, PaymentRequestForm } from "..";
import { rollbar, useMemberPlanPrice } from "utils";

const PayButton = ({
	processing,
	error,
	children,
	disabled,
	onClickFunction,
}: {
	processing: boolean;
	error:
		| { type: "validation_error"; code: string; message: string }
		| StripeError
		| null
		| undefined;
	children: ReactNode;
	disabled: boolean;
	onClickFunction: () => void;
}) => {
	return (
		<button
			className={classNames(styles.payBtn, {
				[styles.payBtnError]: error,
			})}
			onClick={onClickFunction}
			disabled={processing || disabled}
		>
			{children}
		</button>
	);
};

interface IPaymentMethodsList {
	typeOfCommunity: ECommunityType;
	paymentMethods?: IPaymentMethod[];
}
const stateSelectorHandle = createSelector(
	(state: TStore) => state.payments.memberSubscription,
	(state: TStore) => state.auth?.credentials?.user,
	(state: TStore) => state.communities.selectedCommunityToJoinInfo,

	(memberSubscription, authenticatedUser, selectedCommunityToJoinInfo) => ({
		memberSubscription,
		authenticatedUser,
		selectedCommunityToJoinInfo,
	}),
);

const PaymentMethodsList: React.FunctionComponent<IPaymentMethodsList> = ({
	paymentMethods,
	typeOfCommunity,
}) => {
	const stripe = useStripe();
	const stateSelector = useCallback(stateSelectorHandle, []);
	const dispatch = useDispatch();
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
		string | undefined
	>(undefined);
	const [backendProcessing, setBackendProcessing] = useState(false);
	const [showBackendErrMsg, setShowBackendErrMsg] = useState(false);
	const [paymentRequestFormLoading, setPaymentRequestFormLoading] =
		useState(true);
	const [error, setError] = useState<
		| { type: "validation_error"; code: string; message: string }
		| null
		| StripeError
		| undefined
	>();
	const {
		memberSubscription,
		authenticatedUser,
		selectedCommunityToJoinInfo,
	} = useSelector(stateSelector);

	const memberPlanPrice = useMemberPlanPrice({ typeOfCommunity });

	const handlePay = useCallback(async () => {
		if (
			stripe &&
			memberSubscription?.payload?.client_secret &&
			memberSubscription?.payload?.subscription_id &&
			selectedPaymentMethod &&
			selectedCommunityToJoinInfo?.selected_member_plan &&
			authenticatedUser &&
			memberPlanPrice?.stripe_id
		) {
			try {
				setBackendProcessing(true);

				//  Before the transaction is started, update the subscription's status from inactive into pending.
				dispatch(
					changeTheMemberSubscriptionToPendingAction({
						communityId: selectedCommunityToJoinInfo?.id,
						subscriptionId:
							memberSubscription.payload.subscription_id,
						onErrorCallback: () => {
							throw Error(
								"The subscription could not be changed in pending state.",
							);
						},
					}),
				);

				const payload = await stripe.confirmCardPayment(
					memberSubscription?.payload?.client_secret,
					{
						payment_method: selectedPaymentMethod,
					},
				);

				if (payload.error) {
					// If the transaction failed, update the subscription's status from pending back to inactive.
					dispatch(
						paymentsCreateMemberSubscription({
							communityId: selectedCommunityToJoinInfo.id,
							stripePlanPriceId: memberPlanPrice.stripe_id,
							onErrorCallback: () => {
								throw Error(
									"The subscription could not be activated.",
								);
							},
						}),
					);
					setError(payload.error);
					if (payload.error.message) {
						alert(payload.error.message);
					} else {
						alert("Payment failed.");
					}
					setBackendProcessing(false);
				} else {
					dispatch(
						completePaymentProcessSagaAction({
							onErrorCallback: () => {
								setShowBackendErrMsg(true);
							},
							onSuccessCallback: () => {
								setBackendProcessing(false);
							},
							typeOfCommunity,
						}),
					);
				}
			} catch (error) {
				console.error(error);
				rollbar.error(error)
			}
		} else {
			notify("error", "An error occurred. Please try again later.");
		}
	}, [
		authenticatedUser,
		dispatch,
		memberSubscription,
		selectedPaymentMethod,
		selectedCommunityToJoinInfo.id,
		selectedCommunityToJoinInfo.selected_member_plan,
		stripe,
		typeOfCommunity,
		memberPlanPrice?.stripe_id,
	]);

	const finishPaymentRequestFormLoading = useCallback(() => {
		setPaymentRequestFormLoading(false);
	}, []);

	return (
		<>
			<div
				className={classNames(styles.skeletonContainer, {
					[styles.displayNone]: !paymentRequestFormLoading,
				})}
			>
				<Skeleton.Input
					active={true}
					style={{ height: 55, width: "100%" }}
				/>
				<Skeleton.Input
					active={true}
					style={{ height: 55, width: "100%" }}
				/>
			</div>

			<div
				className={classNames(styles.container, {
					[styles.displayNone]: paymentRequestFormLoading,
				})}
			>
				{paymentMethods?.map((item) => {
					return (
						<div
							key={item.data.id}
							className={classNames(styles.cardContainer, {
								[styles.activeCard]:
									item.data.id === selectedPaymentMethod,
							})}
							onClick={() =>
								setSelectedPaymentMethod(item.data.id)
							}
						>
							<div className={styles.icon}>
								{item.data.card.brand.toLowerCase().trim() ===
								"visa" ? (
									<VisaCardIcon width={38} />
								) : item.data.card.brand
										.toLowerCase()
										.trim() === "mastercard" ? (
									<MasterCardIcon width={38} />
								) : (
									item.data.card.brand
								)}
							</div>
							<div className={styles.text}>
								<span>{`${item.data.card.brand
									.slice(0, 1)
									.toUpperCase()}${item.data.card.brand
									.slice(1)
									.toLowerCase()} *${
									item.data.card.last4
								}`}</span>
								<span>{`Expires: ${item.data.card.exp_month}/${item.data.card.exp_year}`}</span>
							</div>
						</div>
					);
				})}
				{error && (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							color: "red",
							margin: "14px",
						}}
					>
						{error.message}
					</div>
				)}
				{paymentMethods && paymentMethods.length > 0 ? (
					<PayButton
						processing={backendProcessing}
						error={error}
						disabled={
							!stripe ||
							!selectedPaymentMethod ||
							backendProcessing
						}
						onClickFunction={handlePay}
					>
						{"Pay"}
					</PayButton>
				) : (
					<div className={styles.emptyCardListTxtContainer}>
						<div style={{ color: "grey" }}>{"No cards."}</div>
					</div>
				)}
				<PaymentRequestForm
					setShowBackendErrMsg={setShowBackendErrMsg}
					finishPaymentRequestFormLoading={
						finishPaymentRequestFormLoading
					}
					setBackendProcessing={setBackendProcessing}
					typeOfCommunity={typeOfCommunity}
				/>
				<IsProcessingModal
					showErrorMessage={showBackendErrMsg}
					visible={backendProcessing}
				/>
			</div>
		</>
	);
};

export default memo(PaymentMethodsList);
