import React, { memo, useCallback } from "react";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";

import { FeedPosts } from "components";
import { TStore } from "models";
import { getPosts } from "store";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.posts.data,
	(state: TStore) => state.posts.meta,
	(state: TStore) => state.posts.loading,
	(postsData, postsMeta, postsLoading) => ({
		postsData,
		postsMeta,
		postsLoading,
	}),
);

interface IPostData {}

const PostsData: React.FunctionComponent<IPostData> = () => {
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { postsData, postsMeta, postsLoading } = useSelector(stateSelector);
	const dispatch = useDispatch();

	const handleLoadMore = useCallback(
		(page: number) => {
			dispatch(getPosts({ page }));
		},
		[dispatch],
	);

	return (
		<FeedPosts
			meta={postsMeta}
			loading={postsLoading}
			posts={postsData}
			hasMore={!postsLoading && postsMeta?.pagination?.has_next_page}
			loadMore={handleLoadMore}
			getScrollParent={() => document.getElementsByTagName("main")[0]}
		/>
	);
};

export default memo(PostsData);
