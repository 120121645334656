import {
	IFirebaseFetchDocumentAction,
	IFirebaseUpdateLocalDocumentAction,
	IFirebaseUpdateLocalPostCommentsAction,
	IFirebaseAddLocalCommentForPostAction,
	IFirebaseAddLocalPostCommentsAction,
} from ".";

// ENUMS
export enum EFirebaseActionTypes {
	fetchPostDocument = "firebase/fetch/postDocument",
	fetchFirebasePostComments = "firebase/fetch/postComments",
	fetchPostComments = "fetch/postComments",
	fetchFirebaseMorePostComments = "firebase/fetch/morePostComments",
	fetchMorePostComments = "posts/fetch/moreComments",
	updateLocalPostDocument = "firebase/update/localDocument",
	addFirebaseLocalPostComments = "firebase/add/localComments",
	updateFirebaseLocalPostComments = "firebase/update/localComments",
	sendFirebaseCommentForPost = "firebase/postComment/send",
	addFirebaseLocalCommentForPost = "firebase/addComment/send",
}

// INTERFACES
export interface ILocalFirebasePostComment {
	message: String;
	authorId: Number;
	authorName: String;
	authorAvatar: String;
	createdAt: Date;
}

export interface IFirebaseState {
	postsDocuments: { [key: string]: ILocalFirebasePostDocument } | null;
	postComments: { [key: string]: ILocalFirebasePostComment[] } | null;
}

export interface ILocalFirebasePostDocument {
	count: Number;
	authorId: Number;
	authorName: String;
	authorAvatar: String;
	createdAt: Date;
	comments: ILocalFirebasePostComment[];
}

// TYPES
export type TFirebaseActions =
	| IFirebaseFetchDocumentAction
	| IFirebaseUpdateLocalDocumentAction
	| IFirebaseUpdateLocalPostCommentsAction
	| IFirebaseAddLocalCommentForPostAction
	| IFirebaseAddLocalPostCommentsAction;
