import React, {
	memo,
	useCallback,
	useRef,
	useEffect,
	useState,
	LegacyRef,
} from "react";
import { Row, Col, Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { ReloadOutlined } from "@ant-design/icons";

import { MainLayout } from "layouts";
import {
	PostForm,
	ScrollToTopButton,
	FilterTag,
	HelmetPageTitle,
	Loading,
	CommunityWelcomeModal,
} from "components";
import { TStore, IPostsState } from "models";
import styles from "./styles.module.scss";
import {
	setFilterTagsMiddleware,
	getPosts,
	setQueryPostsAction,
	notify,
	SetWelcomeModal,
} from "store";
import { PostsData } from "./components";
import { getUnreadNotificationsCounterSagaAction } from "store/actions/notifications";
import DirectoryMemberOptedOutPanel from "components/DirectoryMemberOptedOutPanel";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.posts,
	(state: TStore) => state.community.mainTags,
	(state: TStore) => state.app.currentCommunityId,
	(state: TStore) => state.app.currentGroupId,
	(state: TStore) => state.editProfile.data,
	(state: TStore) => state.app.welcomeModal,
	(
		posts: IPostsState,
		communityTags,
		currentCommunityId,
		currentGroupId,
		editProfileData,
		welcomeModal,
	) => ({
		posts,
		communityTags,
		currentCommunityId,
		currentGroupId,
		editProfileData,
		welcomeModal,
	}),
);

const Dashboard = () => {
	const ref: LegacyRef<HTMLDivElement> | null = useRef(null);
	const dispatch = useDispatch();
	const listRef = useRef<any>();
	const [position, setPosition] = useState<any>();
	const [initializingPostsDataByTags, setInitializingPostsDataByTags] =
		useState(false);
	const [hideTheComponent, setHideTheComponent] = useState(false);

	const handleHideTheComponent = () => {
		setHideTheComponent(true);
	};
	const stateSelector = useCallback(stateSelectorHandle, []);
	const {
		posts,
		communityTags,
		currentCommunityId,
		currentGroupId,
		editProfileData,
		welcomeModal,
	} = useSelector(stateSelector);
	const { selectedTags, meta, query, shouldRefresh } = posts;

	const handleSearchPosts = useCallback(
		(onSuccess?: () => void) => {
			if (!currentCommunityId) {
				console.error("Missing arguments!");
				return;
			}

			dispatch(
				getPosts({
					resetPosts: true,
					onError: () => {
						notify(
							"error",
							"An error occurred. Please try again later.",
						);
					},
					onSuccess,
				}),
			);
			const mainContainer = document.getElementsByTagName("main")[0];
			if (mainContainer) {
				mainContainer.scrollTop = 0;
			}
		},
		[dispatch, currentCommunityId],
	);

	const handleFilterTagsPosts = useCallback(
		(tags?: string[]) => {
			setInitializingPostsDataByTags(true);
			let newTag: string[] = [];
			if (tags && tags.length) {
				newTag = tags.filter(
					(item: any) => selectedTags && !selectedTags.includes(item),
				);
			}
			dispatch(setFilterTagsMiddleware({ tag: newTag }));
			handleSearchPosts(() => setInitializingPostsDataByTags(false));

			if (newTag.length === 0) {
				dispatch(setQueryPostsAction({ query: "" }));
			}
		},
		[dispatch, selectedTags, handleSearchPosts],
	);

	useEffect(() => {
		if (shouldRefresh)
			setPosition(
				listRef.current.firstElementChild.nextElementSibling.getBoundingClientRect(),
			);
	}, [shouldRefresh]);

	useEffect(() => {
		handleSearchPosts();
	}, [currentGroupId, currentCommunityId, handleSearchPosts]);

	useEffect(() => {
		dispatch(getUnreadNotificationsCounterSagaAction());
		return () => {
			dispatch(setQueryPostsAction({ query: "" }));
		};
	}, [dispatch]);



	return (
		<>
			<HelmetPageTitle screenTitle={"Feed"} />
			<MainLayout>
				<div className={styles.container}>
					<Row style={{ flexShrink: 0 }}>
						<Col span={24} order={1}>
							<PostForm uniqueKey={`create-post-form`} />
						</Col>
					</Row>

					<Row style={{ flexShrink: 0 }}>
						<Col span={24} order={1}>
							<div
								className={styles.tagFiltersContainer}
								ref={ref}
							>
								<FilterTag
									onClick={handleFilterTagsPosts}
									tagsList={communityTags.tags}
									selectedTags={selectedTags}
									query={query}
								/>
							</div>
						</Col>
					</Row>
					{posts.data.length === 0 && !posts.loading && (
						<Row style={{ flexShrink: 0 }}>
							<Col span={24} order={1}>
								{
									<div className={"emptyPlaceholder"}>
										{"No posts, yet."}
									</div>
								}
							</Col>
						</Row>
					)}
					{parseInt(editProfileData?.user?.id) &&
					editProfileData?.user?.member_directory_opt_in === false ? (
						<Row style={{ flexShrink: 0 }}>
							<Col span={24} order={1}>
								{!hideTheComponent && (
									<DirectoryMemberOptedOutPanel
										memberDirectoryOptIn={
											editProfileData.user
												.member_directory_opt_in
										}
										showHidebutton={
											hideTheComponent ||
											!editProfileData.user
												.member_directory_opt_in
										}
										handleHideButton={
											handleHideTheComponent
										}
									/>
								)}
							</Col>
						</Row>
					) : null}
					<Row gutter={[32, 24]}>
						<Col span={24} order={2} ref={listRef}>
							{shouldRefresh && (
								<Button
									type="primary"
									className={styles.newContent}
									style={{
										background:
											meta.appearance?.primary_color,
										borderColor:
											meta.appearance?.primary_color,
										position: position
											? "sticky"
											: undefined,
									}}
									onClick={() => handleSearchPosts()}
								>
									<ReloadOutlined
										style={{
											position: "absolute",
											left: 15,
											top: 12,
										}}
									/>
									{"New content available"}
									<span>{"click to refresh"}</span>
								</Button>
							)}
							{!initializingPostsDataByTags && <PostsData />}
						</Col>
					</Row>
					{initializingPostsDataByTags && <Loading />}
					<ScrollToTopButton parentRef={ref} />
				</div>
			</MainLayout>
			<CommunityWelcomeModal
				isOpen={welcomeModal}
				onClose={() => dispatch(SetWelcomeModal(false))}
				mainButtonHandler={() => dispatch(SetWelcomeModal(false))}
			/>
		</>
	);
};

export default memo(Dashboard);
