import React, { memo, useEffect, useCallback, useMemo } from "react";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";
import Scrollbar from "react-scrollbars-custom";

import styles from "./styles.module.scss";
import { MessengerCard } from "..";
import {
	createPinnedConversation,
	fetchConversation,
	setSelectedThread,
	updateConversation,
} from "store";
import { TStore, IMessageThread } from "models";
import { Loading } from "components";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.auth.credentials?.user,
	(state: TStore) => state.messages.conversation,
	(user, conversation) => {
		return {
			user,
			conversation,
		};
	},
);

const Messenger: React.FunctionComponent<any> = ({
	communityId,
	threadRef,
	selectedThread,
}) => {
	const dispatch = useDispatch();
	const stateSelector = useCallback(stateSelectorHandle, [selectedThread]);
	const { user, conversation } = useSelector(stateSelector);
	const { loading, data, meta } = conversation;

	const conversationIds: number[] = useMemo(() => {
		if (data) {
			return data && Object.keys(data).map((item) => parseInt(item));
		} else {
			return [];
		}
	}, [data]);

	const fetch = useCallback(() => {
		if (selectedThread.id) {
			dispatch(
				fetchConversation({
					conversationId: selectedThread.id,
					communityPostId:
						selectedThread.community_post_id ||
						(selectedThread.activity_type === "community_post" &&
							selectedThread.id) ||
						0,
					recipientId: selectedThread.user && selectedThread.user.id,
					shouldOpen: selectedThread.message_counter > 0,
				}),
			);
		}
	}, [dispatch, selectedThread]);

	const getScrollParent = useCallback(() => threadRef.current, [threadRef]);

	useEffect(() => {
		const messengerScrollbarContainer = document.getElementById(
			`messenger-scrollbar-container`,
		);
		if (messengerScrollbarContainer) {
			messengerScrollbarContainer.scrollIntoView(false);
		}
		// any time the conversation is updated -> scroll down
	}, [conversation]);

	useEffect(() => {
		const continuousFetchInterval = setInterval(() => {
			if (selectedThread.id)
				dispatch(
					fetchConversation({
						conversationId: selectedThread.id,
						communityPostId:
							selectedThread.community_post_id ||
							(selectedThread.activity_type ===
								"community_post" &&
								selectedThread.id) ||
							0,
						recipientId:
							selectedThread?.user?.id ||
							(selectedThread.users &&
								selectedThread.users[0] &&
								selectedThread.users[0].id),
						shouldOpen: true,
						page: 1,
					}),
				);
		}, 20000);

		return () => clearInterval(continuousFetchInterval);
	}, [
		dispatch,
		selectedThread.activity_type,
		selectedThread.community_post_id,
		selectedThread.id,
		selectedThread.user,
		selectedThread.users,
	]);

	useEffect(() => {
		if (selectedThread) {
			fetch();
		}
	}, [fetch, selectedThread]);

	useEffect(() => {
		// on unmount reset the conversation data and selected thread data
		return () => {
			dispatch(updateConversation({ data: null, meta: null }));
			dispatch(setSelectedThread(null));
			dispatch(createPinnedConversation(null));
		};
	}, [dispatch]);

	return conversation.errorMessage ? (
		<div className={styles.normalText}>{conversation.errorMessage}</div>
	) : (
		<Scrollbar
			className={styles.container}
			contentProps={{
				renderer: (props) => {
					const { elementRef, ...restProps } = props;
					return (
						<div
							{...restProps}
							className={styles.ScrollbarsCustom_Content}
							id="messenger-scrollbar-container"
							ref={elementRef}
						></div>
					);
				},
			}}
		>
			<InfiniteScroll
				pageStart={1}
				loadMore={fetch}
				initialLoad={false}
				hasMore={meta?.pagination?.has_next_page}
				loader={
					<div key={0}>
						<Loading />
					</div>
				}
				useWindow={false}
				getScrollParent={getScrollParent}
			>
				{loading && <Loading />}
				{!loading &&
					data &&
					conversationIds.length > 0 &&
					conversationIds.map((id: number, index: number) => {
						const item = data![id] as unknown as IMessageThread;

						let sender = item.sender;
						let previous: IMessageThread | null = null;
						let sameMsgBlock: boolean = false;
						if (index > 0) {
							previous = conversation.data![
								conversationIds[index - 1]
							] as unknown as IMessageThread;
						}
						sameMsgBlock =
							(sender &&
								previous &&
								previous.sender &&
								sender.id === previous.sender.id) ||
							false;
						return (
							<MessengerCard
								user={user}
								key={`messenger_${index}`}
								data={item}
								sameMsgBlock={sameMsgBlock}
							/>
						);
					})}
			</InfiniteScroll>
		</Scrollbar>
	);
};

export default memo(Messenger);
