import { ICommunityMemberPlan, IUser } from "models";
import {
	ICommunityLoadingSetAction,
	ICommunityGetAction,
	ICommunitySetAction,
	ICommunityTagsSetAction,
	ICommunitySetMemberPlansAction,
} from "./actions";

// ENUMS
export enum ECommunityActionTypes {
	communityLoadingSet = "community/set/loading",
	getCommunity = "community/get",
	setCommunity = "community/set",
	setCommunityTags = "community/set/tags",
	communityTagsLoadingSet = "community/tags/set/loading",
	setCommunityGroups = "community/set/groups",
	getBySlug = "community/get/by-slug",
	setMemberPlans = "community/set/member/plans",
	setSelectedMemberPlan = "community/set/selected/member/plan",
	getMembersPlans = "communities/get/member/plans",
	getMembersPlansCompleted = "communities/get/member/plans/completed",
}

// INTERFACES
export interface ICommunity {
	id: number;
	slug: string;
	name: string;
	location: string;
	picture: string;
	about: string;
	type: string;
	membership_id: string;
	membership_type: string;
	privacy: "open" | "close";
	interests: string[];
	contact_email: string;
	welcome_message: string;
	joined: boolean;
	invited: boolean;
	approved: boolean;
	invite_member_type: null | string;
	primary_color: string;
	announcement_header_background: string;
	header_background: string;
	group_count: number;
	open_group_count: number;
	tags: string[];
	sub_sets: string[];
	current_user: IUser;
	services?: any;
	access_status?: ECommunityAccessStatus | null;
	uses_stripe: boolean;
	memberPlans?: ICommunityMemberPlan[] | null;
	eligible_for_trial?: boolean;
	member_count: number;
	owner_email: string;
	selected_member_plan?: Partial<ICommunityMemberPlan> | null;
}

export enum ECommunityAccessStatus {
	closed_community = "closed_community",
	subscription_inactive = "subscription_inactive",
	subscription_pending = "subscription_pending",
	granted = "granted",
	trialing = "trialing",
	not_a_member = "not_a_member",
	platform_unauthorized = "platform_unauthorized",
}

export enum ESubscriptionStatus {
	active = "active",
	account_deactivated = "account_deactivated",
	inactive = "inactive",
	incomplete_expired = "incomplete_expired",
	trialing = "trialing",
	canceled = "canceled",
	incomplete = "incomplete",
	past_due = "past_due",
	pending = "pending",
}

export enum ECommunityType {
	join = "join",
	current = "current", // the type of the community can be 'join' if the selected community is the one the user wants to join or it can be
	// "current" and that means the community where the user is already joined and it is the last visited one.
}

export interface ICommunityState {
	loading: boolean;
	data?: ICommunity;
	mainTags: {
		tags: { id: number; name: string; is_selected: boolean }[];
		memberTypes: any[];
		groups: any[];
	};
}

export interface ICommunityLoadingPayload {
	loading: boolean;
}

// TYPES
export type TCommunityActions =
	| ICommunitySetAction
	| ICommunityLoadingSetAction
	| ICommunityGetAction
	| ICommunityTagsSetAction
	| ICommunitySetMemberPlansAction;
