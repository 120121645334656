import {
	ICommunityState,
	ECommunityActionTypes,
	TCommunityActions,
} from "models";

const initial: ICommunityState = {
	loading: false,
	data: undefined,
	mainTags: {
		tags: [],
		memberTypes: [],
		groups: [],
	},
};

export function communityReducer(
	state = initial,
	action: TCommunityActions,
): ICommunityState {
	switch (action.type) {
		case ECommunityActionTypes.communityLoadingSet: {
			return {
				...state,
				loading: action.payload,
			};
		}
		case ECommunityActionTypes.setCommunity: {
			const { ...communityData } = action.payload.community;

			return {
				...state,
				data: {
					...communityData,
				},
			};
		}
		case ECommunityActionTypes.setMemberPlans: {
			const { plans } = action.payload;
			let resultedState = { ...state };
			if (resultedState.data) {
				resultedState = {
					...resultedState,
					data: {
						...resultedState.data,
						memberPlans: plans,
					},
				};
			}
			return {
				...resultedState,
			};
		}
		case ECommunityActionTypes.setCommunityTags: {
			return {
				...state,
				mainTags: {
					...state.mainTags,
					tags: action.payload.tags,
					groups: action.payload.meta.groups,
					memberTypes: action.payload.meta.member_types,
				},
			};
		}
		default:
			return state;
	}
}
