import React, { ReactNode, useCallback, useMemo, useState } from "react";
import { Form, Upload ,message} from "antd";
// import { InboxOutlined } from "@ant-design/icons";
import {ReactComponent as FileUploaderIcon} from "assets/images/icons/common_icons/imageUpload.svg";

import styles from "./styles.module.scss";
import CustomButton from "components/Button";
import { createSelector } from "reselect";
import { ISelectedCommunityToJoin, TStore } from "models";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { UploadChangeParam, UploadFile } from "antd/lib/upload";
import { setSelectedCommunityToJoinUserProfilePicture } from "store";

const { Dragger } = Upload;
const stateSelectorHandle = createSelector(
	(state: TStore) => state.auth?.credentials?.user,
	(authenticatedUser) => ({ authenticatedUser }),
);

function getBase64(img: any, callback: any) {
	const reader = new FileReader();
	reader.addEventListener("load", () => callback(reader.result));
	reader.readAsDataURL(img);
}

function beforeUpload(file: any) {
	const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
	if (!isJpgOrPng) {
		message.error("You can only upload JPG/PNG file!");
	}
	const isLt2M = file.size / 1024 / 1024 < 2;
	if (!isLt2M) {
		message.error("Image must smaller than 2MB!");
	}
	return isJpgOrPng && isLt2M;
}

// interface IProfilePicture {
// 	selectedCommunityToJoinInfo: ISelectedCommunityToJoin;
// }

const FileUploader: React.FunctionComponent<any> = ({UploadText,hintChild,selectedCommunityToJoinInfo}:{UploadText:string,hintChild:ReactNode,selectedCommunityToJoinInfo: ISelectedCommunityToJoin;}) => {
	
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { authenticatedUser } = useSelector(stateSelector);
	const [ ,setImageLoading] = useState(false);
	const dispatch = useDispatch();
	const handleUploadImage = useCallback(
		(info: UploadChangeParam<UploadFile<any>>) => {
			if (info.file.status === "uploading") {
				setImageLoading(true);
				return;
			}
			if (info.file.status === "done") {
				getBase64(info.file.originFileObj, (imageUrl: any) => {
					dispatch(
						setSelectedCommunityToJoinUserProfilePicture({
							profileImage: info,
							imageUrl,
						}),
					);
					setImageLoading(false);
				});
			}
		},
		[dispatch],
	);

	const handleCustomRequest = ({ file, onSuccess }: any) => {
		setTimeout(() => {
			onSuccess("ok");
		}, 0);
	};

	const hasTheUserUploadedAProfilePicture = useMemo((): boolean => {
		if (!authenticatedUser) return false;
		const userAvatarRegex = new RegExp(
			"avatar_placeholder-[a-zA-Z0-9]+.png",
		);
		const regexArray = userAvatarRegex.exec(authenticatedUser.picture);
		if (regexArray) return false;
		else return true;
	}, [authenticatedUser]);

	const normFile = (e: any) => {
		if (Array.isArray(e)) {
			return e;
		}
		return e && e.fileList;
	};


	return (
		<div className={styles.uploadInput}>
			<Form.Item
				name={"document"}
				rules={[
					{
						required: true,
						message: "'Document' is required",
					},
				]}
				valuePropName="fileList"
				getValueFromEvent={normFile}
			>
				<Dragger
					listType="picture"
					showUploadList={true}
					accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .zip, application/zip, application/x-zip-compressed"
					// customRequest={dummyRequest}
					multiple={false}
					beforeUpload={beforeUpload}
					onChange={handleUploadImage}
					customRequest={handleCustomRequest}
				>
					<p className="ant-upload-drag-icon" >
						{/* <InboxOutlined /> */}
						{selectedCommunityToJoinInfo.userProfilePicture
						?.imageUrl ? (
						<img
							src={
								selectedCommunityToJoinInfo.userProfilePicture
									.imageUrl
							}
							alt="avatar"
							style={{ width: "48px",height:'48px' }}
						/>
					) : hasTheUserUploadedAProfilePicture ?
					(
						<img
							src={
								authenticatedUser?.picture
							}
							alt="avatar"
							style={{ width: "48px",height:'48px' }}
						/>
					):
					(
						
				
                        <FileUploaderIcon className={styles.uploadIcon} />)
					}
					</p>
					<p className={styles.uploadText} >
						{UploadText}
					</p>
					{hintChild && (<p className="ant-upload-hint">
						{hintChild}
					</p>)}
                    <CustomButton  varient="outline" style={{width:"120px"}}>
						<span className={styles.ButtonText}>Browse File</span>
						</CustomButton>
				</Dragger>
			</Form.Item>
		</div>
	);
};

export default FileUploader;
