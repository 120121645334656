import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { ErrorBoundary, Provider as RollBarProvider } from "@rollbar/react";
import { createSelector } from "reselect";
import { matchPath, useLocation } from "react-router-dom";
import { Avatar, Button, Layout } from "antd";
import classNames from "classnames";
import { ReduxRouter } from "@lagunovsky/redux-react-router";

import MainRoutes from "routes";
import configureStore, {
	appSetUser,
	appSiderMenuSetCollapsed,
	history,
	notify,
	getPreviewWhiteLabelCommunityAction,
} from "store";
import {
	Loading,
	SiderTopInfo,
	SiderCustomMenu,
	SiderBottomInfo,
	AppBar,
} from "components";
import { IGroup, TStore } from "models";
import { CollapseType } from "antd/lib/layout/Sider";
import styles from "./styles.module.scss";
import SiderJoinedCommunitiesList from "components/SiderJoinedCommunitiesList";
import { rollbarConfig } from "utils/rollbar";
import { GetImg } from "utils";
import { ReactComponent as CloseGroup } from "assets/images/icons/menu_icons/closed-group.svg";
import { ReactComponent as OpenGroup } from "assets/images/icons/menu_icons/open-group.svg";
import { ReactComponent as UsersLight } from "assets/images/icons/menu_icons/users-light.svg";
import { isItAWhiteLabel } from "utils";
const { Sider, Content } = Layout;

const stateSelectorHandle = createSelector(
	(state: TStore) => state.auth.credentials,
	(state: TStore) => state.app.loading,
	(state: TStore) => state.app.siderMenu.collapsed,
	(state: TStore) => state.app.siderMenu.showJoinedCommuntiesList,
	(state: TStore) => state.app.currentGroupId,
	(state: TStore) => state.groups,

	(
		user,
		loading,
		siderMenuCollapsed,
		siderMenuShowJoinedCommunitiesList,
		currentGroupId,
		groups,
	) => ({
		user,
		loading,
		siderMenuCollapsed,
		siderMenuShowJoinedCommunitiesList,
		currentGroupId,
		groups,
	}),
);
const { store } = configureStore();

const AuthenticationApp: React.FunctionComponent = () => {
	const location = useLocation();
	const isCommunitiesPath = matchPath("/communities/*", location.pathname);
	const isItWhiteLabelJoiningPath = matchPath(
		"/joining/*",
		location.pathname,
	);
	const isSubscriptionManagementPath = matchPath(
		"/subscription-management/change-plan",
		location.pathname,
	);
	const isProcessingPaymentPath = matchPath(
		"/processing-setup-intent-payment",
		location.pathname,
	);
	const dispatch = useDispatch();
	const stateSelector = useCallback(stateSelectorHandle, []);
	const {
		user,
		loading,
		siderMenuCollapsed,
		siderMenuShowJoinedCommunitiesList,
		currentGroupId,
		groups,
	} = useSelector(stateSelector);
	const [absoluteSideBar, setAbsoluteSideBar] = useState(false); // TODO: create a custom hook to get the current breakpoint at first render
	const group: IGroup | undefined = useMemo(() => {
		if (!currentGroupId && !groups) {
			return undefined;
		} else {
			const group = groups.data.find(
				(item) => item.id === currentGroupId,
			);
			return group ? group : undefined;
		}
	}, [currentGroupId, groups]);
	const handleOnCollapse = useCallback(
		(collapsed: boolean, type: CollapseType) => {
			if (type === "responsive") {
				dispatch(appSiderMenuSetCollapsed(collapsed));
				if (!collapsed) {
					setAbsoluteSideBar(false);
				}
			} else if (type === "clickTrigger") {
				setAbsoluteSideBar(!absoluteSideBar);
			}
		},
		[absoluteSideBar, dispatch],
	);

	const handleTriggerTheDrawerMenu = useCallback(() => {
		setAbsoluteSideBar(!absoluteSideBar);
	}, [absoluteSideBar]);

	const setUserAuthenticationStatus = useCallback(() => {
		dispatch(
			appSetUser({
				onErrorCallback: () => {
					notify(
						"error",
						"An error occurred. Please try again later.",
					);
				},
				onSuccessCallback: () => {},
			}),
		);
	}, [dispatch]);

	useEffect(() => {
		if (isItAWhiteLabel()) {
			dispatch(
				getPreviewWhiteLabelCommunityAction({
					onSuccessCallback: () => {
						setUserAuthenticationStatus();
					},
					onErrorCallback: () => {
						notify(
							"error",
							"An error occurred. Please try again later.",
						);
					},
				}),
			);
		} else {
			setUserAuthenticationStatus();
		}
	}, [setUserAuthenticationStatus, dispatch]);

	return loading ? (
		<Loading />
	) : (
		<>
			{user ? (
				<>
					{isItWhiteLabelJoiningPath ||
					isCommunitiesPath ||
					isProcessingPaymentPath ||
					isSubscriptionManagementPath ? (
						<Content>
							<React.Suspense fallback={<Loading />}>
								<MainRoutes />
							</React.Suspense>
						</Content>
					) : (
						<Layout>
							<Sider
								id={"sider"}
								className={classNames(styles.sideBar, {
									[styles.absoluteSideBar]: absoluteSideBar,
									[styles.collapsedSideBar]:
										siderMenuCollapsed,
									[styles.groupSiderBackground]:
										currentGroupId,
								})}
								collapsible={true}
								breakpoint="lg"
								collapsedWidth="0"
								onCollapse={handleOnCollapse}
								trigger={null}
							>
								<SiderTopInfo
									absoluteSideBar={absoluteSideBar}
									handleTriggerTheDrawerMenu={
										handleTriggerTheDrawerMenu
									}
								/>
								{!siderMenuShowJoinedCommunitiesList ? (
									<>
										{group &&
											(!absoluteSideBar ? (
												<div
													className={
														styles.groupInfoContainer
													}
												>
													<div
														className={
															styles.infoContainer
														}
													>
														{group && group.avatar && (
															<Avatar
																size={35}
																src={
																	<GetImg
																		endpoint={
																			group?.avatar
																		}
																	/>
																}
																style={{
																	minWidth: 35,
																	minHeight: 35,
																}}
															/>
														)}
														<div
															className={
																styles.detailsContaienr
															}
														>
															<div
																className={
																	styles.title
																}
															>
																{group.name}
															</div>
															<div
																className={
																	styles.membersDetails
																}
															>
																<div
																	className={
																		group.is_open
																			? styles.groupStatusOpen
																			: styles.groupStatusClosed
																	}
																>
																	{group.is_open ? (
																		<OpenGroup
																			width={
																				15
																			}
																			height={
																				15
																			}
																		/>
																	) : (
																		<CloseGroup
																			width={
																				15
																			}
																			height={
																				15
																			}
																		/>
																	)}
																	<span>
																		{`${
																			!group.is_open
																				? "Closed"
																				: "Open"
																		} Group`}
																	</span>
																</div>
																<div
																	className={
																		styles.memberNumberContainer
																	}
																>
																	<UsersLight
																		width={
																			15
																		}
																		height={
																			15
																		}
																	/>
																	<span
																		className={
																			styles.groupMembersNumber
																		}
																	>
																		{
																			group.number_of_members
																		}
																	</span>
																</div>
															</div>
														</div>
													</div>
												</div>
											) : (
												<div
													className={
														styles.imageWrapper
													}
												>
													<div
														className={
															styles.imageContainer
														}
													>
														{group &&
															group.avatar && (
																<Avatar
																	size={35}
																	src={
																		<GetImg
																			endpoint={
																				group?.avatar
																			}
																		/>
																	}
																/>
															)}
													</div>
												</div>
											))}
										<SiderCustomMenu
											absoluteSideBar={absoluteSideBar}
											handleTriggerDrawerMenu={
												handleTriggerTheDrawerMenu
											}
										/>
									</>
								) : (
									<SiderJoinedCommunitiesList />
								)}
								<SiderBottomInfo
									absoluteSideBar={absoluteSideBar}
								/>
							</Sider>

							<Layout>
								<AppBar
									handleTriggerTheDrawerMenu={
										handleOnCollapse
									}
								/>
								<Content
									id="right-section"
									className={styles.activeUserLayout}
								>
									<div
										id="content"
										style={{
											display: "flex",
											flex: 1,
											flexDirection: "column",
											maxWidth: 1024,
											width: "100%",
										}}
									>
										<React.Suspense fallback={<Loading />}>
											<MainRoutes />
										</React.Suspense>
									</div>
								</Content>
							</Layout>
						</Layout>
					)}
				</>
			) : (
				<Content>
					<React.Suspense fallback={<Loading />}>
						<MainRoutes />
					</React.Suspense>
				</Content>
			)}
		</>
	);
};

const App = () => {
	const FallBackUI = () => {
		return (
			<div
				style={{
					display: "flex",
					height: "100vh",
					width: "100vw",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Button
					onClick={() => {
						window.location.reload();
					}}
				>
					Reload page
				</Button>
			</div>
		);
	};

	return (
		<RollBarProvider config={rollbarConfig}>
			<ErrorBoundary fallbackUI={FallBackUI}>
				<Provider store={store}>
					<ReduxRouter history={history}>
						<AuthenticationApp />
					</ReduxRouter>
				</Provider>
			</ErrorBoundary>
		</RollBarProvider>
	);
};

export default App;
