import {
	IGroupsLoadingSetAction,
	IGroupsGetAction,
	IGroupsSetAction,
	IGroupsUpdateJoinedAction,
	IGroupsCancelJoinRequestAction,
	IGroupsGetMoreAction,
} from "./actions";

// ENUMS
export enum EGroupsActionTypes {
	groupsLoadingSet = "groups/set/loading",
	updateLocaJoinedGroups = "groups/join/update",
	cancelJoinGroupRequest = "groups/join/request/cancel",
	getGroups = "groups/get",
	getGroupsCompleted = "groups/get/completed",
	getMoreGroups = "groups/get/more",
	setGroups = "groups/set",
	setMembers = "members/set",
	joinGroup = "groups/join",
	leaveGroup = "groups/leave",
}

// INTERFACES

export interface IGroup {
	id: number;
	name: string;
	community_id: number;
	description: string;
	is_open: boolean;
	privacy: EGroupPrivacy;
	number_of_members: number;
	is_joined: boolean;
	join_request_status: "none" | "pending" | "approved";
	membership_id: number;
	request_id: null | string;
	avatar:string
}

export enum EGroupPrivacy {
	open = "open",
	closed = "closed",
	secret = "secret",
}

export interface IGroupsState {
	loading: boolean;
	data: IGroup[];
	meta?: {
		pagination: {
			current_page: number;
			per_page: number;
			total_pages: number;
			total_objects: number;
			has_next_page: boolean;
		};
	};
}

export interface IGroupsLoadingPayload {
	loading: boolean;
}

// TYPES
export type TGroupsActions =
	| IGroupsSetAction
	| IGroupsLoadingSetAction
	| IGroupsGetAction
	| IGroupsGetMoreAction
	| IGroupsUpdateJoinedAction
	| IGroupsCancelJoinRequestAction;
