import {
	EVideosActionTypes,
	IVideosGetPayload,
	IIncrementVideoViewsPayload,
} from "models";

export const setVideosLoading = (payload: boolean) => ({
	type: EVideosActionTypes.videosLoadingSet,
	payload,
});

export const getVideos = (payload: IVideosGetPayload) => ({
	type: EVideosActionTypes.getVideos,
	payload,
});

export const deleteVediosAction = (payload: {
	videoId: number;
}) => ({
	type: EVideosActionTypes.deleteVideos,
	payload,
});

export const setVideos = (payload: any) => ({
	type: EVideosActionTypes.setVideos,
	payload,
});

export const setVideosFilterTagsAction = (payload: any) => ({
	type: EVideosActionTypes.setFilterTags,
	payload,
});

export const setVideosFilterTagsInStoreAction = (payload: any) => ({
	type: EVideosActionTypes.setFilterTagsInStore,
	payload,
});

export const setVideosQueryAndFilterAction = (payload: any) => (
	{
		type: EVideosActionTypes.setQueryAndFilter,
		payload,
	});

export const setVideosQueryAction = (payload: { query: string }) => (
	{
		type: EVideosActionTypes.setVideosQuery,
		payload,
	});
export const setVideosRedirectQuery = (payload: { redirectQuery?: string | null }) => (
	{
		type: EVideosActionTypes.setRedirectQuery,
		payload,
	});

export const incrementVideoViewsAction = (
	payload: IIncrementVideoViewsPayload,
) => ({
	type: EVideosActionTypes.incrementVideoViews,
	payload,
});

export const setVideosCurrentFolderAction = (payload: any) => ({
	type: EVideosActionTypes.setVideosCurrentFolder,
	payload,
});

export const updateVideosCurrentFolderAction = (payload: any) => ({
	type: EVideosActionTypes.updateVideosCurrentFolder,
	payload,
});

export const updateVideosOrderAction = (payload: any) => ({
	type: EVideosActionTypes.updateVideosOrder,
	payload,
});

export const setVideosOrderAction = (payload: any) => ({
	type: EVideosActionTypes.setVideosOrder,
	payload,
});

export const setVideosPathAction = (payload: any) => ({
	type: EVideosActionTypes.setPath,
	payload,
});
