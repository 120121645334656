import { EViewCountsTypes } from "models";

export const incrementPostViewCountAction = (payload: number) => ({
	type: EViewCountsTypes.postViewCountsIncrement,
	payload,
});

export const addViewCountForPostAction = (payload: number) => ({
	type: EViewCountsTypes.addPost,
	payload,
});

export const incrementVideoViewCountAction = (payload: number) => ({
	type: EViewCountsTypes.videoViewCountsIncrement,
	payload,
});

export const addViewCountForVideoAction = (payload: number) => ({
	type: EViewCountsTypes.addVideo,
	payload,
});

export const incrementDocViewCountAction = (payload: number) => ({
	type: EViewCountsTypes.docViewCountsIncrement,
	payload,
});

export const addViewCountForDocAction = (payload: number) => ({
	type: EViewCountsTypes.addDoc,
	payload,
});

export const incrementOfferViewCountAction = (payload: number) => ({
	type: EViewCountsTypes.offerViewCountsIncrement,
	payload,
});

export const addViewCountForOfferAction = (payload: number) => ({
	type: EViewCountsTypes.addOffer,
	payload,
});
