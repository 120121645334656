import { call, put, all, takeEvery, select } from "redux-saga/effects";

import {
	EResourcesActionTypes,
	TStore,
	IIncrementResourceViewsAction,
} from "models";
import {
	api,
	setResourcesLoading,
	setResources,
	setResourcesFilterTagsInStoreAction,
	setResourcesQueryAction,
} from "store";
import {
	deleteResourceAction,
	getResources,
	incrementResourceViewsAction,
	setResourcesOrderAction,
	setResourcesQueryAndFilterAction,
	updateResourcesCurrentFolderAction,
} from "store/actions";
import { rollbar } from "utils";

const getCommunityId = (state: TStore) => state.app.currentCommunityId;
const getGroupId = (state: TStore) => state.app.currentGroupId;
const getCommunityTags = (state: TStore) => state.community.mainTags.tags;
const getSelectedTags = (state: TStore) => state.resources.selectedTags;
const getQuery = (state: TStore) => state.resources.query;
const getCurrentFolder = (state: TStore) => state.resources.currentFolder;
const getResourcesData = (state: TStore) => state.resources.data;
const getResourcesOrder = (state: TStore) => ({
	order: state.resources.order,
	order_dir: state.resources.order_dir,
});
const getResourcesMeta = (state: TStore) => state.resources.meta;
const getCurrentCommunityId = (state: TStore) => state.app.currentCommunityId;
const getRedirectQuery = (state: TStore) => state.resources.redirectQuery;

function* getResourcesHandle({ payload }: ReturnType<typeof getResources>) {
	try {
		yield put(setResourcesLoading(true));
		const communityId = yield select(getCommunityId);
		const groupId = yield select(getGroupId);
		const selectedTags = yield select(getSelectedTags);
		const q = yield select(getQuery);
		const currentFolder = yield select(getCurrentFolder);
		const resourcesData = yield select(getResourcesData);
		const meta = yield select(getResourcesMeta);
		const order = yield select(getResourcesOrder);
		const rQ = yield select(getRedirectQuery);

		let page = parseInt(meta?.pagination?.current_page);
		if (payload?.reset || resourcesData?.length === 0 || !resourcesData) {
			page = 1;
		} else {
			page++;
		}

		const { data } = yield call(api.resources.get, {
			communityId,
			groupId,
			tag_clicked: !!selectedTags.length,
			q: !!selectedTags.length ? selectedTags[0] : q,
			folder_id: currentFolder,
			page,
			resourceId: rQ,
			...order,
		});
		if (!data) {
			yield put(setResourcesLoading(false));
		} else {
			yield put(setResources({ data, reset: payload?.reset }));
			yield put(setResourcesLoading(false));
		}
	} catch (error) {
		rollbar.error(
			error
		)
		console.error({ error });
		yield put(setResourcesLoading(false));
	}
}

function* setFilterTagsHandle({ payload }: any) {
	const tags: {
		id: number;
		name: string;
		is_selected: boolean;
	}[] = yield select(getCommunityTags);
	const parsedTags = tags
		.filter((item) => {
			return payload.tag.find(
				(element: string) =>
					element.toLowerCase().trim() ===
					item.name.toLowerCase().trim(),
			);
		})
		.map((item) => item.name);
	yield put(setResourcesFilterTagsInStoreAction({ parsedTags }));
	return {
		selectedTags: parsedTags,
	};
}

function* handleSetQueryAndFilter({
	payload,
}: ReturnType<typeof setResourcesQueryAndFilterAction>) {
	try {
		yield put(setResourcesQueryAction({ query: payload.query }));
		yield put(getResources({ reset: true }));
	} catch (error) {
		rollbar.error(
			error
		)
		console.error({ error });
	}
}

function* setResourcesCurrentFolderHandle({ payload }: any) {
	try {
		yield put(updateResourcesCurrentFolderAction(payload));
		yield put(getResources({ reset: true }));
	} catch (error) {
		rollbar.error(
			error
		)
		console.error({ error });
	}
}

function* incrementResourceViewsHandle({
	payload,
}: IIncrementResourceViewsAction) {
	try {
		yield put(incrementResourceViewsAction(payload));
	} catch (error) {
		rollbar.error(
			error
		)
		console.error({ error });
	}
}

function* updateResourcesOrderHandle({ payload }: any) {
	try {
		yield put(setResourcesOrderAction(payload));
		yield put(getResources({ reset: true }));
	} catch (error) {
		rollbar.error(
			error
		)
		console.error({ error });
	}
}
function* resourcesDeleteHandle({ payload }: ReturnType<typeof deleteResourceAction>) {
	const { resourceId } = payload;
	try {
		const currentCommunityId = yield select(getCurrentCommunityId);
		if (!resourceId || !currentCommunityId) {
			throw new Error("Missing parameters!");
		}
		yield call(api.resources.deleteReesource, {
			community_id: currentCommunityId,
			resourceId: resourceId,
		});
		yield put(getResources({ reset: true }))
	} catch (error) {
		rollbar.error(
			error
		)
		console.error({ error });
	}
}
export default function* rootSaga() {
	yield all([
		takeEvery(EResourcesActionTypes.getResources, getResourcesHandle),
		takeEvery(
			EResourcesActionTypes.setQueryAndFilter,
			handleSetQueryAndFilter,
		),
		takeEvery(
			EResourcesActionTypes.setResourcesCurrentFolder,
			setResourcesCurrentFolderHandle,
		),
		takeEvery(EResourcesActionTypes.setFilterTags, setFilterTagsHandle),
		takeEvery(
			EResourcesActionTypes.incrementResourceViews,
			incrementResourceViewsHandle,
		),
		takeEvery(
			EResourcesActionTypes.updateResourcesOrder,
			updateResourcesOrderHandle,
		),
		takeEvery(
			EResourcesActionTypes.deleteResources,
			resourcesDeleteHandle,
		),
	]);
}
