import { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import styles from "./styles.module.scss";
import { Loading } from "components";
import {
	changeSubscriptionPlanWithPaymentIntentAction,
	notify,
	updateToken,
} from "store";
import {
	CustomTokenPassword,
	isItAWhiteLabel,
	LocalStorageKey,
	LocalStorageKeyCustomToken,
} from "utils";

import CryptoJS, { AES } from "crypto-js";
import { ECommunityAccessStatus, ECommunityType } from "models";

const ProcessingChangingPlansWithSetupIntentPayment = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [triggeTheChangePlanAction, setTriggerTheChangePlanAction] =
		useState(false);

	const clientSecret = new URLSearchParams(window.location.search).get(
		"setup_intent_client_secret",
	);

	const stripePlanPriceId = new URLSearchParams(window.location.search).get(
		"stripe_plan_price_id",
	);
	const currentSubscriptionId = new URLSearchParams(
		window.location.search,
	).get("current_subscription_id");
	const communityId = new URLSearchParams(window.location.search).get(
		"community_id",
	);
	const typeOfCommunity = new URLSearchParams(window.location.search).get(
		"type_of_community",
	);
	const subscriptionAccessStatus = new URLSearchParams(
		window.location.search,
	).get("subscription_access_status");

	useEffect(() => {
		if (!triggeTheChangePlanAction) {
			setTriggerTheChangePlanAction(true);
			if (
				!clientSecret ||
				!stripePlanPriceId ||
				!currentSubscriptionId ||
				!communityId ||
				!typeOfCommunity
			) {
				if (isItAWhiteLabel()) {
					navigate("/joining/init", {
						state: {
							error: true,
						},
					});
				} else {
					navigate("/communities");
				}
			} else {
				const customToken = localStorage.getItem(
					LocalStorageKeyCustomToken,
				);
				const token = localStorage.getItem(LocalStorageKey);
				let localStorageUserId: number = 0;
				let localStorageCommunityId: number = 0;

				if (customToken) {
					let decryptCustomToken = AES.decrypt(
						customToken,
						CustomTokenPassword,
					);
					let arrCustomToken = decryptCustomToken
						.toString(CryptoJS.enc.Utf8)
						.split(" ");

					localStorageUserId = parseInt(arrCustomToken[0]);
					//** Replace the community saved in the local storage (if any) with the one returned by the payment process url. */
					localStorageCommunityId = parseInt(communityId);
				}

				if (localStorageUserId && localStorageCommunityId) {
					const customTokenValEncrypted = AES.encrypt(
						`${localStorageUserId} ${localStorageCommunityId} ${token}`,
						CustomTokenPassword,
					);
					localStorage.setItem(
						LocalStorageKeyCustomToken,
						customTokenValEncrypted.toString(),
					);
				} else {
					if (isItAWhiteLabel()) {
						navigate("/joining/init", {
							state: {
								error: true,
							},
						});
					} else {
						navigate("/communities");
					}
				}

				if (token) {
					// update the token for axios.
					updateToken(token);
				}

				if (
					typeOfCommunity === ECommunityType.join &&
					subscriptionAccessStatus === ECommunityAccessStatus.trialing
				) {
					window.location.assign(
						`${window.location.origin}/dashboard`,
					);
				} else if (typeOfCommunity === ECommunityType.current) {
					dispatch(
						changeSubscriptionPlanWithPaymentIntentAction({
							stripePlanPriceId: stripePlanPriceId,
							currentSubscriptionId: parseInt(
								currentSubscriptionId,
							),
							communityId: parseInt(communityId),
							onSuccessCallback: () => {
								notify(
									"success",
									"The plan has been updated successfully!",
								);
								window.location.assign(
									`${window.location.origin}/dashboard`,
								);
							},
							onErrorCallback: () => {
								notify(
									"error",
									"An error occurred. Please try again later.",
								);
								if (isItAWhiteLabel()) {
									navigate("/joining/init", {
										state: {
											error: true,
										},
										replace: true,
									});
								} else {
									navigate("/communities");
								}
							},
						}),
					);
				} else {
					notify(
						"error",
						"An error occurred. Please try again later.",
					);
					if (isItAWhiteLabel()) {
						navigate("/joining/init", {
							state: {
								error: true,
							},
						});
					} else {
						navigate("/communities");
					}
				}
			}
		}
		// clean up the store.
		return () => {
			// TODO: create a reducer that will clean some specific fields within the store.
		};
	}, [
		clientSecret,
		currentSubscriptionId,
		dispatch,
		navigate,
		stripePlanPriceId,
		triggeTheChangePlanAction,
		communityId,
		typeOfCommunity,
		subscriptionAccessStatus,
	]);

	return (
		<div className={styles.container}>
			<Loading />
		</div>
	);
};

export default memo(ProcessingChangingPlansWithSetupIntentPayment);
