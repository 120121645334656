import { memo, useCallback, useEffect, useState } from "react";
import { MainLayout } from "layouts";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import styles from "./styles.module.scss";
import { TStore } from "models";
import { getVideo, incrementVideoViewCountAction, setVideo } from "store";
import { VideoItem } from "components";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.video.data,
	(video) => {
		return {
			video,
		};
	},
);

const Video = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { video } = useSelector(stateSelector);
	const { id } = useParams();
	const [videoHasBeenFetched, setVideoHasBeenFetched] = useState(false);
	const videoItem = video;

	const incrementVideoViewCount = useCallback(() => {
		if (!id) return;
		dispatch(incrementVideoViewCountAction(parseInt(id)));
	}, [dispatch, id]);

	const goBack = useCallback(() => {
		navigate(-1);
	}, [navigate]);

	useEffect(() => {
		if (id && !videoHasBeenFetched) {
			dispatch(getVideo({ videoId: parseInt(id) }));
			setVideoHasBeenFetched(true);
		}
		return () => {
			dispatch(setVideo({}));
		};
	}, [dispatch, id, videoHasBeenFetched]);

	return (
		<MainLayout>
			<>
				<div className={styles.backBackButtonContainer}>
					<div onClick={goBack}>
						<FontAwesomeIcon
							icon={faArrowLeft as unknown as IconProp}
							style={{
								cursor: "pointer",
								fontSize: "20px",
							}}
						/>
						<span>{"Back"}</span>
					</div>
				</div>
				{(videoItem?.video_link ||
					videoItem?.vimeo_uri ||
					videoItem?.resource_embed_uri) && (
					<VideoItem
						item={videoItem}
						incrementVideo={incrementVideoViewCount}
					/>
				)}
			</>
		</MainLayout>
	);
};

export default memo(Video);
