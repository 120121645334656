import { axiosInstance } from "..";

// function readFile(evt:any) {
//   var f = evt.target.files[0];

//   if (f) {
//     if (/(jpe?g|png|gif)$/i.test(f.type)) {
//       var r = new FileReader();
//       r.onload = function (e:any) {
//         var base64Img = e.target.result;
//         var binaryImg = convertDataURIToBinary(base64Img);
//         var blob = new Blob([binaryImg], { type: f.type });

//       }
//       r.readAsDataURL(f);
//     } else {
//       alert("Failed file type");
//     }
//   } else {
//     alert("Failed to load file");
//   }
// }

export const marketplaceOffer = {
	add: (payload: any) => {
		const {
			community_id,
			title,
			website,
			description,
			community_post,
			expiration_date,
		} = payload;
		const {
			pin_type,
			good_attributes: {
				max_price,
				how_much,
				good_type,
				postcode,
				latitude,
				longitude,
				currency,
			} = {
				max_price: 0,
				how_much: 0,
				good_type: "Sell",
				postcode: "",
				latitude: 0,
				longitude: 0,
				currency: "£",
			},
			all_groups,
			member_type_list,
			group_ids,
			tag_list,
		} = community_post;
		return axiosInstance().post(
			`/communities/${community_id}/community-posts`,
			{
				community_post: {
					title,
					description,
					pin_type,
					website,
					expiration_date,
					all_groups,
					good_attributes: {
						max_price,
						how_much,
						good_type,
						postcode,
						latitude,
						longitude,
						currency, // TODO check currency. I need two currencyies
					},
					member_type_list,
					group_ids,
					tag_list,
				},
			},
		);
	},
	addImages: (payload: any) => {
		const { community_id, community_post_id, images } = payload;
		let formData = new FormData();
		images.forEach((element: File) => {
			formData.append("images[]", element);
		});
		return axiosInstance().post(
			`/communities/${community_id}/community-posts/${community_post_id}/images`,
			formData,
			{
				headers: {
					"Content-Type": "multipart/form-data",
				},
			},
		);
	},
	getMarketplaceOfferForEdit: (payload: {
		community_id: number;
		community_post_id: number;
	}) =>
		axiosInstance().get(
			`/communities/${payload.community_id}/community-posts/${payload.community_post_id}/edit`,
		),

	getMarketplaceOfferRawData: (payload: {
		communityId: number;
		communityPostId: number;
	}) =>
		axiosInstance()
			.get(
				`/communities/${payload.communityId}/community-posts/${payload.communityPostId}`,
				{
					params: {
						is_goods: true,
					},
				},
			)
			.then((response) => {
				return response;
			})
			.catch((error) => {
				return error;
			}),

	update: (payload: any) => {
		const {
			post_id,
			community_id,
			title,
			website,
			description,
			community_post,
			expiration_date,

			delete_images,
		} = payload;
		const {
			pin_type,
			good_attributes: {
				max_price,
				how_much,
				good_type,
				postcode,
				latitude,
				longitude,
				currency,
			} = {
				max_price: 0,
				how_much: 0,
				good_type: "Sell",
				postcode: "",
				latitude: 0,
				longitude: 0,
				currency: "",
			},
			member_type_list,
			group_ids,
			tag_list,
			all_groups,
		} = community_post;
		return axiosInstance().patch(
			`/communities/${community_id}/community-posts/${post_id}`,
			{
				community_post: {
					title,
					description,
					pin_type,
					website,
					expiration_date,
					good_attributes: {
						max_price,
						how_much,
						good_type,
						postcode,
						latitude,
						longitude,
						currency, // TODO check currency. I need two currencyies
					},
					all_groups,
					member_type_list,
					group_ids,
					tag_list,
				},
				delete_images,
			},
		);
	},
};
