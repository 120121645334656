import { memo, useState } from "react";
import { Col, Row } from "antd";
// import { useSelector, useDispatch } from "react-redux";
// import { createSelector } from "reselect";
// import classNames from "classnames";

import { MainLayout } from "layouts";
import { CalendarEvents, ListOfEvent } from "./components";
// import styles from "./styles.module.scss";
// import { TStore } from "models";
// import { setCalendarDate } from "store";
import { HelmetPageTitle } from "components";

// const stateSelectorHandle = createSelector(
// 	(state: TStore) => state.events.selectedCalendarDate,
// 	(selectedCalendarDate) => ({ selectedCalendarDate }),
// );

const Calendar = () => {
	// const dispatch = useDispatch();
	// const stateSelector = useCallback(stateSelectorHandle, []);
	// const { selectedCalendarDate } = useSelector(stateSelector);
	// const handleFilterEvents = useCallback(
	// 	(value: "upcoming" | "nerby" | "specific") => () => {
	// 		switch (value) {
	// 			case "upcoming": {
	// 				// get upcoming events
	// 				// reset selected Data
	// 				dispatch(setCalendarDate({ date: undefined }));
	// 				break;
	// 			}
	// 			default:
	// 		}
	// 	},
	// 	[dispatch],
	// );
	const [view, setView] = useState(true);

	return (
		<>
			<HelmetPageTitle screenTitle={"Calendar"} />
			<MainLayout>
				<Row gutter={[16, 16]} style={{ height: "calc(100% - 32px)" }}>
					<Col xs={24} xl={16} style={{ minHeight: "50%" }}>
						{/* <div className={styles.calendarEventsContainer}> */}
						<CalendarEvents setView={setView} />
						{/* </div> */}
					</Col>
					<ListOfEvent />
				</Row>
			</MainLayout>
		</>
	);
};

export default memo(Calendar);
