import React, {
	memo,
	useCallback,
	useEffect,
	useState,
	useRef,
	useMemo,
} from "react";
import { Col } from "antd";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
	faTwitter,
	faInstagram,
	faLinkedin,
	faFacebookF,
} from "@fortawesome/free-brands-svg-icons";
import { useLocation } from "react-router-dom";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import { api } from "store";
import { TStore } from "models";
import GlobalTermsAndConditions from "../../components/legalAgreements/components/termsAndConditions";
import { MainLayout } from "layouts";
import { ScreenNameBar, Loading, HelmetPageTitle } from "components";
import calendar from "assets/images/icons/common_icons/calendar_ico.png";
import logo from "assets/images/logo.png";
import styles from "./styles.module.scss";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.app.currentCommunityId,
	(state: TStore) => state.community?.data?.services,
	(currentCommunityId, services) => ({ currentCommunityId, services }),
);

const TermsAndConditions: React.FunctionComponent = () => {
	const [loading, setLoading] = useState(true);
	const activeData = useRef<{
		about: any;
		terms: any;
	}>({
		about: {},
		terms: {},
	});
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { currentCommunityId, services } = useSelector(stateSelector);
	const location = useLocation();

	const showAbout = useMemo(
		() =>
			services
				? services.find((i: any) => i.label === "About").show
				: false,
		[services],
	);

	const showContact = useMemo(
		() =>
			services
				? services.find((i: any) => i.label === "Contact Us").show
				: false,
		[services],
	);

	const goTo = useCallback(
		(link: string) => () => {
			window.open(link, "_blank");
		},
		[],
	);

	const paths = [
		"/community/terms-and-conditions",
		"/community/contact-details",
		"/community/about-us",
	];

	const match = paths.indexOf(location.pathname) !== -1;

	useEffect(() => {
		if (currentCommunityId && match) {
			const fetch = async () => {
				activeData.current.about = await api.community.about({
					community_id: currentCommunityId,
				});

				activeData.current.terms = await api.community.terms({
					community_id: currentCommunityId,
				});
				setLoading(false);
			};
			fetch();
		}
	}, [currentCommunityId, match]);

	if (match && loading) return <Loading />;
	return (
		<>
			<HelmetPageTitle screenTitle={"About Us"} />
			<MainLayout>
				<div className={styles.mainWrapper}>
					<div className={styles.subWrapper}>
						<div id="aboutUsMain" className={styles.leftSideTop}>
							<div className={styles.logo}>
								{match && showAbout && (
									<img
										src={
											activeData.current.about.data
												.community.picture
										}
										alt="logo"
										style={{
											width: 125,
											height: 125,
											borderRadius: "100%",
										}}
									/>
								)}
								{!match && (
									<img
										src={logo}
										alt="logo"
										style={{
											objectFit: "contain",
											width: 150,
											height: 70,
										}}
									/>
								)}
							</div>
							<p
								className={styles.content}
								style={{ whiteSpace: "pre-line" }}
							>
								{match &&
									activeData.current.about.data.community
										.about}
								{!match &&
									`Ugenie works with any business that wants to gather customer
                                    community on a branded mobile hub, without having to spend time,
                                    money or resources on costly app development. We provide a safe
                                    space to share content, interact with customers and gather
                                    feedback. Ugenie supports: membership & subscription companies,
                                    nonprofits, professional bodies, chambers of commerce, coaches &
                                    consultants, business networks, religious organizations and more!`}
							</p>
						</div>

						{activeData.current.terms?.data?.community?.rules && (
							<>
								<ScreenNameBar
									icon={calendar}
									title="Terms & Conditions"
								/>
								<div
									id="termsAndConditionsMain"
									className={styles.leftSide}
								>
									<GlobalTermsAndConditions
										data={
											activeData.current.terms?.data
												?.community?.rules
										}
										communityName={
											activeData.current.terms?.data
												?.community?.name
										}
									/>
								</div>
							</>
						)}
					</div>
					{((match && showContact) || !match) && (
						<Col span={6} className={styles.linkedInContainer}>
							<div className={styles.rightSide}>
								{match &&
									activeData.current.terms.data.community
										.contact_email && (
										<div
											className={classNames(
												styles.link,
												styles.mail,
											)}
										>
											<div
												onClick={goTo(
													"mailto:" +
														activeData.current.terms
															.data.community
															.contact_email,
												)}
											>
												<FontAwesomeIcon
													icon={
														faEnvelope as unknown as IconProp
													}
												/>
											</div>
										</div>
									)}
								{!match && (
									<div
										className={classNames(
											styles.link,
											styles.mail,
										)}
									>
										<div
											onClick={goTo(
												"mailto:email@ugenie.com",
											)}
										>
											<FontAwesomeIcon
												icon={
													faEnvelope as unknown as IconProp
												}
											/>
										</div>
									</div>
								)}
								{match &&
									activeData.current.terms.data.community
										.facebook_link && (
										<div
											className={classNames(styles.link)}
										>
											<div
												onClick={goTo(
													activeData.current.terms
														.data.community
														.facebook_link,
												)}
											>
												<FontAwesomeIcon
													icon={
														faFacebookF as unknown as IconProp
													}
												/>
											</div>
										</div>
									)}
								{!match && (
									<div className={classNames(styles.link)}>
										<div
											onClick={goTo(
												"https://facebook.com/ugenie",
											)}
										>
											<FontAwesomeIcon
												icon={
													faFacebookF as unknown as IconProp
												}
											/>
										</div>
									</div>
								)}
								{match &&
									activeData.current.terms.data.community
										.twitter_link && (
										<div
											className={classNames(styles.link)}
										>
											<div
												onClick={goTo(
													activeData.current.terms
														.data.community
														.twitter_link,
												)}
											>
												<FontAwesomeIcon
													icon={
														faTwitter as unknown as IconProp
													}
												/>
											</div>
										</div>
									)}
								{!match && (
									<div className={classNames(styles.link)}>
										<div
											onClick={goTo(
												"https://twitter.com/ugenie",
											)}
										>
											<FontAwesomeIcon
												icon={
													faTwitter as unknown as IconProp
												}
											/>
										</div>
									</div>
								)}
								{match &&
									activeData.current.terms.data.community
										.instagram_link && (
										<div
											className={classNames(styles.link)}
										>
											<div
												onClick={goTo(
													activeData.current.terms
														.data.community
														.instagram_link,
												)}
											>
												<FontAwesomeIcon
													icon={
														faInstagram as unknown as IconProp
													}
												/>
											</div>
										</div>
									)}
								{!match && (
									<div className={classNames(styles.link)}>
										<div
											onClick={goTo(
												"https://instagram.com/ugenie",
											)}
										>
											<FontAwesomeIcon
												icon={
													faInstagram as unknown as IconProp
												}
											/>
										</div>
									</div>
								)}
								{match &&
									activeData.current.terms.data.community
										.linkedin_link && (
										<div
											className={classNames(styles.link)}
										>
											<div
												onClick={goTo(
													activeData.current.terms
														.data.community
														.linkedin_link,
												)}
											>
												<FontAwesomeIcon
													icon={
														faLinkedin as unknown as IconProp
													}
												/>
											</div>
										</div>
									)}
								{!match && (
									<div className={classNames(styles.link)}>
										<div
											onClick={goTo(
												"https://linkedin.com/ugenie",
											)}
										>
											<FontAwesomeIcon
												icon={
													faLinkedin as unknown as IconProp
												}
											/>
										</div>
									</div>
								)}
							</div>
						</Col>
					)}
				</div>
			</MainLayout>
		</>
	);
};

export default memo(TermsAndConditions);
