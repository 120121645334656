import React, { memo, useCallback } from "react";
import { Checkbox } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import classNames from "classnames";

import styles from "./styles.module.scss";
import { setMarketplaceOffersQuery } from "store";
import { useDispatch } from "react-redux";

interface IFilterTag {
	onClick: (checkedValue: any[]) => void;
	tagsList?: { id: number; name: string; is_selected: boolean }[];
	selectedTags?: string[];
	query?: string;
}

const MemberFilterTagUI: React.FunctionComponent<IFilterTag> = ({
	onClick,
	tagsList,
	selectedTags,
	query,
}) => {
	const parseTagsList = tagsList && tagsList.map((item) => item.name);
	const dispatch = useDispatch();
	const handleScrollTags = useCallback(
		(direction: "right" | "left") => () => {
			const elem = document.getElementById("checkbox-group");
			if (elem) {
				let scrollAmount = 0;
				const sliderTimer = setInterval(() => {
					direction === "left"
						? (elem.scrollLeft += 30)
						: (elem.scrollLeft -= 30);
					scrollAmount += 30;
					if (scrollAmount > 350) {
						window.clearInterval(sliderTimer);
					}
				}, 40);
			}
		},
		[],
	);
	const reset = useCallback(() => {
		onClick([]);
		dispatch(setMarketplaceOffersQuery({ query: "" }));
	}, [onClick, dispatch]);

	return (
		<div className={styles.container}>
			{query && (
				<div className={styles.activeState}>
					<div>
						Showing only items <br />
						containing: <strong>{query}</strong>
					</div>
					<div style={{ marginLeft: "auto" }}>
						<button
							type="button"
							onClick={reset}
							className={styles.button}
						>
							{"Reset"}
						</button>
					</div>
				</div>
			)}
			{!!selectedTags?.length && (
				<div className={styles.activeState}>
					<div>
						Showing only items tagged with:
						<strong>{selectedTags[0]}</strong>
					</div>
					<div style={{ marginLeft: "auto" }}>
						<button
							type="button"
							onClick={reset}
							className={styles.button}
						>
							{"Reset"}
						</button>
					</div>
				</div>
			)}
			{!selectedTags?.length && !query && (
				<>
					<LeftOutlined
						className={styles.arrow}
						onClick={handleScrollTags("right")}
					/>
					<div
						id="checkbox-group"
						className={classNames(
							styles.checkboxGroup,
							styles.accents,
						)}
					>
						<Checkbox.Group
							className={styles.customCheckBox}
							options={parseTagsList}
							onChange={onClick}
							value={selectedTags}
						/>
					</div>
					<RightOutlined
						className={styles.arrow}
						onClick={handleScrollTags("left")}
					/>
				</>
			)}
		</div>
	);
};

export default memo(MemberFilterTagUI);
