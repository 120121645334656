import {
	EMarketplaceOfferActionTypes,
	IMarketplaceOfferEditData,
	IMarketplaceOfferRawData,
	ISaveMarketplaceOfferPayload,
} from "models";

export const setMarketplaceOfferLoading = (payload: boolean) => ({
	type: EMarketplaceOfferActionTypes.marketplaceOfferLoadingSet,
	payload,
});

export const addMarketplaceOffer = (payload: {
	data: ISaveMarketplaceOfferPayload;
	successCallback: () => void;
	erroCallback: () => void;
}) => ({
	type: EMarketplaceOfferActionTypes.addMarketplaceOffer,
	payload,
});
export const updateMarketplaceOffer = (payload: {
	data: ISaveMarketplaceOfferPayload;
	successCallback: () => void;
	errorCallback: () => void;
}) => ({
	type: EMarketplaceOfferActionTypes.updateMarketplaceOffer,
	payload,
});
export const setMarketplaceOfferEditData = (payload: {
	data: IMarketplaceOfferEditData;
}) => ({
	type: EMarketplaceOfferActionTypes.setMarketplaceOfferEditData,
	payload,
});
export const selectMarketplaceOffer = (payload: {
	marketplaceOfferId: number;
	successCallback: () => void;
	erroCallback: () => void;
}) => ({
	type: EMarketplaceOfferActionTypes.selectMarketplaceOffer,
	payload,
});
export const resetMarketplaceOffer = () => ({
	type: EMarketplaceOfferActionTypes.resetMarketplaceOffer,
	//payload,
});
export const getMarketplaceOfferRawData = (payload: {
	id: number;
	callbackOnSuccess: () => void;
	callbackOnError: () => void;
	forceCommunityId?: number;
}) => ({
	type: EMarketplaceOfferActionTypes.getMarketplaceOfferRawData,
	payload,
});
export const getMarketplaceOfferEditData = (payload: {
	id: number;
	callbackOnSuccess: () => void;
	callbackOnError: () => void;
	forceCommunityId?: number;
}) => ({
	type: EMarketplaceOfferActionTypes.getMarketplaceOfferEditData,
	payload,
});
export const setMarketplaceOfferRawData = (payload: {
	data: IMarketplaceOfferRawData;
}) => ({
	type: EMarketplaceOfferActionTypes.setMarketplaceOfferRawData,
	payload,
});
