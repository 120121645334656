import { memo, useState, useRef, useEffect } from "react";

import styles from "./styles.module.scss";

const ReadMore = (props: any) => {
	const { text, maxLines = 0 } = props;
	const [isExpanded, setIsExpanded] = useState<boolean>(false);
	const contentRef = useRef<any>();

	const handleToggle = () => {
		setIsExpanded(!isExpanded);
	};

	const [showMore, setShowMore] = useState(false);

	useEffect(() => {
		if (
			contentRef.current &&
			contentRef.current.scrollHeight > maxLines &&
			maxLines > 0
		) {
			setShowMore(true);
		}
	}, [maxLines]);

	return (
		<div className={styles.readMore}>
			<div
				ref={contentRef}
				className={isExpanded ? "expanded" : "collapsed"}
				style={{
					maxHeight: isExpanded
						? props.maxHeight
							? props.maxHeight
							: "100%"
						: maxLines,
					lineHeight: "20px",
					overflow: isExpanded ? "auto" : "hidden",
					whiteSpace: "break-spaces",
				}}
			>
				{text}
			</div>
			{showMore && (
				<p
					id="read_more"
					onClick={handleToggle}
					style={{
						margin: "5px 0 0 0",
						cursor: "pointer",
						color: "#00BDD9",
						fontFamily: "Nunito-SemiBold",
					}}
				>
					{isExpanded ? "See less" : "See more"}
				</p>
			)}
		</div>
	);
};

export default memo(ReadMore);
