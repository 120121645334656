import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { useNavigate } from "react-router-dom";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "moment/locale/en-gb";

import styles from "./styles.module.scss";
import { TStore } from "models";
import { getEvent, getEvents, notify, setCalendarDate } from "store";
import { DateCellWrapper, CustomToolbar } from "../";
import eventWrapper from "../eventWrapper";

const localizer = momentLocalizer(moment);

const stateSelectorHandle = createSelector(
	(state: TStore) => state.events,
	(state: TStore) => state.auth.credentials?.user,
	(state: TStore) => state.app.currentCommunityId,
	(state: TStore) => state.app.currentGroupId,
	(events, user, currentCommunityId, currentGroupId) => ({
		events,
		user,
		currentCommunityId,
		currentGroupId,
	}),
);

const CalendarEvents: React.FunctionComponent<any> = ({ setView }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { events, currentCommunityId, currentGroupId } =
		useSelector(stateSelector);
	const [currentDate, setCurrentDate] = useState({
		start: moment().startOf("month").subtract(4, "days"),
		end: moment().endOf("month").add(4, "days"),
	});

	const updateDate = useCallback(
		(newDate: Date[] | { start: Date; end: Date }) => {
			if (!newDate) return;
			if (Array.isArray(newDate)) {
				let startDate;
				let endDate;
				if (newDate.length === 7) {
					// week view
					startDate = moment(newDate[0]).startOf("day");
					endDate = moment(newDate[6]).endOf("day");
				} else if (newDate.length === 1) {
					// day view
					startDate = moment(newDate[0]).startOf("day");
					endDate = moment(newDate[0]).endOf("day");
				} else {
					startDate = moment().startOf("day");
					endDate = moment().endOf("day");
				}
				setCurrentDate({
					start: startDate,
					end: endDate,
				});
			} else if (typeof newDate === "object") {
				setCurrentDate({
					start: moment(newDate.start).startOf("day"),
					end: moment(newDate.end).endOf("day"),
				});
			}
		},
		[setCurrentDate],
	);
	const parsedEvents = useMemo(() => {
		const eventsData = events.data;

		if (!eventsData || eventsData.length === 0) return [];
		return eventsData.map((item) => {
			const {
				id,
				title,
				starts_at,
				ends_at,
				all_day,
				location,
				event_tag_list,
			} = item;
			return {
				id,
				title,
				start: new Date(starts_at),
				end: new Date(ends_at),
				allDay: all_day,
				location,
				event_tag_list,
			};
		});
	}, [events]);

	const handleOnSelectEvent = useCallback(
		(event: {
			id: number;
			title: string;
			start: Date;
			end: Date;
			allDay: boolean;
		}) => {
			if (!event) {
				notify("error", "An error occurred. Please try again later.");
				return;
			}
			dispatch(getEvent({ id: event.id }));
			navigate(`/calendar/events/${event.id}`);
		},
		[dispatch, navigate],
	);

	const handleOnSelectSlot = useCallback(
		(slotInfo: {
			start: string | Date;
			end: string | Date;
			slots: Date[] | string[];
			action: "select" | "click" | "doubleClick";
		}) => {
			dispatch(setCalendarDate({ date: slotInfo.start }));
		},
		[dispatch],
	);
	const handleSlotPropGetter = (date: Date) => {
		return {
			style: { backgroundColor: "red !important" },
		};
	};

	useEffect(() => {
		if (!currentCommunityId) return;

		dispatch(
			getEvents({
				communityId: currentCommunityId,
				start: !events.selectedCalendarDate
					? currentDate.start.format("MM/DD/YYYY hh:mm:ss")
					: moment(events.selectedCalendarDate)
							.add(-3, "months")
							.format("MM/DD/YYYY hh:mm:ss"),
				end: !events.selectedCalendarDate
					? currentDate.end.format("MM/DD/YYYY hh:mm:ss")
					: moment(events.selectedCalendarDate)
							.add(+3, "months")
							.format("MM/DD/YYYY hh:mm:ss"),
				groupId: currentGroupId,
			}),
		);
	}, [
		dispatch,
		currentDate,
		events.selectedCalendarDate,
		currentCommunityId,
		currentGroupId,
	]);

	return (
		<>
			<div className={styles.container}>
				<Calendar
					onView={(e) => {
						setView(false);
					}}
					localizer={localizer}
					events={parsedEvents}
					startAccessor="start"
					endAccessor="end"
					style={{ width: "100%" }}
					slotPropGetter={handleSlotPropGetter}
					selectable={"ignoreEvents"}
					onSelectSlot={handleOnSelectSlot}
					onRangeChange={updateDate}
					components={{
						toolbar: CustomToolbar,
						dateCellWrapper: DateCellWrapper,
						eventWrapper: eventWrapper,
					}}
					onDrillDown={(e) => {
						dispatch(setCalendarDate({ date: e }));
					}}
					onSelectEvent={handleOnSelectEvent}
				/>
			</div>
			<div className={styles.mobileContainer}>
				<Calendar
					onView={(e) => {
						setView(false);
					}}
					localizer={localizer}
					events={parsedEvents}
					startAccessor="start"
					endAccessor="end"
					style={{ width: "100%" }}
					slotPropGetter={handleSlotPropGetter}
					selectable={"ignoreEvents"}
					onSelectSlot={handleOnSelectSlot}
					onRangeChange={updateDate}
					components={{
						toolbar: CustomToolbar,
						dateCellWrapper: DateCellWrapper,
						eventWrapper: eventWrapper,
					}}
					onDrillDown={(e) => {
						navigate(`/calendar/eventList`, { state: e });
					}}
					onSelectEvent={handleOnSelectEvent}
				/>
			</div>
		</>
	);
};

export default memo(CalendarEvents);
