import { memo, useCallback } from "react";
import classNames from "classnames";
import moment from "moment";
import { useDispatch } from "react-redux";

import { Tag } from "antd";
import { IMessageThread } from "models";
import { GetImg } from "utils";
import { setSelectedThread, updateConversation } from "store";
import styles from "./styles.module.scss";

interface IPreviewMessageCard {
	thread: IMessageThread;
	isSelected: boolean;
	openMultiThread?: Function;
}

const PreviewMessageCard = ({
	thread,
	isSelected,
	openMultiThread,
}: IPreviewMessageCard) => {
	const {
		user,
		users,
		timestamp,
		message_counter,
		body,
		picture,
		title,
		activity_type,
		respond_length,
		community_post_id,
	} = thread;

	const dispatch = useDispatch();

	const onClick = useCallback(() => {
		dispatch(updateConversation({ data: null, meta: null }));
		if (thread.activity_type === "community_post" && !thread.user) {
			openMultiThread && openMultiThread(thread);
			dispatch(setSelectedThread(null));
		} else if (!thread.id && thread.user) {
			// new created thread with no message
			dispatch(
				setSelectedThread({
					id: 0,
					user: thread.user,
				}),
			);
		} else {
			dispatch(setSelectedThread(thread));
		}
	}, [dispatch, thread, openMultiThread]);

	return (
		<div
			className={classNames(styles.container, {
				[styles.selected]: isSelected,
			})}
			onClick={onClick}
		>
			<div className={styles.top}>
				<div className={styles.leftSide}>
					<GetImg
						endpoint={
							user?.picture || (users && picture) || picture
						}
						className={styles.avatar}
						loadingSize={30}
					/>
					<div className={styles.userName}>
						{(user && user.name) ||
							(user && user.full_name) ||
							title}
					</div>
				</div>
				<div className={styles.rightSide}>
					{!!message_counter &&
						message_counter > 0 &&
						((thread.activity_type === "community_post" && !user) ||
							thread.activity_type !== "community_post") && (
							<div className={styles.leftTopBullet}>
								{message_counter || 0}
							</div>
						)}
					<div>
						{moment
							.duration(moment().diff(moment(timestamp)))
							.humanize()}
					</div>
				</div>
			</div>
			<div
				className={classNames(styles.bottom, {
					[styles.boldMessage]: message_counter,
				})}
			>
				{(activity_type === "community_post" ||
					!!community_post_id) && (
					<Tag
						color="gray"
						style={{ marginTop: 4, borderRadius: 10 }}
					>
						{"post"}
					</Tag>
				)}
				{(activity_type === "community_post" &&
					!user &&
					`${respond_length} active chats`) ||
					(title && title !== "." && title) ||
					body}
			</div>
		</div>
	);
};

export default memo(PreviewMessageCard);
