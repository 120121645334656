import React, { memo, useCallback } from "react";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { ReactComponent as Close } from "assets/images/icons/menu_icons/close.svg";
import { ReactComponent as LeftArrow } from "assets/images/icons/menu_icons/arrow-left.svg";
import { TStore } from "models";
import { ChangeCommunityMenu, GroupSection } from "./components";
import { GetImg, whitelistSrc, isItAWhiteLabel } from "utils";
import styles from "./styles.module.scss";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.community,
	(state: TStore) => state.app.siderMenu.showJoinedCommuntiesList,
	(state: TStore) => state.app.currentGroupId,
	(community, siderMenuShowJoinedCommuntiesList, currentGroupId) => ({
		community,
		siderMenuShowJoinedCommuntiesList,
		currentGroupId,
	}),
);

interface ISiderTopInfo {
	handleTriggerTheDrawerMenu: () => void;
	absoluteSideBar: boolean;
}

const SiderTopInfo: React.FunctionComponent<ISiderTopInfo> = ({
	handleTriggerTheDrawerMenu,
	absoluteSideBar,
}) => {
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { community, siderMenuShowJoinedCommuntiesList, currentGroupId } =
		useSelector(stateSelector);

	return (
		<div
			className={
				currentGroupId ? styles.groupSiderTopInfo : styles.siderTopInfo
			}
			style={{
				backgroundColor: currentGroupId
					? "var(--announcementHeaderBackground)"
					: "transparent",
			}}
		>
			{currentGroupId ? (
				<GroupSection
					handleTriggerTheDrawerMenu={handleTriggerTheDrawerMenu}
					absoluteSideBar={absoluteSideBar}
				/>
			) : (
				<div className={styles.communitySection}>
					{!absoluteSideBar && (
						<>
							{isItAWhiteLabel() ? null : (
								<ChangeCommunityMenu
									handleTriggerTheDrawerMenu={
										handleTriggerTheDrawerMenu
									}
								/>
							)}
							<div className={styles.closeButtonContainerMobile}>
								<Close onClick={handleTriggerTheDrawerMenu} />
							</div>
						</>
					)}
					<div
						className={
							absoluteSideBar
								? styles.absoluteCloseButtonContainer
								: styles.closeButtonContainer
						}
					>
						{!absoluteSideBar ? (
							<Close onClick={handleTriggerTheDrawerMenu} />
						) : (
							<LeftArrow onClick={handleTriggerTheDrawerMenu} />
						)}
					</div>
					{!siderMenuShowJoinedCommuntiesList && (
						<div
							className={
								absoluteSideBar
									? styles.absoluteLogoSection
									: styles.logoSection
							}
						>
							<Link to="/" className={styles.logo}>
								{community?.data &&
								whitelistSrc.indexOf(
									community.data && community?.data?.picture,
								) === -1 ? (
									<GetImg
										endpoint={
											community.data &&
											community.data.picture
										}
									/>
								) : (
									<img
										src={
											community.data &&
											community.data.picture
										}
										alt="community_profile_picture"
									/>
								)}
							</Link>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default memo(SiderTopInfo);
