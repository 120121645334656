import {
	ICommunitiesJoinedState,
	ECommunitiesJoinedActionTypes,
	TCommunitiesJoinedActions,
} from "models";

const initial: ICommunitiesJoinedState = {
	loading: false,
	searchQuery: "",
	data: undefined,
	meta: {
		invites: undefined,
		pagination: {
			current_page: 1,
			per_page: 10,
			has_next_page: false,
			total_objects: 0,
			total_pages: 0,
		},
	},
	// TODO: add meta property
};

export function communitiesJoinedReducer(
	state = initial,
	action: TCommunitiesJoinedActions,
): ICommunitiesJoinedState {
	switch (action.type) {
		case ECommunitiesJoinedActionTypes.communitiesJoinedLoadingSet: {
			return {
				...state,
				loading: action.payload,
			};
		}
		case ECommunitiesJoinedActionTypes.setSearchQuery: {
			return {
				...state,
				searchQuery: action.q,
			};
		}
		case ECommunitiesJoinedActionTypes.setCommunitiesJoined: {
			const communitiesData = action.payload.communities.map(
				(item: any) => {
					const { ...itemData } = item;
					return {
						...itemData,
					};
				},
			);

			return {
				...state,
				data: action.payload.init
					? communitiesData
					: state?.data
					? [...state.data].concat(communitiesData)
					: communitiesData,
				meta: action.payload.meta,
			};
		}
		case ECommunitiesJoinedActionTypes.updateJoinedAndApprovedStatusForJoinedCommunity: {
			const { communityId, joined, approved } = action.payload;
			if (communityId && state?.data && !joined && !approved) {
				// if the user decide to leave a community then that community should be removed from this state.
				const resultedCommunitiesList = state.data.filter(
					(item) => item.id !== communityId,
				);

				return {
					...state,
					data: [...resultedCommunitiesList],
				};
			} else if (communityId && state?.data && joined && approved) {
				let selectedCommunityIndex = 0;
				const selectedCommunity = [...state.data].find(
					(item, index) => {
						if (item.id === communityId) {
							selectedCommunityIndex = index;
							return true;
						} else {
							return false;
						}
					},
				);

				if (selectedCommunity) {
					selectedCommunity.joined = joined;
					selectedCommunity.approved = approved;

					const resultedState = {
						...state,
						data: [...state.data],
					};
					resultedState.data[selectedCommunityIndex] = {
						...selectedCommunity,
					};

					return resultedState;
				} else {
					return { ...state };
				}
			} else {
				return { ...state };
			}
		}
		default:
			return state;
	}
}
