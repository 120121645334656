import { memo, useCallback, useState, useMemo } from "react";
import { AutoComplete, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import classNames from "classnames";

import styles from "./styles.module.scss";
import { axiosInstance, setSelectedCommunityToJoinUserLocation } from "store";
import { ISelectedCommunityToJoin, TStore } from "models";
import { rollbar } from "utils";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.auth?.credentials?.user,
	(authenticatedUser) => ({ authenticatedUser }),
);
const { Option } = Select;

interface ILocation {
	selectedCommunityToJoinInfo: ISelectedCommunityToJoin;
	setErrorNoLocationAdded: React.Dispatch<React.SetStateAction<boolean>>;
	errorNoLocationSelected: boolean;
}

const Location: React.FunctionComponent<ILocation> = ({
	selectedCommunityToJoinInfo,
	setErrorNoLocationAdded,
	errorNoLocationSelected,
}) => {
	
	const dispatch = useDispatch();
	const [locations, setLocations] = useState<any>([]);
	
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { authenticatedUser = { location: "" } } = useSelector(stateSelector);

	const locationsOptions = useMemo(() => {
		if (!locations) return;

		return locations.map(
			(item: {
				label: string;
				value: {
					address: string;
				};
			}) => {
				const {
					label,
					value: { address },
				} = item;
				return (
					<Option key={label} value={address}>
						{label}
					</Option>
				);
			},
		);
	}, [locations]);

	const onSearchLocation = useCallback(
		async (value: string) => {
			if (selectedCommunityToJoinInfo.userProfileLocation?.address) {
				dispatch(
					setSelectedCommunityToJoinUserLocation({
						address: "",
						latitude: 0,
						longitude: 0,
					}),
				);
			}

			if (!value) return;

			try {
				const { data: { results } = { results: [] } } =
					await axiosInstance().get("/geocoder", {
						params: {
							address: value,
						},
					});
				const parsedData = results.map(
					(item: {
						address: string;
						latitude: number;
						longitude: number;
					}) => {
						const { address, latitude, longitude } = item;
						return {
							label: address,
							value: JSON.stringify({
								address,
								latitude,
								longitude,
							}),
						};
					},
				);
				setLocations(parsedData);
			} catch (error) {
				rollbar.error(error)
				console.error(error);
			}
		},
		[dispatch, selectedCommunityToJoinInfo.userProfileLocation?.address],
	);

	const onSelect = useCallback(
		(val) => {
			let location = locations.find(
				(item: { label: string }) => item.label === val,
			);
			location = location ? JSON.parse(location.value) : "";
			dispatch(
				setSelectedCommunityToJoinUserLocation(
					location ? { ...location } : "",
				),
			);
			setErrorNoLocationAdded(location ? false : true);
		},
		[dispatch, locations, setErrorNoLocationAdded],
	);

	return (
		<div className={styles.container}>
			<div className={styles.boldWeightLabel}>
				{"Where are you located?"}
			</div>
			<div
				className={classNames(styles.subTitle, {
					[styles.errorText]: errorNoLocationSelected,
					[styles.greyText]: !errorNoLocationSelected,
				})}
			>
				{"(the location is mandatory)"}
			</div>
			<AutoComplete
				className={styles.autocomplete}
				onSearch={onSearchLocation}
				onSelect={onSelect}
				placeholder="Enter your town, post code or zip code to start searching..."
				inputValue={
					selectedCommunityToJoinInfo.userProfileLocation?.address
						? selectedCommunityToJoinInfo.userProfileLocation
								.address
						: authenticatedUser.location
				}
				defaultValue={selectedCommunityToJoinInfo.userProfileLocation?.address
					? selectedCommunityToJoinInfo.userProfileLocation
							.address
					: authenticatedUser.location}
			>
				{locationsOptions}
			</AutoComplete>
			<div className={styles.greySmallText} style={{lineHeight: "16px", color: "#4D5A81", marginTop: "8px", marginBottom: "24px"}}>
				Only distance will be shown to other members. Providing your
				location will help us connect you to the most appropriate
				members of the community.
			</div>
		</div>
	);
};

export default memo(Location);
