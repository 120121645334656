import { memo, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "antd";

import styles from "./styles.module.scss";
import {
	getUnreadNotificationsCounterSagaAction,
	markAllAsReadSagaAction,
} from "store/actions/notifications";
import { notify } from "store";

interface IMarkAllAsRead {
	notitficationCount: number;
}

const MarkAllAsRead: React.FunctionComponent<IMarkAllAsRead> = memo(
	({ notitficationCount }) => {
		const [loading, setLoading] = useState<boolean>(false);
		const dispatch = useDispatch();
		const handleOnErrorCallback = () => {
			setLoading(false);
			notify("error", "Something went wrong.", "Please try again later.");
		};

		const handleOnSuccessCallback = () => {
			setLoading(false);
			dispatch(getUnreadNotificationsCounterSagaAction());
		};

		const markAllAsRead = () => {
			setLoading(true);
			dispatch(
				markAllAsReadSagaAction({
					onSuccessCallback: handleOnSuccessCallback,
					onErrorCallback: handleOnErrorCallback,
				}),
			);
		};

		return (
			<div className={styles.container}>
				{notitficationCount > 0 ? (
					<Button
						type="primary"
						className={styles.primaryBtn}
						onClick={markAllAsRead}
						loading={loading}
					>
						{"Mark all as read"}
					</Button>
				) : (
					<Button
						// type="primary"
						className={styles.disableBtn}
					>
						{"Mark all as read"}
					</Button>
				)}
			</div>
		);
	},
);

export default MarkAllAsRead;
