/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { Button } from "antd";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import styles from "./styles.module.scss";
import { MainLayout } from "layouts";
import {
	FilterByPath,
	FilterByTags,
	FilterHeaderButtons,
	List,
} from "./components";
import { IDocsState, IResourcesState, IVideosState, TStore } from "models";
import {
	HelmetPageTitle,
	UploadDocForm,
	UploadVideoForm,
	UploadResourceForm,
} from "components";
import { UploadResourceProgress } from "components/UploadResourceForm/components/uploadResourceProgress";
import { UploadVideoProgress } from "components/UploadVideoForm/components/uploadVideoProgress";
import {
	setDocsRedirectQuery,
	setVideosQueryAndFilterAction,
	setVideosRedirectQuery,
	setDocsQueryAndFilterAction,
	setResourcesQueryAndFilterAction,
} from "store";

const stateSelectorHandle = (type: keyof TStore) =>
	createSelector(
		(state: TStore) => {
			const results: IVideosState | IDocsState | IResourcesState = state[
				type
			] as IVideosState | IDocsState | IResourcesState;
			return results.meta.pagination.total_objects;
		},
		(state: TStore) =>
			state.posts?.meta?.appearance?.video_display_name?.plural,
		(state: TStore) =>
			state.posts?.meta?.appearance?.document_display_name?.plural,
		(state: TStore) => state.posts?.meta?.appearance?.resource_display_name,
		(state: TStore) => state.video.uploadProgress,
		(state: TStore) => state.video.videoId,
		(state: TStore) => state.video.videoSuccessfullyUploaded,
		(state: TStore) => state.video.createdInterval,
		(state: TStore) => state.resource.uploadProgress,
		(state: TStore) => state.resource.resourceId,
		(state: TStore) => state.resource.resourceSuccessfullyUploaded,
		(state: TStore) => state.resource.createdInterval,
		(
			count,
			videoDisplayName,
			documentDisplayName,
			resourceDisplayName,
			videoUploadProgress,
			videoId,
			videoSuccessfullyUploaded,
			videoCreatedInterval,
			resourceUploadProgress,
			resourceId,
			resourceSuccessfullyUploaded,
			resourceCreatedInterval,
		) => {
			return {
				count,
				videoDisplayName,
				documentDisplayName,
				resourceDisplayName,
				videoUploadProgress,
				videoId,
				videoSuccessfullyUploaded,
				videoCreatedInterval,
				resourceUploadProgress,
				resourceId,
				resourceSuccessfullyUploaded,
				resourceCreatedInterval,
			};
		},
	);

const Media = ({ type }: { type: keyof TStore }) => {
	const location = useLocation();
	const stateSelector = useCallback(stateSelectorHandle(type), []);
	const {
		count,
		videoDisplayName,
		documentDisplayName,
		resourceDisplayName,
		videoUploadProgress,
		videoId,
		videoSuccessfullyUploaded,
		videoCreatedInterval,
		resourceUploadProgress,
		resourceId,
		resourceSuccessfullyUploaded,
		resourceCreatedInterval,
	} = useSelector(stateSelector);
	const [showUploadDocForm, setShowUploadDocForm] = useState<boolean>(false);
	const [showUploadVideoForm, setShowUploadVideoForm] =
		useState<boolean>(false);
	const [showUploadResourceForm, setShowUploadResourceForm] =
		useState<boolean>(false);
	const dispatch = useDispatch();

	const helmetPageTitle: string = useMemo(() => {
		if (type === "videos") {
			dispatch(setVideosRedirectQuery({ redirectQuery: location.state }));
			location.state = null;

			return videoDisplayName ? videoDisplayName : "Videos";
		} else if (type === "documents") {
			dispatch(setDocsRedirectQuery({ redirectQuery: location.state }));

			return documentDisplayName ? documentDisplayName : "Documents";
		} else if (type === "resources") {
			// dispatch(setResourcesRedirectQuery({redirectQuery:location.state}));

			return resourceDisplayName ? resourceDisplayName : "Resources";
		} else {
			return "Media";
		}
	}, [type, videoDisplayName, documentDisplayName, resourceDisplayName]);

	const handleShowUploadForm = useCallback(
		(askTheUserToConfirm?: boolean) => {
			if (askTheUserToConfirm) {
				if (
					window.confirm(
						"Changes you made may not be saved. Do you really want to leave?",
					)
				) {
					if (type === "documents") {
						setShowUploadDocForm(!showUploadDocForm);
					} else if (type === "videos") {
						setShowUploadVideoForm(!showUploadVideoForm);
					} else if (type === "resources") {
						setShowUploadResourceForm(!showUploadResourceForm);
					}
				}
			} else {
				if (type === "documents") {
					setShowUploadDocForm(!showUploadDocForm);
				} else if (type === "videos") {
					setShowUploadVideoForm(!showUploadVideoForm);
				} else if (type === "resources") {
					setShowUploadResourceForm(!showUploadResourceForm);
				}
			}
		},
		[showUploadDocForm, showUploadVideoForm, showUploadResourceForm],
	);

	useEffect(() => {
		return () => {
			dispatch(setVideosQueryAndFilterAction(""));
			dispatch(setDocsQueryAndFilterAction(""));
			dispatch(setResourcesQueryAndFilterAction({ query: "" }));
		};
	}, []);

	return (
		<>
			<HelmetPageTitle screenTitle={helmetPageTitle} />
			{videoUploadProgress && type === "videos" ? (
				<UploadVideoProgress
					uploadProgress={videoUploadProgress}
					videoId={videoId}
					videoSuccessfullyUploaded={videoSuccessfullyUploaded}
					createdInterval={videoCreatedInterval}
				/>
			) : null}
			{resourceUploadProgress && type === "resources" ? (
				<UploadResourceProgress
					uploadProgress={resourceUploadProgress}
					resourceId={resourceId}
					resourceSuccessfullyUploaded={resourceSuccessfullyUploaded}
					createdInterval={resourceCreatedInterval}
				/>
			) : null}
			<MainLayout>
				<div className={styles.container}>
					{((!showUploadDocForm && type === "documents") ||
						(!showUploadVideoForm && type === "videos") ||
						(!showUploadResourceForm && type === "resources")) && (
						<div className={styles.header}>
							<div className={styles.primaryButton}>
								<Button
									type="primary"
									onClick={() => handleShowUploadForm()}
									disabled={
										(videoUploadProgress &&
											type === "videos") ||
										(resourceUploadProgress &&
											type === "resources")
											? true
											: false
									}
								>
									Upload{" "}
									{type === "documents"
										? "Document"
										: type === "videos"
										? "Video"
										: "Resource"}
								</Button>
							</div>
							<div className={styles.flexRowDiv}>
								<h1 className={styles.sortBy}>Sort by:</h1>

								<FilterHeaderButtons type={type} />
							</div>
						</div>
					)}
					{type === "documents" && showUploadDocForm ? (
						<UploadDocForm closeTheForm={handleShowUploadForm} />
					) : type === "videos" && showUploadVideoForm ? (
						<UploadVideoForm closeTheForm={handleShowUploadForm} />
					) : type === "resources" && showUploadResourceForm ? (
						<UploadResourceForm
							closeTheForm={handleShowUploadForm}
						/>
					) : (
						<div className={styles.mainContent}>
							<div className={styles.filterSections}>
								<FilterByPath type={type} />
							</div>
							<div className={styles.videosSection}>
								<FilterByTags type={type} count={count} />
								<List type={type} />
							</div>
						</div>
					)}
				</div>
			</MainLayout>
		</>
	);
};

export default memo(Media);
