import React, { useState, useEffect, memo } from "react";
import axios from "axios";

import { Loading } from "components";
import { LocalStorageKey } from "utils";

export const GetImg: React.FunctionComponent<any> = memo(
	({ endpoint, className, style, loadingSize }) => {
		const [imgSrc, setImgSrc] = useState<any>();
		const token = localStorage.getItem(LocalStorageKey);

		useEffect(() => {
			const abortController = new AbortController();
			if (endpoint)
				axios
					.get(endpoint, {
						headers: { Authorization: token ?? "" },
						responseType: "arraybuffer",
					})
					.then((response: any) => {
						if (!abortController.signal.aborted) {
							let data = `data:${
								response.headers["content-type"]
							};base64,${new Buffer(
								response.data,
								"binary",
							).toString("base64")}`;
							setImgSrc(data);
						}
					})
					.catch(function (error) {
						// handle error
						if (!abortController.signal.aborted) {
							setImgSrc(endpoint);
						}
					});
			return () => {
				abortController.abort();
			};
		}, [endpoint, token]);

		if (!imgSrc) return <Loading fontSize={loadingSize} />;
		else {
			return (
				<img
					src={imgSrc}
					alt="img"
					className={className}
					style={{
						...{ objectFit: "contain" },
						...{ ...(style || {}) },
					}}
				/>
			);
		}
	},
);
