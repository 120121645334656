import React, { memo, useState, useEffect, useCallback } from "react";
import { loadStripe, Stripe, StripeElementsOptions } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { createSelector } from "reselect";

import { Loading } from "components";
import { TStore } from "models";
import { useSelector } from "react-redux";
import { rollbar } from "utils";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.community,
	(community) => ({
		community,
	}),
);

interface IStripeWrapper {
	children: React.ReactNode;
	stripeLinkedAccountId: string;
	setupIntentClientSecret?: string;
}

const StripeWrapper = ({
	children,
	stripeLinkedAccountId,
	setupIntentClientSecret,
}: IStripeWrapper) => {
	const [stripeObject, setStripeObject] = useState<Stripe | null>(null);

	const stateSelector = useCallback(stateSelectorHandle, []);
	const { community } = useSelector(stateSelector);

	// This function will re-run if the accountId prop changes.
	useEffect(() => {
		try{const fetchStripeObject = async () => {
			// If there is no accountId, do not run the loadStripe function.
			if (stripeLinkedAccountId) {
				const res = await loadStripe(
					`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`,
					{
						stripeAccount: stripeLinkedAccountId,
					},
				);
				// When we have got the Stripe object, pass it into our useState.
				setStripeObject(res);
			}
		};
		fetchStripeObject();}
		catch (error) {
			rollbar.error(error)
		}
	}, [stripeLinkedAccountId]);

	// If no Stripe object, do not render the Stripe Element.
	if (!stripeObject) {
		return <Loading />;
	}

	const appearance = {
		theme: "stripe" as "stripe" | "none" | "night" | undefined,
		variables: {
			colorPrimary: community?.data?.primary_color ?? undefined,
			colorBackground: community?.data?.primary_color ?? undefined,
		},
	};

	const options: StripeElementsOptions = {
		clientSecret: setupIntentClientSecret ?? undefined,
		appearance,
	};

	// Once we have the Stripe object, load everything.
	return (
		<Elements stripe={stripeObject} options={options}>
			{children}
		</Elements>
	);
};

export default memo(StripeWrapper);
