import {
	IMemberSetAction,
	IMemberGetAction,
	IMembersSetLoading,
	IMemberPostsGetAction,
	IMemberPostsSetAction,
	IMemberBlock,
	IMemberUnblock,
} from "./actions";

// ENUMS
export enum EMemberActionTypes {
	memberLoadingSet = "member/set/loading",
	memberGet = "member/get",
	memberSet = "member/set",
	getMemberPosts = "member/posts/get",
	setMemberPosts = "member/posts/set",
	blockMember = "member/block",
	unblockMember = "member/unblock",
	reportMember = "member/report",
}

// INTERFACES
export interface IGetMemberDataPayload {
	type: any;
	payload: {
		communityId: number;
		memberId: number;
		page?: number; // for member posts
	};
}

export interface IGetMemberApi {
	payload: {
		communityId: number;
		memberId: number;
	};
}

export interface IMembership {
	created_at: string;
	group_id: number;
	id: number;
	membership_id: number;
	updated_at: string;
}
export interface IMemberState {
	loading: boolean;
	member: {
		id: number;
		about_me: string;
		location: string;
		blocked: boolean;
		blocked_by: boolean;
		membership_id: number;
		name: string;
		picture: string;
		email: string;
		full_name: string;
		distance: string;
		membership_type: string;
		industry: string | null;
		profession: string | null;
		interest_list: [];
		skill_list: {
			id: number;
			is_selected: boolean;
			name: string;
		}[];
		subset_list: {
			id: number;
			is_selected: boolean;
			name: string;
		}[];
		posts: {
			pins: any[];
			meta: {
				pagination: {
					current_page: number;
					per_page: number;
					total_pages: number;
					total_objects: number;
					has_next_page: boolean;
				};
			};
		};
	};
}

export interface IMemberLoadingPayload {
	loading: boolean;
}

// TYPES
export type TMemberActions =
	| IMemberSetAction
	| IMemberGetAction
	| IMembersSetLoading
	| IMemberPostsSetAction
	| IMemberPostsGetAction
	| IMemberBlock
	| IMemberUnblock;
