import { TPaymentsActions, EPaymentsTypes, IPaymentsState } from "models";

const initial: IPaymentsState = {
	memberSubscription: null,
};

export function paymentsReducer(
	state = initial,
	action: TPaymentsActions,
): IPaymentsState {
	switch (action.type) {
		case EPaymentsTypes.setMemberSubscription: {
			if (action?.payload?.payload && action.payload?.success) {
				const { payload, success, setup_intent_client_secret } =
					action.payload;
				return {
					...state,
					memberSubscription: {
						...state.memberSubscription,
						payload,
						success,
						setup_intent_client_secret,
					},
				};
			} else {
				return {
					...state,
					memberSubscription: null,
				};
			}
		}
		case EPaymentsTypes.setTheMemberSubscriptionToPending: {
			if (state?.memberSubscription?.payload) {
				return {
					...state,
					memberSubscription: {
						...state.memberSubscription,
						payload: {
							...state.memberSubscription.payload,
							subscription_status:
								action.payload.subscription_status,
						},
					},
				};
			} else {
				return { ...state };
			}
		}
		default:
			return state;
	}
}
