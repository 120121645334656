import { IOfferLoadingSetAction, IOfferSetAction } from "./actions";

// ENUMS
export enum EOfferActionTypes {
	offerLoadingSet = "offer/set/loading",
	setOffer = "offer/set",
	getOffer = "offer/get",
	redeemOffer = "offer/redeem",
}

// INTERFACES

export interface IOffer {
	id: number;
	description: string;
	search_tags: string[];
	title: string;
	business_name: string;
	contact: string;
	website: string;
	email: string;
	phone_number: string;
	is_online: boolean;
	location: string;
	latitude: number;
	longitude: number;
	active_from: string;
	forever: boolean;
	expires_at?: string;
	distance?: string;
	cover: string;
	facebook_link?: null;
	instagram_link?: null;
	redeem_email?: null;
	redeem_url?: null;
	tags?: string[];
}

export interface IOfferState {
	loading: boolean;
	data?: IOffer;
	currentId?: number;
}

export interface IOfferLoadingPayload {
	loading: boolean;
}

// TYPES
export type TOfferActions = IOfferLoadingSetAction | IOfferSetAction;
