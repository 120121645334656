import { EMemberActionTypes } from "models";

export const setMemberLoading = (payload: boolean) => ({
	type: EMemberActionTypes.memberLoadingSet,
	payload,
});
export const setMember = (payload: any) => (
	{
		type: EMemberActionTypes.memberSet,
		payload,
	});
export const getMember = (payload: {
	memberId: number;
	updateTheMemberItem?: boolean;
}) => ({
	// updateTheMemberItem is used to indicate whether the member item from members list should be updated or not

	type: EMemberActionTypes.memberGet,
	payload,
});
export const getMemberPosts = (payload: {
	memberId: number;
	communityId: number;
	page: number;
}) => ({
	type: EMemberActionTypes.getMemberPosts,
	payload,
});
export const setMemberPosts = (payload: any) => ({
	type: EMemberActionTypes.setMemberPosts,
	payload,
});
export const blockMember = (payload: {
	id: number;
	successCallback: () => void;
	errorCallback: () => void;
}) => ({
	type: EMemberActionTypes.blockMember,
	payload,
});
export const unblockMember = (payload: {
	id: number;
	successCallback: () => void;
	errorCallback: () => void;
}) => ({
	type: EMemberActionTypes.unblockMember,
	payload,
});
export const reportMember = (payload: {
	membershipId: number;
	message: string;
	successCallback: () => void;
	errorCallback: () => void;
}) => ({
	type: EMemberActionTypes.reportMember,
	payload,
});
