import { IMarketplaceOfferRawData } from "models";
import {
	IMarketplaceOfferLoadingSetAction,
	IMarketplaceOfferSaveAction,
	IMarketplaceOfferSetEditDataAction,
	IMarketplaceOfferUpdateAction,
	IMarketplaceOfferResetAction,
	IMarketplaceOfferSetRawDataAction,
} from "./actions";

// ENUMS
export enum EMarketplaceOfferActionTypes {
	marketplaceOfferLoadingSet = "marketplaceOffer/set/loading",
	addMarketplaceOffer = "marketplaceOffer/add",
	updateMarketplaceOffer = "marketplaceOffer/update",
	setMarketplaceOfferEditData = "marketplaceOffer/set/edit/data",
	setMarketplaceOfferRawData = "marketplaceOffer/set/raw/data",
	selectMarketplaceOffer = "marketplaceOffer/select",
	resetMarketplaceOffer = "marketplaceOffer/reset",
	getMarketplaceOfferRawData = "marketplaceOffer/get/raw/data",
	getMarketplaceOfferEditData = "marketplaceOffer/get/edit/data",
}

// INTERFACES

export interface IMarketplaceOfferEditData {
	community_post: {
		all_groups: boolean;
		description: string;
		expiration_date: string;
		good: {
			id: number;
			price: string;
			type: "Sell" | "Buy" | "Trade";
			min_price: string;
			max_price: string;
			currency: string;
			postcode: string;
		};
		id: number;
		images: any[];
		is_private: boolean;
		pin_type: number;
		title: string;
		video_link: null | string;
		website: string;
	};
	currencies: ["£", "$", "€"];
	groups: { id: number; name: string; is_selected: boolean }[];
	member_types: { id: number; name: string; is_selected: boolean }[];
	tags: {
		id: number;
		name: string;
		is_selected: boolean;
		is_user_defined: boolean;
	}[];
}

export interface IMarketplaceOfferState {
	loading: boolean;
	editData?: IMarketplaceOfferEditData;
	rawData?: IMarketplaceOfferRawData;
}

export interface IMarketplaceOfferLoadingPayload {
	loading: boolean;
}

// TYPES
export type TMarketplaceOfferActions =
	| IMarketplaceOfferLoadingSetAction
	| IMarketplaceOfferSaveAction
	| IMarketplaceOfferSetEditDataAction
	| IMarketplaceOfferResetAction
	| IMarketplaceOfferUpdateAction
	| IMarketplaceOfferSetRawDataAction;
