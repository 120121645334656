import { memo, useCallback, useMemo, useState } from "react";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";

import { MainLayout } from "layouts";
import styles from "./styles.module.scss";
import { ECommunityType, ICommunityMemberPlan, TStore } from "models";
import { PricingCard } from "..";
import { CurrenciesList } from "./components";
import { useSelectCommunityByProcess } from "utils";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.subscription.currentMemberPlan,
	(state: TStore) => state.subscription,
	(state: TStore) => state.communities.selectedCommunityToJoinInfo,
	(state: TStore) => state.auth?.credentials,
	(
		subscriptionCurrentMemberPlan,
		subscriptionState,
		selectedCommunityToJoinInfo,
		authenticatedUser,
	) => ({
		subscriptionCurrentMemberPlan,
		subscriptionState,
		selectedCommunityToJoinInfo,
		authenticatedUser,
	}),
);

interface ICommunityMemberPlans {
	memberPlans?: ICommunityMemberPlan[] | null;
	typeOfCommunity: ECommunityType;
}

const CommunityMemberPlans: React.FunctionComponent<ICommunityMemberPlans> = ({
	memberPlans,
	typeOfCommunity,
}) => {
	const stateSelector = useCallback(stateSelectorHandle, []);
	const {
		subscriptionCurrentMemberPlan,
		subscriptionState,
		selectedCommunityToJoinInfo,
		authenticatedUser,
	} = useSelector(stateSelector);
	const [selectedCurrency, setSelectedCurrency] = useState<string | null>(
		null,
	);

	const { resultedCommunity } = useSelectCommunityByProcess({
		typeOfCommunity,
	});

	const filteredMemberPlansByCurrencies = useMemo(() => {
		if (
			!selectedCurrency ||
			selectedCurrency.length === 0 ||
			!memberPlans
		) {
			return [];
		}
		return memberPlans?.filter((item) => {
			const priceOptions = item.price_options;
			if (!priceOptions || priceOptions.length === 0) {
				return false;
			} else {
				return Boolean(
					priceOptions.find((item) => {
						if (!item.synced) return false;
						return selectedCurrency.includes(item.currency);
					}),
				);
			}
		});
	}, [memberPlans, selectedCurrency]);

	const elligibleForTrial = useMemo((): boolean | undefined => {
		if (
			typeOfCommunity === ECommunityType.join &&
			selectedCommunityToJoinInfo
		) {
			return selectedCommunityToJoinInfo.eligible_for_trial;
		} else if (
			typeOfCommunity === ECommunityType.current &&
			typeof authenticatedUser?.user
		) {
			return authenticatedUser?.user?.eligible_for_trial;
		} else {
			return undefined;
		}
	}, [typeOfCommunity, selectedCommunityToJoinInfo, authenticatedUser?.user]);

	return (
		<MainLayout>
			<div className={styles.container}>
				<CurrenciesList
					setSelectedCurrency={setSelectedCurrency}
					typeOfCommunity={typeOfCommunity}
				/>

				{/* <div className={styles.paymentPlanContainer}>

					<div style={{ display: "flex", gap: "16px", alignItems: "flex-end" }}>
						<div className={styles.monthlyPaymentLabel}>
							Bill Monthly
						</div>
						<Switch />
						<div className={styles.yearlyPaymentContainer}>
							<Tag className={styles.tag}>Save up to 20%</Tag>

							<ArrowIcon className={styles.arrow} />

							<div className={styles.yearlyPaymentLabel}>
								Bill Yearly
							</div>
						</div>
					</div>
				</div> */}

				{filteredMemberPlansByCurrencies.length === 0 ? (
					<div className={styles.noDataAvailableTxt}>
						{"There are no plans available."}
					</div>
				) : (
					<div className={styles.responsiveCardsContainer}>
						{filteredMemberPlansByCurrencies?.map((item) => {
							return (
								<PricingCard
									key={item.id}
									memberPlan={item}
									selectedCurrency={selectedCurrency}
									itIsCurrentMemberPlan={
										typeOfCommunity ===
											ECommunityType.current &&
										subscriptionCurrentMemberPlan?.id ===
											item.id
											? true
											: false
									}
									communityType={typeOfCommunity}
									subscriptionCurrentMemberPlan={
										subscriptionCurrentMemberPlan
									}
									subscriptionState={subscriptionState}
									elligibleForTrial={elligibleForTrial}
									resultedCommunity={resultedCommunity}
								/>
							);
						})}
					</div>
				)}
			</div>
		</MainLayout>
	);
};

export default memo(CommunityMemberPlans);
