import React, {
	memo,
	useState,
	useCallback,
	useEffect,
	ChangeEvent,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { Input } from "antd";
import { LeftOutlined } from "@ant-design/icons";

import styles from "./styles.module.scss";
import { TStore } from "models";
import {
	appSiderMenuSetShowJoinedCommunitiesList,
	setCommunitiesJoinedSearchQuery,
} from "store";
import { useDebounce } from "utils";
import { ReactComponent as CommunityIco } from "assets/images/icons/common_icons/side_bar_community.svg";

interface IChangeCommunity {
	handleTriggerTheDrawerMenu: () => void;
}

const stateSelectorHandle = createSelector(
	(state: TStore) => state.app.siderMenu.showJoinedCommuntiesList,
	(siderMenuShowJoinedCommunitiesList) => {
		return {
			siderMenuShowJoinedCommunitiesList,
		};
	},
);

const ChangeCommunityMenu: React.FunctionComponent<IChangeCommunity> = ({
	handleTriggerTheDrawerMenu,
}) => {
	const dispatch = useDispatch();
	const stateSelector = useCallback(stateSelectorHandle, []);
	const [inputValue, setInputValue] = useState("");
	const debouncedInputValue = useDebounce(inputValue, 300);
	const { siderMenuShowJoinedCommunitiesList } = useSelector(stateSelector);

	const handleOpenChangeCommunityMenu = useCallback(() => {
		dispatch(
			appSiderMenuSetShowJoinedCommunitiesList(
				!siderMenuShowJoinedCommunitiesList,
			),
		);
		setInputValue("");
	}, [dispatch, siderMenuShowJoinedCommunitiesList]);

	const handleSearchInput = (event: ChangeEvent<HTMLInputElement>) => {
		setInputValue(event.currentTarget.value);
	};

	useEffect(() => {
		if (debouncedInputValue === "" || debouncedInputValue) {
			dispatch(
				setCommunitiesJoinedSearchQuery({ q: debouncedInputValue }),
			);
		}
	}, [debouncedInputValue, dispatch]);

	useEffect(() => {
		return () => {
			dispatch(appSiderMenuSetShowJoinedCommunitiesList(false));
		};
	}, [dispatch]);

	return (
		<div
			className={styles.container}
			style={{ zIndex: siderMenuShowJoinedCommunitiesList ? 99 : 0 }}
		>
			<div className={styles.titleContainer}>
				{process.env.REACT_APP_WHITELABEL_COMMUNITY_ID
					? null
					: !siderMenuShowJoinedCommunitiesList && (
							<div
								className={styles.icon}
								onClick={handleOpenChangeCommunityMenu}
							>
								<CommunityIco />
							</div>
					  )}
				{siderMenuShowJoinedCommunitiesList && (
					<div className={styles.flexColumnContainer}>
						<div onClick={handleOpenChangeCommunityMenu}>
							<LeftOutlined
								style={{
									marginLeft: 8,
									marginRight: 4,
									fontFamily: `"Avenir Heavy", sans-serif`,
								}}
							/>
							{"Back"}
						</div>
						<div>
							<Input
								className={styles.filterInput}
								placeholder={"Filter communities"}
								value={inputValue}
								onChange={handleSearchInput}
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default memo(ChangeCommunityMenu);
