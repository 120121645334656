import React, { ReactNode, useState, useCallback, useMemo } from "react";
import {
	// StripeCardElementChangeEvent,
	StripeCardElementOptions,
	StripeCardNumberElement,
	StripeCardElement,
	StripeError,
	StripeCardNumberElementChangeEvent,
	StripeCardExpiryElementChangeEvent,
	StripeCardCvcElementChangeEvent,
} from "@stripe/stripe-js";
import {
	useStripe,
	useElements,
	CardNumberElement,
	CardExpiryElement,
	CardCvcElement,
} from "@stripe/react-stripe-js";

import styles from "./styles.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
	changeTheMemberSubscriptionToPendingAction,
	completePaymentProcessSagaAction,
	notify,
	paymentsCreateMemberSubscription,
} from "store";
import { createSelector } from "reselect";
import {
	ECommunityType,
	ESubscriptionStatus,
	ICommunityMemberPlan,
	TStore,
} from "models";
import { rollbar, useMemberPlanPrice, useSelectCommunityByProcess } from "utils";
import { IsProcessingModal } from "components";
import CustomButton from "components/Button";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.payments.memberSubscription,
	(state: TStore) => state.auth?.credentials?.user,
	(state: TStore) => state.communities?.selectedCommunityToJoinInfo,
	(state: TStore) => state.subscription,
	(
		paymentsMemberSubscription,
		authenticatedUser,
		selectedCommunityToJoinInfo,
		subscription,
	) => ({
		paymentsMemberSubscription,
		authenticatedUser,
		selectedCommunityToJoinInfo,
		subscription,
	}),
);

const CardField = ({
	onChange,
	typeOfCommunity,
}: {
	onChange: (
		event:
			| StripeCardNumberElementChangeEvent
			| StripeCardExpiryElementChangeEvent
			| StripeCardCvcElementChangeEvent,
	) => any;
	typeOfCommunity: ECommunityType;
}) => {
	const headerBackgroundColor = getComputedStyle(
		document.body,
	).getPropertyValue("--selectedCommunityToJoinHeaderBackground");

	const CARD_OPTIONS: StripeCardElementOptions = useMemo(() => {
		return {
			hidePostalCode: true,
			iconStyle: "solid",
			style: {
				base: {
					iconColor: headerBackgroundColor
						? headerBackgroundColor
						: "#009bb9",
					color: headerBackgroundColor
						? headerBackgroundColor
						: "#009bb9",

					fontWeight: 400,
					fontFamily: "Nunito-Regular",
					fontSize: "14px",
					fontSmoothing: "antialiased",
					":-webkit-autofill": {
						color: headerBackgroundColor
							? headerBackgroundColor
							: "#009bb9",
					},
					"::placeholder": {
						color:
							// headerBackgroundColor
							// 	? headerBackgroundColor
							// 	:
							"#9CA1BA",
					},
				},
				invalid: {
					iconColor: headerBackgroundColor
						? headerBackgroundColor
						: "#ffc7ee",
					color: headerBackgroundColor
						? headerBackgroundColor
						: "#ffc7ee",
				},
			},
		};
	}, [headerBackgroundColor]);

	return (
		<div className={styles.formRow}>
			{/* <CardElement
				className={classNames(
					styles.stripeElement,
					styles.stripeElementWebkitAutofill,
				)}
				options={CARD_OPTIONS}
				onChange={e => console.log({e},'this is full')}
			/> */}
			<div className={styles.stripInput}>
				<CardNumberElement
					onChange={onChange}
					options={{
						placeholder: "Card number",
						showIcon: true,
						style: { base: CARD_OPTIONS.style?.base },
					}}
				/>
			</div>
			<div style={{ display: "flex", gap: "16px" }}>
				<div style={{ width: "30%" }} className={styles.stripInput}>
					<CardExpiryElement
						onChange={onChange}
						options={{ style: { base: CARD_OPTIONS.style?.base } }}
					/>
				</div>
				<div style={{ width: "30%" }} className={styles.stripInput}>
					<CardCvcElement
						onChange={onChange}
						options={{
							placeholder: "Secure code",
							style: { base: CARD_OPTIONS.style?.base },
						}}
					/>
				</div>
			</div>
			{/* <Input placeholder="Name on card" style={{padding:'8px 12px'}}/> */}
		</div>
	);
};

const ErrorMessage = ({ children }: { children: ReactNode }) => {
	return (
		<div className={styles.errorMessage} role={"alert"}>
			{children}
		</div>
	);
};

interface ISripeCheckoutForm {
	typeOfCommunity: ECommunityType;
	price?: string;
}

const StripeCheckoutForm: React.FunctionComponent<ISripeCheckoutForm> = ({
	typeOfCommunity,
	price,
}) => {
	const stripe = useStripe();
	const elements = useElements();
	const [error, setError] = useState<
		| { type: "validation_error"; code: string; message: string }
		| null
		| StripeError
		| undefined
	>(null);
	const [cardComplete, setCardComplete] = useState({
		cardNumber: false,
		cvc: false,
		expiryDate: false,
	});

	const [backendProcessing, setBackendProcessing] = useState(false);
	const [showBackendErrMsg, setShowBackendErrMsg] = useState(false);
	const dispatch = useDispatch();
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { paymentsMemberSubscription, authenticatedUser, subscription } =
		useSelector(stateSelector);

	const { resultedCommunity } = useSelectCommunityByProcess({
		typeOfCommunity,
	});
	const memberPlanPrice = useMemberPlanPrice({ typeOfCommunity });

	const handleSubmit = async (event: unknown) => {
		(event as Event).preventDefault();
		if (
			typeOfCommunity === ECommunityType.join ||
			subscription?.currentMemberPlan?.free
		) {
			if (
				paymentsMemberSubscription?.payload?.subscription_status ===
					ESubscriptionStatus.inactive ||
				paymentsMemberSubscription?.payload?.subscription_status ===
					ESubscriptionStatus.incomplete
			) {
				let subscriptionData: {
					clientSecret?: string;
					subscriptionId?: number;
					selectedMemberPlan?: Partial<ICommunityMemberPlan>;
				} = {
					clientSecret: undefined,
					subscriptionId: undefined,
					selectedMemberPlan: undefined,
				};

				if (
					paymentsMemberSubscription?.payload?.client_secret &&
					paymentsMemberSubscription?.payload?.subscription_id &&
					resultedCommunity?.selected_member_plan
				) {
					subscriptionData = {
						clientSecret:
							paymentsMemberSubscription.payload.client_secret,
						subscriptionId:
							paymentsMemberSubscription.payload.subscription_id,
						selectedMemberPlan:
							resultedCommunity.selected_member_plan,
					};
				}

				if (
					!stripe ||
					!elements ||
					!subscriptionData?.clientSecret ||
					!authenticatedUser ||
					!resultedCommunity?.id ||
					!subscriptionData?.subscriptionId ||
					!(
						cardComplete.cardNumber &&
						cardComplete.cvc &&
						cardComplete.expiryDate
					) ||
					!subscriptionData.selectedMemberPlan
				) {
					// Stripe.js has not loaded yet. Make sure to disable
					// form submission until Stripe.js has loaded.
					notify("error", "Something went wrong.", "Payment issue");
					return;
				}

				if (error) {
					elements.getElement("card")?.focus();
					return;
				}
				try {
					setBackendProcessing(true);
					//  Before the transaction is started, update the subscription's status from inactive into pending.
					dispatch(
						changeTheMemberSubscriptionToPendingAction({
							communityId: resultedCommunity?.id,
							subscriptionId: subscriptionData.subscriptionId,
							onErrorCallback: () => {
								notify(
									"error",
									"Something went wrong.",
									"Please try again later.",
								);

								throw Error(
									"The subscription could not be changed in pending state.",
								);
							},
						}),
					);

					const payload = await stripe.confirmCardPayment(
						subscriptionData.clientSecret,
						{
							payment_method: {
								billing_details: {
									name: `${authenticatedUser.first_name} ${authenticatedUser.last_name}`,
									email: authenticatedUser.email,
								},
								card: elements.getElement(CardNumberElement) as
									| StripeCardElement
									| StripeCardNumberElement
									| { token: string },
							},
						},
					);

					if (payload.error) {
						// If the transaction failed, update the subscription's status from pending back to inactive.
						let paymentsCreateMemberSubscriptionPayload: {
							communityId: number;
							stripePlanPriceId: string;
						} = {
							communityId: resultedCommunity.id,
							stripePlanPriceId: "",
						};
						if (memberPlanPrice?.stripe_id) {
							paymentsCreateMemberSubscriptionPayload.stripePlanPriceId =
								memberPlanPrice.stripe_id;
						}
						if (
							paymentsCreateMemberSubscriptionPayload.stripePlanPriceId !==
							""
						) {
							dispatch(
								paymentsCreateMemberSubscription({
									...paymentsCreateMemberSubscriptionPayload,
									onErrorCallback: () => {
										throw Error(
											"The subscription could not be activated.",
										);
									},
								}),
							);
						} else {
							notify(
								"error",
								"Something went wrong.",
								"Payload error.",
							);
						}
						setError(payload.error);
						if (payload.error.message) {
							alert(payload.error.message);
						} else {
							alert("Payment failed.");
						}
						setBackendProcessing(false);
					} else {
						dispatch(
							completePaymentProcessSagaAction({
								onErrorCallback: () => {
									setShowBackendErrMsg(true);
								},
								onSuccessCallback: () => {
									setBackendProcessing(false);
								},
								typeOfCommunity,
							}),
						);
					}
				} catch (error) {
					notify(
						"error",
						"Something went wrong.",
						"Please try again later.",
					);
					setBackendProcessing(false);
					console.error(error);
					rollbar.error(error)
				}
			}
			// } else {
			// 	notify(
			// 		"error",
			// 		"Something went wrong.",
			// 		"You are not joining this community",
			// 	);
			// }
		}
	};

	const SubmitButton = ({
		processing,
		error,
		children,
		disabled,
	}: {
		processing: boolean;
		error:
			| { type: "validation_error"; code: string; message: string }
			| StripeError
			| null
			| undefined;
		children: ReactNode;
		disabled: boolean;
	}) => {
		return (
			<CustomButton
				// className={classNames(styles.submitButton, {
				// 	[styles.submitButtonError]: error,
				// })}
				varient="solid"
				onClick={handleSubmit}
				// type={"submit"}
				disabled={processing || disabled}
			>
				{children}
			</CustomButton>
		);
	};

	return (
		<>
			<form className={styles.form} onSubmit={handleSubmit}>
				{/* <fieldset className={styles.formGroup}></fieldset> */}
				<fieldset className={styles.formGroup}>
					<CardField
						onChange={(e) => {
							setError(e.error);
							if (e.elementType === "cardCvc") {
								setCardComplete((prevState) => ({
									...prevState,
									cvc: e.complete,
								}));
							} else if (e.elementType === "cardNumber") {
								setCardComplete((prevState) => ({
									...prevState,
									cardNumber: e.complete,
								}));
							} else if (e.elementType === "cardExpiry") {
								setCardComplete((prevState) => ({
									...prevState,
									expiryDate: e.complete,
								}));
							}
						}}
						typeOfCommunity={typeOfCommunity}
					/>
				</fieldset>
				{/* <fieldset className={styles.checkbox}>
					<Checkbox onChange={() => {}}>
						{"Save the card for the future payments."}
					</Checkbox>
				</fieldset> */}
				{error && error.message && (
					<ErrorMessage>{error.message}</ErrorMessage>
				)}
				<SubmitButton
					processing={backendProcessing}
					error={error}
					disabled={!stripe || backendProcessing}
				>
					{paymentsMemberSubscription?.setup_intent_client_secret &&
					paymentsMemberSubscription?.payload?.client_secret === null
						? "Add the payment method"
						: `Pay ${price}`}
				</SubmitButton>
			</form>
			<IsProcessingModal
				showErrorMessage={showBackendErrMsg}
				visible={backendProcessing}
			/>
		</>
	);
};

export default StripeCheckoutForm;
