import React, { memo } from "react";
import { Navigate } from "react-router-dom";

import { isItAWhiteLabel } from "utils";
import PrivateRoute from "./private";

interface IWhiteLabelRoute {
	children: any;
}

const WhiteLabelRoute: React.FunctionComponent<IWhiteLabelRoute> = ({
	children,
}) => {
	if (!isItAWhiteLabel()) {
		return (
			<Navigate
				to={{
					pathname: "/dashboard",
				}}
			/>
		);
	} else {
		return <PrivateRoute>{children}</PrivateRoute>;
	}
};

export default memo(WhiteLabelRoute);
