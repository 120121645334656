/* eslint-disable array-callback-return */
import React, { memo, Children, useMemo, useCallback } from "react";

import styles from "./styles.module.scss";
import { TStore } from "models";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import moment from "moment";
const stateSelectorHandle = createSelector(
	(state: TStore) => state.events,
	(events) => ({
		events
	}))
const DateCellWrapper: React.FunctionComponent<any> = (props) => {
	const { children, value } = props;

	const stateSelector = useCallback(stateSelectorHandle, []);
	const { events } =
		useSelector(stateSelector);
	const parsedEvents = useMemo(() => {
		const eventsData = events.data;

		if (!eventsData || eventsData.length === 0) return [];
		return eventsData.map((item) => {
			const { id, title, starts_at, ends_at, all_day, location, event_tag_list } = item;
			return {
				id,
				title,
				start: new Date(starts_at),
				end: new Date(ends_at),
				allDay: all_day,
				location,
				event_tag_list
			};
		});
	}, [events]);

	const isWeekendDay =
		new Date(value).getDay() === 6 || new Date(value).getDay() === 0;

	const eventList = parsedEvents.filter((event) => {
		const startDate = moment(event?.start);
		const endDate = moment(event?.end);
		const targetDate = moment(value);

		if (startDate.isSameOrBefore(targetDate, 'day') && endDate.isSameOrAfter(targetDate, 'day')) {
			return true;
		}

		return false;
	});

	return React.cloneElement(Children.only(children), {
		className: eventList.length > 0 ? styles.cellContainerSelected : styles.cellContainer,
		style: {
			...children.style,
			backgroundColor: isWeekendDay ? "#f6e9e9" : "#f7f6f3",
		},
	});
};

export default memo(DateCellWrapper);
