import emptyState from "assets/images/empty-state.png";
import CustomButton from "components/Button";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";

const CommunityEmptyState = () => {
	const navigate = useNavigate()
	return (
		<div className={styles.emptyCommunityContainer}>
			<img src={emptyState} alt="no communities joined" />

			<div className={styles.titleContainer}>
				<h3 className={styles.title}>You don't have communities yet</h3>

				<p className={styles.description}>
					You haven't joined the community yet, but you can do it,
					<br/> go
					to all communities and pick one
				</p>
			</div>

			<div className={styles.buttonContainer}>
				<CustomButton varient="outline" onClick={() => navigate('/communities/invites')}>Invites</CustomButton>
				<CustomButton varient="solid" onClick={() => navigate('/communities/all') }>All communitites</CustomButton>
			</div>
		</div>
	);
};

export default CommunityEmptyState;
