import { IViewCountsState, EViewCountsTypes, TViewCountsActions } from "models";

const initial: IViewCountsState = {
	posts: [],
	videos: [],
	docs: [],
	offers: [],
};

export function viewCountsReducer(
	state = initial,
	action: TViewCountsActions,
): IViewCountsState {
	switch (action.type) {
		case EViewCountsTypes.addPost: {
			return {
				...state,
				posts: [...state.posts].concat(action.payload),
			};
		}
		case EViewCountsTypes.addVideo: {
			return {
				...state,
				videos: [...state.videos].concat(action.payload),
			};
		}
		case EViewCountsTypes.addDoc: {
			return {
				...state,
				docs: [...state.docs].concat(action.payload),
			};
		}
		case EViewCountsTypes.addOffer: {
			return {
				...state,
				offers: [...state.offers].concat(action.payload),
			};
		}
		default:
			return state;
	}
}
