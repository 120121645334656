import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from "history";
import { createRouterMiddleware } from "@lagunovsky/redux-react-router";

// import * as firebase from "firebase";
// import "@firebase/firestore"; // 👈 If you're using firestore
// import ReduxSagaFirebase from "redux-saga-firebase";
import storage from "redux-persist/lib/storage";
import { persistStore, createTransform, persistReducer } from "redux-persist";

import sagas from "./sagas";
import { rootReducer } from "./reducers";

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const SetTransform = createTransform(
	// transform state on its way to being serialized and persisted.
	(inboundState: object, key) => {
		// convert mySet to an Array.
		return { ...inboundState };
	},
	// transform state being rehydrated
	(outboundState, key) => {
		return { ...outboundState };
	},
	// define which reducers this transform gets called for.
	{ whitelist: ["auth"] },
);

const persistConfig = {
	key: "auth",
	storage,
	whitelist: ["credentials"],
	transforms: [SetTransform],
};

// const myFirebaseApp = firebase.initializeApp({
//   apiKey: "AIzaSyDicbkp_6B1qqR0liVg0UkzS-zYV_HUcHU",
//   authDomain: "ugeniedev.firebaseapp.com",
//   databaseURL: "https://ugeniedev.firebaseio.com",
//   projectId: "ugeniedev",
//   storageBucket: "ugeniedev.appspot.com",
//   messagingSenderId: "897566672351",
//   appId: "1:897566672351:web:f94498667395f06cf5f06a",
//   measurementId: "G-36Z7HPZ2WE",
// });

// export const reduxSagaFirebase = new ReduxSagaFirebase(myFirebaseApp);

export default function configureStore() {
	const middlewares = [sagaMiddleware, createRouterMiddleware(history)];
	const persistedReducer = persistReducer(
		persistConfig,
		rootReducer(history),
	);
	const store = createStore(
		persistedReducer,
		composeWithDevTools(applyMiddleware(...middlewares)),
	);
	sagaMiddleware.run(sagas);
	let persistor = persistStore(store);

	return { store, persistor };
}

export * from "./actions";
export * from "./services";
