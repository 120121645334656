import { IEventsState, EEventsActionTypes, TEventsActions } from "models";

const initial: IEventsState = {
	loading: false,
	data: [],
	selectedCalendarDate: undefined,
	meta: {
		scope_group: null,
	},
};

export function eventsReducer(
	state = initial,
	action: TEventsActions,
): IEventsState {
	switch (action.type) {
		case EEventsActionTypes.eventsLoadingSet: {
			return {
				...state,
				loading: action.payload,
			};
		}
		case EEventsActionTypes.setEvents: {
			return {
				...state,
				data: action.payload.events,
				meta: action.payload.meta,
			};
		}
		case EEventsActionTypes.setCalendarDate: {
			return {
				...state,
				selectedCalendarDate: action.payload.date,
			};
		}
		default:
			return state;
	}
}
