import { whiteLabelConfig } from "config";
import { axiosInstance } from "../";

export const auth = {
    authenticateByToken: (payload: any) => {
        return axiosInstance().get("/sessions", {
            headers: {
                Authorization: payload,
            },
        });
    },
    login: (payload: any) =>
        axiosInstance().post("/sessions", {
            ...payload,
        }),
    checkToken: () => axiosInstance().post("/auth/checkToken"),
    logout: (payload: { userId: number }) =>
        axiosInstance().delete("/sessions/" + payload.userId),
    signUp: (payload: any) =>
        axiosInstance().post("/users", {
            ...payload,
        }),
    getInvite: (payload: string) =>
        axiosInstance().get("/community_invites/" + payload),
    recover: (email: string) =>
        axiosInstance().post("/users/forgot-password", {
            email,
            desktop: true,
            white_label_id: whiteLabelConfig?.community?.id,
        }),
    resetPassword: (password: string, token: string) =>
        axiosInstance().patch(
            "/users/update-password",
            {
                password,
            },
            {
                headers: {
                    Authorization: token,
                },
            },
        ),
    deleteAccount: (userId: number) =>
        axiosInstance().delete(`/users/${userId}`),
    communityPreviews: (payload: { token: string }) =>
        axiosInstance().get(`/community_previews/${payload.token}`),
    checkAccessToken: (payload: { userId: string }) =>
        axiosInstance().get(`/users/${payload.userId}/check-access-token`),
    signUpWithSSO: (payload: {
        encrypted_data: string;
        encrypted_iv: string
        community_id: string
    }) =>
        axiosInstance().post(`/sessions/sso_login`, {
            encrypted_data: payload.encrypted_data,
            encrypted_iv: payload.encrypted_iv,
            community_id: payload.community_id
        }, {
        }),
};
