import {
	EOffersActionTypes,
	IOffersGetPayload,
	IOffersUpdatedData,
} from "models";

export const setOffersLoading = (payload: boolean) => ({
	type: EOffersActionTypes.offersLoadingSet,
	payload,
});

export const getOffers = (payload?: IOffersGetPayload) => ({
	type: EOffersActionTypes.getOffers,
	payload,
});

export const setOffers = (payload: any) => ({
	type: EOffersActionTypes.setOffers,
	payload,
});

export const setOffersFilterTagsAction = (payload: any) => ({
	type: EOffersActionTypes.setFilterTags,
	payload,
});

export const setOffersFilterTagsInStoreAction = (payload: any) => ({
	type: EOffersActionTypes.setFilterTagsInStore,
	payload,
});

export const setOffersQueryAndFilterAction = (payload: any) => ({
	type: EOffersActionTypes.setQueryAndFilter,
	payload,
});

export const setOffersQueryAction = (payload: any) => ({
	type: EOffersActionTypes.setOffersQuery,
	payload,
});
export const incrementRedeemByEmailAction = (payload: IOffersUpdatedData) => ({
	type: EOffersActionTypes.incrementRedeemByEmailCounter,
	payload,
});
export const incrementRedeemByLinkAction = (payload: IOffersUpdatedData) => ({
	type: EOffersActionTypes.incrementRedeemByLinkCounter,
	payload,
});
export const incrementWebsiteViewsAction = (payload: IOffersUpdatedData) => ({
	type: EOffersActionTypes.incrementWebsiteViews,
	payload,
});
