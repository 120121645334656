import {
	useMemo,
	useState,
	useEffect,
	useCallback,
	Dispatch,
	SetStateAction,
} from "react";
import {
	useStripe,
	PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";

import styles from "./styles.module.scss";
import {
	changeTheMemberSubscriptionToPendingAction,
	completePaymentProcessSagaAction,
	notify,
	paymentsCreateMemberSubscription,
} from "store";
import { ECommunityType, ISelectedCommunityToJoin, TStore } from "models";
import { useMemberPlanPrice, useSelectCommunityByProcess } from "utils";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.payments.memberSubscription,
	(memberSubscription) => ({
		memberSubscription,
	}),
);

const useOptions = (paymentRequest: PaymentRequest) => {
	const options = useMemo(
		() => ({
			paymentRequest,
			style: {
				paymentRequestButton: {
					theme: "dark",
					height: "40px",
					type: "default",
					// One of 'default', 'book', 'buy', or 'donate'
					// Defaults to 'default'
				},
			},
		}),
		[paymentRequest],
	);

	return options;
};

const usePaymentRequest = ({
	typeOfCommunity,
	resultedCommunity,
	// options,
	onPaymentMethod,
}: {
	typeOfCommunity: ECommunityType;
	resultedCommunity: ISelectedCommunityToJoin | null;
	onPaymentMethod: Function;
}) => {
	//** The payment request object allows us to configure what the customer is going to see in the payment flow. */
	const stripe = useStripe();
	const [paymentRequest, setPaymentRequest] = useState<any>(null);
	const [canMakePayment, setCanMakePayment] = useState<null | boolean>(null);
	const [wasPaymentRequestCreated, setWasPaymentRequestCreated] =
		useState(false);

	const selectedMemberPlanPriceOption = useMemberPlanPrice({
		typeOfCommunity,
	});

	useEffect(() => {
		if (!resultedCommunity || !selectedMemberPlanPriceOption) return;
		const { selected_member_plan } = resultedCommunity;
		if (!selected_member_plan?.display_name) return;

		if (!selectedMemberPlanPriceOption) return;
		const options = {
			country: "GB",
			currency: selectedMemberPlanPriceOption.currency.toLowerCase(),
			requestPayerName: true,
			requestPayerEmail: true,
			total: {
				label: selected_member_plan.display_name,
				amount:
					parseInt(selectedMemberPlanPriceOption.amount, 10) * 100, // 1/100 is the ration between currency and the indivisible part of the currency. For example pound/penny
			},
		};

		if (stripe && paymentRequest === null) {
			const pr: any = stripe.paymentRequest(options);
			setPaymentRequest(pr);
			setWasPaymentRequestCreated(true);
		}
	}, [
		stripe,
		paymentRequest,
		resultedCommunity,
		selectedMemberPlanPriceOption,
	]);

	useEffect(() => {
		let subscribed = true;
		if (paymentRequest) {
			// Check the availability of the Payment Request API.
			paymentRequest
				.canMakePayment()
				.then((res: any) => {
					if (res && subscribed) {
						setCanMakePayment(true);
					} else {
						setCanMakePayment(false);
					}
				})
				.catch((err: any) => {
					console.error(err);
					setCanMakePayment(false);
				});
		}

		return () => {
			subscribed = false;
		};
	}, [paymentRequest]);

	useEffect(() => {
		if (paymentRequest) {
			paymentRequest.on("paymentmethod", onPaymentMethod);
		}
		return () => {
			if (paymentRequest) {
				paymentRequest.off("paymentmethod", onPaymentMethod);
			}
		};
	}, [paymentRequest, onPaymentMethod]);

	return {
		paymentRequest: canMakePayment ? paymentRequest : null,
		paymentRequestIsFinished:
			wasPaymentRequestCreated && canMakePayment !== null ? true : false,
	};
};

interface IPaymentRequestForm {
	finishPaymentRequestFormLoading: () => void;
	setBackendProcessing: Dispatch<SetStateAction<boolean>>;
	setShowBackendErrMsg: Dispatch<SetStateAction<boolean>>;
	typeOfCommunity: ECommunityType;
}

const PaymentRequestForm: React.FunctionComponent<IPaymentRequestForm> = ({
	finishPaymentRequestFormLoading,
	setBackendProcessing,
	setShowBackendErrMsg,
	typeOfCommunity,
}) => {
	const stripe = useStripe();
	const dispatch = useDispatch();
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { memberSubscription } = useSelector(stateSelector);
	const { resultedCommunity } = useSelectCommunityByProcess({
		typeOfCommunity,
	});

	const memberPlanPrice = useMemberPlanPrice({ typeOfCommunity });

	const { paymentRequest, paymentRequestIsFinished } = usePaymentRequest({
		typeOfCommunity,
		resultedCommunity,
		// @ts-ignore
		onPaymentMethod: async ({ complete, paymentMethod, ...data }) => {
			try {
				if (
					!stripe ||
					!resultedCommunity ||
					!memberSubscription?.payload?.client_secret ||
					!memberSubscription?.payload?.subscription_id ||
					!resultedCommunity.selected_member_plan ||
					!memberPlanPrice?.stripe_id
				) {
					notify(
						"error",
						"Something went wrong.",
						"Please try again later.",
					);
					return;
				}

				//  Before the transaction is started, update the subscription's status from inactive into pending.
				dispatch(
					changeTheMemberSubscriptionToPendingAction({
						communityId: resultedCommunity?.id,
						subscriptionId:
							memberSubscription.payload.subscription_id!,
						onErrorCallback: () => {
							throw Error(
								"The subscription could not be changed in pending state.",
							);
						},
					}),
				);

				const { error } = await stripe.confirmCardPayment(
					memberSubscription.payload.client_secret!,
					{
						payment_method: paymentMethod.id,
					},
					// { handleActions: false },
				);

				if (error) {
					// If the transaction failed, update the subscription's status from pending back to inactive.
					dispatch(
						paymentsCreateMemberSubscription({
							communityId: resultedCommunity.id,
							stripePlanPriceId: memberPlanPrice.stripe_id,
							onErrorCallback: () => {
								throw Error(
									"The subscription could not be activated.",
								);
							},
						}),
					);
					complete("fail");
				} else {
					complete("success");
					setBackendProcessing(true);
					dispatch(
						completePaymentProcessSagaAction({
							onErrorCallback: () => {
								setShowBackendErrMsg(true);
							},
							onSuccessCallback: () => {
								setBackendProcessing(true);
							},
							typeOfCommunity,
						}),
					);
				}
			} catch (error) {
				console.error(error);
			}
		},
	});
	const options = useOptions(paymentRequest);

	useEffect(() => {
		if (stripe && paymentRequestIsFinished) {
			finishPaymentRequestFormLoading();
		}
	}, [
		stripe,
		paymentRequest,
		paymentRequestIsFinished,
		finishPaymentRequestFormLoading,
	]);

	if (!paymentRequestIsFinished || paymentRequest === null) {
		return null;
	} else {
		return (
			<div className={styles.btnContainer}>
				<div className={styles.btnLabel}>
					{"Pay using a card saved in your browser."}
				</div>
				<PaymentRequestButtonElement
					className={styles.paymentRequestButton}
					// @ts-ignore
					options={options}
				/>
			</div>
		);
	}
};

export default PaymentRequestForm;
