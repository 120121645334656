import React, { memo, useCallback, useState, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Menu, Dropdown, Avatar } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import ClassNames from "classnames";
import { Modal } from "antd";

import { ReactComponent as X } from "assets/images/x.svg";
import styles from "./styles.module.scss";
import { TStore } from "models";
import { deleteAccount, leaveCommunity, notify } from "store";
import { Search, NotificationsMenu, LogoutMenuItem } from "./components";
import { GetImg, settingSelectedCommunityToJoinColors } from "utils";
import { ReactComponent as Message } from "assets/images/memberMessage.svg";
import { ReactComponent as DownArrow } from "assets/images/downArrow-navBar.svg";
import { ReactComponent as HamBurgerMenu } from "assets/images/hamburgerMenu.svg";
import { CollapseType } from "antd/lib/layout/Sider";
import { isItAWhiteLabel } from "utils";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.app.messagesCounter,
	(state: TStore) => state.app.siderMenu.collapsed,
	(state: TStore) => state.auth.credentials?.user,
	(state: TStore) => state.community,
	(state: TStore) => state.subscription.subscription,
	(state: TStore) => state.subscription.currentMemberPlan,
	(
		messagesCounter: number,
		siderMenuCollapsed,
		user,
		community,
		memberSubscription,
		memberPlan,
	) => {
		return {
			messagesCounter,
			siderMenuCollapsed,
			user,
			community,
			memberSubscription,
			memberPlan,
		};
	},
);

interface IAppBar {
	handleTriggerTheDrawerMenu: (
		collapsed: boolean,
		type: CollapseType,
	) => void;
}

const AppBar: React.FunctionComponent<IAppBar> = ({
	handleTriggerTheDrawerMenu,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [showFullSearchbox, setShowFullSearchbox] = useState<boolean>(false);
	const [isDeleteModalVisible, setIsDeleteModalVisible] =
		useState<boolean>(false);
	const stateSelector = useCallback(stateSelectorHandle, []);
	const {
		messagesCounter,
		siderMenuCollapsed,
		user,
		community,
		memberSubscription,
		memberPlan,
	} = useSelector(stateSelector);
	const [leaveCommunityLoading, setLeaveCommunityLoading] =
		useState<boolean>(false);
	const [confirmModalVisible, setConfirmModalVisible] =
		useState<boolean>(false);
	const currentPlan = memberSubscription
		? memberSubscription.plan
		: memberPlan;
	const handleDeleteAccount = useCallback(() => {
		setIsDeleteModalVisible(!isDeleteModalVisible);
		dispatch(deleteAccount());
	}, [dispatch, isDeleteModalVisible]);

	const leaveLabelsAndTexts: {
		btnLabel: string;
		modalTitle: string;
		modalContent: string | JSX.Element | null;
	} = useMemo(() => {
		const resultedObject = {
			btnLabel: "Leave",
			modalTitle: "Are you sure you want to leave?",
			modalContent: (
				<>
					If you leave the community, this will also cancel your
					membership.
					<br />
					Are you sure you want to leave the community?
				</>
			),
		};
		if (!currentPlan) {
			return resultedObject;
		}
		if (memberSubscription && !currentPlan.free) {
			return {
				...resultedObject,
				modalContent: (
					<>
						Your subscription will end on{" "}
						<span style={{ fontWeight: "bold" }}>
							{memberSubscription.expiry_date}
						</span>
						.
						<br />
						If you leave the community, this will also cancel your
						membership but you will not receive a refund.
						<br /> Are you sure you want to leave the community?
					</>
				),
			};
		} else {
			return resultedObject;
		}
	}, [currentPlan, memberSubscription]);

	const handleCancelDeleteAccount = useCallback(() => {
		setIsDeleteModalVisible(!isDeleteModalVisible);
	}, [isDeleteModalVisible]);

	const handleLeave = useCallback(() => {
		// * Reset the modal's status and title.
		if (!community?.data) {
			notify("error", "An error occurred. Please try again later.");
			return;
		}
		setLeaveCommunityLoading(true);
		dispatch(
			leaveCommunity({
				communityId: community.data.id,
				onSuccessCallback: () => {
					settingSelectedCommunityToJoinColors();
					if (isItAWhiteLabel()) {
						navigate("/auth/sign-out");
					} else {
						navigate("/communities");
					}
				},
				onErrorCallback: () => {
					notify(
						"error",
						"An error occurred. Please try again later.",
					);
					setLeaveCommunityLoading(false);
				},
			}),
		);
	}, [community.data, dispatch, navigate]);

	const toggleLeaveCommunityModal = useCallback(() => {
		setConfirmModalVisible(!confirmModalVisible);
	}, [confirmModalVisible]);

	const menu = useMemo(
		() => (
			<Menu
				style={{
					fontFamily: `"Avenir Light", sans-serif`,
					borderRadius: "8px",
				}}
			>
				<Menu.Item>
					<Link className={styles.userIcon} to="/profile">
						{"Edit profile"}
					</Link>
				</Menu.Item>
				<Menu.Item>
					<Link
						className={styles.userIcon}
						to="/profile/account-settings"
					>
						{"Account Settings"}
					</Link>
				</Menu.Item>
				<Menu.Item>
					<a
						href="mailto:info@ugenie.io?subject=Greetings!"
						rel="noreferrer"
						target="_blank"
					>
						{"Contact Tech Support"}
					</a>
				</Menu.Item>
				{community?.data?.uses_stripe && !user?.is_a_manager ? (
					<Menu.Item>
						<Link to="/subscription-management">
							{"Subscription Management"}
						</Link>
					</Menu.Item>
				) : null}
				<Menu.Item>
					<Link to="/profile/manage-notifications">
						{"Manage Notifications"}
					</Link>
				</Menu.Item>
				{!isItAWhiteLabel() && (
					<Menu.Item>
						<div onClick={toggleLeaveCommunityModal}>{"Leave"}</div>
					</Menu.Item>
				)}
				<Menu.Item>
					<LogoutMenuItem />
				</Menu.Item>
				{process.env.REACT_APP_WHITELABEL_LOGIN_PAGE &&
				process.env.REACT_APP_SSO ? null : (
					<Menu.Item
						className={styles.btnDanger}
						onClick={() => setIsDeleteModalVisible(true)}
					>
						{"Delete Account"}
					</Menu.Item>
				)}
			</Menu>
		),
		[
			community?.data?.uses_stripe,
			user?.is_a_manager,
			toggleLeaveCommunityModal,
		],
	);

	return (
		<>
			<div className={styles.AppBar}>
				{siderMenuCollapsed && (
					<div className={styles.menuUnfoldIconContainer}>
						<HamBurgerMenu
							style={{ fontSize: 24 }}
							onClick={() =>
								handleTriggerTheDrawerMenu(false, "responsive")
							}
						/>
					</div>
				)}
				<div className={styles.appContainer}>
					<div className={styles.selectAndInputContainer}>
						<Search
							setShowFullSearchbox={setShowFullSearchbox}
							showFullSearchBox={showFullSearchbox}
						/>
					</div>

					<div className={styles.rightSide}>
						<Link
							className={ClassNames(
								styles.messagesBtn,
								styles.accents,
							)}
							to="/messages"
						>
							{messagesCounter > 0 && (
								<sup>
									{messagesCounter < 10
										? messagesCounter
										: "9+"}
								</sup>
							)}
							<Message />
							<p className={styles.message}>Message</p>
						</Link>
						<NotificationsMenu />
						<Dropdown
							className={styles.userIcon}
							overlay={menu}
							placement="bottomRight"
						>
							<div>
								<Avatar
									size={30}
									src={<GetImg endpoint={user?.picture} />}
								/>
								<p className={styles.userName}>{user?.name}</p>
								<DownArrow height={"16px"} width={"16px"} />
							</div>
						</Dropdown>
					</div>
				</div>
			</div>
			{!showFullSearchbox && (
				<>
					<Modal
						title={"Are you sure you want to delete your account?"}
						visible={isDeleteModalVisible}
						onOk={handleDeleteAccount}
						okType={"danger"}
						centered
						closable={false}
						cancelButtonProps={{ id: "btn-cancel-delete-account" }}
						okButtonProps={{ id: "btn-ok-leave-group" }}
						okText={"Yes, I am sure"}
						onCancel={handleCancelDeleteAccount}
						className={"modalContainer"}
						getContainer={() =>
							document.getElementById("content") as HTMLElement
						}
					>
						<div className={styles.modalContent}>
							<>
								<div>
									{
										"Deleting your account is permanent and will remove all content including comments, avatars and profile settings."
									}
								</div>
								<div>
									{
										"Are you sure you want to delete your account?"
									}
								</div>
							</>
						</div>
						<div
							onClick={handleCancelDeleteAccount}
							className={"closeModal"}
						>
							<X fill="#FFFFFF" width={13} height={13} />
						</div>
					</Modal>
					<Modal
						title={leaveLabelsAndTexts.modalTitle} //{"Are you sure you want to leave this community?"}
						visible={confirmModalVisible}
						onOk={handleLeave}
						okType={"danger"}
						centered
						closable={false}
						cancelText={"Cancel"}
						cancelButtonProps={{ id: "btn-cancel-delete-account" }}
						okButtonProps={{ id: "btn-ok-leave-group" }}
						okText={"Yes, I am sure"}
						onCancel={toggleLeaveCommunityModal}
						className={"modalContainer"}
						confirmLoading={leaveCommunityLoading}
						getContainer={() =>
							document.getElementById("content") as HTMLElement
						}
					>
						<div className={styles.modalContent}>
							{leaveLabelsAndTexts.modalContent}
						</div>
						<div
							onClick={toggleLeaveCommunityModal}
							className={"closeModal"}
						>
							<X fill="#FFFFFF" width={13} height={13} />
						</div>
					</Modal>
				</>
			)}
		</>
	);
};

export default memo(AppBar);
