import React, { memo, useEffect } from "react";
import { Switch } from "antd";

import styles from "./styles.module.scss";
import { useDispatch } from "react-redux";
import {
	getMembers,
	setEditProfile,
	updateProfileAccountSettings,
} from "store";
import { JoinMemberText } from "components/DirectoryMemberOptedOutPanel/joinMemberText";

interface IDirectoryMemberOptedOutPanel {
	memberDirectoryOptIn: boolean;
	resetTheSettingsPanelState?: () => void;
    showHidebutton?:boolean;
    handleHideButton?: () => void;
}

const DirectoryMemberOptedOutPanel: React.FunctionComponent<
	IDirectoryMemberOptedOutPanel
> = ({ memberDirectoryOptIn, resetTheSettingsPanelState ,showHidebutton=false,handleHideButton}) => {
	const dispatch = useDispatch();


	const onChange = (checked: boolean) => {
		const callbackOnSuccess = (updatedUserData: any) => {
			dispatch(setEditProfile({ user: updatedUserData.data.user }));
            if (checked) {
                dispatch(getMembers());
            }
		};

		// update the user profile account settings
		dispatch(
			updateProfileAccountSettings({
				userData: { member_directory_opt_in: checked },
				callbackOnSuccess,
			}),
		);
	};

	useEffect(() => {
        if(resetTheSettingsPanelState)
		resetTheSettingsPanelState();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className={styles.container}>
				<div className={styles.content}>
               {showHidebutton && <div className={styles.absoluteBtn}>
					<div
						className={styles.boldGreyTxt}
						onClick={handleHideButton}
					>
						{"Hide"}
					</div>
				</div>}
					<JoinMemberText/>
					<div className={styles.switchContainer}>
						<div className={styles.label}>{"Directory Opt-In"}</div>
						<Switch defaultChecked={memberDirectoryOptIn} onChange={onChange} />
					</div>
				</div>
			</div>
		</>
	);
};

export default memo(DirectoryMemberOptedOutPanel);
