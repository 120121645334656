import { IEventLoadingSetAction, IEventSetAction } from "./actions";

// ENUMS
export enum EEventActionTypes {
	eventLoadingSet = "event/set/loading",
	setEvent = "event/set",
	getEvent = "event/get",
}

// INTERFACES
export interface IEvent {
	id: number;
	community_id: number;
	title: string;
	all_day: false;
	starts_at: string;
	ends_at: string;
	created_at: string;
	updated_at: string;
	all_groups: true;
	description: string;
	latitude: number;
	longitude: number;
	location: string;
	is_private: false;
	needs_audience_update: false;
	event_tag_list: string[];
	member_type_list: string[];
	group_list: { id: number; name: string }[];
	duration?: number;
	endDatetime?: string;
	startDatetime?: string;
}
export interface IEventState {
	loading: boolean;
	currentId: number;
	data?: IEvent;
}

export interface IEventLoadingPayload {
	loading: boolean;
}

// TYPES
export type TEventActions = IEventLoadingSetAction | IEventSetAction;
