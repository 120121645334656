import FormWrapper from "components/FormWrapper";
import styles from "./styles.module.scss";

const LoginFormContainer = ({ children }) => {
	return (
		<>
			<div className={styles.title}>
				Welcome back,
				<br />
				sign in to your account
			</div>
			<FormWrapper>{children}</FormWrapper>
		</>
	);
};
export default LoginFormContainer;
