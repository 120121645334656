import { EEventActionTypes, IEvent } from "models";

export const setEventLoading = (payload: boolean) => ({
	type: EEventActionTypes.eventLoadingSet,
	payload,
});

export const setEvent = (payload: { data: IEvent }) => ({
	type: EEventActionTypes.setEvent,
	payload,
});
export const getEvent = (payload: {
	id: number;
	callbackOnSuccess?: () => void;
	callbackOnError?: () => void;
	forceCommunityId?: number;
}) => ({
	type: EEventActionTypes.getEvent,
	payload,
});
