import React, { memo, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import moment from "moment";
import Scrollbar from "react-scrollbars-custom";

import { FeaturedEventBox, Loading } from "components";
import avatarSm1 from "assets/images/avatar_sm_1.jpg";
import { TStore } from "models";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.events,
	(events) => ({
		events,
	}),
);
const Events: React.FunctionComponent<any> = ({ display = true }) => {
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { events } = useSelector(stateSelector);

	const selectedData = events.selectedCalendarDate
		? moment(events.selectedCalendarDate, "YYYY-MM-DD")
		: moment(new Date(), "YYYY-MM-DD");
	const selectedEvents = useMemo(() => {
		if (!events) return [];
		let result: any[] = [];
		if (selectedData) {
			// return all the events for the selected day
			result = events?.data?.filter((item: any) => {
				if (
					selectedData.isBetween(
						item.starts_at,
						item.ends_at,
						"days",
						"[]",
					)
				) {
					return true;
				} else {
					return false;
				}
			});
		}
		return result;
	}, [events, selectedData]);
	const upcomingEvents = useMemo(() => {
		if (!events) return [];
		let result: any[] = [];
		if (selectedData) {
			// return all the events for the selected day
			result = events?.data?.filter((item: any) => {
				if (
					// selectedData.isBetween(item.starts_at, item.ends_at, "days", "[]")
					selectedData.isSameOrBefore(item.ends_at)
				) {
					return true;
				} else {
					return false;
				}
			});
		}
		return result;
	}, [events, selectedData]);
	const listedEvents = display
		? events.selectedCalendarDate
			? selectedEvents
			: upcomingEvents
		: events.data;

	if (events.loading) {
		return <Loading />;
	}
	return (
		<Scrollbar
			contentProps={{
				renderer: (props) => {
					const { elementRef, ...restProps } = props;
					return (
						<div
							{...restProps}
							ref={elementRef}
							id="content-props"
							style={{
								minHeight: "unset",
								minWidth: "unset",
							}}
						/>
					);
				},
			}}
			wrapperProps={{
				renderer: (props) => {
					const { elementRef, ...restProps } = props;
					return (
						<div
							{...restProps}
							ref={elementRef}
							id="content-wrapper"
						/>
					);
				},
			}}
		>
			{listedEvents?.map((item: any, index: number) => (
				<div key={`event_${index}`}>
					<FeaturedEventBox
						day={moment(item.starts_at).format("ddd")}
						date={moment(item.starts_at).format("DDMMM")}
						avatar={avatarSm1}
						event={item}
					/>
					<div style={{ margin: "10px 0" }} />
				</div>
			))}
		</Scrollbar>
	);
};

export default memo(Events);
