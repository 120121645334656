import { IUploadDocServicePayload } from "models";
import { axiosInstance } from "..";

export const docs = {
	get: (payload: {
		page: number;
		communityId?: number;
		groupId?: number | null;
		tag_clicked?: boolean;
		q?: string;
		docId?: number;
		folder_id?: number | undefined;
		order: "view_count" | "created_at" | "title";
		order_dir: "asc" | "desc";
	}) =>
		axiosInstance().get(
			`/communities/${payload.communityId}/community_documents`,
			{
				params: {
					group_id: payload.groupId,
					tag_clicked: payload.tag_clicked || false,
					q: payload.q || null,
					page: payload.page,
					docId: payload.docId,
					all: true,
					folder_id: payload.folder_id,
					order: payload.order,
					order_dir: payload.order_dir,
				},
			},
		),
	resourcesOne: (payload: {
		communityId: number;
		id: number;
		folderId: number;
	}) =>
		axiosInstance().get(`/communities/${payload.communityId}/resources`, {
			params: {
				resource_id: payload.id,
				folder_id: payload.folderId ? payload.folderId : undefined,
				all: true,
			},
		}),
	deleteDoc: (payload: any) => {
		return axiosInstance().delete(`/communities/${payload.community_id}/community_documents/${payload.docId}`)
	},
	documentsOne: (payload: {
		communityId: number;
		id: number;
		folderId: number | null;
	}) =>
		axiosInstance().get(
			`/communities/${payload.communityId}/community_documents`,
			{
				params: {
					document_id: payload.id,
					folder_id: payload.folderId ? payload.folderId : undefined,
					all: true,
				},
			},
		),
	uploadDocument: (payload: IUploadDocServicePayload) => {
		const { group_ids, member_type_list, tag_list, folder_id } = payload;

		const formData = new FormData();
		const formKeys = Object.keys(payload);
		formKeys.forEach((element) => {
			const item = element as keyof IUploadDocServicePayload;
			if (item === "group_ids") {
				if (group_ids && group_ids.length > 0) {
					group_ids.forEach((item) => {
						formData.append(
							"community_document[group_ids][]",
							String(item),
						);
					});
				} else {
					formData.append("community_document[group_ids][]", "");
				}
			} else if (item === "member_type_list") {
				if (member_type_list && member_type_list.length > 0) {
					member_type_list.forEach((item) => {
						formData.append(
							"community_document[member_type_list][]",
							String(item),
						);
					});
				} else {
					formData.append(
						"community_document[member_type_list][]",
						"",
					);
				}
			} else if (item === "tag_list") {
				if (tag_list && tag_list.length > 0) {
					tag_list.forEach((item) => {
						formData.append(
							"community_document[tag_list][]",
							String(item),
						);
					});
				} else {
					formData.append("community_document[tag_list][]", "");
				}
			} else if (item === "folder_id" && folder_id) {
				formData.append(
					"community_document[folder_id]",
					String(folder_id),
				);
			} else if (item === "is_private" || item === "all_groups") {
				formData.append(
					`community_document[${item}]`,
					String(payload[item]),
				);
			} else if (
				item !== "communityId" &&
				item !== "folder_id" &&
				payload[item]
			) {
				formData.append(`community_document[${item}]`, payload[item]);
			}
		});

		return axiosInstance()
			.post(
				`/communities/${payload.communityId}/community_documents`,
				formData,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				},
			)
			.then((response: any) => {
				return response;
			})
			.catch((error: any) => {
				console.error({ error });
				return Promise.reject(
					error.response ? error.response.data : error,
				);
			});
	},
};
