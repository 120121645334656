import React, { memo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { createSelector } from "reselect";

import { getMarketplaceOfferRawData } from "store";
import { TStore } from "models";
import Loading from "components/Loading";
import { CardView } from "screens/marketplace/components";
import styles from "./styles.module.scss";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.marketplaceOffer?.rawData,
	(marketplaceOfferData) => {
		return {
			marketplaceOfferData,
		};
	},
);

interface IMarketplaceOfferNotificationDetails {
	marketplaceId: number;
	forceCommunityId: number;
}

const MarketplaceOfferNotificationDetails: React.FC<
	IMarketplaceOfferNotificationDetails
> = ({ marketplaceId, forceCommunityId }) => {
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { marketplaceOfferData } = useSelector(stateSelector);

	useEffect(() => {
		if (marketplaceId && forceCommunityId) {
			dispatch(
				getMarketplaceOfferRawData({
					id: marketplaceId,
					forceCommunityId,
					callbackOnSuccess: () => setLoading(false),
					callbackOnError: () => setLoading(false),
				}),
			);
		}
	}, [dispatch, marketplaceId, forceCommunityId]);

	return loading ? (
		<Loading />
	) : marketplaceOfferData ? (
		<div className={styles.container}>
			<CardView
				marketplaceOffer={marketplaceOfferData}
				disableFilterByTags={true}
			/>
		</div>
	) : null;
};

export default memo(MarketplaceOfferNotificationDetails);
