import { all, takeEvery, select, call, put, take } from "redux-saga/effects";
import CryptoJS, { AES } from "crypto-js";

import { EAppActionTypes, ECommunitiesActionTypes, ESubscriptionActionTypes } from "models";
import {
    LocalStorageKeyCustomToken,
    LocalStorageKey,
    CustomTokenPassword,
    rollbar,
    isItAWhiteLabel
} from "utils";
import {
    api,
    setUser,
    setAppLoading,
    getCommunitiesJoined,
    appSetData,
    getPosts,
    getEditProfile,
    history,
    updateHeaders,
} from "store";
import { updateToken } from "../..";
import { TStore } from "models";
import {
    appChangeCommunity,
    appInit,
    appSetUser,
    getCommunity,
    getSubscriptionManagementActionSaga,
    getUserCurrentSubscriptionAction,
    joinCommunity,
    SetWelcomeModal,
} from "store/actions";
import { getUnreadNotificationsCounterSagaAction } from "store/actions/notifications";
import { whiteLabelConfig } from "config";

declare global {
    interface Window {
        OneSignal: any;
    }
}

function* appOneSignalResetIdHandle() {
    // yield put(storeLogout());
}

const getAuthenticatedUser: any = (state: TStore) => state.auth;

function* appInitHandle(data: any) {
    const { userId, communityId, token } = data.payload;
    try {
        if (!userId || !token) {
            return;
        }
        const customTokenValEncrypted = AES.encrypt(
            `${userId} ${communityId} ${token}`,
            CustomTokenPassword,
        );
        localStorage.setItem(
            LocalStorageKeyCustomToken,
            customTokenValEncrypted.toString(),
        );
        if (communityId) {
            yield put(
                appSetData({
                    currentCommunityId: communityId,
                    currentGroupId: "",
                }),
            );
        }
    } catch (error) {
        yield put(setAppLoading(false));
        rollbar.error(error)
        console.error({ error });
    }
}

function* appSetUserHandle({ payload }: ReturnType<typeof appSetUser>) {
    const { onErrorCallback, onSuccessCallback } = payload;
    try {
        yield put(setAppLoading(true));
        const customToken = localStorage.getItem(LocalStorageKeyCustomToken);
        const response = yield fetch('https://api.ipify.org?format=json')
        if (response.ok) {
            const data = yield response.json()
            updateHeaders([{
                name: 'X_FORWARDED_FOR',
                value: data.ip
            }])
        }


        let user_id: number = 0;
        let community_id: number = 0;
        let token;
        if (customToken) {
            let decryptCustomToken = AES.decrypt(
                customToken,
                CustomTokenPassword,
            );
            let arrCustomToken = decryptCustomToken
                .toString(CryptoJS.enc.Utf8)
                .split(" ");

            user_id = parseInt(arrCustomToken[0]);
            community_id = parseInt(arrCustomToken[1]);
            token = arrCustomToken[2];
        }

        if (!token || !user_id) {
            if (isItAWhiteLabel() && process.env.REACT_APP_WHITELABEL_LOGIN_PAGE && process.env.REACT_APP_SSO) {
                const queryParamsString = window.location.search;
                const regexPattern = /content=([^&]+)&iv=([^&]+)/;
                const match = queryParamsString.match(regexPattern)

                if (match && match?.length > 0) {
                    const { data } = yield api.auth.signUpWithSSO({
                        encrypted_data: match[1],
                        encrypted_iv: match[2],
                        community_id: whiteLabelConfig?.community?.id.toString() as string
                    })

                    if (data) {
                        updateToken(data.user.access_token);
                        yield put(
                            setUser({
                                credentials: {
                                    user: data.user,
                                },
                            }),
                        );
                        yield put(
                            appInit({
                                userId: data.user.id,
                                communityId: "",
                                token: data.user.access_token,
                            }),
                        );
                        yield put(
                            joinCommunity({
                                id: whiteLabelConfig?.community?.id!,
                            }),
                        );
                        yield take(ECommunitiesActionTypes.joinCommunityCompleted);
                        yield put(setAppLoading(false));
                    }
                } else {
                    return window.location.assign(
                        process.env.REACT_APP_WHITELABEL_LOGIN_PAGE,
                    );
                }
                yield put(setAppLoading(false));
                return;
            }
            yield put(setAppLoading(false));
            onSuccessCallback();
            return;
        }
        updateToken(token);
        yield put(getCommunitiesJoined());
        if (community_id) {
            const { data: communityData } = yield call(api.community.home, {
                community_id,
            });

            if (
                communityData.meta.privacy === "open" ||
                communityData.meta.is_approved
            ) {
                yield put(
                    getPosts({
                        resetPosts: true,
                        communityId: community_id,
                    }),
                );
                yield put(
                    getUnreadNotificationsCounterSagaAction({
                        communityId: community_id,
                    }),
                );
                const { data: userProfileData } = yield call(
                    api.profile.getUserProfile,
                    {
                        user_id: user_id,
                        community_id: community_id,
                    },
                );

                yield put(
                    getEditProfile({
                        userId: user_id,
                        communityId: community_id,
                    }),
                );
                // TODO: add the editProfileData, profileAccountSettingsData and setUser in a single function
                const { data: editProfileData } = yield call(
                    api.profile.getEditProfile,
                    {
                        user_id: user_id,
                        community_id: community_id,
                    },
                );
                const { data: profileAccountSettingsData } = yield call(
                    api.profile.getProfileAccountSettings,
                    {
                        user_id: user_id,
                        community_id: community_id,
                    },
                );

                const userData = {
                    user: {
                        ...editProfileData.user,
                        ...userProfileData.user,
                        member_directory_opt_in:
                            profileAccountSettingsData.user
                                .member_directory_opt_in,
                        community_id,
                        joined_community_id: community_id,
                    },
                };

                yield put(
                    setUser({
                        credentials: userData,
                    }),
                );
                yield put(
                    appSetData({
                        currentCommunityId: community_id,
                        currentGroupId: null,
                    }),
                );
                yield put(
                    getSubscriptionManagementActionSaga({
                        communityId: community_id,
                    }),
                );
                yield take(
                    ESubscriptionActionTypes.COMPLETE_GET_SUBSCRIPTION_MANAGEMENT,
                );
            }
        }
        else {
            const { data: checkAccessTokenData } = yield call(
                api.auth.checkAccessToken,
                {
                    userId: String(user_id),
                },
            );
            if (!checkAccessTokenData.user) yield history.push("/auth/sign-in");
            if (checkAccessTokenData.user) {
                yield put(
                    setUser({
                        credentials: { user: checkAccessTokenData.user },
                    }),
                );

                if (!isItAWhiteLabel()) {
                    yield history.push("/communities");
                } else {
                    yield history.push("/joining/init");
                }
            }
        }
        yield put(setAppLoading(false));
        onSuccessCallback();
    } catch (error: any) {
        rollbar.error(error)
        if (
            error.response &&
            error.response.data &&
            error.response.data &&
            error.response.data.meta
        ) {
            if (
                Object.keys(error.response.data.meta).indexOf("is_approved") !==
                -1 &&
                !error.response.data.meta.is_approved
            ) {
                if (!isItAWhiteLabel()) {
                    yield history.push("/communities");
                } else {
                    yield history.push("/joining/init");
                }
            }
        }
        onErrorCallback();
        yield put(setAppLoading(false));
    }
}

function* handleAppChangeCommunity({
    payload,
}: ReturnType<typeof appChangeCommunity>) {
    const {
        dontRedirect = false,
        onSuccessCallback,
        onErrorCallback,
    } = payload;
    try {
        yield put(setAppLoading(true));
        if (!payload.communityId) {
            throw new Error("Missing parameters.");
        }
        const { communityId } = payload;
        yield put(
            appSetData({
                currentCommunityId: communityId,
                currentGroupId: null,
            }),
        );
        yield put(
            getCommunity({
                communityId,
            }),
        );
        yield put(getUserCurrentSubscriptionAction({ communityId }));
        const authUser = yield select(getAuthenticatedUser);
        const token = localStorage.getItem(LocalStorageKey);
        const customTokenValEncrypted = AES.encrypt(
            `${authUser.credentials.user.id} ${communityId} ${token}`,
            CustomTokenPassword,
        );
        localStorage.setItem(
            LocalStorageKeyCustomToken,
            customTokenValEncrypted.toString(),
        );
        if (!dontRedirect) {
            yield history.push({ pathname: "/dashboard" });
            yield put(SetWelcomeModal(true))
        }
        onSuccessCallback?.();
        // For the rest of entities I will add an useEffect that will fetch the community specific data.
        yield put(setAppLoading(false));
    } catch (error) {
        rollbar.error(error)
        if (
            error.response &&
            error.response.data &&
            error.response.data &&
            error.response.data.meta
        ) {
            if (
                Object.keys(error.response.data.meta).indexOf("is_approved") !==
                -1 &&
                !error.response.data.meta.is_approved
            ) {
                if (!dontRedirect) {
                    if (!isItAWhiteLabel()) {
                        yield history.push("/communities");
                    } else {
                        yield history.push("/joining/init");
                    }
                }
            }
        }
        console.log({ error });
        yield put(setAppLoading(false));
        onErrorCallback?.();
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(
            EAppActionTypes.appOneSignalResetId,
            appOneSignalResetIdHandle,
        ),
        takeEvery(EAppActionTypes.appInit, appInitHandle),
        takeEvery(EAppActionTypes.appChangeCommunity, handleAppChangeCommunity),
        takeEvery(EAppActionTypes.appSetUser, appSetUserHandle),
    ]);
}
