import {
	EResourcesActionTypes,
	IResourcesGetPayload,
	IIncrementResourceViewsPayload,
} from "models";



export const setResourcesLoading = (payload: boolean) => ({
	type: EResourcesActionTypes.resourcesLoadingSet,
	payload,
});

export const getResources = (payload: IResourcesGetPayload) => ({
	type: EResourcesActionTypes.getResources,
	payload,
});

export const deleteResourceAction = (payload: {
	resourceId: number;
}) => ({
	type: EResourcesActionTypes.deleteResources,
	payload,
});

export const setResources = (payload: any) => ({
	type: EResourcesActionTypes.setResources,
	payload,
});

export const setResourcesFilterTagsAction = (payload: any) => ({
	type: EResourcesActionTypes.setFilterTags,
	payload,
});

export const setResourcesFilterTagsInStoreAction = (payload: any) => ({
	type: EResourcesActionTypes.setFilterTagsInStore,
	payload,
});

export const setResourcesQueryAndFilterAction = (payload: {
	query: string;
}) => ({
	type: EResourcesActionTypes.setQueryAndFilter,
	payload,
});

export const setResourcesQueryAction = (payload: { query: string }) => ({
	type: EResourcesActionTypes.setResourcesQuery,
	payload,
});

export const incrementResourceViewsAction = (
	payload: IIncrementResourceViewsPayload,
) => ({
	type: EResourcesActionTypes.incrementResourceViews,
	payload,
});

export const setResourcesCurrentFolderAction = (payload: any) => ({
	type: EResourcesActionTypes.setResourcesCurrentFolder,
	payload,
});

export const updateResourcesCurrentFolderAction = (payload: any) => ({
	type: EResourcesActionTypes.updateResourcesCurrentFolder,
	payload,
});

export const updateResourcesOrderAction = (payload: any) => ({
	type: EResourcesActionTypes.updateResourcesOrder,
	payload,
});

export const setResourcesOrderAction = (payload: any) => ({
	type: EResourcesActionTypes.setResourcesOrder,
	payload,
});

export const setResourcesPathAction = (payload: any) => ({
	type: EResourcesActionTypes.setPath,
	payload,
});

export const setResourcesRedirectQuery = (payload: { redirectQuery?: string | null }) => (
	{
		type: EResourcesActionTypes.setResourcesRedirectQuery,
		payload,
	});