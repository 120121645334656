import React, { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";

import { FeedPosts } from "components";
import { TStore } from "models";
import { getEditProfilePosts } from "store";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.editProfile.data.posts,
	(state: TStore) => state.editProfile.loading,
	(state: TStore) => state.app.currentCommunityId,
	(posts, loading, currentCommunityId) => ({
		posts,
		loading,
		currentCommunityId,
	}),
);

const MyPosts = () => {
	const dispatch = useDispatch();
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { posts, loading, currentCommunityId } = useSelector(stateSelector);

	const handleLoadMore = useCallback(
		(page: number) => {
			if (currentCommunityId) {
				dispatch(getEditProfilePosts());
			}
		},
		[currentCommunityId, dispatch],
	);

	return (
		<FeedPosts
			posts={posts?.pins}
			loading={loading}
			hasMore={!loading && posts.meta.pagination.has_next_page}
			meta={posts?.meta}
			loadMore={handleLoadMore}
			getScrollParent={() => document.getElementsByTagName("main")[0]}
		/>
	);
};

export default memo(MyPosts);
