import { IDocLoadingSetAction, IDocSetAction } from "./actions";

// ENUMS
export enum EDocActionTypes {
	docLoadingSet = "doc/set/loading",
	setDoc = "doc/set",
	getDoc = "doc/get",
	uploadDoc = "doc/upload",
}

// INTERFACES

export interface IUploadDocServicePayload {
	communityId: number;
	title: string;
	attachment: File;
	folder_id: number;
	member_type_list: string[] | [];
	group_ids: number[] | [];
	is_private: boolean;
	description?: string;
	expiry_date?: Date;
	tag_list?: string[];
	[key: string]: any;
}

export interface IUploadDocActionPayload {
	title: string;
	document: File;
	longDescription?: string;
	expirationDate?: Date;
	audienceGroup?: string[];
	groupsGroup?: number[];
	tagsGroup?: string[];
	onError: () => void;
	onSuccess: (e?:boolean) => void;
	all_groups: boolean;
	is_private: boolean;
	parent: string;
}

export interface IDoc {
	attachment: { url: string };
	description: string;
	id: number;
	mime_type: string;
	original_filename: string;
	search_tags: string[];
	title: string;
	mentions: string[];
	docType?: string;
	docExtension?: string;
}

export interface IDocState {
	loading: boolean;
	currentId: number;
	data?: IDoc;
}

export interface IDocLoadingPayload {
	loading: boolean;
}

// TYPES
export type TDocActions = IDocLoadingSetAction | IDocSetAction;
