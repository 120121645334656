import {
    call,
    put,
    all,
    takeEvery,
    select,
    putResolve,
} from "redux-saga/effects";

import { EDocsActionTypes, TStore, IDocsIncrementDocViewsAction } from "models";
import {
    api,
    setDocsLoading,
    setDocs,
    setFilesFilterTagsInStoreAction,
} from "store";
import {
    setDocsQueryAction,
    getDocs,
    incrementDocViewsAction,
    setFilterDocsLoading,
    setDocsOrderAction,
    updateDocsCurrentFolderAction,
    deleteDocsAction,
    setDocsQueryAndFilterAction
} from "store/actions";
import { rollbar } from "utils";

const getCommunityId = (state: TStore) => state.app.currentCommunityId;
const getGroupId = (state: TStore) => state.app.currentGroupId;
const getCommunityTags = (state: TStore) => state.community.mainTags.tags;
const getSelectedTags = (state: TStore) => state.documents.selectedTags;
const getQuery = (state: TStore) => state.documents.query;
const getDocsMeta = (state: TStore) => state.documents.meta;
const getDocsData = (state: TStore) => state.documents.data;
const getCurrentFolder = (state: TStore) => state.documents.currentFolder;
const getDocsOrder = (state: TStore) => ({
    order: state.documents.order,
    order_dir: state.documents.order_dir,
});
const getCurrentPath = (state: TStore) => state.documents.path;
const getCurrentCommunityId = (state: TStore) => state.app.currentCommunityId;
const getRedirectQuery = (state: TStore) => state.documents.redirectQuery;


function* getDocsHandle({ payload }: ReturnType<typeof getDocs>) {
    try {
        yield put(setDocsLoading(true));
        let page: number;
        const communityId = yield select(getCommunityId);
        const groupId = yield select(getGroupId);
        const selectedTags = yield select(getSelectedTags);
        const q = yield select(getQuery);
        const meta = yield select(getDocsMeta);
        const docsData = yield select(getDocsData);
        const order = yield select(getDocsOrder);
        const currentFolder = yield select(getCurrentFolder);
        const currentPath = yield select(getCurrentPath);
        const rQ = yield select(getRedirectQuery);

        if (payload?.reset || docsData?.length === 0 || !docsData) {
            page = 1;
        } else if (meta?.pagination?.has_next_page) {
            page = parseInt(meta?.pagination?.current_page) + 1;
        } else {
            return;
        }

        const { data } = yield call(api.docs.get, {
            communityId,
            groupId,
            tag_clicked: !!selectedTags.length,
            q: !!selectedTags.length ? selectedTags[0] : q,
            page,
            docId: rQ,
            folder_id: currentFolder,
            ...order,
        });

        if (!data) {
            yield put(setDocsLoading(false));
        } else {

            yield put(
                setDocs({
                    data: {
                        ...data,
                        meta: {
                            ...data.meta,
                            folders:
                                currentPath?.length > 0
                                    ? currentPath
                                        .map((c) => c.children)
                                    [currentPath.length - 1].flat(1)
                                    : data.meta.folders,
                        },
                    },
                    reset: payload?.reset,
                }),
            );
            yield put(setDocsLoading(false));
        }
    } catch (error) {
        rollbar.error(error)
        console.error({ error });
        yield put(setDocsLoading(false));
    }
}

function* setFilterTagsHandle({ payload }: any) {
    yield put(setFilterDocsLoading(true));
    const tags: {
        id: number;
        name: string;
        is_selected: boolean;
    }[] = yield select(getCommunityTags);

    const parsedTags = tags
        .filter((item) => {
            return payload.tag.find(
                (element: string) =>
                    element.toLowerCase().trim() ===
                    item.name.toLowerCase().trim(),
            );
        })
        .map((item) => item.name);
    yield put(setFilesFilterTagsInStoreAction({ parsedTags }));
    yield putResolve(getDocs({ reset: true }));
    yield put(setFilterDocsLoading(false));
}

function* handleSetQueryAndFilterTags({ payload }: ReturnType<typeof setDocsQueryAndFilterAction>) {
    try {
        yield put(setDocsQueryAction({ query: payload }));
        yield put(getDocs({ reset: true }));
    } catch (error) {
        rollbar.error(error)
        console.error({ error });
    }
}

function* incrementDocViewsHandle({ payload }: IDocsIncrementDocViewsAction) {
    try {
        yield put(incrementDocViewsAction(payload));
    } catch (error) {
        rollbar.error(error)
        console.error({ error });
    }
}

function* updateDocsOrderHandle({ payload }: any) {
    try {
        yield put(setDocsOrderAction(payload));
        yield put(getDocs({ reset: true }));
    } catch (error) {
        rollbar.error(error)
        console.error({ error });
    }
}

function* setDocsCurrentFolderHandle({ payload }: any) {
    try {
        yield put(updateDocsCurrentFolderAction(payload));
        yield put(getDocs({ reset: true }));
    } catch (error) {
        rollbar.error(error)
        console.error({ error });
    }
}
function* docsDeleteHandle({ payload }: ReturnType<typeof deleteDocsAction>) {
    const { docId } = payload;
    try {
        const currentCommunityId = yield select(getCurrentCommunityId);
        if (!docId || !currentCommunityId) {
            throw new Error("Missing parameters!");
        }
        yield call(api.docs.deleteDoc, {
            community_id: currentCommunityId,
            docId: docId,
        });
        yield put(getDocs({ reset: true }))
    } catch (error) {
        rollbar.error(error)
        console.error({ error });
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(EDocsActionTypes.getDocs, getDocsHandle),
        takeEvery(
            EDocsActionTypes.setQueryAndFilter,
            handleSetQueryAndFilterTags,
        ),
        takeEvery(EDocsActionTypes.setFilterTags, setFilterTagsHandle),
        takeEvery(EDocsActionTypes.incrementDocViews, incrementDocViewsHandle),
        takeEvery(
            EDocsActionTypes.setDocsCurrentFolder,
            setDocsCurrentFolderHandle,
        ),
        takeEvery(
            EDocsActionTypes.docsDelete,
            docsDeleteHandle,
        ),
        takeEvery(EDocsActionTypes.updateDocsOrder, updateDocsOrderHandle),
    ]);
}
