import { memo, useCallback, useEffect } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { CheckOutlined } from "@ant-design/icons";
import { CloseOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";

import styles from "./styles.module.scss";
import { EGroupPrivacy, IGroup } from "models";
import { ReactComponent as MemberGroup } from "assets/images/member-group.svg";
import { ReactComponent as LockedIcon } from "assets/images/locked.svg";
import { ReactComponent as UnlockedIcon } from "assets/images/unlocked.svg";
import { ReactComponent as RequestedIcon } from "assets/images/requested.svg";
import { ReactComponent as CommunityGroupLeaveIcon } from "assets/images/communityGroupIcon.svg";
import { UseShowDescriptionWithMentions } from "utils";
import { CustomButton } from "components";
import { leaveGroup } from "store";

interface IJoinCommunityGroupCard {
	handleCancelJoinGroupRequest: (groupId: number, requestId: number) => void;
	handleJoinGroup: (groupId: number) => void;
	currentCommunityId: number;
	data: IGroup;
}

const JoinCommunityGroupCard: React.FunctionComponent<
	IJoinCommunityGroupCard
> = ({
	currentCommunityId,
	data,
	handleJoinGroup,
	handleCancelJoinGroupRequest,
}) => {
	const location = useLocation();
	const matchCommunitiesPath = matchPath("/communities/*", location.pathname);
	const matchJoiningPath = matchPath("/joining/*", location.pathname);
	const renderedFromRegistrationProcessScreen =
		matchCommunitiesPath || matchJoiningPath;
	const dispatch = useDispatch();
	const { name, privacy, number_of_members } = data;

	const renderButtonsAndText = useCallback(() => {
		if (!data) return;
		const {
			is_joined,
			join_request_status,
			id,
			request_id,
			privacy,
			community_id,
			membership_id,
		} = data;

		if (is_joined) {
			return (
				<div className={styles.joinCancelButtons}>
					<div className={styles.joinedButton}>
						<CustomButton
							varient="outline"
							disabled
							style={{
								color: "#9CA1BA",
								border: "1px solid #DDE1E4",
							}}
						>
							<CheckOutlined />
							{"Joined"}
						</CustomButton>
					</div>
					<div className={styles.cancelButton}>
						<CustomButton
							style={{ width: "150px" }}
							varient="denger"
							onClick={() =>
								dispatch(
									leaveGroup({
										communityId: community_id,
										groupId: id,
										membershipId: membership_id,
										privacy,
									}),
								)
							}
						>
							<CloseOutlined />
							Leave
						</CustomButton>
					</div>
				</div>
			);
		} else if (join_request_status === "pending" && request_id) {
			return (
				<div className={styles.joinCancelButtons}>
					<div className={styles.joinedButton}>
						<CustomButton disabled>
							<RequestedIcon style={{ marginRight: "8px" }} />
							Requested
						</CustomButton>
					</div>
					<div className={styles.cancelButton}>
						<CustomButton
							style={{ width: "150px" }}
							varient="denger"
							onClick={() =>
								handleCancelJoinGroupRequest(
									id,
									parseInt(request_id ?? ""),
								)
							}
						>
							<CloseOutlined />
							Cancel request
						</CustomButton>
					</div>
				</div>
			);
		} else {
			return (
				<CustomButton
					varient="outline"
					style={{
						marginTop: "auto",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
					onClick={() => handleJoinGroup(data.id)}
				>
					<CommunityGroupLeaveIcon className={styles.leaveIcon} />
					{privacy === EGroupPrivacy.closed
						? "Request to join"
						: "Join"}
				</CustomButton>
			);
		}
	}, [dispatch, data, handleCancelJoinGroupRequest, handleJoinGroup]);

	useEffect(() => {
		if (renderedFromRegistrationProcessScreen) {
			const selectEnterButton = document.getElementById(
				"enter-dropdown-left-button",
			);

			selectEnterButton?.setAttribute("disabled", "disabled");
			return () => {
				selectEnterButton?.removeAttribute("disabled");
			};
		}
	}, [renderedFromRegistrationProcessScreen]);

	return (
		<>
			{/* <section
				className={classNames(styles.groupBox, styles.spaceBetweenContent)}
			>
				<div>
					<div
						className={styles.mdSizedTxt}
						style={{
							color:
								privacy === EGroupPrivacy.open
									? "#15DE5A"
									: "#EB5757",
						}}
					>
						{privacy &&
							`${privacy.charAt(0).toUpperCase()}${privacy.slice(1)}`}
					</div>
					<div className={styles.boldTitle}>{name}</div>
					<div>
						<div
							style={{ color: "rgba(0, 0, 0, 0.6)" }}
							className={styles.mdSizedTxt}
						>{`${number_of_members} members`}</div>
					</div>
				</div>
				<div className={styles.flexColumnDirection}>
					{renderButtonsAndText()}
				</div>
			</section> */}
			<div className={styles.groupCardContainer}>
				<div className={styles.cardBodyContainer}>
					<div style={{ display: "flex", gap: "12px" }}>
						<div className={styles.imageHolder}>
							{data.avatar ? (
								<img src={data.avatar} alt="" />
							) : (
								<div className={styles.textAvatar}>{"A"}</div>
							)}
						</div>
						<div className={styles.cardBodyHeader}>
							<div className={styles.title}>{name}</div>
							<div className={styles.membersCount}>
								<MemberGroup />
								{`${number_of_members}`}
							</div>

							{privacy && (
								<div
									style={{
										color:
											privacy === EGroupPrivacy.open
												? "#2EAB58"
												: "#D82140",
									}}
									className={styles.privacyContainer}
								>
									{privacy === EGroupPrivacy.open ? (
										<UnlockedIcon />
									) : (
										<LockedIcon />
									)}
									{privacy === EGroupPrivacy.closed
										? "Closed group"
										: "Open group"}
								</div>
							)}
						</div>
					</div>

					<div className={styles.cardDescription}>
						<UseShowDescriptionWithMentions
							text={data.description}
						/>
					</div>
				</div>

				<div className={styles.buttonContainer}>
					{renderButtonsAndText()}
				</div>
			</div>
		</>
	);
};

export default memo(JoinCommunityGroupCard);
