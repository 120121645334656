import { EVideoActionTypes, ICreateVideoPayload, IVideo } from "models";

export const setVideoLoading = (payload: boolean) => ({
	type: EVideoActionTypes.videoLoadingSet,
	payload,
});

export const setVideo = ({ video }: { video?: IVideo | undefined } = {}) => ({
	type: EVideoActionTypes.setVideo,
	payload: video,
});
export const getVideo = (payload: {
	videoId: number;
	callbackOnSuccess?: () => void;
	callbackOnError?: () => void;
	forceCommunityId?: number;
	folderId?: number;
}) => ({
	type: EVideoActionTypes.getVideo,
	payload,
});

export const getVideoForProgressbar = (payload: {
	videoId: number;
	callbackOnSuccess?: () => void;
	callbackOnError?: () => void;
	forceCommunityId?: number;
	folderId?: number;
}) => ({
	type: EVideoActionTypes.getVideoForProgressbar,
	payload,
});

export const createVideo = (payload: ICreateVideoPayload) => ({
	type: EVideoActionTypes.createVideo,
	payload,
});

export const setVideoUploadProgressAction = (payload: number | undefined) => ({
	type: EVideoActionTypes.setVideoUploadProgress,
	payload,
});

export const setVideoIdAction = (payload: number | undefined) => ({
	type: EVideoActionTypes.setVideoId,
	payload,
});

export const setVideoSuccessfullyUploadedAction = (
	payload: boolean | undefined,
) => ({
	type: EVideoActionTypes.setVideoSuccessfullyUploaded,
	payload,
});

export const setVideoCreatedIntervalAction = (payload: number | undefined) => ({
	type: EVideoActionTypes.setInterval,
	payload,
});
