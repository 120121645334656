import React, { memo } from "react";
import { Navigate } from "react-router-dom";

import PrivateRoute from "./private";
import { isItAWhiteLabel } from "utils";

interface IUhubsRoute {
	children: any;
}

const UhubsRoute: React.FunctionComponent<IUhubsRoute> = ({ children }) => {
	if (isItAWhiteLabel()) {
		return (
			<Navigate
				to={{
					pathname: "/dashboard", // TODO: I need to check if the user is either joined or not and depending on that I will redirect the user accordingly
				}}
			/>
		);
	} else {
		return <PrivateRoute>{children}</PrivateRoute>;
	}
};

export default memo(UhubsRoute);
