import React, { memo, useCallback, useMemo, useState } from "react";
import { Col, Form, Select, Row, Input, Button, AutoComplete } from "antd";
import { SelectValue } from "antd/lib/select";

import styles from "./styles.module.scss";
import { axiosInstance } from "store/services";
import { ClearIcon } from "..";
import { rollbar } from "utils";
import { ReactComponent as Search } from "assets/images/searchIcon.svg";

const { Option } = Select;

const FormItem = Form.Item;
interface ISearchBarMultiple {
	handleSearchByMemberNameAndBio:
		| React.ChangeEventHandler<HTMLInputElement>
		| undefined;
	firstInputPlaceholder: string;
	onSearch: () => void;
	firsInputValue?: string;
	handleOnChangeMemberType: (value: SelectValue, option: any) => void;
	handleSearchByLocation: (value: string) => void;
	handleResetFilteringByLocation: (value: string) => void;
	triggerSearchByMemberNameAndBio:
		| React.KeyboardEventHandler<HTMLInputElement>
		| undefined;
	resetResultsByMemberNameAndBio: (value: string) => void;
}

const SearchBarMultiple: React.FunctionComponent<ISearchBarMultiple> = ({
	handleSearchByMemberNameAndBio,
	firsInputValue = "",
	firstInputPlaceholder = "",
	onSearch,
	handleOnChangeMemberType,
	handleSearchByLocation,
	handleResetFilteringByLocation,
	triggerSearchByMemberNameAndBio,
	resetResultsByMemberNameAndBio,
}) => {
	const [locations, setLocations] = useState<any>([]);

	const initialValues = useMemo(() => {
		return {
			firsInputValue,
		};
	}, [firsInputValue]);

	const onLocationSearch = useCallback((val) => {
		axiosInstance()
			.get("/geocoder", {
				params: { address: val },
			})
			.then((data) => {
				const parseData =
					data &&
					data.data.results.map(
						(item: {
							address: string;
							latitude: number;
							longitude: number;
						}) => {
							const { address, latitude, longitude } = item;
							return {
								label: address,
								value: JSON.stringify({
									address,
									latitude,
									longitude,
								}),
							};
						},
					);
				setLocations(parseData);
			})
			.catch((error) => {
				rollbar.error(error);
				console.error({ error });
				setLocations([]);
			});
	}, []);

	return (
		<div className={styles.searchBarMultiple}>
			<Form initialValues={initialValues}>
				<Row
					gutter={8}
					style={{
						display: "flex",
						flexDirection: "row",
					}}
				>
					<Col className={styles.search}>
						<FormItem name="memberName">
							<Input
								onChange={handleSearchByMemberNameAndBio}
								onKeyUp={triggerSearchByMemberNameAndBio}
								placeholder={"Search by name and bio"}
								allowClear={{
									clearIcon: (
										<ClearIcon
											handleClearData={
												resetResultsByMemberNameAndBio
											}
										/>
									),
								}}
							/>
						</FormItem>
					</Col>
					<Col className={styles.search}>
						<FormItem name="location">
							<AutoComplete
								onSearch={onLocationSearch}
								onSelect={handleSearchByLocation}
								onChange={handleResetFilteringByLocation}
								placeholder="Location"
							>
								{locations &&
									locations.map(
										(item: {
											label: string;
											value: string;
										}) => {
											const { label } = item;
											return (
												<Option
													key={label}
													value={label}
												>
													{label}
												</Option>
											);
										},
									)}
							</AutoComplete>
						</FormItem>
					</Col>
					<Col className={styles.searchButton}>
						<Button icon={<Search />} onClick={() => onSearch()}>
							<div className={styles.text}>{"Search"}</div>
						</Button>
					</Col>
				</Row>
			</Form>
		</div>
	);
};

export default memo(SearchBarMultiple);
