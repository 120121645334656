import React, { memo, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { Button, Modal } from "antd";
import { useNavigate } from "react-router-dom";

import styles from "./styles.module.scss";
import { ESubscriptionStatus, TStore } from "models";
import { ReactComponent as X } from "assets/images/x.svg";
import {
	cancelSubscriptionActionSaga,
	leaveCommunity,
	notify,
	resubscribeSagaAction,
} from "store";
import { isItAWhiteLabel } from "utils";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.subscription.currentMemberPlan,
	(state: TStore) => state.subscription.subscription,
	(state: TStore) => state.community?.data,
	(memberPlan, memberSubscription, communityData) => ({
		memberPlan,
		memberSubscription,
		communityData,
	}),
);

const CancelSubscriptionBtn = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [
		isLeaveOrCancelSubscriptionModalVisible,
		setIsLeaveOrCancelSubscriptionModalVisible,
	] = useState(false);
	const [actionInProgress, setActionInProgress] = useState(false);

	const stateSelector = useCallback(stateSelectorHandle, []);
	const { memberPlan, memberSubscription, communityData } =
		useSelector(stateSelector);
	const currentPlan = memberSubscription
		? memberSubscription.plan
		: memberPlan;

	const diffInDays = useMemo(() => {
		if (memberSubscription?.cancelation_requested) {
			if (memberSubscription?.expiry_date) {
				const today = new Date().getTime();
				const expiryDate = Date.parse(memberSubscription.expiry_date);

				return Math.ceil((expiryDate - today) / (24 * 3600 * 1000));
			}
		} else {
			return null;
		}
	}, [memberSubscription]);

	const toggleModal = useCallback(() => {
		setIsLeaveOrCancelSubscriptionModalVisible(
			!isLeaveOrCancelSubscriptionModalVisible,
		);
	}, [isLeaveOrCancelSubscriptionModalVisible]);

	const cancelPlan = useCallback(() => {
		setActionInProgress(true);
		dispatch(
			cancelSubscriptionActionSaga({
				onSuccessCallback: () => {
					toggleModal();
					setActionInProgress(false);
				},
				onErrorCallback: () => {
					toggleModal();
					setActionInProgress(false);
				},
			}),
		);
	}, [dispatch, toggleModal]);

	const cancelSubscriptionOrLeaveBtnLabelsAndTexts: {
		btnLabel: string;
		modalTitle: string;
		modalContent: string | JSX.Element | null;
		hideBtn: boolean;
	} = useMemo(() => {
		const resultedObject = {
			btnLabel: "Leave",
			modalTitle: "Are you sure you want to leave?",
			modalContent: (
				<>
					If you leave the community, this will also cancel your
					membership.
					<br />
					Are you sure you want to leave the community?
				</>
			),
			hideBtn: Boolean(isItAWhiteLabel()),
		};
		if (!currentPlan) {
			return {
				...resultedObject,
			};
		}
		if (memberSubscription && !currentPlan.free) {
			return {
				...resultedObject,
				btnLabel: "Cancel",
				modalTitle: "Subscription Cancelation",
				modalContent: (
					<>
						Your subscription will end on{" "}
						<span style={{ fontWeight: "bold" }}>
							{memberSubscription.expiry_date}
						</span>
						.
						<br />
						Are you sure you want to cancel the current
						subscription?
					</>
				),
				hideBtn: false,
			};
		} else {
			return resultedObject;
		}
	}, [currentPlan, memberSubscription]);

	const handleLeave = useCallback(() => {
		// reset the modal's status and title.
		if (communityData?.id) {
			setActionInProgress(true);
			dispatch(
				leaveCommunity({
					communityId: communityData.id,
					onSuccessCallback: () => {
						navigate("/communities");
						toggleModal();
						setActionInProgress(false);
					},
					onErrorCallback: () => {
						toggleModal();
						setActionInProgress(false);
						notify(
							"error",
							"An error occurred. Please try again later.",
						);
					},
				}),
			);
		} else {
			notify("error", "An error occurred. Please try again later.");
		}
	}, [communityData?.id, dispatch, navigate, toggleModal]);

	const resubscribe = useCallback(() => {
		setActionInProgress(true);
		dispatch(
			resubscribeSagaAction({
				onSuccessCallback: () => {
					toggleModal();
					setActionInProgress(false);
				},
				onErrorCallback: () => {
					toggleModal();
					setActionInProgress(false);
				},
			}),
		);
	}, [dispatch, toggleModal]);

	return (
		<div className={styles.mainContainer}>
			<div className={styles.container}>
				{!memberSubscription || !currentPlan ? null : (
					<>
						{diffInDays ? (
							<div
								style={{
									color: "red",
									textAlign: "center",
									fontSize: 14,
									fontWeight: 500,
								}}
							>
								{diffInDays} days left
							</div>
						) : null}

						{!cancelSubscriptionOrLeaveBtnLabelsAndTexts.hideBtn && (
							<div className={styles.buttonContainer}>
								<Button
									onClick={toggleModal}
									type="link"
									size={"middle"}
									style={{ color: "#fff", width: "100%" }}
									// className={styles.btnBoldWeight}
								>
									{memberSubscription?.cancelation_requested
										? "Resubscribe"
										: cancelSubscriptionOrLeaveBtnLabelsAndTexts.btnLabel}
								</Button>
							</div>
						)}
					</>
				)}
			</div>
			<Modal
				title={
					memberSubscription?.cancelation_requested
						? "Resubscribe"
						: cancelSubscriptionOrLeaveBtnLabelsAndTexts.modalTitle
				}
				visible={isLeaveOrCancelSubscriptionModalVisible}
				onOk={
					memberSubscription?.cancelation_requested
						? resubscribe
						: currentPlan?.free ||
						  memberSubscription?.status ===
								ESubscriptionStatus.trialing
						? handleLeave
						: cancelPlan
				}
				okType={"danger"}
				centered
				closable={false}
				cancelText={"Cancel"}
				cancelButtonProps={{
					id: "btn-cancel-delete-account",
					disabled: actionInProgress,
				}}
				okButtonProps={{ id: "btn-ok-leave-group" }}
				okText={"Yes, I am sure"}
				onCancel={toggleModal}
				className={"modalContainer"}
				getContainer={() =>
					document.getElementById(
						"subscription-management-layout",
					) as any
				}
				confirmLoading={actionInProgress}
				maskClosable={false}
			>
				<div className={styles.modalContent}>
					{cancelSubscriptionOrLeaveBtnLabelsAndTexts.modalContent}
				</div>
				<div onClick={toggleModal} className={"closeModal"}>
					<X fill="#FFFFFF" width={13} height={13} />
				</div>
			</Modal>
		</div>
	);
};

export default memo(CancelSubscriptionBtn);
