import React, { memo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { createSelector } from "reselect";

import { getEvent } from "store";
import { TStore } from "models";
import Loading from "components/Loading";
import { CalendarEvent } from "screens";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.calendarEventData,
	(calendarEventData) => {
		return {
			calendarEventData,
		};
	},
);

interface ICommunityEventNotificationDetails {
	communityEventId: number;
	forceCommunityId: number;
}

const CommunityEventNotificationDetails: React.FC<ICommunityEventNotificationDetails> =
	({ communityEventId, forceCommunityId }) => {
		const [loading, setLoading] = useState(false);
		const dispatch = useDispatch();
		const stateSelector = useCallback(stateSelectorHandle, []);
		const { calendarEventData } = useSelector(stateSelector);

		useEffect(() => {
			if (!communityEventId || !forceCommunityId) return;
			dispatch(
				getEvent({
					id: communityEventId,
					forceCommunityId,
					callbackOnSuccess: () => {
						setLoading(false);
					},
					callbackOnError: () => setLoading(false),
				}),
			);
		}, [dispatch, communityEventId, forceCommunityId]);

		return loading ? (
			<Loading />
		) : calendarEventData ? (
			<CalendarEvent calendarEventDataIsAvailable={true} />
		) : null;
	};

export default memo(CommunityEventNotificationDetails);
