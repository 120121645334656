import { put, all, takeEvery, select, call } from "redux-saga/effects";

import { EVideoActionTypes, TStore } from "models";
import {
    setVideoLoading,
    setVideo,
    api,
    getVideo,
    createVideo,
    setVideoIdAction,
    setVideoSuccessfullyUploadedAction,
    notify,
    setResourceIdAction,
} from "store";
import { rollbar } from "utils";

const getCommunityId = (state: TStore) => state.app.currentCommunityId;
const selectVideosCurrentFolderId = (state: TStore) =>
    state.videos.currentFolder;
const selectResourcesCurrentFolderId = (state: TStore) =>
    state.resources.currentFolder;

function* getVideoHandle({ payload }: ReturnType<typeof getVideo>) {
    const {
        videoId,
        callbackOnSuccess,
        callbackOnError,
        forceCommunityId,
        folderId,
    } = payload;

    try {
        yield put(setVideoLoading(true));
        const communityId: number = yield select(getCommunityId);

        const { data } = yield call(api.videos.get, {
            communityId: forceCommunityId || communityId,
            videoId: videoId,
            folder_id: folderId,
        });

        const video = data.videos[0];
        yield put(setVideo({ video }));
        yield put(setVideoLoading(false));
        if (callbackOnSuccess) {
            callbackOnSuccess();
        }
    } catch (error) {
        if (callbackOnError) {
            callbackOnError();
        }
        yield put(setVideoLoading(false));
        rollbar.error(
            error
        )
        console.error({ error });
    }
}

function* getVideoForProgressbar({ payload }: ReturnType<typeof getVideo>) {
    const { videoId, forceCommunityId, folderId } = payload;

    try {
        const communityId: number = yield select(getCommunityId);

        const { data } = yield call(api.videos.get, {
            communityId: forceCommunityId || communityId,
            videoId: videoId,
            folder_id: folderId,
        });

        const video = data.videos[0];
        if (video) {
            yield put(setVideoSuccessfullyUploadedAction(true));
        }
    } catch (error) {
        rollbar.error(
            error
        )
        console.error({ error });
    }
}

function* handleCreateVideo({ payload }: ReturnType<typeof createVideo>) {
    const {
        video,
        video_link,
        title,
        tagsGroup,
        audienceGroup,
        groupsGroup,
        longDescription,
        expirationDate,
        onSuccess,
        onError,
        all_groups,
        is_private,
        parent,
    } = payload;
    try {
        yield put(setVideoLoading(true));
        const currentCommunityId = yield select(getCommunityId);
        const videosCurrentFolderId = yield select(selectVideosCurrentFolderId);
        const resourcesCurrentFolderId = yield select(
            selectResourcesCurrentFolderId,
        );

        if (parent === "Videos") {
            const { data } = yield call(api.videos.createVideo, {
                communityId: currentCommunityId,
                title,
                video_link,
                group_ids: groupsGroup || [],
                member_type_list: audienceGroup || [],
                is_private,
                all_groups,
                folder_id: videosCurrentFolderId,
                description: longDescription,
                expiry_date: expirationDate,
                tag_list: tagsGroup,
            });

            if (onSuccess) {
                onSuccess();
            }


            if (data.meta.needs_approval) { notify('success', data.meta.message) }
            else { notify("success", "Video upload successful"); }

            if (!payload.video_link && payload.video && data) {
                yield put(setVideoIdAction(data.video_id));
                yield call(api.videos.uploadVideo, {
                    communityId: currentCommunityId,
                    videoId: data.video_id,
                    video: video,
                    videoUploadProgress: payload.videoUploadProgress,
                });
            }
        }

        if (parent === "Resources") {
            const { data } = yield call(api.resources.createVideoForResources, {
                communityId: currentCommunityId,
                title,
                video_link,
                group_ids: groupsGroup || [],
                member_type_list: audienceGroup || [],
                is_private,
                all_groups,
                folder_id: resourcesCurrentFolderId,
                description: longDescription,
                expiry_date: expirationDate,
                tag_list: tagsGroup,
            });
            if (onSuccess) {
                onSuccess();
            }


            if (!payload.video_link && payload.video && data) {
                yield put(setResourceIdAction(data.resource_id));
                yield call(api.resources.uploadVideoForResources, {
                    communityId: currentCommunityId,
                    resourceId: data.resource_id,
                    video: video,
                    videoUploadProgress: payload.resourceUploadProgress,
                });

            } else if (data) {
                if (data.meta.needs_approval) { notify('success', data.meta.message) }
                else { notify("success", "Video upload successful"); }
            }
        }

        yield put(setVideoLoading(false));
    } catch (error) {
        rollbar.error(
            error
        )
        if (onError) {
            onError();
        }
        console.error({ error });
        yield put(setVideoLoading(false));
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(EVideoActionTypes.getVideo, getVideoHandle),
        takeEvery(EVideoActionTypes.createVideo, handleCreateVideo),
        takeEvery(
            EVideoActionTypes.getVideoForProgressbar,
            getVideoForProgressbar,
        ),
    ]);
}
