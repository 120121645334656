import { useCallback, useEffect, useState } from "react";
import { Input, Form } from "antd";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import { authenticate } from "store";
import FormInput from "components/FormInput";
import LoginFormContainer from "../LoginFormContainer";
import CustomButton from "components/Button";
import styles from "./styles.module.scss";
import { getCookie } from "utils";

const LoginForm = () => {
	const dispatch = useDispatch();
	const [disableSignIn, setDisableSignIn] = useState<boolean>(false);

	const handleSubmit = useCallback(
		(values: { email: any; password: any }) => {
			dispatch(
				authenticate({
					email: values.email,
					password: values.password,
				}),
			);
		},
		[dispatch],
	);

	useEffect(() => {
		if (getCookie("lockdown")) return setDisableSignIn(true);

		const interval = setInterval(() => {
			if (getCookie("lockdown")) {
				setDisableSignIn(true);
				clearInterval(interval);
			}
		}, 1000);

		return () => {
			clearInterval(interval);
		};
	}, []);

	return (
		<LoginFormContainer>
			<div className={styles.form}>
				<div
					className={styles.title}
					// style={{
					// 	fontSize: 20,
					// 	marginBottom: 48,
					// 	fontFamily: "'Avenir-Heavy', sans-serif",
					// }}
				>
					{"Don’t have an account yet? "}
					<Link
						className={styles.underlinedTextWithPrimaryColorLink}
						to="/auth/sign-up"
					>
						Sign up
					</Link>
				</div>
				<Form className={styles.form} onFinish={handleSubmit}>
					<div style={{ marginBottom: "15px" }}>
						<FormInput
							label="Email"
							name="email"
							rules={[
								{
									type: "email",
									message:
										"Please enter your email address in the format email@example.com!",
								},
								{
									required: true,
									message: "Please enter your email address!",
								},
							]}
						>
							<Input type="text" placeholder="Enter your email" />
						</FormInput>
					</div>
					<div style={{ marginBottom: "24px" }}>
						<FormInput
							label="Password"
							name="password"
							style={{ marginBottom: "8px" }}
							hasFeedback
							rules={[
								{
									required: true,
									message: "Please input your Password!",
								},
								{
									min: 6,
									message: "Password minimum length is 6",
								},
								{
									pattern:
										/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
									message:
										"Password is too weak... Please include a mix of capitals, lower case and numbers",
								},
							]}
						>
							<Input.Password placeholder="Enter your password" />
						</FormInput>
						<Link
							className={
								styles.underlinedTextWithPrimaryColorLink
							}
							to="/auth/forgot-password"
						>
							Forgot password
						</Link>
					</div>
					<FormInput
						className={styles.formItemCenteredNoMarginBtn}
						style={{ margin: "0px" }}
					>
						<CustomButton
							disabled={disableSignIn}
							htmlType="submit"
							// loading={loading}
							buttonText="Sign in"
						/>
						{/* </Button> */}
					</FormInput>
					{/* <Link
							className={styles.underlinedTextWithPrimaryColor}
							to="/auth/forgot-password"
						>
							Forgot password
						</Link>
						<div>
							Don't have an account?{" "}
							<Link
								className={
									styles.underlinedTextWithPrimaryColor
								}
								to="/auth/sign-up"
							>
								Sign-up Now
							</Link>
							.
						</div> */}
				</Form>
			</div>
		</LoginFormContainer>
	);
};
export default LoginForm;
