import React, {
	memo,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { Button, DatePicker, Form, Input } from "antd";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import moment from "moment";
import { Mention, MentionsInput, SuggestionDataItem } from "react-mentions";
import { createSelector } from "reselect";

import styles from "./styles.module.scss";
import { TStore } from "models";
import { CheckboxGroupView } from "components";
import { UploadFile } from "./components";
import { notify, uploadDoc } from "store";
import { disabledDate, parseContentWithMentions } from "utils";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.community.mainTags,
	(state: TStore) => state.document.loading,
	(state: TStore) => state.groups.data,
	(state: TStore) => state.documents?.path,
	(state: TStore) => state.members.users,
	(state: TStore) => state.resources?.path,
	(state: TStore) => state.auth?.credentials?.user?.membership_type,
	(state: TStore) => state.app.currentGroupId,
	(
		communityTags,
		docLoading,
		groups,
		documentsPath,
		members,
		resourcesPath,
		authUserMembership,
		currentGroupId,
	) => ({
		communityTags,
		docLoading,
		groups: groups.filter((group: any) => group.is_joined),
		documentsPath,
		members,
		resourcesPath,
		authUserMembership,
		currentGroupId,
	}),
);
const DESCRIPTION_MAX_NO_OF_CHARACTERS = 5000;

interface IUploadDocForm {
	closeTheForm?: (askTheUserToConfirm?: boolean) => void;
}
const UploadDocForm: React.FunctionComponent<IUploadDocForm> = ({
	closeTheForm,
}) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const [form] = Form.useForm();
	const watch = Form.useWatch("audienceCheckboxGroup", form);

	const stateSelector = useCallback(stateSelectorHandle, []);
	const {
		communityTags,
		docLoading,
		groups,
		documentsPath,
		members,
		resourcesPath,
		authUserMembership,
		currentGroupId,
	} = useSelector(stateSelector);
	const suggestionsPortalHostRef = useRef<any>(undefined);

	useEffect(() => {
		form.setFieldValue("audienceCheckboxGroup", [authUserMembership]);
	}, [authUserMembership, form]);
	const users = useMemo(() => {
		return members?.map((item) => {
			return { id: item.id, display: item.full_name };
		});
	}, [members]);
	const [
		descriptionNoOfCharactersRemaining,
		setDescriptionNoOfCharactersRemaining,
	] = useState(DESCRIPTION_MAX_NO_OF_CHARACTERS);

	const handleChangePostBody = useCallback(
		(event: { target: { value: string } } | undefined) => {
			if (!event) return;
			let parsedBody = event.target.value;
			if (event.target.value.length > 5000) {
				parsedBody = event.target.value.substring(0, 5000);
			}
			setDescriptionNoOfCharactersRemaining(5000 - parsedBody.length);

			form.setFieldsValue({
				longDescription: parsedBody,
			});
		},
		[form],
	);

	const handleRenderSuggestions = useCallback(
		(
			suggestion: SuggestionDataItem,
			search: string,
			highlightedDisplay: React.ReactNode,
			index: number,
			focused: boolean,
		) => {
			return (
				<div
					key={`key_${index}`}
					className={classnames(styles.listItem, {
						[styles.listItemFocused]: focused,
					})}
				>
					{highlightedDisplay}
				</div>
			);
		},
		[],
	);

	const handleUploadError = useCallback(() => {
		notify(
			"error",
			"The upload failed",
			"Sorry ... we could not upload your document",
		);
	}, []);

	const handleUploadSuccess = useCallback(
		(approve?: boolean) => {
			!approve && notify("success", "File upload successful");
			if (closeTheForm) closeTheForm();
		},
		[closeTheForm],
	);

	const onValuesChange = useCallback(
		(changedValues: {
			document: any[];
			audienceCheckboxGroup: any[];
			groupsCheckboxGroup: any[];
			expirationDate: Date;
		}) => {
			switch (Object.keys(changedValues)[0]) {
				case "document": {
					if (changedValues.document.length > 1)
						form.setFieldsValue({
							document: [
								changedValues.document[
									changedValues.document.length - 1
								],
							],
						});
					break;
				}
				case "audienceCheckAll": {
					form.setFieldsValue({
						audienceCheckboxGroup: communityTags.memberTypes.map(
							(item: any) => item.name,
						),
					});
					break;
				}
				case "audienceCheckNone": {
					form.setFieldsValue({
						audienceCheckboxGroup: [authUserMembership],
					});
					break;
				}
				case "groupsCheckAll": {
					form.setFieldsValue({
						groupsCheckboxGroup: groups.map((item: any) => item.id),
					});

					break;
				}
				case "groupsCheckNone": {
					form.setFieldsValue({
						groupsCheckboxGroup: [],
					});

					break;
				}
				case "audienceCheckboxGroup": {
					// check if all checkboxes are checked
					if (
						changedValues.audienceCheckboxGroup.length ===
						communityTags.memberTypes.length
					) {
						form.setFieldsValue({
							audienceCheckAll: true,
						});
					} else {
						form.setFieldsValue({
							audienceCheckAll: false,
						});
					}
					break;
				}
				case "groupsCheckboxGroup": {
					// check if all checkboxes are checked

					if (
						changedValues.groupsCheckboxGroup.length ===
						groups.length
					) {
						form.setFieldsValue({
							groupsCheckAll: true,
						});
					} else {
						form.setFieldsValue({
							groupsCheckAll: false,
						});
					}
					break;
				}
				case "expirationDate": {
					if (moment.isMoment(changedValues.expirationDate)) {
						form.setFieldsValue({
							expirationDate:
								changedValues.expirationDate.endOf("day"),
						});
					} else {
						form.setFieldsValue({
							expirationDate: undefined,
						});
					}
					break;
				}
				default:
			}
		},
		[authUserMembership, communityTags.memberTypes, form, groups],
	);

	const onFinish = useCallback(
		(values: {
			title: string;
			document: any;
			longDescription?: string;
			expirationDate?: Date;
			audienceCheckboxGroup?: string[];
			groupsCheckboxGroup?: number[];
			tagsCheckboxGroup?: string[];
		}) => {
			const {
				audienceCheckboxGroup,
				groupsCheckboxGroup,
				tagsCheckboxGroup,
				longDescription,
			} = values;

			try {
				const { source } = parseContentWithMentions({
					content: longDescription ? longDescription : "",
					typeOfConversion: "fromInputToSystem",
				});

				dispatch(
					uploadDoc({
						...values,
						longDescription: source,
						audienceGroup: audienceCheckboxGroup,
						groupsGroup: currentGroupId
							? [currentGroupId]
							: groupsCheckboxGroup,
						tagsGroup: tagsCheckboxGroup,
						document: values.document
							? values.document[0]?.originFileObj
							: null,
						onError: handleUploadError,
						onSuccess: handleUploadSuccess,
						all_groups: currentGroupId
							? false
							: !groupsCheckboxGroup ||
							  groupsCheckboxGroup?.length === 0
							? true
							: false,
						is_private:
							!audienceCheckboxGroup ||
							audienceCheckboxGroup?.length === 0
								? false
								: true,
						parent:
							location.pathname === "/documents"
								? "Docs"
								: "Resources",
					}),
				);
			} catch (error) {
				console.log({ error });
			}
		},
		[
			currentGroupId,
			dispatch,
			handleUploadError,
			handleUploadSuccess,
			location.pathname,
		],
	);

	const initialValues = useMemo(
		() => ({
			audienceCheckAll: false,
			groupsCheckboxGroup: [],
			longDescription: "",
		}),
		[],
	);
	const folderPath = useMemo(() => {
		const defaultPath = "Home";
		if (location.pathname === "/documents" && documentsPath.length > 0) {
			return documentsPath.slice(-1).shift()?.name ?? defaultPath;
		} else if (
			location.pathname === "/resources" &&
			resourcesPath.length > 0
		) {
			return resourcesPath.slice(-1).shift()?.name ?? defaultPath;
		} else {
			return defaultPath;
		}
	}, [documentsPath, location.pathname, resourcesPath]);

	const handleBackToTheDocs = useCallback(() => {
		if (closeTheForm) closeTheForm(true);
	}, [closeTheForm]);

	return (
		<div className={styles.container}>
			<div className={styles.titleContainer}>
				<div className={styles.backBackButtonContainer}>
					<div onClick={handleBackToTheDocs}>
						<FontAwesomeIcon
							icon={faArrowLeft as unknown as IconProp}
							style={{
								cursor: "pointer",
								fontSize: "20px",
							}}
						/>
						<span>{"Back"}</span>
					</div>
				</div>
				<div className={styles.centeredText}>
					<span className={styles.title}>{"Current folder:"}</span>
					<span className={styles.name}>{folderPath}</span>
				</div>
			</div>
			<Form
				name="upload-doc-form"
				form={form}
				layout="vertical"
				initialValues={initialValues}
				onFinish={onFinish}
				onValuesChange={onValuesChange}
			>
				<Form.Item
					name="title"
					rules={[
						{
							required: true,
							message: "'Title' is required",
						},
					]}
				>
					<div className={styles.input}>
						<Input
							type={"text"}
							placeholder={"What is the name of your document?"}
						/>
					</div>
				</Form.Item>
				<Form.Item name="longDescription">
					<MentionsInput
						onChange={handleChangePostBody}
						placeholder={"Short description"}
						className={"detailsInput"}
						classNames={styles}
						suggestionsPortalHost={suggestionsPortalHostRef.current}
						maxLength={5000}
						style={{
							suggestions: {
								list: {
									maxHeight: 300,
									overflowY: "auto",
								},
							},
						}}
					>
						<Mention
							trigger="@"
							data={users ? users : []}
							className={styles.mention}
							renderSuggestion={handleRenderSuggestions}
							appendSpaceOnAdd={true}
							displayTransform={(_, display: any) =>
								`@${display}`
							}
						/>
					</MentionsInput>
				</Form.Item>
				<div
					ref={suggestionsPortalHostRef}
					className={styles.divPositionRelativeWrapper}
				/>
				<div className={styles.noOfCharactersRemaining}>
					{!(descriptionNoOfCharactersRemaining === 0)
						? `${descriptionNoOfCharactersRemaining} ${
								descriptionNoOfCharactersRemaining > 1
									? "characters"
									: "character"
						  }  remaining`
						: "Limit reached"}
				</div>

				<UploadFile />
				{communityTags.memberTypes &&
					communityTags.memberTypes.length > 0 && (
						<>
							<div className={styles.tags}>
								<CheckboxGroupView
									title={"AUDIENCE"}
									checkAllTitle={"All Members"}
									category="audience"
									options={communityTags.memberTypes.map(
										(item: any) => item.name,
									)}
									checkedValues={watch}
									disabled={authUserMembership}
									setValue={form.setFieldValue}
								/>
							</div>
						</>
					)}
				{communityTags.groups &&
					communityTags.groups.length > 0 &&
					!currentGroupId && (
						<div className={styles.tags}>
							<CheckboxGroupView
								title={"GROUPS"}
								checkAllTitle={"All Groups"}
								category="groups"
								options={communityTags.groups.map(
									(item: any) => ({
										label: item.name,
										value: item.id,
									}),
								)}
							/>
						</div>
					)}
				{communityTags.tags && communityTags.tags.length > 0 && (
					<>
						<div className={styles.tags}>
							<CheckboxGroupView
								checkAllTitle={""}
								title={"TAGS"}
								category="tags"
								options={(
									communityTags.tags as {
										id: number;
										name: string;
										is_selected: boolean;
									}[]
								).map((item: any) => item.name)}
							/>
						</div>
					</>
				)}
				<div className={styles.datepicker}>
					<Form.Item
						name={"expirationDate"}
						label={"Choose the expiration date"}
					>
						<DatePicker
							placeholder={"Select"}
							size={"middle"}
							disabledDate={disabledDate}
						/>
					</Form.Item>
				</div>
				<Form.Item>
					<div className={styles.primaryButton}>
						<Button
							type="primary"
							htmlType="submit"
							className={styles.right}
							loading={docLoading}
							disabled={docLoading}
						>
							{"Post"}
						</Button>
					</div>
				</Form.Item>
			</Form>
		</div>
	);
};

export default memo(UploadDocForm);
