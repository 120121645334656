import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

import styles from "./styles.module.scss";
import { IGroup, TStore } from "models";
import { appSetGroup, appSiderMenuSetCollapsed } from "store";
import { GetImg, whitelistSrc } from "utils";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ReactComponent as Close } from "assets/images/icons/menu_icons/close.svg";
import { ReactComponent as LeftArrow } from "assets/images/icons/menu_icons/arrow-left.svg";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.app.currentGroupId,
	(state: TStore) => state.groups,
	(state: TStore) => state.community,
	(currentGroupId, groups, community) => ({
		currentGroupId,
		groups,
		community,
	}),
);

const DescriptionElement: React.FunctionComponent<{
	description: string;
}> = ({ description }) => {
	return (
		<div className={styles.groupDescriptionContainer}>
			<div className={styles.title}>{"Description:"}</div>
			<div>{description}</div>
		</div>
	);
};

const GroupSection: React.FunctionComponent<any> = ({
	handleTriggerTheDrawerMenu,
	absoluteSideBar,
}) => {
	const [, setZoomLevel] = useState<number>(
		((window.outerWidth - 10) / window.innerWidth) * 100,
	);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { currentGroupId, groups, community } = useSelector(stateSelector);

	const group: IGroup | undefined = useMemo(() => {
		if (!currentGroupId && !groups) {
			return undefined;
		} else {
			const group = groups.data.find(
				(item) => item.id === currentGroupId,
			);
			return group ? group : undefined;
		}
	}, [currentGroupId, groups]);

	const handleBackToGroups = useCallback(() => {
		navigate("/groups");
		dispatch(appSetGroup({ currentGroupId: null }));
	}, [dispatch, navigate]);

	const handleResize = useCallback(() => {
		let zoom = ((window.outerWidth - 10) / window.innerWidth) * 100;
		setZoomLevel(zoom);
	}, []);

	useEffect(() => {
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", () => null);
		};
	}, [handleResize]);

	return (
		<Tooltip
			title={
				group?.description ? (
					<DescriptionElement description={group.description} />
				) : null
			}
			placement={"right"}
		>
			{group ? (
				<div className={styles.container}>
					{!absoluteSideBar && (
						<>
							<div className={styles.closeButtonContainerMobile}>
								{
									<Close
										onClick={() => {
											dispatch(
												appSiderMenuSetCollapsed(true),
											);
											// handleTriggerTheDrawerMenu();
										}}
										style={{
											fontFamily: `"Avenir Heavy", sans-serif`,
										}}
									/>
								}
							</div>
						</>
					)}
					<div
						className={
							absoluteSideBar
								? styles.absoluteCloseButtonContainer
								: styles.closeButtonContainer
						}
					>
						{!absoluteSideBar ? (
							<Close onClick={handleTriggerTheDrawerMenu} />
						) : (
							<LeftArrow onClick={handleTriggerTheDrawerMenu} />
						)}
					</div>
					{community?.data && (
						<div
							className={styles.logoSection}
							onClick={handleBackToGroups}
						>
							<FontAwesomeIcon
								icon={faChevronLeft as unknown as IconProp}
								style={{
									height: 14,
									widows: 6,
									marginRight: 7,
								}}
							/>

							{whitelistSrc.indexOf(
								community.data && community.data.picture,
							) === -1 ? (
								<GetImg
									endpoint={
										community.data && community.data.picture
									}
								/>
							) : (
								<img
									src={
										community.data && community.data.picture
									}
									alt=""
								/>
							)}
							{!absoluteSideBar && (
								<span>{community.data.name}</span>
							)}
						</div>
					)}
					{/* <Divider /> */}
				</div>
			) : null}
		</Tooltip>
	);
};

export default memo(GroupSection);
