import { useCallback } from "react";
import { createSelector } from "reselect";

import { TStore } from "models";
import { useSelector } from "react-redux";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.communitiesJoined.data,
	(state: TStore) => state.communities.meta.invites,
	(state: TStore) => state.auth.deepLink,
	(joinedCommunities, invites, deepLink) => ({
		joinedCommunities,
		invites,
		deepLink,
	}),
);

export const useCheckInvitesAndJoinedCommunities = (): {
	joinedCommunities?: boolean;
	invites?: boolean;
} => {
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { joinedCommunities, invites, deepLink } = useSelector(stateSelector);

	return {
		joinedCommunities: joinedCommunities
			? joinedCommunities.length > 0
			: undefined, // undefined -> the joined communities hasn't been fetched from server
		invites: invites ? invites.length > 0 || deepLink : undefined, // undefined -> the invites hasn't been fetched from server
	};
};
