// import { IResource } from "models";
import { IFolder } from "components";
import { IDoc } from "models/doc";
import { EGroupPrivacy } from "models/groups";
import { IVideo } from "models/video";
import {
	IResourcesLoadingSetAction,
	IResourcesGetAction,
	IResourcesSetAction,
	IResourcesFilterTagsSetAction,
	IResourcesFilterTagsSetInStoreAction,
	ISetResourcesQueryAction,
	IIncrementResourceViewsAction,
	IUpdateResourcesCurrentFolderAction,
	IResourcesSetOrderAction,
	IResourcesSetPathAction,
	ISetResourcesRedirectQuery
} from "./actions";

// ENUMS
export enum EResourcesActionTypes {
	resourcesLoadingSet = "resources/set/loading",
	getResources = "resources/get",
	setResources = "resources/set",
	setFilterTags = "resources/filter/tags/set",
	setFilterTagsInStore = "resources/filter/tags/set/inStore",
	setQueryAndFilter = "resources/query/setAndFilter",
	setResourcesQuery = "resources/query/set",
	setResourcesCurrentFolder = "resources/currentFolder/set",
	updateResourcesCurrentFolder = "resources/currentFolder/update",
	incrementResourceViews = "resources/increment/views",
	updateResourcesOrder = "resources/order/update",
	setResourcesOrder = "resources/order/set",
	setPath = "resources/path/set",
	deleteResources = "resources/delete",
	setResourcesRedirectQuery = "resources/redirect/query"
}

// INTERFACES
export interface IResourcesState {
	loading: boolean;
	path: IFolder[];
	data?: (IVideo | IDoc)[];
	selectedTags: string[];
	query: string;
	redirectQuery?: string | null;
	currentFolder: undefined | number;
	order: "view_count" | "created_at" | "title" | "latest";
	order_dir: "asc" | "desc";
	meta: {
		pagination: {
			current_page: number;
			per_page: number;
			total_pages: number;
			total_objects: number;
			has_next_page: boolean;
		};
		scope_group: {
			id: number;
			name: string;
			description: string;
			privacy: EGroupPrivacy;
		};
		folders:
		| null
		| {
			children: any[];
			id: number;
			name: string;
			path: string;
		}[];
	};
}

// TYPES
export type TResourcesActions =
	| IResourcesSetAction
	| IResourcesLoadingSetAction
	| IResourcesGetAction
	| IResourcesFilterTagsSetAction
	| IResourcesFilterTagsSetInStoreAction
	| ISetResourcesQueryAction
	| IUpdateResourcesCurrentFolderAction
	| IIncrementResourceViewsAction
	| IResourcesSetOrderAction
	| ISetResourcesRedirectQuery
	| IResourcesSetPathAction;
