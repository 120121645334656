import React, { memo } from "react";
import { Form, Checkbox } from "antd";
import classNames from "classnames";

import styles from "./styles.module.scss";
import { NamePath } from "antd/lib/form/interface";

interface ICheckboxGroup {
	checkAllTitle: string;
	category: string;
	options: any[];
	rules?: {}[];
	title?: string;
	description?: any;
	disabled?: string;
	checkedValues?: string[];
	setValue?: (name: NamePath, value: any) => void;
}

const CheckboxGroup = Checkbox.Group;

const CheckboxGroupView: React.FunctionComponent<ICheckboxGroup> = (props) => {
	const {
		checkAllTitle,
		category,
		options,
		rules,
		title,
		description,
		disabled,
		checkedValues,
		setValue,
	} = props;
	return (
		<>
			<>
				<div className={styles.headerTags}>
					{title && (
						<div
							className={classNames(styles.tagTitle, {
								[styles.required]: rules,
							})}
						>
							{title}
						</div>
					)}

					{checkAllTitle && options.length > 1 && (
						<div className={styles.allNoneContainer}>
							<Form.Item
								name={`${category}CheckAll`}
								valuePropName="checked"
							>
								<Checkbox
									defaultChecked={false}
									className={styles.checkBoxGroupGhost}
								>
									{"Select All"}
								</Checkbox>
							</Form.Item>
							<div style={{ color: "#aaa" }}>|</div>
							<Form.Item
								name={`${category}CheckNone`}
								valuePropName="checked"
							>
								<Checkbox
									defaultChecked={false}
									className={styles.checkBoxGroupGhost}
								>
									{"Clear"}
								</Checkbox>
							</Form.Item>
						</div>
					)}
				</div>
			</>
			{description}
			{category === "audience" ? (
				<Form.Item name={`${category}CheckboxGroup`} rules={rules}>
					{options.length > 0 &&
						options.map((opt: string) => {
							
							
							return (
								<Checkbox
									className={classNames(
										styles.checkBoxGroup,
										styles.accents,
									)}
									
									checked={
										checkedValues &&
										checkedValues.length > 0 &&
										checkedValues?.indexOf(opt) !== -1
									}
								
									disabled={opt === disabled}
									onChange={(event) => {
										
										if (setValue) {
											setValue(
												"audienceCheckboxGroup",
												event.target.checked
													? checkedValues?.concat(opt)
													: checkedValues?.filter(
															(e: string) =>
																e !== opt,
													  ),
											);
										}
									}}
								>
									{opt}
								</Checkbox>
							);
						})}
				</Form.Item>
			) : (
				<Form.Item name={`${category}CheckboxGroup`} rules={rules}>
					<CheckboxGroup
						className={classNames(
							styles.checkBoxGroup,
							styles.accents,
						)}
						options={options}
						onChange={(event) => {

							if (setValue) {
								setValue(category,event)
							}
						}}
					/>
				</Form.Item>
			)}
		</>
	);
};

export default memo(CheckboxGroupView);
