import React, { memo, useCallback, useMemo, useState } from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import {
	joinCommunity,
	appChangeCommunity,
	paymentsCreateMemberSubscription,
	notify,
	startTheJoinCommunityProcessAction,
	leaveCommunity,
} from "store";
import {
	isItAWhiteLabel,
	UseShowDescriptionWithMentions,
	useCheckForCommunityApproval,
} from "utils";
import {
	ECommunityAccessStatus,
	ICommunity,
	ETypeOfCommunities,
	EProcessToJoinCommunityStage,
} from "models";
import { ReactComponent as UsersIcon } from "assets/images/communityUsers.svg";
import { ReactComponent as RequestedIcon } from "assets/images/requested.svg";
import { ReactComponent as JoinGroup } from "assets/images/icons/common_icons/join_group.svg";
import { ReactComponent as LeaveGroup } from "assets/images/icons/common_icons/leave.svg";
import ConfirmationModal from "components/ConfirmationModal";

import CustomButton from "components/Button";

interface ICommunityStatus {
	community: ICommunity;
	sourceOfCommunitiesDataList: ETypeOfCommunities;
}

const CommunityStatus = memo(
	({ community, sourceOfCommunitiesDataList }: ICommunityStatus) => {
		const navigate = useNavigate();
		const {
			id,
			joined,
			privacy,
			access_status,
			selected_member_plan,
			name,
		} = community;
		const dispatch = useDispatch();
		const [loadingCreateSubscription, setLoadingCreateSubscription] =
			useState(false);

		const { loading, isApproved } = useCheckForCommunityApproval({
			joined: joined,
			id: id,
			privacy: privacy,
		});

		const goToCommunity = useCallback(() => {
			dispatch(
				appChangeCommunity({
					communityId: id,
				}),
			);
		}, [dispatch, id]);

		const handleResumePaymentProcess = useCallback(() => {
			if (!id) {
				console.error("Missing data!");
				return;
			}
			dispatch(startTheJoinCommunityProcessAction(true));
			navigate(
				`/communities/${id}/${EProcessToJoinCommunityStage["resume payment process"]}`,
			);
		}, [dispatch, navigate, id]);

		const handleJoin = useCallback(() => {
			dispatch(joinCommunity({ id }));
		}, [dispatch, id]);

		const createSubscriptionForFreePlanInClosedCommunities =
			useCallback(() => {
				if (selected_member_plan?.price?.stripe_id && id) {
					setLoadingCreateSubscription(true);
					dispatch(
						paymentsCreateMemberSubscription({
							stripePlanPriceId:
								selected_member_plan?.price?.stripe_id,
							communityId: id,
							onSuccessCallback: () => {
								dispatch(
									appChangeCommunity({ communityId: id }),
								);
							},
							onErrorCallback: () => {
								setLoadingCreateSubscription(false);
								notify(
									"error",
									"Something went wrong.",
									"Please try again later.",
								);
								if (isItAWhiteLabel()) {
									navigate("/joining/init", {
										state: {
											error: true,
										},
									});
								} else {
									navigate("/communities");
								}
							},
						}),
					);
				} else {
					notify(
						"error",
						"Something went wrong.",
						"Please try again later.",
					);
				}
			}, [
				dispatch,
				navigate,
				id,
				selected_member_plan?.price?.stripe_id,
			]);

		const handleLeave = useCallback(() => {
			if (!id) {
				notify("error", "An error occurred. Please try again later.");
				return;
			}
			dispatch(
				leaveCommunity({
					communityId: id,
					// onSuccessCallback: () => {
					// 	settingSelectedCommunityToJoinColors();
					// 	navigate("/communities");
					// },
					onErrorCallback: () => {
						notify(
							"error",
							"An error occurred. Please try again later.",
						);
					},
				}),
			);
		}, [id, dispatch]);

		return (
			<>
				<div className={classNames(styles.buttonContainer)}>
					{!loading && joined && (
						<>
							{(access_status ===
								ECommunityAccessStatus.granted ||
								access_status ===
									ECommunityAccessStatus.closed_community) && (
								<>
									{(privacy === "open" || isApproved) && (
										<ConfirmationModal
											communityName={name}
											trigger={
												<CustomButton
													varient="gray"
													className={
														styles.leaveButton
													}
												>
													<LeaveGroup
														className={
															styles.leaveIcon
														}
													/>
												</CustomButton>
											}
											onConfirm={handleLeave}
											action="leave"
										/>
									)}
									{privacy === "open" || isApproved ? (
										<CustomButton
											varient="outline"
											style={{
												fontSize: "14px",
												width: "96px",
												padding: "0px",
											}}
											onClick={goToCommunity}
											disabled={
												privacy !== "open" &&
												!isApproved
											}
										>
											<JoinGroup fill={"currentColor"} />
											Enter
										</CustomButton>
									) : (
										<div
											className={styles.joinCancelButtons}
										>
											<div
												className={styles.joinedButton}
											>
												<CustomButton disabled>
													<RequestedIcon />
													Requested
												</CustomButton>
											</div>
											<div
												className={styles.cancelButton}
											>
												<CustomButton
													varient="denger"
													onClick={() =>
														handleLeave()
													}
												>
													<CloseOutlined />
													Cancel request
												</CustomButton>
											</div>
										</div>
									)}
								</>
							)}
							{access_status ===
								ECommunityAccessStatus.subscription_pending && (
								<CustomButton disabled={true}>
									{"Pending payment"}
								</CustomButton>
							)}

							{access_status ===
							ECommunityAccessStatus.subscription_inactive ? (
								privacy === "close" &&
								selected_member_plan?.free ? (
									<CustomButton
										varient="outline"
										style={{ fontSize: "14px" }}
										onClick={
											createSubscriptionForFreePlanInClosedCommunities
										}
										loading={loadingCreateSubscription}
									>
										{"Enter"}
									</CustomButton>
								) : (
									<CustomButton
										varient="outline"
										style={{ fontSize: "14px" }}
										onClick={handleResumePaymentProcess}
									>
										{"Add payment info"}
									</CustomButton>
								)
							) : null}
						</>
					)}
					{!joined &&
						sourceOfCommunitiesDataList !==
							ETypeOfCommunities.Invites && (
							<CustomButton
								onClick={handleJoin}
								varient="outline"
								style={{ fontSize: "14px" }}
							>
								<JoinGroup fill={"currentColor"} />
								{"Join"}
							</CustomButton>
						)}

					{sourceOfCommunitiesDataList ===
						ETypeOfCommunities.Invites && (
						<>
							<CustomButton
								varient="outline"
								style={{ fontSize: "14px" }}
								onClick={handleJoin}
							>
								<CheckOutlined />
								{"Accept"}
							</CustomButton>
						</>
					)}
				</div>
			</>
		);
	},
);

interface ICommunityBox {
	data: ICommunity;
	active?: boolean;
	sourceOfCommunitiesDataList: ETypeOfCommunities;
}

const CommunityBox = (props: ICommunityBox) => {
	const { data, active, sourceOfCommunitiesDataList } = props;

	const companyAvatar = useMemo((): {
		image: string;
		companyNameInitial: string;
	} => {
		if (!data.picture || !data.name)
			return { image: "", companyNameInitial: "" };
		if (data.picture.indexOf("profile-fallback-thumb") !== -1) {
			return {
				image: "",
				companyNameInitial: data.name.slice(0, 1).toUpperCase(),
			};
		} else {
			return {
				image: data.picture,
				companyNameInitial: "",
			};
		}
	}, [data]);

	return (
		<div
			className={classNames(styles.communityBox, active && styles.active)}
		>
			<div className={styles.flexOneBox}>
				<div className={styles.imageTextWrapper}>
					<div className={styles.imageHolder}>
						{companyAvatar.image ? (
							<img src={data.picture} alt="community-logo" />
						) : (
							<div className={styles.textAvatar}>
								{companyAvatar.companyNameInitial}
							</div>
						)}
					</div>
					<div className={styles.communityInfo}>
						<p>{data.name}</p>
						<div className={styles.userIcon}>
							<UsersIcon />
							<span className={styles.count}>
								{data?.member_count ?? "0"}
							</span>
						</div>
						{data.about && (
							<div className={styles.detail}>
								<UseShowDescriptionWithMentions
									text={data?.about}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
			<CommunityStatus
				community={data}
				sourceOfCommunitiesDataList={sourceOfCommunitiesDataList}
			/>
		</div>
	);
};

export default memo(CommunityBox);
