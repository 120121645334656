import { memo, useCallback, useEffect } from "react";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import { matchPath, useNavigate, useLocation } from "react-router-dom";

import styles from "./styles.module.scss";
import { joinGroup, cancelJoinGroupRequest, appSetGroup } from "store";
import { EGroupPrivacy } from "models";
import { LeaveMenu } from "./components";
import { ReactComponent as JoinGroup } from "assets/images/icons/common_icons/join_group.svg";
import { ReactComponent as ClosedLock } from "assets/images/icons/common_icons/closed_lock.svg";
import { ReactComponent as OpenLock } from "assets/images/icons/common_icons/open_lock.svg";
import { ReactComponent as Members } from "assets/images/icons/common_icons/members_group.svg";
import { ReactComponent as Cancel } from "assets/images/icons/common_icons/cancel.svg";
import {  UseShowDescriptionWithMentions, useParseCommunityTags } from "utils";
import { ReactComponent as RequestedIcon } from "assets/images/requested.svg";

const GroupCard = (props: any) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const renderedFromCommunitiesScreen = matchPath(
		"/communities/*",
		location.pathname,
	);
	const renderedFromJoiningScreen = matchPath(
		"/joining/*",
		location.pathname,
	);

	const renderedFromRegistrationProcessScreen =
		renderedFromCommunitiesScreen || renderedFromJoiningScreen;

	const {
		data: {
			id,
			name,
			description,
			is_joined,
			privacy,
			number_of_members,
			join_request_status,
			membership_id,
			request_id,
			avatar,
			tags,
		},
		currentCommunityId,
		style = null,
	} = props;
	const { parsedTags } = useParseCommunityTags({ tags });

	const handleJoinGroup = useCallback(
		(groupId: number) => {
			dispatch(
				joinGroup({
					communityId: currentCommunityId,
					groupId,
				}),
			);
		},
		[dispatch, currentCommunityId],
	);

	const handleCancelJoinGroupRequest = useCallback(
		(groupId: number, requestId: number) => {
			dispatch(
				cancelJoinGroupRequest({
					communityId: currentCommunityId,
					groupId,
					requestId,
				}),
			);
		},
		[dispatch, currentCommunityId],
	);
	

	const handleSelectGroup = useCallback(() => {
		dispatch(appSetGroup({ currentGroupId: id }));
		navigate("/dashboard");
	}, [dispatch, navigate, id]);

	useEffect(() => {
		if (renderedFromRegistrationProcessScreen) {
			const selectEnterButton = document.getElementById(
				"enter-dropdown-left-button",
			);

			selectEnterButton?.setAttribute("disabled", "disabled");
			return () => {
				selectEnterButton?.removeAttribute("disabled");
			};
		}
	}, [renderedFromRegistrationProcessScreen]);

	return (
		<div className={styles.groupBox} style={style} id="groups">
			<div className={styles.groupContainer}>
				{avatar ?
				<img className={styles.avatar}  alt={avatar}  src={avatar}/>
				:<div className={styles.avatar} >
				{name[0].toUpperCase()}
					</div>}
			
				<div className={styles.groupInfo}>
					<div className={styles.content}>
						<div className={styles.titleDetails}>
							<div className={styles.titleWrap}>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										alignItems: "flex-start",
									}}
								>
									<span className={styles.title}>{name}</span>
									{!!number_of_members && (
										<span className={styles.subtitle}>
											<Members />
											{` ${number_of_members} 
                    `}
										</span>
									)}
								</div>
							</div>
							<div
								className={styles.groupStatus}
								style={{
									color:
										privacy === EGroupPrivacy.open
											? "#2EAB58"
											: "#D82140",
								}}
							>
								{privacy === EGroupPrivacy.open ? (
									<OpenLock />
								) : (
									<ClosedLock />
								)}
								{privacy &&
									`${privacy
										.charAt(0)
										.toUpperCase()}${privacy.slice(
										1,
									)} group`}
							</div>
						</div>
					
					</div>
					<div className={styles.description}>
						<UseShowDescriptionWithMentions
							text={description}
							words={20}
						/>
						{parsedTags?.length > 0 && (
					<div className={styles.tagsContainer}>
						{parsedTags.map((item: any, index: number) => {
							return (
								<small
									key={`tag_${index}_${item}`}
									className={styles.tag}
									// onClick={(event) =>
									// 	handleTagFiltering(event, item)
									// }
								>
									{item}
								</small>
							);
						})}
					</div>
				)}
							<div className={styles.buttonsSection}>
							<div className={styles.rightButtons} >
								{!is_joined && join_request_status === "none" && (
									<div className={privacy === EGroupPrivacy.open?styles.joinButton:styles.requestToJoin} style={{display:'block'}} >
										<Button
											onClick={() => handleJoinGroup(id)}
										>
											{privacy === EGroupPrivacy.open ?<JoinGroup fill={"currentColor"} />:<RequestedIcon className={styles.requestSvg}  />}
											{privacy === EGroupPrivacy.open
												? "Join"
												: "Request to join"}
										</Button>
									</div>
								)}

								{is_joined && (
									<div className={styles.enterButton}>
										<LeaveMenu
											communityId={currentCommunityId}
											privacy={privacy}
											groupId={id}
											membershipId={membership_id}
										/>
										<Button
											onClick={() => handleSelectGroup()}
										>
											<JoinGroup fill={"currentColor"} />
											{"Enter"}
										</Button>
									</div>
								)}
								{!is_joined &&
									join_request_status === "pending" &&
									!!request_id && (
										<div className={styles.cancelButton}>
											<Button
												onClick={() =>
													handleCancelJoinGroupRequest(
														id,
														request_id,
													)
												}
											>
												<Cancel />
												{" Cancel request"}
											</Button>
										</div>
									)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default memo(GroupCard);
