import React, {
	ChangeEvent,
	memo,
	useCallback,
	useEffect,
	useState,
} from "react";
import { Input } from "antd";
import { useDispatch } from "react-redux";

import styles from "./styles.module.scss";
import { useDebounce } from "utils";
import {
	getCommunities,
	getCommunitiesJoined,
	setCommunitiesJoinedSearchQuery,
} from "store";
import { ETypeOfCommunities } from "models";
import { ReactComponent as SearchIcon } from "assets/images/search.svg";

interface ICommunitySearch {
	communitiesCategory: ETypeOfCommunities;
}

const CommunitySearch: React.FunctionComponent<ICommunitySearch> = ({
	communitiesCategory,
}) => {
	const dispatch = useDispatch();
	const [inputValue, setInputValue] = useState("");
	const debouncedInputValue = useDebounce(inputValue, 500);

	const handleSearchInput = (event: ChangeEvent<HTMLInputElement>) => {
		setInputValue(event.currentTarget.value);
	};

	const getAllCommunitiesData = useCallback(() => {
		dispatch(getCommunities({ q: debouncedInputValue }));
	}, [dispatch, debouncedInputValue]);

	const getJoinedCommunitiesData = useCallback(() => {
		dispatch(getCommunitiesJoined({ q: debouncedInputValue }));
		dispatch(setCommunitiesJoinedSearchQuery({ q: debouncedInputValue }));
	}, [dispatch, debouncedInputValue]);

	useEffect(() => {
		if (debouncedInputValue === "" || debouncedInputValue) {
			if (communitiesCategory === ETypeOfCommunities.All) {
				getAllCommunitiesData();
			} else if (communitiesCategory === ETypeOfCommunities.Joined) {
				getJoinedCommunitiesData();
			}
		}
	}, [
		communitiesCategory,
		debouncedInputValue,
		dispatch,
		getAllCommunitiesData,
		getJoinedCommunitiesData,
	]);

	useEffect(() => {
		if (communitiesCategory === ETypeOfCommunities.All) {
			dispatch(getCommunities({ q: "" }));
		} else if (communitiesCategory === ETypeOfCommunities.Joined) {
			dispatch(setCommunitiesJoinedSearchQuery({ q: "" }));
		}
		// when communities category is changed the input value is resetted
		setInputValue("");
	}, [communitiesCategory, dispatch]);

	return (
		<div className={styles.container}>
			<Input
				className={styles.input}
				value={inputValue}
				placeholder="Search communities"
				onChange={handleSearchInput}
				suffix={
			
					<SearchIcon fill="#cdcdcd" width="14px" height="14px" />
				}
			/>
				{/* <Select 
				className={styles.select}
				/>
				<Select 
				className={styles.select}
				/>
				<Select 
				className={styles.select}
				/>
				<Select 
				className={styles.select}
				/> */}
		</div>
	);
};

export default memo(CommunitySearch);
