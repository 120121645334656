import { IPostState, EPostActionTypes, TPostActions } from "models";

const initial: IPostState = {
	loading: false,
	data: undefined,
};

export function postReducer(state = initial, action: TPostActions): IPostState {
	switch (action.type) {
		case EPostActionTypes.postLoadingSet: {
			return {
				...state,
				loading: action.payload,
			};
		}
		case EPostActionTypes.postSet: {
			return {
				...state,
				data: action.payload,
			};
		}
		default:
			return state;
	}
}
