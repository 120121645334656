import React, {
	memo,
	useCallback,
	useEffect,
	useMemo,
	useState,
	useRef,
} from "react";
import { Row, Col, Menu } from "antd";
// import GoogleMapReact from "google-map-react";
// import { Map, GoogleApiWrapper } from "google-maps-react";
import { MainLayout } from "layouts";
import {
	HelmetPageTitle,
	// ScreenNameBar,
	RelatedEventsBox,
} from "components";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import linkifyStr from "linkifyjs/string";
import DOMPurify from "dompurify";
import {
	google,
	outlook,
	office365,
	yahoo,
	ics,
	CalendarEvent as ICalendarEvent,
} from "calendar-link";

import { ReactComponent as Location } from "assets/images/location.svg";

import avatarSm1 from "assets/images/avatar_sm_1.jpg";
import styles from "./styles.module.scss";
import { IEvent, TStore } from "models";
import { Loading } from "components";
import { getEvent, getEvents, notify } from "store";
import { ReactComponent as CheckmarkIcon } from "assets/images/icons/common_icons/checkmark_icon.svg";
import ListOfEvent from "screens/calendar/components/listOfEvent";
import { ReactComponent as Back } from "assets/images/memberBack.svg";
const stateSelectorHandle = createSelector(
	(state: TStore) => state.calendarEventData,
	(state: TStore) => state.events,
	(state: TStore) => state.app.currentCommunityId,
	(state: TStore) => state.app.currentGroupId,
	(calendarEventData, events, currentCommunityId, currentGroupId) => ({
		calendarEventData,
		events,
		currentCommunityId,
		currentGroupId,
	}),
);

interface ICalendarEventComponent {
	calendarEventDataIsAvailable?: boolean;
}

const CalendarEvent: React.FC<ICalendarEventComponent> = ({
	calendarEventDataIsAvailable = false,
}) => {
	const dispatch = useDispatch();
	const { id }: { id?: string } = useParams();
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { calendarEventData, events, currentCommunityId, currentGroupId } =
		useSelector(stateSelector);
	const [getEventLoading, setGetEventLoading] = useState(true);
	const navigate = useNavigate();
	const getEventsDataRef = useRef(false);

	const calendarEventDataForLink = useMemo(() => {
		if (calendarEventData?.data) {
			const ev: IEvent = calendarEventData.data!;

			if (ev) {
				const { title, description, location, ends_at, starts_at } = ev;
				let duration: number =
					moment
						.duration(moment(ends_at).diff(moment(starts_at)))
						.asHours() < 24
						? moment
								.duration(
									moment(ends_at).diff(moment(starts_at)),
								)
								.asHours()
						: moment
								.duration(
									moment(ends_at).diff(moment(starts_at)),
								)
								.asDays();
				let durationUnit: "day" | "hour" =
					moment
						.duration(moment(ends_at).diff(moment(starts_at)))
						.asHours() < 24
						? "hour"
						: "day";
				return {
					title,
					description,
					location,
					start: moment(starts_at).format("YYYY-MM-DDTHH:mm:ss"),
					end: moment(ends_at).format("YYYY-MM-DDTHH:mm:ss"),
					duration: [Math.round(duration), durationUnit],
				} as ICalendarEvent;
			}
		}
	}, [calendarEventData]);

	const currentEvent: IEvent | null = useMemo(() => {
		if (!calendarEventData?.data) return null;
		const result: IEvent = calendarEventData.data;
		const ev = result;
		if (ev) {
			const startDatetime = moment(ev.starts_at);
			const endDatetime = moment(ev.ends_at);
			ev.duration = moment
				.duration(endDatetime.diff(startDatetime))
				.asHours();
			ev.endDatetime = endDatetime.format("YYYYMMDDTHHmmssZ");
			ev.startDatetime = startDatetime.format("YYYYMMDDTHHmmssZ");
		}
		return ev;
	}, [calendarEventData]);

	const relatedEvents = useMemo(() => {
		if (!currentEvent || events?.data?.length === 0) return [];
		// get events that have at least one tag that is found in the tag list of current event
		const relatedEvents = events?.data?.filter((item) => {
			if (
				moment().isSameOrBefore(item.ends_at) &&
				item.id !== currentEvent.id
			) {
				return true;
			} else return false;
		});
		return relatedEvents;
	}, [events, currentEvent]);

	const clean = useMemo(
		() =>
			currentEvent
				? DOMPurify.sanitize(currentEvent.description, {
						ALLOWED_TAGS: ["a"],
						ALLOWED_ATTR: ["href"], // TODO: for security purposes I need to allow just a specific href structure
				  })
				: "",
		[currentEvent],
	);

	// const shouldShowSidebar = useMemo(
	// 	() =>
	// 		currentEvent
	// 			? currentEvent.location ||
	// 			(currentEvent.latitude && currentEvent.longitude)
	// 			: false,
	// 	[currentEvent],
	// );

	const handleOnSelectEvent = useCallback(
		(event: IEvent) => {
			if (!event) {
				notify("error", "An error occurred. Please try again later.");
				return;
			}
			dispatch(getEvent({ id: event.id }));
			navigate(`/calendar/events/${event.id}`);
		},
		[dispatch, navigate],
	);

	const handleAddToCalendar = (item: any) => {
		const path = calendarItems.find((i) => i.key === item.key)?.path;
		if (path?.startsWith("BEGIN")) {
			const blob = new Blob([path], {
				type: "text/calendar;charset=utf-8",
			});
			const link = document.createElement("a");
			link.href = window.URL.createObjectURL(blob);
			link.setAttribute("download", `CalendarEvent.ics`);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} else {
			window.open(path, "_blank");
		}
	};

	const goBack = () => {
		navigate(-1);
	};

	let calendarItems = calendarEventDataForLink
		? [
				{ key: "Google", path: google(calendarEventDataForLink) },
				{
					key: "Apple",
					path: decodeURIComponent(
						ics(calendarEventDataForLink).split("charset=utf8,")[1],
					),
				},
				{ key: "Yahoo!", path: yahoo(calendarEventDataForLink) },

				{
					key: "Outlook - Web",
					path: outlook(calendarEventDataForLink),
				},
				{
					key: "Outlook - Desktop",
					path: decodeURIComponent(
						ics(calendarEventDataForLink).split("charset=utf8,")[1],
					),
				},
				{
					key: "Office365",
					path: office365(calendarEventDataForLink),
				},
		  ]
		: [];

	useEffect(() => {
		if (!calendarEventDataIsAvailable) return;
		//**
		/* If the component is rendered through the notification flow
		/* the notification data is already saved in the global store  
		*/
		setGetEventLoading(false);
	}, [calendarEventDataIsAvailable]);

	useEffect(() => {
		if (!id || !getEventLoading) return;
		dispatch(
			getEvent({
				id: parseInt(id),
				callbackOnSuccess: () => {
					setGetEventLoading(false);
				},
				callbackOnError: () => {
					setGetEventLoading(false);
				},
			}),
		);
	}, [dispatch, id, getEventLoading]);

	useEffect(() => {
		if (
			!currentCommunityId ||
			!currentEvent ||
			events?.data?.length > 0 ||
			getEventsDataRef.current
		)
			return;
		dispatch(
			getEvents({
				communityId: currentCommunityId,
				start: !events.selectedCalendarDate
					? moment(currentEvent.starts_at)
							.startOf("month")
							.format("MM/DD/YYYY hh:mm:ss")
					: moment(events.selectedCalendarDate)
							.add(-3, "months")
							.format("MM/DD/YYYY hh:mm:ss"),
				end: !events.selectedCalendarDate
					? moment(currentEvent.ends_at)
							.endOf("month")
							.format("MM/DD/YYYY hh:mm:ss")
					: moment(events.selectedCalendarDate)
							.add(+3, "months")
							.format("MM/DD/YYYY hh:mm:ss"),
				groupId: currentGroupId,
			}),
		);
		getEventsDataRef.current = true;
	}, [
		dispatch,
		events.selectedCalendarDate,
		currentCommunityId,
		currentGroupId,
		currentEvent,
		events?.data?.length,
		events,
	]);

	return (
		<>
			<HelmetPageTitle screenTitle={"Calendar"} />
			<MainLayout>
				<div
					onClick={goBack}
					className={styles.backBackButtonContainer}
				>
					<Back />
					<span>{"Back to calendar"}</span>
				</div>
				<div
					onClick={goBack}
					className={styles.backBackButtonContainerEvent}
				>
					<Back />
					<span>{"Back to Events List"}</span>
				</div>
				{getEventLoading ? (
					<Loading />
				) : currentEvent ? (
					<Row gutter={[16, 16]} style={{ height: "100%" }}>
						<Col xs={24} xl={16}>
							<div className={styles.eventInfoBox}>
								<div className={styles.eventInfo}>
									<div className={styles.eventTitleInfo}>
										<h3>{currentEvent.title}</h3>
										<p style={{ marginTop: 10 }}>{`${moment(
											currentEvent.starts_at,
										).format("MMMM DD HH:mm")} - ${moment(
											currentEvent.ends_at,
										).format("MMMM DD HH:mm")}`}</p>
									</div>
									{currentEvent.location && (
										<div
											className={
												styles.eventLocationAndCalendar
											}
										>
											<Location
												className={styles.location}
											/>
											<p className={styles.locationText}>
												{currentEvent.location}
											</p>
										</div>
									)}
								</div>
								<div className={styles.eventButtonandEvent}>
									<div
										className={styles.eventContent}
										style={{
											margin: "0 0 20px 0",
											fontSize: "14px",
											lineHeight: "18px",
											fontFamily: "Nunito-Regular",
											fontWeight: "normal",
											whiteSpace: "pre-wrap",
											wordBreak: "break-word",
										}}
										dangerouslySetInnerHTML={{
											__html: linkifyStr(clean),
										}}
									/>
									<div className={styles.addToCalendarBtn}>
										<Menu
											onClick={handleAddToCalendar}
											selectedKeys={[]}
											mode="horizontal"
											style={{
												backgroundColor: "transparent",
											}}
										>
											<Menu.SubMenu
												key="SubMenu"
												icon={<CheckmarkIcon />}
												title="Add to Calendar"
											>
												{calendarItems.map((item) => (
													<Menu.Item key={item.key}>
														{item.key}
													</Menu.Item>
												))}
											</Menu.SubMenu>
										</Menu>
									</div>
								</div>
								{relatedEvents.length > 0 && (
									<div className={styles.relatedEvents}>
										<h3>{"Related Events"}</h3>
										<Row
											gutter={[18, 18]}
											className={
												styles.relatedEventsWidth
											}
										>
											{relatedEvents.map(
												(item: any, index: number) => {
													return (
														<Col
															className={
																styles.relatedEventsWidth
															}
															span={12}
															key={`related_events_${index}`}
															onClick={() =>
																handleOnSelectEvent(
																	item,
																)
															}
														>
															<RelatedEventsBox
																eventId={
																	item.id
																}
																text={
																	item.title
																}
																avatar={
																	avatarSm1
																}
																tag={
																	item.event_tag_list
																}
																// tagColor="blue"
																description={
																	item.description
																}
															/>
														</Col>
													);
												},
											)}
										</Row>
									</div>
								)}
							</div>
						</Col>
						{/* <div className={styles.listOfEvents}> */}
						<ListOfEvent />
						{/* </div> */}
						{/* {shouldShowSidebar && (
							<Col span={10} className={styles.eventDetails}>
								<div className={styles.calendarProfileInfoBox}>
									<div className={styles.mapInfoBox}>
										<h5>{currentEvent.title}</h5>
										<div style={{display:'-webkit-box'}}>
											<img src={locationIco} alt="" style={{marginRight:'4px'}}/>
											<p
												dangerouslySetInnerHTML={{
													__html: linkifyStr(
														currentEvent.location,
													),
												}}
											/>
										</div>
										<Map
											lat={currentEvent.latitude}
											lng={currentEvent.longitude}
										/>
									</div>
								</div>
							</Col>
						)} */}
					</Row>
				) : (
					<div className={styles.emptyPlaceholder}>
						{"There are no data available."}
					</div>
				)}
			</MainLayout>
		</>
	);
};

export default memo(CalendarEvent);
