import React, { memo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faFileWord,
	faFilePdf,
	faFileAlt,
	faImage,
} from "@fortawesome/free-solid-svg-icons";

import downloadIco from "assets/images/icons/common_icons/download_ico.png";
import styles from "./styles.module.scss";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface IFileDownloadBox {
	attachment: { url: string };
	name: string;
	size: string;
	docType?: string;
	docExtension?: string;
}

const iconFileTypes: any = {
	pdf: faFilePdf,
	word: faFileWord,
	alt: faFileAlt,
};

const FileDownloadBox = ({
	attachment,
	docExtension,
	docType,
	name,
}: IFileDownloadBox) => {
	return (
		<div className={styles.fileDownloadWrapper}>
			<div className={styles.fileDownloadBox}>
				<div className={styles.imageHolder}>
					{/* <img src={image} alt="" /> */}
					{docExtension && docType === "application" ? (
						// <img src={image} alt="" />
						<FontAwesomeIcon
							icon={
								(iconFileTypes[docExtension]
									? iconFileTypes[docExtension]
									: iconFileTypes.alt) as unknown as IconProp
							}
							style={{
								//color: "#77D6E6",
								display: "block",
								fontSize: "50px",
								margin: "auto",
							}}
						/>
					) : (
						<FontAwesomeIcon
							icon={faImage as unknown as IconProp}
							style={{
								//color: "#77D6E6",
								display: "block",
								fontSize: "50px",
								margin: "auto",
							}}
						/>
					)}
				</div>
				<div className={styles.fileInfo}>
					<p>{name}</p>
					{/* <span>Uploaded by {uploadedBy}</span> */}
				</div>
				{/* <div className={styles.date}>
            <p>{date}</p>
            <span>{month}</span>
          </div> */}
				{/* <div className={styles.size}>
          <p>{size}</p>
          <span>mb</span>
        </div> */}
				<a
					href={attachment.url}
					className={styles.download}
					download
					target="_blank"
					rel="noopener noreferrer"
				>
					<img src={downloadIco} alt="" />
					<span>download</span>
				</a>
			</div>
		</div>
	);
};

export default memo(FileDownloadBox);
