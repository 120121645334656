import React, { memo, useLayoutEffect } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useDispatch } from "react-redux";

import styles from "./styles.module.scss";
import { Loading, PostBox } from "components";
import { appSetFeedPostsContainerWidth } from "store";

interface IFeedPosts {
	posts: any[];
	loading: boolean;
	meta: any;
	loadMore: (page: number) => void;
	hasMore: boolean;
	getScrollParent: () => HTMLElement | null;
	useWindows?: boolean;
	initialLoad?: boolean;
	parentRef?: any;
}

const FeedPosts: React.FunctionComponent<IFeedPosts> = ({
	useWindows = false,
	getScrollParent,
	initialLoad = false,
	loading,
	posts,
	meta,
	loadMore,
}) => {
	const dispatch = useDispatch();

	useLayoutEffect(() => {
		const feedPostInfiniteScrollNode = document.getElementById(
			"feed-posts-infinite-scroll",
		);
		if (feedPostInfiniteScrollNode) {
			dispatch(
				appSetFeedPostsContainerWidth({
					containerWidth: feedPostInfiniteScrollNode.clientWidth,
				}),
			);
		}
	}, [dispatch]);


	return (
		<InfiniteScroll
			pageStart={1}
			loadMore={loadMore}
			initialLoad={initialLoad}
			hasMore={!loading && meta?.pagination?.has_next_page}
			loader={
				<div key={0}>
					<Loading />
				</div>
			}
			useWindow={useWindows}
			getScrollParent={getScrollParent}
			className={styles.infiniteScrollContainer}
			id={"feed-posts-infinite-scroll"}
		>
			{posts?.length === 0 && loading ? (
				<Loading />
			) : posts?.length > 0 ? (
				posts.map((item: any, index: number) => (
					<PostBox
						key={`${index}-${item.id}`}
						uniqueKey={`post-${index}-${item.id}`}
						data={item}
						getScrollParent={getScrollParent}
					/>
				))
			) : null}
		</InfiniteScroll>
	);
};

export default memo(FeedPosts);
