import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import { EProfileActionTypes } from "models";

export const setPublicProfileLoading = (payload: boolean) => ({
	type: EProfileActionTypes.publicProfileLoadingSet,
	payload,
});
export const setEditProfileLoading = (payload: boolean) => ({
	type: EProfileActionTypes.editProfileLoadingSet,
	payload,
});

export const setUserProfileLoading = (payload: boolean) => ({
	type: EProfileActionTypes.editProfileLoadingSet,
	payload,
});
export const setUserProfileError = (payload: any) => ({
	type: EProfileActionTypes.setUserProfileError,
	payload,
});

export const getPublicProfile = (payload: any) => ({
	type: EProfileActionTypes.getPublicProfile,
	payload,
});
export const getEditProfile = (payload: {
	userId: number;
	communityId: number;
	onSuccessCallback?: () => void;
	onErrorCallback?: () => void;
}) => ({
	type: EProfileActionTypes.getEditProfile,
	payload,
});
export const getUserProfile = (payload: any) => ({
	type: EProfileActionTypes.getUserProfile,
	payload,
});

export const setPublicProfile = (payload: any) => ({
	type: EProfileActionTypes.setPublicProfile,
	payload,
});
export const setEditProfile = (payload: any) => ({
	type: EProfileActionTypes.setEditProfile,
	payload,
});
export const getEditProfilePosts = (payload?: any) => ({
	type: EProfileActionTypes.getEditProfilePosts,
	payload,
});
export const setEditProfilePosts = (payload: any) => ({
	type: EProfileActionTypes.setEditProfilePosts,
	payload,
});
export const setUserProfile = (payload: any) => ({
	type: EProfileActionTypes.setUserProfile,
	payload,
});
export const saveUserProfile = (payload: {
	id?: number;
	firstName?: string;
	lastName?: string;
	email?: string;
	address?: string;
	latitude?: number;
	longitude?: number;
	aboutMe?: string;
	communityCheckboxGroup?: any;
	marketplaceCheckboxGroup?: any;
	skillsCheckboxGroup?: any;
	avatar?: UploadChangeParam<UploadFile<any>>;
	isFromOnboarding?: boolean;
	ugenieHubLink?: string;
	dontRedirect?: boolean;
	communityId?: number;
	onSuccessCallback?: () => void;
	onErrorCallback?: () => void;
	industry: string;
	profession: string;
}) => ({
	type: EProfileActionTypes.saveUserProfile,
	payload,
});

export const addUserImage = (payload: any) => ({
	type: EProfileActionTypes.addUserImage,
	payload,
});
export const addUserLocation = (payload: any) => ({
	type: EProfileActionTypes.addUserLocation,
	payload,
});

export const updateUserDetails = ({ user }: { user: any }) => ({
	type: EProfileActionTypes.setUserDetails,

	user,
});

export const updateProfileAccountSettings = ({
	userData,
	memberId = undefined,
	actionFromMentionModal = false,
	callbackOnSuccess,
	callbackOnError,
}: {
	userData: {
		location?: string;
		first_name?: string;
		last_name?: string;
		password?: string;
		member_directory_opt_in?: boolean;
	};
	memberId?: number; // when a user will opt in in member directory by using the modal from Mention component, the memberId is used to redirect the user to a specific member in members page.
	actionFromMentionModal?: boolean;
	callbackOnSuccess?: (updatedUserData?: any) => void;
	callbackOnError?: () => void;
}) => ({
	type: EProfileActionTypes.updateProfileAccountSettings,
	userData,
	memberId,
	actionFromMentionModal,
	callbackOnSuccess,
	callbackOnError,
});
