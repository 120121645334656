import { ICommunity } from "models";
import {
	ICommunitiesJoinedLoadingSetAction,
	ICommunitiesJoinedGetAction,
	ICommunitiesJoinedSetAction,
	ICommunitiesJoinedSetSearchQueryAction,
	ICommunitiesJoinedUpdateJoinedAndApprovedStatusAction,
} from "./actions";

// ENUMS
export enum ECommunitiesJoinedActionTypes {
	communitiesJoinedLoadingSet = "communities/joined/set/loading",
	getCommunitiesJoined = "communities/joined/get",
	getMoreCommunitiesJoined = "communities/joined/get/more",
	setCommunitiesJoined = "communities/joined/set",
	setSearchQuery = "communities/joined/set/search/query",
	updateJoinedAndApprovedStatusForJoinedCommunity = "communities/joined/update/joined/and/approved/status",
}

// INTERFACES
export interface ICommunitiesJoinedState {
	loading: boolean;
	searchQuery: string;
	data?: ICommunity[];
	meta?: {
		//TODO: meta needs to be mandatory (temporary)
		invites?: any[];
		pagination: {
			current_page: number;
			per_page: number;
			total_pages: number;
			total_objects: number;
			has_next_page: boolean;
		};
	};
}

export interface ICommunitiesJoinedLoadingPayload {
	loading: boolean;
}

// TYPES
export type TCommunitiesJoinedActions =
	| ICommunitiesJoinedSetAction
	| ICommunitiesJoinedLoadingSetAction
	| ICommunitiesJoinedGetAction
	| ICommunitiesJoinedSetSearchQueryAction
	| ICommunitiesJoinedUpdateJoinedAndApprovedStatusAction;
