import React, { memo, useCallback, useMemo, useEffect, useState } from "react";
import { Form, Row, Col, Input, Button, AutoComplete, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";

import styles from "./styles.module.scss";
import { TStore, IAuthState, IEditProfileState } from "models";
import { saveUserProfile, getEditProfile, getEditProfilePosts } from "store";
import { Loading, CheckboxGroupView } from "components";
import UserAvatar from "../userAvatar";
import { axiosInstance, notify } from "store/services";
import { rollbar } from "utils";

const { Option } = Select;

const stateSelectorHandle = createSelector(
	(state: TStore) => state.auth,
	(state: TStore) => state.editProfile,
	(state: TStore) => state.app.currentCommunityId,
	(state: TStore) =>
		state.posts?.meta?.appearance?.skill_tag_display_name?.plural,
	(state: TStore) => state?.posts?.meta?.appearance?.industry_display_name,
	(state: TStore) => state?.posts?.meta?.appearance?.profession_display_name,
	(
		auth: IAuthState,
		editProfile: IEditProfileState,
		currentCommunityId,
		skill_tag_display_name,
		industry_display_name,
		profession_display_name,
	) => ({
		auth,
		editProfile,
		currentCommunityId,
		skill_tag_display_name,
		industry_display_name,
		profession_display_name,
	}),
);

const ProfileForm = () => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const stateSelector = useCallback(stateSelectorHandle, []);
	const [locations, setLocations] = useState<any>([]);
	const [selectedLocation, setSelectedLocation] = useState<{
		address: string;
	} | null>(null);
	const [loading, setLoading] = useState(true);
	const {
		auth,
		editProfile,
		currentCommunityId,
		skill_tag_display_name,
		industry_display_name,
		profession_display_name,
	} = useSelector(stateSelector);

	const locationsOptions = useMemo(() => {
		return locations?.map((item: any) => {
			return (
				<Option key={item.label} value={item.value.address}>
					{item.label}
				</Option>
			);
		});
	}, [locations]);

	const onLocationSelect = useCallback((val) => {
		setSelectedLocation(val);
	}, []);

	const onLocationSearch = useCallback((val) => {
		// reset the selected location data anytime the user interacts with the location select field in order to enforce the user to select from the dropdown.
		setSelectedLocation(null);
		if (!val) return;
		axiosInstance()
			.get("/geocoder", {
				params: { address: val },
			})
			.then((data) => {
				const parseData =
					data &&
					data.data.results.map(
						(item: {
							address: string;
							latitude: number;
							longitude: number;
						}) => {
							const { address, latitude, longitude } = item;
							return {
								label: address,
								value: JSON.stringify({
									address,
									latitude,
									longitude,
								}),
							};
						},
					);
				setLocations(parseData);
			})
			.catch((error) => {
				console.error({ error });
				rollbar.error(error);
				setLocations([]);
			});
	}, []);

	const onValuesChange = useCallback(
		(changedValues, allValues) => {
			switch (Object.keys(changedValues)[0]) {
				case "industry": {
					form.setFieldsValue({
						industry: changedValues.industry,
					});
					break;
				}
				case "profession": {
					form.setFieldsValue({
						profession: changedValues.profession,
					});
					break;
				}
				case "communityCheckAll": {
					form.setFieldsValue({
						communityCheckboxGroup:
							editProfile.data.user?.subset_list?.map(
								(item: any) => item.name,
							),
					});
					break;
				}
				case "communityCheckNone": {
					form.setFieldsValue({
						communityCheckboxGroup: [],
					});

					break;
				}
				case "communityCheckboxGroup": {
					// check if all checkboxes are checked
					if (
						editProfile.data.user.subset_list &&
						changedValues.communityCheckboxGroup.length ===
							editProfile.data.user.subset_list.length
					) {
						form.setFieldsValue({
							communityCheckAll: true,
						});
					} else {
						form.setFieldsValue({
							communityCheckAll: false,
						});
					}
					if (changedValues.communityCheckboxGroup.length === 0) {
						form.setFieldsValue({
							all_community: true,
						});
					} else {
						form.setFieldsValue({
							all_community: false,
						});
					}
					break;
				}
				case "marketplaceCheckAll": {
					form.setFieldsValue({
						marketplaceCheckboxGroup:
							editProfile.data.user.good_tag_list &&
							editProfile.data.user.good_tag_list.map(
								(item: any) => item.name,
							),
					});

					break;
				}
				case "marketplaceCheckNone": {
					form.setFieldsValue({
						marketplaceCheckboxGroup: [],
					});

					break;
				}
				case "marketplaceCheckboxGroup": {
					// check if all checkboxes are checked
					if (
						editProfile.data.user.good_tag_list &&
						changedValues.marketplaceCheckboxGroup.length ===
							editProfile.data.user.good_tag_list.length
					) {
						form.setFieldsValue({
							marketplaceCheckAll: true,
						});
					} else {
						form.setFieldsValue({
							marketplaceCheckAll: false,
						});
					}
					if (changedValues.marketplaceCheckboxGroup.length === 0) {
						form.setFieldsValue({
							all_marketplace: true,
						});
					} else {
						form.setFieldsValue({
							all_marketplace: false,
						});
					}
					break;
				}
				case "skillsCheckAll": {
					form.setFieldsValue({
						skillsCheckboxGroup:
							editProfile.data.user.skill_list &&
							editProfile.data.user.skill_list.map(
								(item: any) => item.name,
							),
					});

					break;
				}
				case "skillsCheckNone": {
					form.setFieldsValue({
						skillsCheckboxGroup: [],
					});

					break;
				}
				case "skillsCheckboxGroup": {
					// check if all checkboxes are checked
					if (
						editProfile.data.user.skill_list &&
						changedValues.skillsCheckboxGroup.length ===
							editProfile.data.user.skill_list.filter(
								(item) => item.is_selected,
							)
					) {
						form.setFieldsValue({
							skillsCheckAll: true,
						});
					} else {
						form.setFieldsValue({
							skillsCheckAll: false,
						});
					}
					if (changedValues.skillsCheckboxGroup.length === 0) {
						form.setFieldsValue({
							all_skills: true,
						});
					} else {
						form.setFieldsValue({
							all_skills: false,
						});
					}
					break;
				}

				default:
			}
		},
		[editProfile, form],
	);

	const onFinish = useCallback(
		(values) => {
			try {
				const { email, ...resultedValues } = values;
				setLoading(true);
				dispatch(
					saveUserProfile({
						id: auth.credentials?.user.id,
						...resultedValues,
						onErrorCallback: () => {
							setLoading(false);
						},
						onSuccessCallback: () => {
							setLoading(false);
						},
					}),
				);
			} catch (error) {
				console.error({ error });
			}
		},
		[dispatch, auth],
	);

	useEffect(() => {
		if (currentCommunityId) {
			dispatch(getEditProfilePosts());
		}
	}, [currentCommunityId, dispatch]);

	useEffect(() => {
		// TODO: don't execute the function if the needed data is already there.
		if (!auth.credentials?.user.id || !currentCommunityId) return;
		dispatch(
			getEditProfile({
				communityId: currentCommunityId,
				userId: auth.credentials?.user.id,
				onErrorCallback: () => {
					notify(
						"error",
						"An error occurred. Please try again later.",
					);
					setLoading(false);
				},
				onSuccessCallback: () => setLoading(false),
			}),
		);
	}, [auth.credentials?.user.id, currentCommunityId, dispatch]);

	useEffect(() => {
		if (auth?.credentials?.user && editProfile?.data?.user && form) {
			const { email, first_name, last_name, location } =
				auth.credentials.user;
			const {
				subset_list,
				good_tag_list,
				skill_list,
				picture,
				profession,
				industry,
			} = editProfile.data.user;

			const selectedCommunityTags =
				subset_list &&
				subset_list
					.filter((item) => {
						return item.is_selected;
					})
					.map((item) => {
						return item.name;
					});
			const selectedMarketplaceTags =
				good_tag_list &&
				good_tag_list
					.filter((item) => {
						return item.is_selected;
					})
					.map((item) => {
						return item.name;
					});

			const selectedSkillsTags =
				skill_list &&
				skill_list
					.filter((item) => {
						return item.is_selected;
					})
					.map((item) => {
						return item.name;
					});

			form.setFieldsValue({
				email,
				firstName: first_name,
				lastName: last_name,
				address: location,
				aboutMe: editProfile.data.user.about_me,
				avatar: picture,
				communityCheckboxGroup: selectedCommunityTags,
				marketplaceCheckboxGroup: selectedMarketplaceTags,
				skillsCheckboxGroup: selectedSkillsTags,
				industry: industry || "",
				profession: profession || "",
			});
			setSelectedLocation({ address: location });
		}
	}, [auth, editProfile, form]);

	if (!editProfile.data.user || !form || loading) return <Loading />;
	else
		return (
			<div className={styles.form}>
				<Form
					name="edit-profile-form"
					layout="vertical"
					onFinish={onFinish}
					onValuesChange={onValuesChange}
					form={form}
					requiredMark={false}
				>
					<Row className={styles.mainEditProfileLayout}>
						<Col span={6}>
							<div className={styles.avatar}>
								<UserAvatar form={form} />
							</div>
						</Col>

						<Col span={18} className={styles.formLayout}>
							<Row
								gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
								className={styles.twoColumnsPerRow}
							>
								<Col span={12}>
									<Form.Item
										label="First Name"
										name="firstName"
										rules={[
											{
												required: true,
												message: `"First Name" is required`,
											},
										]}
									>
										<Input />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										label="Last Name"
										name="lastName"
										rules={[
											{
												required: true,
												message: `"Last Name" is required`,
											},
										]}
									>
										<Input />
									</Form.Item>
								</Col>
							</Row>
							<Row className={styles.twoColumnsPerRow}>
								<Col span={24}>
									<Form.Item label="Email" name="email">
										<Input
											className={styles.email}
											readOnly
											disabled
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row>
								<Col span={24}>
									<Form.Item
										label="Address"
										name="address"
										rules={[
											{
												required: true,
												message: `"Address" is required`,
											},
											() => ({
												validator() {
													if (!selectedLocation) {
														return Promise.reject(
															`Please select a valid location from the dropdown.`,
														);
													} else {
														return Promise.resolve();
													}
												},
											}),
										]}
									>
										<AutoComplete
											onSearch={onLocationSearch}
											onSelect={onLocationSelect}
											placeholder="Location"
											value={selectedLocation?.address}
										>
											{locationsOptions}
										</AutoComplete>
									</Form.Item>
								</Col>
							</Row>
							<Row>
								<Col span={24}>
									<Form.Item label="About me" name="aboutMe">
										<Input.TextArea rows={5} />
									</Form.Item>
								</Col>
							</Row>
							{industry_display_name?.singular && (
								<Row>
									<Col span={24}>
										<Form.Item
											label={
												industry_display_name?.singular ||
												"Industry"
											}
											name="industry"
											rules={[
												{
													required: false,
													message: `"${
														industry_display_name?.singular ||
														"Industry"
													}" is required`,
												},
											]}
										>
											<Input />
										</Form.Item>
									</Col>
								</Row>
							)}
							{profession_display_name?.singular && (
								<Row>
									<Col span={24}>
										<Form.Item
											label={
												profession_display_name?.singular ||
												"Profession"
											}
											name="profession"
											rules={[
												{
													required: false,
													message: `"${
														profession_display_name?.singular ||
														"Profession"
													}" is required`,
												},
											]}
										>
											<Input />
										</Form.Item>
									</Col>
								</Row>
							)}
							<div className={styles.tagsSection}>
								{editProfile.data.user.subset_list &&
									editProfile.data.user.subset_list.length >
										0 && (
										<div className={styles.communityTags}>
											<div className={styles.tags}>
												<CheckboxGroupView
													checkAllTitle={
														"All Community Tags"
													}
													title={"COMMUNITY TAGS"}
													category="community"
													options={editProfile.data.user.subset_list.map(
														(item: any) =>
															item.name,
													)}
												/>
											</div>
										</div>
									)}
								{editProfile?.data?.user.skill_list &&
									editProfile?.data?.user.skill_list?.length >
										0 && (
										<div className={styles.marketPlaceTags}>
											<div className={styles.tags}>
												<CheckboxGroupView
													checkAllTitle={
														"All Skills Title"
													}
													category="skills"
													title={
														skill_tag_display_name ??
														"SKILLS TAGS"
													}
													options={editProfile.data.user.skill_list.map(
														(item: any) =>
															item.name,
													)}
												/>
											</div>
										</div>
									)}
							</div>
							<Row>
								<Col span={24} className={styles.submitButton}>
									<Form.Item name="post">
										<Button
											type="primary"
											htmlType="submit"
											className=""
										>
											{"Save Changes"}
										</Button>
									</Form.Item>
								</Col>
							</Row>
						</Col>
					</Row>
				</Form>
			</div>
		);
};

export default memo(ProfileForm);
